/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

/** AbnormalFlowInfoDTO */
export interface AbnormalFlowInfoDTO {
	/** @format int32 */
	abnormalNum?: number;
	flowCode?: string;
}

/** AbnormalOrderUnitPirceRespDTO */
export interface AbnormalOrderUnitPirceRespDTO {
	purchaseUnitPrice?: number;
	purchasingAbnormalOrderNo?: string;
}

/** AbnormalRefundOrderAddExpressNoReqDTO */
export interface AbnormalRefundOrderAddExpressNoReqDTO {
	/** @format int64 */
	abnormalOrderDealId?: number;
	/** @format int32 */
	deliveryNum?: number;
	expressNo?: string;
	flowCode?: string;
	purchaseNo?: string;
}

/** AbnormalRollBackReqDTO */
export interface AbnormalRollBackReqDTO {
	purchaseAbnormalNo?: string;
	/** @format int32 */
	type?: number;
}

/** AddOemPurchaseOperateLogReqDTO */
export interface AddOemPurchaseOperateLogReqDTO {
	operateContent?: string;
	/** @format int32 */
	operateScene?: number;
	/** @format int64 */
	purchaseAbnormalOrderId?: number;
	purchaseAbnormalOrderNo?: string;
	/** @format int64 */
	purchaseId?: number;
	purchaseOrderNo?: string;
	userId?: string;
	userName?: string;
}

/** ApplyOutOfStockAbnormalReqDTO */
export interface ApplyOutOfStockAbnormalReqDTO {
	accountType?: string;
	alipayAccount?: string;
	approvalNo?: string;
	/** @format int32 */
	num?: number;
	/** @format int64 */
	oemPurchaseItemId?: number;
	/** @format date-time */
	receiveMoneyTime?: string;
	refundAmount?: number;
	remark?: string;
	userId?: string;
	userName?: string;
}

/** ApplySupplierRefundReqDTO */
export interface ApplySupplierRefundReqDTO {
	accountType?: string;
	alipayAccount?: string;
	/** @format int64 */
	purchaseAbnormalOrderId?: number;
	receiveMoney?: number;
	/** @format date-time */
	receiveMoneyTime?: string;
	supplierOrderNo?: string;
	tradeNo?: string;
}

/** BarleyRefundOrderReqDTO */
export interface BarleyRefundOrderReqDTO {
	appKey?: string;
	courierPhoneNumber?: string;
	dataDigest?: string;
	pickupCode?: string;
	/** @format date-time */
	pickupStartTime?: string;
	pickupTimeInterval?: string;
	/** @format float */
	pickupTotalTime?: number;
	purchaseAccount?: string;
	purchaseOrderNo?: string;
	refundAddress?: string;
	refundAmount?: number;
	refundCity?: string;
	/** @format date-time */
	refundDeadline?: string;
	refundDistrict?: string;
	refundProvince?: string;
	refundReceivingAccount?: string;
	/** @format date-time */
	refundTime?: string;
	/** @format int32 */
	refundType?: number;
	/** @format int64 */
	timestamp?: number;
}

/** BarleySubmitOrderReqDTO */
export interface BarleySubmitOrderReqDTO {
	appKey?: string;
	dataDigest?: string;
	logisticsNo?: string;
	orderNo?: string;
	payAccount?: string;
	productSku?: string;
	purchaseAccount?: string;
	purchaseOrderNo?: string;
	purchaseOrderTotalPrice?: number;
	/** @format date-time */
	spendingTime?: string;
	/** @format int64 */
	timestamp?: number;
	totalFreight?: number;
}

/** BatchCreateOrderReqDTO */
export interface BatchCreateOrderReqDTO {
	purchaseIds?: number[];
}

/** BizResponse */
export interface BizResponse {
	code?: string;
	data?: object;
	msg?: string;
	success?: boolean;
}

/** BoundOrderNoCallBackReqDTO */
export interface BoundOrderNoCallBackReqDTO {
	/** @format int64 */
	abnormalOrderDealId?: number;
	inBoundOrderNo?: string;
	oemPurchaseNo?: string;
	outBoundOrderNo?: string;
	/** @format int32 */
	signTotal?: number;
	/** @format int32 */
	total?: number;
}

/** ChangeAddressReqDTO */
export interface ChangeAddressReqDTO {
	address?: string;
	/** @format int64 */
	purchaseAbnormalOrderId?: number;
	refundContact?: string;
	refundPhone?: string;
	/** @format date-time */
	returnDeadLine?: string;
}

/** ChangeDealTypeReqDTO */
export interface ChangeDealTypeReqDTO {
	/** @format int64 */
	abnormalOrderDealId?: number;
	accountType?: string;
	collectionAccount?: string;
	notReturnReason?: string;
	/** @format int64 */
	oemPurchaseAbnormalOrderId?: number;
	/** @format int32 */
	purchaseOperateType?: number;
	purchaseRemark?: string;
	refundContact?: string;
	refundPhone?: string;
	/** @format int32 */
	responsiblePerson?: number;
	returnAddress?: string;
	returnAmount?: number;
	returnApprovalNo?: string;
	/** @format date-time */
	returnExpiryTime?: string;
	/** @format date-time */
	returnPaymentTime?: string;
	userId?: string;
	userName?: string;
	userReturnAmount?: number;
}

/** ChangePurchaseRemarkDTO */
export interface ChangePurchaseRemarkDTO {
	/** @format int64 */
	id?: number;
	remark?: string;
	userId?: string;
	userName?: string;
}

/** ChangePurchaseUserReqDTO */
export interface ChangePurchaseUserReqDTO {
	/** 修改列表 */
	changeUsers?: ChangeUserDTO[];
	/**
	 * 修改类型 1. 修改成品采购员；2. 修改成品跟单员
	 * @format int32
	 */
	modifyType?: number;
	/** 操作用户id */
	userId?: string;
	/** 操作用户名 */
	userName?: string;
}

/** ChangeUserDTO */
export interface ChangeUserDTO {
	/** 修改后的用户id */
	newUserId?: string;
	/** 修改后的用户名称 */
	newUserName?: string;
	/**
	 * 采购单id
	 * @format int64
	 */
	purchaseId?: number;
}

/** CommitWmsAddressReqDTO */
export interface CommitWmsAddressReqDTO {
	address?: string;
	/** @format int64 */
	purchaseAbnormalOrderId?: number;
	refundContact?: string;
	refundPhone?: string;
	remark?: string;
	/** @format date-time */
	returnDeadLine?: string;
}

/** ContinueEntryWareReqDTO */
export interface ContinueEntryWareReqDTO {
	/** @format int32 */
	applyNum?: number;
	/** @format int64 */
	oemPurchaseAbnormalOrderId?: number;
	purchaseRemark?: string;
	userId?: string;
	userName?: string;
}

/** CreateFactoryExceptionReqDTO */
export interface CreateFactoryExceptionReqDTO {
	/** @format int32 */
	num?: number;
	/** @format int64 */
	oemPurchaseItemId?: number;
	originatorId?: string;
	originatorName?: string;
	/** @format int32 */
	type?: number;
}

/** CreateYinYangPurchaseItemReqDTO */
export interface CreateYinYangPurchaseItemReqDTO {
	/**
	 * 业务类型: 1-档口(市场购);2-线上平台（透明购）;5-线下平台（线下购）
	 * @format int32
	 */
	bizType?: number;
	/** sku平台类型 */
	platformType?: string;
	/**
	 * 采购单明细id
	 * @format int64
	 */
	purchaseItemId?: number;
	/** 推荐sku */
	recommendProductSku?: string;
	/** 供应商编码 */
	shopCode?: string;
}

/** CreateYinYangPurchaseReqDTO */
export interface CreateYinYangPurchaseReqDTO {
	/**
	 * 异常重采标识：0-否 1-是
	 * @format int32
	 */
	abnormalTag?: number;
	/** 采购单明细 */
	purchaseItemList?: CreateYinYangPurchaseItemReqDTO[];
	/** 采购单号 */
	purchaseNo?: string;
	/**
	 * 转阴阳id类型：0-采购单转换 1-明细转换
	 * @format int32
	 */
	type?: number;
	userId?: string;
	userName?: string;
}

/** EditBargainRemarkDTO */
export interface EditBargainRemarkDTO {
	/** 议价邮费 */
	bargainFreight?: number;
	/** 议价备注 */
	bargainRemark?: string;
	/** 议价单价 */
	bargainUnitPrice?: number;
	/** 下单邮费 */
	createOrderFreight?: number;
	/** 下单单价 */
	createOrderUnitPrice?: number;
	/** 折扣 */
	discountRate?: string;
	/** 优惠金额 */
	promotionAmount?: number;
	/**
	 * 采购货品主键id
	 * @format int64
	 */
	purchaseItemId?: number;
	/** 立减金额 */
	reductionAmount?: string;
}

/** EditBargainRemarkReqDTO */
export interface EditBargainRemarkReqDTO {
	/** 货品列表 */
	orderItems?: EditBargainRemarkDTO[];
	/**
	 * 采购单ID
	 * @format int64
	 */
	purchaseId?: number;
	/** 操作用户id */
	userId?: string;
	/** 操作用户名 */
	userName?: string;
}

/** EditItemCategoryReqDTO */
export interface EditItemCategoryReqDTO {
	/** 新中文品名 */
	itemCategory?: string;
	manageUserId?: string;
	manageUsername?: string;
	/** 原中文品名 */
	oldItemCategory?: string;
	/**
	 * 采购货品明细id
	 * @format int64
	 */
	purchaseItemId?: number;
}

/** EditOemPurchaseItemReqDTO */
export interface EditOemPurchaseItemReqDTO {
	/** @format int64 */
	oemPurchaseItemId?: number;
	/** @format int64 */
	productCustomsId?: number;
	productImg?: string;
	productItemCategoryZh?: string;
	purchaseAmount?: number;
	/** @format int32 */
	purchaseNum?: number;
	purchaseUnitPrice?: number;
	remark?: string;
	sellUnitPrice?: number;
	userId?: string;
	userName?: string;
}

/** EditOemPurchaseOrderReqDTO */
export interface EditOemPurchaseOrderReqDTO {
	customerFreight?: number;
	domesticFreight?: number;
	/** @format int64 */
	oemPurchaseId?: number;
	paymentList?: OemPurchasePaymentReqDTO[];
	/** @format int32 */
	purchaseChannel?: number;
	remark?: string;
	tbAccount?: string;
	tbOrderNo?: string;
	userId?: string;
	userName?: string;
}

/** EditOfflineOrderReqDTO */
export interface EditOfflineOrderReqDTO {
	/** 对账流水号 */
	accountCheckBizId?: string;
	/** 支付账户类型；1：支付宝；2：跨境宝 */
	accountType?: string;
	/** 实际结算金额 */
	actualSettleAmount?: number;
	/** 支付账号 */
	alipayAccount?: string;
	manageUserId?: string;
	manageUsername?: string;
	/** 应结算金额 */
	needSettleAmount?: number;
	/**
	 * 实际支付时间
	 * @format date-time
	 */
	payTime?: string;
	/** 交易流水号 */
	tradeNo?: string;
}

/** EditPurchaseExpressNoReqDTO */
export interface EditPurchaseExpressNoReqDTO {
	expressNo?: string;
	logisticsTag?: string;
	purchaseEstimateStockTime?: string;
	/** @format int64 */
	purchaseId?: number;
}

/** EditPurchaseOrderReqDTO */
export interface EditPurchaseOrderReqDTO {
	/** 支付方式 1：支付宝；2：跨境宝 */
	accountType?: string;
	/** 支付账户 */
	alipayAccount?: string;
	/**
	 * 实际支出时间
	 * @format date-time
	 */
	payTime?: string;
	/** 采购运费 */
	purchaseFreight?: number;
	/**
	 * 采购单ID
	 * @format int64
	 */
	purchaseId?: number;
	/** 采购商品总价 */
	purchaseMoney?: number;
	/** 采购账户 */
	tbAccount?: string;
	/** 渠道单号 */
	tbOrderNo?: string;
	/** 用户ID */
	userId?: string;
	/** 用户名 */
	userName?: string;
}

/** ExtendPurchaseAbnormalOrderRespDTO */
export interface ExtendPurchaseAbnormalOrderRespDTO {
	/** @format int32 */
	isAdditionReturnAndRefund?: number;
	/** @format int32 */
	isUpperLimitRemakeNum?: number;
}

/** File */
export interface File {
	absolute?: boolean;
	absoluteFile?: File;
	absolutePath?: string;
	canonicalFile?: File;
	canonicalPath?: string;
	directory?: boolean;
	executable?: boolean;
	file?: boolean;
	/** @format int64 */
	freeSpace?: number;
	hidden?: boolean;
	/** @format int64 */
	lastModified?: number;
	name?: string;
	parent?: string;
	parentFile?: File;
	path?: string;
	readable?: boolean;
	/** @format int64 */
	totalSpace?: number;
	/** @format int64 */
	usableSpace?: number;
	writable?: boolean;
}

/** HandleUserRefundReqDTO */
export interface HandleUserRefundReqDTO {
	manageUserId?: string;
	manageUsername?: string;
	onlyRefundGoodsReqDTO?: OnlyRefundGoodsReqDTO;
	/** @format int64 */
	purchaseAbnormalOrderId?: number;
	purchaseAssociateReqDTO?: PurchaseAssociateReqDTO;
	purchaseDiscardReqDTO?: PurchaseDiscardReqDTO;
	purchaseOnlyReturnMoneyReqDTO?: PurchaseOnlyReturnMoneyReqDTO;
	/** @format int32 */
	purchaseOperateDetailType?: number;
	/** @format int32 */
	purchaseOperateType?: number;
	purchaseReSupplyReqDTO?: PurchaseReSupplyReqDTO;
	purchaseReturnAllReqDTO?: PurchaseReturnAllReqDTO;
	/** @format int32 */
	purchaseStatus?: number;
	stallAbnormalHandleReqDTO?: StallAbnormalHandleReqDTO;
	supplierAgreeRefundReqDTO?: SupplierAgreeRefundReqDTO;
	supplierRejectRefundReqDTO?: SupplierRejectRefundReqDTO;
}

/** HistoryPriceRespDTO */
export interface HistoryPriceRespDTO {
	price?: number;
	productSku?: string;
}

/** IdListQueryReqDTO */
export interface IdListQueryReqDTO {
	idList?: number[];
	userId?: string;
	username?: string;
}

/** IdQueryReqDTO */
export interface IdQueryReqDTO {
	/** @format int64 */
	id?: number;
	userId?: string;
	userName?: string;
}

/** ImportLogsiticNumbersRespDTO */
export interface ImportLogsiticNumbersRespDTO {
	/**
	 * 失败条数
	 * @format int32
	 */
	failCount?: number;
	/**
	 * 成功条数
	 * @format int32
	 */
	successCount?: number;
	/**
	 * 总条数
	 * @format int32
	 */
	totalCount?: number;
}

/** InputStream */
export type InputStream = object;

/** KuaiDiTrackItemRespDTO */
export interface KuaiDiTrackItemRespDTO {
	context?: string;
	ftime?: string;
	status?: string;
	time?: string;
}

/** KuaiDiTrackReqDTO */
export interface KuaiDiTrackReqDTO {
	trackingNumber?: string;
}

/** KuaiDiTrackRespDTO */
export interface KuaiDiTrackRespDTO {
	kuaiDiTrackItemList?: KuaiDiTrackItemRespDTO[];
	trackingNumber?: string;
}

/** ListOemPurchaseAbnormalRespDTO */
export interface ListOemPurchaseAbnormalRespDTO {
	abnormalImg?: string;
	abnormalImgList?: string[];
	/** @format int32 */
	abnormalNum?: number;
	/** @format int32 */
	accountCheckStatus?: number;
	accountType?: string;
	actualUnitPrice?: number;
	classificationName?: string;
	collectionAccount?: string;
	/** @format date-time */
	completeTime?: string;
	/** @format date-time */
	createTime?: string;
	/** @format int64 */
	customerId?: number;
	customerName?: string;
	/** @format int64 */
	customerShopId?: number;
	customerShopName?: string;
	/** 客户标签 1 oem项目重点客户 2 oem非项目重点客户 */
	customerTags?: number[];
	dealDetails?: OemPurchaseAbnormalOrderItemRespDTO[];
	/** @format int32 */
	dealedNum?: number;
	/** @format int32 */
	dealingNum?: number;
	flowCode?: string;
	/** @format date-time */
	handleTime?: string;
	/** @format int32 */
	maxApplyNum?: number;
	notReturnReason?: string;
	/** @format int64 */
	oemProductClassificationId?: number;
	/** @format int64 */
	oemPurchaseAbnormalOrderId?: number;
	/** @format int64 */
	oemPurchaseId?: number;
	oemPurchaseNo?: string;
	oemPurchasingAbnormalOrderNo?: string;
	originatorId?: string;
	originatorName?: string;
	platformOrderNo?: string;
	productImg?: string;
	productName?: string;
	productProp?: string;
	productSku?: string;
	/** @format int32 */
	purchaseKind?: number;
	purchaseManageUserId?: string;
	purchaseManageUserName?: string;
	/** @format int32 */
	purchaseOperateType?: number;
	purchaseRemark?: string;
	purchaseUserId?: string;
	purchaseUserName?: string;
	/** @format date-time */
	recordTime?: string;
	returnAddress?: string;
	returnAmount?: number;
	returnApprovalNo?: string;
	/** @format int32 */
	returnExamineStatus?: number;
	/** @format date-time */
	returnExpiryTime?: string;
	/** @format date-time */
	returnPaymentTime?: string;
	returnRemark?: string;
	returnWaybillNo?: string;
	shopCode?: string;
	shopName?: string;
	/** 站点代码(日本:JapanStation,韩国:KoreaStation,英国:UkStation) */
	stationCode?: string;
	/** @format int32 */
	status?: number;
	storeRemark?: string;
	/** @format int32 */
	storeReturnStatus?: number;
	/** @format int32 */
	supplierReturnStatus?: number;
	/** @format int32 */
	systemSource?: number;
	tbAccount?: string;
	tbOrderNo?: string;
	/** @format int32 */
	type?: number;
	unificationCustomerFullName?: string;
	/** @format date-time */
	updateTime?: string;
	userReturnAmount?: number;
	/** @format int32 */
	userReturnStatus?: number;
	/** @format int32 */
	waitDealNum?: number;
	wareCode?: string;
	wareName?: string;
}

/** ListOemPurchaseOrderRespDTO */
export interface ListOemPurchaseOrderRespDTO {
	/** @format int32 */
	checkedNum?: number;
	classificationName?: string;
	/** @format date-time */
	createTime?: string;
	customerFreight?: number;
	/** @format int64 */
	customerId?: number;
	customerName?: string;
	/** @format int64 */
	customerShopId?: number;
	customerShopName?: string;
	/** 客户标签 1 oem项目重点客户 2 oem非项目重点客户 */
	customerTags?: number[];
	/** @format int32 */
	defectiveNum?: number;
	expressList?: OrderExpressRespDTO[];
	inBoundOrderNo?: string;
	/** @format int32 */
	logisticsStatus?: number;
	/** @format int64 */
	oemProductClassificationId?: number;
	/** @format int64 */
	oemPurchaseId?: number;
	oemPurchaseNo?: string;
	orderItemList?: OemPurchaseOrderItemRespDTO[];
	paymentFlag?: boolean;
	platformOrderNo?: string;
	purchaseAmount?: number;
	/** @format int32 */
	purchaseChannel?: number;
	/** @format date-time */
	purchaseCompleteTime?: string;
	purchaseFreight?: number;
	purchaseKinds?: number[];
	purchaseManageUserId?: string;
	purchaseManageUserName?: string;
	/** @format int32 */
	purchaseNum?: number;
	/** @format int32 */
	purchaseStatus?: number;
	/** @format int32 */
	purchaseType?: number;
	purchaseUserId?: string;
	purchaseUserName?: string;
	purchasingAbnormalOrderNo?: string;
	/** @format int64 */
	searchSourceOrderId?: number;
	searchSourceOrderNo?: string;
	/** @format int32 */
	sellNum?: number;
	shopCode?: string;
	shopName?: string;
	/** @format int32 */
	signNum?: number;
	/** @format date-time */
	splitDate?: string;
	stageInfos?: StageInfoRespDTO[];
	/** @format int32 */
	stageType?: number;
	/** 站点代码(日本:JapanStation,韩国:KoreaStation,英国:UkStation) */
	stationCode?: string;
	/** @format date-time */
	stockCompleteTime?: string;
	/** @format int32 */
	stockInNum?: number;
	/** @format int32 */
	stockInStatus?: number;
	/** @format int32 */
	systemSource?: number;
	tbAccount?: string;
	tbOrderNo?: string;
	totalAmount?: number;
	totalFreezeAmount?: number;
	unificationCustomerFullName?: string;
	/** @format date-time */
	updateTime?: string;
	wareCode?: string;
	wareName?: string;
}

/** ListPurchaseOrderReqDTO */
export interface ListPurchaseOrderReqDTO {
	accountType?: string;
	alipayAccount?: string;
	/** @format date-time */
	payTime?: string;
	reconciliationStatusList?: number[];
	tradeNo?: string;
}

/** ListPurchaseOrderRespDTO */
export interface ListPurchaseOrderRespDTO {
	/** @format int32 */
	abnormalStatus?: number;
	/** @format int32 */
	bizType?: number;
	/** @format int32 */
	createNum?: number;
	/** @format date-time */
	createTime?: string;
	/** @format int32 */
	delayDays?: number;
	logisticsNumberList?: OrderExpressRespDTO[];
	nextPurchase?: string;
	/** @format int32 */
	num?: number;
	/** @format int32 */
	pickStatus?: number;
	platformType?: string;
	prePurchase?: string;
	/** @format int32 */
	productTag?: number;
	/** @format date-time */
	purchaseCompleteTime?: string;
	/** @format date-time */
	purchaseCreateTime?: string;
	purchaseFreight?: number;
	/** @format int64 */
	purchaseId?: number;
	/** @format int32 */
	purchaseLevel?: number;
	purchaseMoney?: number;
	purchaseNo?: string;
	/** @format int32 */
	purchaseNum?: number;
	/** @format int32 */
	purchaseStatus?: number;
	purchaseUserId?: string;
	purchaseUserName?: string;
	purchasingAbnormalOrderNo?: string;
	/** @format int32 */
	reconciliationStatus?: number;
	remark?: string;
	shopCode?: string;
	shopName?: string;
	/** @format int32 */
	signNum?: number;
	/** @format date-time */
	splitConfirmTime?: string;
	splitConfirmUserId?: string;
	splitConfirmUserName?: string;
	/** @format date-time */
	splitDate?: string;
	stallFloorNumber?: string;
	stallName?: string;
	stallRoomNumber?: string;
	/** @format date-time */
	stockCompleteTime?: string;
	/** @format int32 */
	stockInNum?: number;
	/** @format int32 */
	stockInStatus?: number;
	/** @format int32 */
	syncStatus?: number;
	/** @format int32 */
	synchronousStatus?: number;
	/** @format int32 */
	systemSource?: number;
	systemSourceName?: string;
	systemTag?: string;
	tbAccount?: string;
	tbOrderNo?: string;
	tradeAccountRespDTOList?: TradeAccountRespDTO[];
	/** @format date-time */
	updateTime?: string;
	/** @format int32 */
	viewStatus?: number;
	wareCode?: string;
	/** @format int32 */
	yinYangTag?: number;
}

/** MerchandiserRelationListReqDTO */
export interface MerchandiserRelationListReqDTO {
	/**
	 * 页码
	 * @format int32
	 */
	pageNum?: number;
	/**
	 * 每页条数
	 * @format int32
	 */
	pageSize?: number;
}

/** MerchandiserRelationLogRespDTO */
export interface MerchandiserRelationLogRespDTO {
	/** @format date-time */
	operateTime?: string;
	operateType?: string;
	operator?: string;
}

/** MerchandiserRelationRespDTO */
export interface MerchandiserRelationRespDTO {
	/** @format int32 */
	bizType?: number;
	/** @format int64 */
	merchandiserRelationId?: number;
	merchandiserUserId?: string;
	merchandiserUserName?: string;
	platformType?: string;
	/** @format int32 */
	systemSource?: number;
	wareCode?: string;
}

/** MerchandiserRelationUpdateReqDTO */
export interface MerchandiserRelationUpdateReqDTO {
	/** @format int64 */
	merchandiserRelationId?: number;
	/** @format int64 */
	merchandiserUserId?: number;
	merchandiserUserName?: string;
	operateType?: string;
	operator?: string;
}

/** ModifyAbnormalImagesReqDTO */
export interface ModifyAbnormalImagesReqDTO {
	flowCode?: string;
	imgs?: string[];
}

/** OemAbnormalGetInfoRespDTO */
export interface OemAbnormalGetInfoRespDTO {
	dealDetails?: OemPurchaseAbnormalOrderItemRespDTO[];
}

/** OemCustomQueryReqDTO */
export interface OemCustomQueryReqDTO {
	productSkuList?: string[];
}

/** OemPaidFactoryExceptionReqDTO */
export interface OemPaidFactoryExceptionReqDTO {
	accountType?: string;
	collectionAccount?: string;
	/** @format int64 */
	oemPurchaseAbnormalOrderId?: number;
	purchaseRemark?: string;
	returnAmount?: number;
	returnApprovalNo?: string;
	/** @format date-time */
	returnPaymentTime?: string;
	returnRemark?: string;
	userId?: string;
	userName?: string;
}

/** OemPaymentListQueryReqDTO */
export interface OemPaymentListQueryReqDTO {
	approvalNo?: string;
	payAccount?: string;
	/** @format date-time */
	payTime?: string;
	reconciliationStatusList?: number[];
}

/** OemPaymentListRespDTO */
export interface OemPaymentListRespDTO {
	accountType?: string;
	approvalNo?: string;
	/** @format int64 */
	oemPurchaseId?: number;
	/** @format int64 */
	oemPurchaseItemId?: number;
	oemPurchaseNo?: string;
	/** @format int64 */
	oemPurchasePaymentId?: number;
	payAccount?: string;
	payAmount?: number;
	/** @format date-time */
	payTime?: string;
}

/** OemPaymentUpdateReqDTO */
export interface OemPaymentUpdateReqDTO {
	/** @format int64 */
	oemPurchasePaymentId?: number;
	/** @format int32 */
	reconciliationStatus?: number;
}

/** OemPurchaseAbnormalOrderItemRespDTO */
export interface OemPurchaseAbnormalOrderItemRespDTO {
	/** @format int32 */
	abnormalNum?: number;
	/** @format int32 */
	accountCheckStatus?: number;
	accountType?: string;
	collectionAccount?: string;
	/** @format date-time */
	completeTime?: string;
	/** @format date-time */
	createTime?: string;
	/** @format int32 */
	deliveryNum?: number;
	expressNoList?: string[];
	flowCode?: string;
	/** @format date-time */
	handleTime?: string;
	/** @format int64 */
	id?: number;
	inBoundOrderNo?: string;
	notReturnReason?: string;
	/** @format int64 */
	oemPurchaseAbnormalOrderId?: number;
	oemPurchasingAbnormalOrderNo?: string;
	/** @format int32 */
	originPurchaseOperateType?: number;
	outBoundOrderNo?: string;
	/** @format int32 */
	purchaseKind?: number;
	/** @format int32 */
	purchaseOperateType?: number;
	purchaseRemark?: string;
	refundContact?: string;
	/** @format int32 */
	refundNum?: number;
	refundPhone?: string;
	reissueLogisticsNo?: string;
	/** @format int32 */
	responsiblePerson?: number;
	returnAddress?: string;
	returnAmount?: number;
	returnApprovalNo?: string;
	/** @format int32 */
	returnExamineStatus?: number;
	/** @format date-time */
	returnExpiryTime?: string;
	/** @format date-time */
	returnPaymentTime?: string;
	returnRemark?: string;
	returnWaybillNo?: string;
	/** @format int32 */
	signNum?: number;
	/** @format int32 */
	status?: number;
	/** @format int32 */
	stockInNum?: number;
	storeRemark?: string;
	/** @format int32 */
	storeReturnStatus?: number;
	/** @format int32 */
	supplierReturnStatus?: number;
	/** @format int32 */
	systemSource?: number;
	tbAccount?: string;
	tbOrderNo?: string;
	/** @format int32 */
	type?: number;
	/** @format date-time */
	updateTime?: string;
	userReturnAmount?: number;
	/** @format int32 */
	userReturnStatus?: number;
}

/** OemPurchaseChangeGoodsReqDTO */
export interface OemPurchaseChangeGoodsReqDTO {
	/** @format int64 */
	abnormalOrderDealId?: number;
	expressNoList?: string[];
	/** @format int64 */
	oemPurchaseAbnormalOrderId?: number;
	purchaseRemark?: string;
	refundContact?: string;
	refundPhone?: string;
	returnAddress?: string;
	/** @format date-time */
	returnExpiryTime?: string;
	userId?: string;
	userName?: string;
}

/** OemPurchaseOrderExpressAddReqDTO */
export interface OemPurchaseOrderExpressAddReqDTO {
	expressNo?: OrderExpressRespDTO[];
	/** @format int64 */
	purchaseId?: number;
	type?: string;
	userId?: string;
	userName?: string;
}

/** OemPurchaseOrderItemRespDTO */
export interface OemPurchaseOrderItemRespDTO {
	actualUnitPrice?: number;
	/** @format int64 */
	changeGoodsNum?: number;
	/** @format int32 */
	checkedNum?: number;
	classificationName?: string;
	/** @format date-time */
	createTime?: string;
	/** @format int32 */
	defectiveNum?: number;
	/** @format int32 */
	maxOverFlowNum?: number;
	/** @format int64 */
	oemProductClassificationId?: number;
	oemProductImg?: string;
	oemProductItemCategoryZh?: string;
	/** @format int64 */
	oemPurchaseId?: number;
	/** @format int64 */
	oemPurchaseItemId?: number;
	oemPurchaseNo?: string;
	/** @format int64 */
	onlyReturnMoneyNum?: number;
	/** @format int64 */
	productCustomsId?: number;
	productImg?: string;
	productItemCategoryZh?: string;
	productName?: string;
	productPrice?: number;
	productProp?: string;
	productSku?: string;
	purchaseAmount?: number;
	/** @format int32 */
	purchaseChannel?: number;
	/** @format int32 */
	purchaseKind?: number;
	/** @format int32 */
	purchaseNum?: number;
	/** @format int32 */
	purchaseStatus?: number;
	/** @format int32 */
	purchaseType?: number;
	purchaseUnitPrice?: number;
	remark?: string;
	/** @format int64 */
	returnAllNum?: number;
	/** @format int64 */
	returnNothingNum?: number;
	/** @format int64 */
	returnNothingSuccessNum?: number;
	/** @format int64 */
	searchSourceOrderId?: number;
	searchSourceOrderNo?: string;
	/** @format int64 */
	searchSourcePriceId?: number;
	/** @format int32 */
	sellQuantity?: number;
	sellUnitPrice?: number;
	/** @format int32 */
	signNum?: number;
	/** @format int32 */
	stockInNum?: number;
	/** @format int32 */
	stockInStatus?: number;
	/** @format date-time */
	updateTime?: string;
}

/** OemPurchaseOrderRespDTO */
export interface OemPurchaseOrderRespDTO {
	customerFreight?: number;
	domesticFreight?: number;
	paymentList?: OemPurchasePaymentRespDTO[];
	/** @format int32 */
	purchaseChannel?: number;
	remark?: string;
	tbAccount?: string;
	tbOrderNo?: string;
}

/** OemPurchasePaymentReqDTO */
export interface OemPurchasePaymentReqDTO {
	accountType?: string;
	approvalNo?: string;
	payAccount?: string;
	payAmount?: number;
	/** @format date-time */
	payTime?: string;
	/**
	 * 付款渠道：1-线下财务打款、2-线上采购支付
	 * @format int32
	 */
	paymentChannel?: number;
	/**
	 * 付款方式：1-对公支付、2-对私支付
	 * @format int32
	 */
	paymentMethod?: number;
	/**
	 * 出货方式：1-9610、2-非9610
	 * @format int32
	 */
	shippingMethod?: number;
}

/** OemPurchasePaymentRespDTO */
export interface OemPurchasePaymentRespDTO {
	accountType?: string;
	approvalNo?: string;
	/** @format date-time */
	createTime?: string;
	/** @format int64 */
	oemPurchaseId?: number;
	/** @format int64 */
	oemPurchaseItemId?: number;
	oemPurchaseNo?: string;
	/** @format int64 */
	oemPurchasePaymentId?: number;
	payAccount?: string;
	payAmount?: number;
	/** @format date-time */
	payTime?: string;
	/**
	 * 付款渠道：1-线下财务打款、2-线上采购支付
	 * @format int32
	 */
	paymentChannel?: number;
	/**
	 * 付款方式：1-对公支付、2-对私支付
	 * @format int32
	 */
	paymentMethod?: number;
	/**
	 * 出货方式：1-9610、2-非9610
	 * @format int32
	 */
	shippingMethod?: number;
	/** @format date-time */
	updateTime?: string;
}

/** OemReissueReqDTO */
export interface OemReissueReqDTO {
	/** @format int64 */
	abnormalOrderDealId?: number;
	/** @format int64 */
	oemPurchaseAbnormalOrderId?: number;
	purchaseRemark?: string;
	reissueLogisticsNo?: string;
	userId?: string;
	userName?: string;
}

/** OemReturnGoodsAndMoneyReqDTO */
export interface OemReturnGoodsAndMoneyReqDTO {
	/** @format int64 */
	abnormalOrderDealId?: number;
	accountType?: string;
	collectionAccount?: string;
	/** @format int64 */
	oemPurchaseAbnormalOrderId?: number;
	purchaseRemark?: string;
	refundContact?: string;
	refundPhone?: string;
	returnAddress?: string;
	returnAmount?: number;
	returnApprovalNo?: string;
	/** @format date-time */
	returnExpiryTime?: string;
	/** @format date-time */
	returnPaymentTime?: string;
	userId?: string;
	userName?: string;
}

/** OemReturnGoodsReqDTO */
export interface OemReturnGoodsReqDTO {
	/** @format int64 */
	abnormalOrderDealId?: number;
	/** @format int32 */
	applyNum?: number;
	/** @format int64 */
	oemPurchaseAbnormalOrderId?: number;
	purchaseRemark?: string;
	refundContact?: string;
	refundPhone?: string;
	returnAddress?: string;
	/** @format date-time */
	returnExpiryTime?: string;
	userId?: string;
	userName?: string;
}

/** OemReturnMoneyReqDTO */
export interface OemReturnMoneyReqDTO {
	/** @format int64 */
	abnormalOrderDealId?: number;
	accountType?: string;
	collectionAccount?: string;
	/** @format int64 */
	oemPurchaseAbnormalOrderId?: number;
	purchaseRemark?: string;
	returnAmount?: number;
	returnApprovalNo?: string;
	/** @format date-time */
	returnPaymentTime?: string;
	userId?: string;
	userName?: string;
}

/** OemSupplierReturnListQueryReqDTO */
export interface OemSupplierReturnListQueryReqDTO {
	collectionAccount?: string;
	returnAmount?: number;
	/** @format date-time */
	returnPaymentTime?: string;
}

/** OemSupplierReturnListRespDTO */
export interface OemSupplierReturnListRespDTO {
	collectionAccount?: string;
	/** @format int64 */
	oemPurchaseAbnormalOrderId?: number;
	/** @format int64 */
	oemPurchaseId?: number;
	/** @format int64 */
	oemPurchaseItemId?: number;
	oemPurchaseNo?: string;
	oemPurchasingAbnormalOrderNo?: string;
	platformOrderNo?: string;
	returnAmount?: number;
	/** @format date-time */
	returnPaymentTime?: string;
}

/** OemToCompanyInventoryReqDTO */
export interface OemToCompanyInventoryReqDTO {
	/** @format int32 */
	applyNum?: number;
	/** @format int64 */
	oemPurchaseAbnormalOrderId?: number;
	purchaseRemark?: string;
	userId?: string;
	userName?: string;
}

/** OemUnallowedReturnReqDTO */
export interface OemUnallowedReturnReqDTO {
	/** @format int64 */
	abnormalOrderDealId?: number;
	/** @format int64 */
	oemPurchaseAbnormalOrderId?: number;
	purchaseRemark?: string;
	/** @format int32 */
	responsiblePerson?: number;
	userId?: string;
	userName?: string;
	userReturnAmount?: number;
}

/** OemUnpaidFactoryExceptionReqDTO */
export interface OemUnpaidFactoryExceptionReqDTO {
	/** @format int64 */
	oemPurchaseAbnormalOrderId?: number;
	returnRemark?: string;
	userId?: string;
	userName?: string;
}

/** OfflineSettlementOrderRespDTO */
export interface OfflineSettlementOrderRespDTO {
	/** 对账流水号 */
	accountCheckBizId?: string;
	/** 支付账户类型；1：支付宝；2：跨境宝 */
	accountType?: string;
	/** 支付账号 */
	alipayAccount?: string;
	/** 对账结算明细列表 */
	details?: StallSettlementOrderRespDTO[];
	/**
	 * 实际支付时间
	 * @format date-time
	 */
	payTime?: string;
	/** 操作员姓名 */
	purchaseUserName?: string;
	/** 结算金额 */
	settleAmount?: number;
	/** 交易流水号 */
	tradeNo?: string;
}

/** OnlinePurchaseItemEditDTO */
export interface OnlinePurchaseItemEditDTO {
	barginPriceContext?: OnlinePurchaseItemPriceContextDTO;
	errorMsg?: string;
	payAblePriceContext?: OnlinePurchaseItemPriceContextDTO;
	priceContext?: OnlinePurchaseItemPriceContextDTO;
	/** @format int64 */
	purchaseItemId?: number;
}

/** OnlinePurchaseItemEditReqDTO */
export interface OnlinePurchaseItemEditReqDTO {
	itemCategory?: string;
	manageUserId?: string;
	manageUsername?: string;
	/** @format int64 */
	purchaseItemId?: number;
	/** @format int32 */
	purchaseNum?: number;
	remark?: string;
}

/** OnlinePurchaseItemPriceContextDTO */
export interface OnlinePurchaseItemPriceContextDTO {
	price?: number;
	/** @format int32 */
	quantity?: number;
}

/** OnlinePurchaseOrderEditByRpaDTO */
export interface OnlinePurchaseOrderEditByRpaDTO {
	accountType?: string;
	actualDiscountMoney?: number;
	alipayAccount?: string;
	bargainPurchaseFreight?: number;
	bargainPurchaseMoney?: number;
	/** @format int32 */
	createOrderStatus?: number;
	errorMsg?: string;
	onlinePurchaseItemEditDTOS?: OnlinePurchaseItemEditDTO[];
	/** @format date-time */
	orderTime?: string;
	payStatus?: boolean;
	/** @format date-time */
	payTime?: string;
	payablePurchaseFreight?: number;
	payablePurchaseMoney?: number;
	purchaseFreight?: number;
	/** @format int64 */
	purchaseId?: number;
	purchaseMoney?: number;
	tbAccount?: string;
	tbOrderNo?: string;
}

/** OnlinePurchaseOrderEditReqDTO */
export interface OnlinePurchaseOrderEditReqDTO {
	accountType?: string;
	alipayAccount?: string;
	expressNoList?: OrderExpressRespDTO[];
	manageUserId?: string;
	manageUsername?: string;
	/** @format date-time */
	payTime?: string;
	purchaseFreight?: number;
	/** @format int64 */
	purchaseId?: number;
	purchaseMoney?: number;
	tbAccount?: string;
	tbOrderNo?: string;
}

/** OnlyRefundGoodsReqDTO */
export interface OnlyRefundGoodsReqDTO {
	address?: string;
	refundContact?: string;
	refundPhone?: string;
	remark?: string;
	/** @format date-time */
	returnDeadLine?: string;
}

/** OrderExpressRespDTO */
export interface OrderExpressRespDTO {
	/** @format date-time */
	createTime?: string;
	logisticsNumber?: string;
	logisticsTag?: string;
	/** @format date-time */
	purchaseEstimateStockTime?: string;
	/** @format date-time */
	signTime?: string;
	/** @format date-time */
	systemEstimateStockTime?: string;
	virtualLogisticsNumber?: string;
}

/** OrderItem */
export interface OrderItem {
	asc?: boolean;
	column?: string;
}

/** OrderItemRelationRespDTO */
export interface OrderItemRelationRespDTO {
	/** @format int64 */
	customerShopId?: number;
	customerShopName?: string;
	/** @format int32 */
	num?: number;
	/** @format int64 */
	orderId?: number;
	/** @format int64 */
	orderItemId?: number;
	/** @format int32 */
	orderType?: number;
	/** @format int64 */
	platformOrderId?: number;
	platformOrderNo?: string;
	productSku?: string;
	/** @format int32 */
	productType?: number;
	purchaseMoney?: number;
	/** @format int64 */
	purchaseOrderId?: number;
	/** @format int64 */
	purchaseOrderItemId?: number;
	/** @format int64 */
	purchaseOrderItemRelationId?: number;
	purchaseUnitPrice?: number;
	/** @format int32 */
	saleAmount?: number;
}

/** OrderItemStatusInfoDTO */
export interface OrderItemStatusInfoDTO {
	orderItemStatusList?: OrderItemStatusListDTO[];
	/** @format int32 */
	orderQuantity?: number;
	systemOrderNo?: string;
}

/** OrderItemStatusListDTO */
export interface OrderItemStatusListDTO {
	/** @format int32 */
	commodityQuantity?: number;
	/** @format int32 */
	orderItemStatus?: number;
}

/** PackageLogisticsDTO */
export interface PackageLogisticsDTO {
	estimateStockTime?: string;
	kuaiDiTrackItemList?: KuaiDiTrackItemRespDTO[];
	logisticsNumber?: string;
	logisticsTag?: string;
	/** @format int32 */
	signStatus?: number;
}

/** PageOemPurchaseAbnormalQuery */
export interface PageOemPurchaseAbnormalQuery {
	boundNo?: string;
	/** 客户标签 1 oem项目重点客户 2 oem非项目重点客户 */
	customerTags?: number[];
	impCustomers?: number[];
	/** @format int64 */
	oemProductClassificationId?: number;
	oemPurchaseNo?: string;
	oemPurchasingAbnormalOrderNo?: string;
	originatorName?: string;
	/** @format int32 */
	pageNum?: number;
	/** @format int32 */
	pageSize?: number;
	platformOrderNo?: string;
	productSku?: string;
	/** @format int32 */
	purchaseKind?: number;
	purchaseUserName?: string;
	returnWaybillNo?: string;
	/** @format int32 */
	startIndex?: number;
	/** 站点代码(日本:JapanStation,韩国:KoreaStation,英国:UkStation) */
	stationCode?: string;
	/** @format int32 */
	status?: number;
	/** @format int32 */
	storeReturnStatus?: number;
	/** @format int32 */
	systemSource?: number;
}

/** PageOemPurchaseOrderQuery */
export interface PageOemPurchaseOrderQuery {
	customerShopName?: string;
	/** 客户标签 1 oem项目重点客户 2 oem非项目重点客户 */
	customerTags?: number[];
	/** @format date-time */
	dateTimeEnd?: string;
	/** @format date-time */
	dateTimeStart?: string;
	impCustomers?: number[];
	logisticsNumber?: string;
	/** @format int64 */
	oemProductClassificationId?: number;
	oemPurchaseNo?: string;
	/** @format int32 */
	pageNum?: number;
	/** @format int32 */
	pageSize?: number;
	platformOrderNo?: string;
	productSku?: string;
	/** @format date-time */
	purchaseDateTimeEnd?: string;
	/** @format date-time */
	purchaseDateTimeStart?: string;
	purchaseIdList?: number[];
	/** @format int32 */
	purchaseKind?: number;
	/** @format int32 */
	purchaseStatus?: number;
	/** @format int32 */
	purchaseType?: number;
	purchaseUserName?: string;
	searchSourceOrderNo?: string;
	/** @format date-time */
	signTimeDateTimeEnd?: string;
	/** @format date-time */
	signTimeDateTimeStart?: string;
	/** @format int32 */
	startIndex?: number;
	/** 站点代码(日本:JapanStation,韩国:KoreaStation,英国:UkStation) */
	stationCode?: string;
	/** @format int32 */
	stockInStatus?: number;
	/** @format date-time */
	systemDateTimeEnd?: string;
	/** @format date-time */
	systemDateTimeStart?: string;
	/** @format int32 */
	systemSource?: number;
	tag?: string;
	/** @format int32 */
	tagType?: number;
	/** @format int32 */
	timeType?: number;
}

/** PaymentRequestQuery */
export interface PaymentRequestQuery {
	/** 申请人 */
	applicantUserId?: string;
	/**
	 * 申请时间-结束时间
	 * @format date-time
	 */
	dateTimeEnd?: string;
	/**
	 * 申请时间-开始时间
	 * @format date-time
	 */
	dateTimeStart?: string;
	/** OA审批单号 */
	instanceId?: string;
	/**
	 * 采购订单ID
	 * @format int64
	 */
	oemPurchaseId?: number;
	/** @format int32 */
	pageNum?: number;
	/** @format int32 */
	pageSize?: number;
	/** 付款单号 */
	paymentRequestNo?: string;
	/** 付款类型 */
	paymentType?: string;
	/** 采购单号 */
	purchaseOrderNo?: string;
	/** 采购员 */
	purchaserUserId?: string;
	/** @format int32 */
	startIndex?: number;
	/**
	 * 状态: 1 待审核 2 已完成 3已拒绝
	 * @format int32
	 */
	status?: number;
	/** 收款供应商 */
	supplierName?: string;
}

/** PaymentRequestReqDTO */
export interface PaymentRequestReqDTO {
	/** 申请人ID */
	applicantUserId?: string;
	/** 是否议价（枚举，是或否） */
	bargaining?: string;
	/** 议价时间 */
	bargainingTime?: string;
	/** 支行信息 */
	branchInformation?: string;
	/** 对应业务担当 */
	businessRepresentativeId?: string;
	/**
	 * 完成时间
	 * @format date-time
	 */
	completeTime?: string;
	/** 对应客户总监 */
	customerDirectorId?: string;
	/** 客户ID */
	customerId?: string;
	/** 客户名称 */
	customerName?: string;
	/**
	 * 时长（天）
	 * @format int32
	 */
	durationDay?: number;
	/** 期望付款时间 */
	expectedPaymentDate?: string;
	/** 首单付款审批提出时间 */
	firstPayApplyTime?: string;
	/**
	 * 主键ID
	 * @format int64
	 */
	id?: number;
	/** 图片,最多6张 */
	images?: string;
	/** 议价总金额 */
	negotiatedAmount?: number;
	/**
	 * 采购订单ID
	 * @format int64
	 */
	oemPurchaseId?: number;
	/** 订单来源:self=自主寻源/other=其他同事交接 */
	orderSource?: string;
	/** 订单标题 */
	orderTitle?: string;
	/** 订单类型:clothing=服饰类/grocery=杂货类/accessories=辅料类 */
	orderType?: string;
	/** 收款人姓名 */
	payeeName?: string;
	/** 付款操作人ID */
	payerId?: string;
	/** 付款操作人姓名 */
	payerName?: string;
	/** 付款账户: */
	paymentAccountNo?: string;
	/** 付款渠道:offline=线下财务打款/online=线上采购支付 */
	paymentChannel?: string;
	/** 付款方式:corporate=对公/person=对私/other=其他 */
	paymentMethod?: string;
	/** 支付单号 */
	paymentRequestNo?: string;
	/** 付款类型:first=首款/mid=中款/last=尾款/full=全款/supplementary=补款 */
	paymentType?: string;
	/** 本次付款所占总额的比例 */
	proportionOfTotal?: number;
	/** 采购订单号 */
	purchaseOrderNo?: string;
	/** 订单复购情况:first=首单/repurchase=复购单 */
	purchaseStatus?: string;
	/** 对应采购员 */
	purchaserUserId?: string;
	/** 对应采购经理 */
	purchasingManagerId?: string;
	/** 收款帐号 */
	receivingAccountNo?: string;
	/** 收款渠道 */
	receivingChannel?: string;
	/** 备注 */
	remark?: string;
	/**
	 * 第几次复购
	 * @format int32
	 */
	repurchasesNum?: number;
	/** 申请金额 */
	requestAmount?: number;
	/**
	 * 申请时间
	 * @format date-time
	 */
	requestTime?: string;
	/** 卖家承诺发货日 */
	sellerPromisedDeliveryDate?: string;
	/** 出货方式:small=9610(小件)/other=非9610 */
	shippingType?: string;
	/**
	 * 状态: 1 待审核 2 已完成 3已拒绝
	 * @format int32
	 */
	status?: number;
	/** 收款供应商名称 */
	supplierName?: string;
	/** 本次付款审批提出时间 */
	thisTimePayApplyTime?: string;
	/** 订单总金额 */
	totalAmount?: number;
	userId?: string;
	userName?: string;
}

/** PaymentRequestRespDTO */
export interface PaymentRequestRespDTO {
	/** 申请人ID */
	applicantUserId?: string;
	/** 是否议价（枚举，是或否） */
	bargaining?: string;
	/** 议价时间 */
	bargainingTime?: string;
	/** 支行信息 */
	branchInformation?: string;
	/** 对应业务担当 */
	businessRepresentativeId?: string;
	/**
	 * 完成时间
	 * @format date-time
	 */
	completeTime?: string;
	/** 对应客户总监 */
	customerDirectorId?: string;
	/** 客户ID */
	customerId?: string;
	/** 客户名称 */
	customerName?: string;
	/**
	 * 时长（天）
	 * @format int32
	 */
	durationDay?: number;
	/** 期望付款时间 */
	expectedPaymentDate?: string;
	/** 首单付款审批提出时间 */
	firstPayApplyTime?: string;
	/**
	 * 主键
	 * @format int64
	 */
	id?: number;
	/** 图片,最多6张 */
	imageList?: string[];
	/** 图片,最多6张,逗号分隔 */
	images?: string;
	/** OA审批单号 */
	instanceId?: string;
	/** 议价总金额 */
	negotiatedAmount?: number;
	/**
	 * 采购订单ID
	 * @format int64
	 */
	oemPurchaseId?: number;
	/** 订单来源:self=自主寻源/other=其他同事交接 */
	orderSource?: string;
	orderSourceDesc?: string;
	/** 订单标题 */
	orderTitle?: string;
	/** 订单类型:clothing=服饰类/grocery=杂货类/accessories=辅料类 */
	orderType?: string;
	orderTypeDesc?: string;
	/** 收款人姓名 */
	payeeName?: string;
	/** 付款操作人ID */
	payerId?: string;
	/** 付款操作人姓名 */
	payerName?: string;
	/** 付款账户: */
	paymentAccountNo?: string;
	/** 付款渠道:offline=线下财务打款/online=线上采购支付 */
	paymentChannel?: string;
	/** 付款方式:corporate=对公/person=对私/other=其他 */
	paymentMethod?: string;
	paymentMethodDesc?: string;
	/** 支付单号 */
	paymentRequestNo?: string;
	/** 付款类型:first=首款/mid=中款/last=尾款/full=全款/supplementary=补款 */
	paymentType?: string;
	paymentTypeDesc?: string;
	/** 本次付款所占总额的比例 */
	proportionOfTotal?: number;
	/** 采购订单号 */
	purchaseOrderNo?: string;
	/** 订单复购情况:first=首单/repurchase=复购单 */
	purchaseStatus?: string;
	purchaseStatusDesc?: string;
	/** 对应采购员 */
	purchaserUserId?: string;
	/** 对应采购经理 */
	purchasingManagerId?: string;
	/** 收款帐号 */
	receivingAccountNo?: string;
	/** 收款渠道 */
	receivingChannel?: string;
	/** 备注 */
	remark?: string;
	/**
	 * 第几次复购
	 * @format int32
	 */
	repurchasesNum?: number;
	/** 申请金额 */
	requestAmount?: number;
	/**
	 * 申请时间
	 * @format date-time
	 */
	requestTime?: string;
	/** 卖家承诺发货日 */
	sellerPromisedDeliveryDate?: string;
	/** 出货方式:small=9610(小件)/other=非9610 */
	shippingType?: string;
	shippingTypeDesc?: string;
	/**
	 * 状态: 1 待审核 2 已完成 3已拒绝
	 * @format int32
	 */
	status?: number;
	statusDesc?: string;
	/** 收款供应商名称 */
	supplierName?: string;
	/** 本次付款审批提出时间 */
	thisTimePayApplyTime?: string;
	/** 订单总金额 */
	totalAmount?: number;
	userName?: string;
}

/** ProductCustomsRespDTO */
export interface ProductCustomsRespDTO {
	compilationMethod?: string;
	customsCode?: string;
	customsCodeEn?: string;
	customsCodeKr?: string;
	customsPriceJp?: number;
	/** @format int64 */
	productCustomsId?: number;
	productItemCategoryEn?: string;
	productItemCategoryZh?: string;
	simpleReportCode?: string;
	stationCode?: string;
}

/** ProductImageUrlDTO */
export interface ProductImageUrlDTO {
	url?: string;
}

/** ProductPriceLadderRespDTO */
export interface ProductPriceLadderRespDTO {
	appPrice?: number;
	/** @format int32 */
	count?: number;
	discount?: number;
	originPrice?: number;
	price?: number;
	purchasePrice?: number;
}

/** ProductPropGroupRespDTO */
export interface ProductPropGroupRespDTO {
	id?: string;
	props?: ProductPropRespDTO[];
	type?: string;
}

/** ProductPropRespDTO */
export interface ProductPropRespDTO {
	id?: string;
	propKey?: string;
	propValue?: string;
	props?: ProductPropRespDTO[];
	/** @format int64 */
	sort?: number;
	type?: string;
	url?: string;
}

/** ProductResourceRespDTO */
export interface ProductResourceRespDTO {
	descImgs?: string[];
	itemImgs?: string[];
	mainImg?: string;
	propImgs?: string;
	video?: string;
}

/** ProductTransportationAttrDTO */
export interface ProductTransportationAttrDTO {
	code?: string;
	name?: string;
	nameEn?: string;
	nameJp?: string;
	nameKr?: string;
}

/** PurchaseAbnormalApplyReqDTO */
export interface PurchaseAbnormalApplyReqDTO {
	/** @format int32 */
	abnormalDetailType?: number;
	manageUserId?: string;
	manageUsername?: string;
	/** @format int32 */
	miniNumOrderQuantity?: number;
	/** @format int32 */
	num?: number;
	/** @format int64 */
	purchaseId?: number;
	/** @format int64 */
	purchaseItemId?: number;
	/** @format int64 */
	purchaseOrderItemRelationId?: number;
	purchaseUserProductLink?: string;
	purchaseUserProductSku?: string;
	remark?: string;
	skuRespDTO?: PurchaseSkuRespDTO;
	/** @format int32 */
	type?: number;
	userExpected?: boolean;
}

/** PurchaseAbnormalItemDetailDTO */
export interface PurchaseAbnormalItemDetailDTO {
	/** @format date-time */
	createTime?: string;
	/** @format int32 */
	dealType?: number;
	/** @format int32 */
	num?: number;
	/** @format int64 */
	platformOrderItemId?: number;
	platformOrderNo?: string;
	productCode?: string;
	productImg?: string;
	productLink?: string;
	productName?: string;
	productProp?: string;
	productSku?: string;
	/** @format int64 */
	purchaseAbnormalOrderId?: number;
	/** @format date-time */
	updateTime?: string;
}

/** PurchaseAbnormalMqDTO */
export interface PurchaseAbnormalMqDTO {
	abnormalDetailMessage?: string[];
	abnormalDetailMessageJp?: number[];
	applyUserId?: string;
	applyUserName?: string;
	applyUserPhone?: string;
	expressNo?: string;
	flowCode?: string;
	imgs?: string[];
	messageId?: string;
	/** @format int32 */
	num?: number;
	orderCode?: string;
	/** @format int32 */
	pkgType?: number;
	productSku?: string;
	/** @format int32 */
	purchaseAbnormalType?: number;
	purchaseNo?: string;
	remark?: string;
	userId?: string;
	userName?: string;
}

/** PurchaseAbnormalNumRespDTO */
export interface PurchaseAbnormalNumRespDTO {
	/** @format int64 */
	num?: number;
	platformOrderNo?: string;
}

/** PurchaseAbnormalOrderExtendRespDTO */
export interface PurchaseAbnormalOrderExtendRespDTO {
	actualUnitPrice?: number;
	/** @format int32 */
	bizType?: number;
	/** @format date-time */
	createTime?: string;
	itemCategory?: string;
	platformType?: string;
	/** @format int64 */
	purchaseAbnormalOrderExtendId?: number;
	/** @format int64 */
	purchaseAbnormalOrderId?: number;
	purchasingAbnormalOrderNo?: string;
	shopCode?: string;
	shopName?: string;
	stallCodeNo?: string;
	stallFloorNumber?: string;
	stallName?: string;
	stallPrice?: number;
	stallRoomNumber?: string;
	/** @format date-time */
	updateTime?: string;
}

/** PurchaseAbnormalOrderItemRespDTO */
export interface PurchaseAbnormalOrderItemRespDTO {
	chooseProductCode?: string;
	chooseProductImg?: string;
	chooseProductLink?: string;
	chooseProductName?: string;
	chooseProductProp?: string;
	chooseProductSku?: string;
	/** @format int32 */
	combinationProductItemFlag?: number;
	/** @format date-time */
	createTime?: string;
	customerProductSku?: string;
	imageList?: string[];
	/** @format int32 */
	num?: number;
	orderNo?: string;
	/** @format int32 */
	orderType?: number;
	originalProductSku?: string;
	productCode?: string;
	productImg?: string;
	productLink?: string;
	productName?: string;
	productProp?: string;
	productSku?: string;
	/** @format int32 */
	productType?: number;
	/** @format int64 */
	purchaseAbnormalOrderId?: number;
	/** @format int64 */
	purchaseAbnormalOrderItemId?: number;
	/** @format int64 */
	purchaseOrderItemRelationAbnormalId?: number;
	/** @format int64 */
	purchaseOrderItemRelationId?: number;
	purchaseProductCode?: string;
	purchaseProductImg?: string;
	purchaseProductLink?: string;
	purchaseProductName?: string;
	purchaseProductProp?: string;
	purchaseProductSku?: string;
	purchasingAbnormalOrderNo?: string;
	/** @format date-time */
	updateTime?: string;
	/** @format int32 */
	userChooseNum?: number;
}

/** PurchaseAbnormalOrderRespDTO */
export interface PurchaseAbnormalOrderRespDTO {
	abnormalDetailMessage?: string;
	abnormalDetailMessageJp?: string;
	abnormalDetailRemark?: string;
	/** @format int32 */
	abnormalDetailType?: number;
	accountCheckFailMessage?: string;
	/** @format int32 */
	accountCheckStatus?: number;
	applyUserId?: string;
	applyUserName?: string;
	applyUserPhone?: string;
	/** @format int32 */
	bizType?: number;
	/** @format date-time */
	completeTime?: string;
	/** @format date-time */
	createTime?: string;
	/** @format int64 */
	customerId?: number;
	customerName?: string;
	customerOperateMessage?: string;
	/** @format int32 */
	customerOperateStatus?: number;
	/** @format int32 */
	customerOperateType?: number;
	customerProductLink?: string;
	customerProductSku?: string;
	/** @format int64 */
	customerShopId?: number;
	customerShopName?: string;
	customerTags?: number[];
	finalAbnormalDetailMessage?: string;
	finalAbnormalDetailMessageJp?: string;
	/** @format int32 */
	finalAbnormalDetailType?: number;
	/** @format int32 */
	finalType?: number;
	flowCode?: string;
	/** @format int64 */
	orderId?: number;
	orderNo?: string;
	/** @format int32 */
	pkgType?: number;
	/** @format int64 */
	platformOrderId?: number;
	platformOrderNo?: string;
	platformType?: string;
	/** @format date-time */
	processingTime?: string;
	productSku?: string;
	/** @format int64 */
	purchaseAbnormalOrderId?: number;
	/** @format int64 */
	purchaseAbnormalRefundOrderId?: number;
	/** @format int32 */
	purchaseCreateStatus?: number;
	/** @format int64 */
	purchaseId?: number;
	purchaseManageUserId?: string;
	purchaseManageUserName?: string;
	purchaseOperateDetailMessage?: string;
	/** @format int32 */
	purchaseOperateDetailType?: number;
	purchaseOperateMessage?: string;
	/** @format int32 */
	purchaseOperateStatus?: number;
	/** @format int32 */
	purchaseOperateType?: number;
	purchaseOrderNo?: string;
	/** @format int64 */
	purchaseOriginalId?: number;
	purchaseOriginalOrderNo?: string;
	/** @format int32 */
	purchaseRemakeStatus?: number;
	/** @format int32 */
	purchaseStatus?: number;
	purchaseUserId?: string;
	purchaseUserName?: string;
	purchaseUserProductLink?: string;
	purchaseUserProductSku?: string;
	purchasingAbnormalOrderNo?: string;
	purchasingAbnormalRemark?: string;
	/** @format int32 */
	refundType?: number;
	/** @format date-time */
	refuseTime?: string;
	remark?: string;
	/** @format int32 */
	rollBackStatus?: number;
	/** @format int32 */
	rpaTag?: number;
	stationCode?: string;
	/** @format int32 */
	status?: number;
	/** @format int32 */
	systemSource?: number;
	/** @format int32 */
	type?: number;
	/** @format date-time */
	updateTime?: string;
	/** @format int32 */
	userExpected?: number;
	userName?: string;
	/** @format int32 */
	userRefundStatus?: number;
	/** @format int32 */
	userViewStatus?: number;
	wmsExpressNo?: string;
}

/** PurchaseAbnormalOrderUpdateReqDTO */
export interface PurchaseAbnormalOrderUpdateReqDTO {
	/** @format int64 */
	purchaseAbnormalOrderId?: number;
	purchasingAbnormalRemark?: string;
}

/** PurchaseAbnormalQuery */
export interface PurchaseAbnormalQuery {
	/** @format int32 */
	accountCheckStatus?: number;
	applyUserPhone?: string;
	/** @format int32 */
	bizType?: number;
	/** @format date-time */
	createTimeEnd?: string;
	/** @format date-time */
	createTimeStart?: string;
	/** @format int32 */
	customerOperateStatus?: number;
	customerOrderNo?: string;
	customerOrderNoList?: string[];
	/** @format int64 */
	customerShopId?: number;
	/** 客户标签 1 oem项目重点客户 2 oem非项目重点客户 */
	customerTags?: number[];
	/** @format date-time */
	dateTimeEnd?: string;
	/** @format date-time */
	dateTimeStart?: string;
	impCustomers?: number[];
	managerUserId?: string;
	orderName?: string;
	orderNameList?: string[];
	orderNo?: string;
	/** @format int32 */
	pageNum?: number;
	/** @format int32 */
	pageSize?: number;
	platformOrderNo?: string;
	platformOrderNoList?: string[];
	platformType?: string;
	/** @format int32 */
	processTimeIsNullTag?: number;
	productSku?: string;
	productSkuList?: string[];
	/** @format int64 */
	purchaseAbnormalOrderId?: number;
	/** @format int32 */
	purchaseOperateDetailType?: number;
	/** @format int32 */
	purchaseOperateType?: number;
	purchaseOriginalOrderNo?: string;
	purchaseUserId?: string;
	purchaseUserName?: string;
	/** @format int32 */
	purchaseViewStatus?: number;
	purchasingAbnormalOrderNo?: string;
	purchasingAbnormalRemark?: string;
	/** @format int32 */
	refundType?: number;
	/** @format int32 */
	rpaTag?: number;
	/** @format int32 */
	startIndex?: number;
	/** @format int32 */
	status?: number;
	/** @format int32 */
	systemSource?: number;
	/** @format int32 */
	timeType?: number;
	/** @format int32 */
	type?: number;
	userName?: string;
	/** @format int32 */
	userViewStatus?: number;
}

/** PurchaseAbnormalRefundCallBackReqDTO */
export interface PurchaseAbnormalRefundCallBackReqDTO {
	/** @format int64 */
	abnormalOrderDealId?: number;
	productType?: string;
	purchasingAbnormalOrderNo?: string;
	/** @format int32 */
	state?: number;
	/** @format int32 */
	status?: number;
}

/** PurchaseAbnormalRefundOrderRespDTO */
export interface PurchaseAbnormalRefundOrderRespDTO {
	accountType?: string;
	alipayAccount?: string;
	/** @format date-time */
	createTime?: string;
	customerRefundRejectReason?: string;
	/** @format int32 */
	customerRefundStatus?: number;
	money?: number;
	/** @format int64 */
	purchaseAbnormalOrderId?: number;
	/** @format int64 */
	purchaseAbnormalRefundOrderId?: number;
	purchasingAbnormalOrderNo?: string;
	rejectReason?: string;
	tbAccount?: string;
	tbOrderNo?: string;
	/** @format date-time */
	updateTime?: string;
}

/** PurchaseAbnormalRemakeReqDTO */
export interface PurchaseAbnormalRemakeReqDTO {
	actualUnitPrice?: number;
	/** @format int64 */
	platformOrderItemId?: number;
	/** @format int32 */
	productTag?: number;
	purchasingAbnormalOrderNo?: string;
}

/** PurchaseAbnormalRespDTO */
export interface PurchaseAbnormalRespDTO {
	extendPurchaseAbnormalOrderRespDTO?: ExtendPurchaseAbnormalOrderRespDTO;
	itemDetails?: PurchaseAbnormalItemDetailDTO[];
	purchaseAbnormalOrderExtendRespDTO?: PurchaseAbnormalOrderExtendRespDTO;
	purchaseAbnormalOrderItemRespDTO?: PurchaseAbnormalOrderItemRespDTO;
	purchaseAbnormalOrderRespDTO?: PurchaseAbnormalOrderRespDTO;
	purchaseAbnormalWmsRefundOrderReqDTO?: PurchaseAbnormalWmsRefundOrderReqDTO;
	purchaseOrderRespDTO?: PurchaseOrderRespDTO;
	refundOrderRespDTO?: PurchaseAbnormalRefundOrderRespDTO;
	shopPurchaseRespDTO?: ShopPurchaseDTO;
	supplierRefundOrderRespDTO?: PurchaseAbnormalSupplierRefundOrderRespDTO;
}

/** PurchaseAbnormalSupplierRefundCallBackReqDTO */
export interface PurchaseAbnormalSupplierRefundCallBackReqDTO {
	/** @format int64 */
	abnormalOrderDealId?: number;
	bizType?: string;
	meesage?: string;
	purchasingAbnormalOrderNo?: string;
	/** @format date-time */
	refuseTime?: string;
	/** @format int32 */
	status?: number;
}

/** PurchaseAbnormalSupplierRefundOrderRespDTO */
export interface PurchaseAbnormalSupplierRefundOrderRespDTO {
	accountType?: string;
	alipayAccount?: string;
	/** @format date-time */
	createTime?: string;
	/** @format int64 */
	purchaseAbnormalOrderId?: number;
	/** @format int64 */
	purchaseAbnormalRefundOrderId?: number;
	purchasingAbnormalOrderNo?: string;
	receiveMoney?: number;
	/** @format date-time */
	receiveMoneyTime?: string;
	supplierOrderNo?: string;
	tradeNo?: string;
	/** @format date-time */
	updateTime?: string;
}

/** PurchaseAbnormalWmsRefundOrderReqDTO */
export interface PurchaseAbnormalWmsRefundOrderReqDTO {
	address?: string;
	associatePurchaseOrderNo?: string;
	/** @format int32 */
	canEditStatus?: number;
	/** @format date-time */
	createTime?: string;
	expressNo?: string;
	/** @format int64 */
	purchaseAbnormalOrderId?: number;
	/** @format int64 */
	purchaseAbnormalWmsRefundOrderId?: number;
	purchasingAbnormalOrderNo?: string;
	reSupplyExpressNo?: string;
	refundContact?: string;
	refundPhone?: string;
	remark?: string;
	/** @format date-time */
	returnDeadLine?: string;
	/** @format date-time */
	updateTime?: string;
}

/** PurchaseAssociateReqDTO */
export interface PurchaseAssociateReqDTO {
	associatePurchaseOrderNo?: string;
	remark?: string;
}

/** PurchaseDiscardReqDTO */
export interface PurchaseDiscardReqDTO {
	remark?: string;
}

/** PurchaseExpressRespDTO */
export interface PurchaseExpressRespDTO {
	expressNo?: OrderExpressRespDTO[];
}

/** PurchaseItemUnitPriceDTO */
export interface PurchaseItemUnitPriceDTO {
	payablePurchaseUnitPrice?: number;
	purchaseUnitPrice?: number;
}

/** PurchaseLogQuery */
export interface PurchaseLogQuery {
	operateUserId?: string;
	operateUserName?: string;
	/** @format int32 */
	pageNum?: number;
	/** @format int32 */
	pageSize?: number;
	/** @format int32 */
	startIndex?: number;
}

/** PurchaseLogRespDTO */
export interface PurchaseLogRespDTO {
	/** @format int64 */
	costTime?: number;
	/** @format date-time */
	createTime?: string;
	failMsg?: string;
	operateDescription?: string;
	operateType?: string;
	operateUserId?: string;
	operateUserName?: string;
	/** @format date-time */
	operateUserTime?: string;
	/** @format int64 */
	purchaseLogId?: number;
	requestIp?: string;
	requestIpCity?: string;
	requestMappingType?: string;
	requestParams?: string;
	/** @format int32 */
	requestStatus?: number;
	requestUri?: string;
	/** @format date-time */
	updateTime?: string;
}

/** PurchaseNormalOrderRespDTO */
export interface PurchaseNormalOrderRespDTO {
	/**
	 * 业务类型: 1-档口（市场购）;2-线上平台（透明购）;5-线下采购（线下购）
	 * @format int32
	 */
	bizType?: number;
	/**
	 * 主键id
	 * @format int64
	 */
	purchaseAbnormalOrderId?: number;
	/** 异常采购单号 */
	purchasingAbnormalOrderNo?: string;
	/**
	 * 是否完成 0  1
	 * @format int32
	 */
	status?: number;
}

/** PurchaseOnlyReturnMoneyReqDTO */
export interface PurchaseOnlyReturnMoneyReqDTO {
	/** @format int32 */
	purchaseOperateDetailType?: number;
	/** @format int32 */
	purchaseRemakeStatus?: number;
	purchaseUserProductLink?: string;
	purchaseUserProductSku?: string;
}

/** PurchaseOrderAndAbnormalOnlyRespDTO */
export interface PurchaseOrderAndAbnormalOnlyRespDTO {
	/** @format int32 */
	accountCheckStatus?: number;
	accountType?: string;
	alipayAccount?: string;
	purchasingAbnormalOrderNo?: string;
	receiveMoney?: number;
	/** @format date-time */
	receiveMoneyTime?: string;
	tradeNo?: string;
}

/** PurchaseOrderAndAbnormalRespDTO */
export interface PurchaseOrderAndAbnormalRespDTO {
	merchantOrderNumber?: string;
	purchaseNo?: string;
	purchaseOrderAndAbnormalOnlyRespDTOList?: PurchaseOrderAndAbnormalOnlyRespDTO[];
}

/** PurchaseOrderCalculateReqDTO */
export interface PurchaseOrderCalculateReqDTO {
	purchaseUserId?: string;
}

/** PurchaseOrderCalculateRespDTO */
export interface PurchaseOrderCalculateRespDTO {
	/** @format int32 */
	abnormalPurchaseOrderNum?: number;
	/** @format int32 */
	accountCheckFailNum?: number;
	/** @format int32 */
	addInformationNum?: number;
	/** @format int32 */
	logisticsPurchaseOrderNum?: number;
	/** @format int32 */
	prePurchaseOrderNum?: number;
	/** @format int32 */
	programPurchaseOrder?: number;
	/** @format int32 */
	purchaseOrderNum?: number;
	/** @format int32 */
	settledNum?: number;
}

/** PurchaseOrderDetailRespDTO */
export interface PurchaseOrderDetailRespDTO {
	/** 支付方式；1：支付宝；2：跨境宝 */
	accountType?: string;
	/** 实际优惠 */
	actualDiscountMoney?: number;
	/** 支付账户 */
	alipayAccount?: string;
	/**
	 * 业务类型: 1-档口（市场购）;2-线上平台（透明购）;5-线下采购（线下购）
	 * @format int32
	 */
	bizType?: number;
	/**
	 * 换源标识: 0-不允许;1-允许
	 * @format int32
	 */
	changeUrlTag?: number;
	/** 质检类型 */
	checkType?: string;
	/**
	 * 重拍次数
	 * @format int32
	 */
	createNum?: number;
	/**
	 * 下单状态：0-待下单；1-下单中；2-下单成功；3-下单失败
	 * @format int32
	 */
	createOrderStatus?: number;
	/** 客户 */
	customerNames?: string[];
	/** 下单失败原因 */
	errorMsg?: string;
	/** 冻结金额 */
	freezeAmount?: number;
	/** 物流单号 */
	logisticsNumberList?: OrderExpressRespDTO[];
	/** 跟单员id */
	merchandiserUserId?: string;
	/** 跟单员用户姓名 */
	merchandiserUserName?: string;
	/** 下级采购单 多个英文逗号隔开 */
	nextPurchase?: string;
	/** 采购货品 */
	orderItemList?: PurchaseOrderItemDTO[];
	/**
	 * 支付时间
	 * @format date-time
	 */
	payTime?: string;
	/** 所属渠道: TB-淘宝; AM-1688; TM-天猫 */
	platformType?: string;
	/** 上级采购单 */
	prePurchase?: string;
	/** 关联异常采购单列表 */
	purchaseAbnormalLists?: PurchaseNormalOrderRespDTO[];
	/** 国内实付总运费 */
	purchaseFreight?: number;
	/**
	 * 采购单ID
	 * @format int64
	 */
	purchaseId?: number;
	/**
	 * 采购单层级
	 * @format int32
	 */
	purchaseLevel?: number;
	/** 采购实付商品总价 */
	purchaseMoney?: number;
	/** 采购单编号 */
	purchaseNo?: string;
	/**
	 * 新采购状态：0-待分配；1-待换源；2-待下单；3-待支付；4-待供应商发货；5-待发货；6-采购完成；7-采购作废
	 * @format int32
	 */
	purchaseOrderStatus?: number;
	/** 采购员id */
	purchaseUserId?: string;
	/** 采购员用户姓名 */
	purchaseUserName?: string;
	/** 备注 */
	remark?: string;
	/**
	 * 结算方式: 1-按采购单现结;2-按账期结算
	 * @format int32
	 */
	settleType?: number;
	/** 供应商店铺编码 */
	shopCode?: string;
	/** 供应商店铺名 */
	shopName?: string;
	/**
	 * 签收数量
	 * @format int32
	 */
	signNum?: number;
	/** 供应商所在商场楼层 */
	stallFloorNumber?: string;
	/** 供应商所在商场 */
	stallName?: string;
	/** 档口号 */
	stallRoomNumber?: string;
	/**
	 * 美国站标识 0-否;1-是
	 * @format int32
	 */
	stationFlag?: number;
	/**
	 * 入库数量
	 * @format int32
	 */
	stockInNum?: number;
	/**
	 * 到货情况：0-未入库；1-部分入库；2-全部入库
	 * @format int32
	 */
	stockInStatus?: number;
	/**
	 * 系统来源: 1-D2C; 2-B2B
	 * @format int32
	 */
	systemSource?: number;
	/** 采购账户 */
	tbAccount?: string;
	/** 渠道单号 */
	tbOrderNo?: string;
	/** 仓库编码 */
	wareCode?: string;
}

/** PurchaseOrderExpressReqDTO */
export interface PurchaseOrderExpressReqDTO {
	expressNo?: OrderExpressRespDTO[];
	manageUserId?: string;
	manageUsername?: string;
	/** @format int64 */
	purchaseId?: number;
}

/** PurchaseOrderInfoReqDTO */
export interface PurchaseOrderInfoReqDTO {
	productSku?: string;
	/** @format int64 */
	purchaseId?: number;
	recommendProductSku?: string;
	/** @format int32 */
	stockInStatus?: number;
}

/** PurchaseOrderItemDTO */
export interface PurchaseOrderItemDTO {
	abnormalFlowInfoDTOList?: AbnormalFlowInfoDTO[];
	/** @format int32 */
	changeGoodsTag?: number;
	/** @format int32 */
	createOrderStatus?: number;
	customerTags?: number[];
	errorMsg?: string;
	historicalLowPrice?: number;
	itemCategory?: string;
	/** @format int32 */
	maxOverFlowNum?: number;
	productCode?: string;
	productImg?: string;
	productLink?: string;
	productName?: string;
	productPrice?: number;
	productProp?: string;
	productSku?: string;
	productStallNumber?: string;
	/** @format int32 */
	productTag?: number;
	/** @format int64 */
	purchaseId?: number;
	/** @format int64 */
	purchaseItemId?: number;
	purchaseNo?: string;
	/** @format int32 */
	purchaseNum?: number;
	purchaseUnitPrice?: number;
	recommendProductSku?: string;
	relationRespDTOList?: PurchaseOrderItemRelationRespDTO[];
	remark?: string;
	/** @format int32 */
	sellQuantity?: number;
	sellUnitPrice?: number;
	/** @format int32 */
	signNum?: number;
	stallPrice?: number;
	/** @format int32 */
	stockInNum?: number;
	/** @format int32 */
	stockInStatus?: number;
	/** @format int32 */
	yinYangTag?: number;
}

/** PurchaseOrderItemRelationRespDTO */
export interface PurchaseOrderItemRelationRespDTO {
	actualUnitPrice?: number;
	/** @format int32 */
	combinationProductItemFlag?: number;
	/** @format date-time */
	createTime?: string;
	/** @format int64 */
	customerId?: number;
	customerName?: string;
	customerProductSku?: string;
	/** @format int64 */
	customerShopId?: number;
	customerShopName?: string;
	/** @format int32 */
	customerTag?: number;
	/** @format int32 */
	directSaleFlag?: number;
	isUseDiscount?: boolean;
	/** @format int64 */
	mainCustomerId?: number;
	/** @format int32 */
	num?: number;
	/** @format int64 */
	oemProductClassificationId?: number;
	/** @format int64 */
	orderId?: number;
	/** @format int64 */
	orderItemId?: number;
	orderNo?: string;
	/** @format date-time */
	orderTime?: string;
	/** @format int32 */
	orderType?: number;
	originalProductLink?: string;
	originalProductSku?: string;
	platformOrderNo?: string;
	productCode?: string;
	productImg?: string;
	productLink?: string;
	productName?: string;
	productProp?: string;
	productSku?: string;
	/** @format int32 */
	productTag?: number;
	/** @format int32 */
	productType?: number;
	/** @format int64 */
	purchaseOrderId?: number;
	/** @format int64 */
	purchaseOrderItemId?: number;
	/** @format int64 */
	purchaseOrderItemRelationId?: number;
	/** @format int32 */
	purchasePriceAdd?: number;
	recommendProductSku?: string;
	/** @format int32 */
	saleAmount?: number;
	/** @format int32 */
	sellQuantity?: number;
	sellUnitPrice?: number;
	settlePrice?: number;
	/** @format int32 */
	singleProductFlag?: number;
	singleProductFlagStatus?: boolean;
	specialCheckInfoList?: SpecialCheckDTO[];
	stationCode?: string;
	/** @format int32 */
	status?: number;
	totalActualPrice?: number;
	totalSellPrice?: number;
	/** @format int32 */
	transformStockStatus?: number;
	unificationCustomerFullName?: string;
	/** @format date-time */
	updateTime?: string;
}

/** PurchaseOrderItemRespDTO */
export interface PurchaseOrderItemRespDTO {
	/** 实付邮费 */
	actualFreight?: number;
	/** 实付金额 */
	actualPrice?: number;
	/**
	 * 实付数量
	 * @format int32
	 */
	actualQuantity?: number;
	/** 实付单价 */
	actualUnitPrice?: number;
	/** 议价邮费 */
	bargainFreight?: number;
	/** 议价金额 */
	bargainPrice?: number;
	/**
	 * 议价数量
	 * @format int32
	 */
	bargainQuantity?: number;
	/** 议价备注 */
	bargainRemark?: string;
	/** 议价单价 */
	bargainUnitPrice?: number;
	/** 下单邮费 */
	createOrderFreight?: number;
	/** 下单金额 */
	createOrderPrice?: number;
	/**
	 * 下单数量
	 * @format int32
	 */
	createOrderQuantity?: number;
	/**
	 * 下单状态：0-待下单；1-下单中；2-下单成功；3-下单失败
	 * @format int32
	 */
	createOrderStatus?: number;
	/** 下单单价 */
	createOrderUnitPrice?: number;
	/** 折扣 */
	discountRate?: string;
	/** 下单失败原因 */
	errorMsg?: string;
	/** 历史采购低价 */
	historicalLowPrice?: number;
	/** 采购货品图片 */
	productImg?: string;
	/** 采购货品链接 */
	productLink?: string;
	/** 采购货品名称 */
	productName?: string;
	/** 采购货品属性 */
	productProp?: string;
	/** 采购货品sku */
	productSku?: string;
	/** 优惠金额 */
	promotionAmount?: number;
	/** 优惠方式:1-改价,2-返金 */
	promotionMethod?: string;
	/** 优惠方式:1-立减,2-折扣 */
	promotionType?: string;
	/**
	 * 采购货品主键id
	 * @format int64
	 */
	purchaseItemId?: number;
	/** 立减金额 */
	reductionAmount?: string;
	/**
	 * 销售数量
	 * @format int32
	 */
	sellQuantity?: number;
	/** 销售邮费 */
	sellTotalFreight?: number;
	/** 销售金额 */
	sellTotalPrice?: number;
	/** 销售单价 */
	sellUnitPrice?: number;
}

/** PurchaseOrderReqDTO */
export interface PurchaseOrderReqDTO {
	/** @format int32 */
	abnormalOrderStatus?: number;
	alipayAccount?: string;
	/** @format int32 */
	bizType?: number;
	/** @format date-time */
	createTimeEnd?: string;
	/** @format date-time */
	createTimeStart?: string;
	/** @format date-time */
	dateTimeEnd?: string;
	/** @format date-time */
	dateTimeStart?: string;
	detailRemark?: string;
	/** @format int32 */
	isErrorData?: number;
	logisticsNumber?: string;
	/** @format int32 */
	logisticsStatus?: number;
	/** @format int32 */
	pageNum?: number;
	/** @format int32 */
	pageSize?: number;
	/** @format int32 */
	pickStatus?: number;
	platformOrderNo?: string;
	platformType?: string;
	/** @format int32 */
	productTag?: number;
	/** @format date-time */
	purchaseCreateTimeEnd?: string;
	/** @format date-time */
	purchaseCreateTimeStart?: string;
	/** @format date-time */
	purchaseDateTimeEnd?: string;
	/** @format date-time */
	purchaseDateTimeStart?: string;
	purchaseIdList?: number[];
	purchaseNo?: string;
	purchaseNos?: string[];
	/** @format int32 */
	purchaseOrderStatus?: number;
	purchaseUserName?: string;
	/** @format int32 */
	qcStatus?: number;
	remark?: string;
	shopName?: string;
	/** @format date-time */
	signTimeDateTimeEnd?: string;
	/** @format date-time */
	signTimeDateTimeStart?: string;
	/** @format int32 */
	skuType?: number;
	skuTypeValue?: string;
	/** @format date-time */
	splitConfirmTimeEnd?: string;
	/** @format date-time */
	splitConfirmTimeStart?: string;
	stallName?: string;
	/** @format int32 */
	startIndex?: number;
	/** @format int32 */
	stockInStatus?: number;
	/** @format int32 */
	synchronousStatus?: number;
	/** @format date-time */
	systemDateTimeEnd?: string;
	/** @format date-time */
	systemDateTimeStart?: string;
	/** @format int32 */
	systemSource?: number;
	tag?: string;
	/** @format int32 */
	tagType?: number;
	tbAccount?: string;
	tbOrderNo?: string;
	/** @format int32 */
	timeType?: number;
	userId?: string;
	wareCode?: string;
	/** @format int32 */
	yinYangTag?: number;
}

/** PurchaseOrderRespDTO */
export interface PurchaseOrderRespDTO {
	/** @format int32 */
	abnormalStatus?: number;
	accountType?: string;
	alipayAccount?: string;
	/** @format int32 */
	bizType?: number;
	/** @format int32 */
	changeUrlTag?: number;
	/** @format int32 */
	createNum?: number;
	/** @format date-time */
	createTime?: string;
	/** @format int32 */
	delayDays?: number;
	logisticsNumberList?: OrderExpressRespDTO[];
	nextPurchase?: string;
	/** @format int32 */
	num?: number;
	/** @format int32 */
	oemProductClassificationId?: number;
	/** @format date-time */
	payTime?: string;
	/** @format int32 */
	pickStatus?: number;
	platformType?: string;
	prePurchase?: string;
	/** @format int32 */
	productTag?: number;
	/** @format date-time */
	purchaseCompleteTime?: string;
	/** @format date-time */
	purchaseCreateTime?: string;
	purchaseFreight?: number;
	/** @format int64 */
	purchaseId?: number;
	/** @format int32 */
	purchaseLevel?: number;
	purchaseMoney?: number;
	purchaseNo?: string;
	/** @format int32 */
	purchaseNum?: number;
	purchaseOrderItemDTOList?: PurchaseOrderItemDTO[];
	/** @format int32 */
	purchaseStatus?: number;
	/** @format int32 */
	purchaseType?: number;
	purchaseUserId?: string;
	purchaseUserName?: string;
	purchasingAbnormalOrderNo?: string;
	/** @format int32 */
	reconciliationStatus?: number;
	remark?: string;
	shopCode?: string;
	shopName?: string;
	/** @format int32 */
	signNum?: number;
	/** @format date-time */
	splitConfirmTime?: string;
	splitConfirmUserId?: string;
	splitConfirmUserName?: string;
	/** @format date-time */
	splitDate?: string;
	stallFloorNumber?: string;
	stallName?: string;
	stallRoomNumber?: string;
	/** @format date-time */
	stockCompleteTime?: string;
	/** @format int32 */
	stockInNum?: number;
	/** @format int32 */
	stockInStatus?: number;
	/** @format int32 */
	syncStatus?: number;
	/** @format int32 */
	synchronousStatus?: number;
	/** @format int32 */
	systemSource?: number;
	systemTag?: string;
	tbAccount?: string;
	tbOrderNo?: string;
	tradeAccountRespDTOList?: TradeAccountRespDTO[];
	/** @format date-time */
	updateTime?: string;
	/** @format int32 */
	viewStatus?: number;
	wareCode?: string;
}

/** PurchaseOrderSettlePriceReqDTO */
export interface PurchaseOrderSettlePriceReqDTO {
	orderNo?: string;
	settlePrice?: number;
}

/** PurchaseOrderUpdateReqDTO */
export interface PurchaseOrderUpdateReqDTO {
	/** @format int64 */
	purchaseId?: number;
	/** @format int32 */
	reconciliationStatus?: number;
}

/** PurchasePayFlowDTO */
export interface PurchasePayFlowDTO {
	/** @format int64 */
	abnormalOrderDealId?: number;
	accountLogId?: string;
	accountNumber?: string;
	approvalOrderNumber?: string;
	bizType?: string;
	inAmount?: number;
	merchantOrderNumber?: string;
	outAmount?: number;
	purchaseNo?: string;
	purchasingAbnormalOrderNo?: string;
	refundSupplierFlowNo?: string;
}

/** PurchasePayStageReqDTO */
export interface PurchasePayStageReqDTO {
	/** @format int64 */
	oemPurchaseId?: number;
	stageFreezeAmount?: number;
	/** @format int32 */
	stageNo?: number;
	userId?: string;
	userName?: string;
}

/** PurchaseProductDetailRespDTO */
export interface PurchaseProductDetailRespDTO {
	/**
	 * 商品一手数量
	 * @format int32
	 */
	batchNumber?: number;
	/**
	 * 客户行为;1-喜欢;2-不喜欢
	 * @format int32
	 */
	behaviorFlag?: number;
	/**
	 * 黑名单加入时间
	 * @format date-time
	 */
	blackAddTime?: string;
	/** 黑名单原因 */
	blackReason?: string;
	/**
	 * 黑名单原因编码
	 * @format int32
	 */
	blackReasonCode?: number;
	/**
	 * 黑名单标记
	 * @format int32
	 */
	blackTag?: number;
	/**
	 * 黑名单类型:1-商品,2-供应商,类型为2的无法解除黑名单限制
	 * @format int32
	 */
	blackType?: number;
	/** 黑名单操作员id */
	blackUserId?: string;
	/** 黑名单操作员 */
	blackUserName?: string;
	/**
	 * 来源类型;1-档口(市场购);2-线上平台（透明购）
	 * @format int32
	 */
	channel?: number;
	/** 一件代发价 */
	consignPrice?: number;
	/** 一件代发价,当地汇率价格 */
	consignPriceJpy?: number;
	/** @format int32 */
	countryProductTag?: number;
	/**
	 * 创建时间
	 * @format date-time
	 */
	createTime?: string;
	/**
	 * 是否收藏;1-已收藏;0-未收藏
	 * @format int32
	 */
	favoriteFlag?: number;
	/** @format int32 */
	isCkbDiscount?: number;
	/** 是否一件代发(ture:是) */
	isOnePsale?: boolean;
	jxhyPrice?: number;
	/** 材质信息-日文（注意：订单使用） */
	materialInfoJa?: string;
	/** 材质信息-中文（注意：订单使用） */
	materialInfoZh?: string;
	/**
	 * oem最小起订量
	 * @format int32
	 */
	minOrderQuantity?: number;
	/**
	 * OEM商品分类表id：1-服装; 2-杂货; 3-辅料
	 * @format int32
	 */
	oemProductClassificationId?: number;
	/** 商品原始价格 */
	originalPrice?: number;
	paymentDetail?: string;
	/** @format int32 */
	paymentType?: number;
	/** 平台类型;TB:淘宝;AM:1688;TM:天猫;VC:搜款网;WS:网商园 */
	platformType?: string;
	/** 商品APP价格 */
	productAppSellPrice?: number;
	/** 品牌id */
	productBrandId?: string;
	/** 品牌名称 */
	productBrandName?: string;
	/**
	 * 最小购买数/起拍数
	 * @format int32
	 */
	productBuyMininum?: number;
	/** 类目编码; */
	productCateCode?: string;
	/**
	 * 前台类目二级ID
	 * @format int64
	 */
	productCategoryFrontendNdId?: number;
	/** 平台商品SPU */
	productCode?: string;
	/**
	 * 商品评论数
	 * @format int32
	 */
	productCommentCount?: number;
	/** 描述图片 */
	productDescImgs?: string[];
	/** 商品描述 */
	productDescription?: string;
	/** 详情URL */
	productDetailUrl?: string;
	/** item缩略图（大） */
	productItemImgs?: ProductImageUrlDTO[];
	/** item缩略图（小） */
	productItemImgsS?: ProductImageUrlDTO[];
	/** 主图 */
	productMainImg?: string;
	/** 商品原销售价格 */
	productOriginPriceRange?: string;
	/** 商品原销售价格-日元 */
	productOriginPriceRangeJpy?: string;
	/** 商品阶梯价 */
	productPriceLadderList?: ProductPriceLadderRespDTO[];
	/** 商品阶梯价 */
	productPriceRangeLadderList?: ProductPriceLadderRespDTO[];
	/** 商品属性 */
	productPropGroupEnList?: ProductPropGroupRespDTO[];
	/** 商品属性 */
	productPropGroupJaList?: ProductPropGroupRespDTO[];
	/** 商品属性 */
	productPropGroupList?: ProductPropGroupRespDTO[];
	/** 属性图片 */
	productPropImgs?: string[];
	/** 属性标识（注意：订单使用） */
	productProperties?: string;
	/** 属性名称（注意：订单使用） */
	productPropertiesName?: string;
	/** 属性名称parse（注意：sku使用） */
	productPropertiesNameParse?: string;
	/** 商品详情属性 */
	productProps?: string;
	productPropsEn?: string;
	productPropsEnList?: string;
	productPropsJa?: string;
	productPropsJaList?: string;
	/** 商品sku属性列表 */
	productPropsList?: string;
	/** 商品资源 */
	productResource?: ProductResourceRespDTO;
	/** 商品价格 */
	productSellPrice?: number;
	/** 商品销售价格-日元 */
	productSellPriceJpy?: number;
	/** 商品价格范围 */
	productSellPriceRange?: string;
	/** 商品销售价格范围-日元 */
	productSellPriceRangeJpy?: string;
	/**
	 * 销量
	 * @format int32
	 */
	productSellQuantity?: number;
	/** 平台商品SKU */
	productSku?: string;
	/** sku对应图片（注意：订单使用） */
	productSkuImg?: string;
	/** 档口货号 */
	productStallNumber?: string;
	/**
	 * 库存
	 * @format int32
	 */
	productStockQuantity?: number;
	/** @format int32 */
	productTag?: number;
	/** 商品标题 */
	productTitle?: string;
	/** 商品标题翻译; 日文 */
	productTitleEn?: string;
	/** 商品标题翻译; 日文 */
	productTitleJp?: string;
	/** 商品标题翻译; 日文 */
	productTitleKr?: string;
	/** 商品视频介绍 */
	productVideo?: string;
	/** 问题-商品类型【问题一：商品类型】（注意：订单使用） */
	questionClassificationName?: string;
	/** 请求商品详情URL */
	requestProductDetailUrl?: string;
	/**
	 * 寻源单复购申请表id
	 * @format int64
	 */
	searchSourcePriceId?: number;
	/** 二级类目编码 */
	secondaryProductCateCode?: string;
	/** 卖家id */
	sellerId?: string;
	/** 掌柜昵称 */
	sellerNick?: string;
	/** 店铺code */
	shopCode?: string;
	/** 发货地 */
	shopLocation?: string;
	/** 店铺主图 */
	shopMainImg?: string;
	/** 店铺名称 */
	shopName?: string;
	/** 店铺名称-英文 */
	shopNameEn?: string;
	/** 店铺商品SKU(店铺商品库)（注意：订单使用） */
	shopProductSku?: string;
	/**
	 * 1-单尺码; 2-多尺码（注意：订单使用）
	 * @format int32
	 */
	sizeFlag?: number;
	/** 商品sku信息 */
	skuList?: SkuRespDTO[];
	stationCode?: string;
}

/** PurchaseReSupplyReqDTO */
export interface PurchaseReSupplyReqDTO {
	reSupplyExpressNo?: string;
	remark?: string;
}

/** PurchaseReturnAllReqDTO */
export interface PurchaseReturnAllReqDTO {
	/** @format int32 */
	purchaseOperateDetailType?: number;
	/** @format int32 */
	purchaseRemakeStatus?: number;
	purchaseUserProductLink?: string;
	purchaseUserProductSku?: string;
}

/** PurchaseSkuRespDTO */
export interface PurchaseSkuRespDTO {
	/** @format int32 */
	availableQty?: number;
	/** @format int32 */
	batchNumber?: number;
	/** @format int32 */
	blackReasonCode?: number;
	/** @format int32 */
	blackTag?: number;
	bought?: boolean;
	/** @format int32 */
	channel?: number;
	consignPrice?: number;
	/** @format int32 */
	countryProductTag?: number;
	/** @format int64 */
	customerId?: number;
	/** @format double */
	discount?: number;
	discountPriceSetting?: string;
	/** @format double */
	height?: number;
	/** @format int32 */
	hotFlag?: number;
	isInBlank?: boolean;
	isInProductCategoryClose?: boolean;
	isOnePsale?: boolean;
	isOnline?: boolean;
	isOnlineProduct?: boolean;
	jxhyPrice?: number;
	/** @format double */
	length?: number;
	liveSellPrice?: number;
	/** @format date-time */
	liveSellPriceEndTime?: string;
	/** @format date-time */
	liveSellPriceStartTime?: string;
	/** @format double */
	maintainHeight?: number;
	/** @format double */
	maintainLength?: number;
	/** @format int64 */
	maintainWeight?: number;
	/** @format double */
	maintainWidth?: number;
	materialInfoEn?: string;
	materialInfoJa?: string;
	materialInfoKr?: string;
	materialInfoZh?: string;
	/** @format int32 */
	notInStorage?: number;
	productCateCode?: string;
	productCategoryFrontendNdId?: string;
	productCode?: string;
	productCustoms?: ProductCustomsRespDTO;
	/** @format int64 */
	productCustomsId?: number;
	productDetailUrl?: string;
	productItemCategoryZh?: string;
	productOriginSellPrice?: number;
	productPriceLadderList?: ProductPriceLadderRespDTO[];
	productProperties?: string;
	productPropertiesName?: string;
	productPropertiesNameEn?: string;
	productPropertiesNameJa?: string;
	productPropertiesNameParse?: string;
	productPropertiesNameParseEn?: string;
	/** @format int64 */
	productPropertiesSort?: number;
	productSellPrice?: number;
	/** @format int32 */
	productSellPriceType?: number;
	/** @format int32 */
	productSellQuantity?: number;
	productSku?: string;
	productSkuImg?: string;
	/** @format int32 */
	productStockQuantity?: number;
	/** @format int32 */
	productTag?: number;
	productTitle?: string;
	productTitleEn?: string;
	productTitleJp?: string;
	productTitleKr?: string;
	/** 运输属性 */
	productTransportationAttrDTOS?: ProductTransportationAttrDTO[];
	promotionMethod?: string;
	promotionType?: string;
	purchasePrice?: number;
	purchaseProductDetailRespDTO?: PurchaseProductDetailRespDTO;
	recommendProductSku?: string;
	reductionAmount?: string;
	shopCode?: string;
	/** @format int32 */
	shopIsBlack?: number;
	skuId?: string;
	specId?: string;
	stallProductSellPrice?: number;
	stationCode?: string;
	/** @format int32 */
	stationStatus?: number;
	supplierSku?: string;
	transportationAttrCode?: string;
	userChooseConfirmOriginalPrice?: number;
	/** @format int64 */
	weight?: number;
	/** @format double */
	width?: number;
}

/** PushCompleteEventReqDTO */
export interface PushCompleteEventReqDTO {
	purchaseIds?: number[];
}

/** QueryAbnormalCountReqDTO */
export interface QueryAbnormalCountReqDTO {
	customerShopId?: string;
	platformOrderNoList?: string[];
}

/** QueryAbnormalCountRespDTO */
export interface QueryAbnormalCountRespDTO {
	customerOperateStatus?: string;
	/** @format int32 */
	num?: number;
	platformOrderNo?: string;
}

/** QueryAbnormalItemCountReqDTO */
export interface QueryAbnormalItemCountReqDTO {
	customerShopId?: string;
	platformOrderNo?: string;
}

/** QueryAbnormalItemCountRespDTO */
export interface QueryAbnormalItemCountRespDTO {
	customerOperateStatus?: string;
	/** @format int32 */
	num?: number;
	orderNo?: string;
}

/** QueryBargainInfoRespDTO */
export interface QueryBargainInfoRespDTO {
	/** 冻结金额 */
	freezeAmount?: number;
	/** 货品列表 */
	itemList?: PurchaseOrderItemRespDTO[];
}

/** QueryEstimateStockTimeRespDTO */
export interface QueryEstimateStockTimeRespDTO {
	estimateStockTime?: string;
}

/** QueryItemExpressInfoRespDTO */
export interface QueryItemExpressInfoRespDTO {
	packageLogistics?: PackageLogisticsDTO[];
}

/** QueryItemLogisticsReqDTO */
export interface QueryItemLogisticsReqDTO {
	productSku?: string;
	/** @format int32 */
	productType?: number;
	/** @format int32 */
	requestSource?: number;
	shopCode?: string;
	systemOrderNo?: string;
}

/** QueryOemPurchaseOperateLogRespDTO */
export interface QueryOemPurchaseOperateLogRespDTO {
	operateContent?: string;
	/** @format int32 */
	operateScene?: number;
	/** @format date-time */
	operateTime?: string;
	operateUserName?: string;
}

/** QueryPayStageRespDTO */
export interface QueryPayStageRespDTO {
	freezeAmount?: number;
	stageFreezeAmount?: number;
	totalAmount?: number;
}

/** QueryPurchaseOrderReqDTO */
export interface QueryPurchaseOrderReqDTO {
	/**
	 * 是否生成异常采购单 0-否 1-是
	 * @format int32
	 */
	abnormalStatus?: number;
	/** 采购账户类型 1-支付宝 2-跨境宝 */
	accountType?: string;
	/**
	 * 改价状态：0-无需改价；1-未改价；2-已改价
	 * @format int32
	 */
	adjustPriceStatus?: number;
	/**
	 * 业务类型: 1-档口(市场购);2-线上平台（透明购）;5-线下平台（线下购）
	 * @format int32
	 */
	bizType?: number;
	/** 采购单层级 0-4 表示1-5级，-1表示5级以上 */
	createNums?: number[];
	/**
	 * 下单状态：0-待下单；1-下单中；2-下单成功；3-下单失败
	 * @format int32
	 */
	createOrderStatus?: number;
	/** 客户名称 */
	customerName?: string;
	/** 客户标签 1 oem项目重点客户 2 oem非项目重点客户 */
	customerTags?: number[];
	/**
	 * 结束日期
	 * @format date-time
	 */
	dateTimeEnd?: string;
	/**
	 * 开始日期
	 * @format date-time
	 */
	dateTimeStart?: string;
	impCustomers?: number[];
	/**
	 * 是否有中文品名 0-否 1-是
	 * @format int32
	 */
	itemCategoryTag?: number;
	/** 物流单号列表 */
	logisticsNumbers?: string[];
	/** 跟单员id */
	merchandiserUserId?: string;
	/**
	 * 分页起始条数
	 * @format int32
	 */
	offset?: number;
	/**
	 * 自动下单标识 0->手动下单 1->手动下单
	 * @format int32
	 */
	orderCreateType?: number;
	/**
	 * 页码
	 * @format int32
	 */
	pageNum?: number;
	/**
	 * 每页条数
	 * @format int32
	 */
	pageSize?: number;
	/** 订单编号列表 */
	platformOrderNos?: string[];
	/** 商品来源: TB-淘宝; AM-1688; TM-天猫 */
	platformType?: string;
	/** 采购商品名称 */
	productName?: string;
	/** 下单商品sku列表 */
	productSkus?: string[];
	/** 优惠方式:1-改价,2-返金 */
	promotionMethod?: string;
	/** 采购单id列表 */
	purchaseIds?: number[];
	/** 采购单列表 */
	purchaseNos?: string[];
	/**
	 * 新采购状态：0-待分配；1-待换源；2-待下单；3-待支付；4-待供应商发货；5-待发货；6-采购完成；7-采购作废
	 * @format int32
	 */
	purchaseOrderStatus?: number;
	/** 采购商品sku列表 */
	purchaseProductSkus?: string[];
	/** 采购员id */
	purchaseUserId?: string;
	/** 采购单备注 */
	remark?: string;
	/**
	 * 结算方式: 1-按采购单现结;2-按账期结算
	 * @format int32
	 */
	settleType?: number;
	/** 供应商名称 */
	shopName?: string;
	/** 排序规则：正序-asc 倒序-desc；默认倒序 */
	sort?: string;
	/**
	 * 排序类型 1->采购单创建时间 2->应采数量 3->采购下单时间 4->采购应付商品总价 5->采购应付运费 6->采购支付时间 7->采购实付商品总价 8->采购实付运费 9->最大商品销售单价 10->采购运费比例
	 * @format int32
	 */
	sortType?: number;
	/** 档口名称 */
	stallName?: string;
	/**
	 * 美国站标识 0-否;1-是
	 * @format int32
	 */
	stationFlag?: number;
	/**
	 * 到货情况：0-未入库；1-部分入库；2-全部入库
	 * @format int32
	 */
	stockInStatus?: number;
	/**
	 * 系统来源: 1-D2C; 2-B2B
	 * @format int32
	 */
	systemSource?: number;
	/** 采购账户 */
	tbAccount?: string;
	/** 渠道单号列表 */
	tbOrderNos?: string[];
	/**
	 * 时间类型 1->采购单创建时间 2->采购下单时间 3->采购支付时间 4->系统预估到仓时间 5->采购预估到仓时间 6->发货时间 7->到仓时间
	 * @format int32
	 */
	timeType?: number;
	/** 登录用户id */
	userId?: string;
	/** 登录用户名称 */
	userName?: string;
	/** 仓库编码 */
	wareCode?: string;
	/**
	 * 阴阳id标识 0-否 1-是
	 * @format int32
	 */
	yinYangTag?: number;
}

/** QueryPurchaseOrderRespDTO */
export interface QueryPurchaseOrderRespDTO {
	/**
	 * 改价状态：0-无需改价；1-未改价；2-已改价
	 * @format int32
	 */
	adjustPriceStatus?: number;
	/**
	 * 业务类型: 1-档口（市场购）;2-线上平台（透明购）;5-线下采购（线下购）
	 * @format int32
	 */
	bizType?: number;
	/**
	 * 换源标识: 0-不允许;1-允许
	 * @format int32
	 */
	changeUrlTag?: number;
	/**
	 * 重拍次数
	 * @format int32
	 */
	createNum?: number;
	/** 下单金额 */
	createOrderAmount?: number;
	/** 下单邮费 */
	createOrderFreight?: number;
	/**
	 * 下单状态：0-待下单；1-下单中；2-下单成功；3-下单失败
	 * @format int32
	 */
	createOrderStatus?: number;
	/**
	 * 创建时间
	 * @format date-time
	 */
	createTime?: string;
	/** 客户标签 1 oem项目重点客户 2 oem非项目重点客户 */
	customerTags?: number[];
	/**
	 * 直营订单标识 0-代采单 1-直营单
	 * @format int32
	 */
	directSaleFlag?: number;
	/**
	 * 折扣标识: 0-没有;1-有
	 * @format int32
	 */
	discountTag?: number;
	/** 下单失败原因 */
	errorMsg?: string;
	/** 冻结金额 */
	freezeAmount?: number;
	/** 运费比例 */
	freightRate?: string;
	/** 是否使用折扣价 */
	isUseDiscount?: boolean;
	/** 发货物流 */
	logisticsNumberList?: OrderExpressRespDTO[];
	/**
	 * 主客户id
	 * @format int64
	 */
	mainCustomerId?: number;
	/** 主客户名称 */
	mainCustomerName?: string;
	/** 最大商品销售单价 */
	maxProductPrice?: number;
	/** 跟单员id */
	merchandiserUserId?: string;
	/** 跟单员用户姓名 */
	merchandiserUserName?: string;
	/** 下级采购单 多个英文逗号隔开 */
	nextPurchase?: string;
	/**
	 * 自动下单标识 0->手动下单 1->手动下单
	 * @format int32
	 */
	orderCreateType?: number;
	/** 采购货品 */
	orderItemList?: PurchaseOrderItemRespDTO[];
	/** 支付金额 */
	payAmount?: number;
	/** 支付邮费 */
	payFreight?: number;
	/**
	 * 支付时间
	 * @format date-time
	 */
	payTime?: string;
	/** 平台类型: TB-淘宝; AM-1688; TM-天猫 */
	platformType?: string;
	/** 上级采购单 */
	prePurchase?: string;
	/** 优惠方式:1-改价,2-返金 */
	promotionMethod?: string;
	/** 关联异常采购单列表 */
	purchaseAbnormalLists?: PurchaseNormalOrderRespDTO[];
	/**
	 * 下单时间
	 * @format date-time
	 */
	purchaseCreateTime?: string;
	/**
	 * 采购单ID
	 * @format int64
	 */
	purchaseId?: number;
	/**
	 * 采购单层级
	 * @format int32
	 */
	purchaseLevel?: number;
	/** 采购单编号 */
	purchaseNo?: string;
	/**
	 * 应采数量
	 * @format int32
	 */
	purchaseNum?: number;
	/**
	 * 新采购状态：0-待分配；1-待换源；2-待下单；3-待支付；4-待供应商发货；5-待发货；6-采购完成；7-采购作废
	 * @format int32
	 */
	purchaseOrderStatus?: number;
	/** 采购员id */
	purchaseUserId?: string;
	/** 采购员用户姓名 */
	purchaseUserName?: string;
	/** 备注 */
	remark?: string;
	/** 供应商店铺编码 */
	shopCode?: string;
	/** 供应商店铺名 */
	shopName?: string;
	/**
	 * 签收数量
	 * @format int32
	 */
	signNum?: number;
	/** 供应商所在商场楼层 */
	stallFloorNumber?: string;
	/** 供应商所在商场 */
	stallName?: string;
	/** 档口号 */
	stallRoomNumber?: string;
	/**
	 * 美国站标识 0-否;1-是
	 * @format int32
	 */
	stationFlag?: number;
	/**
	 * 入库数量
	 * @format int32
	 */
	stockInNum?: number;
	/**
	 * 系统来源: 1-D2C; 2-B2B
	 * @format int32
	 */
	systemSource?: number;
	/** 渠道单号 */
	tbOrderNo?: string;
	/** 优惠总额 */
	totalPromotionAmount?: number;
	/** 仓库编码 */
	wareCode?: string;
	/**
	 * 阴阳id标识: 0-否;1-是
	 * @format int32
	 */
	yinYangTag?: number;
}

/** QueryUnitPriceBySystemNoReq */
export interface QueryUnitPriceBySystemNoReq {
	productSku?: string;
	systemNo?: string;
}

/** QueryUnitPriceBySystemNoRespDTO */
export interface QueryUnitPriceBySystemNoRespDTO {
	/** @format int64 */
	productCustomsId?: number;
	productItemCategoryZh?: string;
	productSku?: string;
	unitPrice?: number;
}

/** ReApplySupplierRefundOrderReqDTO */
export interface ReApplySupplierRefundOrderReqDTO {
	accountType?: string;
	alipayAccount?: string;
	/** @format int64 */
	purchaseAbnormalRefundOrderId?: number;
	receiveMoney?: number;
	/** @format date-time */
	receiveMoneyTime?: string;
	supplierOrderNo?: string;
	tradeNo?: string;
}

/** RemovePurchaseExpressNoReqDTO */
export interface RemovePurchaseExpressNoReqDTO {
	expressNo?: string[];
	manageUserId?: string;
	manageUsername?: string;
	/** @format int64 */
	purchaseId?: number;
}

/** RepairPrePurchaseReqDTO */
export interface RepairPrePurchaseReqDTO {
	purchaseAbnormalOrderSn?: string[];
}

/** Resource */
export interface Resource {
	description?: string;
	file?: File;
	filename?: string;
	inputStream?: InputStream;
	open?: boolean;
	readable?: boolean;
	uri?: URI;
	url?: URL;
}

/** RpaApplySupplierRefundReqDTO */
export interface RpaApplySupplierRefundReqDTO {
	accountType?: string;
	alipayAccount?: string;
	/** @format int64 */
	purchaseAbnormalOrderId?: number;
	receiveMoney?: number;
	/** @format date-time */
	receiveMoneyTime?: string;
	supplierOrderNo?: string;
	tradeNo?: string;
}

/** RpaHandleUserRefundReqDTO */
export interface RpaHandleUserRefundReqDTO {
	manageUserId?: string;
	manageUsername?: string;
	/** @format int64 */
	purchaseAbnormalOrderId?: number;
	/** @format int32 */
	purchaseOperateDetailType?: number;
	/** @format int32 */
	purchaseOperateType?: number;
	/** @format int32 */
	purchaseStatus?: number;
	supplierAgreeRefundReqDTO?: RpaSupplierAgreeRefundReqDTO;
}

/** RpaPurchaseAbnormalOrderItemRespDTO */
export interface RpaPurchaseAbnormalOrderItemRespDTO {
	chooseProductCode?: string;
	chooseProductImg?: string;
	chooseProductLink?: string;
	chooseProductName?: string;
	chooseProductProp?: string;
	chooseProductSku?: string;
	/** @format int32 */
	combinationProductItemFlag?: number;
	/** @format date-time */
	createTime?: string;
	customerProductSku?: string;
	imageList?: string[];
	/** @format int32 */
	num?: number;
	orderNo?: string;
	/** @format int32 */
	orderType?: number;
	originalProductSku?: string;
	productCode?: string;
	productImg?: string;
	productLink?: string;
	productName?: string;
	productProp?: string;
	productSku?: string;
	/** @format int32 */
	productType?: number;
	/** @format int64 */
	purchaseAbnormalOrderId?: number;
	/** @format int64 */
	purchaseAbnormalOrderItemId?: number;
	/** @format int64 */
	purchaseOrderItemRelationAbnormalId?: number;
	/** @format int64 */
	purchaseOrderItemRelationId?: number;
	purchaseProductCode?: string;
	purchaseProductImg?: string;
	purchaseProductLink?: string;
	purchaseProductName?: string;
	purchaseProductProp?: string;
	purchaseProductSku?: string;
	purchasingAbnormalOrderNo?: string;
	/** @format date-time */
	updateTime?: string;
	/** @format int32 */
	userChooseNum?: number;
}

/** RpaPurchaseAbnormalOrderUpdateReqDTO */
export interface RpaPurchaseAbnormalOrderUpdateReqDTO {
	/** @format int64 */
	purchaseAbnormalOrderId?: number;
	purchasingAbnormalRemark?: string;
}

/** RpaPurchaseAbnormalQuery */
export interface RpaPurchaseAbnormalQuery {
	/** @format int32 */
	accountCheckStatus?: number;
	applyUserPhone?: string;
	/** @format int32 */
	bizType?: number;
	/** @format date-time */
	createTimeEnd?: string;
	/** @format date-time */
	createTimeStart?: string;
	/** @format int32 */
	customerOperateStatus?: number;
	customerOrderNo?: string;
	customerOrderNoList?: string[];
	/** @format int64 */
	customerShopId?: number;
	/** @format date-time */
	dateTimeEnd?: string;
	/** @format date-time */
	dateTimeStart?: string;
	managerUserId?: string;
	orderName?: string;
	orderNameList?: string[];
	orderNo?: string;
	/** @format int32 */
	pageNum?: number;
	/** @format int32 */
	pageSize?: number;
	platformOrderNo?: string;
	platformOrderNoList?: string[];
	platformType?: string;
	productSku?: string;
	productSkuList?: string[];
	/** @format int64 */
	purchaseAbnormalOrderId?: number;
	/** @format int32 */
	purchaseOperateDetailType?: number;
	/** @format int32 */
	purchaseOperateType?: number;
	purchaseOriginalOrderNo?: string;
	purchaseUserId?: string;
	purchaseUserName?: string;
	/** @format int32 */
	purchaseViewStatus?: number;
	purchasingAbnormalOrderNo?: string;
	purchasingAbnormalRemark?: string;
	/** @format int32 */
	refundType?: number;
	/** @format int32 */
	rpaTag?: number;
	/** @format int32 */
	startIndex?: number;
	/** @format int32 */
	status?: number;
	/** @format int32 */
	systemSource?: number;
	/** @format int32 */
	timeType?: number;
	/** @format int32 */
	type?: number;
	userName?: string;
	/** @format int32 */
	userViewStatus?: number;
}

/** RpaPurchaseAbnormalRespDTO */
export interface RpaPurchaseAbnormalRespDTO {
	itemDetails?: PurchaseAbnormalItemDetailDTO[];
	purchaseAbnormalOrderItemRespDTO?: RpaPurchaseAbnormalOrderItemRespDTO;
	purchaseAbnormalOrderRespDTO?: PurchaseAbnormalOrderRespDTO;
	purchaseAbnormalWmsRefundOrderReqDTO?: RpaPurchaseAbnormalWmsRefundOrderReqDTO;
	purchaseOrderRespDTO?: PurchaseOrderRespDTO;
	shopPurchaseRespDTO?: ShopPurchaseDTO;
	supplierRefundOrderRespDTO?: PurchaseAbnormalSupplierRefundOrderRespDTO;
}

/** RpaPurchaseAbnormalWmsRefundOrderReqDTO */
export interface RpaPurchaseAbnormalWmsRefundOrderReqDTO {
	address?: string;
	associatePurchaseOrderNo?: string;
	/** @format int32 */
	canEditStatus?: number;
	/** @format date-time */
	createTime?: string;
	expressNo?: string;
	/** @format int64 */
	purchaseAbnormalOrderId?: number;
	/** @format int64 */
	purchaseAbnormalWmsRefundOrderId?: number;
	purchasingAbnormalOrderNo?: string;
	reSupplyExpressNo?: string;
	refundContact?: string;
	refundPhone?: string;
	remark?: string;
	/** @format date-time */
	returnDeadLine?: string;
	/** @format date-time */
	updateTime?: string;
}

/** RpaSupplierAgreeRefundReqDTO */
export interface RpaSupplierAgreeRefundReqDTO {
	accountType?: string;
	address?: string;
	alipayAccount?: string;
	receiveMoney?: number;
	/** @format date-time */
	receiveMoneyTime?: string;
	refundContact?: string;
	refundPhone?: string;
	/** @format int32 */
	refundType?: number;
	remark?: string;
	/** @format date-time */
	returnDeadLine?: string;
	supplierOrderNo?: string;
	tradeNo?: string;
}

/** SeparateOrderReqDTO */
export interface SeparateOrderReqDTO {
	platformOrderNos?: string[];
}

/** SeparateOrderRespDTO */
export interface SeparateOrderRespDTO {
	platformOrderNo?: string;
	purchaseOrderNo?: string[];
}

/** ShopPurchaseDTO */
export interface ShopPurchaseDTO {
	/** @example "yyyy-MM-dd HH:mm:ss" */
	createTime?: string;
	/** @format int64 */
	customerShopId?: number;
	/** @format int32 */
	payRepurchaseType?: number;
	/** @format int32 */
	purchasePriceAdd?: number;
	/** @format int32 */
	repurchaseFree?: number;
	/** @format int32 */
	repurchasePrice?: number;
	/** @format int32 */
	repurchaseTryMax?: number;
	/** @format int64 */
	shopPurchaseId?: number;
	/** @example "yyyy-MM-dd HH:mm:ss" */
	updateTime?: string;
}

/** SkuProductCustomsRespDTO */
export interface SkuProductCustomsRespDTO {
	compilationMethod?: string;
	customsCode?: string;
	/** @format int64 */
	productCustomsId?: number;
	productItemCategoryEn?: string;
	productItemCategoryZh?: string;
	productSku?: string;
	simpleReportCode?: string;
}

/** SkuRespDTO */
export interface SkuRespDTO {
	/** @format int32 */
	availableQty?: number;
	/** @format int32 */
	batchNumber?: number;
	/** @format int32 */
	blackReasonCode?: number;
	/** @format int32 */
	blackTag?: number;
	bought?: boolean;
	/** @format int32 */
	channel?: number;
	consignPrice?: number;
	/** @format int32 */
	countryProductTag?: number;
	/** @format int64 */
	customerId?: number;
	/** @format double */
	discount?: number;
	discountPriceSetting?: string;
	/** @format double */
	height?: number;
	/** @format int32 */
	hotFlag?: number;
	isInBlank?: boolean;
	isInProductCategoryClose?: boolean;
	isOnePsale?: boolean;
	isOnline?: boolean;
	isOnlineProduct?: boolean;
	jxhyPrice?: number;
	/** @format double */
	length?: number;
	liveSellPrice?: number;
	/** @format date-time */
	liveSellPriceEndTime?: string;
	/** @format date-time */
	liveSellPriceStartTime?: string;
	/** @format double */
	maintainHeight?: number;
	/** @format double */
	maintainLength?: number;
	/** @format int64 */
	maintainWeight?: number;
	/** @format double */
	maintainWidth?: number;
	materialInfoEn?: string;
	materialInfoJa?: string;
	materialInfoKr?: string;
	materialInfoZh?: string;
	/** @format int32 */
	notInStorage?: number;
	productCateCode?: string;
	productCategoryFrontendNdId?: string;
	productCode?: string;
	productCustoms?: ProductCustomsRespDTO;
	/** @format int64 */
	productCustomsId?: number;
	productDetailUrl?: string;
	productItemCategoryZh?: string;
	productOriginSellPrice?: number;
	productPriceLadderList?: ProductPriceLadderRespDTO[];
	productProperties?: string;
	productPropertiesName?: string;
	productPropertiesNameEn?: string;
	productPropertiesNameJa?: string;
	productPropertiesNameParse?: string;
	productPropertiesNameParseEn?: string;
	/** @format int64 */
	productPropertiesSort?: number;
	productSellPrice?: number;
	/** @format int32 */
	productSellPriceType?: number;
	/** @format int32 */
	productSellQuantity?: number;
	productSku?: string;
	productSkuImg?: string;
	/** @format int32 */
	productStockQuantity?: number;
	/** @format int32 */
	productTag?: number;
	productTitle?: string;
	productTitleEn?: string;
	productTitleJp?: string;
	productTitleKr?: string;
	/** 运输属性 */
	productTransportationAttrDTOS?: ProductTransportationAttrDTO[];
	promotionMethod?: string;
	promotionType?: string;
	purchasePrice?: number;
	recommendProductSku?: string;
	reductionAmount?: string;
	shopCode?: string;
	/** @format int32 */
	shopIsBlack?: number;
	skuId?: string;
	specId?: string;
	stallProductSellPrice?: number;
	stationCode?: string;
	/** @format int32 */
	stationStatus?: number;
	supplierSku?: string;
	transportationAttrCode?: string;
	/** @format int64 */
	weight?: number;
	/** @format double */
	width?: number;
}

/** SpecialCheckDTO */
export interface SpecialCheckDTO {
	/** @format int32 */
	orderQuantity?: number;
	platformOrderNo?: string;
	productSku?: string;
	shopProductSku?: string;
	specialCheckRequirement?: string;
	specialCheckRequirementImg?: string;
	systemOrderNo?: string;
}

/** StageInfoRespDTO */
export interface StageInfoRespDTO {
	freezeAmount?: number;
	freezeRate?: string;
	/** @format int32 */
	freezeStatus?: number;
	/** @format int32 */
	stageNo?: number;
	totalFreezeAmount?: number;
}

/** StallAbnormalHandleReqDTO */
export interface StallAbnormalHandleReqDTO {
	/** @format int32 */
	purchaseOperateDetailType?: number;
	purchaseUserProductLink?: string;
	purchaseUserProductSku?: string;
	shopCode?: string;
	shopName?: string;
	skuRespDTO?: PurchaseSkuRespDTO;
	stallCodeNo?: string;
	stallFloorNumber?: string;
	stallName?: string;
	stallRoomNumber?: string;
	userExpected?: boolean;
}

/** StallPurchaseItemEditReqDTO */
export interface StallPurchaseItemEditReqDTO {
	manageUserId?: string;
	manageUsername?: string;
	productStallNumber?: string;
	/** @format int64 */
	purchaseItemId?: number;
	stallPrice?: number;
}

/** StallSettlementOrderQuery */
export interface StallSettlementOrderQuery {
	/** 对账流水号 */
	accountCheckBizId?: string;
	/** 支付账号 */
	alipayAccount?: string;
	/**
	 * 业务类型: 1-档口(市场购);2-线上平台（透明购）;5-线下平台（线下购）
	 * @format int32
	 */
	bizType?: number;
	/** @format int32 */
	pageNum?: number;
	/** @format int32 */
	pageSize?: number;
	/** 平台商品SKU */
	productSku?: string;
	/** 采购单编号 */
	purchaseNo?: string;
	/** 操作员姓名 */
	purchaseUserName?: string;
	/** 供应商名称 */
	shopName?: string;
	/** 结算单号 */
	stallSettlementOrderNo?: string;
	/** @format int32 */
	startIndex?: number;
	/**
	 * 结算状态 0-待结算 1-已结算 2-结算中 3-结算失败
	 * @format int32
	 */
	status?: number;
	/**
	 * 订单来源: 1-D2C; 2-B2B
	 * @format int32
	 */
	systemSource?: number;
	/** 交易流水号 */
	tradeNo?: string;
	/** 拿货员ID */
	userId?: string;
	/** 拿货员名称 */
	userName?: string;
	/** 仓库编码 */
	wareCode?: string;
}

/** StallSettlementOrderReqDTO */
export interface StallSettlementOrderReqDTO {
	/** 支付账户类型；1：支付宝；2：跨境宝 */
	accountType?: string;
	/** 实际结算金额 */
	actualSettleAmount?: number;
	/** 支付账号 */
	alipayAccount?: string;
	/**
	 * 业务类型: 1-档口(市场购);2-线上平台（透明购）;5-线下平台（线下购）
	 * @format int32
	 */
	bizType?: number;
	/**
	 * 结算单号ID【修改使用】
	 * @format int64
	 */
	id?: number;
	/** 结算单号ID列表 */
	idList?: number[];
	manageUserId?: string;
	manageUsername?: string;
	/** 应结算金额 */
	needSettleAmount?: number;
	/**
	 * 实际支付时间
	 * @format date-time
	 */
	payTime?: string;
	/** 交易流水号 */
	tradeNo?: string;
}

/** StallSettlementOrderRespDTO */
export interface StallSettlementOrderRespDTO {
	/** 对账流水号 */
	accountCheckBizId?: string;
	/** 支付账户类型；1：支付宝；2：跨境宝 */
	accountType?: string;
	/** 支付账号 */
	alipayAccount?: string;
	/**
	 * 业务类型: 1-档口(市场购);2-线上平台（透明购）;5-线下平台（线下购）
	 * @format int32
	 */
	bizType?: number;
	/**
	 * 完成时间
	 * @format date-time
	 */
	completeTime?: string;
	/**
	 * 创建日期
	 * @format date-time
	 */
	createTime?: string;
	/** 结算金额 */
	money?: number;
	/**
	 * 结算数量
	 * @format int32
	 */
	num?: number;
	/**
	 * 实际支付时间
	 * @format date-time
	 */
	payTime?: string;
	/** 平台商品SPU */
	productCode?: string;
	/** 商品图片(优先属性图，否则主图) */
	productImg?: string;
	/** 商品链接 */
	productLink?: string;
	/** 商品名称 */
	productName?: string;
	/** 参考单价 */
	productPrice?: number;
	/** 商品属性 */
	productProp?: string;
	/** 平台商品SKU */
	productSku?: string;
	/**
	 * 采购单号
	 * @format int64
	 */
	purchaseId?: number;
	/** 采购单编号 */
	purchaseNo?: string;
	/** 操作员id */
	purchaseUserId?: string;
	/** 操作员姓名 */
	purchaseUserName?: string;
	/** 供应商店铺编码 */
	shopCode?: string;
	/** 供应商店铺名 */
	shopName?: string;
	/** 采购单价 */
	stallPrice?: number;
	/**
	 * 档口结算单ID
	 * @format int64
	 */
	stallSettlementOrderId?: number;
	/** 结算单号 */
	stallSettlementOrderNo?: string;
	/**
	 * 结算状态 0-待结算 1-已结算 2-结算中 3-结算失败
	 * @format int32
	 */
	status?: number;
	/**
	 * 系统来源: 1-D2C; 2-B2B
	 * @format int32
	 */
	systemSource?: number;
	/** 交易流水号 */
	tradeNo?: string;
	/**
	 * 更新日期
	 * @format date-time
	 */
	updateTime?: string;
	/** 拿货员ID */
	userId?: string;
	/** 拿货员名称 */
	userName?: string;
	/** 仓库编码 */
	wareCode?: string;
}

/** SubmitOrderReqDTO */
export interface SubmitOrderReqDTO {
	/** 采购单id列表 */
	purchaseIds?: number[];
	/** 操作用户id */
	userId?: string;
	/** 操作用户名 */
	userName?: string;
}

/** SuccessAccountNumReqDTO */
export interface SuccessAccountNumReqDTO {
	systemOrderNo?: string;
	type?: number[];
}

/** SupplierAgreeRefundReqDTO */
export interface SupplierAgreeRefundReqDTO {
	accountType?: string;
	address?: string;
	alipayAccount?: string;
	receiveMoney?: number;
	/** @format date-time */
	receiveMoneyTime?: string;
	refundContact?: string;
	refundPhone?: string;
	/** @format int32 */
	refundType?: number;
	remark?: string;
	/** @format date-time */
	returnDeadLine?: string;
	supplierOrderNo?: string;
	tradeNo?: string;
}

/** SupplierRefundReqDTO */
export interface SupplierRefundReqDTO {
	accountType?: string;
	alipayAccount?: string;
	purchasingAbnormalOrderNo?: string[];
	/** @format date-time */
	receiveMoneyTime?: string;
}

/** SupplierRejectRefundReqDTO */
export interface SupplierRejectRefundReqDTO {
	accountType?: string;
	alipayAccount?: string;
	money?: number;
	purchaseAbnormalNo?: string;
	rejectReason?: string;
	tbAccount?: string;
}

/** TakeOffOrdersReqDTO */
export interface TakeOffOrdersReqDTO {
	/** @format int64 */
	id?: number;
	userId?: string;
	username?: string;
}

/** TakeOrderMessageReqDTO */
export interface TakeOrderMessageReqDTO {
	message?: string;
	/** @format int64 */
	takeOrderId?: number;
	userId?: string;
	username?: string;
}

/** TakeOrderMessageRespDTO */
export interface TakeOrderMessageRespDTO {
	/** @format date-time */
	createTime?: string;
	message?: string;
	/** @format int64 */
	takeOrderId?: number;
	/** @format int64 */
	takeOrderMessageId?: number;
}

/** TakeOrderQuery */
export interface TakeOrderQuery {
	keyword?: string;
	marketCode?: string;
	/** @format int32 */
	pageNum?: number;
	/** @format int32 */
	pageSize?: number;
	/** @format int32 */
	startIndex?: number;
	/** @format int32 */
	status?: number;
	userId?: string;
}

/** TakeOrderRecordRespDTO */
export interface TakeOrderRecordRespDTO {
	/** @format date-time */
	createTime?: string;
	/** @format int32 */
	num?: number;
	/** @format int64 */
	takeOrderId?: number;
	/** @format int64 */
	takeOrderRecordId?: number;
	/** @format date-time */
	updateTime?: string;
}

/** TakeOrderRespDTO */
export interface TakeOrderRespDTO {
	abnormalDetailMessage?: string;
	abnormalDetailRemark?: string;
	/** @format int32 */
	abnormalNum?: number;
	/** @format date-time */
	completeTime?: string;
	/** @format date-time */
	createTime?: string;
	/** @format int32 */
	num?: number;
	/** @format int32 */
	offsaleNum?: number;
	productCode?: string;
	productImg?: string;
	productName?: string;
	productProp?: string;
	productSku?: string;
	productStallNumber?: string;
	/** @format int64 */
	purchaseId?: number;
	/** @format int64 */
	purchaseItemId?: number;
	purchaseNo?: string;
	purchaseUserMobile?: string;
	purchaseUserName?: string;
	shopCode?: string;
	shopName?: string;
	stallFloorNumber?: string;
	stallName?: string;
	stallPrice?: number;
	stallRoomNumber?: string;
	/** @format int32 */
	status?: number;
	/** @format date-time */
	syncDateTime?: string;
	/** @format int32 */
	takeNum?: number;
	/** @format int64 */
	takeOrderId?: number;
	/** @format date-time */
	updateTime?: string;
	userId?: string;
	userName?: string;
	wareCode?: string;
}

/** TakeRecordReqDTO */
export interface TakeRecordReqDTO {
	/** @format int32 */
	num?: number;
	/** @format int64 */
	takeOrderId?: number;
	/** @format int64 */
	takeOrderRecordId?: number;
	userId?: string;
	username?: string;
}

/** TakeRefundOrderQuery */
export interface TakeRefundOrderQuery {
	keyword?: string;
	/** @format int32 */
	pageNum?: number;
	/** @format int32 */
	pageSize?: number;
	/** @format int32 */
	startIndex?: number;
	/** @format int32 */
	status?: number;
	userId?: string;
}

/** TakeRefundOrderRespDTO */
export interface TakeRefundOrderRespDTO {
	/** @format date-time */
	createTime?: string;
	/** @format int32 */
	num?: number;
	productCode?: string;
	productImg?: string;
	productName?: string;
	productProp?: string;
	productSku?: string;
	productStallNumber?: string;
	/** @format int64 */
	purchaseId?: number;
	/** @format int64 */
	purchaseItemId?: number;
	purchaseNo?: string;
	purchaseUserMobile?: string;
	purchaseUserName?: string;
	refundMessage?: string;
	shopCode?: string;
	shopName?: string;
	stallFloorNumber?: string;
	stallName?: string;
	stallPrice?: number;
	stallRoomNumber?: string;
	/** @format int32 */
	status?: number;
	/** @format date-time */
	syncDateTime?: string;
	/** @format int64 */
	takeOrderId?: number;
	/** @format int64 */
	takeRefundOrderId?: number;
	/** @format date-time */
	updateTime?: string;
	userId?: string;
	userName?: string;
	wareCode?: string;
}

/** TakeRefundReqDTO */
export interface TakeRefundReqDTO {
	refundMessage?: string;
	/** @format int32 */
	status?: number;
	takeRefundOrderIdList?: number[];
	userId?: string;
	username?: string;
}

/** TakeRefundRespDTO */
export interface TakeRefundRespDTO {
	takeOrderRespDTO?: TakeOrderRespDTO;
	takeRefundOrderRespDTO?: TakeRefundOrderRespDTO;
}

/** TakeUserApplyAbnormalReqDTO */
export interface TakeUserApplyAbnormalReqDTO {
	abnormalDetailMessage?: string[];
	abnormalDetailRemark?: string;
	/** @format int64 */
	id?: number;
	userId?: string;
	username?: string;
}

/** TradeAccountRespDTO */
export interface TradeAccountRespDTO {
	accountType?: string;
	alipayAccount?: string;
	/** @format date-time */
	payTime?: string;
	tradeNo?: string;
}

/** URI */
export interface URI {
	absolute?: boolean;
	authority?: string;
	fragment?: string;
	host?: string;
	opaque?: boolean;
	path?: string;
	/** @format int32 */
	port?: number;
	query?: string;
	rawAuthority?: string;
	rawFragment?: string;
	rawPath?: string;
	rawQuery?: string;
	rawSchemeSpecificPart?: string;
	rawUserInfo?: string;
	scheme?: string;
	schemeSpecificPart?: string;
	userInfo?: string;
}

/** URL */
export interface URL {
	authority?: string;
	content?: object;
	/** @format int32 */
	defaultPort?: number;
	deserializedFields?: URLStreamHandler;
	file?: string;
	host?: string;
	path?: string;
	/** @format int32 */
	port?: number;
	protocol?: string;
	query?: string;
	ref?: string;
	/** @format int32 */
	serializedHashCode?: number;
	userInfo?: string;
}

/** URLStreamHandler */
export type URLStreamHandler = object;

/** UserCancelReqDTO */
export interface UserCancelReqDTO {
	systemOrderNoList?: string[];
}

/** UserManagerDetailRespDTO */
export interface UserManagerDetailRespDTO {
	businessRepresentativeId?: string;
	businessRepresentativeName?: string;
	customerDirectorId?: string;
	customerDirectorName?: string;
	/** @format int64 */
	customerId?: number;
	customerName?: string;
}

/** UserPurchaseAbnormalExtentReqDTO */
export interface UserPurchaseAbnormalExtentReqDTO {
	/** @format int64 */
	purchaseAbnormalOrderId?: number;
}

/** UserPurchaseAbnormalExtentRespDTO */
export interface UserPurchaseAbnormalExtentRespDTO {
	/** @format int32 */
	isHaveShopPurchase?: number;
	productSellPrice?: string;
}

/** UserPurchaseAbnormalHandleReqDTO */
export interface UserPurchaseAbnormalHandleReqDTO {
	/** @format int32 */
	customerOperateType?: number;
	customerProductLink?: string;
	customerProductSku?: string;
	/** @format int64 */
	customerShopId?: number;
	itemDetails?: PurchaseAbnormalItemDetailDTO[];
	/** @format int32 */
	payRepurchaseType?: number;
	/** @format int64 */
	purchaseAbnormalOrderId?: number;
	purchaseSkuRespDTO?: PurchaseSkuRespDTO;
}

/** UserPurchaseAbnormalPinDuoDuoDTO */
export interface UserPurchaseAbnormalPinDuoDuoDTO {
	/** @format int32 */
	goodNum?: number;
	groupBookingPrice?: string;
	originalPrice?: string;
	userChooseConfirmOriginalPrice?: string;
}

/** UserPurchaseAbnormalQueryReqDTO */
export interface UserPurchaseAbnormalQueryReqDTO {
	/** @format date-time */
	createTimeEnd?: string;
	/** @format date-time */
	createTimeStart?: string;
	/** @format int32 */
	customerOperateStatus?: number;
	customerOrderNo?: string;
	customerOrderNoList?: string[];
	/** @format int64 */
	customerShopId?: number;
	orderName?: string;
	orderNameList?: string[];
	orderNo?: string;
	/** @format int32 */
	pageNum?: number;
	/** @format int32 */
	pageSize?: number;
	platformOrderNo?: string;
	platformOrderNoList?: string[];
	productSku?: string;
	productSkuList?: string[];
	/** @format int64 */
	purchaseAbnormalOrderId?: number;
}

/** UserPurchaseAbnormalRespDTO */
export interface UserPurchaseAbnormalRespDTO {
	abnormalDetailMessage?: string;
	abnormalDetailMessageJp?: string;
	/** @format int32 */
	abnormalDetailType?: number;
	changeLinkSellPrice?: string;
	/** @format date-time */
	createTime?: string;
	customerOperateMessage?: string;
	/** @format int32 */
	customerOperateStatus?: number;
	/** @format int32 */
	customerOperateType?: number;
	customerOptionList?: number[];
	customerOrderNo?: string;
	customerProductLink?: string;
	customerProductSku?: string;
	imageList?: string[];
	/** @format int32 */
	isHaveShopPurchase?: number;
	itemDetails?: PurchaseAbnormalItemDetailDTO[];
	/** @format int32 */
	num?: number;
	orderItemStatusInfoDTO?: OrderItemStatusInfoDTO;
	orderName?: string;
	orderNo?: string;
	/** @format int32 */
	orderType?: number;
	/** @format int32 */
	payRepurchaseType?: number;
	/** @format int64 */
	platformOrderId?: number;
	platformOrderNo?: string;
	productCode?: string;
	productImg?: string;
	productLink?: string;
	productName?: string;
	productProp?: string;
	productSellPrice?: string;
	productSku?: string;
	/** @format int64 */
	purchaseAbnormalOrderId?: number;
	purchaseOperateMessage?: string;
	/** @format int32 */
	purchaseOperateType?: number;
	purchaseProductProp?: string;
	/** @format int32 */
	purchaseRemakeStatus?: number;
	purchaseUserProductLink?: string;
	purchaseUserProductSku?: string;
	/** @format int32 */
	reachMaxRemakeTimes?: number;
	remakeMoney?: number;
	remark?: string;
	stationCode?: string;
	/** @format int32 */
	transformStockStatus?: number;
	/** @format int32 */
	type?: number;
	/** @format int32 */
	userChooseNum?: number;
	/** @format int32 */
	userExpected?: number;
}

/** WmsHandleSuccessReqDTO */
export interface WmsHandleSuccessReqDTO {
	flowCode?: string;
}

/** BizResponse«ImportLogsiticNumbersRespDTO» */
export interface BizResponseImportLogsiticNumbersRespDTO {
	code?: string;
	data?: ImportLogsiticNumbersRespDTO;
	msg?: string;
	success?: boolean;
}

/** BizResponse«KuaiDiTrackRespDTO» */
export interface BizResponseKuaiDiTrackRespDTO {
	code?: string;
	data?: KuaiDiTrackRespDTO;
	msg?: string;
	success?: boolean;
}

/** BizResponse«List«AbnormalOrderUnitPirceRespDTO»» */
export interface BizResponseListAbnormalOrderUnitPirceRespDTO {
	code?: string;
	data?: AbnormalOrderUnitPirceRespDTO[];
	msg?: string;
	success?: boolean;
}

/** BizResponse«List«HistoryPriceRespDTO»» */
export interface BizResponseListHistoryPriceRespDTO {
	code?: string;
	data?: HistoryPriceRespDTO[];
	msg?: string;
	success?: boolean;
}

/** BizResponse«List«Map«int,int»»» */
export interface BizResponseListMapIntInt {
	code?: string;
	data?: MapIntInt[];
	msg?: string;
	success?: boolean;
}

/** BizResponse«List«MerchandiserRelationLogRespDTO»» */
export interface BizResponseListMerchandiserRelationLogRespDTO {
	code?: string;
	data?: MerchandiserRelationLogRespDTO[];
	msg?: string;
	success?: boolean;
}

/** BizResponse«List«OemPaymentListRespDTO»» */
export interface BizResponseListOemPaymentListRespDTO {
	code?: string;
	data?: OemPaymentListRespDTO[];
	msg?: string;
	success?: boolean;
}

/** BizResponse«List«OemSupplierReturnListRespDTO»» */
export interface BizResponseListOemSupplierReturnListRespDTO {
	code?: string;
	data?: OemSupplierReturnListRespDTO[];
	msg?: string;
	success?: boolean;
}

/** BizResponse«List«OrderItemRelationRespDTO»» */
export interface BizResponseListOrderItemRelationRespDTO {
	code?: string;
	data?: OrderItemRelationRespDTO[];
	msg?: string;
	success?: boolean;
}

/** BizResponse«List«PurchaseAbnormalNumRespDTO»» */
export interface BizResponseListPurchaseAbnormalNumRespDTO {
	code?: string;
	data?: PurchaseAbnormalNumRespDTO[];
	msg?: string;
	success?: boolean;
}

/** BizResponse«List«PurchaseAbnormalSupplierRefundOrderRespDTO»» */
export interface BizResponseListPurchaseAbnormalSupplierRefundOrderRespDTO {
	code?: string;
	data?: PurchaseAbnormalSupplierRefundOrderRespDTO[];
	msg?: string;
	success?: boolean;
}

/** BizResponse«List«PurchaseOrderAndAbnormalRespDTO»» */
export interface BizResponseListPurchaseOrderAndAbnormalRespDTO {
	code?: string;
	data?: PurchaseOrderAndAbnormalRespDTO[];
	msg?: string;
	success?: boolean;
}

/** BizResponse«List«PurchaseOrderItemDTO»» */
export interface BizResponseListPurchaseOrderItemDTO {
	code?: string;
	data?: PurchaseOrderItemDTO[];
	msg?: string;
	success?: boolean;
}

/** BizResponse«List«PurchaseOrderRespDTO»» */
export interface BizResponseListPurchaseOrderRespDTO {
	code?: string;
	data?: PurchaseOrderRespDTO[];
	msg?: string;
	success?: boolean;
}

/** BizResponse«List«QueryAbnormalCountRespDTO»» */
export interface BizResponseListQueryAbnormalCountRespDTO {
	code?: string;
	data?: QueryAbnormalCountRespDTO[];
	msg?: string;
	success?: boolean;
}

/** BizResponse«List«QueryAbnormalItemCountRespDTO»» */
export interface BizResponseListQueryAbnormalItemCountRespDTO {
	code?: string;
	data?: QueryAbnormalItemCountRespDTO[];
	msg?: string;
	success?: boolean;
}

/** BizResponse«List«QueryOemPurchaseOperateLogRespDTO»» */
export interface BizResponseListQueryOemPurchaseOperateLogRespDTO {
	code?: string;
	data?: QueryOemPurchaseOperateLogRespDTO[];
	msg?: string;
	success?: boolean;
}

/** BizResponse«List«SeparateOrderRespDTO»» */
export interface BizResponseListSeparateOrderRespDTO {
	code?: string;
	data?: SeparateOrderRespDTO[];
	msg?: string;
	success?: boolean;
}

/** BizResponse«List«SkuProductCustomsRespDTO»» */
export interface BizResponseListSkuProductCustomsRespDTO {
	code?: string;
	data?: SkuProductCustomsRespDTO[];
	msg?: string;
	success?: boolean;
}

/** BizResponse«List«TakeOrderMessageRespDTO»» */
export interface BizResponseListTakeOrderMessageRespDTO {
	code?: string;
	data?: TakeOrderMessageRespDTO[];
	msg?: string;
	success?: boolean;
}

/** BizResponse«List«TakeOrderRecordRespDTO»» */
export interface BizResponseListTakeOrderRecordRespDTO {
	code?: string;
	data?: TakeOrderRecordRespDTO[];
	msg?: string;
	success?: boolean;
}

/** BizResponse«List«string»» */
export interface BizResponseListString {
	code?: string;
	data?: string[];
	msg?: string;
	success?: boolean;
}

/** BizResponse«Map«long,PurchaseItemUnitPriceDTO»» */
export interface BizResponseMapLongPurchaseItemUnitPriceDTO {
	code?: string;
	data?: Record<string, PurchaseItemUnitPriceDTO>;
	msg?: string;
	success?: boolean;
}

/** BizResponse«Map«long,bigdecimal»» */
export interface BizResponseMapLongBigdecimal {
	code?: string;
	data?: Record<string, number>;
	msg?: string;
	success?: boolean;
}

/** BizResponse«OemAbnormalGetInfoRespDTO» */
export interface BizResponseOemAbnormalGetInfoRespDTO {
	code?: string;
	data?: OemAbnormalGetInfoRespDTO;
	msg?: string;
	success?: boolean;
}

/** BizResponse«OemPurchaseOrderItemRespDTO» */
export interface BizResponseOemPurchaseOrderItemRespDTO {
	code?: string;
	data?: OemPurchaseOrderItemRespDTO;
	msg?: string;
	success?: boolean;
}

/** BizResponse«OemPurchaseOrderRespDTO» */
export interface BizResponseOemPurchaseOrderRespDTO {
	code?: string;
	data?: OemPurchaseOrderRespDTO;
	msg?: string;
	success?: boolean;
}

/** BizResponse«Page«ListOemPurchaseAbnormalRespDTO»» */
export interface BizResponsePageListOemPurchaseAbnormalRespDTO {
	code?: string;
	data?: PageListOemPurchaseAbnormalRespDTO;
	msg?: string;
	success?: boolean;
}

/** BizResponse«Page«ListOemPurchaseOrderRespDTO»» */
export interface BizResponsePageListOemPurchaseOrderRespDTO {
	code?: string;
	data?: PageListOemPurchaseOrderRespDTO;
	msg?: string;
	success?: boolean;
}

/** BizResponse«Page«ListPurchaseOrderRespDTO»» */
export interface BizResponsePageListPurchaseOrderRespDTO {
	code?: string;
	data?: PageListPurchaseOrderRespDTO;
	msg?: string;
	success?: boolean;
}

/** BizResponse«Page«MerchandiserRelationRespDTO»» */
export interface BizResponsePageMerchandiserRelationRespDTO {
	code?: string;
	data?: PageMerchandiserRelationRespDTO;
	msg?: string;
	success?: boolean;
}

/** BizResponse«Page«OfflineSettlementOrderRespDTO»» */
export interface BizResponsePageOfflineSettlementOrderRespDTO {
	code?: string;
	data?: PageOfflineSettlementOrderRespDTO;
	msg?: string;
	success?: boolean;
}

/** BizResponse«Page«PaymentRequestRespDTO»» */
export interface BizResponsePagePaymentRequestRespDTO {
	code?: string;
	data?: PagePaymentRequestRespDTO;
	msg?: string;
	success?: boolean;
}

/** BizResponse«Page«PurchaseAbnormalRespDTO»» */
export interface BizResponsePagePurchaseAbnormalRespDTO {
	code?: string;
	data?: PagePurchaseAbnormalRespDTO;
	msg?: string;
	success?: boolean;
}

/** BizResponse«Page«PurchaseLogRespDTO»» */
export interface BizResponsePagePurchaseLogRespDTO {
	code?: string;
	data?: PagePurchaseLogRespDTO;
	msg?: string;
	success?: boolean;
}

/** BizResponse«Page«QueryPurchaseOrderRespDTO»» */
export interface BizResponsePageQueryPurchaseOrderRespDTO {
	code?: string;
	data?: PageQueryPurchaseOrderRespDTO;
	msg?: string;
	success?: boolean;
}

/** BizResponse«Page«RpaPurchaseAbnormalRespDTO»» */
export interface BizResponsePageRpaPurchaseAbnormalRespDTO {
	code?: string;
	data?: PageRpaPurchaseAbnormalRespDTO;
	msg?: string;
	success?: boolean;
}

/** BizResponse«Page«StallSettlementOrderRespDTO»» */
export interface BizResponsePageStallSettlementOrderRespDTO {
	code?: string;
	data?: PageStallSettlementOrderRespDTO;
	msg?: string;
	success?: boolean;
}

/** BizResponse«Page«TakeOrderRespDTO»» */
export interface BizResponsePageTakeOrderRespDTO {
	code?: string;
	data?: PageTakeOrderRespDTO;
	msg?: string;
	success?: boolean;
}

/** BizResponse«Page«TakeRefundOrderRespDTO»» */
export interface BizResponsePageTakeRefundOrderRespDTO {
	code?: string;
	data?: PageTakeRefundOrderRespDTO;
	msg?: string;
	success?: boolean;
}

/** BizResponse«Page«UserPurchaseAbnormalRespDTO»» */
export interface BizResponsePageUserPurchaseAbnormalRespDTO {
	code?: string;
	data?: PageUserPurchaseAbnormalRespDTO;
	msg?: string;
	success?: boolean;
}

/** BizResponse«PaymentRequestRespDTO» */
export interface BizResponsePaymentRequestRespDTO {
	code?: string;
	data?: PaymentRequestRespDTO;
	msg?: string;
	success?: boolean;
}

/** BizResponse«PurchaseAbnormalOrderRespDTO» */
export interface BizResponsePurchaseAbnormalOrderRespDTO {
	code?: string;
	data?: PurchaseAbnormalOrderRespDTO;
	msg?: string;
	success?: boolean;
}

/** BizResponse«PurchaseAbnormalRespDTO» */
export interface BizResponsePurchaseAbnormalRespDTO {
	code?: string;
	data?: PurchaseAbnormalRespDTO;
	msg?: string;
	success?: boolean;
}

/** BizResponse«PurchaseExpressRespDTO» */
export interface BizResponsePurchaseExpressRespDTO {
	code?: string;
	data?: PurchaseExpressRespDTO;
	msg?: string;
	success?: boolean;
}

/** BizResponse«PurchaseOrderCalculateRespDTO» */
export interface BizResponsePurchaseOrderCalculateRespDTO {
	code?: string;
	data?: PurchaseOrderCalculateRespDTO;
	msg?: string;
	success?: boolean;
}

/** BizResponse«PurchaseOrderDetailRespDTO» */
export interface BizResponsePurchaseOrderDetailRespDTO {
	code?: string;
	data?: PurchaseOrderDetailRespDTO;
	msg?: string;
	success?: boolean;
}

/** BizResponse«PurchaseOrderRespDTO» */
export interface BizResponsePurchaseOrderRespDTO {
	code?: string;
	data?: PurchaseOrderRespDTO;
	msg?: string;
	success?: boolean;
}

/** BizResponse«QueryBargainInfoRespDTO» */
export interface BizResponseQueryBargainInfoRespDTO {
	code?: string;
	data?: QueryBargainInfoRespDTO;
	msg?: string;
	success?: boolean;
}

/** BizResponse«QueryEstimateStockTimeRespDTO» */
export interface BizResponseQueryEstimateStockTimeRespDTO {
	code?: string;
	data?: QueryEstimateStockTimeRespDTO;
	msg?: string;
	success?: boolean;
}

/** BizResponse«QueryItemExpressInfoRespDTO» */
export interface BizResponseQueryItemExpressInfoRespDTO {
	code?: string;
	data?: QueryItemExpressInfoRespDTO;
	msg?: string;
	success?: boolean;
}

/** BizResponse«QueryPayStageRespDTO» */
export interface BizResponseQueryPayStageRespDTO {
	code?: string;
	data?: QueryPayStageRespDTO;
	msg?: string;
	success?: boolean;
}

/** BizResponse«QueryUnitPriceBySystemNoRespDTO» */
export interface BizResponseQueryUnitPriceBySystemNoRespDTO {
	code?: string;
	data?: QueryUnitPriceBySystemNoRespDTO;
	msg?: string;
	success?: boolean;
}

/** BizResponse«RpaPurchaseAbnormalRespDTO» */
export interface BizResponseRpaPurchaseAbnormalRespDTO {
	code?: string;
	data?: RpaPurchaseAbnormalRespDTO;
	msg?: string;
	success?: boolean;
}

/** BizResponse«StallSettlementOrderRespDTO» */
export interface BizResponseStallSettlementOrderRespDTO {
	code?: string;
	data?: StallSettlementOrderRespDTO;
	msg?: string;
	success?: boolean;
}

/** BizResponse«TakeOrderRespDTO» */
export interface BizResponseTakeOrderRespDTO {
	code?: string;
	data?: TakeOrderRespDTO;
	msg?: string;
	success?: boolean;
}

/** BizResponse«TakeRefundRespDTO» */
export interface BizResponseTakeRefundRespDTO {
	code?: string;
	data?: TakeRefundRespDTO;
	msg?: string;
	success?: boolean;
}

/** BizResponse«UserManagerDetailRespDTO» */
export interface BizResponseUserManagerDetailRespDTO {
	code?: string;
	data?: UserManagerDetailRespDTO;
	msg?: string;
	success?: boolean;
}

/** BizResponse«UserPurchaseAbnormalExtentRespDTO» */
export interface BizResponseUserPurchaseAbnormalExtentRespDTO {
	code?: string;
	data?: UserPurchaseAbnormalExtentRespDTO;
	msg?: string;
	success?: boolean;
}

/** BizResponse«UserPurchaseAbnormalPinDuoDuoDTO» */
export interface BizResponseUserPurchaseAbnormalPinDuoDuoDTO {
	code?: string;
	data?: UserPurchaseAbnormalPinDuoDuoDTO;
	msg?: string;
	success?: boolean;
}

/** BizResponse«UserPurchaseAbnormalRespDTO» */
export interface BizResponseUserPurchaseAbnormalRespDTO {
	code?: string;
	data?: UserPurchaseAbnormalRespDTO;
	msg?: string;
	success?: boolean;
}

/** BizResponse«Void» */
export interface BizResponseVoid {
	code?: string;
	msg?: string;
	success?: boolean;
}

/** BizResponse«bigdecimal» */
export interface BizResponseBigdecimal {
	code?: string;
	data?: number;
	msg?: string;
	success?: boolean;
}

/** BizResponse«boolean» */
export interface BizResponseBoolean {
	code?: string;
	data?: boolean;
	msg?: string;
	success?: boolean;
}

/** BizResponse«int» */
export interface BizResponseInt {
	code?: string;
	/** @format int32 */
	data?: number;
	msg?: string;
	success?: boolean;
}

/** BizResponse«long» */
export interface BizResponseLong {
	code?: string;
	/** @format int64 */
	data?: number;
	msg?: string;
	success?: boolean;
}

/** BizResponse«object» */
export interface BizResponseObject {
	code?: string;
	data?: object;
	msg?: string;
	success?: boolean;
}

/** Map«int,int» */
export type MapIntInt = Record<string, any>;

/** Map«long,PurchaseItemUnitPriceDTO» */
export type MapLongPurchaseItemUnitPriceDTO = Record<
	string,
	PurchaseItemUnitPriceDTO
>;

/** Map«long,bigdecimal» */
export type MapLongBigdecimal = Record<string, number>;

/** Page«ListOemPurchaseAbnormalRespDTO» */
export interface PageListOemPurchaseAbnormalRespDTO {
	countId?: string;
	/** @format int64 */
	current?: number;
	hitCount?: boolean;
	/** @format int64 */
	maxLimit?: number;
	optimizeCountSql?: boolean;
	orders?: OrderItem[];
	/** @format int64 */
	pages?: number;
	records?: ListOemPurchaseAbnormalRespDTO[];
	searchCount?: boolean;
	/** @format int64 */
	size?: number;
	/** @format int64 */
	total?: number;
}

/** Page«ListOemPurchaseOrderRespDTO» */
export interface PageListOemPurchaseOrderRespDTO {
	countId?: string;
	/** @format int64 */
	current?: number;
	hitCount?: boolean;
	/** @format int64 */
	maxLimit?: number;
	optimizeCountSql?: boolean;
	orders?: OrderItem[];
	/** @format int64 */
	pages?: number;
	records?: ListOemPurchaseOrderRespDTO[];
	searchCount?: boolean;
	/** @format int64 */
	size?: number;
	/** @format int64 */
	total?: number;
}

/** Page«ListPurchaseOrderRespDTO» */
export interface PageListPurchaseOrderRespDTO {
	countId?: string;
	/** @format int64 */
	current?: number;
	hitCount?: boolean;
	/** @format int64 */
	maxLimit?: number;
	optimizeCountSql?: boolean;
	orders?: OrderItem[];
	/** @format int64 */
	pages?: number;
	records?: ListPurchaseOrderRespDTO[];
	searchCount?: boolean;
	/** @format int64 */
	size?: number;
	/** @format int64 */
	total?: number;
}

/** Page«MerchandiserRelationRespDTO» */
export interface PageMerchandiserRelationRespDTO {
	countId?: string;
	/** @format int64 */
	current?: number;
	hitCount?: boolean;
	/** @format int64 */
	maxLimit?: number;
	optimizeCountSql?: boolean;
	orders?: OrderItem[];
	/** @format int64 */
	pages?: number;
	records?: MerchandiserRelationRespDTO[];
	searchCount?: boolean;
	/** @format int64 */
	size?: number;
	/** @format int64 */
	total?: number;
}

/** Page«OfflineSettlementOrderRespDTO» */
export interface PageOfflineSettlementOrderRespDTO {
	countId?: string;
	/** @format int64 */
	current?: number;
	hitCount?: boolean;
	/** @format int64 */
	maxLimit?: number;
	optimizeCountSql?: boolean;
	orders?: OrderItem[];
	/** @format int64 */
	pages?: number;
	records?: OfflineSettlementOrderRespDTO[];
	searchCount?: boolean;
	/** @format int64 */
	size?: number;
	/** @format int64 */
	total?: number;
}

/** Page«PaymentRequestRespDTO» */
export interface PagePaymentRequestRespDTO {
	countId?: string;
	/** @format int64 */
	current?: number;
	hitCount?: boolean;
	/** @format int64 */
	maxLimit?: number;
	optimizeCountSql?: boolean;
	orders?: OrderItem[];
	/** @format int64 */
	pages?: number;
	records?: PaymentRequestRespDTO[];
	searchCount?: boolean;
	/** @format int64 */
	size?: number;
	/** @format int64 */
	total?: number;
}

/** Page«PurchaseAbnormalRespDTO» */
export interface PagePurchaseAbnormalRespDTO {
	countId?: string;
	/** @format int64 */
	current?: number;
	hitCount?: boolean;
	/** @format int64 */
	maxLimit?: number;
	optimizeCountSql?: boolean;
	orders?: OrderItem[];
	/** @format int64 */
	pages?: number;
	records?: PurchaseAbnormalRespDTO[];
	searchCount?: boolean;
	/** @format int64 */
	size?: number;
	/** @format int64 */
	total?: number;
}

/** Page«PurchaseLogRespDTO» */
export interface PagePurchaseLogRespDTO {
	countId?: string;
	/** @format int64 */
	current?: number;
	hitCount?: boolean;
	/** @format int64 */
	maxLimit?: number;
	optimizeCountSql?: boolean;
	orders?: OrderItem[];
	/** @format int64 */
	pages?: number;
	records?: PurchaseLogRespDTO[];
	searchCount?: boolean;
	/** @format int64 */
	size?: number;
	/** @format int64 */
	total?: number;
}

/** Page«QueryPurchaseOrderRespDTO» */
export interface PageQueryPurchaseOrderRespDTO {
	countId?: string;
	/** @format int64 */
	current?: number;
	hitCount?: boolean;
	/** @format int64 */
	maxLimit?: number;
	optimizeCountSql?: boolean;
	orders?: OrderItem[];
	/** @format int64 */
	pages?: number;
	records?: QueryPurchaseOrderRespDTO[];
	searchCount?: boolean;
	/** @format int64 */
	size?: number;
	/** @format int64 */
	total?: number;
}

/** Page«RpaPurchaseAbnormalRespDTO» */
export interface PageRpaPurchaseAbnormalRespDTO {
	countId?: string;
	/** @format int64 */
	current?: number;
	hitCount?: boolean;
	/** @format int64 */
	maxLimit?: number;
	optimizeCountSql?: boolean;
	orders?: OrderItem[];
	/** @format int64 */
	pages?: number;
	records?: RpaPurchaseAbnormalRespDTO[];
	searchCount?: boolean;
	/** @format int64 */
	size?: number;
	/** @format int64 */
	total?: number;
}

/** Page«StallSettlementOrderRespDTO» */
export interface PageStallSettlementOrderRespDTO {
	countId?: string;
	/** @format int64 */
	current?: number;
	hitCount?: boolean;
	/** @format int64 */
	maxLimit?: number;
	optimizeCountSql?: boolean;
	orders?: OrderItem[];
	/** @format int64 */
	pages?: number;
	records?: StallSettlementOrderRespDTO[];
	searchCount?: boolean;
	/** @format int64 */
	size?: number;
	/** @format int64 */
	total?: number;
}

/** Page«TakeOrderRespDTO» */
export interface PageTakeOrderRespDTO {
	countId?: string;
	/** @format int64 */
	current?: number;
	hitCount?: boolean;
	/** @format int64 */
	maxLimit?: number;
	optimizeCountSql?: boolean;
	orders?: OrderItem[];
	/** @format int64 */
	pages?: number;
	records?: TakeOrderRespDTO[];
	searchCount?: boolean;
	/** @format int64 */
	size?: number;
	/** @format int64 */
	total?: number;
}

/** Page«TakeRefundOrderRespDTO» */
export interface PageTakeRefundOrderRespDTO {
	countId?: string;
	/** @format int64 */
	current?: number;
	hitCount?: boolean;
	/** @format int64 */
	maxLimit?: number;
	optimizeCountSql?: boolean;
	orders?: OrderItem[];
	/** @format int64 */
	pages?: number;
	records?: TakeRefundOrderRespDTO[];
	searchCount?: boolean;
	/** @format int64 */
	size?: number;
	/** @format int64 */
	total?: number;
}

/** Page«UserPurchaseAbnormalRespDTO» */
export interface PageUserPurchaseAbnormalRespDTO {
	countId?: string;
	/** @format int64 */
	current?: number;
	hitCount?: boolean;
	/** @format int64 */
	maxLimit?: number;
	optimizeCountSql?: boolean;
	orders?: OrderItem[];
	/** @format int64 */
	pages?: number;
	records?: UserPurchaseAbnormalRespDTO[];
	searchCount?: boolean;
	/** @format int64 */
	size?: number;
	/** @format int64 */
	total?: number;
}

import axios, {
	AxiosInstance,
	AxiosRequestConfig,
	HeadersDefaults,
	ResponseType
} from 'axios';

export type QueryParamsType = Record<string | number, any>;

export interface FullRequestParams
	extends Omit<
		AxiosRequestConfig,
		'data' | 'params' | 'url' | 'responseType'
	> {
	/** set parameter to `true` for call `securityWorker` for this request */
	secure?: boolean;
	/** request path */
	path: string;
	/** content type of request body */
	type?: ContentType;
	/** query params */
	query?: QueryParamsType;
	/** format of response (i.e. response.json() -> format: "json") */
	format?: ResponseType;
	/** request body */
	body?: unknown;
}

export type RequestParams = Omit<
	FullRequestParams,
	'body' | 'method' | 'query' | 'path'
>;

export interface ApiConfig<SecurityDataType = unknown>
	extends Omit<AxiosRequestConfig, 'data' | 'cancelToken'> {
	securityWorker?: (
		securityData: SecurityDataType | null
	) => Promise<AxiosRequestConfig | void> | AxiosRequestConfig | void;
	secure?: boolean;
	format?: ResponseType;
}

export enum ContentType {
	Json = 'application/json',
	FormData = 'multipart/form-data',
	UrlEncoded = 'application/x-www-form-urlencoded',
	Text = 'text/plain'
}

export class HttpClient<SecurityDataType = unknown> {
	public instance: AxiosInstance;
	private securityData: SecurityDataType | null = null;
	private securityWorker?: ApiConfig<SecurityDataType>['securityWorker'];
	private secure?: boolean;
	private format?: ResponseType;

	constructor({
		securityWorker,
		secure,
		format,
		...axiosConfig
	}: ApiConfig<SecurityDataType> = {}) {
		this.instance = axios.create({
			...axiosConfig,
			baseURL:
				axiosConfig.baseURL ||
				'//master-gateway.theckb.com:443/purchase'
		});
		this.secure = secure;
		this.format = format;
		this.securityWorker = securityWorker;
	}

	public setSecurityData = (data: SecurityDataType | null) => {
		this.securityData = data;
	};

	protected mergeRequestParams(
		params1: AxiosRequestConfig,
		params2?: AxiosRequestConfig
	): AxiosRequestConfig {
		const method = params1.method || (params2 && params2.method);

		return {
			...this.instance.defaults,
			...params1,
			...(params2 || {}),
			headers: {
				...((method &&
					this.instance.defaults.headers[
						method.toLowerCase() as keyof HeadersDefaults
					]) ||
					{}),
				...(params1.headers || {}),
				...((params2 && params2.headers) || {})
			}
		};
	}

	protected stringifyFormItem(formItem: unknown) {
		if (typeof formItem === 'object' && formItem !== null) {
			return JSON.stringify(formItem);
		} else {
			return `${formItem}`;
		}
	}

	protected createFormData(input: Record<string, unknown>): FormData {
		return Object.keys(input || {}).reduce((formData, key) => {
			const property = input[key];
			const propertyContent: any[] =
				property instanceof Array ? property : [property];

			for (const formItem of propertyContent) {
				const isFileType =
					formItem instanceof Blob || formItem instanceof File;
				formData.append(
					key,
					isFileType ? formItem : this.stringifyFormItem(formItem)
				);
			}

			return formData;
		}, new FormData());
	}

	public request = async <T = any, _E = any>({
		secure,
		path,
		type,
		query,
		format,
		body,
		...params
	}: FullRequestParams): Promise<T> => {
		const secureParams =
			((typeof secure === 'boolean' ? secure : this.secure) &&
				this.securityWorker &&
				(await this.securityWorker(this.securityData))) ||
			{};
		const requestParams = this.mergeRequestParams(params, secureParams);
		const responseFormat = format || this.format || undefined;

		if (
			type === ContentType.FormData &&
			body &&
			body !== null &&
			typeof body === 'object'
		) {
			body = this.createFormData(body as Record<string, unknown>);
		}

		if (
			type === ContentType.Text &&
			body &&
			body !== null &&
			typeof body !== 'string'
		) {
			body = JSON.stringify(body);
		}

		return this.instance
			.request({
				...requestParams,
				headers: {
					...(requestParams.headers || {}),
					...(type && type !== ContentType.FormData
						? { 'Content-Type': type }
						: {})
				},
				params: query,
				responseType: responseFormat,
				data: body,
				url: path
			})
			.then((response) => response.data);
	};
}

/**
 * @title API文档
 * @version v1.0.0
 * @baseUrl //master-gateway.theckb.com:443/purchase
 * @contact 史尼芙 (https://www.taobaockb.com/)
 *
 * 史尼芙API文档
 */
export class Api<
	SecurityDataType extends unknown
> extends HttpClient<SecurityDataType> {
	probe = {
		/**
		 * No description
		 *
		 * @tags livens-probe-controller
		 * @name Test
		 * @summary test
		 * @request GET:/Probe/test
		 */
		test: (params: RequestParams = {}) =>
			this.request<BizResponseObject, any>({
				path: `/Probe/test`,
				method: 'GET',
				...params
			})
	};
	actuator1 = {
		/**
		 * No description
		 *
		 * @tags monitor-contorller
		 * @name JvmInfo
		 * @summary jvmInfo
		 * @request GET:/actuator1/jvmInfo
		 */
		jvmInfo: (params: RequestParams = {}) =>
			this.request<void, any>({
				path: `/actuator1/jvmInfo`,
				method: 'GET',
				...params
			}),

		/**
		 * No description
		 *
		 * @tags monitor-contorller
		 * @name HeadActuator1
		 * @summary jvmInfo
		 * @request HEAD:/actuator1/jvmInfo
		 */
		headActuator1: (params: RequestParams = {}) =>
			this.request<void, any>({
				path: `/actuator1/jvmInfo`,
				method: 'HEAD',
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags monitor-contorller
		 * @name JvmInfo2
		 * @summary jvmInfo
		 * @request POST:/actuator1/jvmInfo
		 * @originalName jvmInfo
		 * @duplicate
		 */
		jvmInfo2: (params: RequestParams = {}) =>
			this.request<void, any>({
				path: `/actuator1/jvmInfo`,
				method: 'POST',
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags monitor-contorller
		 * @name JvmInfo3
		 * @summary jvmInfo
		 * @request PUT:/actuator1/jvmInfo
		 * @originalName jvmInfo
		 * @duplicate
		 */
		jvmInfo3: (params: RequestParams = {}) =>
			this.request<void, any>({
				path: `/actuator1/jvmInfo`,
				method: 'PUT',
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags monitor-contorller
		 * @name JvmInfo4
		 * @summary jvmInfo
		 * @request DELETE:/actuator1/jvmInfo
		 * @originalName jvmInfo
		 * @duplicate
		 */
		jvmInfo4: (params: RequestParams = {}) =>
			this.request<void, any>({
				path: `/actuator1/jvmInfo`,
				method: 'DELETE',
				...params
			}),

		/**
		 * No description
		 *
		 * @tags monitor-contorller
		 * @name OptionsActuator1
		 * @summary jvmInfo
		 * @request OPTIONS:/actuator1/jvmInfo
		 */
		optionsActuator1: (params: RequestParams = {}) =>
			this.request<void, any>({
				path: `/actuator1/jvmInfo`,
				method: 'OPTIONS',
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags monitor-contorller
		 * @name JvmInfo5
		 * @summary jvmInfo
		 * @request PATCH:/actuator1/jvmInfo
		 * @originalName jvmInfo
		 * @duplicate
		 */
		jvmInfo5: (params: RequestParams = {}) =>
			this.request<void, any>({
				path: `/actuator1/jvmInfo`,
				method: 'PATCH',
				type: ContentType.Json,
				...params
			})
	};
	api = {
		/**
		 * No description
		 *
		 * @tags api-open-barley-controller
		 * @name OpenBarleyRefundOrder
		 * @summary refundOrder
		 * @request POST:/api/open/barley/refundOrder
		 */
		openBarleyRefundOrder: (
			reqDTO: BarleyRefundOrderReqDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponseObject, any>({
				path: `/api/open/barley/refundOrder`,
				method: 'POST',
				body: reqDTO,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags api-open-barley-controller
		 * @name OpenBarleySubmitOrder
		 * @summary submitOrder
		 * @request POST:/api/open/barley/submitOrder
		 */
		openBarleySubmitOrder: (
			reqDTO: BarleySubmitOrderReqDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponseObject, any>({
				path: `/api/open/barley/submitOrder`,
				method: 'POST',
				body: reqDTO,
				type: ContentType.Json,
				...params
			})
	};
	log = {
		/**
		 * No description
		 *
		 * @tags purchase-log-controller
		 * @name Query
		 * @summary query
		 * @request POST:/log/query
		 */
		query: (reqDTO: PurchaseLogQuery, params: RequestParams = {}) =>
			this.request<BizResponsePagePurchaseLogRespDTO, any>({
				path: `/log/query`,
				method: 'POST',
				body: reqDTO,
				type: ContentType.Json,
				...params
			})
	};
	oem = {
		/**
		 * No description
		 *
		 * @tags oem-purchase-abnormal-controller
		 * @name PurchaseAbnormalApplyOutOfStockAbnormal
		 * @summary applyOutOfStockAbnormal
		 * @request POST:/oem/purchase/abnormal/applyOutOfStockAbnormal
		 */
		purchaseAbnormalApplyOutOfStockAbnormal: (
			reqDTO: ApplyOutOfStockAbnormalReqDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponseObject, any>({
				path: `/oem/purchase/abnormal/applyOutOfStockAbnormal`,
				method: 'POST',
				body: reqDTO,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags oem-purchase-abnormal-controller
		 * @name PurchaseAbnormalBackGetAbnormalImg
		 * @summary getAbnormalImg
		 * @request POST:/oem/purchase/abnormal/back/getAbnormalImg
		 */
		purchaseAbnormalBackGetAbnormalImg: (params: RequestParams = {}) =>
			this.request<BizResponseObject, any>({
				path: `/oem/purchase/abnormal/back/getAbnormalImg`,
				method: 'POST',
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags oem-purchase-abnormal-controller
		 * @name PurchaseAbnormalChangeDealType
		 * @summary changeDealType
		 * @request POST:/oem/purchase/abnormal/changeDealType
		 */
		purchaseAbnormalChangeDealType: (
			reqDTO: ChangeDealTypeReqDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponseObject, any>({
				path: `/oem/purchase/abnormal/changeDealType`,
				method: 'POST',
				body: reqDTO,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags oem-purchase-abnormal-controller
		 * @name PurchaseAbnormalChangeGoods
		 * @summary changeGoods
		 * @request POST:/oem/purchase/abnormal/changeGoods
		 */
		purchaseAbnormalChangeGoods: (
			reqDTO: OemPurchaseChangeGoodsReqDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponseObject, any>({
				path: `/oem/purchase/abnormal/changeGoods`,
				method: 'POST',
				body: reqDTO,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags oem-purchase-abnormal-controller
		 * @name PurchaseAbnormalContinueEntryWare
		 * @summary continueEntryWare
		 * @request POST:/oem/purchase/abnormal/continueEntryWare
		 */
		purchaseAbnormalContinueEntryWare: (
			reqDTO: ContinueEntryWareReqDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponseObject, any>({
				path: `/oem/purchase/abnormal/continueEntryWare`,
				method: 'POST',
				body: reqDTO,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags oem-purchase-abnormal-controller
		 * @name PurchaseAbnormalGetExistNormalCount
		 * @summary getExistNormalCount
		 * @request GET:/oem/purchase/abnormal/getExistNormalCount
		 */
		purchaseAbnormalGetExistNormalCount: (
			query: {
				/**
				 * oemPurchaseId
				 * @format int64
				 */
				oemPurchaseId: number;
			},
			params: RequestParams = {}
		) =>
			this.request<BizResponseBoolean, any>({
				path: `/oem/purchase/abnormal/getExistNormalCount`,
				method: 'GET',
				query: query,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags oem-purchase-abnormal-controller
		 * @name PurchaseAbnormalGetInfo
		 * @summary getInfo
		 * @request GET:/oem/purchase/abnormal/getInfo
		 */
		purchaseAbnormalGetInfo: (
			query: {
				/**
				 * oemPurchaseAbnormalOrderId
				 * @format int64
				 */
				oemPurchaseAbnormalOrderId: number;
			},
			params: RequestParams = {}
		) =>
			this.request<BizResponseOemAbnormalGetInfoRespDTO, any>({
				path: `/oem/purchase/abnormal/getInfo`,
				method: 'GET',
				query: query,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags oem-purchase-abnormal-controller
		 * @name PurchaseAbnormalGetMaxApplyNum
		 * @summary getMaxApplyNum
		 * @request GET:/oem/purchase/abnormal/getMaxApplyNum
		 */
		purchaseAbnormalGetMaxApplyNum: (
			query: {
				/**
				 * oemPurchaseAbnormalOrderId
				 * @format int64
				 */
				oemPurchaseAbnormalOrderId: number;
				/**
				 * purchaseOperateType
				 * @format int32
				 */
				purchaseOperateType: number;
			},
			params: RequestParams = {}
		) =>
			this.request<BizResponseInt, any>({
				path: `/oem/purchase/abnormal/getMaxApplyNum`,
				method: 'GET',
				query: query,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags oem-purchase-abnormal-controller
		 * @name PurchaseAbnormalPagePurchase
		 * @summary pagePurchase
		 * @request POST:/oem/purchase/abnormal/pagePurchase
		 */
		purchaseAbnormalPagePurchase: (
			query: PageOemPurchaseAbnormalQuery,
			params: RequestParams = {}
		) =>
			this.request<BizResponsePageListOemPurchaseAbnormalRespDTO, any>({
				path: `/oem/purchase/abnormal/pagePurchase`,
				method: 'POST',
				body: query,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags oem-purchase-abnormal-controller
		 * @name PurchaseAbnormalPaidFactoryException
		 * @summary paidFactoryException
		 * @request POST:/oem/purchase/abnormal/paidFactoryException
		 */
		purchaseAbnormalPaidFactoryException: (
			reqDTO: OemPaidFactoryExceptionReqDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponseObject, any>({
				path: `/oem/purchase/abnormal/paidFactoryException`,
				method: 'POST',
				body: reqDTO,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags oem-purchase-abnormal-controller
		 * @name PurchaseAbnormalReissue
		 * @summary reissue
		 * @request POST:/oem/purchase/abnormal/reissue
		 */
		purchaseAbnormalReissue: (
			reqDTO: OemReissueReqDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponseObject, any>({
				path: `/oem/purchase/abnormal/reissue`,
				method: 'POST',
				body: reqDTO,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags oem-purchase-abnormal-controller
		 * @name PurchaseAbnormalReturnGoods
		 * @summary returnGoods
		 * @request POST:/oem/purchase/abnormal/returnGoods
		 */
		purchaseAbnormalReturnGoods: (
			reqDTO: OemReturnGoodsReqDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponseObject, any>({
				path: `/oem/purchase/abnormal/returnGoods`,
				method: 'POST',
				body: reqDTO,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags oem-purchase-abnormal-controller
		 * @name PurchaseAbnormalReturnGoodsAndMoney
		 * @summary returnGoodsAndMoney
		 * @request POST:/oem/purchase/abnormal/returnGoodsAndMoney
		 */
		purchaseAbnormalReturnGoodsAndMoney: (
			reqDTO: OemReturnGoodsAndMoneyReqDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponseObject, any>({
				path: `/oem/purchase/abnormal/returnGoodsAndMoney`,
				method: 'POST',
				body: reqDTO,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags oem-purchase-abnormal-controller
		 * @name PurchaseAbnormalReturnMoney
		 * @summary 仅退款
		 * @request POST:/oem/purchase/abnormal/returnMoney
		 */
		purchaseAbnormalReturnMoney: (
			reqDTO: OemReturnMoneyReqDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponseObject, any>({
				path: `/oem/purchase/abnormal/returnMoney`,
				method: 'POST',
				body: reqDTO,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags oem-purchase-abnormal-controller
		 * @name PurchaseAbnormalToCompanyInventory
		 * @summary toCompanyInventory
		 * @request POST:/oem/purchase/abnormal/toCompanyInventory
		 */
		purchaseAbnormalToCompanyInventory: (
			reqDTO: OemToCompanyInventoryReqDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponseObject, any>({
				path: `/oem/purchase/abnormal/toCompanyInventory`,
				method: 'POST',
				body: reqDTO,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags oem-purchase-abnormal-controller
		 * @name PurchaseAbnormalUnallowedReturn
		 * @summary unallowedReturn
		 * @request POST:/oem/purchase/abnormal/unallowedReturn
		 */
		purchaseAbnormalUnallowedReturn: (
			reqDTO: OemUnallowedReturnReqDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponseObject, any>({
				path: `/oem/purchase/abnormal/unallowedReturn`,
				method: 'POST',
				body: reqDTO,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags oem-purchase-abnormal-controller
		 * @name PurchaseAbnormalUnpaidFactoryException
		 * @summary unpaidFactoryException
		 * @request POST:/oem/purchase/abnormal/unpaidFactoryException
		 */
		purchaseAbnormalUnpaidFactoryException: (
			reqDTO: OemUnpaidFactoryExceptionReqDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponseObject, any>({
				path: `/oem/purchase/abnormal/unpaidFactoryException`,
				method: 'POST',
				body: reqDTO,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags oem-purchase-controller
		 * @name PurchaseAddOemPurchaseOperateLog
		 * @summary addOemPurchaseOperateLog
		 * @request POST:/oem/purchase/addOemPurchaseOperateLog
		 */
		purchaseAddOemPurchaseOperateLog: (
			reqDTO: AddOemPurchaseOperateLogReqDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponseObject, any>({
				path: `/oem/purchase/addOemPurchaseOperateLog`,
				method: 'POST',
				body: reqDTO,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags oem-purchase-controller
		 * @name PurchaseAddPurchaseExpressNo
		 * @summary addPurchaseExpressNo
		 * @request POST:/oem/purchase/addPurchaseExpressNo
		 */
		purchaseAddPurchaseExpressNo: (
			reqDTO: OemPurchaseOrderExpressAddReqDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponseObject, any>({
				path: `/oem/purchase/addPurchaseExpressNo`,
				method: 'POST',
				body: reqDTO,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags oem-purchase-controller
		 * @name PurchaseBoundOrderNoCallBack
		 * @summary boundOrderNoCallBack
		 * @request POST:/oem/purchase/boundOrderNoCallBack
		 */
		purchaseBoundOrderNoCallBack: (
			req: BoundOrderNoCallBackReqDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponseBoolean, any>({
				path: `/oem/purchase/boundOrderNoCallBack`,
				method: 'POST',
				body: req,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags oem-purchase-controller
		 * @name PurchaseCreateFactoryException
		 * @summary createFactoryException
		 * @request POST:/oem/purchase/createFactoryException
		 */
		purchaseCreateFactoryException: (
			dto: CreateFactoryExceptionReqDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponseObject, any>({
				path: `/oem/purchase/createFactoryException`,
				method: 'POST',
				body: dto,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags oem-purchase-controller
		 * @name PurchaseEditItemInfo
		 * @summary editItemInfo
		 * @request POST:/oem/purchase/editItemInfo
		 */
		purchaseEditItemInfo: (
			dto: EditOemPurchaseItemReqDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponseObject, any>({
				path: `/oem/purchase/editItemInfo`,
				method: 'POST',
				body: dto,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags oem-purchase-controller
		 * @name PurchaseEditOemPurchaseOrder
		 * @summary editOemPurchaseOrder
		 * @request POST:/oem/purchase/editOemPurchaseOrder
		 */
		purchaseEditOemPurchaseOrder: (
			dto: EditOemPurchaseOrderReqDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponseObject, any>({
				path: `/oem/purchase/editOemPurchaseOrder`,
				method: 'POST',
				body: dto,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags oem-purchase-controller
		 * @name PurchaseGetCustom
		 * @summary getCustom
		 * @request POST:/oem/purchase/getCustom
		 */
		purchaseGetCustom: (
			query: OemCustomQueryReqDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponseListSkuProductCustomsRespDTO, any>({
				path: `/oem/purchase/getCustom`,
				method: 'POST',
				body: query,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags oem-purchase-controller
		 * @name PurchaseGetItemInfo
		 * @summary getItemInfo
		 * @request GET:/oem/purchase/getItemInfo
		 */
		purchaseGetItemInfo: (
			query?: {
				/**
				 * 采购商品id
				 * @format int64
				 */
				oemPurchaseItemId?: number;
			},
			params: RequestParams = {}
		) =>
			this.request<BizResponseOemPurchaseOrderItemRespDTO, any>({
				path: `/oem/purchase/getItemInfo`,
				method: 'GET',
				query: query,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags oem-purchase-controller
		 * @name PurchaseGetMaxApplyAmount
		 * @summary getMaxApplyAmount
		 * @request GET:/oem/purchase/getMaxApplyAmount
		 */
		purchaseGetMaxApplyAmount: (
			query: {
				/**
				 * oemPurchaseId
				 * @format int64
				 */
				oemPurchaseId: number;
			},
			params: RequestParams = {}
		) =>
			this.request<BizResponseBigdecimal, any>({
				path: `/oem/purchase/getMaxApplyAmount`,
				method: 'GET',
				query: query,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags oem-purchase-controller
		 * @name PurchaseGetOemPurchaseOrder
		 * @summary getOemPurchaseOrder
		 * @request GET:/oem/purchase/getOemPurchaseOrder
		 */
		purchaseGetOemPurchaseOrder: (
			query?: {
				/**
				 * 采购单id
				 * @format int64
				 */
				oemPurchaseId?: number;
			},
			params: RequestParams = {}
		) =>
			this.request<BizResponseOemPurchaseOrderRespDTO, any>({
				path: `/oem/purchase/getOemPurchaseOrder`,
				method: 'GET',
				query: query,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags oem-purchase-controller
		 * @name PurchaseGetPaymentList
		 * @summary getPaymentList
		 * @request POST:/oem/purchase/getPaymentList
		 */
		purchaseGetPaymentList: (
			reqDTO: OemPaymentListQueryReqDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponseListOemPaymentListRespDTO, any>({
				path: `/oem/purchase/getPaymentList`,
				method: 'POST',
				body: reqDTO,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags oem-purchase-controller
		 * @name PurchaseGetSupplierReturnList
		 * @summary getSupplierReturnList
		 * @request POST:/oem/purchase/getSupplierReturnList
		 */
		purchaseGetSupplierReturnList: (
			reqDTO: OemSupplierReturnListQueryReqDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponseListOemSupplierReturnListRespDTO, any>({
				path: `/oem/purchase/getSupplierReturnList`,
				method: 'POST',
				body: reqDTO,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags oem-purchase-controller
		 * @name PurchasePagePurchase
		 * @summary pagePurchase
		 * @request POST:/oem/purchase/pagePurchase
		 */
		purchasePagePurchase: (
			query: PageOemPurchaseOrderQuery,
			params: RequestParams = {}
		) =>
			this.request<BizResponsePageListOemPurchaseOrderRespDTO, any>({
				path: `/oem/purchase/pagePurchase`,
				method: 'POST',
				body: query,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags oem-purchase-controller
		 * @name PurchasePayStage
		 * @summary payStage
		 * @request POST:/oem/purchase/payStage
		 */
		purchasePayStage: (
			reqDTO: PurchasePayStageReqDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponseObject, any>({
				path: `/oem/purchase/payStage`,
				method: 'POST',
				body: reqDTO,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags oem-purchase-controller
		 * @name PurchaseQueryOemPurchaseOperateLog
		 * @summary queryOemPurchaseOperateLog
		 * @request GET:/oem/purchase/queryOemPurchaseOperateLog
		 */
		purchaseQueryOemPurchaseOperateLog: (
			query: {
				/**
				 * id
				 * @format int64
				 */
				id: number;
				/**
				 * type
				 * @format int32
				 */
				type: number;
			},
			params: RequestParams = {}
		) =>
			this.request<BizResponseListQueryOemPurchaseOperateLogRespDTO, any>(
				{
					path: `/oem/purchase/queryOemPurchaseOperateLog`,
					method: 'GET',
					query: query,
					...params
				}
			),

		/**
		 * No description
		 *
		 * @tags oem-purchase-controller
		 * @name PurchaseQueryPayStage
		 * @summary queryPayStage
		 * @request POST:/oem/purchase/queryPayStage
		 */
		purchaseQueryPayStage: (
			reqDTO: PurchasePayStageReqDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponseQueryPayStageRespDTO, any>({
				path: `/oem/purchase/queryPayStage`,
				method: 'POST',
				body: reqDTO,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags oem-purchase-controller
		 * @name PurchaseQueryUnitPriceBySystemNo
		 * @summary queryUnitPriceBySystemNo
		 * @request POST:/oem/purchase/queryUnitPriceBySystemNo
		 */
		purchaseQueryUnitPriceBySystemNo: (
			req: QueryUnitPriceBySystemNoReq,
			params: RequestParams = {}
		) =>
			this.request<BizResponseQueryUnitPriceBySystemNoRespDTO, any>({
				path: `/oem/purchase/queryUnitPriceBySystemNo`,
				method: 'POST',
				body: req,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags oem-purchase-controller
		 * @name PurchaseUpdateListReconciliationStatus
		 * @summary updateListReconciliationStatus
		 * @request POST:/oem/purchase/updateListReconciliationStatus
		 */
		purchaseUpdateListReconciliationStatus: (
			updateList: OemPaymentUpdateReqDTO[],
			params: RequestParams = {}
		) =>
			this.request<BizResponseBoolean, any>({
				path: `/oem/purchase/updateListReconciliationStatus`,
				method: 'POST',
				body: updateList,
				type: ContentType.Json,
				...params
			})
	};
	payment = {
		/**
		 * No description
		 *
		 * @tags 付款申请
		 * @name RequestAdd
		 * @summary 新增付款申请
		 * @request POST:/payment/request/add
		 */
		requestAdd: (
			paymentRequestReqDTO: PaymentRequestReqDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponseLong, any>({
				path: `/payment/request/add`,
				method: 'POST',
				body: paymentRequestReqDTO,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 付款申请
		 * @name RequestDingCallbackB2B
		 * @summary B2b回调
		 * @request GET:/payment/request/ding/callback/b2b
		 */
		requestDingCallbackB2B: (
			body: Record<string, object>,
			query?: {
				/** nonce */
				nonce?: string;
				/** signature */
				signature?: string;
				/** timestamp */
				timestamp?: string;
			},
			params: RequestParams = {}
		) =>
			this.request<Record<string, string>, any>({
				path: `/payment/request/ding/callback/b2b`,
				method: 'GET',
				query: query,
				body: body,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 付款申请
		 * @name HeadPayment
		 * @summary B2b回调
		 * @request HEAD:/payment/request/ding/callback/b2b
		 */
		headPayment: (
			body: Record<string, object>,
			query?: {
				/** nonce */
				nonce?: string;
				/** signature */
				signature?: string;
				/** timestamp */
				timestamp?: string;
			},
			params: RequestParams = {}
		) =>
			this.request<Record<string, string>, any>({
				path: `/payment/request/ding/callback/b2b`,
				method: 'HEAD',
				query: query,
				body: body,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 付款申请
		 * @name RequestDingCallbackB2B2
		 * @summary B2b回调
		 * @request POST:/payment/request/ding/callback/b2b
		 * @originalName requestDingCallbackB2B
		 * @duplicate
		 */
		requestDingCallbackB2B2: (
			body: Record<string, object>,
			query?: {
				/** nonce */
				nonce?: string;
				/** signature */
				signature?: string;
				/** timestamp */
				timestamp?: string;
			},
			params: RequestParams = {}
		) =>
			this.request<Record<string, string>, any>({
				path: `/payment/request/ding/callback/b2b`,
				method: 'POST',
				query: query,
				body: body,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 付款申请
		 * @name RequestDingCallbackB2B3
		 * @summary B2b回调
		 * @request PUT:/payment/request/ding/callback/b2b
		 * @originalName requestDingCallbackB2B
		 * @duplicate
		 */
		requestDingCallbackB2B3: (
			body: Record<string, object>,
			query?: {
				/** nonce */
				nonce?: string;
				/** signature */
				signature?: string;
				/** timestamp */
				timestamp?: string;
			},
			params: RequestParams = {}
		) =>
			this.request<Record<string, string>, any>({
				path: `/payment/request/ding/callback/b2b`,
				method: 'PUT',
				query: query,
				body: body,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 付款申请
		 * @name RequestDingCallbackB2B4
		 * @summary B2b回调
		 * @request DELETE:/payment/request/ding/callback/b2b
		 * @originalName requestDingCallbackB2B
		 * @duplicate
		 */
		requestDingCallbackB2B4: (
			body: Record<string, object>,
			query?: {
				/** nonce */
				nonce?: string;
				/** signature */
				signature?: string;
				/** timestamp */
				timestamp?: string;
			},
			params: RequestParams = {}
		) =>
			this.request<Record<string, string>, any>({
				path: `/payment/request/ding/callback/b2b`,
				method: 'DELETE',
				query: query,
				body: body,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 付款申请
		 * @name OptionsPayment
		 * @summary B2b回调
		 * @request OPTIONS:/payment/request/ding/callback/b2b
		 */
		optionsPayment: (
			body: Record<string, object>,
			query?: {
				/** nonce */
				nonce?: string;
				/** signature */
				signature?: string;
				/** timestamp */
				timestamp?: string;
			},
			params: RequestParams = {}
		) =>
			this.request<Record<string, string>, any>({
				path: `/payment/request/ding/callback/b2b`,
				method: 'OPTIONS',
				query: query,
				body: body,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 付款申请
		 * @name RequestDingCallbackB2B5
		 * @summary B2b回调
		 * @request PATCH:/payment/request/ding/callback/b2b
		 * @originalName requestDingCallbackB2B
		 * @duplicate
		 */
		requestDingCallbackB2B5: (
			body: Record<string, object>,
			query?: {
				/** nonce */
				nonce?: string;
				/** signature */
				signature?: string;
				/** timestamp */
				timestamp?: string;
			},
			params: RequestParams = {}
		) =>
			this.request<Record<string, string>, any>({
				path: `/payment/request/ding/callback/b2b`,
				method: 'PATCH',
				query: query,
				body: body,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 付款申请
		 * @name RequestDingCallbackD2C
		 * @summary D2C回调
		 * @request GET:/payment/request/ding/callback/d2c
		 */
		requestDingCallbackD2C: (
			body: Record<string, object>,
			query?: {
				/** nonce */
				nonce?: string;
				/** signature */
				signature?: string;
				/** timestamp */
				timestamp?: string;
			},
			params: RequestParams = {}
		) =>
			this.request<Record<string, string>, any>({
				path: `/payment/request/ding/callback/d2c`,
				method: 'GET',
				query: query,
				body: body,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 付款申请
		 * @name HeadPayment2
		 * @summary D2C回调
		 * @request HEAD:/payment/request/ding/callback/d2c
		 * @originalName headPayment
		 * @duplicate
		 */
		headPayment2: (
			body: Record<string, object>,
			query?: {
				/** nonce */
				nonce?: string;
				/** signature */
				signature?: string;
				/** timestamp */
				timestamp?: string;
			},
			params: RequestParams = {}
		) =>
			this.request<Record<string, string>, any>({
				path: `/payment/request/ding/callback/d2c`,
				method: 'HEAD',
				query: query,
				body: body,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 付款申请
		 * @name RequestDingCallbackD2C2
		 * @summary D2C回调
		 * @request POST:/payment/request/ding/callback/d2c
		 * @originalName requestDingCallbackD2C
		 * @duplicate
		 */
		requestDingCallbackD2C2: (
			body: Record<string, object>,
			query?: {
				/** nonce */
				nonce?: string;
				/** signature */
				signature?: string;
				/** timestamp */
				timestamp?: string;
			},
			params: RequestParams = {}
		) =>
			this.request<Record<string, string>, any>({
				path: `/payment/request/ding/callback/d2c`,
				method: 'POST',
				query: query,
				body: body,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 付款申请
		 * @name RequestDingCallbackD2C3
		 * @summary D2C回调
		 * @request PUT:/payment/request/ding/callback/d2c
		 * @originalName requestDingCallbackD2C
		 * @duplicate
		 */
		requestDingCallbackD2C3: (
			body: Record<string, object>,
			query?: {
				/** nonce */
				nonce?: string;
				/** signature */
				signature?: string;
				/** timestamp */
				timestamp?: string;
			},
			params: RequestParams = {}
		) =>
			this.request<Record<string, string>, any>({
				path: `/payment/request/ding/callback/d2c`,
				method: 'PUT',
				query: query,
				body: body,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 付款申请
		 * @name RequestDingCallbackD2C4
		 * @summary D2C回调
		 * @request DELETE:/payment/request/ding/callback/d2c
		 * @originalName requestDingCallbackD2C
		 * @duplicate
		 */
		requestDingCallbackD2C4: (
			body: Record<string, object>,
			query?: {
				/** nonce */
				nonce?: string;
				/** signature */
				signature?: string;
				/** timestamp */
				timestamp?: string;
			},
			params: RequestParams = {}
		) =>
			this.request<Record<string, string>, any>({
				path: `/payment/request/ding/callback/d2c`,
				method: 'DELETE',
				query: query,
				body: body,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 付款申请
		 * @name OptionsPayment2
		 * @summary D2C回调
		 * @request OPTIONS:/payment/request/ding/callback/d2c
		 * @originalName optionsPayment
		 * @duplicate
		 */
		optionsPayment2: (
			body: Record<string, object>,
			query?: {
				/** nonce */
				nonce?: string;
				/** signature */
				signature?: string;
				/** timestamp */
				timestamp?: string;
			},
			params: RequestParams = {}
		) =>
			this.request<Record<string, string>, any>({
				path: `/payment/request/ding/callback/d2c`,
				method: 'OPTIONS',
				query: query,
				body: body,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 付款申请
		 * @name RequestDingCallbackD2C5
		 * @summary D2C回调
		 * @request PATCH:/payment/request/ding/callback/d2c
		 * @originalName requestDingCallbackD2C
		 * @duplicate
		 */
		requestDingCallbackD2C5: (
			body: Record<string, object>,
			query?: {
				/** nonce */
				nonce?: string;
				/** signature */
				signature?: string;
				/** timestamp */
				timestamp?: string;
			},
			params: RequestParams = {}
		) =>
			this.request<Record<string, string>, any>({
				path: `/payment/request/ding/callback/d2c`,
				method: 'PATCH',
				query: query,
				body: body,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 付款申请
		 * @name RequestGetDetail
		 * @summary 查看付款申请详情
		 * @request GET:/payment/request/get/detail
		 */
		requestGetDetail: (
			query?: {
				/**
				 * 付款申请ID
				 * @format int64
				 */
				id?: number;
			},
			params: RequestParams = {}
		) =>
			this.request<BizResponsePaymentRequestRespDTO, any>({
				path: `/payment/request/get/detail`,
				method: 'GET',
				query: query,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 付款申请
		 * @name RequestGetUserDetail
		 * @summary 获取对应的客户、经理和总监信息
		 * @request GET:/payment/request/getUserDetail
		 */
		requestGetUserDetail: (
			query: {
				/** purchaseOrderNo */
				purchaseOrderNo: string;
			},
			params: RequestParams = {}
		) =>
			this.request<BizResponseUserManagerDetailRespDTO, any>({
				path: `/payment/request/getUserDetail`,
				method: 'GET',
				query: query,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 付款申请
		 * @name RequestPageList
		 * @summary 付款申请分页查询
		 * @request POST:/payment/request/page/list
		 */
		requestPageList: (
			query: PaymentRequestQuery,
			params: RequestParams = {}
		) =>
			this.request<BizResponsePagePaymentRequestRespDTO, any>({
				path: `/payment/request/page/list`,
				method: 'POST',
				body: query,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 付款申请
		 * @name RequestQueryOemPurchaseOperateLog
		 * @summary 获取操作日志
		 * @request GET:/payment/request/queryOemPurchaseOperateLog
		 */
		requestQueryOemPurchaseOperateLog: (
			query: {
				/**
				 * id
				 * @format int64
				 */
				id: number;
			},
			params: RequestParams = {}
		) =>
			this.request<BizResponseListQueryOemPurchaseOperateLogRespDTO, any>(
				{
					path: `/payment/request/queryOemPurchaseOperateLog`,
					method: 'GET',
					query: query,
					...params
				}
			),

		/**
		 * No description
		 *
		 * @tags 付款申请
		 * @name RequestUpdate
		 * @summary 修改付款申请
		 * @request POST:/payment/request/update
		 */
		requestUpdate: (
			paymentRequestReqDTO: PaymentRequestReqDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponse, any>({
				path: `/payment/request/update`,
				method: 'POST',
				body: paymentRequestReqDTO,
				type: ContentType.Json,
				...params
			})
	};
	purchase = {
		/**
		 * No description
		 *
		 * @tags purchase-abnormal-controller
		 * @name AbnormalAbnormalCount
		 * @summary abnormalCount
		 * @request POST:/purchase/abnormal/abnormalCount
		 */
		abnormalAbnormalCount: (
			queryDTO: UserPurchaseAbnormalQueryReqDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponseLong, any>({
				path: `/purchase/abnormal/abnormalCount`,
				method: 'POST',
				body: queryDTO,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags purchase-abnormal-controller
		 * @name AbnormalAddExpressNo
		 * @summary addExpressNo
		 * @request POST:/purchase/abnormal/addExpressNo
		 */
		abnormalAddExpressNo: (
			reqDTO: AbnormalRefundOrderAddExpressNoReqDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponseBoolean, any>({
				path: `/purchase/abnormal/addExpressNo`,
				method: 'POST',
				body: reqDTO,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags purchase-abnormal-controller
		 * @name AbnormalApplyAbnormal
		 * @summary applyAbnormal
		 * @request POST:/purchase/abnormal/applyAbnormal
		 */
		abnormalApplyAbnormal: (
			reqDTO: PurchaseAbnormalApplyReqDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponseBoolean, any>({
				path: `/purchase/abnormal/applyAbnormal`,
				method: 'POST',
				body: reqDTO,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags purchase-abnormal-controller
		 * @name AbnormalApplySupplierRefund
		 * @summary applySupplierRefund
		 * @request POST:/purchase/abnormal/applySupplierRefund
		 */
		abnormalApplySupplierRefund: (
			reqDTO: ApplySupplierRefundReqDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponseBoolean, any>({
				path: `/purchase/abnormal/applySupplierRefund`,
				method: 'POST',
				body: reqDTO,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags purchase-abnormal-controller
		 * @name AbnormalApplySupplierRefundFeign
		 * @summary applySupplierRefund
		 * @request POST:/purchase/abnormal/applySupplierRefund/feign
		 */
		abnormalApplySupplierRefundFeign: (
			reqDTO: RpaApplySupplierRefundReqDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponseBoolean, any>({
				path: `/purchase/abnormal/applySupplierRefund/feign`,
				method: 'POST',
				body: reqDTO,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags purchase-abnormal-controller
		 * @name AbnormalApplySupplierRefundForOnlyRefundAndReturn
		 * @summary applySupplierRefundForOnlyRefundAndReturn
		 * @request POST:/purchase/abnormal/applySupplierRefundForOnlyRefundAndReturn
		 */
		abnormalApplySupplierRefundForOnlyRefundAndReturn: (
			reqDTO: ApplySupplierRefundReqDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponseBoolean, any>({
				path: `/purchase/abnormal/applySupplierRefundForOnlyRefundAndReturn`,
				method: 'POST',
				body: reqDTO,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags purchase-abnormal-controller
		 * @name AbnormalApplySupplierRefundForOnlyRefundAndReturnFeign
		 * @summary applySupplierRefundForOnlyRefundAndReturn
		 * @request POST:/purchase/abnormal/applySupplierRefundForOnlyRefundAndReturn/feign
		 */
		abnormalApplySupplierRefundForOnlyRefundAndReturnFeign: (
			reqDTO: RpaApplySupplierRefundReqDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponseBoolean, any>({
				path: `/purchase/abnormal/applySupplierRefundForOnlyRefundAndReturn/feign`,
				method: 'POST',
				body: reqDTO,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags purchase-abnormal-controller
		 * @name AbnormalChangeAddress
		 * @summary changeAddress
		 * @request POST:/purchase/abnormal/changeAddress
		 */
		abnormalChangeAddress: (
			reqDTO: ChangeAddressReqDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponseBoolean, any>({
				path: `/purchase/abnormal/changeAddress`,
				method: 'POST',
				body: reqDTO,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags purchase-abnormal-controller
		 * @name AbnormalCommitWmsAddressReqDto
		 * @summary commitWmsAddress
		 * @request POST:/purchase/abnormal/commitWmsAddressReqDTO
		 */
		abnormalCommitWmsAddressReqDto: (
			reqDTO: CommitWmsAddressReqDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponseBoolean, any>({
				path: `/purchase/abnormal/commitWmsAddressReqDTO`,
				method: 'POST',
				body: reqDTO,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags purchase-abnormal-controller
		 * @name AbnormalGetAbnormalDetailByNo
		 * @summary getAbnormalDetailByNo
		 * @request GET:/purchase/abnormal/getAbnormalDetailByNo
		 */
		abnormalGetAbnormalDetailByNo: (
			query: {
				/** purchasingAbnormalOrderNo */
				purchasingAbnormalOrderNo: string;
			},
			params: RequestParams = {}
		) =>
			this.request<BizResponsePurchaseAbnormalOrderRespDTO, any>({
				path: `/purchase/abnormal/getAbnormalDetailByNo`,
				method: 'GET',
				query: query,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags purchase-abnormal-controller
		 * @name AbnormalGetAbnormalListByMerchantOrderNumber
		 * @summary getAbnormalListByMerchantOrderNumber
		 * @request POST:/purchase/abnormal/getAbnormalListByMerchantOrderNumber
		 */
		abnormalGetAbnormalListByMerchantOrderNumber: (
			purchasePayFlowDTOS: PurchasePayFlowDTO[],
			params: RequestParams = {}
		) =>
			this.request<BizResponseListPurchaseOrderAndAbnormalRespDTO, any>({
				path: `/purchase/abnormal/getAbnormalListByMerchantOrderNumber`,
				method: 'POST',
				body: purchasePayFlowDTOS,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags purchase-abnormal-controller
		 * @name AbnormalGetAbnormalListByPurchaseNo
		 * @summary getAbnormalListByPurchaseNo
		 * @request POST:/purchase/abnormal/getAbnormalListByPurchaseNo
		 */
		abnormalGetAbnormalListByPurchaseNo: (
			purchasePayFlowDTOS: PurchasePayFlowDTO[],
			params: RequestParams = {}
		) =>
			this.request<BizResponseListPurchaseOrderAndAbnormalRespDTO, any>({
				path: `/purchase/abnormal/getAbnormalListByPurchaseNo`,
				method: 'POST',
				body: purchasePayFlowDTOS,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags purchase-abnormal-controller
		 * @name AbnormalGetDealingAbnormalNum
		 * @summary getDealingAbnormalNum
		 * @request GET:/purchase/abnormal/getDealingAbnormalNum
		 */
		abnormalGetDealingAbnormalNum: (
			query: {
				/** platformOrderNo */
				platformOrderNo: string;
			},
			params: RequestParams = {}
		) =>
			this.request<BizResponseInt, any>({
				path: `/purchase/abnormal/getDealingAbnormalNum`,
				method: 'GET',
				query: query,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags purchase-abnormal-controller
		 * @name AbnormalGetSuccessAccountNum
		 * @summary getSuccessAccountNum
		 * @request POST:/purchase/abnormal/getSuccessAccountNum
		 */
		abnormalGetSuccessAccountNum: (
			successAccountNumReqDTO: SuccessAccountNumReqDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponseInt, any>({
				path: `/purchase/abnormal/getSuccessAccountNum`,
				method: 'POST',
				body: successAccountNumReqDTO,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags purchase-abnormal-controller
		 * @name AbnormalGetUnCompleteAbnormalListByPlatformOrderNo
		 * @summary getUnCompleteAbnormalListByPlatformOrderNo
		 * @request GET:/purchase/abnormal/getUnCompleteAbnormalListByPlatformOrderNo
		 */
		abnormalGetUnCompleteAbnormalListByPlatformOrderNo: (
			query: {
				/** platformOrderNo */
				platformOrderNo: string;
			},
			params: RequestParams = {}
		) =>
			this.request<BizResponseListString, any>({
				path: `/purchase/abnormal/getUnCompleteAbnormalListByPlatformOrderNo`,
				method: 'GET',
				query: query,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags purchase-abnormal-controller
		 * @name AbnormalGetUserOperateNum
		 * @summary getUserOperateNum
		 * @request GET:/purchase/abnormal/getUserOperateNum
		 */
		abnormalGetUserOperateNum: (
			query: {
				/** platformOrderNo */
				platformOrderNo: string;
			},
			params: RequestParams = {}
		) =>
			this.request<BizResponseInt, any>({
				path: `/purchase/abnormal/getUserOperateNum`,
				method: 'GET',
				query: query,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags purchase-abnormal-controller
		 * @name AbnormalGetUserOperateNumBySystemOrderNo
		 * @summary getUserOperateNumBySystemOrderNo
		 * @request GET:/purchase/abnormal/getUserOperateNumBySystemOrderNo
		 */
		abnormalGetUserOperateNumBySystemOrderNo: (
			query: {
				/** systemOrderNo */
				systemOrderNo: string;
			},
			params: RequestParams = {}
		) =>
			this.request<BizResponseInt, any>({
				path: `/purchase/abnormal/getUserOperateNumBySystemOrderNo`,
				method: 'GET',
				query: query,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags purchase-abnormal-controller
		 * @name AbnormalGetUserPurchaseAbnormalExtent
		 * @summary getUserPurchaseAbnormalExtent
		 * @request POST:/purchase/abnormal/getUserPurchaseAbnormalExtent
		 */
		abnormalGetUserPurchaseAbnormalExtent: (
			reqDTO: UserPurchaseAbnormalExtentReqDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponseUserPurchaseAbnormalExtentRespDTO, any>({
				path: `/purchase/abnormal/getUserPurchaseAbnormalExtent`,
				method: 'POST',
				body: reqDTO,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags purchase-abnormal-controller
		 * @name AbnormalGetUserPurchaseAbnormalPdd
		 * @summary getUserPurchaseAbnormalPinDuoDuoDTO
		 * @request POST:/purchase/abnormal/getUserPurchaseAbnormalPdd
		 */
		abnormalGetUserPurchaseAbnormalPdd: (
			reqDTO: UserPurchaseAbnormalExtentReqDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponseUserPurchaseAbnormalPinDuoDuoDTO, any>({
				path: `/purchase/abnormal/getUserPurchaseAbnormalPdd`,
				method: 'POST',
				body: reqDTO,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags purchase-abnormal-controller
		 * @name AbnormalHandleAbnormal
		 * @summary handleAbnormal
		 * @request POST:/purchase/abnormal/handleAbnormal
		 */
		abnormalHandleAbnormal: (
			reqDTO: HandleUserRefundReqDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponseBoolean, any>({
				path: `/purchase/abnormal/handleAbnormal`,
				method: 'POST',
				body: reqDTO,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags purchase-abnormal-controller
		 * @name AbnormalHandleAbnormalFeign
		 * @summary handleAbnormal
		 * @request POST:/purchase/abnormal/handleAbnormal/feign
		 */
		abnormalHandleAbnormalFeign: (
			reqDTO: RpaHandleUserRefundReqDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponseBoolean, any>({
				path: `/purchase/abnormal/handleAbnormal/feign`,
				method: 'POST',
				body: reqDTO,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags purchase-abnormal-controller
		 * @name AbnormalInfo
		 * @summary info
		 * @request GET:/purchase/abnormal/info
		 */
		abnormalInfo: (
			query: {
				/**
				 * purchaseAbnormalOrderId
				 * @format int64
				 */
				purchaseAbnormalOrderId: number;
			},
			params: RequestParams = {}
		) =>
			this.request<BizResponsePurchaseAbnormalRespDTO, any>({
				path: `/purchase/abnormal/info`,
				method: 'GET',
				query: query,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags purchase-abnormal-controller
		 * @name AbnormalInfoFeign
		 * @summary infoFeign
		 * @request GET:/purchase/abnormal/info/feign
		 */
		abnormalInfoFeign: (
			query: {
				/**
				 * purchaseAbnormalOrderId
				 * @format int64
				 */
				purchaseAbnormalOrderId: number;
			},
			params: RequestParams = {}
		) =>
			this.request<BizResponseRpaPurchaseAbnormalRespDTO, any>({
				path: `/purchase/abnormal/info/feign`,
				method: 'GET',
				query: query,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags purchase-abnormal-controller
		 * @name AbnormalListSupplierRefund
		 * @summary listSupplierRefund
		 * @request POST:/purchase/abnormal/listSupplierRefund
		 */
		abnormalListSupplierRefund: (
			reqDTO: SupplierRefundReqDTO,
			params: RequestParams = {}
		) =>
			this.request<
				BizResponseListPurchaseAbnormalSupplierRefundOrderRespDTO,
				any
			>({
				path: `/purchase/abnormal/listSupplierRefund`,
				method: 'POST',
				body: reqDTO,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags purchase-abnormal-controller
		 * @name AbnormalManageInfo
		 * @summary manageInfo
		 * @request GET:/purchase/abnormal/manageInfo
		 */
		abnormalManageInfo: (
			query: {
				/**
				 * purchaseAbnormalOrderId
				 * @format int64
				 */
				purchaseAbnormalOrderId: number;
			},
			params: RequestParams = {}
		) =>
			this.request<BizResponsePurchaseAbnormalRespDTO, any>({
				path: `/purchase/abnormal/manageInfo`,
				method: 'GET',
				query: query,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags purchase-abnormal-controller
		 * @name AbnormalManualReconciliationCallback
		 * @summary manualReconciliationCallback
		 * @request POST:/purchase/abnormal/manualReconciliationCallback
		 */
		abnormalManualReconciliationCallback: (
			purchasePayFlowDTOs: PurchasePayFlowDTO[],
			params: RequestParams = {}
		) =>
			this.request<BizResponseBoolean, any>({
				path: `/purchase/abnormal/manualReconciliationCallback`,
				method: 'POST',
				body: purchasePayFlowDTOs,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags purchase-abnormal-controller
		 * @name AbnormalModifyAbnormalImages
		 * @summary modifyAbnormalImages
		 * @request POST:/purchase/abnormal/modifyAbnormalImages
		 */
		abnormalModifyAbnormalImages: (
			reqDTO: ModifyAbnormalImagesReqDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponseObject, any>({
				path: `/purchase/abnormal/modifyAbnormalImages`,
				method: 'POST',
				body: reqDTO,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags purchase-abnormal-controller
		 * @name AbnormalPurchaseAbnormalMqDto
		 * @summary addPurchaseAbnormalMqDTO
		 * @request POST:/purchase/abnormal/purchaseAbnormalMqDTO
		 */
		abnormalPurchaseAbnormalMqDto: (
			purchaseAbnormalMqDTO: PurchaseAbnormalMqDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponseBoolean, any>({
				path: `/purchase/abnormal/purchaseAbnormalMqDTO`,
				method: 'POST',
				body: purchaseAbnormalMqDTO,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags purchase-abnormal-controller
		 * @name AbnormalPurchaseAbnormalOrderUpdate
		 * @summary purchaseAbnormalOrderUpdate
		 * @request POST:/purchase/abnormal/purchaseAbnormalOrderUpdate
		 */
		abnormalPurchaseAbnormalOrderUpdate: (
			reqDTO: PurchaseAbnormalOrderUpdateReqDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponseBoolean, any>({
				path: `/purchase/abnormal/purchaseAbnormalOrderUpdate`,
				method: 'POST',
				body: reqDTO,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags purchase-abnormal-controller
		 * @name AbnormalPurchaseAbnormalOrderUpdateFeign
		 * @summary purchaseAbnormalOrderUpdate
		 * @request POST:/purchase/abnormal/purchaseAbnormalOrderUpdate/feign
		 */
		abnormalPurchaseAbnormalOrderUpdateFeign: (
			reqDTO: RpaPurchaseAbnormalOrderUpdateReqDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponseBoolean, any>({
				path: `/purchase/abnormal/purchaseAbnormalOrderUpdate/feign`,
				method: 'POST',
				body: reqDTO,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags purchase-abnormal-controller
		 * @name AbnormalPurchaseAbnormalRefundCallBack
		 * @summary purchaseAbnormalRefundCallBack
		 * @request POST:/purchase/abnormal/purchaseAbnormalRefundCallBack
		 */
		abnormalPurchaseAbnormalRefundCallBack: (
			reqDTO: PurchaseAbnormalRefundCallBackReqDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponseBoolean, any>({
				path: `/purchase/abnormal/purchaseAbnormalRefundCallBack`,
				method: 'POST',
				body: reqDTO,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags purchase-abnormal-controller
		 * @name AbnormalPurchaseAbnormalRemake
		 * @summary purchaseAbnormalRemake
		 * @request POST:/purchase/abnormal/purchaseAbnormalRemake
		 */
		abnormalPurchaseAbnormalRemake: (
			reqDTO: PurchaseAbnormalRemakeReqDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponseBoolean, any>({
				path: `/purchase/abnormal/purchaseAbnormalRemake`,
				method: 'POST',
				body: reqDTO,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags purchase-abnormal-controller
		 * @name AbnormalQueryAbnormal
		 * @summary queryAbnormal
		 * @request POST:/purchase/abnormal/queryAbnormal
		 */
		abnormalQueryAbnormal: (
			queryDTO: UserPurchaseAbnormalQueryReqDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponsePageUserPurchaseAbnormalRespDTO, any>({
				path: `/purchase/abnormal/queryAbnormal`,
				method: 'POST',
				body: queryDTO,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags purchase-abnormal-controller
		 * @name AbnormalQueryAbnormalCountByOrderNo
		 * @summary queryAbnormalCountByOrderNo
		 * @request POST:/purchase/abnormal/queryAbnormalCountByOrderNo
		 */
		abnormalQueryAbnormalCountByOrderNo: (
			reqDTO: QueryAbnormalCountReqDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponseListQueryAbnormalCountRespDTO, any>({
				path: `/purchase/abnormal/queryAbnormalCountByOrderNo`,
				method: 'POST',
				body: reqDTO,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags purchase-abnormal-controller
		 * @name AbnormalQueryAbnormalItemCountByOrderNo
		 * @summary queryAbnormalItemCountByOrderNo
		 * @request POST:/purchase/abnormal/queryAbnormalItemCountByOrderNo
		 */
		abnormalQueryAbnormalItemCountByOrderNo: (
			reqDTO: QueryAbnormalItemCountReqDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponseListQueryAbnormalItemCountRespDTO, any>({
				path: `/purchase/abnormal/queryAbnormalItemCountByOrderNo`,
				method: 'POST',
				body: reqDTO,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags purchase-abnormal-controller
		 * @name AbnormalQueryAbnormalNum
		 * @summary queryAbnormalNum
		 * @request GET:/purchase/abnormal/queryAbnormalNum
		 */
		abnormalQueryAbnormalNum: (
			query: {
				/** platformOrderNoList */
				platformOrderNoList: string[];
			},
			params: RequestParams = {}
		) =>
			this.request<BizResponseListPurchaseAbnormalNumRespDTO, any>({
				path: `/purchase/abnormal/queryAbnormalNum`,
				method: 'GET',
				query: query,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags purchase-abnormal-controller
		 * @name AbnormalQueryOrder
		 * @summary queryOrder
		 * @request POST:/purchase/abnormal/queryOrder
		 */
		abnormalQueryOrder: (
			reqDTO: PurchaseAbnormalQuery,
			params: RequestParams = {}
		) =>
			this.request<BizResponsePagePurchaseAbnormalRespDTO, any>({
				path: `/purchase/abnormal/queryOrder`,
				method: 'POST',
				body: reqDTO,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags purchase-abnormal-controller
		 * @name AbnormalQueryOrderFeign
		 * @summary queryOrder
		 * @request POST:/purchase/abnormal/queryOrder/feign
		 */
		abnormalQueryOrderFeign: (
			reqDTO: RpaPurchaseAbnormalQuery,
			params: RequestParams = {}
		) =>
			this.request<BizResponsePageRpaPurchaseAbnormalRespDTO, any>({
				path: `/purchase/abnormal/queryOrder/feign`,
				method: 'POST',
				body: reqDTO,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags purchase-abnormal-controller
		 * @name AbnormalReApplySupplierRefundOrder
		 * @summary reApplySupplierRefundOrder
		 * @request POST:/purchase/abnormal/reApplySupplierRefundOrder
		 */
		abnormalReApplySupplierRefundOrder: (
			reqDTO: ReApplySupplierRefundOrderReqDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponseBoolean, any>({
				path: `/purchase/abnormal/reApplySupplierRefundOrder`,
				method: 'POST',
				body: reqDTO,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags purchase-abnormal-controller
		 * @name AbnormalRepairPrePurchase
		 * @summary repairPrePurchase
		 * @request POST:/purchase/abnormal/repairPrePurchase
		 */
		abnormalRepairPrePurchase: (
			reqDTO: RepairPrePurchaseReqDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponseBoolean, any>({
				path: `/purchase/abnormal/repairPrePurchase`,
				method: 'POST',
				body: reqDTO,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags purchase-abnormal-controller
		 * @name AbnormalRollBack
		 * @summary rollBack
		 * @request POST:/purchase/abnormal/rollBack
		 */
		abnormalRollBack: (
			reqDTO: AbnormalRollBackReqDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponseBoolean, any>({
				path: `/purchase/abnormal/rollBack`,
				method: 'POST',
				body: reqDTO,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags purchase-abnormal-controller
		 * @name AbnormalSupplierPurchaseAbnormalRefundCallBack
		 * @summary supplierPurchaseAbnormalRefundCallBack
		 * @request POST:/purchase/abnormal/supplierPurchaseAbnormalRefundCallBack
		 */
		abnormalSupplierPurchaseAbnormalRefundCallBack: (
			reqDTO: PurchaseAbnormalSupplierRefundCallBackReqDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponseBoolean, any>({
				path: `/purchase/abnormal/supplierPurchaseAbnormalRefundCallBack`,
				method: 'POST',
				body: reqDTO,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags purchase-abnormal-controller
		 * @name AbnormalSupplierRejectRefund
		 * @summary supplierRejectRefund
		 * @request POST:/purchase/abnormal/supplierRejectRefund
		 */
		abnormalSupplierRejectRefund: (
			reqDTO: SupplierRejectRefundReqDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponseBoolean, any>({
				path: `/purchase/abnormal/supplierRejectRefund`,
				method: 'POST',
				body: reqDTO,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags purchase-abnormal-controller
		 * @name AbnormalUserCancel
		 * @summary userCancel
		 * @request POST:/purchase/abnormal/userCancel
		 */
		abnormalUserCancel: (
			reqDTO: UserCancelReqDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponseBoolean, any>({
				path: `/purchase/abnormal/userCancel`,
				method: 'POST',
				body: reqDTO,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags purchase-abnormal-controller
		 * @name AbnormalWmsHandleSuccess
		 * @summary wmsHandleSuccess
		 * @request POST:/purchase/abnormal/wmsHandleSuccess
		 */
		abnormalWmsHandleSuccess: (
			reqDTO: WmsHandleSuccessReqDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponseBoolean, any>({
				path: `/purchase/abnormal/wmsHandleSuccess`,
				method: 'POST',
				body: reqDTO,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 采购单
		 * @name AddPurchaseExpressNo
		 * @summary addPurchaseExpressNo
		 * @request POST:/purchase/addPurchaseExpressNo
		 */
		addPurchaseExpressNo: (
			reqDTO: PurchaseOrderExpressReqDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponsePurchaseExpressRespDTO, any>({
				path: `/purchase/addPurchaseExpressNo`,
				method: 'POST',
				body: reqDTO,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 采购单
		 * @name BaseInfo
		 * @summary baseInfo
		 * @request GET:/purchase/baseInfo
		 */
		baseInfo: (
			query: {
				/** purchaseNo */
				purchaseNo: string;
			},
			params: RequestParams = {}
		) =>
			this.request<BizResponsePurchaseOrderRespDTO, any>({
				path: `/purchase/baseInfo`,
				method: 'GET',
				query: query,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 采购单
		 * @name BatchCreateOrder
		 * @summary batchCreateOrder
		 * @request POST:/purchase/batchCreateOrder
		 */
		batchCreateOrder: (
			reqDTO: BatchCreateOrderReqDTO,
			params: RequestParams = {}
		) =>
			this.request<void, any>({
				path: `/purchase/batchCreateOrder`,
				method: 'POST',
				body: reqDTO,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 采购单
		 * @name ChangePurchaseRemark
		 * @summary 修改备注
		 * @request POST:/purchase/changePurchaseRemark
		 */
		changePurchaseRemark: (
			reqDTO: ChangePurchaseRemarkDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponseBoolean, any>({
				path: `/purchase/changePurchaseRemark`,
				method: 'POST',
				body: reqDTO,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 采购单
		 * @name ChangePurchaseUser
		 * @summary 修改采购员/跟单员
		 * @request POST:/purchase/changePurchaseUser
		 */
		changePurchaseUser: (
			reqDTO: ChangePurchaseUserReqDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponseBoolean, any>({
				path: `/purchase/changePurchaseUser`,
				method: 'POST',
				body: reqDTO,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 采购单
		 * @name ClearMerchandiser
		 * @summary clearMerchandiser
		 * @request POST:/purchase/clear_merchandiser
		 */
		clearMerchandiser: (
			merchandiserIds: string[],
			params: RequestParams = {}
		) =>
			this.request<BizResponseVoid, any>({
				path: `/purchase/clear_merchandiser`,
				method: 'POST',
				body: merchandiserIds,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 采购单
		 * @name CompleteOnlinePurchaseOrder
		 * @summary completeOnlinePurchaseOrder
		 * @request POST:/purchase/completeOnlinePurchaseOrder
		 */
		completeOnlinePurchaseOrder: (
			idQueryReqDTO: IdQueryReqDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponseBoolean, any>({
				path: `/purchase/completeOnlinePurchaseOrder`,
				method: 'POST',
				body: idQueryReqDTO,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 采购单
		 * @name CreateYinYangPurchase
		 * @summary 转阴阳id
		 * @request POST:/purchase/createYinYangPurchase
		 */
		createYinYangPurchase: (
			createYinYangPurchaseReqDTO: CreateYinYangPurchaseReqDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponseBoolean, any>({
				path: `/purchase/createYinYangPurchase`,
				method: 'POST',
				body: createYinYangPurchaseReqDTO,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 采购单
		 * @name Detail
		 * @summary 采购单详情
		 * @request GET:/purchase/detail
		 */
		detail: (
			query: {
				/**
				 * purchaseId
				 * @format int64
				 */
				purchaseId: number;
			},
			params: RequestParams = {}
		) =>
			this.request<BizResponsePurchaseOrderDetailRespDTO, any>({
				path: `/purchase/detail`,
				method: 'GET',
				query: query,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 采购单
		 * @name EditBargainInfo
		 * @summary 编辑议价备注
		 * @request POST:/purchase/editBargainInfo
		 */
		editBargainInfo: (
			reqDTO: EditBargainRemarkReqDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponseBoolean, any>({
				path: `/purchase/editBargainInfo`,
				method: 'POST',
				body: reqDTO,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 采购单
		 * @name EditByRpa
		 * @summary onlinePurchaseEditByRpa
		 * @request POST:/purchase/editByRpa
		 */
		editByRpa: (
			reqDTO: OnlinePurchaseOrderEditByRpaDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponseBoolean, any>({
				path: `/purchase/editByRpa`,
				method: 'POST',
				body: reqDTO,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 采购单
		 * @name EditItemCategory
		 * @summary 编辑中文品名
		 * @request POST:/purchase/editItemCategory
		 */
		editItemCategory: (
			reqDTO: EditItemCategoryReqDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponseBoolean, any>({
				path: `/purchase/editItemCategory`,
				method: 'POST',
				body: reqDTO,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 采购单
		 * @name EditLogistcNumbers
		 * @summary 编辑物流单号
		 * @request POST:/purchase/editLogistcNumbers
		 */
		editLogistcNumbers: (
			reqDTO: PurchaseOrderExpressReqDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponsePurchaseExpressRespDTO, any>({
				path: `/purchase/editLogistcNumbers`,
				method: 'POST',
				body: reqDTO,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 采购单
		 * @name EditLogistcNumbersRpa
		 * @summary 编辑物流单号
		 * @request POST:/purchase/editLogistcNumbersRPA
		 */
		editLogistcNumbersRpa: (
			reqDTO: PurchaseOrderExpressReqDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponsePurchaseExpressRespDTO, any>({
				path: `/purchase/editLogistcNumbersRPA`,
				method: 'POST',
				body: reqDTO,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 采购单
		 * @name EditOnlinePurchaseItem
		 * @summary editOnlinePurchaseItem
		 * @request POST:/purchase/editOnlinePurchaseItem
		 */
		editOnlinePurchaseItem: (
			reqDTO: OnlinePurchaseItemEditReqDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponseBoolean, any>({
				path: `/purchase/editOnlinePurchaseItem`,
				method: 'POST',
				body: reqDTO,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 采购单
		 * @name EditOnlinePurchaseOrder
		 * @summary editOnlinePurchaseOrder
		 * @request POST:/purchase/editOnlinePurchaseOrder
		 */
		editOnlinePurchaseOrder: (
			reqDTO: OnlinePurchaseOrderEditReqDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponseBoolean, any>({
				path: `/purchase/editOnlinePurchaseOrder`,
				method: 'POST',
				body: reqDTO,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 采购单
		 * @name EditPurchaseExpressNo
		 * @summary 修改物流单号
		 * @request POST:/purchase/editPurchaseExpressNo
		 */
		editPurchaseExpressNo: (
			reqDTO: EditPurchaseExpressNoReqDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponseBoolean, any>({
				path: `/purchase/editPurchaseExpressNo`,
				method: 'POST',
				body: reqDTO,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 采购单
		 * @name EditPurchasing
		 * @summary onlineEditPurchasing
		 * @request GET:/purchase/editPurchasing
		 */
		editPurchasing: (
			query: {
				/**
				 * purchaseId
				 * @format int64
				 */
				purchaseId: number;
			},
			params: RequestParams = {}
		) =>
			this.request<BizResponseBoolean, any>({
				path: `/purchase/editPurchasing`,
				method: 'GET',
				query: query,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 采购单
		 * @name EditStallPurchaseItem
		 * @summary 编辑档口商品信息
		 * @request POST:/purchase/editStallPurchaseItem
		 */
		editStallPurchaseItem: (
			reqDTO: StallPurchaseItemEditReqDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponseBoolean, any>({
				path: `/purchase/editStallPurchaseItem`,
				method: 'POST',
				body: reqDTO,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 采购单
		 * @name ExportPurchaseFlow
		 * @summary exportPurchaseFlow
		 * @request GET:/purchase/exportPurchaseFlow
		 */
		exportPurchaseFlow: (startTime: string, params: RequestParams = {}) =>
			this.request<void, any>({
				path: `/purchase/exportPurchaseFlow`,
				method: 'GET',
				body: startTime,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 采购单
		 * @name ExportPurchaseOrder
		 * @summary 导出采购单
		 * @request POST:/purchase/exportPurchaseOrder
		 */
		exportPurchaseOrder: (
			reqDTO: QueryPurchaseOrderReqDTO,
			params: RequestParams = {}
		) =>
			this.request<void, any>({
				path: `/purchase/exportPurchaseOrder`,
				method: 'POST',
				body: reqDTO,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 采购单
		 * @name FindByPurchaseNo
		 * @summary findByPurchaseNo
		 * @request GET:/purchase/findByPurchaseNo
		 */
		findByPurchaseNo: (
			query: {
				/** purchaseNo */
				purchaseNo: string;
			},
			params: RequestParams = {}
		) =>
			this.request<BizResponsePurchaseOrderRespDTO, any>({
				path: `/purchase/findByPurchaseNo`,
				method: 'GET',
				query: query,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 采购单
		 * @name FindByTbOrderNo
		 * @summary findByTbOrderNo
		 * @request GET:/purchase/findByTbOrderNo
		 */
		findByTbOrderNo: (
			query: {
				/** tbOrderNo */
				tbOrderNo: string;
			},
			params: RequestParams = {}
		) =>
			this.request<BizResponsePurchaseOrderRespDTO, any>({
				path: `/purchase/findByTbOrderNo`,
				method: 'GET',
				query: query,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 采购单
		 * @name GetByExpressNo
		 * @summary getByExpressNo
		 * @request GET:/purchase/getByExpressNo
		 */
		getByExpressNo: (
			query: {
				/** expressNo */
				expressNo: string;
			},
			params: RequestParams = {}
		) =>
			this.request<BizResponsePurchaseOrderRespDTO, any>({
				path: `/purchase/getByExpressNo`,
				method: 'GET',
				query: query,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 采购单
		 * @name GetPurchaseByPlatformOrderNo
		 * @summary getPurchaseByPlatformOrderNo
		 * @request GET:/purchase/getPurchaseByPlatformOrderNo
		 */
		getPurchaseByPlatformOrderNo: (
			query: {
				/** platformOrderNo */
				platformOrderNo: string;
			},
			params: RequestParams = {}
		) =>
			this.request<BizResponseListOrderItemRelationRespDTO, any>({
				path: `/purchase/getPurchaseByPlatformOrderNo`,
				method: 'GET',
				query: query,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 采购单
		 * @name GetTrackingItems
		 * @summary 查询物流轨迹
		 * @request POST:/purchase/getTrackingItems
		 */
		getTrackingItems: (
			reqDTO: KuaiDiTrackReqDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponseKuaiDiTrackRespDTO, any>({
				path: `/purchase/getTrackingItems`,
				method: 'POST',
				body: reqDTO,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 采购单
		 * @name HandCreateOrder
		 * @summary 手动下单
		 * @request POST:/purchase/handCreateOrder
		 */
		handCreateOrder: (
			reqDTO: EditPurchaseOrderReqDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponseBoolean, any>({
				path: `/purchase/handCreateOrder`,
				method: 'POST',
				body: reqDTO,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 采购单
		 * @name HandSubmitOrder
		 * @summary 手动提交
		 * @request POST:/purchase/handSubmitOrder
		 */
		handSubmitOrder: (
			reqDTO: SubmitOrderReqDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponseBoolean, any>({
				path: `/purchase/handSubmitOrder`,
				method: 'POST',
				body: reqDTO,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 采购单
		 * @name ImportLogsiticNumbers
		 * @summary 导入物流单号
		 * @request POST:/purchase/importLogsiticNumbers
		 */
		importLogsiticNumbers: (
			data: {
				/**
				 * file
				 * @format binary
				 */
				file: File;
			},
			params: RequestParams = {}
		) =>
			this.request<BizResponseImportLogsiticNumbersRespDTO, any>({
				path: `/purchase/importLogsiticNumbers`,
				method: 'POST',
				body: data,
				type: ContentType.FormData,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 采购单
		 * @name Info
		 * @summary info
		 * @request POST:/purchase/info
		 */
		info: (reqDTO: PurchaseOrderInfoReqDTO, params: RequestParams = {}) =>
			this.request<BizResponsePurchaseOrderRespDTO, any>({
				path: `/purchase/info`,
				method: 'POST',
				body: reqDTO,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags purchase-item-controller
		 * @name ItemBatchGetPurchaseItemUnitPrice
		 * @summary batchGetPurchaseItemUnitPrice
		 * @request POST:/purchase/item/batchGetPurchaseItemUnitPrice
		 */
		itemBatchGetPurchaseItemUnitPrice: (
			platformOrderItemIds: number[],
			params: RequestParams = {}
		) =>
			this.request<BizResponseMapLongPurchaseItemUnitPriceDTO, any>({
				path: `/purchase/item/batchGetPurchaseItemUnitPrice`,
				method: 'POST',
				body: platformOrderItemIds,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags purchase-item-controller
		 * @name ItemBatchGetPurchaseUnitPrice
		 * @summary batchGetPurchaseUnitPrice
		 * @request POST:/purchase/item/batchGetPurchaseUnitPrice
		 */
		itemBatchGetPurchaseUnitPrice: (
			platformOrderItemIds: number[],
			params: RequestParams = {}
		) =>
			this.request<BizResponseMapLongBigdecimal, any>({
				path: `/purchase/item/batchGetPurchaseUnitPrice`,
				method: 'POST',
				body: platformOrderItemIds,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags purchase-item-controller
		 * @name ItemGetHistoryPriceList
		 * @summary getHistoryPriceList
		 * @request POST:/purchase/item/getHistoryPriceList
		 */
		itemGetHistoryPriceList: (
			productSkuList: string[],
			params: RequestParams = {}
		) =>
			this.request<BizResponseListHistoryPriceRespDTO, any>({
				path: `/purchase/item/getHistoryPriceList`,
				method: 'POST',
				body: productSkuList,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags purchase-item-controller
		 * @name ItemGetPurchaseUnitPrice
		 * @summary getPurchaseUnitPrice
		 * @request POST:/purchase/item/getPurchaseUnitPrice
		 */
		itemGetPurchaseUnitPrice: (
			query: {
				/** orderNo */
				orderNo: string;
				/** productSku */
				productSku: string;
			},
			params: RequestParams = {}
		) =>
			this.request<BizResponseBigdecimal, any>({
				path: `/purchase/item/getPurchaseUnitPrice`,
				method: 'POST',
				query: query,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags purchase-item-controller
		 * @name ItemGetPurchaseUnitPriceByAbnormalOrderNo
		 * @summary getPurchaseUnitPriceByAbnormalOrderNo
		 * @request POST:/purchase/item/getPurchaseUnitPriceByAbnormalOrderNo
		 */
		itemGetPurchaseUnitPriceByAbnormalOrderNo: (
			purchasingAbnormalOrderNoList: string[],
			params: RequestParams = {}
		) =>
			this.request<BizResponseListAbnormalOrderUnitPirceRespDTO, any>({
				path: `/purchase/item/getPurchaseUnitPriceByAbnormalOrderNo`,
				method: 'POST',
				body: purchasingAbnormalOrderNoList,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 采购单
		 * @name List
		 * @summary 采购单列表
		 * @request POST:/purchase/list
		 */
		list: (reqDTO: QueryPurchaseOrderReqDTO, params: RequestParams = {}) =>
			this.request<BizResponsePageQueryPurchaseOrderRespDTO, any>({
				path: `/purchase/list`,
				method: 'POST',
				body: reqDTO,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 采购单
		 * @name ListCanTransferYinYangItem
		 * @summary listCanTransferYinYangItem
		 * @request GET:/purchase/listCanTransferYinYangItem
		 */
		listCanTransferYinYangItem: (
			query: {
				/**
				 * abnormalTag
				 * @format int32
				 */
				abnormalTag: number;
				/** purchaseNo */
				purchaseNo: string;
			},
			params: RequestParams = {}
		) =>
			this.request<BizResponseListPurchaseOrderItemDTO, any>({
				path: `/purchase/listCanTransferYinYangItem`,
				method: 'GET',
				query: query,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 采购单
		 * @name ListPurchase
		 * @summary listPurchase
		 * @request POST:/purchase/listPurchase
		 */
		listPurchase: (
			reqDTO: PurchaseOrderReqDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponsePageListPurchaseOrderRespDTO, any>({
				path: `/purchase/listPurchase`,
				method: 'POST',
				body: reqDTO,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 采购单
		 * @name ListPurchaseOrder
		 * @summary listPurchaseOrder
		 * @request POST:/purchase/listPurchaseOrder
		 */
		listPurchaseOrder: (
			reqDTO: ListPurchaseOrderReqDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponseListPurchaseOrderRespDTO, any>({
				path: `/purchase/listPurchaseOrder`,
				method: 'POST',
				body: reqDTO,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 跟单员分配规则
		 * @name MerchandiserRelationList
		 * @summary list
		 * @request POST:/purchase/merchandiser_relation/list
		 */
		merchandiserRelationList: (
			merchandiserRelationListReqDTO: MerchandiserRelationListReqDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponsePageMerchandiserRelationRespDTO, any>({
				path: `/purchase/merchandiser_relation/list`,
				method: 'POST',
				body: merchandiserRelationListReqDTO,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 跟单员分配规则
		 * @name MerchandiserRelationListLog
		 * @summary listLog
		 * @request GET:/purchase/merchandiser_relation/list_log
		 */
		merchandiserRelationListLog: (
			query: {
				/**
				 * merchandiserRelationId
				 * @format int64
				 */
				merchandiserRelationId: number;
			},
			params: RequestParams = {}
		) =>
			this.request<BizResponseListMerchandiserRelationLogRespDTO, any>({
				path: `/purchase/merchandiser_relation/list_log`,
				method: 'GET',
				query: query,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 跟单员分配规则
		 * @name MerchandiserRelationUpdate
		 * @summary updateRelation
		 * @request POST:/purchase/merchandiser_relation/update
		 */
		merchandiserRelationUpdate: (
			merchandiserRelationUpdateReqDTO: MerchandiserRelationUpdateReqDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponseVoid, any>({
				path: `/purchase/merchandiser_relation/update`,
				method: 'POST',
				body: merchandiserRelationUpdateReqDTO,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 采购单
		 * @name OnlinePurchaseEditMakeUp
		 * @summary onlinePurchaseEditMakeUp
		 * @request GET:/purchase/online_purchase_edit_make_up
		 */
		onlinePurchaseEditMakeUp: (
			reqDTO: OnlinePurchaseOrderEditByRpaDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponseBoolean, any>({
				path: `/purchase/online_purchase_edit_make_up`,
				method: 'GET',
				body: reqDTO,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 采购单
		 * @name PurchaseOrderCalculate
		 * @summary purchaseOrderCalculate
		 * @request POST:/purchase/purchaseOrderCalculate
		 */
		purchaseOrderCalculate: (
			reqDTO: PurchaseOrderCalculateReqDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponsePurchaseOrderCalculateRespDTO, any>({
				path: `/purchase/purchaseOrderCalculate`,
				method: 'POST',
				body: reqDTO,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 采购单
		 * @name PushCompleteEvent
		 * @summary 补偿采购单完成事件
		 * @request POST:/purchase/pushCompleteEvent
		 */
		pushCompleteEvent: (
			reqDTO: PushCompleteEventReqDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponseBoolean, any>({
				path: `/purchase/pushCompleteEvent`,
				method: 'POST',
				body: reqDTO,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 采购单
		 * @name PushStallApplet
		 * @summary 档口单推送
		 * @request POST:/purchase/pushStallApplet
		 */
		pushStallApplet: (
			reqDTO: SubmitOrderReqDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponseListString, any>({
				path: `/purchase/pushStallApplet`,
				method: 'POST',
				body: reqDTO,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 采购单
		 * @name QueryBargainInfo
		 * @summary 查询议价备注
		 * @request GET:/purchase/queryBargainInfo
		 */
		queryBargainInfo: (
			query: {
				/**
				 * purchaseId
				 * @format int64
				 */
				purchaseId: number;
			},
			params: RequestParams = {}
		) =>
			this.request<BizResponseQueryBargainInfoRespDTO, any>({
				path: `/purchase/queryBargainInfo`,
				method: 'GET',
				query: query,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 采购单
		 * @name QueryDoingPurchaseOrderCount
		 * @summary queryDoingPurchaseOrderCount
		 * @request GET:/purchase/queryDoingPurchaseOrderCount
		 */
		queryDoingPurchaseOrderCount: (
			query: {
				/** platformOrderNo */
				platformOrderNo: string;
			},
			params: RequestParams = {}
		) =>
			this.request<BizResponseInt, any>({
				path: `/purchase/queryDoingPurchaseOrderCount`,
				method: 'GET',
				query: query,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 采购单
		 * @name QueryEstimateStockTime
		 * @summary queryEstimateStockTime
		 * @request POST:/purchase/queryEstimateStockTime
		 */
		queryEstimateStockTime: (
			reqDTO: QueryItemLogisticsReqDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponseQueryEstimateStockTimeRespDTO, any>({
				path: `/purchase/queryEstimateStockTime`,
				method: 'POST',
				body: reqDTO,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 采购单
		 * @name QueryItemExpressInfo
		 * @summary queryItemExpressInfo
		 * @request POST:/purchase/queryItemExpressInfo
		 */
		queryItemExpressInfo: (
			reqDTO: QueryItemLogisticsReqDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponseQueryItemExpressInfoRespDTO, any>({
				path: `/purchase/queryItemExpressInfo`,
				method: 'POST',
				body: reqDTO,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 采购单
		 * @name QueryStatusCount
		 * @summary 查询各个状态的采购单数量
		 * @request POST:/purchase/queryStatusCount
		 */
		queryStatusCount: (params: RequestParams = {}) =>
			this.request<BizResponseListMapIntInt, any>({
				path: `/purchase/queryStatusCount`,
				method: 'POST',
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 采购单
		 * @name ReCreateOrder
		 * @summary 重新提交机器下单
		 * @request POST:/purchase/reCreateOrder
		 */
		reCreateOrder: (
			reqDTO: SubmitOrderReqDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponseBoolean, any>({
				path: `/purchase/reCreateOrder`,
				method: 'POST',
				body: reqDTO,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 采购单
		 * @name RemovePurchaseExpressNo
		 * @summary 删除物流单号
		 * @request POST:/purchase/removePurchaseExpressNo
		 */
		removePurchaseExpressNo: (
			reqDTO: RemovePurchaseExpressNoReqDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponseBoolean, any>({
				path: `/purchase/removePurchaseExpressNo`,
				method: 'POST',
				body: reqDTO,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 采购单
		 * @name SavePurchaseOrderSettlePrice
		 * @summary savePurchaseOrderSettlePrice
		 * @request POST:/purchase/savePurchaseOrderSettlePrice
		 */
		savePurchaseOrderSettlePrice: (
			reqDTOList: PurchaseOrderSettlePriceReqDTO[],
			params: RequestParams = {}
		) =>
			this.request<BizResponseBoolean, any>({
				path: `/purchase/savePurchaseOrderSettlePrice`,
				method: 'POST',
				body: reqDTOList,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 采购单
		 * @name SeparateOrder
		 * @summary separateOrder
		 * @request POST:/purchase/separateOrder
		 */
		separateOrder: (
			reqDTO: SeparateOrderReqDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponseListSeparateOrderRespDTO, any>({
				path: `/purchase/separateOrder`,
				method: 'POST',
				body: reqDTO,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 采购单
		 * @name SignExpressNo
		 * @summary signExpressNo
		 * @request POST:/purchase/signExpressNo
		 */
		signExpressNo: (expressNoList: string[], params: RequestParams = {}) =>
			this.request<BizResponseBoolean, any>({
				path: `/purchase/signExpressNo`,
				method: 'POST',
				body: expressNoList,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 采购单
		 * @name SubmitOrder
		 * @summary 提交下单
		 * @request POST:/purchase/submitOrder
		 */
		submitOrder: (reqDTO: SubmitOrderReqDTO, params: RequestParams = {}) =>
			this.request<BizResponseBoolean, any>({
				path: `/purchase/submitOrder`,
				method: 'POST',
				body: reqDTO,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 采购单
		 * @name TranslateOrder
		 * @summary translateOrder
		 * @request POST:/purchase/translateOrder
		 */
		translateOrder: (orderNoList: string[], params: RequestParams = {}) =>
			this.request<BizResponseBoolean, any>({
				path: `/purchase/translateOrder`,
				method: 'POST',
				body: orderNoList,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 采购单
		 * @name UpdateListReconciliationStatus
		 * @summary updateListReconciliationStatus
		 * @request POST:/purchase/updateListReconciliationStatus
		 */
		updateListReconciliationStatus: (
			updateList: PurchaseOrderUpdateReqDTO[],
			params: RequestParams = {}
		) =>
			this.request<BizResponseBoolean, any>({
				path: `/purchase/updateListReconciliationStatus`,
				method: 'POST',
				body: updateList,
				type: ContentType.Json,
				...params
			})
	};
	stall = {
		/**
		 * No description
		 *
		 * @tags stall-settlement-order-controller
		 * @name SettlementEditOfflineOrder
		 * @summary editOfflineOrder
		 * @request POST:/stall/settlement/editOfflineOrder
		 */
		settlementEditOfflineOrder: (
			reqDTO: EditOfflineOrderReqDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponseBoolean, any>({
				path: `/stall/settlement/editOfflineOrder`,
				method: 'POST',
				body: reqDTO,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags stall-settlement-order-controller
		 * @name SettlementEditOrder
		 * @summary editOrder
		 * @request POST:/stall/settlement/editOrder
		 */
		settlementEditOrder: (
			reqDTO: StallSettlementOrderReqDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponseBoolean, any>({
				path: `/stall/settlement/editOrder`,
				method: 'POST',
				body: reqDTO,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags stall-settlement-order-controller
		 * @name SettlementInfo
		 * @summary info
		 * @request GET:/stall/settlement/info
		 */
		settlementInfo: (
			query: {
				/**
				 * stallSettlementOrderId
				 * @format int64
				 */
				stallSettlementOrderId: number;
			},
			params: RequestParams = {}
		) =>
			this.request<BizResponseStallSettlementOrderRespDTO, any>({
				path: `/stall/settlement/info`,
				method: 'GET',
				query: query,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags stall-settlement-order-controller
		 * @name SettlementOfflinePageOrder
		 * @summary offlinePageOrder
		 * @request POST:/stall/settlement/offlinePageOrder
		 */
		settlementOfflinePageOrder: (
			reqDTO: StallSettlementOrderQuery,
			params: RequestParams = {}
		) =>
			this.request<BizResponsePageOfflineSettlementOrderRespDTO, any>({
				path: `/stall/settlement/offlinePageOrder`,
				method: 'POST',
				body: reqDTO,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags stall-settlement-order-controller
		 * @name SettlementPageOrder
		 * @summary pageOrder
		 * @request POST:/stall/settlement/pageOrder
		 */
		settlementPageOrder: (
			reqDTO: StallSettlementOrderQuery,
			params: RequestParams = {}
		) =>
			this.request<BizResponsePageStallSettlementOrderRespDTO, any>({
				path: `/stall/settlement/pageOrder`,
				method: 'POST',
				body: reqDTO,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags stall-settlement-order-controller
		 * @name SettlementSettleOrder
		 * @summary settleOrder
		 * @request POST:/stall/settlement/settleOrder
		 */
		settlementSettleOrder: (
			reqDTO: StallSettlementOrderReqDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponseBoolean, any>({
				path: `/stall/settlement/settleOrder`,
				method: 'POST',
				body: reqDTO,
				type: ContentType.Json,
				...params
			})
	};
	take = {
		/**
		 * No description
		 *
		 * @tags take-order-controller
		 * @name OrderApplyAbnormal
		 * @summary applyAbnormal
		 * @request POST:/take/order/applyAbnormal
		 */
		orderApplyAbnormal: (
			reqDTO: TakeUserApplyAbnormalReqDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponseBoolean, any>({
				path: `/take/order/applyAbnormal`,
				method: 'POST',
				body: reqDTO,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags take-order-controller
		 * @name OrderCancelOrders
		 * @summary cancelOrders
		 * @request POST:/take/order/cancelOrders
		 */
		orderCancelOrders: (
			idListQueryReqDTO: IdListQueryReqDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponseBoolean, any>({
				path: `/take/order/cancelOrders`,
				method: 'POST',
				body: idListQueryReqDTO,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags take-order-controller
		 * @name OrderCompleteOrders
		 * @summary completeOrders
		 * @request POST:/take/order/completeOrders
		 */
		orderCompleteOrders: (
			idListQueryReqDTO: IdListQueryReqDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponseBoolean, any>({
				path: `/take/order/completeOrders`,
				method: 'POST',
				body: idListQueryReqDTO,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags take-order-controller
		 * @name OrderGetTakeOrderById
		 * @summary getTakeOrderById
		 * @request GET:/take/order/getTakeOrderById
		 */
		orderGetTakeOrderById: (
			query: {
				/**
				 * takeOrderId
				 * @format int64
				 */
				takeOrderId: number;
			},
			params: RequestParams = {}
		) =>
			this.request<BizResponseTakeOrderRespDTO, any>({
				path: `/take/order/getTakeOrderById`,
				method: 'GET',
				query: query,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags take-order-controller
		 * @name OrderGetTakeOrderMessage
		 * @summary getTakeOrderMessage
		 * @request GET:/take/order/getTakeOrderMessage
		 */
		orderGetTakeOrderMessage: (
			query: {
				/**
				 * takeOrderId
				 * @format int64
				 */
				takeOrderId: number;
			},
			params: RequestParams = {}
		) =>
			this.request<BizResponseListTakeOrderMessageRespDTO, any>({
				path: `/take/order/getTakeOrderMessage`,
				method: 'GET',
				query: query,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags take-order-controller
		 * @name OrderGetTakeOrderRecord
		 * @summary getTakeOrderRecord
		 * @request GET:/take/order/getTakeOrderRecord
		 */
		orderGetTakeOrderRecord: (
			query: {
				/**
				 * takeOrderId
				 * @format int64
				 */
				takeOrderId: number;
			},
			params: RequestParams = {}
		) =>
			this.request<BizResponseListTakeOrderRecordRespDTO, any>({
				path: `/take/order/getTakeOrderRecord`,
				method: 'GET',
				query: query,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags take-order-controller
		 * @name OrderPageTakeOrder
		 * @summary pageTakeOrder
		 * @request POST:/take/order/pageTakeOrder
		 */
		orderPageTakeOrder: (
			reqDTO: TakeOrderQuery,
			params: RequestParams = {}
		) =>
			this.request<BizResponsePageTakeOrderRespDTO, any>({
				path: `/take/order/pageTakeOrder`,
				method: 'POST',
				body: reqDTO,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags take-order-controller
		 * @name OrderSaveTakeOrderMessage
		 * @summary saveTakeOrderMessage
		 * @request POST:/take/order/saveTakeOrderMessage
		 */
		orderSaveTakeOrderMessage: (
			reqDTO: TakeOrderMessageReqDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponseBoolean, any>({
				path: `/take/order/saveTakeOrderMessage`,
				method: 'POST',
				body: reqDTO,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags take-order-controller
		 * @name OrderSaveTakeRecord
		 * @summary saveTakeRecord
		 * @request POST:/take/order/saveTakeRecord
		 */
		orderSaveTakeRecord: (
			reqDTO: TakeRecordReqDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponseBoolean, any>({
				path: `/take/order/saveTakeRecord`,
				method: 'POST',
				body: reqDTO,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags take-order-controller
		 * @name OrderTakeOffOrders
		 * @summary takeOffOrders
		 * @request POST:/take/order/takeOffOrders
		 */
		orderTakeOffOrders: (
			takeOffOrdersReqDTO: TakeOffOrdersReqDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponseBoolean, any>({
				path: `/take/order/takeOffOrders`,
				method: 'POST',
				body: takeOffOrdersReqDTO,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags take-order-controller
		 * @name OrderTakeOrders
		 * @summary takeOrders
		 * @request POST:/take/order/takeOrders
		 */
		orderTakeOrders: (
			idListQueryReqDTO: IdListQueryReqDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponseBoolean, any>({
				path: `/take/order/takeOrders`,
				method: 'POST',
				body: idListQueryReqDTO,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags take-refund-order-controller
		 * @name RefundOrderGetTakeRefundOrder
		 * @summary getTakeRefundOrder
		 * @request GET:/take/refund/order/getTakeRefundOrder
		 */
		refundOrderGetTakeRefundOrder: (
			query: {
				/**
				 * takeRefundOrderId
				 * @format int64
				 */
				takeRefundOrderId: number;
			},
			params: RequestParams = {}
		) =>
			this.request<BizResponseTakeRefundRespDTO, any>({
				path: `/take/refund/order/getTakeRefundOrder`,
				method: 'GET',
				query: query,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags take-refund-order-controller
		 * @name RefundOrderPageTakeRefundOrder
		 * @summary pageTakeRefundOrder
		 * @request POST:/take/refund/order/pageTakeRefundOrder
		 */
		refundOrderPageTakeRefundOrder: (
			reqDTO: TakeRefundOrderQuery,
			params: RequestParams = {}
		) =>
			this.request<BizResponsePageTakeRefundOrderRespDTO, any>({
				path: `/take/refund/order/pageTakeRefundOrder`,
				method: 'POST',
				body: reqDTO,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags take-refund-order-controller
		 * @name RefundOrderRefundOrder
		 * @summary refundOrder
		 * @request POST:/take/refund/order/refundOrder
		 */
		refundOrderRefundOrder: (
			takeRefundReqDTO: TakeRefundReqDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponseBoolean, any>({
				path: `/take/refund/order/refundOrder`,
				method: 'POST',
				body: takeRefundReqDTO,
				type: ContentType.Json,
				...params
			})
	};
	user = {
		/**
		 * No description
		 *
		 * @tags user-purchase-abnormal-controller
		 * @name PurchaseAbnormalHandleAbnormal
		 * @summary handleAbnormal
		 * @request POST:/user/purchase/abnormal/handleAbnormal
		 */
		purchaseAbnormalHandleAbnormal: (
			reqDTO: UserPurchaseAbnormalHandleReqDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponseBoolean, any>({
				path: `/user/purchase/abnormal/handleAbnormal`,
				method: 'POST',
				body: reqDTO,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags user-purchase-abnormal-controller
		 * @name PurchaseAbnormalInfo
		 * @summary info
		 * @request GET:/user/purchase/abnormal/info
		 */
		purchaseAbnormalInfo: (
			query: {
				/**
				 * purchaseAbnormalOrderId
				 * @format int64
				 */
				purchaseAbnormalOrderId: number;
			},
			params: RequestParams = {}
		) =>
			this.request<BizResponseUserPurchaseAbnormalRespDTO, any>({
				path: `/user/purchase/abnormal/info`,
				method: 'GET',
				query: query,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags user-purchase-abnormal-controller
		 * @name PurchaseAbnormalQueryAbnormal
		 * @summary queryAbnormal
		 * @request POST:/user/purchase/abnormal/queryAbnormal
		 */
		purchaseAbnormalQueryAbnormal: (
			queryDTO: UserPurchaseAbnormalQueryReqDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponsePageUserPurchaseAbnormalRespDTO, any>({
				path: `/user/purchase/abnormal/queryAbnormal`,
				method: 'POST',
				body: queryDTO,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags user-purchase-abnormal-controller
		 * @name PurchaseAbnormalQueryAbnormalApp
		 * @summary queryAbnormalApp
		 * @request POST:/user/purchase/abnormal/queryAbnormal/app
		 */
		purchaseAbnormalQueryAbnormalApp: (
			queryDTO: UserPurchaseAbnormalQueryReqDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponsePageUserPurchaseAbnormalRespDTO, any>({
				path: `/user/purchase/abnormal/queryAbnormal/app`,
				method: 'POST',
				body: queryDTO,
				type: ContentType.Json,
				...params
			})
	};
}
