import React, { useEffect, useRef } from 'react';
import styles from './style.css';
interface IconProps extends React.SVGProps<SVGSVGElement> {
	size?: string | number;
	width?: string | number;
	height?: string | number;
	spin?: boolean;
	rtl?: boolean;
	color?: string;
	fill?: string;
	stroke?: string;
}

export default function Jcb(props: IconProps) {
	const root = useRef<SVGSVGElement>(null);
	const {
		size = '1em',
		width,
		height,
		spin,
		rtl,
		color,
		fill,
		stroke,
		className,
		...rest
	} = props;
	const _width = width || size;
	const _height = height || size;
	const _stroke = stroke || color;
	const _fill = fill || color;
	useEffect(() => {
		if (!_fill) {
			(root.current as SVGSVGElement)
				?.querySelectorAll('[data-follow-fill]')
				.forEach((item) => {
					item.setAttribute(
						'fill',
						item.getAttribute('data-follow-fill') || ''
					);
				});
		}
		if (!_stroke) {
			(root.current as SVGSVGElement)
				?.querySelectorAll('[data-follow-stroke]')
				.forEach((item) => {
					item.setAttribute(
						'stroke',
						item.getAttribute('data-follow-stroke') || ''
					);
				});
		}
	}, [stroke, color, fill]);
	return (
		<svg
			ref={root}
			width={_width}
			height={_height}
			viewBox="0 0 40 40"
			preserveAspectRatio="xMidYMid meet"
			fill="none"
			role="presentation"
			xmlns="http://www.w3.org/2000/svg"
			className={`${className || ''} ${spin ? styles.spin : ''} ${rtl ? styles.rtl : ''}`.trim()}
			{...rest}
		>
			<g>
				<g clipPath="url(#11e7dc__a)">
					<path
						fill="#fff"
						d="M37.646 7.633H2.355A2.354 2.354 0 0 0 0 9.986v20.149c0 1.3 1.054 2.353 2.354 2.353h35.291c1.3 0 2.354-1.053 2.354-2.353V9.986c0-1.3-1.054-2.353-2.354-2.353Z"
					/>
					<path
						fill="#009540"
						d="M30.455 21.436c.071 2.15.143 4.371.036 6.556-.072.86-.61 1.685-1.398 2.186-.609.394-1.361.43-2.15.466l-2.848-.036-.018-18.236c.143-1.219 1.04-2.258 2.186-2.58 1.397-.18 2.687-.108 4.156-.143l.071.931v10.355l-.036.035v.466Z"
					/>
					<path
						fill="#0069B3"
						d="M9.817 20.755v-8.598c.18-.968.968-1.864 1.9-2.222 1.397-.43 3.009-.18 4.442-.215.072 1.397 0 3.153.036 4.371l.072 11.214c-.18 1.72.465 4.049-1.684 5.016-1.362.681-3.19.251-4.73.395l-.036-.251v-9.71Z"
					/>
					<path
						fill="#fff"
						d="M9.817 21.113c.573.753 1.362 1.326 2.33 1.362 1.181.108 2.722.215 3.51-.896.538-.573.215-1.361.323-2.114l-.036-1.361c-.68-.143-1.29 0-2.078-.036-.394 1.47.645 3.117-1.04 3.87-.393.107-.966.035-1.36-.215-.645-.144-.287-1.183-1.147-.896l-.502-.072v.358Z"
					/>
					<path
						fill="#E20613"
						d="m23.325 10.902.035 17.449c-.25.824-.895 1.72-1.79 2.042-1.363.466-3.046.25-4.551.25l-.072-18.379c.072-.86.645-1.612 1.398-2.078.537-.358 1.182-.466 1.863-.502h3.045l.072 1.218Z"
					/>
					<path
						fill="#fff"
						d="M23.038 18.104c.072.215 0 .394.036.645-1.075-.287-2.4-.61-3.368.179-.358.43-.573.86-.537 1.433.107.645.501 1.254 1.11 1.505.931.287 1.9.036 2.759-.18.035.18.071.43 0 .574h-4.12c-.717-.143-1.29-.537-1.613-1.254-.286-.68-.143-1.612.358-2.186a2.093 2.093 0 0 1 1.72-.752l3.655.036Zm7.416 3.332c-.107.43-.466.824-.896.896h-5.015l-.18-.072c0-1.433-.035-2.795.036-4.192h3.189c1.003.108 2.114-.322 2.58.61.143.322.107.752-.144 1.002-.143.251-.466.287-.537.466.394.108.824.43.967.824v.466Zm-4.3-2.938c-.107.394-.107.932-.035 1.361.753-.035 1.72.251 2.221-.322.18-.215.072-.573-.035-.752-.574-.502-1.434-.18-2.15-.287Zm1.936 1.97c-.681-.107-1.362-.071-2.007-.035-.036.537 0 .967.072 1.469.716 0 1.505.107 2.15-.215.179-.18.286-.466.215-.717-.036-.215-.216-.394-.43-.502Z"
					/>
				</g>
				<defs>
					<clipPath id="11e7dc__a">
						<path fill="#fff" d="M0 0h40v40H0z" />
					</clipPath>
				</defs>
			</g>
		</svg>
	);
}
