import React, { useEffect, useRef } from 'react';
import styles from './style.css';
interface IconProps extends React.SVGProps<SVGSVGElement> {
    size?: string | number;
    width?: string | number;
    height?: string | number;
    spin?: boolean;
    rtl?: boolean;
    color?: string;
    fill?: string;
    stroke?: string;
}

export default function MoneyzijinCo0i52a4(props: IconProps) {
    const root = useRef<SVGSVGElement>(null)
    const { size = '1em', width, height, spin, rtl, color, fill, stroke, className, ...rest } = props;
    const _width = width || size;
    const _height = height || size;
    const _stroke = stroke || color;
    const _fill = fill || color;
    useEffect(() => {
      if (!_fill) {
        (root.current as SVGSVGElement)?.querySelectorAll('[data-follow-fill]').forEach(item => {
          item.setAttribute('fill', item.getAttribute('data-follow-fill') || '')
        })
      }
      if (!_stroke) {
        (root.current as SVGSVGElement)?.querySelectorAll('[data-follow-stroke]').forEach(item => {
          item.setAttribute('stroke', item.getAttribute('data-follow-stroke') || '')
        })
      }
    }, [stroke, color, fill])
    return (
        <svg
          ref={root}
          width={_width} 
          height={_height}
          viewBox="0 0 16 16"
          preserveAspectRatio="xMidYMid meet"
          fill="none"
          role="presentation"
          xmlns="http://www.w3.org/2000/svg"
          className={`${className || ''} ${spin ? styles.spin : ''} ${rtl ? styles.rtl : ''}`.trim()}
          {...rest}
        >
          <g><path strokeLinejoin="round" strokeLinecap="round" strokeWidth="1.333" stroke="#199A97" d="M1.334 3.334c0-.369.298-.667.667-.667h12c.368 0 .666.298.666.667v9.333a.667.667 0 0 1-.666.667H2a.667.667 0 0 1-.667-.667V3.334Z" data-follow-fill="#199A97" fill={_fill}/><path strokeLinejoin="round" strokeLinecap="square" strokeWidth="1.333" stroke="#E6E6E6" d="M1.334 5.333h13.333"/><path strokeLinejoin="round" strokeLinecap="round" strokeWidth="1.333" stroke="#E6E6E6" d="M9 10.667h3"/></g>
        </svg>
    )
}
