import React, { useEffect, useRef } from 'react';
import styles from './style.css';
interface IconProps extends React.SVGProps<SVGSVGElement> {
	size?: string | number;
	width?: string | number;
	height?: string | number;
	spin?: boolean;
	rtl?: boolean;
	color?: string;
	fill?: string;
	stroke?: string;
}

export default function BrowsingHistoryliulanjilu(props: IconProps) {
	const root = useRef<SVGSVGElement>(null);
	const {
		size = '1em',
		width,
		height,
		spin,
		rtl,
		color,
		fill,
		stroke,
		className,
		...rest
	} = props;
	const _width = width || size;
	const _height = height || size;
	const _stroke = stroke || color;
	const _fill = fill || color;
	useEffect(() => {
		if (!_fill) {
			(root.current as SVGSVGElement)
				?.querySelectorAll('[data-follow-fill]')
				.forEach((item) => {
					item.setAttribute(
						'fill',
						item.getAttribute('data-follow-fill') || ''
					);
				});
		}
		if (!_stroke) {
			(root.current as SVGSVGElement)
				?.querySelectorAll('[data-follow-stroke]')
				.forEach((item) => {
					item.setAttribute(
						'stroke',
						item.getAttribute('data-follow-stroke') || ''
					);
				});
		}
	}, [stroke, color, fill]);
	return (
		<svg
			ref={root}
			width={_width}
			height={_height}
			viewBox="0 0 16 16"
			preserveAspectRatio="xMidYMid meet"
			fill="none"
			role="presentation"
			xmlns="http://www.w3.org/2000/svg"
			className={`${className || ''} ${spin ? styles.spin : ''} ${rtl ? styles.rtl : ''}`.trim()}
			{...rest}
		>
			<g>
				<path
					strokeLinecap="round"
					strokeWidth="1.333"
					d="M1.333 2.333h13.334M1.333 7.667H5M1.333 13H5"
					data-follow-stroke="#434343"
					stroke={_stroke}
				/>
				<path
					strokeWidth="1.333"
					d="M10.5 11.333a2.833 2.833 0 1 0 0-5.666 2.833 2.833 0 0 0 0 5.666Z"
					data-follow-stroke="#434343"
					stroke={_stroke}
				/>
				<path
					strokeLinecap="round"
					strokeWidth="1.333"
					d="m12.333 10.667 2.334 2.35"
					data-follow-stroke="#434343"
					stroke={_stroke}
				/>
			</g>
		</svg>
	);
}
