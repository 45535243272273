import React, { useEffect, useRef } from 'react';
import styles from './style.css';
interface IconProps extends React.SVGProps<SVGSVGElement> {
    size?: string | number;
    width?: string | number;
    height?: string | number;
    spin?: boolean;
    rtl?: boolean;
    color?: string;
    fill?: string;
    stroke?: string;
}

export default function Orderdingdan(props: IconProps) {
    const root = useRef<SVGSVGElement>(null)
    const { size = '1em', width, height, spin, rtl, color, fill, stroke, className, ...rest } = props;
    const _width = width || size;
    const _height = height || size;
    const _stroke = stroke || color;
    const _fill = fill || color;
    useEffect(() => {
      if (!_fill) {
        (root.current as SVGSVGElement)?.querySelectorAll('[data-follow-fill]').forEach(item => {
          item.setAttribute('fill', item.getAttribute('data-follow-fill') || '')
        })
      }
      if (!_stroke) {
        (root.current as SVGSVGElement)?.querySelectorAll('[data-follow-stroke]').forEach(item => {
          item.setAttribute('stroke', item.getAttribute('data-follow-stroke') || '')
        })
      }
    }, [stroke, color, fill])
    return (
        <svg
          ref={root}
          width={_width} 
          height={_height}
          viewBox="0 0 16 16"
          preserveAspectRatio="xMidYMid meet"
          fill="none"
          role="presentation"
          xmlns="http://www.w3.org/2000/svg"
          className={`${className || ''} ${spin ? styles.spin : ''} ${rtl ? styles.rtl : ''}`.trim()}
          {...rest}
        >
          <g><path fill="#434343" d="M4.955 2.667A3.321 3.321 0 0 1 8 .667c1.369 0 2.532.827 3.045 2H12a2 2 0 0 1 2 2v8.667a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V4.667a2 2 0 0 1 2-2h.955ZM8 2c-.925 0-1.702.635-1.927 1.5a.667.667 0 0 1-.645.5H4a.667.667 0 0 0-.667.667v8.667c0 .367.3.666.667.666h8a.667.667 0 0 0 .667-.666V4.667A.667.667 0 0 0 12 4h-1.428a.667.667 0 0 1-.645-.5A1.994 1.994 0 0 0 8 2Z" clipRule="evenodd" fillRule="evenodd"/><path fill="#1EBCB8" d="M4.666 11.334c0-.368.298-.667.667-.667h5.333a.667.667 0 0 1 0 1.333H5.333a.667.667 0 0 1-.667-.666Zm0-4c0-.369.298-.667.667-.667h5.333a.667.667 0 1 1 0 1.333H5.333a.667.667 0 0 1-.667-.666Z" clipRule="evenodd" fillRule="evenodd"/><path fill="#434343" d="M8 3.327c.369 0 .667.299.667.667V4a.667.667 0 0 1-1.333 0v-.006c0-.368.298-.667.667-.667Z" clipRule="evenodd" fillRule="evenodd"/></g>
        </svg>
    )
}
