import React, { useEffect, useRef } from 'react';
import styles from './style.css';
interface IconProps extends React.SVGProps<SVGSVGElement> {
    size?: string | number;
    width?: string | number;
    height?: string | number;
    spin?: boolean;
    rtl?: boolean;
    color?: string;
    fill?: string;
    stroke?: string;
}

export default function Attentionzhuyi(props: IconProps) {
    const root = useRef<SVGSVGElement>(null)
    const { size = '1em', width, height, spin, rtl, color, fill, stroke, className, ...rest } = props;
    const _width = width || size;
    const _height = height || size;
    const _stroke = stroke || color;
    const _fill = fill || color;
    useEffect(() => {
      if (!_fill) {
        (root.current as SVGSVGElement)?.querySelectorAll('[data-follow-fill]').forEach(item => {
          item.setAttribute('fill', item.getAttribute('data-follow-fill') || '')
        })
      }
      if (!_stroke) {
        (root.current as SVGSVGElement)?.querySelectorAll('[data-follow-stroke]').forEach(item => {
          item.setAttribute('stroke', item.getAttribute('data-follow-stroke') || '')
        })
      }
    }, [stroke, color, fill])
    return (
        <svg
          ref={root}
          width={_width} 
          height={_height}
          viewBox="0 0 24 24"
          preserveAspectRatio="xMidYMid meet"
          fill="none"
          role="presentation"
          xmlns="http://www.w3.org/2000/svg"
          className={`${className || ''} ${spin ? styles.spin : ''} ${rtl ? styles.rtl : ''}`.trim()}
          {...rest}
        >
          <g><path fillOpacity=".88" d="M4.222 4.222A10.969 10.969 0 0 1 12 1c3.037 0 5.789 1.232 7.778 3.222l-.707.707.707-.707A10.969 10.969 0 0 1 23 12c0 3.037-1.232 5.789-3.222 7.778A10.969 10.969 0 0 1 12 23a10.969 10.969 0 0 1-7.778-3.222l.707-.707-.707.707A10.969 10.969 0 0 1 1 12c0-3.037 1.232-5.789 3.222-7.778ZM12 3a8.969 8.969 0 0 0-6.364 2.636A8.969 8.969 0 0 0 3 12a8.969 8.969 0 0 0 2.636 6.364A8.969 8.969 0 0 0 12 21a8.968 8.968 0 0 0 6.364-2.636A8.968 8.968 0 0 0 21 12a8.969 8.969 0 0 0-2.636-6.364A8.969 8.969 0 0 0 12 3Z" clipRule="evenodd" fillRule="evenodd" data-follow-fill="#000" fill={_fill}/><path fillOpacity=".88" d="M12 18.5a1.25 1.25 0 1 0 0-2.5 1.25 1.25 0 0 0 0 2.5ZM12 5a1 1 0 0 1 1 1v8a1 1 0 1 1-2 0V6a1 1 0 0 1 1-1Z" clipRule="evenodd" fillRule="evenodd" data-follow-fill="#000" fill={_fill}/></g>
        </svg>
    )
}
