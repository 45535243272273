/* eslint-disable react/jsx-key */
import { Button } from 'antd';
import { useAsyncEffect, useRequest } from 'ahooks';
import { useRef, useState } from 'react';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';
import VipOnevip from '@/common/icons/VipOnevip';
import Paypal from '@/common/icons/Paypal';
import Unionpay from '@/common/icons/Unionpay';
import Mastercard from '@/common/icons/Mastercard';
import Visa from '@/common/icons/Visa';
import Jcb from '@/common/icons/Jcb';
import Amex from '@/common/icons/Amex';
import Discover from '@/common/icons/Discover';
import { request } from '@/config/request';
import { MembershipTemplatePrice } from '@/service/settlement';
import './index.scss';

import {
	useGetFormatAfterNumberList,
	useRateFormatMoney
} from '@/hooks/useRateFormatMoney';
import { useModal } from '@/components/modal';
import Tickgou from '@/common/icons/Tickgou';
import Subtractjian from '@/common/icons/Subtractjian';
import { userInfo } from '@/atom/user';
import OpenVipDialog from '@/common/vip/OpenVipDialog/Index';
import { formatMoney, getPriceTwoList } from '@/utils/biz';
import DinersSgPay from '@/common/icons/DinersSgPay';
import FaqList from '../home/components/FAQ/Index';

const Vip = () => {
	const [activeItem, setactiveItem] = useState<MembershipTemplatePrice>();
	// 最低平均会员
	const [minPrice, setMinPrice] = useState('');
	const [modal, { open, close }] = useModal(
		<OpenVipDialog activeItem={activeItem} close={() => close()} />,
		{
			width: '1000px',
			footer: <></>,
			styles: {
				content: {
					padding: 0,
					borderRadius: '24px',
					background: '#fff'
				}
			}
		}
	);

	const getList = () => [
		{
			label: `What you'll get`
		},
		{
			label: window._$m.t('海量选品'),
			noVip: true,
			vip: true
		},
		{
			label: window._$m.t('智能成本估算'),
			noVip: true,
			vip: true
		},
		{
			label: window._$m.t('免费客服服务'),
			noVip: true,
			vip: true
		},
		{
			label: window._$m.t('查看tiktok热销1-6名商品'),
			noVip: false,
			vip: true
		},
		{
			label: window._$m.t('查看推荐的prime采购商品'),
			noVip: false,
			vip: true
		},
		{
			label: window._$m.t('加购极致性价比商品下单'),
			noVip: false,
			vip: true
		}
	];

	const payList = [
		<Mastercard width={40} height={40} />,
		<Discover width={40} height={40} />,
		<Visa width={40} height={40} />,
		<Jcb width={40} height={40} />,
		<Amex width={40} height={40} />,
		<Unionpay width={40} height={40} />,
		<DinersSgPay width={40} height={40} />
	];

	const formatZmb = useRateFormatMoney(true);

	const [vipList, setVipist] = useState<MembershipTemplatePrice[]>();
	useAsyncEffect(async () => {
		const res =
			await request.settlement.membership.configureTemplatePriceSelectList();
		setVipist(res?.data?.[0].priceItemList);
		const last = res?.data?.[0].priceItemList?.[2];
		const month = ((last?.validPeriod ?? 0) / 30).toFixed(0);
		const averagePrice = (
			(last?.discountActualPrice ?? 0) / (Number(month) ?? 1)
		).toFixed(2);
		setMinPrice(averagePrice ?? 0);
	}, []);
	// 日期转换
	const dateUnit = {
		30: 'Monthly',
		90: 'Quarterly',
		360: 'Yearly'
	};

	const qslist = [
		{
			label: 'What type of service can I expect from SellerStation?',
			value: `SellerStation specializes in providing direct access to suppliers for products that are trending on TikTok. Our platform carefully curates a range of in-demand items, ensuring that our members can source the hottest products directly from the origin within global supply chain. This direct sourcing approach ensures competitive pricing and access to quality products that are likely to be successful on your TikTok shop.`
		},
		{
			label: 'Is there a minimum order quantity for products sourced through SellerStation?',
			value: `Minimum order quantities vary by supplier and product. We strive to work with suppliers who offer flexible order quantities to accommodate the varied needs of our members. Detailed information about order quantities can be found on the product listings or by reaching out to our support team for assistance.`
		},
		{
			label: 'Can I cancel my membership at any time?',
			value: `To ensure a smooth transition and proper handling of any open orders or pending charges, membership cancellation must be processed through our customer support team. We're committed to making the process as hassle-free as possible. Simply reach out to customer service, and they will guide you through the steps to ensure all your account activities are settled before cancellation.`
		},
		{
			label: 'Will my membership automatically renew?',
			value: `Yes, for your convenience, your membership will automatically renew when it expires. You can opt out of automatic renewal at any time by adjusting your account settings on SellerStation and disabling the auto-payment feature within your PayPal account. This way, you can continue to enjoy uninterrupted service or choose to end your membership after your current billing cycle concludes.`
		},
		{
			label: 'How are shipping and logistics handled for orders?',
			value: `SellerStation partners with reputable shipping and logistics providers to ensure a smooth delivery process for your orders. Depending on your location and the supplier's terms, various shipping options may be available. You can view and select your preferred shipping methods during the order process.`
		}
	];

	const user = userInfo();
	const navigate = useNavigate();
	// 购买指定会员
	const rechargeVip = (item) => {
		if (user?.customerId) {
			setactiveItem({ ...item });
			open();
		} else {
			const pathAndParams = window.location.href.split(
				window.location.origin
			)[1];
			navigate(`/account/signin?redirect=${pathAndParams}`);
		}
	};

	const vipRef = useRef<HTMLDivElement>(null);

	const renderNo0View = (listIndex: number, v: any) => {
		if (listIndex === 0) return null;
		if (v.vip) {
			return <Tickgou className="icon" />;
		}
		return <Subtractjian className="icon" color="rgba(255,255,255,0.5)" />;
	};

	return (
		<div className="bg-black-950 vipContainer text-white-0">
			{modal}
			{minPrice && (
				<div className="w-[1200px] mx-auto">
					{/* 板块1 */}
					<div className="py-[108px] text-center part1">
						<div className=" text-white-0 font-bold text-[48px]">
							<div>
								Accelerate Your TikTok Sales with SellerStation
								at
							</div>
							<div>A Minimum of $ {minPrice} per Month</div>
						</div>
						<div className=" text-h3 text-white-65">
							Please subscribe to the membership and start your
							journey.
						</div>
						<div className="mt-[60px]">
							<div className="flex items-center justify-center">
								<div
									onClick={() => {
										vipRef.current!.scrollIntoView({
											behavior: 'smooth',
											inline: 'start'
										});
									}}
									className="cursor-pointer text-[16px] inline-block leading-[48px] hover:text-font-primary hover:border-[#fff] hover:bg-[#fff] rounded-full font-[700] text-[#fff] h-[48px] px-[32px] border border-[#fff]/[.25]"
								>
									View All Plans
								</div>
							</div>
						</div>
					</div>
					{/* 板块2 */}
					<div className="my-[72px] part2 text-center">
						<div className="text-[40px] ">
							Discover the Advantages
						</div>
						<div className="text-h3 text-white-65">
							Subscribe and unlock your benefits
						</div>
						<div className="vipModule">
							<div className="header tr">
								<div
									className="td1"
									style={{
										justifyContent: 'flex-start'
									}}
								>
									{/* {`What you'll get`} */}
								</div>
								<div className="td2">Free plan</div>
								<div className="td3">
									<VipOnevip />
									<span className="ml-[8px]">Membership</span>
								</div>
							</div>
							{getList().map((i, listIndex) => {
								return (
									<div key={i.label} className="tr">
										<div
											className="td1"
											style={{
												justifyContent: 'flex-start',
												fontWeight: `${listIndex === 0 ? '900' : '400'}`
											}}
										>
											{i.label}
										</div>
										<div
											className={`td2 ${listIndex === 0 ? 'opacity-0' : 'opacity-[1]'}`}
										>
											{i.noVip ? (
												<Tickgou className="icon" />
											) : (
												<Subtractjian
													className="icon"
													color="rgba(255,255,255,0.5)"
												/>
											)}
										</div>
										<div className={`td3 `}>
											{renderNo0View(listIndex, i)}
										</div>
									</div>
								);
							})}
						</div>
					</div>
					{/* 模块3 */}
					<div className="py-[72px] text-center text-white-0">
						<div className="font-bold text-[40px]">
							Payment Methods
						</div>
						<div className="text-lg text-white-65">
							Pay in various ways
						</div>
						<div className="flex items-center justify-center mt-[60px]">
							{payList.map((i, idx) => {
								return (
									<div
										className="mr-[16px] last:mr-0"
										key={idx}
									>
										{i}
									</div>
								);
							})}
						</div>
					</div>
					{/* 模块4 */}
					<div
						ref={vipRef}
						className="py-[72px] text-center text-white-0 part4 "
					>
						<div className="font-bold text-[40px]">Membership</div>
						<div className="text-lg text-white-65">
							Choose a plan and start sourcing
						</div>
						<div className="vipList mt-[60px]">
							{Boolean(vipList?.length) &&
								vipList?.map((i, idx) => {
									const month = (
										(i.validPeriod ?? 0) / 30
									).toFixed(0);
									const averagePrice = (
										(i.discountActualPrice ?? 0) /
										(Number(month) ?? 1)
									).toFixed(2);
									const discountActualPrice = getPriceTwoList(
										String(i.discountActualPrice ?? '0') ??
											'0'
									);
									return (
										<div
											className={classNames(
												'priceItem',
												`price${idx}`
											)}
											key={i.membershipTemplatePriceId}
										>
											<div className="dateUnit text-left">
												{dateUnit[
													i.validPeriod ?? 30
												] ?? 'Monthly'}
											</div>
											<div className="h-[64px]">
												<div className="template ">
													<span className="unit">
														$
													</span>
													<span className="money">
														{discountActualPrice[0]}
													</span>
													<span className="cny">
														{discountActualPrice[1]}
													</span>
												</div>
												<div className="tips">
													Only ${averagePrice} per
													month
												</div>
											</div>
											<div
												onClick={() => rechargeVip(i)}
												className="btn cursor-pointer mt-[32px]"
											>
												Select plan
											</div>
										</div>
									);
								})}
						</div>
					</div>
					{/* 模块5 */}
					<div className="py-[72px] text-center text-white-0">
						<div className="font-bold text-[40px]">FAQ</div>
						<div className="text-lg text-white-65">
							{`We've got answers. Find more answers in our`}
							<span className="underline cursor-pointer custom-chat-button ml-[5px]">
								support site.
							</span>
						</div>
						<div className="mt-[60px]">
							<FaqList list={qslist} />
						</div>
					</div>
				</div>
			)}
		</div>
	);
};

export default Vip;
