// 商品状态
export enum ProductStatus {
	NORMAL = 0,
	INVALID = 1,
	BAN = 2,
	STOCK_NOT_ENOUGH = 3
}

export const DisabledReasonDict = {
	[ProductStatus.INVALID]: 'Invalid Product',
	[ProductStatus.BAN]: 'Not Onsale',
	[ProductStatus.STOCK_NOT_ENOUGH]: 'Stock Not Enough'
};

// 商品列
export const SkuDict = {
	productTitleJp: 'Product name',
	productPropertiesName: 'Product properties',
	orderQuantity: 'Quantity',
	buyPrice: 'Unit price (CNY)',
	totalPrice: 'Subtotal (CNY)',
	cartAdditionList: 'Product options',
	Action: 'Operate'
} as const;

/** 支付账单字典 */
export const FeeDict = {
	totalAmtCn: 'Total amount (estimate)',
	productAmtCn: 'Product Price',
	productAdditionAmtCn: 'Product Additional',
	handlingFeeAmtCn: 'Service Fee',
	shipAmtCn: 'Shipping Fee',
	deliverAdditionAmtCn: 'Shipping Additional',
	// 站点货币字段
	totalAmt: 'Total amount (estimate)',
	productAmt: 'Product Price',
	productAdditionAmt: 'Product Additional',
	handlingFeeAmt: 'Service Fee',
	shipAmt: 'Shipping Fee',
	deliverAdditionAmt: 'Shipping Additional'
} as const;
