import { Divider, Form, Input, message } from 'antd';
import { useForm } from 'antd/es/form/Form';
import TextArea from 'antd/es/input/TextArea';
import {
	forwardRef,
	useEffect,
	useImperativeHandle,
	useRef,
	useState
} from 'react';
import { useMount } from 'ahooks';
import { useModal } from '@/components/modal';
import { request } from '@/config/request';
import { US_INFO } from '@/container/account/components/const';
import AliyunOSSUpload from '@/common/Upload';
import Plusjia from '@/common/icons/Plusjia';
import { userInfo } from '@/atom/user';
import { useWindowSize } from '@/hooks/useWinSize';

interface ChildProps {
	submit: () => Promise<boolean | undefined>;
	clear: () => void;
}

interface QuestionUpProps {
	email?: string;
}
export const QuestionUp = forwardRef((props: QuestionUpProps, ref: any) => {
	const { email } = props;
	const [form] = Form.useForm();
	const values = Form.useWatch([], form);
	const user = userInfo();

	useMount(() => {
		form.setFieldValue('email', email);
	});

	useImperativeHandle(
		ref,
		(): ChildProps => ({
			clear: () => {
				form.resetFields();
			},
			submit: async () => {
				try {
					const valid = await form.validateFields();
					if (valid) {
						const res =
							await request.customer.base.feedbackAddFeedbackInfo(
								{
									stationCode: US_INFO.countryCode,
									email: values.email,
									message: values.message,
									userName: user?.loginName ?? '',
									files: values?.files?.map((i) => i.url)
								}
							);
						if (res.success) {
							form.resetFields();
							message.success(window._$m.t('提交成功'));
						}
						return true;
					}
				} catch (error) {
					console.log(error);

					return false;
				}
			}
		})
	);
	return (
		<div>
			<Form layout="vertical" form={form} style={{ width: 600 }}>
				<Form.Item
					label="E-mail"
					name="email"
					rules={[
						{
							required: true,
							message: window._$m.t('请输入邮箱')
						},
						{
							pattern: /[\d\w-]+@([a-zA-ZA-z0-9-]+\.)+[a-z]+/,
							message: window._$m.t('邮箱格式不正确，请重新输入')
						}
					]}
				>
					<Input maxLength={50} />
				</Form.Item>
				<Form.Item label="Description">
					<Form.Item name="message">
						<TextArea
							rows={6}
							className="h-[320px] max-h-[320px] min-h-[32px]"
							placeholder="Thank you for speaking freely. our feedback is very important to us, and we will continue to improve the user experience."
						/>
					</Form.Item>
					<Divider />
					<Form.Item name="files">
						<AliyunOSSUpload
							maxCount={10}
							listType="picture-card"
							pathParams={{
								serviceName: 'pay',
								bizName: 'sellStationWeb',
								bizType: 'recharge',
								operatorId: 'usstation'
							}}
							readAuto={true}
						>
							<div className="flex text-center flex-col justify-center items-center">
								<Plusjia color="rgba(0,0,0,0.88)" />
								Upload
							</div>
						</AliyunOSSUpload>
					</Form.Item>
				</Form.Item>
			</Form>
		</div>
	);
});

export const useQuestionUpModal = () => {
	const QuestionUpRef = useRef<ChildProps>(null);
	const [forupdateKey, setForupdateKey] = useState(0);
	const user = userInfo();
	const [modal, { open: openQuestionUpModal, close }] = useModal(
		<QuestionUp
			email={user?.customerEmail ?? ''}
			key={forupdateKey}
			ref={QuestionUpRef}
		/>,

		{
			width: 640,
			title: 'Feedback',
			style: {
				right: '20px',
				bottom: '0',
				top: 'auto',
				transformOrigin: '50% 50%',
				position: 'fixed',
				display: 'flex',
				alignItems: 'end'
			},
			getContainer: () =>
				document.getElementById('question') || document.body,
			mask: false,
			okText: 'Send',
			cancelText: 'Cancel',
			onCancel: () => {
				QuestionUpRef.current?.clear();
				close();
			},
			onOk: async () => {
				const res = await QuestionUpRef.current?.submit();
				if (res) close();
			}
		}
	);

	const open = () => {
		setForupdateKey(forupdateKey + 1);
		openQuestionUpModal();
	};
	return [modal, { open, close }] as const;
};
