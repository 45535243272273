import React, { useEffect, useRef } from 'react';
import styles from './style.css';
interface IconProps extends React.SVGProps<SVGSVGElement> {
	size?: string | number;
	width?: string | number;
	height?: string | number;
	spin?: boolean;
	rtl?: boolean;
	color?: string;
	fill?: string;
	stroke?: string;
}

export default function Customerservicekefu(props: IconProps) {
	const root = useRef<SVGSVGElement>(null);
	const {
		size = '1em',
		width,
		height,
		spin,
		rtl,
		color,
		fill,
		stroke,
		className,
		...rest
	} = props;
	const _width = width || size;
	const _height = height || size;
	const _stroke = stroke || color;
	const _fill = fill || color;
	useEffect(() => {
		if (!_fill) {
			(root.current as SVGSVGElement)
				?.querySelectorAll('[data-follow-fill]')
				.forEach((item) => {
					item.setAttribute(
						'fill',
						item.getAttribute('data-follow-fill') || ''
					);
				});
		}
		if (!_stroke) {
			(root.current as SVGSVGElement)
				?.querySelectorAll('[data-follow-stroke]')
				.forEach((item) => {
					item.setAttribute(
						'stroke',
						item.getAttribute('data-follow-stroke') || ''
					);
				});
		}
	}, [stroke, color, fill]);
	return (
		<svg
			ref={root}
			width={_width}
			height={_height}
			viewBox="0 0 20 20"
			preserveAspectRatio="xMidYMid meet"
			fill="none"
			role="presentation"
			xmlns="http://www.w3.org/2000/svg"
			className={`${className || ''} ${spin ? styles.spin : ''} ${rtl ? styles.rtl : ''}`.trim()}
			{...rest}
		>
			<g>
				<g
					data-follow-stroke="currentColor"
					strokeWidth="1.667"
					strokeLinejoin="round"
					clipPath="url(#11e940__a)"
					stroke={_stroke}
				>
					<path d="M15 13.333a3.333 3.333 0 1 0 0-6.666m-10 0a3.333 3.333 0 1 0 0 6.666" />
					<path
						d="M5 13.333V6.667a5 5 0 1 1 10 0v6.666a5 5 0 0 1-5 5"
						strokeLinecap="round"
					/>
				</g>
				<defs>
					<clipPath id="11e940__a">
						<path
							data-follow-fill="currentColor"
							d="M0 0h20v20H0z"
							fill={_fill}
						/>
					</clipPath>
				</defs>
			</g>
		</svg>
	);
}
