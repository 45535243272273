import { Button, Popover, Radio } from 'antd';
import { useBoolean } from 'ahooks';
import { redirect, useNavigate } from 'react-router-dom';
import { createRef, useEffect, useState } from 'react';
import { useSetAtom } from 'jotai';
import { setToken, userInfo } from '@/atom/user';
import VipOnevip from '@/common/icons/VipOnevip';
import AvatortouxiangWeidenglu from '@/common/icons/AvatortouxiangWeidenglu';
import AvatortouxiangYidenglu from '@/common/icons/AvatortouxiangYidenglu';
import Downxia from '@/common/icons/Downxia';
import { Link } from '@/components/base/link';
import Dashboardgongzuotai from '@/common/icons/Dashboardgongzuotai';
import './siteHeaderLogin.scss';
import HomegongzuotaiCofaggk9 from '@/common/icons/HomegongzuotaiCofaggk9';
import OrderdingdanCofaggkk from '@/common/icons/OrderdingdanCofaggkk';
import MoneyzijinCofagglg from '@/common/icons/MoneyzijinCofagglg';
import Favoriteshoucang from '@/common/icons/Favoriteshoucang';
import BrowsingHistoryliulanjilu from '@/common/icons/BrowsingHistoryliulanjilu';
import { isLogin, isVip } from '@/atom/mall/user';

const UserEl = ({ user, navigate, setLoginVisible }) => {
	const setIsLogin = useSetAtom(isLogin);
	const setIsVip = useSetAtom(isVip);
	const signOut = () => {
		setToken('');
		setIsLogin(false);
		setIsVip(false);
		window.location.replace('/');
	};
	const link = (url) => {
		setLoginVisible(false);
		navigate(url);
	};
	return (
		<div className="w-[230px] h-[337px] p-[20px] flex flex-col ">
			<div className="flex items-center">
				<AvatortouxiangYidenglu style={{ width: 56, height: 56 }} />
				<div className="ml-[8px] font-[900] login-name ">
					{user.loginName}
				</div>
			</div>
			<div
				className="flex items-center h-[36px] mt-[16px] cursor-pointer"
				onClick={() => link('/center')}
			>
				<Dashboardgongzuotai style={{ width: 16, height: 16 }} />
				<div className="ml-[8px]">Dashboard</div>
			</div>
			<div
				className="flex items-center h-[36px] cursor-pointer"
				onClick={() => link('/center/order')}
			>
				<OrderdingdanCofaggkk style={{ width: 16, height: 16 }} />
				<div className="ml-[8px]">Orders</div>
			</div>
			<div
				className="flex items-center h-[36px] cursor-pointer"
				onClick={() => link('/center/customerFund')}
			>
				<MoneyzijinCofagglg style={{ width: 16, height: 16 }} />
				<div className="ml-[8px]">Balance</div>
			</div>
			<div
				className="flex items-center h-[36px] cursor-pointer"
				onClick={() => link('/center/favorite')}
			>
				<Favoriteshoucang style={{ width: 16, height: 16 }} />
				<div className="ml-[8px]">Liked</div>
			</div>
			<div
				className="flex items-center h-[36px] cursor-pointer"
				onClick={() => link('/center/browsed')}
			>
				<BrowsingHistoryliulanjilu style={{ width: 16, height: 16 }} />
				<div className="ml-[8px]">Browsing History</div>
			</div>
			<div
				className="h-[1px] my-[4px]"
				style={{
					backgroundColor: 'var(--Neutral-Absolute-4, #F0F0F0)'
				}}
			/>

			<div
				className="flex items-center h-[36px] cursor-pointer"
				style={{
					color: 'var(--Neutral-Text-Secondary, rgba(0, 0, 0, 0.45))'
				}}
				onClick={() => signOut()}
			>
				Log out
			</div>
		</div>
	);
};

const LoginEL = ({ user, loginVisible, setLoginVisible, navigate }) => {
	// console.log(user);
	return (
		<>
			<Popover
				content={<UserEl {...{ user, navigate, setLoginVisible }} />}
				trigger="hover"
				placement="bottomRight"
				// open={loginVisible}
				onOpenChange={(boo) => setLoginVisible(boo)}
				overlayClassName="siteHeaderPopover hiddenPopover"
				arrow={false}
			>
				<div className="flex items-center cursor-pointer">
					<div
						className="siteHeaderAvator"
						style={{
							border: `${user?.membership?.templateLevel ? '1px solid #ffd666' : '1px solid #fff'}`
						}}
					>
						<AvatortouxiangYidenglu
							style={{ width: 24, height: 24 }}
						/>

						{!!user?.membership?.templateLevel && (
							<img
								src="https://static-us.theckb.com/static-asset/icon/Vip-one.svg"
								alt="siteHeaderAvatorVip"
								className="siteHeaderAvatorVip"
							/>
						)}
					</div>
					<div style={{ textAlign: 'left', marginLeft: 8 }}>
						<div style={{ fontWeight: 400 }}>Welcome</div>

						<div
							style={{ fontWeight: 900 }}
							className="flex items-center"
						>
							<div className="login-name ">{user.loginName}</div>
							<Downxia
								fill="white"
								className="transition"
								style={{
									transform: `rotate(${loginVisible ? 180 : 0}deg)`
								}}
							/>
						</div>
					</div>
				</div>
			</Popover>
		</>
	);
};

const ToLoginEl = ({ navigate }) => {
	const goToLoginPage = () => {
		if (window.location.href.includes('/account/signin')) return;
		const pathAndParams = window.location.href.split(
			window.location.origin
		)[1];
		navigate(`/account/signin?redirect=${pathAndParams}`);
	};
	return (
		<div className="w-[230px] h-[110px] flex flex-col justify-center items-center">
			<Button
				shape="round"
				type="primary"
				className="w-[190px] h-[40px]"
				onClick={goToLoginPage}
			>
				Sign in
			</Button>
			<div className="mt-[8px] flex items-center font-[400]">
				New customer?
				<Link {...{ href: '/account/register' }}>Start here.</Link>
			</div>
		</div>
	);
};

const NoLoginEl = ({ loginVisible, setLoginVisible, navigate }) => {
	return (
		<>
			<Popover
				content={<ToLoginEl {...{ navigate }} />}
				trigger="hover"
				placement="bottomRight"
				// open={loginVisible}
				onOpenChange={(boo) => setLoginVisible(boo)}
				overlayClassName="siteHeaderPopover hiddenPopover"
				arrow={false}
			>
				<div className="flex items-center cursor-pointer">
					<AvatortouxiangWeidenglu
						style={{ width: 24, height: 24 }}
					/>

					<div style={{ textAlign: 'left', marginLeft: 8 }}>
						<div style={{ fontWeight: 400 }}>Welcome</div>

						<div
							style={{ fontWeight: 900 }}
							className="flex items-center"
						>
							Sign in / Register
							<Downxia
								fill="white"
								className="transition"
								style={{
									transform: `rotate(${loginVisible ? 180 : 0}deg)`
								}}
							/>
						</div>
					</div>
				</div>
			</Popover>
		</>
	);
};

export default function ({ setMaskVisible }) {
	const user = userInfo();
	const [loginVisible, setLoginVisible] = useState(false);
	useEffect(() => {
		setMaskVisible(loginVisible);
	}, [loginVisible, setMaskVisible]);
	const navigate = useNavigate();
	return (
		<div className="siteHeaderLogin ml-[32px]">
			{user ? (
				<LoginEL
					{...{ user, loginVisible, setLoginVisible, navigate }}
				/>
			) : (
				<NoLoginEl {...{ loginVisible, setLoginVisible, navigate }} />
			)}
		</div>
	);
}
