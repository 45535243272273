import Facebook from '@/common/icons/Facebook';
import Ins from '@/common/icons/Ins';
import InsCpidob83 from '@/common/icons/InsCpidob83';
import Tiktok from '@/common/icons/Tiktok';
import TiktokCpie8p4m from '@/common/icons/TiktokCpie8p4m';
import Twitter from '@/common/icons/Twitter';
import TikTokCateList from '@/container/mall/goods/TikTokCateList';

const contactUs = [
	{
		logo: (
			<TiktokCpie8p4m
				size={32}
				color="#fff"
				className="opacity-25 hover:opacity-65 "
			/>
		),

		href: 'https://www.tiktok.com/@sellerstation_official',
		title: 'Tiktok'
	},
	{
		logo: (
			<Twitter
				size={32}
				color="#fff"
				className="opacity-25 hover:opacity-65 "
			/>
		),

		href: 'https://twitter.com/seller_station',
		title: 'Twitter'
	},
	{
		logo: (
			<InsCpidob83
				size={32}
				color="#fff"
				className="opacity-25 hover:opacity-65 "
			/>
		),

		href: 'https://www.instagram.com/sellerstation_official/',
		title: 'Instagram'
	},
	{
		logo: (
			<Facebook
				size={32}
				color="#fff"
				className="opacity-25 hover:opacity-65 "
			/>
		),

		href: 'https://www.facebook.com/profile.php?id=61558247930469',
		title: 'facebook'
	}
];

export default function () {
	return (
		<div className=" w-[100%]  bg-black-950 ">
			<div className="w-[1200px]  mx-auto">
				<div className="py-[48px] flex">
					<div className="w-[504px] mr-[48px]">
						<div>
							<img
								className="h-[40px]"
								src="https://static-us.theckb.com/static-asset/images/footer-logo.png"
								alt=""
							/>
						</div>
						<div className="mt-[8px]  text-white-65">
							A big data-driven one-stop sourcing platform to
							enhance your online business.
						</div>
						<div className="mt-[24px] flex">
							{contactUs.map((i) => {
								return (
									<div
										className="mr-[32px] cursor-pointer"
										key={i.title}
										onClick={() => {
											window.open(i.href);
										}}
									>
										{i.logo}
									</div>
								);
							})}
						</div>
					</div>
					<div className="mr-[48px]">
						<div className="text-[#FE2C55] font-extrabold text-lg">
							Address
						</div>
						<div className="text-white-65 mt-[24px] text-base">
							<div className="text-white-0 hover:text-white-0 text-lg">
								Hangzhou Zhixingbian Supply Chain Co., Ltd.
							</div>
							<div className="mt-[8px]">
								8 The Green, Suite A<br />
								Dover, DE 19901
								<br />
								Kent County
							</div>
						</div>
						<div className="mt-[24px]">
							<img
								className="h-[108px]"
								src="https://static-us.theckb.com/static-asset/images/footer-map.png"
								alt=""
							/>
						</div>
					</div>
					<div className="flex-1">
						<div className="text-[#FE2C55] font-extrabold text-lg">
							Feedback
						</div>
						<div className="mt-[24px]">
							<a
								className="text-white-0 hover:text-white-0 text-lg"
								href="mailto:support@sellerstation.cn"
								target="_blank"
							>
								support@sellerstation.cn
							</a>
						</div>
						<div className="mt-[8px] text-white-65">
							{`If you have any questions or comments about
						SellerStation's services, please contact us here.
						We will respond to your questions and
					comments within 24 hours. Thank you.`}
						</div>
					</div>
				</div>
				<div className=" text-right text-white-45 pb-[48px]">
					{window._$m.t(
						`©︎ 2024 sellerStation All Rights Reserved.浙ICP备16032890号`
					)}
				</div>
			</div>
		</div>
	);
}
