import { Button } from 'antd';
import Header from '@/common/mall/Header';
import { CartPanel } from '@/components/base/panel';

export function Skeleton() {
	return (
		<>
			<Header />
			<div
				id="cart"
				className=" relative space-y-4 pt-10 pb-10 bg-black-0 m-auto w-[1200px] text-base scroll-smooth"
			>
				<div className=" text-h2 font-extrabold leading-10">Cart</div>
				<div className=" grid grid-cols-1 gap-4">
					{/* 选择地址 */}
					<Addr />
					{/* 选择物流 */}
					<Shipping />
					{/* 购物车列表 */}
					<CartList />
				</div>
			</div>
			<div className=" sticky bottom-0 border-t border-black-200 bg-black-0 z-10 text-base">
				<div className=" w-[1200px] m-auto">
					{/* 支付 */}
					<div className="py-4 h-[147px]" />
				</div>
			</div>
		</>
	);
}

function Addr() {
	return (
		<CartPanel>
			<>
				<span className=" text-blood-600 mr-1">*</span>
				<div className="flex-1">
					{window._$m.t('第一步:选择您的收货地址')}
				</div>
				<Button className=" rounded-full px-4 font-bold">
					{window._$m.t('添加地址')}
				</Button>
			</>
			<div className=" h-[167px]" />
		</CartPanel>
	);
}

function Shipping() {
	return (
		<CartPanel>
			<>
				<span className=" text-blood-600 mr-1">*</span>
				<div className="flex-1">
					Step 2:Please confirm the logistics route and packaging
				</div>
			</>
			<div className=" h-[80px]" />
		</CartPanel>
	);
}

function CartList() {
	return (
		<CartPanel>
			<Button className=" rounded-full font-bold" type="primary" disabled>
				Bulk editing of product options
			</Button>
			<div className=" h-[400px]" />
		</CartPanel>
	);
}
