import { Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import Header from '@/common/layout/header';

export default function NotFound() {
	const navigate = useNavigate();
	return (
		<div>
			<Header />
			<div className="flex items-center fixed top-0 left-0 right-0 bottom-0 justify-center">
				<img
					src="https://static-us.theckb.com/static-asset/images/404.png"
					alt=""
					className="w-[320px] mr-6"
				/>

				<div className="w-[360px]">
					<div className="text-[32px] font-extrabold leading-[46px] mb-2 text-font-Title">
						404 Not Found
					</div>
					<div className="text-font-regular">
						We&apos;re sorry, the page you&apos;re trying to visit
						has an incorrect address or does not exist.
					</div>
					<Button
						type="primary"
						className="rounded-full h-[40px] mt-6 mb-[50px] font-extrabold"
						onClick={() => {
							navigate('/');
						}}
					>
						Back to homepage
					</Button>
					<div className="border-t-black-200 border-t pt-6">
						<span className="text-font-Title">Feedback:</span>
						<a
							className="text-sea-900 ml-1"
							href="mailto:support@sellerstation.cn"
							target="_blank"
						>
							support@sellerstation.cn
						</a>
					</div>
				</div>
			</div>
		</div>
	);
}
