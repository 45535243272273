import { request } from '@/config/request';
import { ShopShippingAddressRespDTO } from '@/service/customer';
import { asyncData } from './_base';

// 收货地址列表
const receives = asyncData(
	{
		get: async () => {
			const res = await request.customer.customer
				.shopGetShippingAddressList({})
				.catch(() => ({ data: [] }));
			// 收货地址转换成下单地址，历史问题兼容
			const addrs = res.data?.map((v: any) => {
				v.country = '2115';
				v.countryJp = v.countryName;
				v.province = v.area;
				v.cityJp = v.cityEn;
				v.provinceJp = v.areaEn;
				v.receiveTel = v.phone;
				return v;
			}) as ShopShippingAddressRespDTO[];
			// 默认地址放第一位
			addrs.sort((a) => 0 - a.defaultAddress!);
			return addrs;
		},
		set: async () => {},
		getDefaultAddrId(store) {
			const addrs = store.getState();
			return addrs.length === 1
				? addrs[0].shopShippingAddressId
				: addrs.find((v) => v.defaultAddress)?.shopShippingAddressId;
		},
		getReceiveById(store, id) {
			return store.getState().find((v) => v.shopShippingAddressId === id);
		}
	},
	{ defaultValue: [] }
);

// todo:api生成
const ReceiveDict = {
	receiveName: 'Name',
	postalCode: 'Zip Code',
	countryName: 'Country',
	areaEn: 'State',
	cityEn: 'City',
	addressEn: 'Street Address',
	phone: 'Phone number',
	email: 'E-mail'
} as const;

// 清关地址
const clear = asyncData(async () => null);

export { receives, ReceiveDict };
