import _ from 'lodash';
import { ReactNode } from 'react';

type Render = {
	render: <U, P extends keyof U>(
		value: U[P],
		label: string,
		record: U
	) => ReactNode;
};

type Standard<T> = {
	// 字段名
	key: keyof T;
	// 字段名对应Label
	label?: string;
} & {
	[key: string]: any;
};

export const toStands = <
	T extends { [key: string]: unknown },
	K extends (Standard<T> | keyof T)[],
	U extends { [key: string]: unknown }
>(
	Dict: T,
	stands: K,
	custom?: Result<U, K>
) => {
	return stands.map((v: any) => {
		const { key, ...rest } = v.key ? v : { key: v };
		const stand = {
			key,
			label: Dict[key],
			...rest
		};
		const source = typeof custom === 'function' ? custom(stand) : custom;
		return _.merge(source, stand) as Standard<T> & U;
	});
};

/** 样式组件 */

export function Label(props) {
	const { children, ...rest } = props;
	return (
		<label className=" text-font-regular font-bold" {...rest}>
			{children}
		</label>
	);
}

export function Info<T>({
	record,
	stand,
	className = ''
}: {
	record: T;
	stand: Standard<T>;
	className?: string;
}) {
	const { key, label } = stand;
	const value: any = record[key];
	return (
		<div className={className} key={label}>
			<Label>{label}</Label>:
			<span className=" text-font-primary">{value}</span>
		</div>
	);
}
