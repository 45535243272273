import { serviceConfig } from '@/config/request/swaggerServiceConfig';
import { Api as Order } from './order';
import { Api as Customer } from './customer';
import { Api as Goods } from './goods';
import { Api as Settlement } from './settlement';
import { Api as Pay } from './pay';
import { Api as Market } from './market';
import { Api as Purchase } from './purchase';
import { Api as Wms } from './wms';

export const Api = {
	Order,
	Customer,
	Goods,
	Settlement,
	Pay,
	Market,
	Purchase,
	Wms
};

const warpperServiceConfig = (
	serviceConfig: any,
	ctx: { name: string; basePath: string }
) => {
	const newConfig = { ...serviceConfig };
	if (newConfig.baseURL) {
		newConfig.baseURL = newConfig.baseURL + ctx.basePath;
	}
	return newConfig;
};

const order = new Order(
	warpperServiceConfig(serviceConfig, { name: 'order', basePath: '/order' })
);
const customer = new Customer(
	warpperServiceConfig(serviceConfig, {
		name: 'customer',
		basePath: '/customer'
	})
);
const goods = new Goods(
	warpperServiceConfig(serviceConfig, { name: 'goods', basePath: '/goods' })
);
const settlement = new Settlement(
	warpperServiceConfig(serviceConfig, {
		name: 'settlement',
		basePath: '/settlement'
	})
);
const pay = new Pay(
	warpperServiceConfig(serviceConfig, { name: 'pay', basePath: '/pay' })
);
const market = new Market(
	warpperServiceConfig(serviceConfig, { name: 'market', basePath: '/market' })
);
const purchase = new Purchase(
	warpperServiceConfig(serviceConfig, {
		name: 'purchase',
		basePath: '/purchase'
	})
);
const wms = new Wms(
	warpperServiceConfig(serviceConfig, { name: 'wms', basePath: '' })
);

export const apiInstanceList = [
	{ key: 'order', instance: order },
	{ key: 'customer', instance: customer },
	{ key: 'goods', instance: goods },
	{ key: 'settlement', instance: settlement },
	{ key: 'pay', instance: pay },
	{ key: 'market', instance: market },
	{ key: 'purchase', instance: purchase },
	{ key: 'wms', instance: wms }
];

export const api = {
	order,
	customer,
	goods,
	settlement,
	pay,
	market,
	purchase,
	wms
};
