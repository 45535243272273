/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

/**
 * AccountRechargeQueryReq
 * 账户充值记录分页查询请求参数
 */
export interface AccountRechargeQueryReq {
	/** 流水号 */
	balanceRechargeCode?: string;
	/**
	 * 开始时间
	 * @format date-time
	 */
	beginTime?: string;
	/**
	 * 结束时间
	 * @format date-time
	 */
	endTime?: string;
	/** @format int32 */
	pageNum?: number;
	/** @format int32 */
	pageSize?: number;
	/** @format int32 */
	startIndex?: number;
	/** 状态 */
	status?: string;
}

/**
 * AccountRechargeQueryResp
 * 账户充值记录查询返回
 */
export interface AccountRechargeQueryResp {
	/**
	 * 时间
	 * @format date-time
	 */
	applyTime?: string;
	/** 流水号 */
	balanceRechargeCode?: string;
	/** 币种 */
	currency?: string;
	/** 充值方式 BANK:银行转账 */
	rechargeWay?: string;
	/** 理由 */
	refuseReason?: string;
	/** 充值金额 */
	remittanceAmount?: number;
	/** 状态 */
	status?: string;
}

/**
 * AccountRechargeReq
 * 账户充值请求参数
 */
export interface AccountRechargeReq {
	/** 币种 */
	currency?: string;
	/** 充值方式 */
	rechargeWay?: string;
	/** 充值金额 */
	remittanceAmount?: number;
	/** 银行名 */
	remitterBankName?: string;
	/** 汇款人姓名 */
	remitterName?: string;
	/** 转账凭证 */
	voucherUrl?: string;
}

/**
 * AccountResp
 * 用户账户(钱包)
 */
export interface AccountResp {
	/** 可用金额 */
	availableBalance?: number;
	/** 币种 */
	currency?: string;
	/**
	 * 用户id
	 * @format int64
	 */
	customerId?: number;
	/** 未入账金额 */
	preSettleBalance?: number;
}

/**
 * AccountTransLogQueryReq
 * 账户流水查询请求参数
 */
export interface AccountTransLogQueryReq {
	/**
	 * 开始时间
	 * @format date-time
	 */
	beginTime?: string;
	/** 费用类型 交易支付PAY/充值RECHARGE */
	bizType?: string;
	/**
	 * 结束时间
	 * @format date-time
	 */
	endTime?: string;
	/** @format int32 */
	pageNum?: number;
	/** @format int32 */
	pageSize?: number;
	/** 单号搜索 */
	relationBizNo?: string;
	/** @format int32 */
	startIndex?: number;
}

/**
 * AccountTransLogResp
 * 用户账户流水明细
 */
export interface AccountTransLogResp {
	/** 账户id */
	accountId?: string;
	/** 金额 */
	amount?: number;
	/** 余额 */
	balance?: number;
	/** 业务类型 PAY:交易支付 RECHARGE:充值 */
	bizType?: string;
	/** 币种 */
	currency?: string;
	/**
	 * 用户id
	 * @format int64
	 */
	customerId?: number;
	/** 资金方向 AMOUNT_IN:入账(+) AMOUNT_OUT:出账(-) */
	direct?: string;
	/** 支付方式 BANK:线下银行 WALLET:余额钱包 */
	payWay?: string;
	/** 关联单号 */
	relationBizNo?: string;
	/** 流水号 */
	transLogId?: string;
	/**
	 * 交易时间
	 * @format date-time
	 */
	transTime?: string;
}

/**
 * BackendAccountQueryReq
 * 账户分页查询请求参数
 */
export interface BackendAccountQueryReq {
	/**
	 * 客户id
	 * @format int64
	 */
	customerId?: number;
	/** 用户名 */
	customerName?: string;
	/** @format int32 */
	pageNum?: number;
	/** @format int32 */
	pageSize?: number;
	/** @format int32 */
	startIndex?: number;
	/** 站点 暂时只有日本站 */
	stationCode?: string;
	/** 统一客户全名 */
	unificationCustomerFullName?: string;
}

/**
 * BackendAccountResp
 * 后台-用户账户(钱包)
 */
export interface BackendAccountResp {
	/** 账户id */
	accountId?: string;
	/** 可用金额 */
	availableBalance?: number;
	/** 币种 */
	currency?: string;
	/**
	 * 用户id
	 * @format int64
	 */
	customerId?: number;
	/** 用户名 */
	customerName?: string;
	/** 冻结金额 */
	frozenBalance?: number;
	/** 未入账金额 */
	preSettleBalance?: number;
	/** 账户金额 */
	totalBalance?: number;
	/** 统一客户全名 */
	unificationCustomerFullName?: string;
}

/**
 * BackendAccountTransLogQueryReq
 * 后台账户分页查询请求参数
 */
export interface BackendAccountTransLogQueryReq {
	/** 账户id */
	accountId?: string;
	/**
	 * 开始时间
	 * @format date-time
	 */
	beginTime?: string;
	/** 费用类型 交易支付PAY/充值RECHARGE */
	bizType?: string;
	/**
	 * 客户id
	 * @format int64
	 */
	customerId?: number;
	/** 出账类型 */
	direct?: string;
	/**
	 * 结束时间
	 * @format date-time
	 */
	endTime?: string;
	/** @format int32 */
	pageNum?: number;
	/** @format int32 */
	pageSize?: number;
	/** 单号搜索 */
	relationBizNo?: string;
	/** @format int32 */
	startIndex?: number;
	/** 站点 */
	stationCode?: string;
}

/**
 * BackendBalanceRechargeAuditReq
 * 充值单审批参数
 */
export interface BackendBalanceRechargeAuditReq {
	/** 审核意见 */
	auditStatus?: boolean;
	/** 充值单号 */
	balanceRechargeCode?: string;
	/** 财务对账金额 */
	financeRechargeAmount?: number;
	/**
	 * 财务到账日期
	 * @format date-time
	 */
	financeRechargeArrivalTime?: string;
	/** 财务对账金额(人民币) */
	financeRechargeCnyAmount?: number;
	/** 拒绝原因 */
	refuseReason?: string;
}

/**
 * BackendBalanceRechargeQueryReq
 * 后台充值单分页查询请求参数
 */
export interface BackendBalanceRechargeQueryReq {
	/**
	 * 到账日期开始
	 * @format date-time
	 */
	beginTime?: string;
	/**
	 * 客户id
	 * @format int64
	 */
	customerId?: number;
	/** 客户名称 */
	customerName?: string;
	/**
	 * 到账日期结束时间
	 * @format date-time
	 */
	endTime?: string;
	/**
	 * 财务到账日期开始
	 * @format date-time
	 */
	financeArrivalBeginTime?: string;
	/**
	 * 财务到账日期结束时间
	 * @format date-time
	 */
	financeArrivalEndTime?: string;
	/** 财务对账金额填充 true/false */
	isFinanceSet?: boolean;
	/** @format int32 */
	pageNum?: number;
	/** @format int32 */
	pageSize?: number;
	/** 支付状态 WAIT未支付 SUCCESS支付成功 */
	payStatus?: string;
	/** 充值金额开始(RMB) */
	rechargeCnyAmountBegin?: number;
	/** 充值金额结束(RMB) */
	rechargeCnyAmountEnd?: number;
	/** 充值渠道(支付渠道) BANK:银行汇款 */
	rechargeWay?: string;
	/** 汇款金额起始 */
	remittanceAmountBegin?: number;
	/** 汇款金额结束 */
	remittanceAmountEnd?: number;
	/** 账户持有人 */
	remitterName?: string;
	/** @format int32 */
	startIndex?: number;
	/** 充值状态 BUSINESS待初审 FINAL待终审 REFUSE已拒绝 SUCCESS已通过, */
	status?: string;
}

/** BackendBalanceRechargeResp */
export interface BackendBalanceRechargeResp {
	/**
	 * 申请时间
	 * @format date-time
	 */
	applyTime?: string;
	/** 充值单号(流水号) */
	balanceRechargeCode?: string;
	/** 币种 */
	currency?: string;
	/**
	 * 客户id
	 * @format int64
	 */
	customerId?: number;
	/** 客户name */
	customerName?: string;
	/** 浮动后汇率 */
	exchangeRate?: number;
	/** 财务对账金额 */
	financeRechargeAmount?: number;
	/**
	 * 财务到账时间
	 * @format date-time
	 */
	financeRechargeArrivalTime?: string;
	/** 财务对账金额(RMB) */
	financeRechargeCnyAmount?: number;
	/** 操作人name */
	operName?: string;
	/**
	 * 操作时间
	 * @format date-time
	 */
	operTime?: string;
	/** 原始汇率 */
	originExchangeRate?: number;
	/** 支付状态 WAIT未支付 SUCCESS已支付 */
	payStatus?: string;
	/**
	 * 到账时间
	 * @format date-time
	 */
	rechargeArrivalTime?: string;
	/** 充值金额RMB */
	rechargeCnyAmount?: number;
	/** 充值方式 BANK:银行汇款 */
	rechargeWay?: string;
	/** 拒绝原因 */
	refuseReason?: string;
	/** 汇款金额 */
	remittanceAmount?: number;
	/** 银行名称 */
	remitterBankName?: string;
	/** 账户持有人 */
	remitterName?: string;
	/** 站点 */
	stationCode?: string;
	/** 状态 待初审;待终审;已通过;已拒绝 */
	status?: string;
	/** 统一客户名称 */
	unificationCustomerFullName?: string;
	/** 凭证url */
	voucherUrl?: string;
	/** 中银汇率 */
	zhongyinExchangeRate?: number;
}

/**
 * BackendBalanceRechargeUpdateReq
 * 充值单更新参数
 */
export interface BackendBalanceRechargeUpdateReq {
	/** 充值单号 */
	balanceRechargeCode?: string;
	/** 财务对账金额 */
	financeRechargeAmount?: number;
}

/** BizResponse */
export interface BizResponse {
	code?: string;
	data?: object;
	msg?: string;
	success?: boolean;
}

/**
 * CashierCreateAndPayReq
 * 支付参数
 */
export interface CashierCreateAndPayReq {
	/** 业务单号 直采商城用订单号 */
	bizNo?: string;
	/** 业务类型 EASY_ORDER:直采商城 */
	bizType?: string;
	/** 支付方式key */
	payTypeKey?: string;
	/**
	 * 请求时间
	 * @format date-time
	 */
	requestTime?: string;
}

/**
 * CashierPayResp
 * 支付返回结果
 */
export interface CashierPayResp {
	/** 业务单号 */
	bizNo?: string;
	/** 渠道支付单号 */
	payChannelNo?: string;
	/** 支付单号 */
	payNo?: string;
	/** 第三方单号 */
	thirdId?: string;
	/** 返回链接 */
	url?: string;
}

/** OrderItem */
export interface OrderItem {
	asc?: boolean;
	column?: string;
}

/**
 * PayTypeResp
 * 支付方式
 */
export interface PayTypeResp {
	/** 内容 (那个问号) */
	content?: string;
	/** 支付方式说明 (使用PalPay支付....等等) */
	desc?: string;
	/** 图标 */
	icon?: string;
	/** 图片 */
	img?: string;
	/** 支付方式key WALLET PAYPAL PAYPAL_CREDIT  (ps:WALLET这个需要特殊处理) */
	key?: string;
	/** 站点 */
	stationCode?: string;
	/** 支付方式value 钱包/paypal/palpay信用卡 */
	value?: string;
}

/**
 * RechargeRequestDownloadPdfReq
 * 充值申请书pdf下载请求参数
 */
export interface RechargeRequestDownloadPdfReq {
	/** 充值方式 */
	rechargeWay?: string;
	/** 请求金额 */
	remittanceAmount?: number;
	/** 请求对象姓名 */
	remitterName?: string;
}

/**
 * ThirdCaptureReq
 * 捕获参数
 */
export interface ThirdCaptureReq {
	/** 第三方单号 */
	thirdId?: string;
}

/** BizResponse«AccountResp» */
export interface BizResponseAccountResp {
	code?: string;
	/** 用户账户(钱包) */
	data?: AccountResp;
	msg?: string;
	success?: boolean;
}

/** BizResponse«CashierPayResp» */
export interface BizResponseCashierPayResp {
	code?: string;
	/** 支付返回结果 */
	data?: CashierPayResp;
	msg?: string;
	success?: boolean;
}

/** BizResponse«List«PayTypeResp»» */
export interface BizResponseListPayTypeResp {
	code?: string;
	data?: PayTypeResp[];
	msg?: string;
	success?: boolean;
}

/** BizResponse«Page«AccountRechargeQueryResp»» */
export interface BizResponsePageAccountRechargeQueryResp {
	code?: string;
	data?: PageAccountRechargeQueryResp;
	msg?: string;
	success?: boolean;
}

/** BizResponse«Page«AccountTransLogResp»» */
export interface BizResponsePageAccountTransLogResp {
	code?: string;
	data?: PageAccountTransLogResp;
	msg?: string;
	success?: boolean;
}

/** BizResponse«Page«BackendAccountResp»» */
export interface BizResponsePageBackendAccountResp {
	code?: string;
	data?: PageBackendAccountResp;
	msg?: string;
	success?: boolean;
}

/** BizResponse«Page«BackendBalanceRechargeResp»» */
export interface BizResponsePageBackendBalanceRechargeResp {
	code?: string;
	data?: PageBackendBalanceRechargeResp;
	msg?: string;
	success?: boolean;
}

/** BizResponse«boolean» */
export interface BizResponseBoolean {
	code?: string;
	data?: boolean;
	msg?: string;
	success?: boolean;
}

/** BizResponse«long» */
export interface BizResponseLong {
	code?: string;
	/** @format int64 */
	data?: number;
	msg?: string;
	success?: boolean;
}

/** BizResponse«object» */
export interface BizResponseObject {
	code?: string;
	data?: object;
	msg?: string;
	success?: boolean;
}

/** BizResponse«string» */
export interface BizResponseString {
	code?: string;
	data?: string;
	msg?: string;
	success?: boolean;
}

/** Page«AccountRechargeQueryResp» */
export interface PageAccountRechargeQueryResp {
	countId?: string;
	/** @format int64 */
	current?: number;
	hitCount?: boolean;
	/** @format int64 */
	maxLimit?: number;
	optimizeCountSql?: boolean;
	orders?: OrderItem[];
	/** @format int64 */
	pages?: number;
	records?: AccountRechargeQueryResp[];
	searchCount?: boolean;
	/** @format int64 */
	size?: number;
	/** @format int64 */
	total?: number;
}

/** Page«AccountTransLogResp» */
export interface PageAccountTransLogResp {
	countId?: string;
	/** @format int64 */
	current?: number;
	hitCount?: boolean;
	/** @format int64 */
	maxLimit?: number;
	optimizeCountSql?: boolean;
	orders?: OrderItem[];
	/** @format int64 */
	pages?: number;
	records?: AccountTransLogResp[];
	searchCount?: boolean;
	/** @format int64 */
	size?: number;
	/** @format int64 */
	total?: number;
}

/** Page«BackendAccountResp» */
export interface PageBackendAccountResp {
	countId?: string;
	/** @format int64 */
	current?: number;
	hitCount?: boolean;
	/** @format int64 */
	maxLimit?: number;
	optimizeCountSql?: boolean;
	orders?: OrderItem[];
	/** @format int64 */
	pages?: number;
	records?: BackendAccountResp[];
	searchCount?: boolean;
	/** @format int64 */
	size?: number;
	/** @format int64 */
	total?: number;
}

/** Page«BackendBalanceRechargeResp» */
export interface PageBackendBalanceRechargeResp {
	countId?: string;
	/** @format int64 */
	current?: number;
	hitCount?: boolean;
	/** @format int64 */
	maxLimit?: number;
	optimizeCountSql?: boolean;
	orders?: OrderItem[];
	/** @format int64 */
	pages?: number;
	records?: BackendBalanceRechargeResp[];
	searchCount?: boolean;
	/** @format int64 */
	size?: number;
	/** @format int64 */
	total?: number;
}

import axios, { AxiosInstance, AxiosRequestConfig, HeadersDefaults, ResponseType } from 'axios';

export type QueryParamsType = Record<string | number, any>;

export interface FullRequestParams extends Omit<AxiosRequestConfig, 'data' | 'params' | 'url' | 'responseType'> {
	/** set parameter to `true` for call `securityWorker` for this request */
	secure?: boolean;
	/** request path */
	path: string;
	/** content type of request body */
	type?: ContentType;
	/** query params */
	query?: QueryParamsType;
	/** format of response (i.e. response.json() -> format: "json") */
	format?: ResponseType;
	/** request body */
	body?: unknown;
}

export type RequestParams = Omit<FullRequestParams, 'body' | 'method' | 'query' | 'path'>;

export interface ApiConfig<SecurityDataType = unknown> extends Omit<AxiosRequestConfig, 'data' | 'cancelToken'> {
	securityWorker?: (
		securityData: SecurityDataType | null
	) => Promise<AxiosRequestConfig | void> | AxiosRequestConfig | void;
	secure?: boolean;
	format?: ResponseType;
}

export enum ContentType {
	Json = 'application/json',
	FormData = 'multipart/form-data',
	UrlEncoded = 'application/x-www-form-urlencoded',
	Text = 'text/plain'
}

export class HttpClient<SecurityDataType = unknown> {
	public instance: AxiosInstance;
	private securityData: SecurityDataType | null = null;
	private securityWorker?: ApiConfig<SecurityDataType>['securityWorker'];
	private secure?: boolean;
	private format?: ResponseType;

	constructor({ securityWorker, secure, format, ...axiosConfig }: ApiConfig<SecurityDataType> = {}) {
		this.instance = axios.create({
			...axiosConfig,
			baseURL: axiosConfig.baseURL || '//master-gateway.theckb.com:443/pay'
		});
		this.secure = secure;
		this.format = format;
		this.securityWorker = securityWorker;
	}

	public setSecurityData = (data: SecurityDataType | null) => {
		this.securityData = data;
	};

	protected mergeRequestParams(params1: AxiosRequestConfig, params2?: AxiosRequestConfig): AxiosRequestConfig {
		const method = params1.method || (params2 && params2.method);

		return {
			...this.instance.defaults,
			...params1,
			...(params2 || {}),
			headers: {
				...((method && this.instance.defaults.headers[method.toLowerCase() as keyof HeadersDefaults]) || {}),
				...(params1.headers || {}),
				...((params2 && params2.headers) || {})
			}
		};
	}

	protected stringifyFormItem(formItem: unknown) {
		if (typeof formItem === 'object' && formItem !== null) {
			return JSON.stringify(formItem);
		} else {
			return `${formItem}`;
		}
	}

	protected createFormData(input: Record<string, unknown>): FormData {
		return Object.keys(input || {}).reduce((formData, key) => {
			const property = input[key];
			const propertyContent: any[] = property instanceof Array ? property : [property];

			for (const formItem of propertyContent) {
				const isFileType = formItem instanceof Blob || formItem instanceof File;
				formData.append(key, isFileType ? formItem : this.stringifyFormItem(formItem));
			}

			return formData;
		}, new FormData());
	}

	public request = async <T = any, _E = any>({
		secure,
		path,
		type,
		query,
		format,
		body,
		...params
	}: FullRequestParams): Promise<T> => {
		const secureParams =
			((typeof secure === 'boolean' ? secure : this.secure) &&
				this.securityWorker &&
				(await this.securityWorker(this.securityData))) ||
			{};
		const requestParams = this.mergeRequestParams(params, secureParams);
		const responseFormat = format || this.format || undefined;

		if (type === ContentType.FormData && body && body !== null && typeof body === 'object') {
			body = this.createFormData(body as Record<string, unknown>);
		}

		if (type === ContentType.Text && body && body !== null && typeof body !== 'string') {
			body = JSON.stringify(body);
		}

		return this.instance
			.request({
				...requestParams,
				headers: {
					...(requestParams.headers || {}),
					...(type && type !== ContentType.FormData ? { 'Content-Type': type } : {})
				},
				params: query,
				responseType: responseFormat,
				data: body,
				url: path
			})
			.then((response) => response.data);
	};
}

/**
 * @title API文档
 * @version v1.0.0
 * @baseUrl //master-gateway.theckb.com:443/pay
 * @contact 史尼芙 (https://www.taobaockb.com/)
 *
 * 史尼芙API文档
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
	probe = {
		/**
		 * No description
		 *
		 * @tags livens-probe-controller
		 * @name Test
		 * @summary 执行一次健康检查探针
		 * @request GET:/Probe/test
		 */
		test: (params: RequestParams = {}) =>
			this.request<BizResponseObject, any>({
				path: `/Probe/test`,
				method: 'GET',
				...params
			})
	};
	backend = {
		/**
		 * No description
		 *
		 * @tags 后台账户相关
		 * @name AccountQueryExport
		 * @summary 用户钱包导出
		 * @request POST:/backend/account/query/export
		 */
		accountQueryExport: (req: BackendAccountQueryReq, params: RequestParams = {}) =>
			this.request<BizResponseLong, any>({
				path: `/backend/account/query/export`,
				method: 'POST',
				body: req,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 后台账户相关
		 * @name AccountQueryLogExport
		 * @summary 单个用户钱包流水导出
		 * @request POST:/backend/account/query/log/export
		 */
		accountQueryLogExport: (req: BackendAccountTransLogQueryReq, params: RequestParams = {}) =>
			this.request<BizResponseLong, any>({
				path: `/backend/account/query/log/export`,
				method: 'POST',
				body: req,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 后台账户相关
		 * @name AccountQueryLogMultiExport
		 * @summary 多个用户钱包流水导出
		 * @request POST:/backend/account/query/log/multi/export
		 */
		accountQueryLogMultiExport: (req: BackendAccountQueryReq, params: RequestParams = {}) =>
			this.request<BizResponseLong, any>({
				path: `/backend/account/query/log/multi/export`,
				method: 'POST',
				body: req,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 后台账户相关
		 * @name AccountQueryLogPage
		 * @summary 用户钱包资金流水分页查询
		 * @request POST:/backend/account/query/log/page
		 */
		accountQueryLogPage: (req: BackendAccountTransLogQueryReq, params: RequestParams = {}) =>
			this.request<BizResponsePageAccountTransLogResp, any>({
				path: `/backend/account/query/log/page`,
				method: 'POST',
				body: req,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 后台账户相关
		 * @name AccountQueryPage
		 * @summary 用户钱包分页查询
		 * @request POST:/backend/account/query/page
		 */
		accountQueryPage: (req: BackendAccountQueryReq, params: RequestParams = {}) =>
			this.request<BizResponsePageBackendAccountResp, any>({
				path: `/backend/account/query/page`,
				method: 'POST',
				body: req,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 后台充值相关
		 * @name RechargeBusinessAudit
		 * @summary 充值单初审
		 * @request POST:/backend/recharge/business/audit
		 */
		rechargeBusinessAudit: (req: BackendBalanceRechargeAuditReq, params: RequestParams = {}) =>
			this.request<BizResponseBoolean, any>({
				path: `/backend/recharge/business/audit`,
				method: 'POST',
				body: req,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 后台充值相关
		 * @name RechargeFinalAudit
		 * @summary 充值单终审
		 * @request POST:/backend/recharge/final/audit
		 */
		rechargeFinalAudit: (req: BackendBalanceRechargeAuditReq, params: RequestParams = {}) =>
			this.request<BizResponseBoolean, any>({
				path: `/backend/recharge/final/audit`,
				method: 'POST',
				body: req,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 后台充值相关
		 * @name RechargeQueryExport
		 * @summary 充值单导出
		 * @request POST:/backend/recharge/query/export
		 */
		rechargeQueryExport: (req: BackendBalanceRechargeQueryReq, params: RequestParams = {}) =>
			this.request<BizResponseLong, any>({
				path: `/backend/recharge/query/export`,
				method: 'POST',
				body: req,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 后台充值相关
		 * @name RechargeQueryPage
		 * @summary 充值单分页查询
		 * @request POST:/backend/recharge/query/page
		 */
		rechargeQueryPage: (req: BackendBalanceRechargeQueryReq, params: RequestParams = {}) =>
			this.request<BizResponsePageBackendBalanceRechargeResp, any>({
				path: `/backend/recharge/query/page`,
				method: 'POST',
				body: req,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 后台充值相关
		 * @name RechargeUpdate
		 * @summary 更新
		 * @request POST:/backend/recharge/update
		 */
		rechargeUpdate: (req: BackendBalanceRechargeUpdateReq, params: RequestParams = {}) =>
			this.request<BizResponseBoolean, any>({
				path: `/backend/recharge/update`,
				method: 'POST',
				body: req,
				type: ContentType.Json,
				...params
			})
	};
	front = {
		/**
		 * No description
		 *
		 * @tags 前台账户相关
		 * @name AccountQuery
		 * @summary 我的钱包-钱包信息查询
		 * @request GET:/front/account/query
		 */
		accountQuery: (params: RequestParams = {}) =>
			this.request<BizResponseAccountResp, any>({
				path: `/front/account/query`,
				method: 'GET',
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 前台账户相关
		 * @name AccountQueryLogExport
		 * @summary 我的钱包-钱包流水导出
		 * @request POST:/front/account/query/log/export
		 */
		accountQueryLogExport: (req: AccountTransLogQueryReq, params: RequestParams = {}) =>
			this.request<BizResponseLong, any>({
				path: `/front/account/query/log/export`,
				method: 'POST',
				body: req,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 前台账户相关
		 * @name AccountQueryLogPage
		 * @summary 我的钱包-钱包流水查询
		 * @request POST:/front/account/query/log/page
		 */
		accountQueryLogPage: (req: AccountTransLogQueryReq, params: RequestParams = {}) =>
			this.request<BizResponsePageAccountTransLogResp, any>({
				path: `/front/account/query/log/page`,
				method: 'POST',
				body: req,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 前台账户相关
		 * @name AccountQueryRechargeDownload
		 * @summary 余额充值受领书下载(钱包流水页面)
		 * @request GET:/front/account/query/recharge/download/{balanceRechargeCode}
		 */
		accountQueryRechargeDownload: (balanceRechargeCode: string, params: RequestParams = {}) =>
			this.request<void, any>({
				path: `/front/account/query/recharge/download/${balanceRechargeCode}`,
				method: 'GET',
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 前台账户相关
		 * @name AccountQueryRechargePage
		 * @summary 余额充值记录查询
		 * @request POST:/front/account/query/recharge/page
		 */
		accountQueryRechargePage: (req: AccountRechargeQueryReq, params: RequestParams = {}) =>
			this.request<BizResponsePageAccountRechargeQueryResp, any>({
				path: `/front/account/query/recharge/page`,
				method: 'POST',
				body: req,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 前台账户相关
		 * @name AccountQueryRechargeRequestDownload
		 * @summary 余额充值申请书下载(充值申请页面)
		 * @request POST:/front/account/query/recharge/request/download
		 */
		accountQueryRechargeRequestDownload: (req: RechargeRequestDownloadPdfReq, params: RequestParams = {}) =>
			this.request<void, any>({
				path: `/front/account/query/recharge/request/download`,
				method: 'POST',
				body: req,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 前台账户相关
		 * @name AccountRecharge
		 * @summary 余额充值申请
		 * @request POST:/front/account/recharge
		 */
		accountRecharge: (req: AccountRechargeReq, params: RequestParams = {}) =>
			this.request<BizResponseBoolean, any>({
				path: `/front/account/recharge`,
				method: 'POST',
				body: req,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 前台支付相关
		 * @name PostFront
		 * @summary 支付-点击支付
		 * @request POST:/front/pay
		 */
		postFront: (cashierPayReq: CashierCreateAndPayReq, params: RequestParams = {}) =>
			this.request<BizResponseCashierPayResp, any>({
				path: `/front/pay`,
				method: 'POST',
				body: cashierPayReq,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 前台支付相关
		 * @name PayCapture
		 * @summary 支付-paypal捕获
		 * @request POST:/front/pay/capture
		 */
		payCapture: (req: ThirdCaptureReq, params: RequestParams = {}) =>
			this.request<BizResponse, any>({
				path: `/front/pay/capture`,
				method: 'POST',
				body: req,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 前台支付查询相关
		 * @name PayQueryType
		 * @summary 支付-支付方式拉取
		 * @request GET:/front/pay/query/type
		 */
		payQueryType: (params: RequestParams = {}) =>
			this.request<BizResponseListPayTypeResp, any>({
				path: `/front/pay/query/type`,
				method: 'GET',
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 前台获取paypal信息
		 * @name PaypalClientId
		 * @summary 支付-获取paypal clientId
		 * @request GET:/front/paypal/clientId
		 */
		paypalClientId: (params: RequestParams = {}) =>
			this.request<BizResponseString, any>({
				path: `/front/paypal/clientId`,
				method: 'GET',
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 前台获取paypal信息
		 * @name PaypalToken
		 * @summary 支付-获取paypal客户token
		 * @request GET:/front/paypal/token
		 */
		paypalToken: (params: RequestParams = {}) =>
			this.request<BizResponseString, any>({
				path: `/front/paypal/token`,
				method: 'GET',
				...params
			})
	};
	test = {
		/**
		 * No description
		 *
		 * @tags test-controller
		 * @name GetTest
		 * @summary aaa
		 * @request GET:/test/aaa
		 */
		getTest: (params: RequestParams = {}) =>
			this.request<BizResponseString, any>({
				path: `/test/aaa`,
				method: 'GET',
				...params
			}),

		/**
		 * No description
		 *
		 * @tags test-controller
		 * @name GetTest2
		 * @summary bbb
		 * @request GET:/test/bbb
		 * @originalName getTest
		 * @duplicate
		 */
		getTest2: (params: RequestParams = {}) =>
			this.request<BizResponseString, any>({
				path: `/test/bbb`,
				method: 'GET',
				...params
			})
	};
}
