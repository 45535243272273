/*
 * @Author: shiguang
 * @Date: 2023-10-16 16:29:34
 * @LastEditors: liusha
 * @LastEditTime: 2023-11-07 15:23:26
 * @Description:
 */
import {
	PayPalScriptProvider,
	PayPalHostedField,
	PayPalHostedFieldsProvider,
	usePayPalHostedFields,
	PayPalButtons
} from '@paypal/react-paypal-js';
import { OnApproveData } from '@paypal/paypal-js/types/components/buttons';

import { useAsyncEffect, useRequest } from 'ahooks';
import dayjs from 'dayjs';
import {
	forwardRef,
	useEffect,
	useImperativeHandle,
	useRef,
	useState
} from 'react';
import { Input, InputNumber, Modal, message } from 'antd';
import { useAtom, useAtomValue } from 'jotai';
import classNames from 'classnames';
import { prop } from 'ramda';
import { request } from '@/config/request';
import './index.scss';
import useEvent from '@/hooks/useEvent';
import { OrderType, PayContent } from '@/const/enum';
import {
	ManualWalletRechargeMembershipVO,
	MembershipTemplatePrice
} from '@/service/settlement';
import { formatMoneyCny } from '@/utils/biz';
import { systemUserRate } from '@/atom/mall/user';
import { US_INFO } from '@/container/account/components/const';
import { rateInfo } from '@/atom/rate';
import {
	calRateToUs,
	useRateFormatMoney,
	useRateFormatMoneyFloor
} from '@/hooks/useRateFormatMoney';
import PaypalLoading from './component/PaypalLoading';
const requestParams = {
	bizNo: '432437',
	bizType: 'EASY_ORDER',
	payTypeKey: 'PAYPAL_CREDIT',
	requestTime: '2023-10-27 10:28:08'
};
const onApproveParams = {
	billingToken: null,
	facilitatorAccessToken:
		'A21AAIl_dL_tRa-b3_ipUidHbFFNwpQHmvFe-Q8GR1S6AvMHtTJ4jCqGBDNwQBozRcLfW_aBIjD23l0EmsPFe4ItWs1oo55Ug',
	orderID: '9X286780R0879452N',
	payerID: 'XPXKAV4L4S8P2',
	paymentID: '9X286780R0879452N',
	paymentSource: 'paypal'
};

interface ApproveProps {
	data: OnApproveData;
	memberShip?: ManualWalletRechargeMembershipVO;
	type?: PayContent;
}

async function onApprove({ type, data, memberShip }: ApproveProps) {
	try {
		if (type === PayContent.VIP) {
			await request.settlement.membership.orderCreatePaypalCreditCapture({
				...memberShip,
				creditCardOrderId: data.orderID
			});
			await request.settlement.wallet.orderPaypalPaymentTokenSave({
				isDefault: false
			} as any);
		}
		if (type === PayContent.Recharge) {
			await request.settlement.wallet.orderCapturePaypalCredit({
				authorizationId: '',
				creditPaymentOption: true,
				payPalOrderId: data.orderID,
				needValid: true
			});
			await request.settlement.wallet.orderPaypalPaymentTokenSave({
				isDefault: false
			} as any);
		}
	} catch (error) {
		throw new Error(window._$m.t('错误'));
	}
}
export interface SubmitPaymentProps {
	payPalSubscriptionId?: string;
	onCaptrueEnd?: (err?: any) => void;
	memberShip?: ManualWalletRechargeMembershipVO;
	type?: PayContent;
	getPrice?: () => number | boolean;
}
const SubmitPayment = (props: SubmitPaymentProps) => {
	const { onCaptrueEnd, memberShip, type } = props;
	const hostedField = usePayPalHostedFields();
	const [loadingType, setPaypalLoadingType] =
		useState<ENUM_PAYPAL_LOADING_TYPE>(ENUM_PAYPAL_LOADING_TYPE.NO_LOADING);
	const isLoading = loadingType !== ENUM_PAYPAL_LOADING_TYPE.NO_LOADING;
	const handleClick = async () => {
		if (props.getPrice && props.getPrice()) {
			try {
				setPaypalLoadingType(ENUM_PAYPAL_LOADING_TYPE.PAYPAL_PAY_ING);
				if (!hostedField?.cardFields) {
					const childErrorMessage =
						'Unable to find any child components in the <PayPalHostedFieldsProvider />';
					throw new Error(childErrorMessage);
				}
				const isFormInvalid = Object.values(
					hostedField.cardFields.getState().fields
				).some((field) => !field.isValid);
				if (isFormInvalid) {
					setPaypalLoadingType(ENUM_PAYPAL_LOADING_TYPE.NO_LOADING);
					message.info(window._$m.t('请填写正确的信用卡信息'));
					return;
				}
				const hostSubmitRes = await hostedField.cardFields.submit();
				console.log(123123, hostSubmitRes, 'hostSubmitRes');
				/** 回调后端接口捕获 */
				await onApprove({
					data: {
						orderID: hostSubmitRes.orderId
					},
					memberShip,
					type
				} as any);
				setPaypalLoadingType(ENUM_PAYPAL_LOADING_TYPE.NO_LOADING);
				onCaptrueEnd?.();
			} catch (e: any) {
				setPaypalLoadingType(ENUM_PAYPAL_LOADING_TYPE.NO_LOADING);
				onCaptrueEnd?.(e);
			}
		}
	};

	return (
		<div>
			<PaypalLoading visible={isLoading} />
			<button
				className={isLoading ? '' : 'btn-primary'}
				style={{
					width: '96%',
					position: 'absolute',
					left: '2%',
					bottom: '4%',
					borderRadius: '22px',
					height: '44px',
					color: '#FFF',
					background: '#0070BA',
					border: 'none'
				}}
				onClick={isLoading ? undefined : handleClick}
			>
				{window._$m.t('确认')}
			</button>
		</div>
	);
};
export enum ENUM_PAYPAL_LOADING_TYPE {
	/** 不展示 loading */
	NO_LOADING,
	/** 初始化 sniff 数据 */
	INIT_SNIFF_DATA,
	/** paypal 加载SDK 中 */
	PAYPAL_INIT_SDK,
	/** paypal 支付中 */
	PAYPAL_PAY_ING
}
export interface PayPalCardPayProps {
	/** 订单金额 */
	amount?: number;
	onCaptrueEnd?: (error?: any) => void;
	/** 充值类型 */
	type: PayContent;
	// clibent 的 vipInfo
	memberShip?: ManualWalletRechargeMembershipVO;
	// client 的activeVip
	activeItem?: MembershipTemplatePrice;
}

const labelClass = 'mb-[4px] font-extrabold leading-[22px]';

interface Props {
	price?: number;
	type: PayContent;
	min?: number;
}
interface InputNumberProps {
	price?: number;
}
const InputNumberComp = forwardRef<InputNumberProps, Props>(
	(props: Props, ref) => {
		const { price, type, min } = props;
		const rate = rateInfo();
		// 支付金额
		const [sprice, setSPrice] = useState<number>(
			price && price > 1 ? price : 1
		);
		const formatMoneyCny = useRateFormatMoneyFloor(true);
		useImperativeHandle(ref, () => {
			return {
				price: sprice
			};
		});

		return (
			<>
				<div className="text-font-primarytext-base mt-[24px]">
					<label className={labelClass}>
						{type === PayContent.VIP
							? window._$m.t('支付金额(USD)')
							: window._$m.t('汇款金额(USD)')}
					</label>
					<InputNumber
						min={price || 1}
						value={sprice}
						controls={false}
						precision={2}
						style={{
							width: '100%',
							paddingTop: '10px',
							paddingBottom: '10px',
							fontSize: '14px',
							marginTop: '4px',
							color: 'rgba(0, 0, 0, 0.88)'
						}}
						className={classNames({
							'border-none': type === PayContent.VIP
						})}
						disabled={type === PayContent.VIP}
						placeholder={window._$m.t('请输入汇款金额')}
						onChange={(e) => setSPrice(Number(e))}
					/>
				</div>
				{type === PayContent.Recharge && (
					<div className="text-font-Secondary font-bold mt-[4px]">
						{window._$m.t('预计到账≈')}
						{`( ${isNaN(sprice) ? '0' : formatMoneyCny(calRateToUs(sprice))})CNY`}
					</div>
				)}
			</>
		);
	}
);

const PayPalCardPay = (props: PayPalCardPayProps) => {
	const { onCaptrueEnd, memberShip, type, amount } = props;
	const { data } = useRequest(request.settlement.membership.orderGetClientId);
	const { data: paypalTokenData } = useRequest(
		request.settlement.wallet.orderPaypalGenerateToken
	);
	const clientId = data?.data;
	const dataClientToken = paypalTokenData?.data;
	const isSniffRequestEnd = Boolean(clientId && dataClientToken);
	const inputNum = useRef<InputNumberProps>(null);
	const createOrder = useEvent(async function (bizNo?: string) {
		if (type === PayContent.VIP) {
			const res =
				await request.settlement.membership.orderCreatePaypalCredit({
					...memberShip
				});
			return res.data?.orderId;
		}

		if (type === PayContent.Recharge) {
			const res: any = await request.settlement.wallet.orderCreatePaypal({
				originCurrencyCode: US_INFO.currency,
				originRechargeAmount: inputNum.current?.price
			});
			return res.data.payPalOrderId;
		}
	});

	const getPrice = () => {
		if (!inputNum.current?.price) {
			message.info(window._$m.t('请输入充值金额'));
			return false;
		}
		return inputNum.current?.price;
	};

	return (
		<div className="page-paypal-card-pay">
			{!isSniffRequestEnd && (
				<PaypalLoading
					description={window._$m.t('paypal 加载中')}
					visible={true}
				/>
			)}

			<div className="text-font-Secondary text-sm">
				{window._$m.t('您填写的信息受到加密保护，请安心填写')}
			</div>
			{!isSniffRequestEnd ? null : (
				<PayPalScriptProvider
					options={{
						clientId: clientId as any,
						dataClientToken,
						components: 'buttons,hosted-fields,funding-eligibility',
						intent: 'capture',
						currency: 'USD',
						vault: true,
						locale: 'en_US'
					}}
				>
					<PayPalHostedFieldsProvider
						createOrder={createOrder}
						styles={{
							'.valid': {
								color: '#28a745'
							},
							'.invalid': {
								color: '#dc3545'
							},
							input: {
								'font-family': 'Avenir',
								'font-size': '16px',
								border: '1px solid #D9D9D9'
							}
						}}
					>
						<div className="text-font-primary text-base mt-[24px]">
							<label className={labelClass} htmlFor="card-number">
								{window._$m.t('信用卡号码')}
							</label>
							<PayPalHostedField
								id="card-number"
								className="paypal-card-field"
								hostedFieldType="number"
								style={{
									height: '42px',
									paddingTop: '10px',
									paddingBottom: '10px',
									marginTop: '4px',
									fontSize: '14px',
									color: 'rgba(0, 0, 0, 0.88)'
								}}
								options={{
									selector: '#card-number',
									placeholder: window._$m.t('仅限数字')
								}}
							/>
						</div>
						<div className="text-font-primarytext-base mt-[24px]">
							<label
								className={labelClass}
								htmlFor="expiration-date"
							>
								{window._$m.t('到期日期')}
							</label>
							<PayPalHostedField
								id="expiration-date"
								className="paypal-card-field"
								hostedFieldType="expirationDate"
								style={{
									height: '42px',
									paddingTop: '10px',
									paddingBottom: '10px',
									marginTop: '4px',
									fontSize: '14px',
									color: 'rgba(0, 0, 0, 0.88)'
								}}
								options={{
									selector: '#expiration-date',
									placeholder:
										window._$m.t('仅限数字，例：05/2022')
								}}
							/>
						</div>
						<div className="text-font-primarytext-base mt-[24px]">
							<label className={labelClass} htmlFor="cvv">
								{window._$m.t('安全码')}
							</label>
							<PayPalHostedField
								id="cvv"
								className="paypal-card-field"
								hostedFieldType="cvv"
								style={{
									height: '42px',
									paddingTop: '10px',
									paddingBottom: '10px',
									marginTop: '4px',
									fontSize: '14px',
									color: 'rgba(0, 0, 0, 0.88)'
								}}
								options={{
									selector: '#cvv',
									placeholder:
										window._$m.t('仅限输入3-4位数字安全码'),
									maskInput: true
								}}
							/>
						</div>
						<InputNumberComp
							ref={inputNum}
							price={memberShip?.price || amount}
							type={type}
						/>

						<SubmitPayment
							getPrice={getPrice}
							type={type}
							onCaptrueEnd={onCaptrueEnd}
						/>
					</PayPalHostedFieldsProvider>
				</PayPalScriptProvider>
			)}
		</div>
	);
};
export default PayPalCardPay;
