import { useState } from 'react';
import Plusjia from '@/common/icons/Plusjia';
import Subtractjian from '@/common/icons/Subtractjian';

interface QsItemProps {
	label?: string;
	value?: string;
}
interface Props {
	list: QsItemProps[];
}

const QsItem = ({ label, value }: QsItemProps) => {
	const [active, setActive] = useState<boolean>(false);
	return (
		<div
			className="cursor-pointer flex py-[16px] text-left border-top border border-white-6 border-l-0 border-r-0 items-start"
			onClick={() => setActive(!active)}
		>
			<div className="cursor-pointer text-[28px] mr-[16px]">
				{active ? <Subtractjian /> : <Plusjia />}
			</div>
			<div className="flex-1 text-lg">
				<div className="text-white-0 font-bold leading-7">{label}</div>
				{active && <div className="text-white-65">{value}</div>}
			</div>
		</div>
	);
};

const FaqList = ({ list }: Props) => {
	return (
		<div>
			{list.map((item, index) => {
				return <QsItem {...item} key={index} />;
			})}
		</div>
	);
};
export default FaqList;
