import _ from 'lodash';
//
export function traverse<T extends Tree<unknown>>(
	list: T[],
	callback: (v: T) => void
) {
	list.forEach((v) => {
		callback(v);
		if (Array.isArray(v.children)) traverse(v.children as T[], callback);
	});
}

//
export function deepHas<T extends Tree<unknown>>(
	obj: T,
	value?: T,
	compare = (a, b) => a === b
) {
	let has = compare(obj, value);
	if (obj.children) {
		traverse(obj.children, (v: any) => {
			if (compare(v, value)) has = true;
		});
	}
	return has;
}

// 保证在主任务后执行
export async function next<T, K extends any[]>(
	callback: (...res: K) => T,
	...val: K
) {
	const res = await val;
	return callback(...res);
}

// 转成{ value, label }格式
export function valueLabel<T, U extends keyof T, V extends keyof T>(
	data: T | T[],
	valueName: U = 'id' as any,
	labelName: V = 'name' as any
) {
	const create = (data: T) => ({
		value: data[valueName],
		label: data[labelName],
		...data
	});
	return Array.isArray(data) ? data.map((v) => create(v)) : [create(data)];
}

// 浅copy
export function clone<T>(prev: T, next?: Partial<T>) {
	// const t = next ? _.merge({ ...prev }, next) : prev;
	// return _.clone(t) as T;
	// todo:数组合并
	return (Array.isArray(prev) ? [...prev] : { ...prev, ...next }) as T;
}

// flat
export function flatProp<T, K extends keyof T>(
	data: T[],
	prop: K,
	number: number = 1
) {
	return data.map((v) => v[prop]).flat(number) as T[K];
}

/** 异步返回结果 */
export function wait<T extends any[], K>(
	fn: (...r: T) => K,
	time?: number,
	...rest: T
) {
	const t = (...r: T) =>
		new Promise<K>((resolve) => {
			const t = () => resolve(fn(...r));
			time ? setTimeout(t, time) : next(t);
		});
	return t(...rest);
}

/** 执行机 */
export function reduceFn<Fn extends Function[]>(...fns: Fn) {
	return <T extends any[]>(...r: T) => {
		return fns.reduce((args, fn) => {
			return [fn(...args)] as any;
		}, r) as any;
	};
}

/**
 * 下载文件
 * @param res
 */
export const downloadFile = (data: BlobPart, fileName: string) => {
	const url = window.URL.createObjectURL(new Blob([data]));
	const a = document.createElement('a');
	a.href = url;
	a.setAttribute('download', fileName);
	document.body.appendChild(a);
	a.click();
	URL.revokeObjectURL(a.href);
	document.body.removeChild(a);
};
