export function createCache(options?: { expire?: number }) {
	const cache = new Map<string, { timer: any; value: any }>();
	const set = (key: string, value, expire?: number) => {
		const _expire = expire ?? options?.expire;
		const timer = cache.get(key)?.timer;
		// 清除上一次
		if (timer) clearTimeout(timer);
		const newVal = {
			timer: _expire ? setTimeout(cache.delete, _expire, key) : null,
			value
		};
		cache.set(key, newVal);
	};
	const get = (key) => cache.get(key)?.value;
	return {
		has: cache.has.bind(cache),
		get,
		set
	};
}
