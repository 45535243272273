import { subscribe } from '@sniff/routeguard';
import { useMount } from 'ahooks';
import { next, wait } from '@/utils/base';

type Request<T = void> = <P = any>(params?: P) => Promise<T>;

type Curd<T> = {
	(): T;
	get: Request<T>;
	set: Request;
	update: Request;
	clearCache: () => void;
	getState: () => T;
	setState: (nextState: Result<T, T>) => void;
};

type Actions<T> = {
	[key: string]: (data: Curd<T> & Dispatchs<Actions<T>>, payload?) => unknown;
};

type Dispatchs<A> = {
	[K in keyof A]: A[K] extends (...r: infer P) => infer R
		? (payload?: P[1]) => R
		: never;
};

type Requests<T, A = {}> = {
	get: Request<T>;
	set?: Request;
} & A;

const collect = <T extends any[], K>(fn: (...r: T) => K, hold?: number) => {
	let count = 0;
	return (...r: T) => {
		const cur = ++count;
		const run = () => {
			if (cur === count) {
				return fn(...r);
			}
		};
		return wait(run, hold);
	};
};

const curdData = <T>(
	requests: Requests<T>,
	options: {
		defaultValue?: any;
		immediate?: any;
		noCache?: boolean;
		manual?: boolean;
	} = {}
) => {
	const { get, set } = requests;
	const { manual, immediate, noCache, defaultValue } = options;
	const data = subscribe<T>(defaultValue);
	const ref = { value: defaultValue, loaded: null as any };
	//
	const clearCache = () => {
		ref.value = data.getState();
	};
	// 一个js任务中收集调用并作为一次get请求
	const refresh = collect(async (param?) => {
		const res = await get(param);
		data.setState(res);
		return res;
	});
	//
	const update = async (param?, param2?) => {
		await set?.(param);
		// 需要考虑后端接口延迟
		await refresh(param2);
	};
	if (immediate) {
		ref.loaded = next(async () => {
			await refresh(immediate);
			ref.loaded = null;
		});
	}
	// 缓存逻辑，是否每次mount都重新获取
	const useFetch = (param?) => {
		useMount(async () => {
			//
			if (manual) return;
			// 如果是immediate,等待完成
			if (ref.loaded) return;
			//
			if (noCache) {
				refresh(param);
			} else {
				// 如果等于defaultValue,默认认为是还未请求数据
				if (data.getState() === ref.value) {
					// todo:是否需要判断undefined
					refresh(param);
				}
			}
		});
		return data();
	};
	return Object.assign(useFetch, {
		...data,
		get: refresh,
		set,
		update,
		clearCache
	});
};

const asyncData = <T, A extends Actions<T>>(
	requests: Requests<T, A> | Request<T>,
	options: {
		defaultValue?: any;
		immediate?: any;
		noCache?: boolean;
		manual?: boolean;
	} = {}
) => {
	const { get, set, ...actions } =
		typeof requests === 'function'
			? { get: requests, set: async () => {} }
			: requests;
	//
	const output = curdData({ get, set }, options);
	//
	const bindActions = {};
	if (actions) {
		Object.entries(actions).forEach(([k, fn]) => {
			bindActions[k] = (payload) => fn(output, payload);
		});
	}
	return Object.assign(output, bindActions as Dispatchs<A>);
};

// todo: 暴露defaultValue
// todo: computed
// todo: get/set 异常捕获,get时的loading状态
// todo: watch: 监听依赖,如user变化
// todo: update方法不方便定位到引用

export { asyncData };
