import React, { useEffect, useRef } from 'react';
import styles from './style.css';
interface IconProps extends React.SVGProps<SVGSVGElement> {
    size?: string | number;
    width?: string | number;
    height?: string | number;
    spin?: boolean;
    rtl?: boolean;
    color?: string;
    fill?: string;
    stroke?: string;
}

export default function OrderdingdanCo0i52dh(props: IconProps) {
    const root = useRef<SVGSVGElement>(null)
    const { size = '1em', width, height, spin, rtl, color, fill, stroke, className, ...rest } = props;
    const _width = width || size;
    const _height = height || size;
    const _stroke = stroke || color;
    const _fill = fill || color;
    useEffect(() => {
      if (!_fill) {
        (root.current as SVGSVGElement)?.querySelectorAll('[data-follow-fill]').forEach(item => {
          item.setAttribute('fill', item.getAttribute('data-follow-fill') || '')
        })
      }
      if (!_stroke) {
        (root.current as SVGSVGElement)?.querySelectorAll('[data-follow-stroke]').forEach(item => {
          item.setAttribute('stroke', item.getAttribute('data-follow-stroke') || '')
        })
      }
    }, [stroke, color, fill])
    return (
        <svg
          ref={root}
          width={_width} 
          height={_height}
          viewBox="0 0 16 16"
          preserveAspectRatio="xMidYMid meet"
          fill="none"
          role="presentation"
          xmlns="http://www.w3.org/2000/svg"
          className={`${className || ''} ${spin ? styles.spin : ''} ${rtl ? styles.rtl : ''}`.trim()}
          {...rest}
        >
          <g><path d="M8 .667a3.321 3.321 0 0 0-3.045 2H4a2 2 0 0 0-2 2v8.667a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.667a2 2 0 0 0-2-2h-.955A3.321 3.321 0 0 0 8 .667Zm.667 3.327a.667.667 0 0 0-1.333 0V4a.667.667 0 0 0 1.333 0v-.006Zm-4.001 7.34c0-.368.298-.667.667-.667h5.333a.667.667 0 0 1 0 1.333H5.333a.667.667 0 0 1-.667-.666Zm.667-4.667a.667.667 0 0 0 0 1.333h5.333a.667.667 0 1 0 0-1.333H5.333Z" clipRule="evenodd" fillRule="evenodd" data-follow-fill="#199A97" fill={_fill}/></g>
        </svg>
    )
}
