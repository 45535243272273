/*
 * @Date: 2024-04-16 11:38:47
 * @LastEditors: lihwang_wf@126.com
 * @LastEditTime: 2024-04-18 13:36:32
 * @FilePath: /seller-station-client/src/config/router/biz/login.tsx
 * @Description:
 */
import { Route } from '@sniff/routeguard';
import { lazy } from 'react';

const login: Route = {
	path: 'account',
	id: 'account_layout',
	component: lazy(() => import('@/container/account/_layout')),
	meta: {
		authentication: false
	},
	children: [
		{
			path: 'signin',
			id: 'login_index',
			meta: {
				title: window._$m.t('登录')
			},
			component: lazy(() => import('@/container/account/Login/Index'))
		},
		{
			path: 'register',
			id: 'regist_index',
			meta: {
				title: window._$m.t('注册')
			},
			component: lazy(() => import('@/container/account/Register/Index'))
		},
		{
			path: 'forget',
			id: 'forget_index',
			component: lazy(() => import('@/container/account/Forget/Index')),
			meta: {
				title: window._$m.t('忘记密码')
			}
		},
		{
			path: 'auth',
			id: 'auth_index',
			component: lazy(() => import('@/container/account/Auth/Index')),
			meta: {
				title: window._$m.t('授权页')
			}
		}
	]
};

export default login;
