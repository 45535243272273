/*
 * @Author: liuliangliang liuliangliang@sniffgroup.com
 * @Date: 2024-04-17 19:57:15
 * @LastEditors: liuliangliang liuliangliang@sniffgroup.com
 * @LastEditTime: 2024-04-18 11:50:05
 * @FilePath: /seller-station-client/src/common/layout/header/components/exchangePopover/exchangePopover.tsx
 * @Description: 充值弹窗
 */
import './exchangePopover.css';
import { Popover } from 'antd';
import { useState } from 'react';
import { rateInfo } from '@/atom/rate';
import Downxia from '@/common/icons/Downxia';
import Meiguo from '@/common/icons/Meiguo';

function getUserTimeZone() {
	return Intl.DateTimeFormat().resolvedOptions().timeZone;
}

const ExchangePopover = () => {
	return (
		<div className="exchangePopover w-[280px] h-[196px] p-[20px]">
			<div className="flex justify-between mb-[16px]">
				<span>Ship to</span>
				<div className="flex items-center">
					<Meiguo style={{ width: 24, height: 16, marginRight: 4 }} />
					United States
				</div>
			</div>
			<div className="flex justify-between mb-[16px]">
				<span>Language</span>
				<div className="flex items-center">English</div>
			</div>
			<div className="flex justify-between mb-[16px]">
				<span>Time zone</span>
				{/* <div className="flex items-center">Los Angeles ( GMT-7)</div> */}
				<div className="flex items-center">{getUserTimeZone()}</div>
			</div>
			<div className="flex justify-between mb-[16px] relative">
				<span>Currency</span>
				<div className="flex items-center">USD ( US Dollar)</div>
				<div className="exchangeInfo">
					$1.00 = CNY{rateInfo.calc({ usd: 1 })}
				</div>
			</div>
		</div>
	);
};

export default function ({ setMaskVisible }) {
	const [exchangeVisible, setExchangeVisible] = useState(false);
	return (
		<Popover
			content={<ExchangePopover />}
			trigger="hover"
			placement="bottomRight"
			onOpenChange={(boo) => {
				setExchangeVisible(boo);
				setMaskVisible(boo);
			}}
			overlayClassName="siteHeaderPopover"
			arrow={false}
		>
			<div className="siteHeaderExchange cursor-pointer">
				<Meiguo style={{ width: 24, height: 16 }} />
				<div style={{ textAlign: 'left', marginLeft: 8 }}>
					<div style={{ fontWeight: 400 }}>US</div>

					<div
						style={{ fontWeight: 900 }}
						className="flex items-center"
					>
						USD
						<Downxia
							fill="white"
							className="transition"
							style={{
								transform: `rotate(${exchangeVisible ? 180 : 0}deg)`
							}}
						/>
					</div>
				</div>
			</div>
		</Popover>
	);
}
