import React, { useEffect, useRef } from 'react';
import styles from './style.css';
interface IconProps extends React.SVGProps<SVGSVGElement> {
	size?: string | number;
	width?: string | number;
	height?: string | number;
	spin?: boolean;
	rtl?: boolean;
	color?: string;
	fill?: string;
	stroke?: string;
}

export default function VipOnevip(props: IconProps) {
	const root = useRef<SVGSVGElement>(null);
	const {
		size = '1em',
		width,
		height,
		spin,
		rtl,
		color,
		fill,
		stroke,
		className,
		...rest
	} = props;
	const _width = width || size;
	const _height = height || size;
	const _stroke = stroke || color;
	const _fill = fill || color;
	useEffect(() => {
		if (!_fill) {
			(root.current as SVGSVGElement)
				?.querySelectorAll('[data-follow-fill]')
				.forEach((item) => {
					item.setAttribute(
						'fill',
						item.getAttribute('data-follow-fill') || ''
					);
				});
		}
		if (!_stroke) {
			(root.current as SVGSVGElement)
				?.querySelectorAll('[data-follow-stroke]')
				.forEach((item) => {
					item.setAttribute(
						'stroke',
						item.getAttribute('data-follow-stroke') || ''
					);
				});
		}
	}, [stroke, color, fill]);
	return (
		<svg
			ref={root}
			width={_width}
			height={_height}
			viewBox="0 0 20 20"
			preserveAspectRatio="xMidYMid meet"
			fill="none"
			role="presentation"
			xmlns="http://www.w3.org/2000/svg"
			className={`${className || ''} ${spin ? styles.spin : ''} ${rtl ? styles.rtl : ''}`.trim()}
			{...rest}
		>
			<g>
				<path
					d="M5.214 3.197 1.876 6.82a.77.77 0 0 0 .014 1.07l7.797 8.838a.417.417 0 0 0 .625 0l7.796-8.838a.77.77 0 0 0 .015-1.071l-3.339-3.623a.888.888 0 0 0-.652-.28H5.867a.888.888 0 0 0-.653.28ZM7.29 7.779a.833.833 0 0 0-1.246 1.108l3.334 3.75a.833.833 0 0 0 1.245 0l3.334-3.75a.833.833 0 1 0-1.246-1.108L10 10.83 7.289 7.78Z"
					clipRule="evenodd"
					fillRule="evenodd"
					data-follow-fill="#FFD666"
					fill={_fill}
				/>
			</g>
		</svg>
	);
}
