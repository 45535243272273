import { ConfigProvider, Divider, Modal, Radio, message } from 'antd';
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { useAsyncEffect } from 'ahooks';
import classNames from 'classnames';
import AddOnetianjia from '@/common/icons/AddOnetianjia';
import { PayContent, PayType } from '@/const/enum';
import {
	ManualWalletRechargeMembershipVO,
	MembershipTemplatePrice,
	PaymentTokenDTO
} from '@/service/settlement';
import { request } from '@/config/request';
import DeleteOneshanchu from '@/common/icons/DeleteOneshanchu';
import CheckOnexiaoyan from '@/common/icons/CheckOnexiaoyan';
import PayPalCardPayModule from '@/common/payment/PayPalCardPay';
const PayCardList = Array.from(
	{ length: 7 },
	(i, idx) =>
		`https://static-us.theckb.com/static-asset/icon/bank_card${idx + 1}.png`
);

interface CardSelectProps {
	memberShip?: ManualWalletRechargeMembershipVO;
	activeItem?: MembershipTemplatePrice;
	type: PayContent;
	amount?: number;
	onSuccess?: () => void;
}

export interface RefCardSelectProps {
	getSelectedCard: () => PaymentTokenDTO | undefined;
}

const CardSelect = forwardRef<RefCardSelectProps, CardSelectProps>(
	(props, ref) => {
		// 默认支付卡片选择
		const [selectedCard, setSelectedCard] = useState<PaymentTokenDTO>();
		// 银行卡列表
		const [cardList, setCardList] = useState<PaymentTokenDTO[]>();
		// 选中的银行卡
		const [paySelectCard, setPaySelectCard] = useState<PaymentTokenDTO>();
		/** 获取卡片列表 */
		const getCardList = async () => {
			const res =
				await request.settlement.wallet.orderPaypalPaymentTokenAcquire();
			if (res.data) {
				setCardList(res.data.paymentTokens || []);
				const card = res.data.paymentTokens?.find((i) => i.isDefault);
				setSelectedCard(card);
				setPaySelectCard(card);
			}
		};

		useImperativeHandle(ref, () => {
			return {
				getSelectedCard: () => paySelectCard
			};
		});

		useEffect(() => {
			getCardList();
		}, []);
		/** 删除卡片 */
		const delCard = (
			card: PaymentTokenDTO,
			e: React.MouseEvent<SVGSVGElement, MouseEvent>
		) => {
			e.preventDefault();
			Modal.confirm({
				title: window._$m.t('提示'),
				content: window._$m.t('是否删除信用卡？'),
				onOk: async () => {
					const res =
						await request.settlement.wallet.orderPaypalPaymentTokenDelete(
							{
								creditCardId: card.creditCardId
							},
							{ postParams: true }
						);
					if (res.success) {
						message.success(window._$m.t('删除成功'));
						getCardList();
					}
				}
			});
		};
		// 切换卡片(默认卡片设置)
		const changeCard = async (
			card: PaymentTokenDTO,
			e: React.MouseEvent<HTMLElement, MouseEvent>
		) => {
			e.stopPropagation();
			e.preventDefault();
			const res =
				await request.settlement.wallet.orderPaypalPaymentTokenDefault(
					{
						creditCardId: card.creditCardId
					},
					{
						postParams: true
					}
				);
			if (res.success) {
				message.success(window._$m.t('操作成功'));
				setSelectedCard(card);
			}
		};

		const addCard = () => {
			const instance = Modal.confirm({
				footer: null,
				icon: null,
				width: 480,
				closable: true,
				title: (
					<div className="flex items-baseline">
						<span className="text-black-950 font-bold text-h4 mr-[16px]">
							{window._$m.t('添加信用卡')}
						</span>
						<img
							className="h-[22px] mr-[8px]"
							src="https://static-us.theckb.com/static-asset/icon/paypal_logo.png"
							alt=""
						/>

						<span className="text-font-regular text-base">
							{window._$m.t('提供服务')}
						</span>
					</div>
				),

				content: (
					<div className="h-[520px]">
						<PayPalCardPayModule
							{...props}
							onCaptrueEnd={(err) => {
								instance.destroy();
								if (!err) {
									if (props.type === PayContent.VIP)
										message.success(
											window._$m.t('开通会员成功')
										);

									if (props.type === PayContent.Recharge)
										message.success(
											window._$m.t('充值成功')
										);
									getCardList();
									props.onSuccess && props.onSuccess();
								}
							}}
						/>
					</div>
				),

				onCancel: () => {}
			});
		};
		return (
			<div className="mt-[16px]">
				<ConfigProvider
					theme={{
						token: {
							colorPrimary: '#199A97'
						}
					}}
				>
					<Radio.Group
						value={selectedCard?.creditCardId}
						className="flex flex-wrap"
					>
						{cardList?.map((card, idx) => {
							return (
								<div
									key={card.creditCardId}
									className={classNames(
										'cursor-pointer overflow-hidden  border border-grey-4 rounded-[16px] mb-[16px]  w-[310px] py-[24px] bg-grey-10',
										{
											'shadow-[0px_0px_0px_3px_#E5FDFC] border-cyan-9 relative':
												paySelectCard?.creditCardId ===
												card.creditCardId
										},
										(idx + 1) % 3 === 0
											? 'mr-0'
											: 'mr-[10px]'
									)}
									onClick={(e) => {
										e.preventDefault();
										setPaySelectCard(card);
									}}
								>
									{paySelectCard?.creditCardId ===
										card.creditCardId && (
										<div className="absolute right-0 top-0">
											<img
												className="h-[32px]"
												src="https://static-us.theckb.com/static-asset/icon/icon_card_selected.png"
												alt=""
											/>
										</div>
									)}

									<div className="px-[16px]">
										<div className="font-bold text-black-0 text-lg">
											************
											{card.lastDigits}
										</div>
										<div className="mt-[16px] opacity-45 text-black-0 text-sm">
											<span className="mr-[3px]">
												{window._$m.t('有効期限')}：
											</span>
											{card.expiry}
										</div>
									</div>
									<Divider className="bg-black-0 opacity-5" />
									<div
										className="px-[16px] flex justify-between"
										onClick={(e) => changeCard(card, e)}
									>
										<Radio
											className="flex items-center"
											value={card.creditCardId}
										>
											<span className="opacity-85 text-black-0">
												Default payment method
											</span>
										</Radio>
										<DeleteOneshanchu
											color="#fff"
											className="text-[28px] px-[5px] cursor-pointer"
											onClick={(e) => delCard(card, e)}
										/>
									</div>
								</div>
							);
						})}
						<div
							onClick={() => addCard()}
							className="mb-[16px]  flex cursor-pointer flex-col justify-center items-center rounded-[16px] w-[310px] p-[24px] border border-grey-4 text-font-Secondary text-lg"
						>
							<AddOnetianjia />
							<div className="mt-[8px]">
								{window._$m.t('添加信用卡')}
							</div>
							<div className="flex mt-[10px]">
								{PayCardList.map((i) => (
									<img
										className="h-[24px]"
										key={i}
										src={i}
										alt=""
									/>
								))}
							</div>
						</div>
					</Radio.Group>
				</ConfigProvider>
			</div>
		);
	}
);

export default CardSelect;
