/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

/** AbnormalFlowInfoDTO */
export interface AbnormalFlowInfoDTO {
	/** @format int32 */
	abnormalNum?: number;
	flowCode?: string;
}

/** AddBoxTemplateReqDTO */
export interface AddBoxTemplateReqDTO {
	/**
	 * 高
	 * @format int32
	 */
	height: number;
	/**
	 * 长
	 * @format int32
	 */
	length: number;
	/** 备注 */
	remark?: string;
	/**
	 * 库存数量
	 * @format int32
	 */
	stock?: number;
	/** 模板名称 */
	templateName: string;
	/**
	 * 宽
	 * @format int32
	 */
	width: number;
}

/** AddDomesticDeliveryDTO */
export interface AddDomesticDeliveryDTO {
	/**
	 * 采购异常明细ID
	 * @format int64
	 */
	abnormalOrderDealId?: number;
	/** 详细地址 */
	address?: string;
	/** 区/县 */
	area?: string;
	/** 市 */
	city?: string;
	/**
	 * 发货数量
	 * @format int32
	 */
	deliveryNum?: number;
	/** 发货任务编号 */
	deliveryTaskCode?: string;
	/** 流程编码 */
	flowCode?: string;
	/** 电话号 */
	mobile?: string;
	/** 订单编号 */
	orderCode?: string;
	/** 原详细地址 */
	originalAddress?: string;
	/** 原电话号 */
	originalMobile?: string;
	/** 原收货人 */
	originalReceiver?: string;
	/** 批次包编号 */
	pkgCode?: string;
	/** 商品图片url链接 */
	productImgUrl?: string;
	/** 商品名称 */
	productName?: string;
	/** 省 */
	province?: string;
	/** 采购单异常编号 */
	purchaseAbnormalNo?: string;
	/** 采购单编号 */
	purchaseNo?: string;
	/** 收货人 */
	receiver?: string;
	/**
	 * 退货截止时间
	 * @format date-time
	 */
	returnDeadlineTime?: string;
	/** 仓库 */
	warehouse?: string;
}

/** AddLabelTemplateReqDTO */
export interface AddLabelTemplateReqDTO {
	/** 标签名称 */
	label?: string;
	/** 模板样式HTML */
	labelHtml?: string;
	/** 模板参数JSON */
	labelInfo?: string;
	/**
	 * 标签类型：1-标签 2-批次包
	 * @format int32
	 */
	type?: number;
}

/** AddTransferDTO */
export interface AddTransferDTO {
	/** @format int64 */
	customerShopId?: number;
	customerShopName?: string;
	inboundWarehouseCode?: string;
	outboundWarehouseCode?: string;
}

/** AddWeightCostConfigReqDTO */
export interface AddWeightCostConfigReqDTO {
	/**
	 * 类型：1-容器，2-箱子
	 * @format int32
	 */
	boxType: number;
	/**
	 * 发货类型:0-FBA发货，1-大件普通发货，2-小件普通发货
	 * @format int32
	 */
	deliverType: number;
	/** 物流公司code（OCS、YD、TH、SKB） */
	logisticsCompanyCode?: string;
	/** 美元限制 */
	priceLimit: number;
	/**
	 * 系统来源: 1-D2C; 2-B2B
	 * @format int32
	 */
	systemSource: number;
	/** 重量限制 */
	weightLimit: number;
}

/** AdditionSettlementDTO */
export interface AdditionSettlementDTO {
	/** @format int32 */
	additionConfigId?: number;
	/** @format int32 */
	additionId?: number;
	additionName?: string;
	additionNameJp?: string;
	bizId?: string;
	/** @format int32 */
	calculateConfigId?: number;
	changeAmount?: number;
	walletProductDTOList?: WalletProductDTO[];
}

/** AdditionalInfoRespDTO */
export interface AdditionalInfoRespDTO {
	/** @format int32 */
	additionCode?: number;
	/**
	 * 附加项配置ID
	 * @format int32
	 */
	additionConfigId?: number;
	/** 附加项拓展名称 */
	additionExtraName?: string;
	/**
	 * 附加项id
	 * @format int32
	 */
	additionId?: number;
	/** 附加项中文名称 */
	additionName?: string;
	/** 附加项日文名称 */
	additionNameJp?: string;
	/** 辅料图片 */
	ingredientsImg?: string;
	/** 辅料名称 */
	ingredientsName?: string;
	/** 辅料名称-日文 */
	ingredientsNameJp?: string;
	/** 辅料sku */
	ingredientsSku?: string;
	/**
	 * 抽检标志
	 * @format int32
	 */
	spotCheckFlag?: number;
	/** 抽检比例 */
	spotCheckRatio?: number;
	/** 系统订单编号 */
	systemOrderNo?: string;
}

/** AdjustPkgInventoryReqDto */
export interface AdjustPkgInventoryReqDto {
	/** 关联工单编号 */
	bizNo?: string;
	/** 批次包编码 */
	pkgCode?: string;
	/** 调整原因 */
	reason?: string;
	/**
	 * 原可拣货数量
	 * @format int32
	 */
	sourceEnablePickNums?: number;
	/**
	 * 调整后可拣货数量
	 * @format int32
	 */
	targetEnablePickNums?: number;
}

/** AllotContainerBindResp */
export interface AllotContainerBindResp {
	/** 拣货批次编码 */
	pickTaskCode?: string;
}

/** AppVersionReq */
export interface AppVersionReq {
	/** 仓库编码 */
	warehouse?: string;
}

/** AppVersionResp */
export interface AppVersionResp {
	/** 应用URL */
	appUrl?: string;
	/**
	 * 应用版本号
	 * @format int32
	 */
	appVersion?: number;
	/**
	 * 是否强制刷新 0 否 1 是
	 * @format int32
	 */
	forceFlush?: number;
	/** 仓库编码 */
	warehouse?: string;
}

/** AvailableStockRespDTO */
export interface AvailableStockRespDTO {
	/** @format int32 */
	checkedQuantity?: number;
	/** @format int32 */
	checkingQuantity?: number;
	shopProductSku?: string;
}

/** B2bDeliverySpotCheckNumsDTO */
export interface B2BDeliverySpotCheckNumsDTO {
	deliveryTaskCode?: string;
	shopProductSku?: string;
	/** @format int32 */
	spotCheckNum?: number;
}

/** BadPendingReqDTO */
export interface BadPendingReqDTO {
	/** @format int32 */
	orderProcessResult?: number;
	/** @format int32 */
	pageNum?: number;
	/** @format int32 */
	pageSize?: number;
	platformOrderNo?: string;
	shopProductSku?: string;
	sorts?: ISortFieldEnum[];
}

/** BadPendingRespDTO */
export interface BadPendingRespDTO {
	/** @format int32 */
	defectiveTotal?: number;
	productImg?: string;
	productName?: string;
	productSku?: string;
	regularizationPage?: PageRegularizationRespDTO;
}

/** BaseBatchDeliverTaskReqDTO */
export interface BaseBatchDeliverTaskReqDTO {
	/**
	 * 发货任务编号集合
	 * @uniqueItems true
	 */
	deliveryTaskCodeList?: string[];
}

/** BaseDeliverTaskApiReqDTO */
export interface BaseDeliverTaskApiReqDTO {
	deliveryTaskCode?: string;
	/** @format int32 */
	trayNums?: number;
}

/** BaseDeliverTaskReqDTO */
export interface BaseDeliverTaskReqDTO {
	/** 发货任务编号 */
	deliveryTaskCode: string;
	/** 平台订单号 */
	platformOrderNo?: string;
	/**
	 * 托盘数
	 * @format int32
	 */
	trayNums?: number;
}

/** BaseDeliverTaskRequest */
export interface BaseDeliverTaskRequest {
	/** 发货任务编号 */
	deliveryTaskCode: string;
}

/** BaseDeliveryBoxReqDTO */
export interface BaseDeliveryBoxReqDTO {
	/** 装箱编号 */
	deliveryBoxCode?: string;
}

/** BasketPackFinishDTO */
export interface BasketPackFinishDTO {
	/** 分拣框编码 */
	basketCode?: string;
	/** 组合任务编号 */
	packCode?: string;
	/** 体积：（XS、S、M、L、XL） */
	volume?: string;
}

/** BasketReqDTO */
export interface BasketReqDTO {
	/** 分拣蓝编码 */
	basketCode?: string;
	/**
	 * 业务主键
	 * @format int64
	 */
	basketId?: number;
	/**
	 * 分拣蓝类型(0:发货-分拣 1:组合任务-分拣)
	 * @format int32
	 */
	basketType?: number;
	/**
	 * 使用状态(0:未使用 1:使用中)
	 * @format int32
	 */
	useStatus?: number;
	/**
	 * 是否可用，0-不可用，1-可用
	 * @format int32
	 */
	useable?: number;
}

/** BasketRespDTO */
export interface BasketRespDTO {
	/**
	 * 分配状态(0:配货中 1:配货完成)
	 * @format int32
	 */
	allotStatus?: number;
	/** 分拣蓝编码 */
	basketCode?: string;
	/**
	 * 业务主键
	 * @format int64
	 */
	basketId?: number;
	/**
	 * 分拣蓝类型(0:发货-分拣 1:组合任务-分拣)
	 * @format int32
	 */
	basketType?: number;
	/**
	 * 创建时间
	 * @format date-time
	 */
	createTime?: string;
	/**
	 * 创建人
	 * @format int64
	 */
	creater?: number;
	/** 创建人昵称 */
	createrName?: string;
	/** 发货任务编号 */
	deliveryTaskCode?: string;
	/**
	 * 最新修改时间
	 * @format date-time
	 */
	lmt?: string;
	/**
	 * 修改人
	 * @format int64
	 */
	modifier?: number;
	/** 批次包编码 */
	pkgCodeList?: string[];
	/**
	 * 使用状态(0:未使用 1:使用中)
	 * @format int32
	 */
	useStatus?: number;
	/**
	 * 是否可用，0-不可用，1-可用
	 * @format int32
	 */
	useable?: number;
	/** 仓库编号 */
	warehouse?: string;
}

/** BasketVO */
export interface BasketVO {
	basketCode?: string;
	/** @format int64 */
	basketId?: number;
	/** @format int32 */
	basketType?: number;
	/** @format int32 */
	beactive?: number;
	/** @format int32 */
	combinableStatus?: number;
	/** @format date-time */
	createTime?: string;
	/** @format int64 */
	creater?: number;
	createrName?: string;
	/** @format date-time */
	lmt?: string;
	/** @format int64 */
	modifier?: number;
	modifierName?: string;
	/** @format int32 */
	useStatus?: number;
	/** @format int32 */
	useable?: number;
	warehouse?: string;
}

/** BatchAddBasketReqDTO */
export interface BatchAddBasketReqDTO {
	/**
	 * 分拣蓝类型(0:发货-分拣 1:组合任务-分拣 2:国内发货)
	 * @format int32
	 */
	basketType?: number;
	/**
	 * 数量
	 * @format int32
	 */
	num?: number;
	/** 前缀 */
	prefix?: string;
}

/** BatchDeliveryDeductionDto */
export interface BatchDeliveryDeductionDto {
	/** @format int64 */
	customerShopId?: number;
	/** @format int32 */
	deductionType?: number;
	deliveryTaskCodeList?: string[];
	pkgCodeList?: string[];
	warehouse?: string;
}

/** BatchEditAuditInfoReqDTO */
export interface BatchEditAuditInfoReqDTO {
	auditReasonCode?: string;
	auditReviewVersionList?: BatchEditAuditReviewVersion[];
	/** @format int64 */
	intForwarderConfigId?: number;
	submit?: boolean;
}

/** BatchEditAuditReviewVersion */
export interface BatchEditAuditReviewVersion {
	deliveryReviewCode?: string;
	/** @format int32 */
	version?: number;
}

/** BatchEditLogisticsNoReqDTO */
export interface BatchEditLogisticsNoReqDTO {
	/** 发货单集合 */
	deliveryTaskList?: EditLogisticsNoDTO[];
}

/** BatchGetLogisticsTrackRespDto */
export interface BatchGetLogisticsTrackRespDto {
	logisticsStatus?: string;
	trackingNumber?: string;
}

/** BatchReviewReqDTO */
export interface BatchReviewReqDTO {
	/** 审核日本配送公司名称（客户） */
	auditDistribution?: string;
	/** 审核中国货代公司名称 */
	auditForwarder?: string;
	/**
	 * 审核中国货代公司id
	 * @format int64
	 */
	auditIntForwarderConfigId?: number;
	/**
	 * 审核日本配送公司id（客户）
	 * @format int64
	 */
	auditIntShipConfigId?: number;
	/** 审核原因 */
	auditReason?: string;
	/** 审核原因编码 */
	auditReasonCode?: string;
	/** 审核原因(日文) */
	auditReasonJp?: string;
	/** 审核备注 */
	auditRemark?: string;
	/** 审核运输方式 */
	auditShippingType?: string;
	/** 发货审单编号 */
	deliveryReviewCode?: string;
	/**
	 * 版本号
	 * @format int32
	 */
	version?: number;
}

/** BatchUpdateVolumeReqDTO */
export interface BatchUpdateVolumeReqDTO {
	/** productSku */
	productSku?: string;
	/** 体积：（XS、S、M、L、XL） */
	volume?: string;
}

/** BecomeRegularReqDTO */
export interface BecomeRegularReqDTO {
	inboundOrderAdditionList?: InboundOrderAdditionReqDTO[];
	pkgCodes?: string[];
	regularOperationName?: string;
	settleAdditionAmount?: number;
}

/** BoxTemplateRespDTO */
export interface BoxTemplateRespDTO {
	/**
	 * 装箱模板ID
	 * @format int64
	 */
	boxTemplateId?: number;
	/**
	 * 创建时间
	 * @format date-time
	 */
	createTime?: string;
	/**
	 * 创建人
	 * @format int64
	 */
	creater?: number;
	/**
	 * 高
	 * @format int32
	 */
	height?: number;
	/**
	 * 长
	 * @format int32
	 */
	length?: number;
	/**
	 * 最新修改时间
	 * @format date-time
	 */
	lmt?: string;
	/**
	 * 修改人
	 * @format int64
	 */
	modifier?: number;
	/** 备注 */
	remark?: string;
	/**
	 * 模板状态(0:启用 1:禁用)
	 * @format int32
	 */
	status?: number;
	/** 模板编码 */
	templateCode?: string;
	/** 模板名称 */
	templateName?: string;
	/**
	 * 宽
	 * @format int32
	 */
	width?: number;
}

/** CheckPickRespDTO */
export interface CheckPickRespDTO {
	/** 分拣蓝编码，无需分拣时展示 */
	basketCode?: string;
	/**
	 * 是否需要分拣，0-否 1-是
	 * @format int32
	 */
	needPick?: number;
}

/** ClearStockPkgDto */
export interface ClearStockPkgDto {
	/** @format int32 */
	nums?: number;
	pkgCode?: string;
}

/** ClearStockReqDto */
export interface ClearStockReqDto {
	bizNo?: string;
	/** @format int64 */
	customerShopId?: number;
	nick?: string;
	productSku?: string;
	reason?: string;
	shopProductSku?: string;
}

/** ClearStockRespDto */
export interface ClearStockRespDto {
	pkgDtoList?: ClearStockPkgDto[];
	/** @format int32 */
	total?: number;
}

/** ClientForwarderReqDTO */
export interface ClientForwarderReqDTO {
	/** 发货任务编码 */
	deliveryTaskCode?: string;
	/**
	 * 发货类型(0:大件fba 1:大件普通 2:小件)
	 * @format int32
	 */
	deliveryType?: number;
	/**
	 * 处理状态(0:待处理 1:待确认 2:已处理 3：异常)
	 * @format int32
	 */
	processingStatus?: number;
}

/** ClientForwarderRespDTO */
export interface ClientForwarderRespDTO {
	/**
	 * 创建时间
	 * @format date-time
	 */
	createTime?: string;
	/**
	 * 当前审单总数
	 * @format int32
	 */
	currentReviewTotal?: number;
	/** 货代公司 */
	forwarder?: string;
	/**
	 * id
	 * @format int64
	 */
	intForwarderConfigId?: number;
	/** @format int64 */
	intShipConfigId?: number;
	/** 物流公司code */
	logisticsCompanyCode?: string;
	/** 会员等级 */
	membershipName?: string[];
	/** 运输方式 */
	shippingType?: string;
	/**
	 * 更新时间
	 * @format date-time
	 */
	updateTime?: string;
	warehouseList?: string[];
}

/** CombinationDirectItemDTO */
export interface CombinationDirectItemDTO {
	orderCode?: string;
	productSku?: string;
	/** @format int32 */
	productType?: number;
	shopProductSku?: string;
	/** @format int32 */
	unitNums?: number;
}

/** CombinationDirectProductDTO */
export interface CombinationDirectProductDTO {
	childs?: CombinationDirectItemDTO[];
	combinationProductSku?: string;
	combinationShopProductSku?: string;
	productImg?: string;
	productPropertiesName?: string;
	productTitle?: string;
	/** @format int32 */
	unpackCombinationNums?: number;
}

/** CombinationPickConfigDTO */
export interface CombinationPickConfigDTO {
	/**
	 * 值
	 * @format int32
	 */
	value?: number;
}

/** CommitInvoiceReqDto */
export interface CommitInvoiceReqDto {
	invoiceTemplateCode?: string;
	logisticsCode?: string;
}

/** ConfirmAllotInfoDTO */
export interface ConfirmAllotInfoDTO {
	/**
	 * 配货明细id
	 * @format int64
	 */
	deliveryTaskAllotDetailId?: number;
	/**
	 * 可分拣数量
	 * @format int32
	 */
	enablePickedNums?: number;
}

/** ConfirmAllotReqDTO */
export interface ConfirmAllotReqDTO {
	/** 分拣明细 */
	confirmAllotInfoDTOList?: ConfirmAllotInfoDTO[];
	/** 拣货任务编号 */
	pickTaskCode?: string;
	/** 批次包编码 */
	pkgCode?: string;
}

/** ConfirmDeliveryDTO */
export interface ConfirmDeliveryDTO {
	/** 发货单号 */
	deliveryTaskCode?: string;
	/** 物流单号 */
	logisticsNo?: string;
	/** 是否需要同步物流 */
	needSync?: boolean;
}

/** ConfirmDomesticDeliveryRespDTO */
export interface ConfirmDomesticDeliveryRespDTO {
	overdueDeliveryTaskCodeList?: string[];
}

/** ConfirmSplitPackageReqDTO */
export interface ConfirmSplitPackageReqDTO {
	/** 发货任务编号 */
	deliveryTaskCode?: string;
}

/** ContainerConfigDTO */
export interface ContainerConfigDTO {
	/** @format int32 */
	beactive?: number;
	/** 容器编码 */
	containerCode?: string;
	/**
	 * 容器商品
	 * @format int32
	 */
	containerGoods?: number;
	/** @format date-time */
	createTime?: string;
	/** @format int64 */
	creater?: number;
	createrName?: string;
	/** @format int64 */
	id?: number;
	/** @format date-time */
	lmt?: string;
	/** @format int64 */
	modifier?: number;
	modifierName?: string;
	/**
	 * 操作类型：0-关闭，1-开启
	 * @format int32
	 */
	operateType?: number;
	/**
	 * 状态：0-全部，1-未使用，2-拣货中，3-待分拣，4-分拣中，5-待打包，6-打包中
	 * @format int32
	 */
	status?: number;
	warehouse?: string;
}

/** ContainerConfigReqDTO */
export interface ContainerConfigReqDTO {
	/** 容器编码 */
	containerCode?: string;
	/**
	 * 容器商品：0-全部，1-无商品，2-有商品
	 * @format int32
	 */
	containerGoods?: number;
	/** @format int32 */
	pageNum?: number;
	/** @format int32 */
	pageSize?: number;
	sorts?: ISortFieldEnum[];
	/**
	 * 状态：0-全部，1-未使用，2-拣货中，3-待分拣，4-分拣中，5-待打包，6-打包中
	 * @format int32
	 */
	status?: number;
}

/** ContainerCreateReq */
export interface ContainerCreateReq {
	/**
	 * 容量类型 0 无 ，1: 5*4框,  2: 4*5框，用于分拣容器
	 * @format int32
	 */
	capacityType?: number;
	/** 子容器编码 */
	childContainerCodes?: string[];
	/** 容器编码 */
	containerCode?: string;
	/**
	 * 容器类型：1 拣货容器 2 分拣容器
	 * @format int32
	 */
	containerType?: number;
}

/** ContainerDetailResp */
export interface ContainerDetailResp {
	/** @format int32 */
	bizStatus?: number;
	/** @format int32 */
	capacityType?: number;
	containerCode?: string;
	relateContainerCode?: string;
}

/** ContainerPageReq */
export interface ContainerPageReq {
	/**
	 * 业务类型：空：全部 1 未使用 2 待拣货 3 拣货中 4 待分拣 5 分拣中 6 待打包 7 打包中 8 异常中
	 * @format int32
	 */
	bizStatus?: number;
	/** 容器编码 */
	containerCode?: string;
	/**
	 * 容器类型：null：全部 1 拣货容器 2 分拣容器
	 * @format int32
	 */
	containerType?: number;
	/** @format int32 */
	pageNum?: number;
	/** @format int32 */
	pageSize?: number;
	/**
	 * 是否有商品：null：全部 1 有商品 2 无商品
	 * @format int32
	 */
	productStatus?: number;
	sorts?: ISortFieldEnum[];
}

/** ContainerPageResp */
export interface ContainerPageResp {
	/**
	 * 绑定时间
	 * @format date-time
	 */
	bindTime?: string;
	/** 绑定人名称 */
	binderName?: string;
	/**
	 *  业务状态：1 未使用 2 待拣货 3 拣货中 4 待分拣 5 分拣中 6 待打包 7 打包中 8 异常中
	 * @format int32
	 */
	bizStatus?: number;
	/**
	 * 容量类型 1: 5*4框,  2: 4*5框，用于分拣容器
	 * @format int32
	 */
	capacityType?: number;
	/** 容器编码 */
	containerCode?: string;
	/**
	 * 容器类型：1 拣货容器 2 分拣容器
	 * @format int32
	 */
	containerType?: number;
	/**
	 * 创建时间
	 * @format date-time
	 */
	createTime?: string;
	/** 创建人 */
	creatorName?: string;
	/**
	 * 容器商品数量
	 * @format int32
	 */
	productAmount?: number;
	/**
	 * 容器状态: 0 开启 1 关闭
	 * @format int32
	 */
	status?: number;
}

/** ContainerProductPageReq */
export interface ContainerProductPageReq {
	/** 容器编码 */
	containerCode?: string;
	/** @format int32 */
	pageNum?: number;
	/** @format int32 */
	pageSize?: number;
	sorts?: ISortFieldEnum[];
}

/** ContainerProductResp */
export interface ContainerProductResp {
	/** 国际发货单编码 */
	deliveryTaskCode?: string;
	/** 批次包编码 */
	pkgCode?: string;
	/** 商品图片URL */
	productImageUrl?: string;
	/**
	 * 商品数量
	 * @format int32
	 */
	productNums?: number;
	/** 来源容器编码 */
	sourceContainerCode?: string;
}

/** ConversionRecordReqDTO */
export interface ConversionRecordReqDTO {
	deliveryTaskCodes?: string;
	platformOrderNos?: string;
	shopProductSkus?: string;
	/** @format int32 */
	systemSource?: number;
}

/** ConversionRecordRespDTO */
export interface ConversionRecordRespDTO {
	/** @format date-time */
	becomeRegularTime?: string;
	/** @format int32 */
	defectiveAmount?: number;
	deliveryTaskCode?: string;
	moveProductName?: string;
	/** @format date-time */
	moveProductTime?: string;
	pkgCode?: string;
	platformOrderNo?: string;
	productSku?: string;
	regularOperationName?: string;
	/** @format date-time */
	regularOperationTime?: string;
}

/** CopyBoxMaxAmountReqDTO */
export interface CopyBoxMaxAmountReqDTO {
	/** 发货装箱编码 */
	deliveryBoxCode?: string;
	/** 国际发货单编码不能为空 */
	deliveryTaskCode?: string;
	/**
	 * 装箱类型：0 大件装箱 1 FBA装箱
	 * @format int32
	 */
	packingType?: number;
}

/** CountryInfoRespDTO */
export interface CountryInfoRespDTO {
	countryCode?: string;
	countryName?: string;
}

/** CreateDeliveryPackageReqDTO */
export interface CreateDeliveryPackageReqDTO {
	/** 发货任务编号 */
	deliveryTaskCode?: string;
}

/** CreateMarkOperationReqDTO */
export interface CreateMarkOperationReqDTO {
	/** 国际发货单号 */
	deliveryTaskCode?: string;
	/**
	 * 打印数量
	 * @format int32
	 */
	nums?: number;
	/** 批次包集合 */
	pkgCodeList?: string[];
	/** 店铺商品sku */
	shopProductSku?: string;
}

/** CustomsClearanceAddressRespDTO */
export interface CustomsClearanceAddressRespDTO {
	/** 详细地址 */
	address?: string;
	/**
	 * 类型(0:收件人地址 1:英文收货地址 2:英文清关地址 3:发件人地址)
	 * @format int32
	 */
	addressType?: number;
	/** 区/县 */
	area?: string;
	/** 城市 */
	city?: string;
	/** 会社编码 */
	clubCode?: string;
	/** 公司名 */
	companyName?: string;
	/** 联系邮箱 */
	email?: string;
	/** 联系方式 */
	mobile?: string;
	/** 姓名 */
	name?: string;
	/** 国家 */
	nation?: string;
	/** 邮编 */
	postalCode?: string;
	/** 省/州 */
	province?: string;
}

/** CustomsProductCompilationMethodDTO */
export interface CustomsProductCompilationMethodDTO {
	compilationMethod?: string;
}

/** CustomsProductDTO */
export interface CustomsProductDTO {
	customsCode?: string;
	/** @format int64 */
	productCustomsId?: number;
	productItemCategoryEn?: string;
	productItemCategoryZh?: string;
	simpleReportCode?: string;
}

/** D2cAllotProgressInfoDTO */
export interface D2CAllotProgressInfoDTO {
	/** 篮子编号 */
	basketCode?: string;
	/** 分拣子容器 */
	containerCode?: string;
	/**
	 * 分拣框号
	 * @format int32
	 */
	frameNo?: number;
	/**
	 * 分拣层号
	 * @format int32
	 */
	layerNo?: number;
	/**
	 * 已分拣数量
	 * @format int32
	 */
	pickedNums?: number;
	/** 批次号编码 */
	pkgCode?: string;
	/**
	 * 未分拣数量
	 * @format int32
	 */
	unPickedNums?: number;
}

/** D2cAllotProgressRespDTO */
export interface D2CAllotProgressRespDTO {
	/**
	 * 已拣货数
	 * @format int32
	 */
	pickedNum?: number;
	/** 已分拣批次包明细 */
	pickedProgressInfoDTOList?: D2CAllotProgressInfoDTO[];
	/**
	 * 总需拣货数
	 * @format int32
	 */
	totalPickedNum?: number;
	/** 未分拣批次包明细 */
	unPickedProgressInfoDTOList?: D2CAllotProgressInfoDTO[];
}

/** D2cDeliverySpotCheckNumsDTO */
export interface D2CDeliverySpotCheckNumsDTO {
	orderCode?: string;
	/** @format int32 */
	spotCheckNum?: number;
}

/** D2cSingleProductAllotReqDTO */
export interface D2CSingleProductAllotReqDTO {
	/** 拣货任务编号 */
	pickTaskCode?: string;
	/** 批次包编码 */
	pkgCode?: string;
}

/** D2cSingleProductAllotRespDTO */
export interface D2CSingleProductAllotRespDTO {
	/** 篮子编号 */
	basketCode?: string;
	/** 分拣子容器编码 */
	containerCode?: string;
	/**
	 * 分拣框号
	 * @format int32
	 */
	frameNo?: number;
	/**
	 * 分拣层号
	 * @format int32
	 */
	layerNo?: number;
}

/** DefaultLabelConfigReqDTO */
export interface DefaultLabelConfigReqDTO {
	/** 节点名称 */
	nodeName?: string;
	/**
	 * 节点类型(1:简单检品打印 2:水洗标打印)
	 * @format int32
	 */
	nodeType?: number;
	/**
	 * 系统来源(1:D2C 2:B2B)
	 * @format int32
	 */
	systemSource?: number;
	/** 模板编码 */
	templateCode?: string;
	/** 模板名称 */
	templateName?: string;
}

/** DefaultLabelConfigVO */
export interface DefaultLabelConfigVO {
	/**
	 * 创建日期
	 * @format date-time
	 */
	createTime?: string;
	/**
	 * 创建人ID
	 * @format int64
	 */
	creatorId?: number;
	/** 创建人名称 */
	creatorName?: string;
	/**
	 * 修改人ID
	 * @format int64
	 */
	editorId?: number;
	/** 修改人名称 */
	editorName?: string;
	/**
	 * 主键id
	 * @format int64
	 */
	id?: number;
	/**
	 * 是否逻辑删除(0:未删除 1:已删除)
	 * @format int32
	 */
	isDelete?: number;
	/** 节点名称 */
	nodeName?: string;
	/**
	 * 节点类型(1:简单检品打印 2:水洗标打印)
	 * @format int32
	 */
	nodeType?: number;
	stationCode?: string;
	/**
	 * 系统来源(1:D2C 2:B2B)
	 * @format int32
	 */
	systemSource?: number;
	/** 模板编码 */
	templateCode?: string;
	/** 模板json */
	templateJson?: string;
	/** 模板名称 */
	templateName?: string;
	/**
	 * 修改时间
	 * @format date-time
	 */
	updateTime?: string;
	/** 仓库 */
	warehouse?: string;
}

/** DeliverReviewPageApiReqDTO */
export interface DeliverReviewPageApiReqDTO {
	/** @format int32 */
	auditType?: number;
	/** @format int64 */
	customerShopId?: number;
	deliveryTaskCodes?: string[];
	deliveryTitle?: string;
	/** @format int32 */
	deliveryType?: number;
	/** @format int32 */
	pageNum?: number;
	/** @format int32 */
	pageSize?: number;
	sorts?: ISortFieldEnum[];
	/** @format int32 */
	systemSource?: number;
}

/** DeliverTaskCancelDetailsReq */
export interface DeliverTaskCancelDetailsReq {
	orderNo?: string;
	shopProductSku?: string;
	/** @format int64 */
	systemOrderItemId?: number;
}

/** DeliverTaskCancelReq */
export interface DeliverTaskCancelReq {
	deliverTaskCancelDetailsReqList?: DeliverTaskCancelDetailsReq[];
	platformOrderNo?: string;
}

/** DeliverTaskConfirmReq */
export interface DeliverTaskConfirmReq {
	boxMarkLink?: string;
	branchCode?: string;
	/** @format int64 */
	customerId?: number;
	customerName?: string;
	/** @format int64 */
	customerShopId?: number;
	customerShopName?: string;
	declarationAddress?: DeliveryTaskAddressConfirmDTO;
	declarationDiscount?: number;
	deliverTaskDetails?: DeliverTaskDetailConfirmDTO[];
	/** @format int32 */
	deliveryNums?: number;
	deliveryTaskAdditionalDetails?: DeliveryTaskAdditionalDetailConfirmDTO[];
	deliveryTitle?: string;
	/** @format int32 */
	deliveryType?: number;
	/** @format int32 */
	directSaleFlag?: number;
	distribution?: string;
	distributionJp?: string;
	financialInstitutionCode?: string;
	forwarder?: string;
	forwarderJp?: string;
	importerCode?: string;
	/** @format int64 */
	intForwarderConfigId?: number;
	/** @format int64 */
	intShipConfigId?: number;
	/** @format int64 */
	labelTemplateId?: number;
	/** @format int32 */
	labelType?: number;
	/** @format int64 */
	mainCustomerId?: number;
	/** @format int32 */
	needBoxMark?: number;
	/** @format int32 */
	needLabel?: number;
	orderNo?: string;
	packingListUrl?: string;
	platformOrderNo?: string;
	/** @format int32 */
	preferFlag?: number;
	/** @format int32 */
	priceDeclarationType?: number;
	receiveHouse?: string;
	receiverAddress?: DeliveryTaskAddressConfirmDTO;
	receiverEnglishAddress?: DeliveryTaskAddressConfirmDTO;
	senderAddress?: DeliveryTaskAddressConfirmDTO;
	shipmentId?: string;
	shipmentName?: string;
	shippingType?: string;
	shippingTypeJp?: string;
	/** @format int32 */
	tariffType?: number;
	taxDeductionAccount?: string;
	/** @format int32 */
	thirdWarehouseType?: number;
	unificationCustomerFullName?: string;
	/** @format int32 */
	userTransportation?: number;
	warehouse?: string;
}

/** DeliverTaskCreateDTO */
export interface DeliverTaskCreateDTO {
	boxMarkLink?: string;
	branchCode?: string;
	cancelRemark?: string;
	/** @format int64 */
	customerId?: number;
	customerName?: string;
	/** @format int64 */
	customerShopId?: number;
	customerShopName?: string;
	declarationAddress?: DeliveryTaskAddressCreateDTO;
	declarationDiscount?: number;
	deliverTaskDetails?: DeliverTaskDetailCreateDTO[];
	/** @format int32 */
	deliveryNums?: number;
	deliveryTaskAdditionalDetails?: DeliveryTaskAdditionalDetailCreateDTO[];
	deliveryTitle?: string;
	/** @format int32 */
	deliveryType?: number;
	/** @format int32 */
	directSaleFlag?: number;
	distribution?: string;
	distributionJp?: string;
	/** @format int32 */
	enableDeliveryStatus?: number;
	financialInstitutionCode?: string;
	forwarder?: string;
	forwarderJp?: string;
	importerCode?: string;
	/** @format int64 */
	intForwarderConfigId?: number;
	/** @format int64 */
	intShipConfigId?: number;
	/** @format int64 */
	labelTemplateId?: number;
	/** @format int32 */
	labelType?: number;
	/** @format int64 */
	mainCustomerId?: number;
	/** @format int32 */
	needBoxMark?: number;
	/** @format int32 */
	needLabel?: number;
	orderNo?: string;
	/** @format int32 */
	orderSource?: number;
	packingListUrl?: string;
	platformOrderNo?: string;
	/** @format int32 */
	preferFlag?: number;
	/** @format int32 */
	priceDeclarationType?: number;
	receiveHouse?: string;
	receiverAddress?: DeliveryTaskAddressCreateDTO;
	receiverEnglishAddress?: DeliveryTaskAddressCreateDTO;
	senderAddress?: DeliveryTaskAddressCreateDTO;
	shipmentId?: string;
	shipmentName?: string;
	shippingType?: string;
	shippingTypeJp?: string;
	shopTagContent?: string;
	/** @format int32 */
	tariffType?: number;
	taxDeductionAccount?: string;
	/** @format int32 */
	thirdWarehouseType?: number;
	unificationCustomerFullName?: string;
	warehouse?: string;
}

/** DeliverTaskDetailConfirmDTO */
export interface DeliverTaskDetailConfirmDTO {
	amazonAsin?: string;
	amazonFnsku?: string;
	amazonMsku?: string;
	amazonProductTitle?: string;
	barCodeName?: string;
	barCodeUrl?: string;
	coding?: string;
	/** @format int32 */
	combinationDirectFlag?: number;
	combinationDirectProductDto?: CombinationDirectProductDTO;
	commodityName?: string;
	commoditySku?: string;
	compilationMethod?: string;
	customerProductMsku?: string;
	/** @format int32 */
	deliveryNums?: number;
	/** @format int32 */
	depotDeliveryNums?: number;
	/** @format int32 */
	inTransitDeliveryNums?: number;
	/** @format int32 */
	orderDeliveryNums?: number;
	orderNo?: string;
	productCategoryEnglishName?: string;
	productCategoryName?: string;
	productCode?: string;
	productDeclarationCode?: string;
	productImg?: string;
	productLink?: string;
	productMainImg?: string;
	productName?: string;
	productPrice?: number;
	productPriceJp?: number;
	productPropertiesName?: string;
	productSku?: string;
	productTitle?: string;
	/** @format int32 */
	productType?: number;
	productUsPrice?: number;
	settleUnitPrice?: number;
	shopProductSku?: string;
	thirdProductSku?: string;
}

/** DeliverTaskDetailCreateDTO */
export interface DeliverTaskDetailCreateDTO {
	amazonAsin?: string;
	amazonFnsku?: string;
	amazonMsku?: string;
	amazonProductTitle?: string;
	barCodeName?: string;
	barCodeUrl?: string;
	coding?: string;
	/** @format int32 */
	combinationDirectFlag?: number;
	combinationDirectProductDto?: CombinationDirectProductDTO;
	commodityName?: string;
	commoditySku?: string;
	compilationMethod?: string;
	customerProductMsku?: string;
	/** @format int32 */
	deliveryNums?: number;
	/** @format int32 */
	depotDeliveryNums?: number;
	/** @format int32 */
	inTransitDeliveryNums?: number;
	/** @format int32 */
	orderDeliveryNums?: number;
	orderNo?: string;
	productCategoryEnglishName?: string;
	productCategoryName?: string;
	productCode?: string;
	productDeclarationCode?: string;
	productImg?: string;
	productLink?: string;
	productMainImg?: string;
	productName?: string;
	productPrice?: number;
	productPriceJp?: number;
	productPropertiesName?: string;
	productSku?: string;
	productTitle?: string;
	/** @format int32 */
	productType?: number;
	productUsPrice?: number;
	settleUnitPrice?: number;
	shopProductSku?: string;
	systemOrderItemId?: string;
	tagContent?: string;
	thirdProductSku?: string;
}

/** DeliverTaskRemarkReqDTO */
export interface DeliverTaskRemarkReqDTO {
	/** 发货任务编号 */
	deliveryTaskCode: string;
	/** 备注 */
	remark?: string;
}

/** DeliverTaskReqDTO */
export interface DeliverTaskReqDTO {
	/** @format int32 */
	canDelivery?: number;
	/** @format date-time */
	createEndTime?: string;
	/** @format date-time */
	createStartTime?: string;
	/** @format int64 */
	customerId?: number;
	customerName?: string;
	/** @format int64 */
	customerShopId?: number;
	customerShopIdList?: number[];
	customerShopName?: string;
	/** @format int32 */
	customerTag?: number;
	/** @format date-time */
	deliveryEndTime?: string;
	/** @format date-time */
	deliveryStartTime?: string;
	/** @format int32 */
	deliveryStatus?: number;
	deliveryStatusList?: number[];
	deliveryTaskCode?: string;
	/** @uniqueItems true */
	deliveryTaskCodeList?: string[];
	deliveryTitle?: string;
	/** @format int32 */
	deliveryType?: number;
	deliveryTypeList?: number[];
	deliveryTypes?: string;
	/** @format date-time */
	endConfirmTimeEnd?: string;
	/** @format date-time */
	endConfirmTimeStart?: string;
	exceptionLocation?: string;
	forwarder?: string;
	historyLogisticsCode?: string;
	/** @uniqueItems true */
	historyLogisticsCodeList?: string[];
	historyLogisticsCodeStr?: string;
	/** @format int64 */
	intForwarderConfigId?: number;
	/** @uniqueItems true */
	logisticsCodeList?: string[];
	logisticsCodeStr?: string;
	/** @format int32 */
	managerConfirmStatus?: number;
	/** @format int64 */
	managerId?: number;
	managerName?: string;
	/** @format date-time */
	packEndTime?: string;
	/** @format date-time */
	packStartTime?: string;
	/** @format int32 */
	pageNum?: number;
	/** @format int32 */
	pageSize?: number;
	/** @format int32 */
	payStatus?: number;
	pkgCode?: string;
	/** @uniqueItems true */
	platformOrderNoList?: string[];
	platformOrderNoStr?: string;
	/** @format int32 */
	processingStatus?: number;
	/** @uniqueItems true */
	productSkuList?: string[];
	productSkuStr?: string;
	receivingNationCode?: string;
	/** @uniqueItems true */
	shopProductSkuList?: string[];
	shopProductSkuStr?: string;
	/** @format int32 */
	singleProduct?: number;
	sorts?: ISortFieldEnum[];
	/** @format int32 */
	systemSource?: number;
	/** @format int32 */
	thirdWarehouseType?: number;
	unificationCustomerFullName?: string;
	warehouse?: string;
}

/** DeliverTaskRespDTO */
export interface DeliverTaskRespDTO {
	additionalFee?: number;
	auditRemark?: string;
	boxMarkLink?: string;
	channelNo?: string;
	/** @format date-time */
	createTime?: string;
	/** @format int64 */
	creater?: number;
	/** @format int64 */
	customerId?: number;
	customerName?: string;
	/** @format int64 */
	customerShopId?: number;
	customerShopName?: string;
	declarationError?: boolean;
	/** @format int32 */
	deliveryNums?: number;
	/** @format int32 */
	deliveryStatus?: number;
	deliveryTaskAdditionalDetailList?: DeliveryTaskAdditionalDetailRespDTO[];
	deliveryTaskAddressList?: DeliveryTaskAddressRespDTO[];
	deliveryTaskCode?: string;
	/** @format int64 */
	deliveryTaskId?: number;
	/** @format date-time */
	deliveryTime?: string;
	deliveryTitle?: string;
	/** @format int32 */
	deliveryType?: number;
	distribution?: string;
	/** @format int32 */
	downloadPackingDetailsFlag?: number;
	/** @format int32 */
	downloadPackingListFlag?: number;
	/** @format date-time */
	endConfirmTime?: string;
	exceptionLocation?: string;
	extendDTO?: DeliveryTaskExtendDTO;
	/** @format int32 */
	fbaDownloadBoxMark?: number;
	/** @format int32 */
	fbaUploadCarton?: number;
	forwarder?: string;
	/** @format int64 */
	intForwarderConfigId?: number;
	/** @format int64 */
	intShipConfigId?: number;
	interceptEventId?: string;
	/** @format int32 */
	interceptStatus?: number;
	/** @format date-time */
	interceptTime?: string;
	/** @format int32 */
	invoiceStatus?: number;
	/** @format int64 */
	labelTemplateId?: number;
	/** @format int32 */
	labelType?: number;
	/** @format date-time */
	lmt?: string;
	logisticsChannelDTOList?: LogisticsChannelDTO[];
	logisticsCompanyCode?: string;
	logisticsCustomerAccount?: string;
	logisticsCustomerName?: string;
	logisticsDetailList?: DeliveryTaskLogisticsDetailRespDTO[];
	/** @format int32 */
	managerConfirmStatus?: number;
	/** @format int64 */
	managerId?: number;
	managerName?: string;
	/** @format int32 */
	membershipLevel?: number;
	/** @format int64 */
	membershipTemplateId?: number;
	message?: string;
	/** @format int64 */
	modifier?: number;
	/** @format int32 */
	needBoxMark?: number;
	/** @format int32 */
	needLabel?: number;
	/** @format date-time */
	packTime?: string;
	packingListUrl?: string;
	/** @format int32 */
	payStatus?: number;
	pkgPackList?: DeliveryPkgDTO[];
	platformOrderNo?: string;
	queryAdditionalFee?: boolean;
	/** @format int32 */
	realDeliveryNums?: number;
	receiverNation?: string;
	receivingNationCode?: string;
	receivingNationName?: string;
	remark?: string;
	shippingType?: string;
	/** @format int32 */
	sign?: number;
	/** @format int32 */
	singleProduct?: number;
	splitPackageFlag?: boolean;
	stationCode?: string;
	/** @format date-time */
	strandedTime?: string;
	tagExtendParams?: string;
	taskDetailList?: DeliveryTaskDetailRespDTO[];
	/** @format int32 */
	thirdWarehouseType?: number;
	totalAmount?: number;
	/** @format int32 */
	totalBox?: number;
	/** @format int32 */
	totalWeight?: number;
	unificationCustomerFullName?: string;
	warehouse?: string;
	wmsDistribution?: string;
	wmsForwarder?: string;
	/** @format int64 */
	wmsIntForwarderConfigId?: number;
	/** @format int64 */
	wmsIntShipConfigId?: number;
	wmsShippingType?: string;
}

/** DeliverTaskSkuRemarkReqDTO */
export interface DeliverTaskSkuRemarkReqDTO {
	/**
	 * 发货任务详情ID
	 * @format int64
	 */
	deliveryTaskDetailId?: number;
	/** 备注 */
	remark?: string;
}

/** DeliverTaskUpdateAddressDTO */
export interface DeliverTaskUpdateAddressDTO {
	address?: string;
	/** @format int32 */
	addressType?: number;
	area?: string;
	city?: string;
	clearanceCode?: string;
	clubCode?: string;
	code?: string;
	companyName?: string;
	email?: string;
	mobile?: string;
	name?: string;
	nation?: string;
	platformOrderNo?: string;
	postalCode?: string;
	province?: string;
}

/** DeliverTaskWmsPageDto */
export interface DeliverTaskWmsPageDto {
	auditRemark?: string;
	boxMarkLink?: string;
	channelNo?: string;
	/** @format date-time */
	createTime?: string;
	/** @format int64 */
	creater?: number;
	/** @format int64 */
	customerId?: number;
	customerName?: string;
	/** @format int64 */
	customerShopId?: number;
	customerShopName?: string;
	/**
	 * 1:OEM重点客户-项目,2:OEM重点客户-非项目
	 * @format int32
	 */
	customerTag?: number;
	declarationError?: boolean;
	/** @format int32 */
	deliveryNums?: number;
	/** @format int32 */
	deliveryStatus?: number;
	deliveryTaskCode?: string;
	/** @format int64 */
	deliveryTaskId?: number;
	/** @format date-time */
	deliveryTime?: string;
	deliveryTitle?: string;
	/** @format int32 */
	deliveryType?: number;
	distribution?: string;
	/** @format int32 */
	downloadPackingListFlag?: number;
	/** @format date-time */
	endConfirmTime?: string;
	exceptionLocation?: string;
	extendDTO?: DeliveryTaskExtendDTO;
	/** @format int32 */
	fbaDownloadBoxMark?: number;
	/** @format int32 */
	fbaUploadCarton?: number;
	forwarder?: string;
	/** @format int64 */
	intForwarderConfigId?: number;
	/** @format int64 */
	intShipConfigId?: number;
	interceptEventId?: string;
	/** @format int32 */
	interceptStatus?: number;
	/** @format date-time */
	interceptTime?: string;
	/** @format int32 */
	labelType?: number;
	/** @format date-time */
	lmt?: string;
	logisticsCompanyCode?: string;
	logisticsCustomerAccount?: string;
	logisticsCustomerName?: string;
	logisticsDetailList?: DeliveryTaskLogisticsDetailRespDTO[];
	/** @format int32 */
	managerConfirmStatus?: number;
	/** @format int64 */
	managerId?: number;
	managerName?: string;
	/** @format int32 */
	membershipLevel?: number;
	/** @format int64 */
	membershipTemplateId?: number;
	message?: string;
	/** @format int64 */
	modifier?: number;
	/** @format int32 */
	needBoxMark?: number;
	/** @format int32 */
	needLabel?: number;
	/** @format date-time */
	packTime?: string;
	packingListUrl?: string;
	/** @format int32 */
	payStatus?: number;
	platformOrderNo?: string;
	/** @format int32 */
	realDeliveryNums?: number;
	receiverNation?: string;
	receivingNationCode?: string;
	receivingNationName?: string;
	remark?: string;
	shippingType?: string;
	/** @format int32 */
	singleProduct?: number;
	/** @format date-time */
	strandedTime?: string;
	taskDetailList?: DeliveryTaskDetailRespDTO[];
	/** @format int32 */
	thirdWarehouseType?: number;
	unificationCustomerFullName?: string;
	warehouse?: string;
	wmsDistribution?: string;
	wmsForwarder?: string;
	/** @format int64 */
	wmsIntForwarderConfigId?: number;
	/** @format int64 */
	wmsIntShipConfigId?: number;
	wmsShippingType?: string;
}

/** DeliveryAfterSalesReqDTO */
export interface DeliveryAfterSalesReqDTO {
	/** @format int32 */
	abnormalOrderType?: number;
	afterSalesImg?: string;
	/** @format int32 */
	afterSalesReason?: number;
	afterSalesRefundPrice?: number;
	afterSalesRefundTotalAmount?: number;
	/** @format int32 */
	applyAfterSalesQuantity?: number;
	/** @format int64 */
	customerId?: number;
	customerName?: string;
	/** @format int64 */
	customerShopId?: number;
	customerShopName?: string;
	/** @format int32 */
	defectiveAmount?: number;
	deliveryTaskCode?: string;
	detailedExplain?: string;
	platformOrderNo?: string;
	productMainImg?: string;
	productPropertiesName?: string;
	productSku?: string;
	productSkuImg?: string;
	productTitle?: string;
	/** @format int32 */
	productType?: number;
	shopProductSku?: string;
	/** @format int32 */
	systemSource?: number;
	unificationCustomerFullName?: string;
}

/** DeliveryBoxBatchCopyReqDTO */
export interface DeliveryBoxBatchCopyReqDTO {
	/**
	 * 复制箱子数
	 * @format int32
	 */
	copyBoxAmount?: number;
	/** 发货装箱编码 */
	deliveryBoxCode?: string;
	/** 出库任务编码 */
	deliveryTaskCode?: string;
	/** 容器编码 */
	insidePackCode?: string;
}

/** DeliveryBoxDetailReqDTO */
export interface DeliveryBoxDetailReqDTO {
	/** 装箱编号 */
	deliveryBoxCode?: string;
	/** 发货任务编号 */
	deliveryTaskCode?: string;
	/** 内部打包编号 */
	insidePackCode?: string;
	/**
	 * 已装数 nums=0为删除sku
	 * @format int32
	 */
	nums?: number;
	/** 批次包编码 */
	pkgCode?: string;
	/** 店铺商品sku */
	shopProductSku?: string;
}

/** DeliveryBoxDetailRespDTO */
export interface DeliveryBoxDetailRespDTO {
	amazonAsin?: string;
	amazonFnsku?: string;
	amazonMsku?: string;
	/** @format date-time */
	createTime?: string;
	/** @format int64 */
	creater?: number;
	deliveryBoxCode?: string;
	/** @format int64 */
	deliveryBoxDetailId?: number;
	/** @format int32 */
	deliveryNums?: number;
	deliveryTaskCode?: string;
	/** @format int32 */
	inNums?: number;
	/** @format date-time */
	lmt?: string;
	/** @format int64 */
	modifier?: number;
	/** @format int32 */
	nums?: number;
	productImg?: string;
	productName?: string;
	productSku?: string;
	shopProductSku?: string;
}

/** DeliveryBoxEditWeightReqDTO */
export interface DeliveryBoxEditWeightReqDTO {
	/** 装箱编号 */
	deliveryBoxCode?: string;
	/**
	 * 重量(g)
	 * @format int64
	 */
	weight?: number;
}

/** DeliveryBoxPkgDetailDTO */
export interface DeliveryBoxPkgDetailDTO {
	amazonAsin?: string;
	amazonFnsku?: string;
	amazonMsku?: string;
	deliveryBoxCode?: string;
	deliveryTaskCode?: string;
	insidePackCode?: string;
	/** @format int32 */
	nums?: number;
	/** @format int32 */
	orderType?: number;
	/** @format int32 */
	pkgAmount?: number;
	pkgCode?: string;
	productImg?: string;
	productName?: string;
	shopProductSku?: string;
}

/** DeliveryBoxPkgDetailReqDTO */
export interface DeliveryBoxPkgDetailReqDTO {
	/** 装箱编号 */
	deliveryBoxCode?: string;
	/** 发货任务编号 */
	deliveryTaskCode?: string;
	/** 内部打包编号 */
	insidePackCode?: string;
	/**
	 * 已装数 nums=0为删除sku
	 * @format int32
	 */
	nums?: number;
	/** 批次包编码 */
	pkgCode?: string;
	/** 店铺商品sku */
	shopProductSku?: string;
}

/** DeliveryBoxProductSkuEditDTO */
export interface DeliveryBoxProductSkuEditDTO {
	/** 亚马逊asin */
	amazonAsin?: string;
	/** 亚马逊fnsku */
	amazonFnsku?: string;
	/** 批次包编码 */
	pkgCode?: string;
	/** 商品图片 */
	productImg?: string;
	/** 商品名称 */
	productName?: string;
	/** 店铺商品sku */
	shopProductSku?: string;
}

/** DeliveryBoxReqDTO */
export interface DeliveryBoxReqDTO {
	/** 箱唛地址 */
	boxMarkLink?: string;
	/**
	 * 箱子模板ID
	 * @format int64
	 */
	boxTemplateId?: number;
	/** 装箱编号 */
	deliveryBoxCode?: string;
	/**
	 * 发货装箱单ID
	 * @format int64
	 */
	deliveryBoxId?: number;
	/** 发货任务编号 */
	deliveryTaskCode?: string;
	/**
	 * 发货任务物流信息ID
	 * @format int64
	 */
	deliveryTaskLogisticsDetailId?: number;
	/**
	 * 高
	 * @format int32
	 */
	height?: number;
	/** 内部打包编号 */
	insidePackCode?: string;
	/**
	 * 长
	 * @format int32
	 */
	length?: number;
	/** 物流单号 */
	logisticsCode?: string;
	/** 物流公司code */
	logisticsCompanyCode?: string;
	/**
	 * 商品总数
	 * @format int32
	 */
	nums?: number;
	/**
	 * 重量(g)
	 * @format int64
	 */
	weight?: number;
	/**
	 * 宽
	 * @format int32
	 */
	width?: number;
}

/** DeliveryBoxRespDTO */
export interface DeliveryBoxRespDTO {
	boxMarkLink?: string;
	boxPkgDetailList?: DeliveryBoxPkgDetailDTO[];
	/** @format int64 */
	boxTemplateId?: number;
	bulky?: number;
	/** @format date-time */
	createTime?: string;
	/** @format int64 */
	creater?: number;
	deliveryBoxCode?: string;
	deliveryBoxDetailList?: DeliveryBoxDetailRespDTO[];
	/** @format int64 */
	deliveryBoxId?: number;
	deliveryTaskCode?: string;
	/** @format int32 */
	height?: number;
	insidePackCode?: string;
	/** @format int32 */
	length?: number;
	/** @format date-time */
	lmt?: string;
	logisticsCode?: string;
	logisticsCompanyCode?: string;
	/** @format int64 */
	modifier?: number;
	/** @format int32 */
	nums?: number;
	priceLimit?: number;
	totalProductUsPrice?: number;
	/** @format int64 */
	weight?: number;
	weightLimit?: number;
	/** @format int32 */
	width?: number;
}

/** DeliveryCensusDTO */
export interface DeliveryCensusDTO {
	/** @format int32 */
	beShippedNums?: number;
	/** @format int64 */
	customerShopId?: number;
	/** @format int32 */
	dayShippedNums?: number;
}

/** DeliveryForwarderReq */
export interface DeliveryForwarderReq {
	/**
	 * 发货类型(0:大件fba 1:大件普通 2:小件)
	 * @format int32
	 */
	deliveryType?: number;
	/** @format int32 */
	singleProduct?: number;
}

/** DeliveryForwarderResp */
export interface DeliveryForwarderResp {
	/** 中国货代公司id（客户） */
	forwarder?: string;
	/**
	 * 中国货代公司名称（客户）
	 * @format int64
	 */
	intForwarderConfigId?: number;
	/** 运输方式 */
	shippingType?: string;
	/**
	 * 订单数量
	 * @format int32
	 */
	totalAmount?: number;
}

/** DeliveryOrderInterceptEventReq */
export interface DeliveryOrderInterceptEventReq {
	/** @format int32 */
	eventType?: number;
	orderEventId?: string;
	/** @format date-time */
	orderOperateTime?: string;
	/** @format int64 */
	orderOperatorId?: number;
	orderOperatorName?: string;
	platformOrderNo?: string;
	warehouse?: string;
}

/** DeliveryOrderInterceptEventResp */
export interface DeliveryOrderInterceptEventResp {
	/** @format date-time */
	createTime?: string;
	/** @format int32 */
	eventType?: number;
	interceptEventId?: string;
	orderEventId?: string;
	platformOrderNo?: string;
	warehouse?: string;
}

/** DeliveryPackageAddSkuReqDTO */
export interface DeliveryPackageAddSkuReqDTO {
	/** 包裹编号 */
	deliveryPackageCode?: string;
	/** 发货任务编号 */
	deliveryTaskCode?: string;
	/** 批次包集合 */
	pkgList?: PkgPackageOptDTO[];
}

/** DeliveryPackageEditWeightReqDTO */
export interface DeliveryPackageEditWeightReqDTO {
	/** 包裹编号 */
	deliveryPackageCode?: string;
	/**
	 * 重量/克
	 * @format int64
	 */
	weight?: number;
}

/** DeliveryPackageRecordDTO */
export interface DeliveryPackageRecordDTO {
	/** 包裹编号 */
	deliveryPackageCode?: string;
	/**
	 * 业务主键
	 * @format int64
	 */
	deliveryPackageRecordId?: number;
	/** 发货任务编号 */
	deliveryTaskCode?: string;
	/**
	 * 打包数
	 * @format int32
	 */
	nums?: number;
	/** 系统订单号 */
	orderCode?: string;
	/** 批次包编码 */
	pkgCode?: string;
	/** 店铺商品sku */
	shopProductSku?: string;
}

/** DeliveryPackageRemoveSkuReqDTO */
export interface DeliveryPackageRemoveSkuReqDTO {
	/** 包裹编号 */
	deliveryPackageCode?: string;
	/** 发货任务编号 */
	deliveryTaskCode?: string;
	/** 批次包集合 */
	pkgList?: PkgPackageOptDTO[];
}

/** DeliveryPackageRespDTO */
export interface DeliveryPackageRespDTO {
	/** 包裹编号 */
	deliveryPackageCode?: string;
	/** 发货任务编号 */
	deliveryTaskCode?: string;
	/**
	 * 包裹序号
	 * @format int32
	 */
	packageNo?: number;
	/** 商品明细 */
	recordList?: DeliveryPackageRecordDTO[];
	/**
	 * 重量(g)
	 * @format int64
	 */
	weight?: number;
}

/** DeliveryPickAllotBindAllotContainerReq */
export interface DeliveryPickAllotBindAllotContainerReq {
	/** 分拣容器编码 */
	allotContainerCode?: string;
}

/** DeliveryPickAllotBindPickContainerReq */
export interface DeliveryPickAllotBindPickContainerReq {
	/** 拣货容器编码 */
	containerCode?: string;
}

/** DeliveryPickAllotTaskCheckReq */
export type DeliveryPickAllotTaskCheckReq = object;

/** DeliveryPickAllotTaskCheckResp */
export interface DeliveryPickAllotTaskCheckResp {
	/** 分拣容器编码 */
	allotContainerCode?: string;
	/**
	 * 业务状态
	 * @format int32
	 */
	bizStatus?: number;
	/**
	 * 是否有当前任务: 0 没有任务绑定 1 绑定中待开始拣货 2 已经开始拣货
	 * @format int32
	 */
	currentStatus?: number;
	/** 拣货容器编码 */
	pickContainerCode?: string;
	/** 当前拣货任务号 */
	pickTaskCode?: string;
}

/** DeliveryPickAllotTaskStartReq */
export interface DeliveryPickAllotTaskStartReq {
	/** 分拣容器编码 */
	allotContainerCode?: string;
	/** 拣货容器编码 */
	pickContainerCode?: string;
}

/** DeliveryPickAllotUnBindAllotContainerReq */
export interface DeliveryPickAllotUnBindAllotContainerReq {
	/** 分拣容器编码 */
	containerCode?: string;
}

/** DeliveryPickAllotUnBindPickContainerReq */
export interface DeliveryPickAllotUnBindPickContainerReq {
	/** 拣货容器编码 */
	containerCode?: string;
}

/** DeliveryPickBatchPageReq */
export interface DeliveryPickBatchPageReq {
	/**
	 * 任务领取人ID
	 * @format int64
	 */
	operatorId?: number;
	/** @format int32 */
	pageNum?: number;
	/** @format int32 */
	pageSize?: number;
	sorts?: ISortFieldEnum[];
}

/** DeliveryPickBatchPageResp */
export interface DeliveryPickBatchPageResp {
	/** 批次号编码 */
	batchCode?: string;
	/**
	 * 批次状态 1 待拣货 2 拣货中 3 分拣中 4 拣货完成
	 * @format int32
	 */
	batchStatus?: number;
	/**
	 * 是否需要分拣 0 否 1 是
	 * @format int32
	 */
	needAllot?: number;
	/**
	 * 操作人ID
	 * @format int64
	 */
	operatorId?: number;
	/** 操作人名称 */
	operatorName?: string;
	/** 拣货容器编码 */
	pickContainerCode?: string;
}

/** DeliveryPickBatchPkgPageReq */
export interface DeliveryPickBatchPkgPageReq {
	/** 批次编码 */
	batchCode?: string;
	/** @format int32 */
	pageNum?: number;
	/** @format int32 */
	pageSize?: number;
	sorts?: ISortFieldEnum[];
}

/** DeliveryPickBatchPkgPageResp */
export interface DeliveryPickBatchPkgPageResp {
	/** 库位编码 */
	gridCode?: string;
	/**
	 * 批次包数量
	 * @format int32
	 */
	nums?: number;
	/**
	 * 拣货时间
	 * @format date-time
	 */
	pickTime?: string;
	/** 批次编码 */
	pkgCode?: string;
	/** 库区编码 */
	regionCode?: string;
	/** 库区名称 */
	regionName?: string;
}

/** DeliveryPickTaskCreateDTO */
export interface DeliveryPickTaskCreateDTO {
	/**
	 * 发货任务编号集合
	 * @uniqueItems true
	 */
	deliveryTaskCodeList?: string[];
	/**
	 * 拣货类型(0:大件拣货 1:小件单品 2:小件多品 3:FBA拣货 5:国内拣货)
	 * @format int32
	 */
	pickType?: number;
}

/** DeliveryPickTaskDetailReqDTO */
export interface DeliveryPickTaskDetailReqDTO {
	/** 拣货任务编号 */
	pickTaskCode?: string;
	/** 批次包编号 */
	pkgCode?: string;
}

/** DeliveryPickTaskDetailRespDTO */
export interface DeliveryPickTaskDetailRespDTO {
	/**
	 * 创建时间
	 * @format date-time
	 */
	createTime?: string;
	/**
	 * 创建人
	 * @format int64
	 */
	creater?: number;
	/**
	 * 发货-拣货任务详情ID
	 * @format int64
	 */
	deliveryPickTaskDetailId?: number;
	/** 货格码编号 */
	gridCode?: string;
	/**
	 * 最新修改时间
	 * @format date-time
	 */
	lmt?: string;
	/**
	 * 修改人
	 * @format int64
	 */
	modifier?: number;
	/**
	 * 商品数
	 * @format int32
	 */
	nums?: number;
	/**
	 * 商品拣货状态(0:未拣 1:已拣)
	 * @format int32
	 */
	pickStatus?: number;
	/** 拣货任务编号 */
	pickTaskCode?: string;
	/** 批次包编号 */
	pkgCode?: string;
	/** 商品图片 */
	productImg?: string;
	/** 库区名称 */
	regionName?: string;
	/** 店铺商品sku */
	shopProductSku?: string;
}

/** DeliveryPickTaskExceptionCreateReq */
export interface DeliveryPickTaskExceptionCreateReq {
	/** 容器编码 */
	containerCode?: string;
	/** 目标库位编码 */
	gridCode?: string;
}

/** DeliveryPickTaskPickFinishReq */
export interface DeliveryPickTaskPickFinishReq {
	/** 拣货批次编码 */
	batchCode?: string;
}

/** DeliveryPickTaskRespDTO */
export interface DeliveryPickTaskRespDTO {
	/**
	 * 创建时间
	 * @format date-time
	 */
	createTime?: string;
	/**
	 * 创建人
	 * @format int64
	 */
	creater?: number;
	/**
	 * 发货-拣货任务ID
	 * @format int64
	 */
	deliveryPickTaskId?: number;
	/**
	 * 最新修改时间
	 * @format date-time
	 */
	lmt?: string;
	/**
	 * 修改人
	 * @format int64
	 */
	modifier?: number;
	/**
	 * 拣货状态(0:待拣货 1:拣货中 2:配货中 3:已完成)
	 * @format int32
	 */
	pickStatus?: number;
	/** 拣货任务编号 */
	pickTaskCode?: string;
	/**
	 * 拣货类型(0:大件拣货 1:小件单品 2:小件多品 3:FBA拣货 4:次转正 5:国内拣货)
	 * @format int32
	 */
	pickType?: number;
}

/** DeliveryPkgDTO */
export interface DeliveryPkgDTO {
	/** @format int32 */
	inNums?: number;
	/** @format int32 */
	pkgAmount?: number;
	pkgCode?: string;
	productImg?: string;
	productName?: string;
	productPropertiesName?: string;
	productSku?: string;
	shopProductSku?: string;
	/** @format int32 */
	totalNums?: number;
}

/** DeliveryReivewSkuReqDTO */
export interface DeliveryReivewSkuReqDTO {
	/** 审核中国货代公司 */
	auditForwarder?: string;
	/**
	 * 审核中国货代公司id
	 * @format int64
	 */
	auditIntForwarderConfigId?: number;
	/**
	 * 国际物流公司ID
	 * @format int64
	 */
	auditIntShipConfigId?: number;
	/** 审核原因 */
	auditReason?: string;
	/** 审核原因编码 */
	auditReasonCode?: string;
	/** 审核原因(日文) */
	auditReasonJp?: string;
	/** 审核运输方式 */
	auditShippingType?: string;
	/** 审核中国货代公司名称SPU */
	auditSpuForwarder?: string;
	/**
	 * 审核中国货代公司id SPU
	 * @format int64
	 */
	auditSpuIntForwarderConfigId?: number;
	/** 审核SPU原因编码 */
	auditSpuReasonCode?: string;
	/** 审核运输方式SPU */
	auditSpuShippingType?: string;
	/** 审核单编码 */
	deliveryReviewCode?: string;
	/**
	 * 审核明细ID
	 * @format int64
	 */
	deliveryReviewDetailId?: number;
	/** SKU名称 */
	productSku?: string;
}

/** DeliveryReviewAbnormalReqDTO */
export interface DeliveryReviewAbnormalReqDTO {
	/** 发货审单编号 */
	deliveryReviewCode?: string;
}

/** DeliveryReviewAbnormalSpuEditReqDTO */
export interface DeliveryReviewAbnormalSpuEditReqDTO {
	/** 发货审单编号 */
	deliveryReviewCode?: string;
	skuList?: DeliveryReviewSkuEditReqDTO[];
	/**
	 * 版本
	 * @format int32
	 */
	version?: number;
}

/** DeliveryReviewApiConfirmCountReqDTO */
export interface DeliveryReviewApiConfirmCountReqDTO {
	/** @format int64 */
	customerShopId?: number;
	/** @format int32 */
	systemSource?: number;
}

/** DeliveryReviewApiConfirmCountRespDTO */
export interface DeliveryReviewApiConfirmCountRespDTO {
	/** @format int32 */
	byOrderCount?: number;
	/** @format int32 */
	bySkuCount?: number;
	/** @format int32 */
	totalCount?: number;
}

/** DeliveryReviewCancelReqDTO */
export interface DeliveryReviewCancelReqDTO {
	/** @format int32 */
	cancelType?: number;
	platformOrderNo?: string;
	skuList?: DeliveryReviewSkuCancelReqDTO[];
}

/** DeliveryReviewCombinationProductReqDTO */
export interface DeliveryReviewCombinationProductReqDTO {
	/** 审核单编码 */
	deliveryReviewCode?: string;
	/** 商品SKU */
	productSku?: string;
}

/** DeliveryReviewCombinationProductRespDTO */
export interface DeliveryReviewCombinationProductRespDTO {
	/** @format int32 */
	amount?: number;
	packCode?: string;
	platformOrderNo?: string;
	productImg?: string;
	productName?: string;
	productProp?: string;
	productSku?: string;
	shopProductSku?: string;
	/** @format int32 */
	unitAmount?: number;
}

/** DeliveryReviewCreateReqDTO */
export interface DeliveryReviewCreateReqDTO {
	/** @format int64 */
	customerId?: number;
	customerName?: string;
	/** @format int64 */
	customerShopId?: number;
	customerShopName?: string;
	/** @format int32 */
	deliveryNums?: number;
	deliveryReviewDetailCreateList?: DeliveryReviewDetailCreateReqDTO[];
	deliveryTitle?: string;
	/** @format int32 */
	deliveryType?: number;
	/** @format int32 */
	directSaleFlag?: number;
	distribution?: string;
	distributionJp?: string;
	forwarder?: string;
	forwarderJp?: string;
	/** @format int64 */
	intForwarderConfigId?: number;
	/** @format int64 */
	intShipConfigId?: number;
	/** @format int64 */
	mainCustomerId?: number;
	orderNo?: string;
	platformOrderNo?: string;
	receivingNationCode?: string;
	receivingNationName?: string;
	shippingType?: string;
	shippingTypeJp?: string;
	unificationCustomerFullName?: string;
	warehouse?: string;
}

/** DeliveryReviewCustomerConfirmReqDTO */
export interface DeliveryReviewCustomerConfirmReqDTO {
	confirmSkuList?: DeliveryReviewCustomerConfirmSkuDTO[];
	/** @format int32 */
	confirmType?: number;
	/** @format int64 */
	customerShopId?: number;
	deliveryReviewCode?: string;
}

/** DeliveryReviewCustomerConfirmSkuDTO */
export interface DeliveryReviewCustomerConfirmSkuDTO {
	/** @format int32 */
	confirmType?: number;
	/** @format int64 */
	deliveryReviewDetailId?: number;
	productSku?: string;
}

/** DeliveryReviewDetailCreateReqDTO */
export interface DeliveryReviewDetailCreateReqDTO {
	compilationMethod?: string;
	/** @format int32 */
	deliveryNums?: number;
	/** @format int32 */
	depotDeliveryNums?: number;
	/** @format int32 */
	orderDeliveryNums?: number;
	orderNo?: string;
	productCategoryEnglishName?: string;
	productCategoryName?: string;
	productCode?: string;
	productDeclarationCode?: string;
	productImg?: string;
	productLink?: string;
	productMainImg?: string;
	productName?: string;
	productPrice?: number;
	productPriceJp?: number;
	productPropertiesName?: string;
	productSku?: string;
	productTitle?: string;
	/** @format int32 */
	productType?: number;
	productUsPrice?: number;
	shopProductSku?: string;
	thirdProductSku?: string;
}

/** DeliveryReviewDetailRespDTO */
export interface DeliveryReviewDetailRespDTO {
	/** 建议中国货代公司名称 */
	auditForwarder?: string;
	/**
	 * 建议中国货代公司id
	 * @format int64
	 */
	auditIntForwarderConfigId?: number;
	/** 异常原因 */
	auditReason?: string;
	/** 异常原因 */
	auditReasonCode?: string;
	/** 备注 */
	auditRemark?: string;
	/** 建议运输方式 */
	auditShippingType?: string;
	/** spu中国货代公司 */
	auditSpuForwarder?: string;
	/**
	 * spu中国货代公司ID
	 * @format int64
	 */
	auditSpuIntForwarderConfigId?: number;
	/** SPU审核原因 */
	auditSpuReasonCode?: string;
	/** SPU中国货代运输方式 */
	auditSpuShippingType?: string;
	/** @format int32 */
	beactive?: number;
	/** 编织方式+材质 */
	compilationMethod?: string;
	/**
	 * 确认类型
	 * @format int32
	 */
	confirmType?: number;
	/** @format date-time */
	createTime?: string;
	/** @format int64 */
	creater?: number;
	createrName?: string;
	/** 申报总额(人民币，小包必填) */
	customsPrice?: number;
	/** 申报总额(日元，小包必填) */
	customsPriceJp?: number;
	/** 申报总额(美元) */
	customsPriceUs?: number;
	/**
	 * 发货数
	 * @format int32
	 */
	deliveryNums?: number;
	/** 发货审单编号 */
	deliveryReviewCode?: string;
	/** 发货任务编号 */
	deliveryTaskCode?: string;
	/**
	 * 库房库存发货数
	 * @format int32
	 */
	depotDeliveryNums?: number;
	/** @format int64 */
	id?: number;
	/** @format date-time */
	lmt?: string;
	/** @format int64 */
	modifier?: number;
	modifierName?: string;
	/**
	 * 订单-采购发货数
	 * @format int32
	 */
	orderDeliveryNums?: number;
	/** 订单编号 */
	orderNo?: string;
	/** 平台订单编号(直行便订单编号) */
	platformOrderNo?: string;
	/** 商品品类英文名称 */
	productCategoryEnglishName?: string;
	/** 商品品类中文名称 */
	productCategoryName?: string;
	/** 平台商品SPU */
	productCode?: string;
	/** 商品报关编码 */
	productDeclarationCode?: string;
	/**
	 * 商品报关id
	 * @format int64
	 */
	productDeclarationId?: number;
	/** 商品图片 */
	productImg?: string;
	/** 商品链接 */
	productLink?: string;
	/** 主图 */
	productMainImg?: string;
	/** 商品名称 */
	productName?: string;
	/** 商品价格(rmb) */
	productPrice?: number;
	/** 申报单价(人民币，小包必填) */
	productPriceCn?: number;
	/** 日元价格 */
	productPriceJp?: number;
	/** 商品价格(美元)-仓库 */
	productPriceUs?: number;
	/** 商品规格(格式-颜色:粉色;尺码:XXXL) */
	productPropertiesName?: string;
	/** 商品sku */
	productSku?: string;
	/** 商品名称 */
	productTitle?: string;
	/**
	 * 商品类型
	 * @format int32
	 */
	productType?: number;
	/** 商品价格(美元) */
	productUsPrice?: number;
	/**
	 * 商品重量(g)
	 * @format int64
	 */
	productWeight?: number;
	/**
	 * 实际发货数
	 * @format int32
	 */
	realDeliveryNums?: number;
	/** 店铺商品sku */
	shopProductSku?: string;
	/**
	 * 状态： 0 正常 1 取消
	 * @format int32
	 */
	status?: number;
	/** 库存类型(0: 库房库存 1:订单采购库存) */
	stockType?: boolean;
	/** 外部店铺商品sku */
	thirdProductSku?: string;
	warehouse?: string;
}

/** DeliveryReviewInfoReqDTO */
export interface DeliveryReviewInfoReqDTO {
	/** 发货审单编号 */
	deliveryReviewCode?: string;
	/** 发货任务编号 */
	deliveryTaskCode?: string;
	/**
	 * 发货类型(0:大件fba 1:大件普通 2:小件)
	 * @format int32
	 */
	deliveryType?: number;
	/** 跳过发货审单编号 */
	notInDeliveryReviewCodes?: string[];
	/**
	 * 处理状态
	 * @format int32
	 */
	processingStatus?: number;
}

/** DeliveryReviewInfoRespDTO */
export interface DeliveryReviewInfoRespDTO {
	/** 审核日本配送公司名称（客户） */
	auditDistribution?: string;
	/**
	 * 审核日本配送公司id（客户）
	 * @format int64
	 */
	auditIntShipConfigId?: number;
	/**
	 * 审单类型: 0 保持不变 1 整单审核 2 按照SKU审核
	 * @format int32
	 */
	auditType?: number;
	/** 清关地址 */
	customsClearanceAddress?: CustomsClearanceAddressRespDTO;
	/** 发货审单编号 */
	deliveryReviewCode?: string;
	/** 审单SPU参数 */
	deliveryReviewSpuList?: DeliveryReviewSpuRespDTO[];
	/** 发货任务编号 */
	deliveryTaskCode?: string;
	/** 货代列表 */
	forwarderList?: ClientForwarderRespDTO[];
	/** 物流选择 */
	logisticsChoose?: LogisticsChooseRespDTO;
	receivingNationCode?: string;
	/** 收货地址 */
	shippingAddress?: ShippingAddressRespDTO;
}

/** DeliveryReviewPageApiRespDTO */
export interface DeliveryReviewPageApiRespDTO {
	auditDistribution?: string;
	auditForwarder?: string;
	/** @format int64 */
	auditIntForwarderConfigId?: number;
	auditReason?: string;
	auditReasonCode?: string;
	auditReasonJp?: string;
	/** @format date-time */
	createTime?: string;
	deliveryReviewCode?: string;
	deliveryTaskCode?: string;
	deliveryTitle?: string;
	/** @format int32 */
	deliveryType?: number;
	distribution?: string;
	forwarder?: string;
	skuList?: DeliveryReviewSkuApiRespDTO[];
	/** @format int32 */
	thirdWarehouseType?: number;
}

/** DeliveryReviewPageRespDTO */
export interface DeliveryReviewPageRespDTO {
	/** 发货审单信息 */
	deliveryReviewPage?: PageDeliveryReviewRespDTO;
	/** 处理状态总数 */
	processingStatusCount?: ProcessingStatusRespDTO;
}

/** DeliveryReviewProductCustomersReqDTO */
export interface DeliveryReviewProductCustomersReqDTO {
	compilationMethod?: string;
	customsCode?: string;
	deliveryReviewCode?: string;
	/** @format int64 */
	deliveryReviewDetailId?: number;
	/** @format int64 */
	productDeclarationId?: number;
	productItemCategoryEn?: string;
	productItemCategoryZh?: string;
	productSku?: string;
}

/** DeliveryReviewQueryReqDTO */
export interface DeliveryReviewQueryReqDTO {
	deliveryReviewCode?: string;
	platformOrderNo?: string;
	/** @format int32 */
	systemSource?: number;
}

/** DeliveryReviewQueryRespDTO */
export interface DeliveryReviewQueryRespDTO {
	deliveryReviewCode?: string;
	platformOrderNo?: string;
	/** @format int32 */
	processingStatus?: number;
}

/** DeliveryReviewReqDTO */
export interface DeliveryReviewReqDTO {
	/**
	 * 审核结束时间
	 * @format date-time
	 */
	auditEndTime?: string;
	/** 审核中国货代公司名称 */
	auditForwarder?: string;
	/**
	 * 审核人id
	 * @format int64
	 */
	auditId?: number;
	/**
	 * 审核中国货代公司id
	 * @format int64
	 */
	auditIntForwarderConfigId?: number;
	/** 审核人 */
	auditName?: string;
	/**
	 * 审核开始时间
	 * @format date-time
	 */
	auditStartTime?: string;
	/**
	 * 申报信息完善(null:全部 1:完善 2:不完善)
	 * @format int32
	 */
	completeReportingInfo?: number;
	/**
	 * 确认结束时间
	 * @format date-time
	 */
	confirmEndTime?: string;
	/**
	 * 确认开始时间
	 * @format date-time
	 */
	confirmStartTime?: string;
	/**
	 * 确认类型(0:未确认 1:接受 2:取消订单 3:拒绝按照原来方式)
	 * @format int32
	 */
	confirmType?: number;
	/**
	 * 创建结束时间
	 * @format date-time
	 */
	createEndTime?: string;
	/**
	 * 创建开始时间
	 * @format date-time
	 */
	createStartTime?: string;
	/** 客户店铺名称 */
	customerShopName?: string;
	/** 发货任务编号 */
	deliveryTaskCode?: string;
	/** 发货任务编号列表 */
	deliveryTaskCodeList?: string;
	/**
	 * 发货类型(0:大件fba 1:大件普通 2:小件)
	 * @format int32
	 */
	deliveryType?: number;
	/** 中国货代公司名称（客户） */
	forwarder?: string;
	/**
	 * 中国货代公司id（客户）
	 * @format int64
	 */
	intForwarderConfigId?: number;
	/**
	 * 可用MINI货代标志 0-否，1-是
	 * @format int32
	 */
	miniFlag?: number;
	/** @format int32 */
	pageNum?: number;
	/** @format int32 */
	pageSize?: number;
	/** 平台订单编号(直行便订单编号) */
	platformOrderNo?: string;
	/**
	 * 处理状态(0:待处理 1:待确认 2:已处理 3：异常)
	 * @format int32
	 */
	processingStatus?: number;
	/** 商品名称 */
	productName?: string;
	sorts?: ISortFieldEnum[];
	/**
	 * 状态(是否正常 0 正常 1 已取消)
	 * @format int32
	 */
	status?: number;
}

/** DeliveryReviewRespDTO */
export interface DeliveryReviewRespDTO {
	/** 审核日本配送公司名称（客户） */
	auditDistribution?: string;
	/** 审核中国货代公司名称 */
	auditForwarder?: string;
	/**
	 * 审核人id
	 * @format int64
	 */
	auditId?: number;
	/**
	 * 审核中国货代公司id
	 * @format int64
	 */
	auditIntForwarderConfigId?: number;
	/**
	 * 审核日本配送公司id（客户）
	 * @format int64
	 */
	auditIntShipConfigId?: number;
	/** 审核人 */
	auditName?: string;
	/** 审核原因 */
	auditReason?: string;
	/** 审核原因编码 */
	auditReasonCode?: string;
	/** 审核原因(日文) */
	auditReasonJp?: string;
	/** 审核备注 */
	auditRemark?: string;
	/** 审核运输方式 */
	auditShippingType?: string;
	/**
	 * 审核时间
	 * @format date-time
	 */
	auditTime?: string;
	/**
	 * 审核类型
	 * @format int32
	 */
	auditType?: number;
	/** @format int32 */
	beactive?: number;
	/** 渠道代码 */
	channelNo?: string;
	/**
	 * 确认时间
	 * @format date-time
	 */
	confirmTime?: string;
	/**
	 * 确认类型(0:未确认 1:接受 2:取消订单)
	 * @format int32
	 */
	confirmType?: number;
	/** @format date-time */
	createTime?: string;
	/** @format int64 */
	creater?: number;
	createrName?: string;
	/**
	 * 客户ID
	 * @format int64
	 */
	customerId?: number;
	/** 客户姓名 */
	customerName?: string;
	/**
	 * 客户店铺表id
	 * @format int64
	 */
	customerShopId?: number;
	/** 客户店铺名称 */
	customerShopName?: string;
	/**
	 * 发货总数
	 * @format int32
	 */
	deliveryNums?: number;
	/** 发货审单编号 */
	deliveryReviewCode?: string;
	/** 审单SPU列表参数 */
	deliveryReviewSpuList?: DeliveryReviewSpuRespDTO[];
	/** 发货任务编号 */
	deliveryTaskCode?: string;
	/** 发货标题 */
	deliveryTitle?: string;
	/**
	 * 发货类型(0:大件fba 1:大件普通 2:小件)
	 * @format int32
	 */
	deliveryType?: number;
	/** 日本配送公司名称（客户） */
	distribution?: string;
	/** 中国货代公司名称（客户） */
	forwarder?: string;
	/** @format int64 */
	id?: number;
	/**
	 * 中国货代公司id（客户）
	 * @format int64
	 */
	intForwarderConfigId?: number;
	/**
	 * 日本配送公司id（客户）
	 * @format int64
	 */
	intShipConfigId?: number;
	/** @format date-time */
	lmt?: string;
	/** 物流公司code */
	logisticsCompanyCode?: string;
	/** 物流公司账号-第三方 */
	logisticsCustomerAccount?: string;
	/** 物流公司用户名-第三方 */
	logisticsCustomerName?: string;
	/**
	 * 主账号id
	 * @format int64
	 */
	mainCustomerId?: number;
	/**
	 * 可用MINI货代标志 0-否，1-是
	 * @format int32
	 */
	miniFlag?: number;
	/** @format int64 */
	modifier?: number;
	modifierName?: string;
	/** 平台订单编号(直行便订单编号) */
	platformOrderNo?: string;
	/**
	 * 处理状态(0:待处理 1:待确认 2:已处理)
	 * @format int32
	 */
	processingStatus?: number;
	/** 收货国家编码 */
	receivingNationCode?: string;
	/** 收货国家 */
	receivingNationName?: string;
	/** 运输方式（客户） */
	shippingType?: string;
	/**
	 * 是否单品(0:多品 1:单品)
	 * @format int32
	 */
	singleProduct?: number;
	/**
	 * 状态： 0 正常 1 取消
	 * @format int32
	 */
	status?: number;
	/**
	 * 系统来源(1:D2C 2:B2B )
	 * @format int32
	 */
	systemSource?: number;
	/**
	 * 第三方仓库类型(B2B) 0-其他 1-fba 2-乐天 3-shoplist 4-zozotown
	 * @format int32
	 */
	thirdWarehouseType?: number;
	/** 统一客户全名 */
	unificationCustomerFullName?: string;
	/**
	 * 审单版本
	 * @format int32
	 */
	version?: number;
	warehouse?: string;
}

/** DeliveryReviewSkuApiRespDTO */
export interface DeliveryReviewSkuApiRespDTO {
	auditDistribution?: string;
	auditForwarder?: string;
	/** @format int64 */
	auditIntShipConfigId?: number;
	auditReason?: string;
	auditReasonCode?: string;
	auditReasonJp?: string;
	/** @format int32 */
	confirmType?: number;
	/** @format date-time */
	createTime?: string;
	/** @format int32 */
	deliveryNums?: number;
	/** @format int64 */
	deliveryReviewDetailId?: number;
	/** @format int64 */
	intShipConfigId?: number;
	productImg?: string;
	productName?: string;
	productPropertiesName?: string;
	productSku?: string;
	shopProductSku?: string;
}

/** DeliveryReviewSkuCancelReqDTO */
export interface DeliveryReviewSkuCancelReqDTO {
	productSku?: string;
	systemOrderNo?: string;
}

/** DeliveryReviewSkuEditReqDTO */
export interface DeliveryReviewSkuEditReqDTO {
	/** 审单建议国内货代公司 */
	auditForwarder?: string;
	/**
	 * 审单建议国内货代公司ID
	 * @format int64
	 */
	auditIntForwarderConfigId?: number;
	/** 审核原因 */
	auditReason?: string;
	/** 审核原因编码 */
	auditReasonCode?: string;
	/** 审核原因(日文) */
	auditReasonJp?: string;
	/** 审核备注 */
	auditRemark?: string;
	/** 发货审单编号 */
	deliveryReviewCode?: string;
	/**
	 * 审核明细ID
	 * @format int64
	 */
	deliveryReviewDetailId?: number;
	/**
	 * 版本
	 * @format int32
	 */
	version?: number;
}

/** DeliveryReviewSpuRespDTO */
export interface DeliveryReviewSpuRespDTO {
	/** 建议中国货代公司名称 */
	auditForwarder?: string;
	/**
	 * 建议中国货代公司id
	 * @format int64
	 */
	auditIntForwarderConfigId?: number;
	/** 异常原因 */
	auditReason?: string;
	/** 异常原因编码 */
	auditReasonCode?: string;
	/** 建议运输方式 */
	auditShippingType?: string;
	/** 审单详情参数 */
	deliveryReviewDetailList?: DeliveryReviewDetailRespDTO[];
	/** 平台商品SPU */
	productCode?: string;
	/** 主图 */
	productMainImg?: string;
	/** 商品名称 */
	productTitle?: string;
	/** @format int32 */
	productType?: number;
}

/** DeliverySkuDeductionRecordDto */
export interface DeliverySkuDeductionRecordDto {
	/** 库区 */
	actualRegionName?: string;
	/**
	 * 抵扣时间
	 * @format date-time
	 */
	deductionTime?: string;
	/** 货格码 */
	gridCode?: string;
	/**
	 * 数量
	 * @format int32
	 */
	nums?: number;
	/** 批次包 */
	pkgCode?: string;
}

/** DeliveryTagTemplateRespDto */
export interface DeliveryTagTemplateRespDto {
	/**
	 * 模板尺寸 1-40*80 2-50*30
	 * @format int32
	 */
	size?: number;
	/** 模板html */
	templateHtml?: string;
	/** 模板内容 */
	templateJson?: object[];
	/**
	 * 类型 0-其他条形码 1-fba 2-乐天 3-shoplist 4-zozotown
	 * @format int32
	 */
	type?: number;
}

/** DeliveryTaskAdditionalDetailConfirmDTO */
export interface DeliveryTaskAdditionalDetailConfirmDTO {
	/** @format int32 */
	additionConfigId?: number;
	/** @format int32 */
	additionId?: number;
	additionName?: string;
	additionNameJp?: string;
	/** @format int32 */
	calculateConfigId?: number;
	cost?: number;
	/** @format int32 */
	costType?: number;
	image?: string;
	ingredientsName?: string;
	ingredientsNameJp?: string;
	ingredientsSku?: string;
}

/** DeliveryTaskAdditionalDetailCreateDTO */
export interface DeliveryTaskAdditionalDetailCreateDTO {
	/** @format int32 */
	additionConfigId?: number;
	/** @format int32 */
	additionId?: number;
	additionName?: string;
	additionNameJp?: string;
	/** @format int32 */
	calculateConfigId?: number;
	cost?: number;
	/** @format int32 */
	costType?: number;
	image?: string;
	ingredientsName?: string;
	ingredientsNameJp?: string;
	ingredientsSku?: string;
}

/** DeliveryTaskAdditionalDetailRespDTO */
export interface DeliveryTaskAdditionalDetailRespDTO {
	/** @format int32 */
	additionConfigId?: number;
	/** @format int32 */
	additionId?: number;
	additionName?: string;
	additionNameJp?: string;
	/** @format int32 */
	calculateConfigId?: number;
	cost?: number;
	/** @format int32 */
	costType?: number;
	/** @format date-time */
	createTime?: string;
	/** @format int64 */
	creater?: number;
	/** @format int64 */
	deliveryTaskAdditionalDetailId?: number;
	deliveryTaskCode?: string;
	image?: string;
	ingredientsName?: string;
	ingredientsNameJp?: string;
	ingredientsSku?: string;
	/** @format date-time */
	lmt?: string;
	/** @format int64 */
	modifier?: number;
	/** @format int32 */
	useNums?: number;
}

/** DeliveryTaskAdditionalReqDTO */
export interface DeliveryTaskAdditionalReqDTO {
	/** 国际发货单任务号 */
	deliveryTaskCode: string;
}

/** DeliveryTaskAddressConfirmDTO */
export interface DeliveryTaskAddressConfirmDTO {
	address?: string;
	/** @format int32 */
	addressType?: number;
	area?: string;
	city?: string;
	clearanceCode?: string;
	/** @format int32 */
	clearanceType?: number;
	clubCode?: string;
	code?: string;
	companyName?: string;
	email?: string;
	mobile?: string;
	name?: string;
	nation?: string;
	postalCode?: string;
	province?: string;
}

/** DeliveryTaskAddressCreateDTO */
export interface DeliveryTaskAddressCreateDTO {
	address?: string;
	/** @format int32 */
	addressType?: number;
	area?: string;
	city?: string;
	clearanceCode?: string;
	/** @format int32 */
	clearanceType?: number;
	clubCode?: string;
	code?: string;
	companyName?: string;
	email?: string;
	mobile?: string;
	name?: string;
	nation?: string;
	postalCode?: string;
	province?: string;
}

/** DeliveryTaskAddressRespDTO */
export interface DeliveryTaskAddressRespDTO {
	address?: string;
	/** @format int32 */
	addressType?: number;
	area?: string;
	city?: string;
	/** @format int32 */
	clearanceType?: number;
	clubCode?: string;
	companyName?: string;
	email?: string;
	mobile?: string;
	name?: string;
	nation?: string;
	postalCode?: string;
	province?: string;
}

/** DeliveryTaskAllotDetailRespDTO */
export interface DeliveryTaskAllotDetailRespDTO {
	/**
	 * 配货数
	 * @format int32
	 */
	allotNums?: number;
	/**
	 * 分配状态(0:未分配 1:已分配 2:已完成)
	 * @format int32
	 */
	allotStatus?: number;
	/** 篮子编号 */
	basketCode?: string;
	/**
	 * 创建时间
	 * @format date-time
	 */
	createTime?: string;
	/**
	 * 创建人
	 * @format int64
	 */
	creater?: number;
	/** 发货任务编号 */
	deliveryTaskCode?: string;
	/**
	 * 最新修改时间
	 * @format date-time
	 */
	lmt?: string;
	/**
	 * 修改人
	 * @format int64
	 */
	modifier?: number;
	/** 拣货任务编号 */
	pickTaskCode?: string;
	/** 批次包编号 */
	pkgCode?: string;
	/** 商品图片 */
	productImg?: string;
	/** 店铺商品sku */
	shopProductSku?: string;
}

/** DeliveryTaskAmountReq */
export interface DeliveryTaskAmountReq {
	/**
	 * 客户ID
	 * @format int64
	 */
	customerId?: number;
	/**
	 * 发货类型(0:大件fba 1:大件普通 2:小件 3:次品退货 4:异常退货 5:仓库调拨)
	 * @format int32
	 */
	deliveryType?: number;
}

/** DeliveryTaskAmountResp */
export interface DeliveryTaskAmountResp {
	/**
	 * 客户ID
	 * @format int64
	 */
	customerId?: number;
	/** 客户编码 */
	customerName?: string;
	/**
	 * 客户待发货单总量
	 * @format int32
	 */
	deliveryTaskTotalAmount?: number;
}

/** DeliveryTaskBoxLogisticsEditReq */
export interface DeliveryTaskBoxLogisticsEditReq {
	/** 发货箱编号 */
	boxCode?: string;
	/** 国际发货单号 */
	deliveryTaskCode?: string;
	/** 内部关联编码 */
	insidePackCode?: string;
	/** 物流单号 */
	logisticsCode?: string;
}

/** DeliveryTaskBoxLogisticsReq */
export interface DeliveryTaskBoxLogisticsReq {
	/** 国际发货单号 */
	deliveryTaskCodes?: string[];
}

/** DeliveryTaskBoxLogisticsResp */
export interface DeliveryTaskBoxLogisticsResp {
	/** 发货箱编码 */
	boxCode?: string;
	/** 国际发货单编号 */
	deliveryTaskCode?: string;
	/** 内部关联编码 */
	insidePackCode?: string;
	/** 物流单号 */
	logisticsCode?: string;
	/**
	 * 重量(单位g)
	 * @format int64
	 */
	weight?: number;
}

/** DeliveryTaskDetailRespDTO */
export interface DeliveryTaskDetailRespDTO {
	/** @format int32 */
	afterSalesStatus?: number;
	amazonAsin?: string;
	amazonFnsku?: string;
	amazonMsku?: string;
	amazonProductTitle?: string;
	barCodeName?: string;
	barCodeUrl?: string;
	/** @format int32 */
	checked?: number;
	coding?: string;
	commodityName?: string;
	commoditySku?: string;
	compilationMethod?: string;
	/** @format date-time */
	createTime?: string;
	/** @format int64 */
	creater?: number;
	customerProductMsku?: string;
	customsPrice?: number;
	customsPriceJp?: number;
	/** @format int32 */
	deductionNums?: number;
	/** @format int32 */
	defectiveAmount?: number;
	/** @format int32 */
	deliveryNums?: number;
	deliveryTaskCode?: string;
	/** @format int64 */
	deliveryTaskDetailId?: number;
	/** @format int32 */
	depotDeliveryNums?: number;
	/** @format int32 */
	inNums?: number;
	/** @format date-time */
	lmt?: string;
	/** @format int64 */
	modifier?: number;
	/** @format int32 */
	orderDeliveryNums?: number;
	orderNo?: string;
	pkgCodeList?: string[];
	productCategoryEnglishName?: string;
	productCategoryName?: string;
	productDeclarationCode?: string;
	productEnglishName?: string;
	productImg?: string;
	productLink?: string;
	productName?: string;
	productPrice?: number;
	productPriceJp?: number;
	productPropertiesName?: string;
	productSku?: string;
	productUsPrice?: number;
	/** @format int32 */
	realDeliveryNums?: number;
	receiveHouse?: string;
	remark?: string;
	shipmentId?: string;
	shipmentName?: string;
	shopProductSku?: string;
	tagContent?: string;
	thirdProductSku?: string;
	warehouse?: string;
}

/** DeliveryTaskExtendDTO */
export interface DeliveryTaskExtendDTO {
	branchCode?: string;
	declarationDiscount?: number;
	deliveryTaskCode?: string;
	/** @format int64 */
	deliveryTaskExtendId?: number;
	financialInstitutionCode?: string;
	importerCode?: string;
	invoicRemark?: string;
	managerConfirmOperator?: string;
	/** @format date-time */
	managerConfirmTime?: string;
	managerRemark?: string;
	managerRemarkImgs?: string;
	managerRemarkOperator?: string;
	/** @format int32 */
	priceDeclarationType?: number;
	/** @format int32 */
	tariffType?: number;
	taxDeductionAccount?: string;
}

/** DeliveryTaskInvoiceDTO */
export interface DeliveryTaskInvoiceDTO {
	address?: string;
	area?: string;
	areaCode?: string;
	/** 箱子明细 */
	boxDetail?: LogisticsBoxDetailDTO[];
	/** 分行代码 */
	branchCode?: string;
	city?: string;
	clubCode?: string;
	code?: string;
	companyName?: string;
	/** @format date-time */
	createTime?: string;
	/** @format int64 */
	creatorId?: number;
	creatorName?: string;
	/** @format int64 */
	customerId?: number;
	customerName?: string;
	deliveryTaskCode?: string;
	/** @format date-time */
	deliveryTime?: string;
	/** @format int32 */
	deliveryType?: number;
	/** @format int64 */
	editorId?: number;
	editorName?: string;
	email?: string;
	/** 金融机构代码 */
	financialInstitutionCode?: string;
	forwarder?: string;
	/** @format int64 */
	id?: number;
	/** 进口商标准代码 */
	importerCode?: string;
	/** @format int64 */
	intForwarderConfigId?: number;
	invoicRemark?: string;
	invoiceOssUrl?: string;
	invoiceTemplateCode?: string;
	invoiceTemplateName?: string;
	/** @format int32 */
	isDelete?: number;
	logisticsCode?: string;
	mobile?: string;
	name?: string;
	nation?: string;
	/** @format int32 */
	offlineFlag?: number;
	/** @format int64 */
	operatorId?: number;
	operatorName?: string;
	/** @format date-time */
	operatorTime?: string;
	postalCode?: string;
	/** @format int32 */
	priceDeclarationType?: number;
	province?: string;
	/** @format int32 */
	realDeliveryNums?: number;
	shippingType?: string;
	skuList?: DeliveryTaskInvoiceItemDTO[];
	/** @format int32 */
	status?: number;
	/** 扣税账号 */
	taxDeductionAccount?: string;
	/**
	 * 总箱数
	 * @format int32
	 */
	totalBoxNum?: number;
	/** @format date-time */
	updateTime?: string;
	warehouse?: string;
	wmsDistribution?: string;
	wmsForwarder?: string;
	/** @format int64 */
	wmsIntForwarderConfigId?: number;
	/** @format int64 */
	wmsIntShipConfigId?: number;
	wmsShippingType?: string;
}

/** DeliveryTaskInvoiceItemDTO */
export interface DeliveryTaskInvoiceItemDTO {
	deliveryTaskCode?: string;
	productImg?: string;
	productLink?: string;
	productName?: string;
	productSku?: string;
	/** @format int32 */
	realDeliveryNums?: number;
	shopProductSku?: string;
}

/** DeliveryTaskInvoicePageQuery */
export interface DeliveryTaskInvoicePageQuery {
	/** @format date-time */
	createEndTime?: string;
	/** @format date-time */
	createStartTime?: string;
	/** @format date-time */
	deliveryEndTime?: string;
	/** @format date-time */
	deliveryStartTime?: string;
	deliveryTaskCode?: string;
	deliveryTaskCodeList?: string[];
	/** @format int32 */
	deliveryType?: number;
	/** @format int64 */
	forwarderConfigId?: number;
	/** @format int32 */
	invoiceStatus?: number;
	logisticsCode?: string;
	operatorName?: string;
	/** @format int32 */
	pageNum?: number;
	/** @format int32 */
	pageSize?: number;
	productSku?: string;
	shopProductSku?: string;
	sorts?: ISortFieldEnum[];
	warehouse?: string;
	wmsForwarder?: string;
}

/** DeliveryTaskLogisticsDetailReqDTO */
export interface DeliveryTaskLogisticsDetailReqDTO {
	/** 发货任务编号 */
	deliveryTaskCode?: string;
	/**
	 * 发货任务物流信息ID
	 * @format int64
	 */
	deliveryTaskLogisticsDetailId?: number;
	/** 内部打包编号 */
	insidePackCode?: string;
	/** 物流单号 */
	logisticsCode?: string;
	/** 物流公司code */
	logisticsCompanyCode?: string;
	/** 物流标签地址 */
	logisticsTagUrl?: string;
	/**
	 * 包裹数(小件打包传递)
	 * @format int32
	 */
	packageNums?: number;
}

/** DeliveryTaskLogisticsDetailRespDTO */
export interface DeliveryTaskLogisticsDetailRespDTO {
	/** @format int64 */
	customerId?: number;
	customerName?: string;
	/** @format int64 */
	customerShopId?: number;
	customerShopName?: string;
	deliveryBoxList?: DeliveryBoxRespDTO[];
	deliveryTaskCode?: string;
	/** @format int64 */
	deliveryTaskLogisticsDetailId?: number;
	/** @format date-time */
	deliveryTime?: string;
	finalLogisticsCode?: string;
	freightFee?: number;
	insidePackCode?: string;
	logisticsCode?: string;
	logisticsCompanyCode?: string;
	logisticsTagUrl?: string;
	/** @format int64 */
	mainCustomerId?: number;
	/** @format int32 */
	packageNums?: number;
	platformOrderNo?: string;
	priceLimit?: number;
	receivingNationCode?: string;
	receivingNationName?: string;
	remoteAreaFee?: number;
	/** @format int32 */
	systemSource?: number;
	unificationCustomerFullName?: string;
	valuationWeight?: number;
	weightLimit?: number;
	/** @format int64 */
	wmsIntShipConfigId?: number;
}

/** DeliveryTaskOperationVO */
export interface DeliveryTaskOperationVO {
	/** 业务内容 */
	bizContent?: string;
	/** 业务编号 */
	bizNo?: string;
	/**
	 * 创建日期
	 * @format date-time
	 */
	createTime?: string;
	/**
	 * 创建人ID
	 * @format int64
	 */
	creatorId?: number;
	/** 创建人名称 */
	creatorName?: string;
	/** 发货任务编号 */
	deliveryTaskCode?: string;
	/**
	 * 修改人ID
	 * @format int64
	 */
	editorId?: number;
	/** 修改人名称 */
	editorName?: string;
	/**
	 * 主键id
	 * @format int64
	 */
	id?: number;
	/**
	 * 是否逻辑删除(0:未删除 1:已删除)
	 * @format int32
	 */
	isDelete?: number;
	/** 节点名称 */
	nodeName?: string;
	/**
	 * 节点类型(1:创建 2:审单 3:生成拣货任务 4:拣货完成 5:分拣完成 6:打标完成 7:更换货代 8:生成物流单号 9:确认发货 10:更新数据 11:下载EXCEL 12:上传EXCEL 13:同步并下载箱唛 14:拆包)
	 * @format int32
	 */
	nodeType?: number;
	/**
	 * 修改时间
	 * @format date-time
	 */
	updateTime?: string;
	/** 仓库 */
	warehouse?: string;
}

/** DeliveryTaskPdaConfirmReq */
export interface DeliveryTaskPdaConfirmReq {
	/** 物流单号 */
	logisticsCode?: string;
}

/** DeliveryTaskPdaConfirmResp */
export interface DeliveryTaskPdaConfirmResp {
	/** 国际发货单号 */
	deliveryTaskCode?: string;
	/** 提示信息 */
	message?: string;
}

/** DeliveryTaskTrackDTO */
export interface DeliveryTaskTrackDTO {
	/**
	 * 时间
	 * @format date-time
	 */
	date?: string;
	/** 轨迹信息 */
	message?: string;
}

/** DeliveryTaskTrackRespDTO */
export interface DeliveryTaskTrackRespDTO {
	/** 发货任务编号 */
	deliveryTaskCode?: string;
	/**
	 * 发货类型(0:大件fba 1:大件普通 2:小件 3:次品退货 4:异常退货 5:仓库调拨)
	 * @format int32
	 */
	deliveryType?: number;
	/** 物流单号 */
	logisticsCode?: string;
	/** 运输方式 */
	shippingType?: string;
	/** 物流轨迹 */
	taskTrackDTOList?: DeliveryTaskTrackDTO[];
}

/** DeliveryWaveBatchVO */
export interface DeliveryWaveBatchVO {
	/** 批次编码 */
	batchCode?: string;
	/**
	 * 拣货批次状态:0 待领取 1 待拣货 2 拣货中 4 拣货完成
	 * @format int32
	 */
	batchStatus?: number;
	/**
	 * 创建时间
	 * @format date-time
	 */
	createTime?: string;
	/** 创建者名称 */
	creatorName?: string;
	/**
	 * 拣货开始时间
	 * @format date-time
	 */
	finishTime?: string;
	/**
	 * 库位数量
	 * @format int32
	 */
	gridAmount?: number;
	/** 拣货人名称 */
	operatorName?: string;
	/**
	 * 商品数量
	 * @format int32
	 */
	productAmount?: number;
	/**
	 * 拣货开始时间
	 * @format date-time
	 */
	startTime?: string;
}

/** DeliveryWaveCreateReq */
export interface DeliveryWaveCreateReq {
	/** 指定国际发货单编码 */
	conditionDeliveryTaskCodes?: string[];
	/**
	 * 条件筛选：截至时间
	 * @format date-time
	 */
	conditionEndTime?: string;
	/**
	 * 条件筛选：开始时间
	 * @format date-time
	 */
	conditionStartTime?: string;
	/**
	 * 客户ID
	 * @format int64
	 */
	customerId?: number;
	/** 客户店铺名称 */
	customerShopName?: string;
	/**
	 * 发货单上限
	 * @format int32
	 */
	deliveryTaskLimit?: number;
	/**
	 * 发货单商品数量限制
	 * @format int32
	 */
	deliveryTaskProductLimit?: number;
	/**
	 * 发货类型(0:大件fba 1:大件普通 2:小件)
	 * @format int32
	 */
	deliveryType?: number;
	/**
	 * 是否包含高架货位
	 * @format int32
	 */
	hasHighLocation?: number;
	/** 物流公司id列表 */
	intForwarderConfigIdList?: number[];
	/**
	 * 拣货任务最大商品数量,超限单独生成任务
	 * @format int32
	 */
	pickTaskDeliveryProductLimit?: number;
	/**
	 * 拣货任务批次发货单上限
	 * @format int32
	 */
	pickTaskLimit?: number;
	/** 库区 */
	regionCodeList?: string[];
	/**
	 * 是否单品(0:多品 1:单品)
	 * @format int32
	 */
	singleProduct?: number;
}

/** DeliveryWaveDetailResp */
export interface DeliveryWaveDetailResp {
	/** 指定国际发货单 */
	conditionDeliveryTaskCodes?: string;
	/**
	 * 条件筛选：截至时间
	 * @format date-time
	 */
	conditionEndTime?: string;
	/**
	 * 用户ID
	 * @format int64
	 */
	customerId?: number;
	/** 用户名称 */
	customerName?: string;
	/** 客户店铺名称 */
	customerShopName?: string;
	/**
	 * 发货单上限
	 * @format int32
	 */
	deliveryTaskLimit?: number;
	/**
	 * 发货单商品上限
	 * @format int32
	 */
	deliveryTaskProductLimit?: number;
	/**
	 * 发货类型(0:大件fba 1:大件普通 2:小件)
	 * @format int32
	 */
	deliveryType?: number;
	/** 物流公司配置ID列表 */
	intForwarderConfigIdList?: number[];
	/** 物流公司集合 */
	intForwarderNames?: string[];
	/**
	 * 拣货任务最大商品数限制
	 * @format int32
	 */
	pickTaskDeliveryProductLimit?: number;
	/**
	 * 拣货任务批次发货单上限
	 * @format int32
	 */
	pickTaskLimit?: number;
	/** 库区集合 */
	regionNames?: string[];
	/**
	 * 是否单品(0:多品 1:单品)
	 * @format int32
	 */
	singleProduct?: number;
	/** 波次编码 */
	waveCode?: string;
}

/** DeliveryWaveMatchReq */
export interface DeliveryWaveMatchReq {
	/** 指定国际发货单编码 */
	conditionDeliveryTaskCodes?: string[];
	/**
	 * 条件筛选：截至时间
	 * @format date-time
	 */
	conditionEndTime?: string;
	/**
	 * 条件筛选：开始时间
	 * @format date-time
	 */
	conditionStartTime?: string;
	/**
	 * 客户ID
	 * @format int64
	 */
	customerId?: number;
	/** 客户店铺名称 */
	customerShopName?: string;
	/**
	 * 发货单上限
	 * @format int32
	 */
	deliveryTaskLimit?: number;
	/**
	 * 发货单商品数量限制
	 * @format int32
	 */
	deliveryTaskProductLimit?: number;
	/**
	 * 发货类型(0:大件fba 1:大件普通 2:小件)
	 * @format int32
	 */
	deliveryType?: number;
	/**
	 * 是否包含高架货位
	 * @format int32
	 */
	hasHighLocation?: number;
	/** 物流公司id列表 */
	intForwarderConfigIdList?: number[];
	/**
	 * 拣货任务批次发货单上限
	 * @format int32
	 */
	pickTaskLimit?: number;
	/** 库区 */
	regionCodes?: string[];
	/**
	 * 是否单品(0:多品 1:单品)
	 * @format int32
	 */
	singleProduct?: number;
}

/** DeliveryWaveMatchResp */
export interface DeliveryWaveMatchResp {
	/**
	 * 匹配到的国际发货单总量
	 * @format int32
	 */
	matchTotal?: number;
	/**
	 * 待处理国际发货单总量
	 * @format int32
	 */
	total?: number;
}

/** DeliveryWavePageReq */
export interface DeliveryWavePageReq {
	/** 拣货批次编码 */
	batchCode?: string;
	/** @format int32 */
	pageNum?: number;
	/** @format int32 */
	pageSize?: number;
	/**
	 *  空 全部， 0 未完成 1 已完成
	 * @format int32
	 */
	pickStatus?: number;
	/** 批次包编码 */
	pkgCode?: string;
	/** 订单编码 */
	platformOrderNo?: string;
	sorts?: ISortFieldEnum[];
}

/** DeliveryWavePageResp */
export interface DeliveryWavePageResp {
	/**
	 * 批次数量
	 * @format int32
	 */
	batchAmount?: number;
	/**
	 * 创建时间
	 * @format date-time
	 */
	createTime?: string;
	/** 创建者名称 */
	creatorName?: string;
	/** 拣货批次列表 */
	deliveryWaveBatchList?: DeliveryWaveBatchVO[];
	/**
	 * 订单数量
	 * @format int32
	 */
	orderAmount?: number;
	/**
	 * 拣货完成状态: 0 未完成 1 已完成
	 * @format int32
	 */
	pickStatus?: number;
	/**
	 * 运行状态: 0 待运行 1 运行中 2 异常 3 完成
	 * @format int32
	 */
	runStatus?: number;
	/**
	 * 状态:0 正常 1 撤销
	 * @format int32
	 */
	status?: number;
	/** 波次编码 */
	waveCode?: string;
}

/** DiscardDomesticReqDTO */
export interface DiscardDomesticReqDTO {
	/** @format int64 */
	abnormalOrderDealId?: number;
	flowCode?: string;
}

/** DomesticDeliverDTO */
export interface DomesticDeliverDTO {
	/** 详细地址 */
	address?: string;
	/** 区/县 */
	area?: string;
	/**
	 * 删除标志，0-已删除，1-未删除
	 * @format int32
	 */
	beactive?: number;
	/** 市 */
	city?: string;
	/**
	 * 创建时间
	 * @format date-time
	 */
	createTime?: string;
	/**
	 * 创建人
	 * @format int64
	 */
	creater?: number;
	/** 创建人名称 */
	createrName?: string;
	/**
	 * 发货数量
	 * @format int32
	 */
	deliveryNum?: number;
	/** 发货任务编号 */
	deliveryTaskCode?: string;
	/**
	 * 发货时间
	 * @format date-time
	 */
	deliveryTime?: string;
	/**
	 * 国内发货单状态，0:待处理;1:处理中,2:已发货,3:无法退货
	 * @format int32
	 */
	domesticDeliveryStatus?: number;
	/** 流程编码 */
	flowCode?: string;
	/** 货格码 */
	gridCode?: string;
	/**
	 * 主键id
	 * @format int64
	 */
	id?: number;
	/**
	 * 最新修改时间
	 * @format date-time
	 */
	lmt?: string;
	/** 物流单号 */
	logisticsNo?: string;
	/** 电话号 */
	mobile?: string;
	/**
	 * 修改人
	 * @format int64
	 */
	modifier?: number;
	/** 修改人名称 */
	modifierName?: string;
	/** 订单编号 */
	orderCode?: string;
	/** 批次包编号 */
	pkgCode?: string;
	/** 商品图片url链接 */
	productImgUrl?: string;
	/** 商品名称 */
	productName?: string;
	/** 省 */
	province?: string;
	/** 采购单异常编号 */
	purchaseAbnormalNo?: string;
	/** 采购单编号 */
	purchaseNo?: string;
	/** 收货人 */
	receiver?: string;
	/** 库区 */
	reservoirArea?: string;
	/**
	 * 退货截止时间
	 * @format date-time
	 */
	returnDeadlineTime?: string;
	/** 仓库 */
	warehouse?: string;
}

/** DomesticDeliveryPrintPickingRespDTO */
export interface DomesticDeliveryPrintPickingRespDTO {
	/**
	 * 发货数量
	 * @format int32
	 */
	deliveryNum?: number;
	/** 发货任务编号 */
	deliveryTaskCode?: string;
	/** 货格码 */
	gridCode?: string;
	/** 批次包编号 */
	pkgCode?: string;
}

/** DomesticDeliveryRespDTO */
export interface DomesticDeliveryRespDTO {
	/** @format int32 */
	domesticDeliveryStatus?: number;
	flowCode?: string;
}

/** DomesticModifyAddressRespDTO */
export interface DomesticModifyAddressRespDTO {
	/** 新详细地址 */
	address?: string;
	/** 发货任务编号 */
	deliveryTaskCode?: string;
	/** 新电话号 */
	mobile?: string;
	/** 原详细地址 */
	originalAddress?: string;
	/** 原电话号 */
	originalMobile?: string;
	/** 原收货人 */
	originalReceiver?: string;
	/** 批次包编号 */
	pkgCode?: string;
	/** 新收货人 */
	receiver?: string;
}

/** DownloadPackingListReqDTO */
export interface DownloadPackingListReqDTO {
	/**
	 * 发货任务编号集合
	 * @uniqueItems true
	 */
	deliveryTaskCodeList?: string[];
}

/** EditBarCodeReqDTO */
export interface EditBarCodeReqDTO {
	/** sku条形码文件名 */
	barCodeName?: string;
	/** sku条形码文件url */
	barCodeUrl?: string;
	/** 发货任务编号 */
	deliveryTaskCode?: string;
	/**
	 * 发货任务详情ID
	 * @format int64
	 */
	deliveryTaskDetailId?: number;
}

/** EditBoxTemplateReqDTO */
export interface EditBoxTemplateReqDTO {
	/**
	 * 模板ID
	 * @format int64
	 */
	boxTemplateId?: number;
	/**
	 * 高
	 * @format int32
	 */
	height: number;
	/**
	 * 长
	 * @format int32
	 */
	length: number;
	/** 备注 */
	remark?: string;
	/**
	 * 库存数量
	 * @format int32
	 */
	stock?: number;
	/** 模板名称 */
	templateName: string;
	/**
	 * 宽
	 * @format int32
	 */
	width: number;
}

/** EditDeliverTaskAdditionalDetailReqDTO */
export interface EditDeliverTaskAdditionalDetailReqDTO {
	/**
	 * 发货附加项id
	 * @format int64
	 */
	deliveryTaskAdditionalDetailId: number;
	/** 辅料名称 */
	ingredientsName?: string;
	/** 辅料名称-日文 */
	ingredientsNameJp?: string;
	/** 辅料sku */
	ingredientsSku?: string;
}

/** EditInvoicRemarkReqDTO */
export interface EditInvoicRemarkReqDTO {
	deliveryTaskCode?: string;
	invoicRemark?: string;
}

/** EditLabelTemplateReqDTO */
export interface EditLabelTemplateReqDTO {
	/** 标签名称 */
	label?: string;
	/** 模板样式HTML */
	labelHtml?: string;
	/** 模板参数JSON */
	labelInfo?: string;
	/**
	 * id
	 * @format int64
	 */
	labelTemplateId?: number;
	/**
	 * 标签类型：1-标签 2-批次包
	 * @format int32
	 */
	type?: number;
}

/** EditLogisticsNoDTO */
export interface EditLogisticsNoDTO {
	/** 发货任务编号 */
	deliveryTaskCode?: string;
	/** 物流单号 */
	logisticsCode?: string;
}

/** EditManagerRemarkReqDTO */
export interface EditManagerRemarkReqDTO {
	deliveryTaskCode?: string;
	managerRemark?: string;
	managerRemarkImgs?: string;
}

/** EditPrintReqDTO */
export interface EditPrintReqDTO {
	/**
	 * 方向(1:纵向 2:横向)
	 * @format int32
	 */
	direction?: number;
	/**
	 * 主键id
	 * @format int64
	 */
	id?: number;
	/** MAC地址 */
	macAddress?: string;
	/** 节点名称 */
	nodeName?: string;
	/**
	 * 节点类型(1:简单检品打印 2:水洗标打印)
	 * @format int32
	 */
	nodeType?: number;
	/** 打印纸尺寸(高) */
	printPaperSizeHigh?: string;
	/** 打印纸尺寸(宽) */
	printPaperSizeWide?: string;
	/** 打印机 */
	printer?: string;
	/**
	 * 系统来源(1:D2C 2:B2B)
	 * @format int32
	 */
	systemSource?: number;
}

/** EditTemplateTemplateReqDTO */
export interface EditTemplateTemplateReqDTO {
	/**
	 * 主键id
	 * @format int64
	 */
	id?: number;
	/** 模板编码 */
	templateCode?: string;
	/** 模板名称 */
	templateName?: string;
}

/** EditWeightCostConfigReqDTO */
export interface EditWeightCostConfigReqDTO {
	/**
	 * 类型：1-容器，2-箱子
	 * @format int32
	 */
	boxType: number;
	/**
	 * 发货类型:0-FBA发货，1-大件普通发货，2-小件普通发货
	 * @format int32
	 */
	deliverType: number;
	/** 物流公司code（OCS、YD、TH、SKB） */
	logisticsCompanyCode?: string;
	/** 美元限制 */
	priceLimit: number;
	/**
	 * 系统来源: 1-D2C; 2-B2B
	 * @format int32
	 */
	systemSource: number;
	/**
	 * 主键id
	 * @format int64
	 */
	weightCostConfigId?: number;
	/** 重量限制 */
	weightLimit: number;
}

/** ExportDeliveryReviewReqDTO */
export interface ExportDeliveryReviewReqDTO {
	/**
	 * 审核结束时间
	 * @format date-time
	 */
	auditEndTime?: string;
	/** 审核中国货代公司名称 */
	auditForwarder?: string;
	/**
	 * 审核人id
	 * @format int64
	 */
	auditId?: number;
	/**
	 * 审核中国货代公司id
	 * @format int64
	 */
	auditIntForwarderConfigId?: number;
	/** 审核人 */
	auditName?: string;
	/**
	 * 审核开始时间
	 * @format date-time
	 */
	auditStartTime?: string;
	/**
	 * 申报信息完善(null:全部 1:完善 2:不完善)
	 * @format int32
	 */
	completeReportingInfo?: number;
	/**
	 * 确认结束时间
	 * @format date-time
	 */
	confirmEndTime?: string;
	/**
	 * 确认开始时间
	 * @format date-time
	 */
	confirmStartTime?: string;
	/**
	 * 确认类型(0:未确认 1:接受 2:取消订单)
	 * @format int32
	 */
	confirmType?: number;
	/**
	 * 创建结束时间
	 * @format date-time
	 */
	createEndTime?: string;
	/**
	 * 创建开始时间
	 * @format date-time
	 */
	createStartTime?: string;
	/** 客户店铺名称 */
	customerShopName?: string;
	/** 发货任务编号 */
	deliveryTaskCode?: string;
	/** 发货任务编号列表 */
	deliveryTaskCodeList?: string;
	/**
	 * 发货类型(0:大件fba 1:大件普通 2:小件)
	 * @format int32
	 */
	deliveryType?: number;
	/** 中国货代公司名称（客户） */
	forwarder?: string;
	/**
	 * 中国货代公司id（客户）
	 * @format int64
	 */
	intForwarderConfigId?: number;
	/**
	 * 可用MINI货代标志 0-否，1-是
	 * @format int32
	 */
	miniFlag?: number;
	/** @format int32 */
	pageNum?: number;
	/** @format int32 */
	pageSize?: number;
	/** 平台订单编号(直行便订单编号) */
	platformOrderNo?: string;
	/**
	 * 处理状态(0:待处理 1:待确认 2:已处理)
	 * @format int32
	 */
	processingStatus?: number;
	/** 商品名称 */
	productName?: string;
	sorts?: ISortFieldEnum[];
	/**
	 * 状态 0 正常 1 取消
	 * @format int32
	 */
	status?: number;
}

/** ExportRecordDTO */
export interface ExportRecordDTO {
	/**
	 * 删除标志，0-已删除，1-未删除
	 * @format int32
	 */
	beactive?: number;
	/** 文件编号 */
	code?: string;
	/**
	 * 创建时间
	 * @format date-time
	 */
	createTime?: string;
	/**
	 * 创建人
	 * @format int64
	 */
	creater?: number;
	/** 创建人名称 */
	createrName?: string;
	/**
	 * id
	 * @format int64
	 */
	id?: number;
	/**
	 * 最新修改时间
	 * @format date-time
	 */
	lmt?: string;
	/** 失败原因 */
	message?: string;
	/**
	 * 修改人
	 * @format int64
	 */
	modifier?: number;
	/** 修改人名称 */
	modifierName?: string;
	/** 文件名 */
	name?: string;
	/**
	 * 导出条数
	 * @format int32
	 */
	nums?: number;
	/** 参数原始JSON */
	paramsJson?: string;
	/** 参数解析文本 */
	paramsText?: string;
	/**
	 * 导出状态 0-生成中 1-成功 2-失败
	 * @format int32
	 */
	status?: number;
	/**
	 * 耗时/秒
	 * @format int64
	 */
	time?: number;
	/** 文件类型 */
	type?: string;
	/** 类型名称 */
	typeName?: string;
	/** 文件链接 */
	url?: string;
	/** 仓库 */
	warehouse?: string;
}

/** ExportRecordReqDTO */
export interface ExportRecordReqDTO {
	/**
	 * 创建结束时间
	 * @format date-time
	 */
	createTimeEnd?: string;
	/**
	 * 创建开始时间
	 * @format date-time
	 */
	createTimeStart?: string;
	/** 创建人/导出人 */
	createrName?: string;
	/** @format int32 */
	pageNum?: number;
	/** @format int32 */
	pageSize?: number;
	sorts?: ISortFieldEnum[];
	/**
	 * 导出状态 0-生成中 1-成功 2-失败
	 * @format int32
	 */
	status?: number;
}

/** ExportTrackReq */
export interface ExportTrackReq {
	/**
	 * 创建结束时间
	 * @format date-time
	 */
	createEndTime?: string;
	/**
	 * 创建开始时间
	 * @format date-time
	 */
	createStartTime?: string;
	/**
	 * 发货结束时间
	 * @format date-time
	 */
	deliveryEndTime?: string;
	/**
	 * 发货开始时间
	 * @format date-time
	 */
	deliveryStartTime?: string;
	/** 发货单号 */
	deliveryTaskCode?: string;
	/** 异常类型：出运异常、通关异常、派送异常 */
	errType?: string;
	/** 物流单号 */
	logisticsCode?: string;
	/** 物流单号 */
	logisticsCodeList?: string[];
	/** 物流状态 */
	logisticsStatus?: string;
	/**
	 * 发货类型(1:大件 2:小件)
	 * @format int32
	 */
	packageType?: number;
	/** 平台订单编号 */
	platformOrderNo?: string;
	/**
	 * 状态 0-进行中 1-异常 2-完结
	 * @format int32
	 */
	status?: number;
	/** 货代公司 */
	wmsForwarder?: string;
	/**
	 * 实际发货的中国货代公司id
	 * @format int64
	 */
	wmsIntForwarderConfigId?: number;
}

/** FlowMoreInboundItemDTO */
export interface FlowMoreInboundItemDTO {
	/** @format int32 */
	amount?: number;
	orderCode?: string;
	productSku?: string;
}

/** FlowMoreInboundReqDTO */
export interface FlowMoreInboundReqDTO {
	/** @format int64 */
	abnormalOrderDealId?: number;
	flowCode?: string;
	items?: FlowMoreInboundItemDTO[];
	operatorId?: string;
	operatorName?: string;
	orderNo?: string;
	purchaseNo?: string;
	purchasingAbnormalOrderNo?: string;
}

/** FlowPageReqDTO */
export interface FlowPageReqDTO {
	/**
	 * 创建时间起始
	 * @format date-time
	 */
	createTimeBegine?: string;
	/**
	 * 创建时间结束
	 * @format date-time
	 */
	createTimeEnd?: string;
	/**
	 * 异常来源: 1-简单检品; 2-详细检品
	 * @format int32
	 */
	exceptionSource?: number;
	/** 快递单号 */
	expressNo?: string;
	/** 入库单号 */
	inboundCode?: string;
	/** @format int32 */
	pageNum?: number;
	/** @format int32 */
	pageSize?: number;
	/** 批次包编码 */
	pkgCode?: string;
	/** 包类型, 1-正品, 2-次品, 3-异常, 4-异常上报采购, 10-自有 */
	pkgTypes?: number[];
	/**
	 * 状态, 0-未解决, 1-已解决
	 * @format int32
	 */
	serviceStatus?: number;
	sorts?: ISortFieldEnum[];
	/**
	 * 系统来源: 1-D2C; 2-B2B
	 * @format int32
	 */
	systemSource?: number;
	/**
	 * 状态, 0-待处理, 1-已处理
	 * @format int32
	 */
	wareStatus?: number;
}

/** FlowPageRespDTO */
export interface FlowPageRespDTO {
	/**
	 * 数量
	 * @format int32
	 */
	amount?: number;
	/**
	 * 创建时间
	 * @format date-time
	 */
	createTime?: string;
	/** 创建人名称 */
	createrName?: string;
	/**
	 * 客户店铺id
	 * @format int64
	 */
	customerShopId?: number;
	/** 客户店铺名称 */
	customerShopName?: string;
	/** 异常编码 */
	exceptionCodes?: number[];
	/** 异常编码名称 */
	exceptionNames?: string[];
	/** 异常关联编码 */
	exceptionRelation?: string;
	/**
	 * 异常来源: 1-简单检品; 2-详细检品
	 * @format int32
	 */
	exceptionSource?: number;
	/** 收货快递单号 */
	expressNo?: string;
	/** 快递面单签收人电话(扫不出) */
	expressPhone?: string;
	/** 流程编码 */
	flowCode?: string;
	/** 货格码 */
	gridCode?: string;
	/** 异常图片 */
	imgs?: string[];
	/** 入库单号 */
	inboundCode?: string;
	/** 是否是oem多件 */
	oemMoreInboundException?: boolean;
	/**
	 * 操作类型, 2-次品入库, 3-异常上报用户入库, 4-异常上报采购入库
	 * @format int32
	 */
	operationType?: number;
	/** 订单编码 */
	orderCode?: string;
	/** 图片关联编码 */
	picRelation?: string;
	/** 批次包编码 */
	pkgCode?: string;
	/**
	 * 包类型, 2-次品, 3-异常, 4-异常上报采购
	 * @format int32
	 */
	pkgType?: number;
	/** 处理结果 */
	processResults?: FlowProcessResultDTO[];
	/** 商品图片(优先属性图，否则主图) */
	productImg?: string;
	/** 商品中文名 */
	productName?: string;
	/** 平台商品SKU */
	productSku?: string;
	/**
	 * 采购入库类型:1 正常采购; 2 补发; 3 换货 4 OEM多件入库
	 * @format int32
	 */
	purchaseInboundType?: number;
	/** 新采购单编号 */
	purchaseNew?: string;
	/** 采购单编号 */
	purchaseNo?: string;
	/** 退货地址 */
	refundAddress?: string;
	/** 退货联系人 */
	refundContact?: string;
	/** 退货快递单号 */
	refundExpress?: string;
	/**
	 * 最迟退货时间
	 * @format date-time
	 */
	refundLrt?: string;
	/** 退货联系电话 */
	refundPhone?: string;
	/** 库区编码 */
	regionCode?: string;
	/** 库区名称 */
	regionName?: string;
	/** 备注 */
	remark?: string;
	/**
	 * 状态, 0-未解决, 1-已解决
	 * @format int32
	 */
	serviceStatus?: number;
	/** 商品sku */
	shopProductSku?: string;
	/** 入库明细编码 */
	stockCode?: string;
	/**
	 * 系统来源: 1-D2C; 2-B2B
	 * @format int32
	 */
	systemSource?: number;
	/**
	 * 标记, 1-转正品, 2-转次退货, 3-新采购单入库,10-次品转公司库存,11-次品转用户库存 12 oem多件入库
	 * @format int32
	 */
	tag?: number;
	/**
	 * 状态, 0-待处理, 1-已处理
	 * @format int32
	 */
	wareStatus?: number;
}

/** FlowProcessResultDTO */
export interface FlowProcessResultDTO {
	/**
	 * 异常处理数量
	 * @format int32
	 */
	amount?: number;
	/**
	 * 异常标记
	 * @format int32
	 */
	flowTag?: number;
	/** 异常标记名称 */
	flowTagName?: string;
	/** 批次包编码 */
	pkgCode?: string;
}

/** FlowReqDTO */
export interface FlowReqDTO {
	/** @format int64 */
	abnormalOrderDealId?: number;
	/** @format int32 */
	amount?: number;
	flowCode?: string;
	purchaseAbnormalNo?: string;
	purchaseNew?: string;
	/** @format int32 */
	purchaseOperateAbnormalType?: number;
	refundAddress?: string;
	refundContact?: string;
	/** @format date-time */
	refundLrt?: string;
	refundPhone?: string;
	remark?: string;
	/** @format int32 */
	serviceStatus?: number;
	/** @format int32 */
	tag?: number;
	/** @format int32 */
	wareStatus?: number;
	/** @format int64 */
	warehouse?: number;
}

/** FlowRespDTO */
export interface FlowRespDTO {
	/** @format int32 */
	amount?: number;
	/** @format int32 */
	changeGoodsFlag?: number;
	/** @format int64 */
	customerShopId?: number;
	customerShopName?: string;
	exceptionRelation?: string;
	expressNo?: string;
	expressPhone?: string;
	flowCode?: string;
	imgs?: string[];
	/** @format int32 */
	operateAbnormalType?: number;
	/** @format int32 */
	operationType?: number;
	orderCode?: string;
	picRelation?: string;
	pkgCode?: string;
	/** @format int32 */
	pkgType?: number;
	productImg?: string;
	productName?: string;
	productSku?: string;
	purchaseAbnormalNo?: string;
	purchaseNew?: string;
	purchaseNo?: string;
	refundAddress?: string;
	refundContact?: string;
	refundExpress?: string;
	/** @format date-time */
	refundLrt?: string;
	refundPhone?: string;
	remark?: string;
	/** @format int32 */
	serviceStatus?: number;
	shopProductSku?: string;
	stockCode?: string;
	/** @format int32 */
	systemSource?: number;
	/** @format int32 */
	tag?: number;
	/** @format int32 */
	wareStatus?: number;
}

/** FlowUpdateImgsReqDTO */
export interface FlowUpdateImgsReqDTO {
	flowCode?: string;
	/** 图片集合 */
	imgUrls?: string[];
	/** 图片关联编码 */
	picRelation?: string;
}

/** FreezeStockReqDTO */
export interface FreezeStockReqDTO {
	bizeId?: string;
	shopProductSku?: string;
	/** @format int32 */
	stockType?: number;
	/** @format int32 */
	targetFreeze?: number;
	warehouse?: string;
}

/** GenerateSalesContractReqDto */
export interface GenerateSalesContractReqDto {
	logisticsCode?: string;
}

/** GetAllotPkgPrePickReqDTO */
export interface GetAllotPkgPrePickReqDTO {
	/**
	 * 拣货数
	 * @format int32
	 */
	nums?: number;
	/** 拣货任务编号 */
	pickTaskCode?: string;
	/** 批次包编码 */
	pkgCode?: string;
}

/** GetAllotPkgPrePickRespDTO */
export interface GetAllotPkgPrePickRespDTO {
	/** 篮子编号 */
	basketCode?: string;
	/** 分拣容器编号 */
	containerCode?: string;
	/**
	 * 配货明细id
	 * @format int64
	 */
	deliveryTaskAllotDetailId?: number;
	/**
	 * 可分拣数量
	 * @format int32
	 */
	enablePickedNums?: number;
	/**
	 * 分拣框号
	 * @format int32
	 */
	frameNo?: number;
	/**
	 * 分拣层号
	 * @format int32
	 */
	layerNo?: number;
}

/** GetAllotPkgUnPickReqDTO */
export interface GetAllotPkgUnPickReqDTO {
	/** 拣货任务编号 */
	pickTaskCode?: string;
	/** 批次包编码 */
	pkgCode?: string;
}

/** GetAllotPkgUnPickRespDTO */
export interface GetAllotPkgUnPickRespDTO {
	/**
	 * 可分拣数/未分拣数
	 * @format int32
	 */
	unPickedNum?: number;
}

/** GetD2cAllotProgressReqDTO */
export interface GetD2CAllotProgressReqDTO {
	/** 拣货任务编号 */
	pickTaskCode?: string;
}

/** GetDomesticDeliveryPageDTO */
export interface GetDomesticDeliveryPageDTO {
	/**
	 * 是否可发，0:不可发;1:可发
	 * @format int32
	 */
	deliveryAble?: number;
	/** 发货任务编号 */
	deliveryTaskCode?: string;
	/** 发货任务编号集合 */
	deliveryTaskCodeList?: string[];
	/**
	 * 国内发货单状态，0:待处理;1:处理中,2:已发货,3:无法退货
	 * @format int32
	 */
	domesticDeliveryStatus?: number;
	/** @format int32 */
	pageNum?: number;
	/** @format int32 */
	pageSize?: number;
	/** 批次包编号 */
	pkgCode?: string;
	/** 采购单异常编号 */
	purchaseAbnormalNo?: string;
	/** 采购单编号 */
	purchaseNo?: string;
	/**
	 * 退货截止结束时间
	 * @format date-time
	 */
	returnDeadlineEndTime?: string;
	/**
	 * 退货截止开始时间
	 * @format date-time
	 */
	returnDeadlineStartTime?: string;
	/**
	 * 退货截止时间
	 * @format date-time
	 */
	returnDeadlineTime?: string;
	sorts?: ISortFieldEnum[];
}

/** GetModifyAddressFlagReqDTO */
export interface GetModifyAddressFlagReqDTO {
	/** @format int32 */
	pageNum?: number;
	/** @format int32 */
	pageSize?: number;
	/** 批次包编号集合 */
	pkgCodeList?: string[];
	sorts?: ISortFieldEnum[];
}

/** GetReviewForwarderReqDTO */
export interface GetReviewForwarderReqDTO {
	/**
	 * 审核中国货代公司id
	 * @format int64
	 */
	auditIntForwarderConfigId?: number;
	/**
	 * 发货类型(0:大件fba 1:大件普通 2:小件)
	 * @format int32
	 */
	deliveryType?: number;
	/** 收货国家编码 */
	receivingNationCode?: string;
	/**
	 * 系统来源(1:D2C 2:B2B )
	 * @format int32
	 */
	systemSource?: number;
	/** 仓库 */
	warehouse?: string;
}

/** GetReviewForwarderRespDTO */
export interface GetReviewForwarderRespDTO {
	/** 审核日本配送公司名称（客户） */
	auditDistribution?: string;
	/** 审核中国货代公司名称 */
	auditForwarder?: string;
	/**
	 * 审核中国货代公司id
	 * @format int64
	 */
	auditIntForwarderConfigId?: number;
	/**
	 * 审核日本配送公司id（客户）
	 * @format int64
	 */
	auditIntShipConfigId?: number;
	/** 审核运输方式 */
	auditShippingType?: string;
	/** 渠道代码 */
	channelNo?: string;
	/** 物流公司code */
	logisticsCompanyCode?: string;
}

/** GetTrackForwarderCompanyReq */
export interface GetTrackForwarderCompanyReq {
	/**
	 * 发货类型(1:大件 2:小件)
	 * @format int32
	 */
	packageType?: number;
	/**
	 * 状态 0-进行中 1-异常 2-完结
	 * @format int32
	 */
	status?: number;
}

/** GetTransferDeliveryPageReqDTO */
export interface GetTransferDeliveryPageReqDTO {
	/** @format int32 */
	pageNum?: number;
	/** @format int32 */
	pageSize?: number;
	sorts?: ISortFieldEnum[];
	/** 发货单编码 */
	transferDeliveryTaskCode?: string;
}

/** GetTransferWarehousePageReqDTO */
export interface GetTransferWarehousePageReqDTO {
	/**
	 * 创建时间开始
	 * @format date-time
	 */
	createTimeBegin?: string;
	/**
	 * 创建时间结束
	 * @format date-time
	 */
	createTimeEnd?: string;
	/** @format int32 */
	pageNum?: number;
	/** @format int32 */
	pageSize?: number;
	sorts?: ISortFieldEnum[];
	/** 调拨单编码 */
	transferWarehouseCode?: string;
}

/** GetUnpackSkuReqDTO */
export interface GetUnpackSkuReqDTO {
	/** 发货任务编号 */
	deliveryTaskCode?: string;
	/** 批次包号 */
	pkgCode?: string;
}

/** Grid */
export interface Grid {
	aisle?: string;
	/** @format int32 */
	beactive?: number;
	/** @format date-time */
	createTime?: string;
	/** @format int64 */
	creater?: number;
	createrName?: string;
	gridCode?: string;
	/** @format int64 */
	id?: number;
	/** @format date-time */
	lmt?: string;
	/** @format int64 */
	modifier?: number;
	modifierName?: string;
	regionCode?: string;
	regionName?: string;
	series?: string;
	shelf?: string;
	tier?: string;
	warehouse?: string;
}

/** GridPageReqDTO */
export interface GridPageReqDTO {
	/** @format int32 */
	pageNum?: number;
	/** @format int32 */
	pageSize?: number;
	/** 库区编码 */
	regionCode?: string;
	sorts?: ISortFieldEnum[];
}

/** GridReqDTO */
export interface GridReqDTO {
	/** 库位列表 */
	gridCodeList?: string[];
	/** 库区编码 */
	regionCode?: string;
	/** 库区名称 */
	regionName?: string;
}

/** ISortFieldEnum */
export type ISortFieldEnum = object;

/** ISortFieldEnumReq */
export type ISortFieldEnumReq = object;

/** ISortFieldEnumRes */
export interface ISortFieldEnumRes {
	asc?: boolean;
	field?: string;
}

/** Img */
export interface Img {
	/** @format int32 */
	beactive?: number;
	/** @format date-time */
	createTime?: string;
	/** @format int64 */
	creater?: number;
	createrName?: string;
	/** @format int64 */
	id?: number;
	imgUrl?: string;
	/** @format date-time */
	lmt?: string;
	/** @format int64 */
	modifier?: number;
	modifierName?: string;
	picRelation?: string;
	warehouse?: string;
}

/** ImgSaveDTO */
export interface ImgSaveDTO {
	/** 图片链接列表 */
	imgUrls?: string[];
	/**
	 * 操作类型 默认0-新增；1-更新
	 * @format int32
	 */
	operationType?: number;
	/** 关联编码 */
	picRelation?: string;
}

/** ImportStockReqDTO */
export interface ImportStockReqDTO {
	/**
	 * 成品库存总数
	 * @format int32
	 */
	checked?: number;
	/** 颜色中文 */
	color?: string;
	/** 颜色日文 */
	colorJp?: string;
	/**
	 * 客户ID
	 * @format int64
	 */
	customerId?: number;
	/** 客户姓名 */
	customerName?: string;
	/** 店铺编码 */
	customerShopId?: string;
	/** 店铺名称 */
	customerShopName?: string;
	/**
	 * 冻结数
	 * @format int32
	 */
	freezed?: number;
	/** 货格码 */
	gridCode?: string;
	/** 中文品名 */
	itemCategory?: string;
	/**
	 * OEM商品分类id：1-服装; 2-杂货; 3-辅料
	 * @format int32
	 */
	oemProductClassificationId?: number;
	/** 原SKU */
	originalSku?: string;
	/** 批次包编码 */
	pkgCode?: string;
	/** 商品链接 */
	productLink?: string;
	/** 商品名称 */
	productName?: string;
	/** 商品属性 */
	productProp?: string;
	/** 商品sku/辅料名 */
	productSku?: string;
	/**
	 * 商品类型: 1-普通商品, 2-OEM商品, 3-组合主商品, 4-组合子商品
	 * @format int32
	 */
	productType?: number;
	/** 备注 */
	remark?: string;
	/**
	 * 上架成品库存总数
	 * @format int32
	 */
	shelved?: number;
	/** 店铺商品SKU(店铺商品库) */
	shopProductSku?: string;
	/** 尺寸中文 */
	size?: string;
	/** 尺寸日文 */
	sizeJp?: string;
	/**
	 * 系统来源: 1-D2C; 2-B2B
	 * @format int32
	 */
	systemSource?: number;
	/** 仓库 */
	warehouse?: string;
}

/** InboundCancelSkuReqDTO */
export interface InboundCancelSkuReqDTO {
	/**
	 * 商品id
	 * @format int64
	 */
	inboundItemId: number;
}

/** InboundCountByTypeRespDTO */
export interface InboundCountByTypeRespDTO {
	/**
	 * 全部数量
	 * @format int32
	 */
	all?: number;
	/**
	 * 已关闭数量
	 * @format int32
	 */
	closedNums?: number;
	/**
	 * 已完成数量
	 * @format int32
	 */
	completedNums?: number;
	/**
	 * 入库中数量
	 * @format int32
	 */
	inboundingNums?: number;
	/**
	 * 待签收数量
	 * @format int32
	 */
	signNums?: number;
	/**
	 * 待确认数量
	 * @format int32
	 */
	toBeConfirmedNums?: number;
}

/** InboundExpressDTO */
export interface InboundExpressDTO {
	/** @format date-time */
	createTime?: string;
	logisticsNumber?: string;
}

/** InboundItemRelationReqDTO */
export interface InboundItemRelationReqDTO {
	actualUnitPrice?: number;
	/** @format int32 */
	combinationProductItemFlag?: number;
	/** @format int64 */
	customerId?: number;
	customerName?: string;
	/** @format int64 */
	customerShopId?: number;
	customerShopName?: string;
	/** @format int32 */
	directSaleFlag?: number;
	/** @format int32 */
	num?: number;
	/** @format int64 */
	oemProductClassificationId?: number;
	/** @format int64 */
	orderItemId?: number;
	orderNo?: string;
	/** @format date-time */
	orderTime?: string;
	/** @format int32 */
	orderType?: number;
	originalProductSku?: string;
	platformOrderNo?: string;
	productCode?: string;
	productImg?: string;
	productLink?: string;
	productName?: string;
	productProp?: string;
	productSku?: string;
	/** @format int32 */
	productType?: number;
	recommendProductSku?: string;
	recommendProductSpu?: string;
	/** @format int32 */
	sellQuantity?: number;
	shopProductSku?: string;
	/** @format int32 */
	singleProductFlag?: number;
	stationCode?: string;
	unificationCustomerFullName?: string;
}

/** InboundItemReqDTO */
export interface InboundItemReqDTO {
	/** @format int64 */
	abnormalOrderDealId?: number;
	/** @format int32 */
	isOriginalProductInfo?: number;
	/** @format int32 */
	maxOverFlowNum?: number;
	productCode?: string;
	productImg?: string;
	productLink?: string;
	productName?: string;
	productProp?: string;
	productSku?: string;
	/** @format int32 */
	purchaseNum?: number;
	relationList?: InboundItemRelationReqDTO[];
}

/** InboundNumInfoRespDTO */
export interface InboundNumInfoRespDTO {
	/**
	 * 入库数量
	 * @format int32
	 */
	amount?: number;
	/** 内容 */
	content?: string;
	/** 系统订单编号 */
	orderCode?: string;
	/**
	 * 类型，1-直发订单, 2-囤货订单
	 * @format int32
	 */
	orderType?: number;
	/** 批次包编码 */
	pkgCode?: string;
	/**
	 * 包类型, 1-正品, 2-次品, 3-异常上报客户, 4-异常上报采购
	 * @format int32
	 */
	pkgType?: number;
	/** 平台订单编号 */
	platformOrderNo?: string;
	/** 商品中文名 */
	productName?: string;
	/** 平台商品SKU */
	productSku?: string;
	/**
	 * 商品类型: 1-普通商品; 2-OEM商品; 3-组合商品
	 * @format int32
	 */
	productType?: number;
	/** 采购单编号 */
	purchaseNo?: string;
	/** 店铺商品SKU */
	shopProductSku?: string;
	stockCode?: string;
}

/** InboundOneKeyCompleteReqDTO */
export interface InboundOneKeyCompleteReqDTO {
	/** 入库单号 */
	inboundCode?: string;
}

/** InboundOneKeyConfirmInfoDTO */
export interface InboundOneKeyConfirmInfoDTO {
	/**
	 * 商品id
	 * @format int64
	 */
	inboundItemId: number;
	/**
	 * 实际导入数量
	 * @format int32
	 */
	realImportNum: number;
	/** 马帮sku */
	thirdProductSku: string;
}

/** InboundOneKeyConfirmReqDTO */
export interface InboundOneKeyConfirmReqDTO {
	/** 入库单号 */
	inboundCode?: string;
	/** sku集合 */
	itemList?: InboundOneKeyConfirmInfoDTO[];
}

/** InboundOneKeyInfoRespDTO */
export interface InboundOneKeyInfoRespDTO {
	/** 入库失败原因 */
	failMessage?: string;
	/** 货格码 */
	gridCode?: string;
	/**
	 * id
	 * @format int64
	 */
	id?: number;
	/**
	 * 预导入数量--导入库存数
	 * @format int32
	 */
	importNum?: number;
	/** 导入的商品属性 */
	importProductProp?: string;
	/** 入库单号 */
	inboundCode?: string;
	/**
	 * 手动入库状态 0-未入库 1-已入库 2-已取消
	 * @format int32
	 */
	inboundStatus?: number;
	/**
	 * 马帮库存数
	 * @format int32
	 */
	mabangNum?: number;
	/** 平台商品SPU */
	productCode?: string;
	/** 商品图片 */
	productImg?: string;
	/** 商品链接 */
	productLink?: string;
	/** 商品名称 */
	productName?: string;
	/** 商品属性 */
	productProp?: string;
	/** 平台商品SKU */
	productSku?: string;
	/**
	 * 实际导入数量
	 * @format int32
	 */
	realImportNum?: number;
	/**
	 * sku类型: 0-普通商品 1-辅料
	 * @format int32
	 */
	skuType?: number;
	/** 三方sku（马帮） */
	thirdProductSku?: string;
}

/** InboundOperationRecordPageQuery */
export interface InboundOperationRecordPageQuery {
	/** 入库单号 */
	inboundCode?: string;
	/** 操作节点 */
	node?: string;
	/** @format int32 */
	pageNum?: number;
	/** @format int32 */
	pageSize?: number;
	/** 批次包编码 */
	pkgCode?: string;
	/** 平台订单编号 */
	platformOrderNo?: string;
	/** 平台商品SKU */
	productSku?: string;
	sorts?: ISortFieldEnum[];
}

/** InboundOperationRecordRespDTO */
export interface InboundOperationRecordRespDTO {
	/** 操作内容 */
	content?: string;
	/**
	 * 创建日期
	 * @format date-time
	 */
	createTime?: string;
	/** 创建人名称 */
	creatorName?: string;
	/** 入库单号 */
	inboundCode?: string;
	/** 跳转链接 */
	link?: string;
	/** 操作节点code */
	node?: string;
	/** 操作节点名称 */
	nodeName?: string;
	/**
	 * 数量
	 * @format int32
	 */
	num?: number;
	/** 系统订单编号 */
	orderNo?: string;
	/** 批次包编码 */
	pkgCode?: string;
	/** 平台订单编号 */
	platformOrderNo?: string;
	/** 平台商品SKU */
	productSku?: string;
}

/** InboundOrderAdditionReqDTO */
export interface InboundOrderAdditionReqDTO {
	/** @format int32 */
	additionConfigId?: number;
	additionExtraName?: string;
	/** @format int32 */
	additionId?: number;
	additionName?: string;
	additionNameJp?: string;
	/** @format int32 */
	additionQuantity?: number;
	calculateConfigFormula?: string;
	/** @format int32 */
	calculateConfigId?: number;
	cost?: number;
	/** @format int32 */
	costType?: number;
	/** @format int32 */
	deductionNode?: number;
	deductionNodeName?: string;
	ingredientsImg?: string;
	ingredientsName?: string;
	ingredientsNameJp?: string;
	ingredientsSku?: string;
	orderAdditionCode?: string;
	orderNo?: string;
	platformOrderNo?: string;
	/** @format int32 */
	regularizationFlag?: number;
	shopProductSku?: string;
	/** @format int32 */
	spotCheckFlag?: number;
	spotCheckRatio?: number;
	warehouse?: string;
}

/** InboundOrderAdditionRespDTO */
export interface InboundOrderAdditionRespDTO {
	/** @format int32 */
	additionCode?: number;
	/** @format int32 */
	additionConfigId?: number;
	additionExtraName?: string;
	/** @format int32 */
	additionId?: number;
	additionName?: string;
	additionNameJp?: string;
	/** @format int32 */
	additionQuantity?: number;
	calculateConfigFormula?: string;
	/** @format int32 */
	calculateConfigId?: number;
	cost?: number;
	/** @format int32 */
	costType?: number;
	/** @format date-time */
	createTime?: string;
	/** @format int64 */
	creatorId?: number;
	creatorName?: string;
	/** @format int32 */
	deductionNode?: number;
	deductionNodeName?: string;
	/** @format int64 */
	editorId?: number;
	editorName?: string;
	/** @format int64 */
	id?: number;
	ingredientsImg?: string;
	ingredientsName?: string;
	ingredientsNameJp?: string;
	ingredientsSku?: string;
	/** @format int32 */
	isDelete?: number;
	newPkgCode?: string;
	orderAdditionCode?: string;
	orderNo?: string;
	pkgCode?: string;
	platformOrderNo?: string;
	/** @format int32 */
	regularizationFlag?: number;
	shopProductSku?: string;
	subtotal?: number;
	/** @format date-time */
	updateTime?: string;
	warehouse?: string;
}

/** InboundOrderCloseReqDTO */
export interface InboundOrderCloseReqDTO {
	/** 关闭原因 */
	closeReason?: string;
	/** 入库单号 */
	inboundCode?: string;
}

/** InboundOrderCreateReqDTO */
export interface InboundOrderCreateReqDTO {
	/** @format int32 */
	bizType?: number;
	flowCode?: string;
	inboundCode?: string;
	itemList?: InboundItemReqDTO[];
	logisticsNumberList?: InboundExpressDTO[];
	/** @format int64 */
	oemProductClassificationId?: number;
	/** @format int64 */
	operatorId?: number;
	operatorName?: string;
	platformType?: string;
	/** @format date-time */
	purchaseCompleteTime?: string;
	/** @format date-time */
	purchaseCreateTime?: string;
	/** @format int64 */
	purchaseId?: number;
	/** @format int32 */
	purchaseInboundType?: number;
	purchaseNo?: string;
	purchaseRemark?: string;
	/** @format int32 */
	purchaseType?: number;
	purchaseUserId?: string;
	purchaseUserName?: string;
	purchasingAbnormalOrderNo?: string;
	shopCode?: string;
	shopName?: string;
	/** @format int32 */
	systemSource?: number;
	warehouseCode?: string;
}

/** InboundOrderPageQuery */
export interface InboundOrderPageQuery {
	/**
	 * 创建时间-结束
	 * @format date-time
	 */
	createTimeEnd?: string;
	/**
	 * 创建时间-开始
	 * @format date-time
	 */
	createTimeStart?: string;
	/** 创建人 */
	creatorName?: string;
	/** 物流单号，支持批量 */
	expressNoList?: string[];
	/**
	 * 是否是重点客户 空 全部 0 否 1 是
	 * @format int32
	 */
	hasKeyCustomer?: number;
	/** 入库单号，支持批量 */
	inboundCodeList?: string[];
	/**
	 * 入库单类型 0-采购入库 1-手动入库 2-调拨入库
	 * @format int32
	 */
	inboundType?: number;
	/** @format int32 */
	pageNum?: number;
	/** @format int32 */
	pageSize?: number;
	/** 批次号 */
	pkgCode?: string;
	/** 采购单号，支持批量 */
	purchaseNoList?: string[];
	/**
	 * 采购类型 0-线上采购 1-OEM大货单 2-OEM样品单 3-档口采购 4-马帮入库
	 * @format int32
	 */
	purchaseType?: number;
	sorts?: ISortFieldEnum[];
	/**
	 * 状态 1-待签收 2-入库中 3-待确认 4-已完成 5-已关闭
	 * @format int32
	 */
	status?: number;
	/**
	 * 系统来源: 1-D2C; 2-B2B
	 * @format int32
	 */
	systemSource?: number;
}

/** InboundOrderRemoveLogisticsReqDTO */
export interface InboundOrderRemoveLogisticsReqDTO {
	logisticsNumberList?: string[];
	/** @format int64 */
	operatorId?: number;
	operatorName?: string;
	purchaseNo?: string;
}

/** InboundOrderRespDTO */
export interface InboundOrderRespDTO {
	/** 关闭人 */
	closeName?: string;
	/** 关闭原因 */
	closeReason?: string;
	/**
	 * 关闭时间
	 * @format date-time
	 */
	closeTime?: string;
	/**
	 * 完成入库时间
	 * @format date-time
	 */
	completeTime?: string;
	/**
	 * 创建日期
	 * @format date-time
	 */
	createTime?: string;
	/**
	 * 创建人ID
	 * @format int64
	 */
	creatorId?: number;
	/** 创建人名称 */
	creatorName?: string;
	/**
	 * 客户id
	 * @format int64
	 */
	customerId?: number;
	/** 客户姓名 */
	customerName?: string;
	/**
	 * 客户店铺id
	 * @format int64
	 */
	customerShopId?: number;
	/** 客户店铺名称 */
	customerShopName?: string;
	/**
	 * 0:无,1:OEM重点客户-项目,2:OEM重点客户-非项目,3:既有OEM重点客户-项目,又有OEM重点客户-非项目
	 * @format int32
	 */
	customerTag?: number;
	/**
	 * 修改人ID
	 * @format int64
	 */
	editorId?: number;
	/** 修改人名称 */
	editorName?: string;
	/** 是否可入库标志 */
	enableInbound?: boolean;
	/** 物流单号 */
	expressNoList?: string[];
	/**
	 * id
	 * @format int64
	 */
	id?: number;
	/** 入库单号 */
	inboundCode?: string;
	/**
	 * 入库单类型 0-采购入库 1-手动入库 2-调拨入库
	 * @format int32
	 */
	inboundType?: number;
	/**
	 * 是否逻辑删除(0:未删除 1:已删除)
	 * @format int32
	 */
	isDelete?: number;
	/**
	 * 商品数量
	 * @format int32
	 */
	nums?: number;
	/**
	 * 采购入库类型：1-正常采购、2-补发、3-换货
	 * @format int32
	 */
	purchaseInboundType?: number;
	/** 采购单号 */
	purchaseNoList?: string[];
	/**
	 * 采购类型 0-线上采购 1-OEM大货单 2-OEM样品单 3-档口采购 4-马帮入库
	 * @format int32
	 */
	purchaseType?: number;
	/** 采购员后台用户id */
	purchaseUserId?: string;
	/** 采购员用户姓名 */
	purchaseUserName?: string;
	/**
	 * 签收时间
	 * @format date-time
	 */
	signTime?: string;
	/**
	 * 状态 1-待签收 2-入库中 3-待确认 4-已完成 5-已关闭
	 * @format int32
	 */
	status?: number;
	/**
	 * 系统来源: 1-D2C; 2-B2B
	 * @format int32
	 */
	systemSource?: number;
	/** 统一客户全名 */
	unificationCustomerFullName?: string;
	/**
	 * 修改时间
	 * @format date-time
	 */
	updateTime?: string;
	/** 仓库 */
	warehouse?: string;
}

/** InboundOrderSkuImageUpdateReqDTO */
export interface InboundOrderSkuImageUpdateReqDTO {
	/** @format int64 */
	operatorId?: number;
	operatorName?: string;
	productImage?: string;
	productSku?: string;
	purchaseNo?: string;
}

/** InboundOrderSkuInfoRespDTO */
export interface InboundOrderSkuInfoRespDTO {
	/**
	 * 次品数
	 * @format int32
	 */
	badNum?: number;
	/**
	 * 手动入库-取消数量
	 * @format int32
	 */
	cancelNum?: number;
	/**
	 * 0:无,1:OEM重点客户-项目,2:OEM重点客户-非项目,3:既有OEM重点客户-项目,又有OEM重点客户-非项目
	 * @format int32
	 */
	customerTag?: number;
	/**
	 * 是否可更改规格 0-不可改 1-可改
	 * @format int32
	 */
	enableEditProp?: number;
	/**
	 * 异常上报客户数量
	 * @format int32
	 */
	exceptionCustomerNum?: number;
	/**
	 * 异常上报采购数量
	 * @format int32
	 */
	exceptionPurchaseNum?: number;
	/** 入库失败原因 */
	failMessage?: string;
	/** 货格码 */
	gridCode?: string;
	/**
	 * id
	 * @format int64
	 */
	id?: number;
	/**
	 * 手动入库-预导入数量
	 * @format int32
	 */
	importNum?: number;
	/** 导入的商品属性 */
	importProductProp?: string;
	/** 入库单号 */
	inboundCode?: string;
	/**
	 * 已入库数量
	 * @format int32
	 */
	inboundNum?: number;
	/**
	 * 手动入库状态 0-未入库 1-已入库 2-已取消
	 * @format int32
	 */
	inboundStatus?: number;
	/** 平台商品SPU */
	productCode?: string;
	/** 商品图片 */
	productImg?: string;
	/** 商品链接 */
	productLink?: string;
	/** 商品名称 */
	productName?: string;
	/** 商品属性 */
	productProp?: string;
	/** 商品规格选项 */
	productPropList?: ProductPropOptionDTO[];
	/** 平台商品SKU */
	productSku?: string;
	/** 采购单编号 */
	purchaseNo?: string;
	/**
	 * 实际采购总数
	 * @format int32
	 */
	purchaseNum?: number;
	/**
	 * 手动入库-实际导入数量
	 * @format int32
	 */
	realImportNum?: number;
	/**
	 * sku类型:  0-线上商品 1-OEM 2-线下商品
	 * @format int32
	 */
	skuType?: number;
	/** 站点国家 */
	stationCode?: string;
	/** 三方sku（马帮） */
	thirdProductSku?: string;
	/**
	 * 手动入库-未入库数量
	 * @format int32
	 */
	unImportNum?: number;
}

/** InboundOrderUpdateLogisticsReqDTO */
export interface InboundOrderUpdateLogisticsReqDTO {
	logisticsNumberList?: string[];
	/** @format int64 */
	operatorId?: number;
	operatorName?: string;
	purchaseNo?: string;
}

/** InboundPurchaseNumInfoRespDTO */
export interface InboundPurchaseNumInfoRespDTO {
	/** 附加项 */
	additionList?: InboundOrderAdditionRespDTO[];
	/**
	 * 附加项数量
	 * @format int32
	 */
	additionNums?: number;
	/**
	 * 采购数量
	 * @format int32
	 */
	num?: number;
	/** 系统订单编号 */
	orderNo?: string;
	/** 平台订单编号 */
	platformOrderNo?: string;
	/**
	 * 商品类型: 1-普通商品; 2-OEM商品; 3-组合商品
	 * @format int32
	 */
	productType?: number;
	receivingNationCode?: string;
	receivingNationName?: string;
	/** 特殊质检要求 */
	specialCheckRequirement?: string;
	/** 特殊质检要求图片 */
	specialCheckRequirementImg?: string;
}

/** InboundSkuPropRespDto */
export interface InboundSkuPropRespDto {
	/**
	 * 导入的OEM商品分类id：1-服装; 2-杂货; 3-辅料
	 * @format int32
	 */
	importOemClassificationId?: number;
	/** 平台商品SPU */
	productCode?: string;
	/** 商品图片 */
	productImg?: string;
	/** 商品链接 */
	productLink?: string;
	/** 商品名称 */
	productName?: string;
	/** 商品属性 */
	productProp?: string;
	/** 商品规格选项 */
	productPropList?: ProductPropOptionDTO[];
	/** 平台商品SKU */
	productSku?: string;
	/**
	 * sku类型:  0-线上商品 1-OEM 2-线下商品
	 * @format int32
	 */
	skuType?: number;
}

/** InboundUpdateByUrlReqDTO */
export interface InboundUpdateByUrlReqDTO {
	/**
	 * 商品id
	 * @format int64
	 */
	inboundItemId?: number;
	/** 商品链接 */
	productLink?: string;
}

/** InboundUpdatePropBySkuReqDTO */
export interface InboundUpdatePropBySkuReqDTO {
	/**
	 * 导入的OEM商品分类id：1-服装; 2-杂货; 3-辅料
	 * @format int32
	 */
	importOemClassificationId?: number;
	/**
	 * 商品id
	 * @format int64
	 */
	inboundItemId: number;
	/** 平台商品SPU */
	productCode?: string;
	/** 商品图片 */
	productImg?: string;
	/** 商品链接 */
	productLink?: string;
	/** 商品名称 */
	productName?: string;
	/** 商品属性 */
	productProp?: string;
	/** 商品规格选项 */
	productPropList?: ProductPropOptionDTO[];
	/** 平台商品SKU */
	productSku: string;
	/**
	 * sku类型:  0-线上商品 1-OEM 2-线下商品
	 * @format int32
	 */
	skuType?: number;
}

/** InboundUpdatePropReqDTO */
export interface InboundUpdatePropReqDTO {
	/**
	 * 商品id
	 * @format int64
	 */
	inboundItemId: number;
	/** 平台商品SPU */
	productCode: string;
	/** 商品图片 */
	productImg?: string;
	/** 商品链接 */
	productLink: string;
	/** 商品名称 */
	productName?: string;
	/** 商品属性 */
	productProp?: string;
	/** 商品规格选项 */
	productPropList?: ProductPropOptionDTO[];
	/** 平台商品SKU */
	productSku: string;
}

/** IngredientsBatchRespDTO */
export interface IngredientsBatchRespDTO {
	/** 货格码 */
	gridCode?: string;
	/** 辅料sku */
	ingredientsSku?: string;
}

/** InitDeliveryDedectionDto */
export interface InitDeliveryDedectionDto {
	deliveryTaskCode?: string;
}

/** InnerExceptionDTO */
export interface InnerExceptionDTO {
	/**
	 * 异常件数
	 * @format int32
	 */
	abnormalAmount?: number;
	/**
	 * 内部异常类型，0-少件，1-多件
	 * @format int32
	 */
	abnormalType?: number;
	/** 图片关联编码 */
	picRelation?: string;
	/** 源包编码 */
	pkgCode?: string;
	/** 备注 */
	remark?: string;
}

/** InnerExceptionDealDTO */
export interface InnerExceptionDealDTO {
	/**
	 * 找回数量
	 * @format int32
	 */
	backAmount?: number;
	/** 责任人 */
	dutyName?: string;
	/** 异常编码 */
	innerCode?: string;
	/**
	 * 处理结果，1-转其他少件，2-转公司库存
	 * @format int32
	 */
	tag?: number;
}

/** InnerExceptionPageReqDTO */
export interface InnerExceptionPageReqDTO {
	/**
	 * 内部异常类型，0-少件，1-多件
	 * @format int32
	 */
	abnormalType?: number;
	/** 补采运单号 */
	expressNo?: string;
	/** 入库单号 */
	inboundCode?: string;
	/**
	 * 是否丢件，0-未丢件，1-确认丢件
	 * @format int32
	 */
	lossFlag?: number;
	/** @format int32 */
	pageNum?: number;
	/** @format int32 */
	pageSize?: number;
	/** 源包编码 */
	pkgCode?: string;
	/** 新包编码 */
	pkgCodeNew?: string;
	sorts?: ISortFieldEnum[];
	/**
	 * 系统来源: 1-D2C; 2-B2B
	 * @format int32
	 */
	systemSource?: number;
}

/** InnerExceptionRespDTO */
export interface InnerExceptionRespDTO {
	/** @format int32 */
	abnormalAmount?: number;
	/** @format int32 */
	abnormalType?: number;
	/** @format int32 */
	amount?: number;
	/** @format int32 */
	backAmount?: number;
	/** @format int32 */
	beactive?: number;
	/** @format date-time */
	createTime?: string;
	/** @format int64 */
	creater?: number;
	createrName?: string;
	dutyName?: string;
	expressNo?: string;
	/** @format int64 */
	id?: number;
	/** 入库单号 */
	inboundCode?: string;
	innerCode?: string;
	/** @format date-time */
	lmt?: string;
	/** @format int32 */
	lossFlag?: number;
	/** @format int64 */
	modifier?: number;
	modifierName?: string;
	orderCode?: string;
	picRelation?: string;
	/** 图片列表 */
	pics?: string[];
	pkgCode?: string;
	pkgCodeNew?: string;
	/** @format int32 */
	pkgStatus?: number;
	productImg?: string;
	regionCode?: string;
	remark?: string;
	shopProductSku?: string;
	/** @format int32 */
	status?: number;
	/** @format int32 */
	systemSource?: number;
	/** @format int32 */
	tag?: number;
	warehouse?: string;
}

/** InvoiceExportReqDTO */
export interface InvoiceExportReqDTO {
	/** @format date-time */
	createEndTime?: string;
	/** @format date-time */
	createStartTime?: string;
	/** @format date-time */
	deliveryEndTime?: string;
	/** @format date-time */
	deliveryStartTime?: string;
	deliveryTaskCode?: string;
	deliveryTaskCodeList?: string[];
	/** @format int32 */
	deliveryType?: number;
	/** @format int64 */
	forwarderConfigId?: number;
	/** @format int32 */
	invoiceStatus?: number;
	logisticsCode?: string;
	/** 勾选物流单号 */
	logisticsCodeList?: string[];
	operatorName?: string;
	productSku?: string;
	shopProductSku?: string;
	wmsForwarder?: string;
}

/** InvoiceForwarderReqDTO */
export interface InvoiceForwarderReqDTO {
	/**
	 * 发货类型(0:大件fba 1:大件普通 2:小件)
	 * @format int32
	 */
	deliveryType?: number;
	/**
	 * 状态 0 未制作 1 已制作
	 * @format int32
	 */
	invoiceStatus?: number;
}

/** InvoiceForwarderRespDTO */
export interface InvoiceForwarderRespDTO {
	/**
	 * 创建时间
	 * @format date-time
	 */
	createTime?: string;
	/**
	 * 数量
	 * @format int32
	 */
	currentAmount?: number;
	/** 货代公司 */
	forwarder?: string;
	/**
	 * id
	 * @format int64
	 */
	intForwarderConfigId?: number;
	/** 物流公司code */
	logisticsCompanyCode?: string;
	/** 会员等级 */
	membershipName?: string[];
	/** 运输方式 */
	shippingType?: string;
	/**
	 * 更新时间
	 * @format date-time
	 */
	updateTime?: string;
}

/** InvoiceTemplateDTO */
export interface InvoiceTemplateDTO {
	forwarder?: string;
	templateCode?: string;
	templateName?: string;
	url?: string;
}

/** LabelTemplateRespDTO */
export interface LabelTemplateRespDTO {
	/**
	 * 删除标志，0-已删除，1-未删除
	 * @format int32
	 */
	beactive?: number;
	/**
	 * 创建时间
	 * @format date-time
	 */
	createTime?: string;
	/**
	 * 创建人
	 * @format int64
	 */
	creater?: number;
	/** 标签名称 */
	label?: string;
	/** 模板样式HTML */
	labelHtml?: string;
	/** 模板参数JSON */
	labelInfo?: string;
	/**
	 * id
	 * @format int64
	 */
	labelTemplateId?: number;
	/**
	 * 最新修改时间
	 * @format date-time
	 */
	lmt?: string;
	/**
	 * 修改人
	 * @format int64
	 */
	modifier?: number;
	/** 仓库 */
	warehouse?: string;
}

/** LableValue */
export interface LableValue {
	code?: string;
	value?: string;
}

/** LogisticsApiConfigEditDTO */
export interface LogisticsApiConfigEditDTO {
	/** 渠道代码 */
	channelNo?: string;
	/**
	 * 中国货代公司id
	 * @format int64
	 */
	intForwarderConfigId?: number;
	/** 物流公司code */
	logisticsCompanyCode?: string;
	/** 打印纸尺寸(高) */
	printPaperSizeHigh?: string;
	/** 打印纸尺寸(宽) */
	printPaperSizeWide?: string;
}

/** LogisticsApiConfigQuery */
export interface LogisticsApiConfigQuery {
	/** 货代公司 */
	forwarder?: string;
	/** @format int32 */
	pageNum?: number;
	/** @format int32 */
	pageSize?: number;
	/** 运输方式 */
	shippingType?: string;
	sorts?: ISortFieldEnum[];
}

/** LogisticsApiConfigRespDTO */
export interface LogisticsApiConfigRespDTO {
	channelNo?: string;
	forwarder?: string;
	/** @format int64 */
	intForwarderConfigId?: number;
	logisticsChannelDTOList?: LogisticsChannelDTO[];
	logisticsCompanyCode?: string;
	printPaperSizeHigh?: string;
	printPaperSizeWide?: string;
	shippingType?: string;
}

/** LogisticsApiPrintConfigRespDTO */
export interface LogisticsApiPrintConfigRespDTO {
	printPaperSizeHigh?: string;
	printPaperSizeWide?: string;
}

/** LogisticsBoxDetailDTO */
export interface LogisticsBoxDetailDTO {
	/**
	 * 箱数
	 * @format int32
	 */
	boxNum?: number;
	/**
	 * 高
	 * @format int32
	 */
	height?: number;
	/**
	 * 长
	 * @format int32
	 */
	length?: number;
	/** 重量kg */
	weight?: number;
	/**
	 * 宽
	 * @format int32
	 */
	width?: number;
}

/** LogisticsChannelDTO */
export interface LogisticsChannelDTO {
	channelName?: string;
	channelNameEn?: string;
	channelNo?: string;
	/** @format int64 */
	logisticsChannelId?: number;
	logisticsCode?: string;
	note?: string;
}

/** LogisticsChooseRespDTO */
export interface LogisticsChooseRespDTO {
	/** 审核原因 */
	auditReason?: string;
	/** 审核原因(日文) */
	auditReasonJp?: string;
	/** 审核备注 */
	auditRemark?: string;
	/** 中国货代公司名称（客户） */
	forwarder?: string;
	/**
	 * 中国货代公司id（客户）
	 * @format int64
	 */
	intForwarderConfigId?: number;
	/** 运输方式（客户） */
	shippingType?: string;
}

/** LogisticsInfoDTO */
export interface LogisticsInfoDTO {
	/** @format int32 */
	deliveryStatus?: number;
	deliveryTaskCode?: string;
	/** @format date-time */
	deliveryTime?: string;
	distribution?: string;
	freightFee?: number;
	logisticsCode?: string;
	productDetailList?: ProductDetail[];
	/** @format int32 */
	realDeliveryNums?: number;
	totalAdditionalFee?: number;
	/** @format int64 */
	weight?: number;
}

/** LogisticsSignDto */
export interface LogisticsSignDto {
	/** 运单号 */
	expressNo?: string;
	/**
	 * 签收时间
	 * @format date-time
	 */
	signDate?: string;
	/**
	 * 签收状态 0未签收 1已签收
	 * @format int32
	 */
	status?: number;
}

/** LogisticsTrackDetailResp */
export interface LogisticsTrackDetailResp {
	/** 描述 */
	description?: string;
	/** 地点 */
	location?: string;
	/**
	 * 操作时间
	 * @format date-time
	 */
	operatorTime?: string;
	/** 轨迹代码 */
	trackCode?: string;
	/** 轨迹代码名称 */
	trackName?: string;
}

/** LogisticsTrackEditErrMsgReq */
export interface LogisticsTrackEditErrMsgReq {
	/** 异常原因 */
	errMsg?: string;
	/** 物流单号 */
	logisticsCode?: string;
}

/** LogisticsTrackPageReq */
export interface LogisticsTrackPageReq {
	/**
	 * 创建结束时间
	 * @format date-time
	 */
	createEndTime?: string;
	/**
	 * 创建开始时间
	 * @format date-time
	 */
	createStartTime?: string;
	/**
	 * 发货结束时间
	 * @format date-time
	 */
	deliveryEndTime?: string;
	/**
	 * 发货开始时间
	 * @format date-time
	 */
	deliveryStartTime?: string;
	/** 发货单号 */
	deliveryTaskCode?: string;
	/** 异常类型：出运异常、通关异常、派送异常 */
	errType?: string;
	/** 物流单号 */
	logisticsCode?: string;
	/** 物流状态 */
	logisticsStatus?: string;
	/**
	 * 发货类型(1:大件 2:小件)
	 * @format int32
	 */
	packageType?: number;
	/** @format int32 */
	pageNum?: number;
	/** @format int32 */
	pageSize?: number;
	/** 平台订单编号 */
	platformOrderNo?: string;
	sorts?: ISortFieldEnum[];
	/**
	 * 状态 0-进行中 1-异常 2-完结
	 * @format int32
	 */
	status?: number;
	/** 货代公司 */
	wmsForwarder?: string;
	/**
	 * 实际发货的中国货代公司id
	 * @format int64
	 */
	wmsIntForwarderConfigId?: number;
}

/** LogisticsTrackPageResp */
export interface LogisticsTrackPageResp {
	/** 异常完结操作人 */
	completeOperator?: string;
	/**
	 * 异常完结时间
	 * @format date-time
	 */
	completeOperatorTime?: string;
	/**
	 * 客户ID
	 * @format int64
	 */
	customerId?: number;
	/** 客户姓名 */
	customerName?: string;
	/**
	 * 客户店铺表id
	 * @format int64
	 */
	customerShopId?: number;
	/** 客户店铺名称 */
	customerShopName?: string;
	/** 发货单号 */
	deliveryTaskCode?: string;
	/**
	 * 发货时间
	 * @format date-time
	 */
	deliveryTime?: string;
	/** 异常原因 */
	errMsg?: string;
	/** 异常类型 */
	errType?: string;
	/** 物流单号 */
	logisticsCode?: string;
	/** 物流状态 */
	logisticsStatus?: string;
	/**
	 * 客户经理id
	 * @format int64
	 */
	managerId?: number;
	/** 客户经理名称 */
	managerName?: string;
	/** 收货国家 */
	nation?: string;
	/** 收货国家代码 */
	nationCode?: string;
	/**
	 * 发货类型(1:大件 2:小件)
	 * @format int32
	 */
	packageType?: number;
	/** 平台订单编号 */
	platformOrderNo?: string;
	/**
	 * 状态 0-进行中 1-异常 2-完结
	 * @format int32
	 */
	status?: number;
	/** 实际发货的日本配送公司名称 */
	wmsDistribution?: string;
	/** 实际发货的中国货代公司名称 */
	wmsForwarder?: string;
	/**
	 * 实际发货的中国货代公司id
	 * @format int64
	 */
	wmsIntForwarderConfigId?: number;
	/**
	 * 实际发货的日本配送公司id
	 * @format int64
	 */
	wmsIntShipConfigId?: number;
	/** 实际发货的运输方式 */
	wmsShippingType?: string;
}

/** LogisticsTrackUpdateReq */
export interface LogisticsTrackUpdateReq {
	/** 物流单号 */
	logisticsCode?: string;
}

/** LogisticsTrackUpdateResp */
export interface LogisticsTrackUpdateResp {
	/** @format int32 */
	status?: number;
}

/** ManagerConfirmReqDTO */
export interface ManagerConfirmReqDTO {
	declarationAddress?: DeliveryTaskAddressCreateDTO;
	deliveryTaskCode?: string;
	managerRemark?: string;
	managerRemarkImgs?: string;
	receiverAddress?: DeliveryTaskAddressCreateDTO;
}

/** MinimumOrderQuantityReqDTO */
export interface MinimumOrderQuantityReqDTO {
	/** @format int64 */
	customerShopId?: number;
	customerShopName?: string;
	orderNo?: string;
	/** @format int32 */
	overflowNums?: number;
	platformOrderNo?: string;
	productSku?: string;
	shopProductSku?: string;
}

/** ModifyCustomsReqDTO */
export interface ModifyCustomsReqDTO {
	/** 编制方式+材质 */
	compilationMethod?: string;
	/** 申报总价格（人民币） */
	customsPrice?: number;
	/** 申报总价格（日元） */
	customsPriceJp?: number;
	/** 申报总价格（美元） */
	customsPriceUs?: number;
	/**
	 * 发货任务详情ID
	 * @format int64
	 */
	deliveryTaskDetailId?: number;
	/** 商品品类英文名称 */
	productCategoryEnglishName?: string;
	/** 商品品类中文名称 */
	productCategoryName?: string;
	/** 商品报关编码 */
	productDeclarationCode?: string;
	/**
	 * 申报ID
	 * @format int64
	 */
	productDeclarationId?: number;
	/** 申报单价（人民币） */
	productPriceCn?: number;
	/** 日元价格 */
	productPriceJp?: number;
	/** 商品价格(美元)-仓库 */
	productPriceUs?: number;
	/**
	 * 状态:1-更新申报信息; 2-编辑
	 * @format int32
	 */
	state?: number;
}

/** ModifyDeliveryForwarderReqDTO */
export interface ModifyDeliveryForwarderReqDTO {
	/** 发货任务编号 */
	deliveryTaskCode: string;
	/**
	 * 中国货代公司id
	 * @format int64
	 */
	wmsIntForwarderConfigId?: number;
}

/** ModifyReviewForwarderReqDTO */
export interface ModifyReviewForwarderReqDTO {
	/** 审核中国货代公司 */
	auditForwarder?: string;
	/**
	 * 审核中国货代公司id
	 * @format int64
	 */
	auditIntForwarderConfigId?: number;
	/** 发货审单编号 */
	deliveryReviewCode?: string;
}

/** MonthlyStatisticsAgeReq */
export interface MonthlyStatisticsAgeReq {
	shopProductSkuList?: string[];
}

/** MonthlyStatisticsAgeResp */
export interface MonthlyStatisticsAgeResp {
	/** @format int32 */
	firstMonthNum?: number;
	/** @format int32 */
	fiveTo6thMonthNum?: number;
	/** @format int32 */
	moreThanOneYearNum?: number;
	/** @format int32 */
	nineTo12thMonthNum?: number;
	/** @format int32 */
	secondMonthNum?: number;
	/** @format int32 */
	sevenTo8thMonthNum?: number;
	shopProductSku?: string;
	/** @format int32 */
	thirdTo4thMonthNum?: number;
	/** @format int32 */
	total?: number;
}

/** MyStockDTO */
export interface MyStockDTO {
	/** @format int32 */
	badAmount?: number;
	/** @format int32 */
	flAmount?: number;
	/** @format int32 */
	productAmount?: number;
}

/** OfflineCommitInvoiceReqDto */
export interface OfflineCommitInvoiceReqDto {
	logisticsCode?: string;
}

/** OperateRecordPageReq */
export interface OperateRecordPageReq {
	/** 业务操作类型 */
	bizNodeType?: string;
	/** 业务单号 */
	bizOrderNo?: string;
	/**
	 * 结束时间
	 * @format date-time
	 */
	endTime?: string;
	/** 操作员工名称 */
	operatorName?: string;
	/** @format int32 */
	pageNum?: number;
	/** @format int32 */
	pageSize?: number;
	sorts?: ISortFieldEnum[];
	/**
	 * 开始时间
	 * @format date-time
	 */
	startTime?: string;
	/** 系统单号 */
	systemOrderNo?: string;
	/** 仓库编码 */
	warehouse?: string;
}

/** OperateRecordPageResp */
export interface OperateRecordPageResp {
	/** 业务单号 */
	bizOrderNo?: string;
	/** @format int64 */
	id?: number;
	/** 操作节点 */
	nodeName?: string;
	/**
	 * 操作数量
	 * @format int32
	 */
	operateNums?: number;
	/**
	 * 操作时间
	 * @format date-time
	 */
	operateTime?: string;
	/** 操作内容 */
	operatorContent?: string;
	/**
	 * 操作人ID
	 * @format int64
	 */
	operatorId?: number;
	/** 操作人名称 */
	operatorName?: string;
	/** 批次包 */
	pkgCode?: string;
	/** 系统单号 */
	systemOrderNo?: string;
}

/** OpsPkgDto */
export interface OpsPkgDto {
	/** 物理库区 */
	actualRegionName?: string;
	/**
	 * 商品入库数量
	 * @format int32
	 */
	amount?: number;
	/**
	 * 客户ID
	 * @format int64
	 */
	customerId?: number;
	/** 客户名称 */
	customerName?: string;
	/**
	 * 客户店铺id
	 * @format int64
	 */
	customerShopId?: number;
	/** 客户店铺名称 */
	customerShopName?: string;
	/**
	 * 抵扣中数量
	 * @format int32
	 */
	deductioningNums?: number;
	/**
	 * 发货数
	 * @format int32
	 */
	deliveryNums?: number;
	/**
	 * 可拣数量
	 * @format int32
	 */
	enablePickNums?: number;
	/** 订单编码 */
	orderCode?: string;
	/**
	 * 类型，1-直发订单, 2-囤货订单
	 * @format int32
	 */
	orderType?: number;
	/** 包编码 */
	pkgCode?: string;
	/**
	 * 批次包类型, 1-正品, 2-次品, 3-异常, 4-异常上报采购
	 * @format int32
	 */
	pkgType?: number;
	/** 平台订单编号(直行便订单编号,用户级别) */
	platformOrderNo?: string;
	/** 商品图片(优先属性图，否则主图) */
	productImg?: string;
	/** 商品链接 */
	productLink?: string;
	/** 商品属性 */
	productProp?: string;
	/** 平台商品SKU */
	productSku?: string;
	/**
	 * 商品类型: 1-普通商品, 2-OEM商品, 3-组合商品
	 * @format int32
	 */
	productType?: number;
	/** 采购单编号 */
	purchaseNo?: string;
	/** 店铺商品SKU(店铺商品库) */
	shopProductSku?: string;
	/**
	 * 状态，1-初检，2-复检，3-精检，4-成品，5-异常，6-次品
	 * @format int32
	 */
	status?: number;
	/**
	 * 系统来源: 1-D2C; 2-B2B
	 * @format int32
	 */
	systemSource?: number;
	/** 统一客户全名 */
	unificationCustomerFullName?: string;
	/** 仓库 */
	warehouse?: string;
	/** 仓库名称 */
	warehouseName?: string;
}

/** OpsPkgInfoDto */
export interface OpsPkgInfoDto {
	/**
	 * 总库存数
	 * @format int32
	 */
	checked?: number;
	/**
	 * 发货数
	 * @format int32
	 */
	deliveryNums?: number;
	/**
	 * 可拣数量
	 * @format int32
	 */
	enablePickNums?: number;
	/**
	 * 已冻结数
	 * @format int32
	 */
	freezed?: number;
	/** 包编码 */
	pkgCode?: string;
}

/** OrderDeliveryInfoDTO */
export interface OrderDeliveryInfoDTO {
	/** @format date-time */
	createTime?: string;
	/** @format int32 */
	deliveryNums?: number;
	/** @format int32 */
	deliveryStatus?: number;
	deliveryTaskCode?: string;
	/** @format date-time */
	deliveryTime?: string;
	distribution?: string;
	freightFee?: number;
	logisticsCode?: string;
	logisticsCodes?: string[];
	/** @format int32 */
	preferFlag?: number;
	/** @format int32 */
	realDeliveryNums?: number;
	/** @format int32 */
	splitFlag?: number;
	/** @uniqueItems true */
	systemOrderNoSet?: string[];
	taskDetailList?: DeliveryTaskDetailRespDTO[];
	totalAdditionalFee?: number;
	/** @format int64 */
	weight?: number;
	wmsDistribution?: string;
	wmsForwarder?: string;
	wmsShippingType?: string;
}

/** OrderExpressRespDTO */
export interface OrderExpressRespDTO {
	/** @format date-time */
	createTime?: string;
	logisticsNumber?: string;
	logisticsTag?: string;
	/** @format date-time */
	purchaseEstimateStockTime?: string;
	/** @format date-time */
	signTime?: string;
	/** @format date-time */
	systemEstimateStockTime?: string;
	virtualLogisticsNumber?: string;
}

/** OrderItem */
export interface OrderItem {
	asc?: boolean;
	column?: string;
}

/** OrderProcessResultRespDTO */
export interface OrderProcessResultRespDTO {
	/** @format int32 */
	haveBecomeObsolete?: number;
	/** @format int32 */
	process?: number;
	/** @format int32 */
	transferredProduct?: number;
	/** @format int32 */
	unprocessed?: number;
}

/** OrderSendAddressCreateDTO */
export interface OrderSendAddressCreateDTO {
	address?: string;
	/** @format int32 */
	addressType?: number;
	area?: string;
	city?: string;
	clearanceCode?: string;
	/** @format int32 */
	clearanceType?: number;
	clubCode?: string;
	code?: string;
	companyName?: string;
	email?: string;
	mobile?: string;
	name?: string;
	nation?: string;
	platformOrderNo?: string;
	postalCode?: string;
	province?: string;
}

/** PackAbleTaskPageDTO */
export interface PackAbleTaskPageDTO {
	/** 组合任务编码 */
	packCode?: string;
	/**
	 * 可组数
	 * @format int32
	 */
	packableAmount?: number;
	/** 直行便订单号 */
	platformOrderNo?: string;
	/** 库区拣货明细 */
	regionPickList?: RegionPickDTO[];
}

/** PackAbleTaskPageReqDTO */
export interface PackAbleTaskPageReqDTO {
	/** 组合任务编码 */
	packCode?: string;
	/** @format int32 */
	pageNum?: number;
	/** @format int32 */
	pageSize?: number;
	/** 直行便订单号 */
	platformOrderNo?: string;
	sorts?: ISortFieldEnum[];
}

/** PackAllot */
export interface PackAllot {
	/** @format int32 */
	amount?: number;
	/** @format int32 */
	beactive?: number;
	/** @format date-time */
	createTime?: string;
	/** @format int64 */
	creater?: number;
	createrName?: string;
	/** @format int64 */
	id?: number;
	/** @format date-time */
	lmt?: string;
	/** @format int64 */
	modifier?: number;
	modifierName?: string;
	newPkgCode?: string;
	orderCode?: string;
	/** @format int32 */
	overFlowAmount?: number;
	pickCode?: string;
	pkgCode?: string;
	productImg?: string;
	productName?: string;
	productProp?: string;
	productSku?: string;
	/** @format int32 */
	remainAmount?: number;
	shopProductSku?: string;
	/** @format int32 */
	status?: number;
	warehouse?: string;
}

/** PackAllotPageReqDTO */
export interface PackAllotPageReqDTO {
	/** 订单编码 */
	orderCode?: string;
	/** @format int32 */
	pageNum?: number;
	/** @format int32 */
	pageSize?: number;
	/** 原批次包编码 */
	pkgCode?: string;
	/** 店铺商品SKU(店铺商品库) */
	shopProductSku?: string;
	sorts?: ISortFieldEnum[];
	/**
	 * 状态，0-待调拨，1-调拨拣货中，2-调拨完成
	 * @format int32
	 */
	status?: number;
}

/** PackAllotPkgDTO */
export interface PackAllotPkgDTO {
	/**
	 * 调拨数
	 * @format int32
	 */
	amount?: number;
	/**
	 * 删除标志，0-已删除，1-未删除
	 * @format int32
	 */
	beactive?: number;
	/**
	 * 创建时间
	 * @format date-time
	 */
	createTime?: string;
	/**
	 * 创建人
	 * @format int64
	 */
	creater?: number;
	/** 创建人名称 */
	createrName?: string;
	/** 货格码 */
	gridCode?: string;
	/**
	 * 主键id
	 * @format int64
	 */
	id?: number;
	/**
	 * 最新修改时间
	 * @format date-time
	 */
	lmt?: string;
	/**
	 * 修改人
	 * @format int64
	 */
	modifier?: number;
	/** 修改人名称 */
	modifierName?: string;
	/** 调拨订单编码 */
	orderCode?: string;
	/** 拣货任务编码 */
	pickCode?: string;
	/**
	 * 是否已拣，0-未拣，1-已拣
	 * @format int32
	 */
	picked?: number;
	/** 调拨批次包编码 */
	pkgCode?: string;
	/** 商品图片(优先属性图，否则主图) */
	productImg?: string;
	/** 商品链接 */
	productLink?: string;
	/** 商品中文名 */
	productName?: string;
	/** 商品属性 */
	productProp?: string;
	/** 平台商品SKU */
	productSku?: string;
	/**
	 * 剩余组合数
	 * @format int32
	 */
	remainCombinationAmount?: number;
	/** 店铺商品SKU(店铺商品库) */
	shopProductSku?: string;
	/** 仓库 */
	warehouse?: string;
}

/** PackAllotSyncDTO */
export interface PackAllotSyncDTO {
	/** @format int32 */
	amount?: number;
	orderCode?: string;
	/** @format int32 */
	overFlowAmount?: number;
	productImg?: string;
	productName?: string;
	productProp?: string;
	productSku?: string;
	/** @format int32 */
	remainAmount?: number;
	/** @format int32 */
	remainCombinationAmount?: number;
	shopProductSku?: string;
	warehouse?: string;
}

/** PackBasketInfoDTO */
export interface PackBasketInfoDTO {
	/** 附加项 */
	additionalList?: AdditionalInfoRespDTO[];
	/** 同组合分拣框 */
	basketVOList?: BasketVO[];
	/** 组合任务编码 */
	packCode?: string;
	/** 单组商品明细 */
	packItemVOList?: PackItemVO[];
	/** 批次包编码 */
	pkgCode?: string;
	/**
	 * 总组合数
	 * @format int32
	 */
	totalNums?: number;
	/** 体积：（XS、S、M、L、XL） */
	volume?: string;
}

/** PackFinishDto */
export interface PackFinishDto {
	/** 发货任务编号 */
	deliveryTaskCode: string;
	/**
	 * 高
	 * @format int32
	 */
	height?: number;
	/**
	 * 长
	 * @format int32
	 */
	length?: number;
	/** 平台订单号 */
	platformOrderNo?: string;
	/**
	 * 托盘数
	 * @format int32
	 */
	trayNums?: number;
	/**
	 * 宽
	 * @format int32
	 */
	width?: number;
}

/** PackFinishRespDTO */
export interface PackFinishRespDTO {
	/** 分拣容器最后一个是否完成 */
	hasFinish?: boolean;
	/** 物流面单 */
	logisticsTagUrl?: string;
	/**
	 * 拆单标记 0-未拆单；1-已拆单
	 * @format int32
	 */
	splitFlag?: number;
}

/** PackItemDTO */
export interface PackItemDTO {
	/** @format int32 */
	adoptAmount?: number;
	adoptOrderCode?: string;
	/** @format int32 */
	amount?: number;
	/** @format int32 */
	beactive?: number;
	/**
	 * 成品上架数量（组合商品）
	 * @format int32
	 */
	checkedShelvedAmount?: number;
	/**
	 * 质检上架数量（组合商品）
	 * @format int32
	 */
	checkingShelvedAmount?: number;
	/** @format date-time */
	createTime?: string;
	/** @format int64 */
	creater?: number;
	createrName?: string;
	/** @format int64 */
	id?: number;
	/** @format date-time */
	lmt?: string;
	/** @format int64 */
	modifier?: number;
	modifierName?: string;
	/** @format int32 */
	needAmount?: number;
	orderCode?: string;
	packCode?: string;
	pkgDTOList?: PackItemPickPkgVO[];
	productImg?: string;
	productMainImg?: string;
	productName?: string;
	productProp?: string;
	productSku?: string;
	shopProductSku?: string;
	/** @format int32 */
	unitAmount?: number;
	warehouse?: string;
}

/** PackItemPickPkgVO */
export interface PackItemPickPkgVO {
	/** @format int32 */
	nums?: number;
	pkgCode?: string;
	/** @format int32 */
	stockFlag?: number;
}

/** PackItemSyncDTO */
export interface PackItemSyncDTO {
	/** @format int32 */
	adoptAmount?: number;
	adoptOrderCode?: string;
	/** @format int32 */
	amount?: number;
	/** @format int32 */
	checkedFreezeQuantity?: number;
	/** @format int32 */
	checkingFreezeQuantity?: number;
	orderCode?: string;
	productImg?: string;
	productMainImg?: string;
	productName?: string;
	productProp?: string;
	productSku?: string;
	shopProductSku?: string;
	/** @format int32 */
	unitAmount?: number;
	warehouse?: string;
}

/** PackItemVO */
export interface PackItemVO {
	/** @format int32 */
	adoptAmount?: number;
	adoptOrderCode?: string;
	/** @format int32 */
	amount?: number;
	/** @format int32 */
	beactive?: number;
	/** @format int32 */
	checkingShelvedAmount?: number;
	/** @format date-time */
	createTime?: string;
	/** @format int64 */
	creater?: number;
	createrName?: string;
	/** @format int64 */
	id?: number;
	/** @format date-time */
	lmt?: string;
	/** @format int64 */
	modifier?: number;
	modifierName?: string;
	/** @format int32 */
	needAmount?: number;
	orderCode?: string;
	packCode?: string;
	pkgDTOList?: PackItemPickPkgVO[];
	productImg?: string;
	productMainImg?: string;
	productName?: string;
	productProp?: string;
	productSku?: string;
	shopProductSku?: string;
	/** @format int32 */
	unitAmount?: number;
	warehouse?: string;
}

/** PackPickBasketRespDTO */
export interface PackPickBasketRespDTO {
	/** 可组合 */
	combinableList?: string[];
	/** 未使用 */
	unUsedList?: string[];
	/** 使用中 */
	usingList?: string[];
}

/** PackPickTaskPageReqDTO */
export interface PackPickTaskPageReqDTO {
	/** 组合任务编码 */
	packCode?: string;
	/** @format int32 */
	pageNum?: number;
	/** @format int32 */
	pageSize?: number;
	/** 拣货任务编码 */
	pickCode?: string;
	/**
	 * 类型，0-组合，1-调拨
	 * @format int32
	 */
	pickType?: number;
	/** 包编码 */
	pkgCode?: string;
	/** 平台订单号 */
	platformOrderNo?: string;
	sorts?: ISortFieldEnum[];
	/**
	 * 状态，0-未完成，1-已完成
	 * @format int32
	 */
	status?: number;
}

/** PackPkg */
export interface PackPkg {
	basketCode?: string;
	/** @format int32 */
	beactive?: number;
	/** @format int32 */
	combinableStatus?: number;
	/** @format date-time */
	createTime?: string;
	/** @format int64 */
	creater?: number;
	createrName?: string;
	/** @format int64 */
	id?: number;
	/** @format date-time */
	lmt?: string;
	mainPkgCode?: string;
	/** @format int64 */
	modifier?: number;
	modifierName?: string;
	orderCode?: string;
	packCode?: string;
	pickCode?: string;
	/** @format int32 */
	picked?: number;
	pkgCode?: string;
	productImg?: string;
	productLink?: string;
	productName?: string;
	productProp?: string;
	productSku?: string;
	shopProductSku?: string;
	/** @format int32 */
	stockFlag?: number;
	/** @format int32 */
	useAmount?: number;
	warehouse?: string;
}

/** PackPkgDTO */
export interface PackPkgDTO {
	/** 分拣蓝编码 */
	basketCode?: string;
	/**
	 * 删除标志，0-已删除，1-未删除
	 * @format int32
	 */
	beactive?: number;
	/**
	 * 创建时间
	 * @format date-time
	 */
	createTime?: string;
	/**
	 * 创建人
	 * @format int64
	 */
	creater?: number;
	/** 创建人名称 */
	createrName?: string;
	/** 货格码 */
	gridCode?: string;
	/**
	 * 主键id
	 * @format int64
	 */
	id?: number;
	/**
	 * 最新修改时间
	 * @format date-time
	 */
	lmt?: string;
	/** 主商品批次包编码 */
	mainPkgCode?: string;
	/**
	 * 修改人
	 * @format int64
	 */
	modifier?: number;
	/** 修改人名称 */
	modifierName?: string;
	/** 订单编码 */
	orderCode?: string;
	/** 组合任务编码 */
	packCode?: string;
	/** 拣货任务编码 */
	pickCode?: string;
	/**
	 * 是否已拣，0-未拣，1-已拣
	 * @format int32
	 */
	picked?: number;
	/** 消耗的子商品批次包编码 */
	pkgCode?: string;
	/** 商品图片(优先属性图，否则主图) */
	productImg?: string;
	/** 商品链接 */
	productLink?: string;
	/** 商品中文名 */
	productName?: string;
	/** 商品属性 */
	productProp?: string;
	/** 平台商品SKU */
	productSku?: string;
	/** 库区名称 */
	regionName?: string;
	/** 店铺商品SKU(店铺商品库) */
	shopProductSku?: string;
	/**
	 * 使用数
	 * @format int32
	 */
	useAmount?: number;
	/** 仓库 */
	warehouse?: string;
}

/** PackPkgVO */
export interface PackPkgVO {
	basketCode?: string;
	/** @format int32 */
	beactive?: number;
	/** @format int32 */
	combinableFlag?: number;
	/** @format date-time */
	createTime?: string;
	/** @format int64 */
	creater?: number;
	createrName?: string;
	/** @format int64 */
	id?: number;
	/** @format date-time */
	lmt?: string;
	mainPkgCode?: string;
	/** @format int64 */
	modifier?: number;
	modifierName?: string;
	orderCode?: string;
	packCode?: string;
	pickCode?: string;
	/** @format int32 */
	picked?: number;
	pkgCode?: string;
	productImg?: string;
	productLink?: string;
	productName?: string;
	productProp?: string;
	productSku?: string;
	shopProductSku?: string;
	/** @format int32 */
	useAmount?: number;
	warehouse?: string;
}

/** PackRecordVO */
export interface PackRecordVO {
	/** @format int32 */
	amount?: number;
	basketCode?: string;
	/** @format int32 */
	beactive?: number;
	/** @format date-time */
	createTime?: string;
	/** @format int64 */
	creater?: number;
	createrName?: string;
	/** @format int64 */
	id?: number;
	/** @format date-time */
	lmt?: string;
	/** @format int64 */
	modifier?: number;
	modifierName?: string;
	orderCode?: string;
	packCode?: string;
	pickCode?: string;
	/** @format int32 */
	pickNum?: number;
	/** @format int32 */
	pickType?: number;
	pkgCode?: string;
	productSku?: string;
	shopProductSku?: string;
	/** @format int32 */
	status?: number;
	warehouse?: string;
}

/** PackSortingDTO */
export interface PackSortingDTO {
	/** 拣货任务编号 */
	pickCode?: string;
	/** 批次包编码 */
	pkgCode?: string;
}

/** PackTaskPageDTO */
export interface PackTaskPageDTO {
	/** @format int32 */
	amount?: number;
	/** @format int32 */
	beactive?: number;
	/**
	 * 已组合数量
	 * @format int32
	 */
	combinedAmount?: number;
	/** @format date-time */
	createTime?: string;
	/** @format int64 */
	creater?: number;
	createrName?: string;
	/** @format int64 */
	customerShopId?: number;
	customerShopName?: string;
	/** @format int32 */
	expectAmount?: number;
	/** @format int64 */
	id?: number;
	/** @format date-time */
	lmt?: string;
	/** @format int64 */
	modifier?: number;
	modifierName?: string;
	orderCode?: string;
	packCode?: string;
	/** 子商品列表 */
	packItems?: PackItemDTO[];
	/** 组合记录 */
	packRecords?: PackRecordVO[];
	/** @format int32 */
	packableAmount?: number;
	pickCode?: string;
	pkgCode?: string;
	platformOrderNo?: string;
	productImg?: string;
	productLink?: string;
	productName?: string;
	productProp?: string;
	productSku?: string;
	/** @format int32 */
	settled?: number;
	shopProductSku?: string;
	/** @format int32 */
	status?: number;
	/** @format int32 */
	systemSource?: number;
	warehouse?: string;
}

/** PackTaskPageReqDTO */
export interface PackTaskPageReqDTO {
	/**
	 * 客户店铺id
	 * @format int64
	 */
	customerShopId?: number;
	/** 订单编码 */
	orderCode?: string;
	/** 组合任务编码 */
	packCode?: string;
	/** @format int32 */
	pageNum?: number;
	/** @format int32 */
	pageSize?: number;
	/** 直行便订单号 */
	platformOrderNo?: string;
	/** 店铺商品SKU(店铺商品库) */
	shopProductSku?: string;
	sorts?: ISortFieldEnum[];
	/**
	 * 状态，0-待处理，1-组合中，2-已完成
	 * @format int32
	 */
	status?: number;
}

/** PackTaskSettleRespDTO */
export interface PackTaskSettleRespDTO {
	/** @format int32 */
	expectAmount?: number;
	orderCode?: string;
}

/** PackTaskSyncDTO */
export interface PackTaskSyncDTO {
	/** @format int32 */
	amount?: number;
	/** @format int64 */
	customerShopId?: number;
	customerShopName?: string;
	items?: PackItemSyncDTO[];
	orderCode?: string;
	platformOrderNo?: string;
	productImg?: string;
	productLink?: string;
	productName?: string;
	productProp?: string;
	productSku?: string;
	shopProductSku?: string;
	/** @format int32 */
	systemSource?: number;
	warehouse?: string;
}

/** PageDefaultLabelConfigReqDTO */
export interface PageDefaultLabelConfigReqDTO {
	/** @format int32 */
	pageNum?: number;
	/** @format int32 */
	pageSize?: number;
	sorts?: ISortFieldEnum[];
}

/** PageDefaultLabelConfigRespDTO */
export interface PageDefaultLabelConfigRespDTO {
	/**
	 * 创建日期
	 * @format date-time
	 */
	createTime?: string;
	/**
	 * 创建人ID
	 * @format int64
	 */
	creatorId?: number;
	/** 创建人名称 */
	creatorName?: string;
	/**
	 * 修改人ID
	 * @format int64
	 */
	editorId?: number;
	/** 修改人名称 */
	editorName?: string;
	/**
	 * 主键id
	 * @format int64
	 */
	id?: number;
	/**
	 * 是否逻辑删除(0:未删除 1:已删除)
	 * @format int32
	 */
	isDelete?: number;
	/** 节点名称 */
	nodeName?: string;
	/**
	 * 节点类型(1:简单检品打印 2:水洗标打印)
	 * @format int32
	 */
	nodeType?: number;
	stationCode?: string;
	/**
	 * 系统来源(1:D2C 2:B2B)
	 * @format int32
	 */
	systemSource?: number;
	/** 模板编码 */
	templateCode?: string;
	/** 模板名称 */
	templateName?: string;
	/**
	 * 修改时间
	 * @format date-time
	 */
	updateTime?: string;
	/** 仓库 */
	warehouse?: string;
}

/** PagePrintConfigReqDTO */
export interface PagePrintConfigReqDTO {
	/**
	 * 是否开启(0:否 1:是)
	 * @format int32
	 */
	isOpen?: number;
	/** MAC地址 */
	macAddress?: string;
	/** @format int32 */
	pageNum?: number;
	/** @format int32 */
	pageSize?: number;
	/**
	 * 打印业务类型(1:面单 2:批次水洗标)
	 * @format int32
	 */
	printBusinessType?: number;
	/** 打印纸尺寸(高) */
	printPaperSizeHigh?: string;
	/** 打印纸尺寸(宽) */
	printPaperSizeWide?: string;
	/** 打印机 */
	printer?: string;
	sorts?: ISortFieldEnum[];
}

/** PagePrintConfigRespDTO */
export interface PagePrintConfigRespDTO {
	/**
	 * 创建日期
	 * @format date-time
	 */
	createTime?: string;
	/**
	 * 创建人ID
	 * @format int64
	 */
	creatorId?: number;
	/** 创建人名称 */
	creatorName?: string;
	/**
	 * 方向(1:纵向 2:横向)
	 * @format int32
	 */
	direction?: number;
	/**
	 * 修改人ID
	 * @format int64
	 */
	editorId?: number;
	/** 修改人名称 */
	editorName?: string;
	/**
	 * 主键id
	 * @format int64
	 */
	id?: number;
	/**
	 * 是否逻辑删除(0:未删除 1:已删除)
	 * @format int32
	 */
	isDelete?: number;
	/**
	 * 是否开启(0:否 1:是)
	 * @format int32
	 */
	isOpen?: number;
	/** MAC地址 */
	macAddress?: string;
	/** 节点名称 */
	nodeName?: string;
	/**
	 * 节点类型(1:简单检品打印 2:水洗标打印)
	 * @format int32
	 */
	nodeType?: number;
	/**
	 * 打印业务类型(1:面单 2:批次水洗标)
	 * @format int32
	 */
	printBusinessType?: number;
	/** 打印纸尺寸(高) */
	printPaperSizeHigh?: string;
	/** 打印纸尺寸(宽) */
	printPaperSizeWide?: string;
	/** 打印机 */
	printer?: string;
	/**
	 * 系统来源(1:D2C 2:B2B)
	 * @format int32
	 */
	systemSource?: number;
	/**
	 * 修改时间
	 * @format date-time
	 */
	updateTime?: string;
	/** 仓库 */
	warehouse?: string;
}

/** PendingBadQuantityRespDTO */
export interface PendingBadQuantityRespDTO {
	orderProcessResult?: OrderProcessResultRespDTO;
	shopProductSku?: string;
}

/** PendingDefectiveReqDTO */
export interface PendingDefectiveReqDTO {
	customerShopIds?: number[];
	shopProductSkus?: string[];
}

/** PendingDefectiveRespDTO */
export interface PendingDefectiveRespDTO {
	/** @format int64 */
	customerShopId?: number;
	/** @format int32 */
	defectiveAmount?: number;
	shopProductSku?: string;
}

/** PickBatchTaskStartReq */
export interface PickBatchTaskStartReq {
	/** 拣货批次编码 */
	batchCode?: string;
	/** 容器编码 */
	containerCode?: string;
}

/** PickBatchTaskStartResp */
export interface PickBatchTaskStartResp {
	/** 拣货任务编码 */
	pickTaskCode?: string;
}

/** PickContainerBindReq */
export interface PickContainerBindReq {
	/** 批次任务编码 */
	batchCode?: string;
	/** 拣货容器编码 */
	containerCode?: string;
}

/** PickContainerUnBindReq */
export interface PickContainerUnBindReq {
	/** 批次编码 */
	batchCode?: string;
	/** 拣货容器编码 */
	containerCode?: string;
}

/** PickSortingDTO */
export interface PickSortingDTO {
	/** 分拣框分配明细 */
	packPkgVOList?: PackPkgVO[];
	/**
	 * 是否已完成分拣状态
	 * @format int32
	 */
	status?: number;
}

/** PickStatusDTO */
export interface PickStatusDTO {
	orderCode?: string;
	/** @format int32 */
	status?: number;
}

/** PickTaskPageDTO */
export interface PickTaskPageDTO {
	/**
	 * 总数量
	 * @format int32
	 */
	amount?: number;
	/** @format int32 */
	beactive?: number;
	/**
	 * 已组合数量
	 * @format int32
	 */
	combinedAmount?: number;
	/** @format date-time */
	createTime?: string;
	/** @format int64 */
	creater?: number;
	createrName?: string;
	/** @format int64 */
	id?: number;
	/** @format date-time */
	lmt?: string;
	/** 主商品批次包 */
	mainPkgList?: string[];
	/** @format int64 */
	modifier?: number;
	modifierName?: string;
	/**
	 * 是否需要分拣，0-否 1-是
	 * @format int32
	 */
	needPick?: number;
	/**
	 * 拣货数量
	 * @format int32
	 */
	nums?: number;
	/** 单品调拨批次包列表 */
	packAllotPkgs?: PackAllotPkgDTO[];
	/** 组合任务编码 */
	packCode?: string;
	/** 组合批次包列表 */
	packPkgs?: PackPkgDTO[];
	/** 组合记录 */
	packRecords?: PackRecordVO[];
	pickCode?: string;
	/** @format int32 */
	pickType?: number;
	/** 平台订单号 */
	platformOrderNo?: string;
	/** 库区名称 */
	regionName?: string;
	/** @format int32 */
	status?: number;
	warehouse?: string;
}

/** PickingTaskNotListedReqDTO */
export interface PickingTaskNotListedReqDTO {
	/**
	 * 发货任务编号集合
	 * @uniqueItems true
	 */
	deliveryTaskCodes?: string[];
	/** @format int32 */
	pageNum?: number;
	/** @format int32 */
	pageSize?: number;
	/**
	 * 拣货类型(0:大件拣货 1:小件单品 2:小件多品 3:FBA拣货 4:次转正 5:国内拣货)
	 * @format int32
	 */
	pickType?: number;
	/**
	 * 店铺商品sku
	 * @uniqueItems true
	 */
	shopProductSkus?: string[];
	sorts?: ISortFieldEnum[];
}

/** PickingTaskNotListedRespDTO */
export interface PickingTaskNotListedRespDTO {
	/** 发货任务编号 */
	deliveryTaskCode?: string;
	/** @format int32 */
	depotDeliveryNums?: number;
	/** @format int32 */
	orderDeliveryNums?: number;
	/** 订单编号 */
	orderNo?: string;
	/** 包编码 */
	pkgCodes?: string[];
	/** 商品图片 */
	productImg?: string;
	/** 商品链接 */
	productLink?: string;
	/** 商品sku */
	productSku?: string;
	/** 店铺商品sku */
	shopProductSku?: string;
}

/** Pkg */
export interface Pkg {
	actualRegionName?: string;
	/** @format int32 */
	actualRegionType?: number;
	/** @format int32 */
	aloneNums?: number;
	/** @format int32 */
	amount?: number;
	/** @format int32 */
	beactive?: number;
	/** @format int32 */
	bizType?: number;
	/** @format int32 */
	checkedAmount?: number;
	/** @format int32 */
	checkedShelvFlag?: number;
	/** @format int32 */
	checkingShelvFlag?: number;
	/** @format int32 */
	checkingShelvedAmount?: number;
	/** @format int32 */
	combinationProductItemFlag?: number;
	/** @format int32 */
	combinationStatus?: number;
	/** @format date-time */
	createTime?: string;
	/** @format int64 */
	creater?: number;
	createrName?: string;
	/** @format int64 */
	customerId?: number;
	customerName?: string;
	/** @format int64 */
	customerShopId?: number;
	customerShopName?: string;
	/** @format int32 */
	customerTag?: number;
	/** @format int32 */
	deductionNums?: number;
	/** @format int32 */
	deliveryNums?: number;
	dictateOrderCode?: string;
	/** @format int32 */
	directSaleFlag?: number;
	domesticLogisticsNo?: string;
	dutyCode?: string;
	dutyName?: string;
	/** @format int32 */
	enablePickNums?: number;
	/** @format int32 */
	exceptionCode?: number;
	exceptionRelation?: string;
	expressNo?: string;
	/** @format int32 */
	freezeNums?: number;
	gridCode?: string;
	/** @format int64 */
	id?: number;
	inboundCode?: string;
	/** @format int32 */
	inventoryAgeFlag?: number;
	/** @format int32 */
	isRoot?: number;
	itemCategory?: string;
	/** @format date-time */
	lmt?: string;
	materialInfoJa?: string;
	materialInfoZh?: string;
	/** @format int64 */
	modifier?: number;
	modifierName?: string;
	/** @format int32 */
	oemProductClassificationId?: number;
	/** @format int32 */
	operationType?: number;
	orderCode?: string;
	/** @format int32 */
	orderType?: number;
	/** @format int32 */
	originAmount?: number;
	packAllotFlag?: boolean;
	parentCode?: string;
	picRelation?: string;
	/** @format int32 */
	pickable?: number;
	pkgCode?: string;
	/** @format int32 */
	pkgOwn?: number;
	/** @format int32 */
	pkgType?: number;
	platformOrderNo?: string;
	productImg?: string;
	productLink?: string;
	productName?: string;
	productProp?: string;
	productSku?: string;
	/** @format int32 */
	productType?: number;
	purchaseNo?: string;
	/** @format int32 */
	purchaseType?: number;
	reconcileFlag?: boolean;
	regionCode?: string;
	/** @format int32 */
	regularizationFlag?: number;
	/** @format int32 */
	remainCombinationAmount?: number;
	remark?: string;
	rootCode?: string;
	/** @format int32 */
	serviceStatus?: number;
	/** @format int32 */
	settled?: number;
	/** @format date-time */
	shelvedTime?: string;
	shopProductSku?: string;
	singleProductFlag?: boolean;
	specialCheckRequirement?: string;
	specialCheckRequirementImg?: string;
	/** @format int32 */
	spiltType?: number;
	stationCode?: string;
	/** @format int32 */
	status?: number;
	stockCode?: string;
	/** @format int32 */
	stockable?: number;
	/** @format int32 */
	systemSource?: number;
	unificationCustomerFullName?: string;
	/** @format date-time */
	upShelfTime?: string;
	/** @format int32 */
	urgent?: number;
	/** @format int32 */
	useAmount?: number;
	volume?: string;
	warehouse?: string;
}

/** PkgAdjustRecordDto */
export interface PkgAdjustRecordDto {
	bizNo?: string;
	/** @format int32 */
	bizType?: number;
	content?: string;
	/** @format date-time */
	operationTime?: string;
	operator?: string;
	pkgCode?: string;
	reason?: string;
	requestJson?: string;
	/** @format int32 */
	sourceEnablePickNums?: number;
	/** @format int32 */
	targetEnablePickNums?: number;
}

/** PkgDTO */
export interface PkgDTO {
	/** 物理库区 */
	actualRegionName?: string;
	/** 附加项 */
	additionalList?: AdditionalInfoRespDTO[];
	/**
	 * 落单数量
	 * @format int32
	 */
	aloneNums?: number;
	/**
	 * 商品数量
	 * @format int32
	 */
	amount?: number;
	/**
	 * 删除标志，0-已删除，1-未删除
	 * @format int32
	 */
	beactive?: number;
	/**
	 * 业务类型: 1-档口(市场购);2-线上平台（透明购）
	 * @format int32
	 */
	bizType?: number;
	/**
	 * 商品成品数量(调拨到成品区时的数量)
	 * @format int32
	 */
	checkedAmount?: number;
	/** @format int32 */
	checkedShelvFlag?: number;
	/**
	 * 质检上架标志，0-未质检上架，1-已质检上架
	 * @format int32
	 */
	checkingShelvFlag?: number;
	/**
	 * 质检上架数量（组合商品）
	 * @format int32
	 */
	checkingShelvedAmount?: number;
	/**
	 * 是否是组合商品的子商品(0:不是 1:是)
	 * @format int32
	 */
	combinationProductItemFlag?: number;
	/**
	 * 组合状态：0-未组合; 1-组合中；2-组合完成；3-取消组合
	 * @format int32
	 */
	combinationStatus?: number;
	/**
	 * 创建时间
	 * @format date-time
	 */
	createTime?: string;
	/**
	 * 创建人
	 * @format int64
	 */
	creater?: number;
	/** 创建人名称 */
	createrName?: string;
	/**
	 * 客户ID
	 * @format int64
	 */
	customerId?: number;
	/** 客户名称 */
	customerName?: string;
	/** 客户商品SKU(客户商品库) */
	customerProductSku?: string;
	/**
	 * 客户店铺id
	 * @format int64
	 */
	customerShopId?: number;
	/** 客户店铺名称 */
	customerShopName?: string;
	/**
	 * 1:OEM重点客户-项目,2:OEM重点客户-非项目
	 * @format int32
	 */
	customerTag?: number;
	/** @format int32 */
	deductionNums?: number;
	/**
	 * 发货数
	 * @format int32
	 */
	deliveryNums?: number;
	/** 支配订单编码（组合商品） */
	dictateOrderCode?: string;
	/**
	 * 订单采购类型: 0 代采 1 直采
	 * @format int32
	 */
	directSaleFlag?: number;
	/** 国内物流单号 */
	domesticLogisticsNo?: string;
	/** 采购员编码 */
	dutyCode?: string;
	/** 采购员名称 */
	dutyName?: string;
	/**
	 * 可拣数量
	 * @format int32
	 */
	enablePickNums?: number;
	/**
	 * 异常代码 0-正常, 101-到错货, 102-少件, 103-多件, 201-颜色差异, 202-商品上有中文, 203-配件差异（口袋, 腰带, 纽扣, 拉链, 拉绳等）, 204-logo差异, 205-尺码差异, 206-设计差异（压线，领口，袖口，花纹，衣服设计等）, 207-材质/面料差异, 301-色差, 302-污渍/斑点/水印, 303-破损, 304-线头, 305-接缝不良, 306-抽丝, 307-针眼, 308-霉菌, 309-生锈, 310-商品有中文, 311-纽扣破损, 312-拉链破损, 313-附属品缺陷, 314-有气味, 315-其他不良
	 * @format int32
	 */
	exceptionCode?: number;
	/** 异常关联编码 */
	exceptionRelation?: string;
	/** 收货快递单号 */
	expressNo?: string;
	/**
	 * 冻结数量
	 * @format int32
	 */
	freezeNums?: number;
	/** 货格码 */
	gridCode?: string;
	/**
	 * 主键id
	 * @format int64
	 */
	id?: number;
	/** 入库单号 */
	inboundCode?: string;
	/**
	 * 附加项辅料标记：0无; 1有
	 * @format int32
	 */
	ingredientsFlag?: number;
	/**
	 * 是否根包，0-否，1-是
	 * @format int32
	 */
	isRoot?: number;
	/** 中文品名 */
	itemCategory?: string;
	/**
	 * 最新修改时间
	 * @format date-time
	 */
	lmt?: string;
	/** 客户经理 */
	managerName?: string;
	/** 材质信息日文 */
	materialInfoJa?: string;
	/** 材质信息中文 */
	materialInfoZh?: string;
	/**
	 * 修改人
	 * @format int64
	 */
	modifier?: number;
	/** 修改人名称 */
	modifierName?: string;
	/** 新品测量标志 */
	needMeasureFlag?: boolean;
	/** 新品拍照标志 */
	needPhotographFlag?: boolean;
	/**
	 * OEM商品分类id：1-服装; 2-杂货; 3-辅料
	 * @format int32
	 */
	oemProductClassificationId?: number;
	/**
	 * 操作类型，1-正品入库, 2-次品入库, 3-异常上报用户入库, 4-异常上报采购入库
	 * @format int32
	 */
	operationType?: number;
	/** 操作记录 */
	operations?: PkgOperationDTO[];
	/** 订单编码 */
	orderCode?: string;
	/**
	 * 类型，1-直发订单, 2-囤货订单
	 * @format int32
	 */
	orderType?: number;
	/**
	 * 原始数量
	 * @format int32
	 */
	originAmount?: number;
	/** 组合商品单品售卖标志(0-否; 1-是) */
	packAllotFlag?: boolean;
	/** 父包编码 */
	parentCode?: string;
	/** 图片关联编码 */
	picRelation?: string;
	/**
	 * 是否可拣，0-不可拣，1-可拣
	 * @format int32
	 */
	pickable?: number;
	/** 包编码 */
	pkgCode?: string;
	/**
	 * 归属，0-未知，1-客户，2-平台，3-供应商
	 * @format int32
	 */
	pkgOwn?: number;
	/**
	 * 包类型, 1-正品, 2-次品, 3-异常, 4-异常上报采购
	 * @format int32
	 */
	pkgType?: number;
	/** 平台订单编号(直行便订单编号,用户级别) */
	platformOrderNo?: string;
	/**
	 * 审单处理状态(0:待处理 1:待确认 2:已确认 3:已取消)
	 * @format int32
	 */
	processingStatus?: number;
	/** @format int32 */
	productDesignation?: number;
	/** 商品图片(优先属性图，否则主图) */
	productImg?: string;
	/** 商品链接 */
	productLink?: string;
	/** 商品测量记录 */
	productMeasureDtoList?: ProductMeasureDto[];
	/** 商品测量图片 */
	productMeasureImageUrlList?: string[];
	/** 商品中文名 */
	productName?: string;
	/** 商品属性 */
	productProp?: string;
	/** 平台商品SKU */
	productSku?: string;
	/**
	 * 商品类型: 1-普通商品, 2-OEM商品, 3-组合商品
	 * @format int32
	 */
	productType?: number;
	/** 采购单编号 */
	purchaseNo?: string;
	/**
	 * 采购状态：0 采购中;1 采购下单;2采购完成 3 采购作废
	 * @format int32
	 */
	purchaseStatus?: number;
	/**
	 * 0 普通采购单 1 OEM大货单 2 OEM样品单
	 * @format int32
	 */
	purchaseType?: number;
	/** 推荐库位 */
	recommendedLocation?: string;
	/** 是否对账(0-未对账; 1-已对账) */
	reconcileFlag?: boolean;
	/** 库区编码 */
	regionCode?: string;
	/** 库区名称 */
	regionName?: string;
	/**
	 * 剩余数
	 * @format int32
	 */
	remainAmount?: number;
	/**
	 * 剩余组合数
	 * @format int32
	 */
	remainCombinationAmount?: number;
	/**
	 * 剩余数量
	 * @format int32
	 */
	remainingQuantity?: number;
	/** 备注 */
	remark?: string;
	/** 根包编码 */
	rootCode?: string;
	/**
	 * 状态, 0-未解决, 1-已解决
	 * @format int32
	 */
	serviceStatus?: number;
	/**
	 * 是否清算，0-未清算，1-已清算
	 * @format int32
	 */
	settled?: number;
	/** @format date-time */
	shelvedTime?: string;
	/** 店铺商品SKU(店铺商品库) */
	shopProductSku?: string;
	/** 是否单品(0-多品; 1-单品) */
	singleProductFlag?: boolean;
	/** 特殊质检要求 */
	specialCheckRequirement?: string;
	/** 特殊质检要求图片 */
	specialCheckRequirementImg?: string;
	/**
	 * 特殊质检要求标记：0无; 1有
	 * @format int32
	 */
	specialFlag?: number;
	/**
	 * 1 移库拆包
	 * @format int32
	 */
	spiltType?: number;
	/** 站点code */
	stationCode?: string;
	/**
	 * 状态，1-初检，2-复检，3-精检，4-成品，5-异常，6-次品
	 * @format int32
	 */
	status?: number;
	/** 入库明细编码 */
	stockCode?: string;
	/**
	 * 库存类型
	 * @format int32
	 */
	stockType?: number;
	/**
	 * 0-订单，1-库存
	 * @format int32
	 */
	stockable?: number;
	/**
	 * 系统来源: 1-D2C; 2-B2B
	 * @format int32
	 */
	systemSource?: number;
	/** 统一客户全名 */
	unificationCustomerFullName?: string;
	/**
	 * 是否紧急，0-否，1-是
	 * @format int32
	 */
	urgent?: number;
	/**
	 * 本次使用商品数量
	 * @format int32
	 */
	useAmount?: number;
	volume?: string;
	/** 仓库 */
	warehouse?: string;
}

/** PkgDeductionPageReq */
export interface PkgDeductionPageReq {
	/** @format int32 */
	pageNum?: number;
	/** @format int32 */
	pageSize?: number;
	/** 批次包编码 */
	pkgCode?: string;
	sorts?: ISortFieldEnum[];
}

/** PkgDeductionPageResp */
export interface PkgDeductionPageResp {
	/**
	 * 取消时间
	 * @format date-time
	 */
	cancelTime?: string;
	/**
	 * 抵扣时间
	 * @format date-time
	 */
	deductionTime?: string;
	/** 发货任务编号 */
	deliveryTaskCode?: string;
	/**
	 * 发货时间
	 * @format date-time
	 */
	deliveryTime?: string;
	/** @format int64 */
	id?: number;
	/**
	 * 库龄
	 * @format int32
	 */
	inventoryAge?: number;
	/**
	 * 数量
	 * @format int32
	 */
	nums?: number;
	/** 订单编号 */
	orderNo?: string;
	/** 平台订单编号 */
	platformOrderNo?: string;
	/**
	 * 状态（1-抵扣中，2-已发货，3-已取消）
	 * @format int32
	 */
	status?: number;
}

/** PkgOperationDTO */
export interface PkgOperationDTO {
	/**
	 * 删除标志，0-已删除，1-未删除
	 * @format int32
	 */
	beactive?: number;
	/** 业务编码 */
	bizCode?: string;
	/** 子包编码 */
	childPkg?: string;
	/** 操作内容 */
	context?: string;
	/**
	 * 创建时间
	 * @format date-time
	 */
	createTime?: string;
	/**
	 * 创建人
	 * @format int64
	 */
	creater?: number;
	/** 创建人名称 */
	createrName?: string;
	/** 货格码 */
	gridCode?: string;
	/**
	 * 最新修改时间
	 * @format date-time
	 */
	lmt?: string;
	/**
	 * 修改人
	 * @format int64
	 */
	modifier?: number;
	/** 修改人名称 */
	modifierName?: string;
	/**
	 * 操作类型，1-入库，2-库区调拨，3-拆包，4-上架, 5-扣减
	 * @format int32
	 */
	operationType?: number;
	/** 批次包编码 */
	pkgCode?: string;
	/** 店铺商品SKU(店铺商品库) */
	shopProductSku?: string;
	/** 源库区名称 */
	sourceName?: string;
	/** 源库区 */
	sourceRegion?: string;
	/** 目标库区名称 */
	targetName?: string;
	/** 目标库区 */
	targetRegion?: string;
	/**
	 * 扣减数量
	 * @format int32
	 */
	useAmount?: number;
	/** 仓库 */
	warehouse?: string;
}

/** PkgPackageOptDTO */
export interface PkgPackageOptDTO {
	/**
	 * 打包数
	 * @format int32
	 */
	nums?: number;
	/** 系统订单号 */
	orderCode?: string;
	/** 批次包编码 */
	pkgCode?: string;
	/** 店铺商品sku */
	shopProductSku?: string;
}

/** PkgPageDTO */
export interface PkgPageDTO {
	/** 物理库区 */
	actualRegionName?: string;
	/**
	 * 质检上架标志，0-未质检上架，1-已质检上架
	 * @format int32
	 */
	checkingShelvFlag?: number;
	/**
	 * 是否是组合商品的子商品(0:不是 1:是)
	 * @format int32
	 */
	combinationProductItemFlag?: number;
	/**
	 * 创建时间开始
	 * @format date-time
	 */
	createTimeBegin?: string;
	/**
	 * 创建时间结束
	 * @format date-time
	 */
	createTimeEnd?: string;
	/** 客户名称 */
	customerName?: string;
	/** 入库单号 */
	inboundCode?: string;
	/** 订单编码 */
	orderCode?: string;
	/** @format int32 */
	pageNum?: number;
	/** @format int32 */
	pageSize?: number;
	/** 包编码 */
	pkgCode?: string;
	/** 包编码集合 */
	pkgCodeList?: string[];
	/**
	 * 归属，0-未知，1-客户，2-平台
	 * @format int32
	 */
	pkgOwn?: number;
	/** 包类型, 1-正品, 2-次品, 3-异常, 4-异常上报采购 */
	pkgType?: number[];
	/** 直行便订单号 */
	platformOrderNo?: string;
	/** 平台商品SKU */
	productSkus?: string[];
	/** 采购单编号 */
	purchaseNo?: string;
	/**
	 * 0 普通采购单 1 OEM大货单 2 OEM样品单
	 * @format int32
	 */
	purchaseType?: number;
	/** 库区编码 */
	regionCode?: string;
	/** 店铺商品SKU(店铺商品库) */
	shopProductSku?: string;
	sorts?: ISortFieldEnum[];
	/**
	 * 系统来源: 1-D2C; 2-B2B
	 * @format int32
	 */
	systemSource?: number;
}

/** PkgPageReqDTO */
export interface PkgPageReqDTO {
	customerShopId?: string;
	/** @format int32 */
	pageNum?: number;
	/** @format int32 */
	pageSize?: number;
	sorts?: ISortFieldEnum[];
}

/** PkgPageRespDTO */
export interface PkgPageRespDTO {
	/** @format int32 */
	amount?: number;
	/** @format date-time */
	createTime?: string;
	customerShopId?: string;
	customerShopName?: string;
	dutyCode?: string;
	dutyName?: string;
	/** @format int32 */
	exceptionCode?: number;
	/** @format int32 */
	exceptionType?: number;
	expressNo?: string;
	gridCode?: string;
	/** @format int32 */
	isRoot?: number;
	itemCategory?: string;
	orderCode?: string;
	/** @format int32 */
	orderType?: number;
	/** @format int32 */
	originAmount?: number;
	parentCode?: string;
	/** @format int32 */
	pickable?: number;
	pkgCode?: string;
	/** @format int32 */
	pkgType?: number;
	productImg?: string;
	productLink?: string;
	productName?: string;
	productProp?: string;
	productSku?: string;
	purchaseNo?: string;
	regionCode?: string;
	remark?: string;
	rootCode?: string;
	shopProductSku?: string;
	/** @format int32 */
	status?: number;
	stockCode?: string;
	/** @format int32 */
	stockable?: number;
	/** @format int32 */
	systemSource?: number;
	/** @format int32 */
	urgent?: number;
	warehouse?: string;
}

/** PkgSkuInfoReqDTO */
export interface PkgSkuInfoReqDTO {
	/** @format int64 */
	customerId?: number;
	/** @format int64 */
	customerShopId?: number;
	shopProductSku?: string;
	warehouse?: string;
}

/** PkgSkuInfoRespDTO */
export interface PkgSkuInfoRespDTO {
	/** @format int32 */
	abnormalNums?: number;
	/** @format int32 */
	allTotalNums?: number;
	/** @format int32 */
	bagNums?: number;
	/** @format int32 */
	checkingNums?: number;
	/** @format int32 */
	normalNums?: number;
	/** @format int32 */
	onShelvesNums?: number;
	shopProductSku?: string;
	/** @format int32 */
	transportationAllocateNums?: number;
	/** @format int32 */
	transportationNums?: number;
}

/** PkgSplitReqDTO */
export interface PkgSplitReqDTO {
	/**
	 * 数量
	 * @format int32
	 */
	amount?: number;
	/** 异常编码列表 */
	exceptionCodeList?: number[];
	/**
	 * 异常来源
	 * @format int32
	 */
	exceptionSource?: number;
	/** 图片关联编码 */
	picRelation?: string;
	/** 批次包编码 */
	pkgCode?: string;
	/**
	 * 包类型, 1-正品, 2-次品, 3-异常
	 * @format int32
	 */
	pkgType?: number;
	/** 备注 */
	remark?: string;
}

/** PrintConfigReqDTO */
export interface PrintConfigReqDTO {
	/**
	 * 方向(1:纵向 2:横向)
	 * @format int32
	 */
	direction?: number;
	/**
	 * 是否开启(0:否 1:是)
	 * @format int32
	 */
	isOpen?: number;
	/** MAC地址 */
	macAddress?: string;
	/** 节点名称 */
	nodeName?: string;
	/**
	 * 节点类型(1:简单检品打印 2:水洗标打印)
	 * @format int32
	 */
	nodeType?: number;
	/**
	 * 打印业务类型(1:面单 2:批次水洗标)
	 * @format int32
	 */
	printBusinessType?: number;
	/** 打印纸尺寸(高) */
	printPaperSizeHigh?: string;
	/** 打印纸尺寸(宽) */
	printPaperSizeWide?: string;
	/** 打印机 */
	printer?: string;
	/**
	 * 系统来源(1:D2C 2:B2B)
	 * @format int32
	 */
	systemSource?: number;
}

/** PrintConfigVO */
export interface PrintConfigVO {
	/**
	 * 创建日期
	 * @format date-time
	 */
	createTime?: string;
	/**
	 * 创建人ID
	 * @format int64
	 */
	creatorId?: number;
	/** 创建人名称 */
	creatorName?: string;
	/**
	 * 方向(1:纵向 2:横向)
	 * @format int32
	 */
	direction?: number;
	/**
	 * 修改人ID
	 * @format int64
	 */
	editorId?: number;
	/** 修改人名称 */
	editorName?: string;
	/**
	 * 主键id
	 * @format int64
	 */
	id?: number;
	/**
	 * 是否逻辑删除(0:未删除 1:已删除)
	 * @format int32
	 */
	isDelete?: number;
	/**
	 * 是否开启(0:否 1:是)
	 * @format int32
	 */
	isOpen?: number;
	/** MAC地址 */
	macAddress?: string;
	/** 节点名称 */
	nodeName?: string;
	/**
	 * 节点类型(1:简单检品打印 2:水洗标打印)
	 * @format int32
	 */
	nodeType?: number;
	/**
	 * 打印业务类型(1:面单 2:批次水洗标)
	 * @format int32
	 */
	printBusinessType?: number;
	/** 打印纸尺寸(高) */
	printPaperSizeHigh?: string;
	/** 打印纸尺寸(宽) */
	printPaperSizeWide?: string;
	/** 打印机 */
	printer?: string;
	/**
	 * 系统来源(1:D2C 2:B2B)
	 * @format int32
	 */
	systemSource?: number;
	/**
	 * 修改时间
	 * @format date-time
	 */
	updateTime?: string;
	/** 仓库 */
	warehouse?: string;
}

/** PrintTemplate */
export interface PrintTemplate {
	/** @format int32 */
	beactive?: number;
	/** @format date-time */
	createTime?: string;
	/** @format int64 */
	creater?: number;
	createrName?: string;
	/** @format int64 */
	id?: number;
	/** @format date-time */
	lmt?: string;
	/** @format int64 */
	modifier?: number;
	modifierName?: string;
	/** @format int32 */
	systemSource?: number;
	templateCode?: string;
	templateHtml?: string;
	templateJson?: string;
	templateName?: string;
	/** @format int32 */
	templateType?: number;
	warehouse?: string;
}

/** ProcessingStatusRespDTO */
export interface ProcessingStatusRespDTO {
	/**
	 * 异常
	 * @format int32
	 */
	abnormal?: number;
	/**
	 * 待处理
	 * @format int32
	 */
	pending?: number;
	/**
	 * 已处理
	 * @format int32
	 */
	processed?: number;
	/**
	 * 待确认
	 * @format int32
	 */
	toBeConfirmed?: number;
}

/** ProductDTO */
export interface ProductDTO {
	/** 物流所属异常信息 */
	abnormalFlowInfoDTOList?: AbnormalFlowInfoDTO[];
	/**
	 * 包裹类型标志 0-未处理；1-已换货，2-补发，默认0
	 * @format int32
	 */
	changeGoodsTag?: number;
	/**
	 * 异常数量
	 * @format int32
	 */
	confirmNum?: number;
	/**
	 * 客户ID
	 * @format int64
	 */
	customerId?: number;
	/** 客户名称 */
	customerName?: string;
	/**
	 * 客户店铺id
	 * @format int64
	 */
	customerShopId?: number;
	/** 客户店铺名称 */
	customerShopName?: string;
	/**
	 * 订单采购类型: 0 代采 1 直采
	 * @format int32
	 */
	directSaleFlag?: number;
	/** 次品上报 */
	errorRelationCode?: string;
	/** 异常上报客户 */
	exception2CustomerRelationCode?: string;
	/** 异常上报业务 */
	exception2ServiceRelationCode?: string;
	/** 历史采购低价 */
	historicalLowPrice?: number;
	/** 中文品名 */
	itemCategory?: string;
	/**
	 * 需采购的总件数
	 * @format int32
	 */
	num?: number;
	/** 平台商品SPU */
	productCode?: string;
	/** 商品图片(优先属性图，否则主图) */
	productImg?: string;
	/** 商品链接 */
	productLink?: string;
	/** 商品名称 */
	productName?: string;
	/** 参考单价 */
	productPrice?: number;
	/** 商品属性 */
	productProp?: string;
	/** 平台商品SKU */
	productSku?: string;
	/**
	 * 采购单号
	 * @format int64
	 */
	purchaseId?: number;
	/**
	 * 采购商品详情ID
	 * @format int64
	 */
	purchaseItemId?: number;
	/** 采购单编号 */
	purchaseNo?: string;
	/** 采购单价 */
	purchaseUnitPrice?: number;
	/**
	 * 检品数量
	 * @format int32
	 */
	qcNum?: number;
	/**
	 * 检品状态：0-未检品；1-部分检品；2-全部检品
	 * @format int32
	 */
	qcStatus?: number;
	/** 推荐商品sku */
	recommendSku?: string;
	/** 客户订单信息 */
	relationRespDTOList?: PurchaseOrderItemRelationRespDTO[];
	/** 采购备注 */
	remark?: string;
	/**
	 * 销售数量
	 * @format int32
	 */
	sellQuantity?: number;
	/** 销售单价 */
	sellUnitPrice?: number;
	specialCheckInfoList?: SpecialCheckDTO[];
	/**
	 * 抽检数量
	 * @format int32
	 */
	spotCheckNum?: number;
	/** 档口价格 */
	stallPrice?: number;
	/** 入库单信息 */
	stockInDTOList?: StockInDTO[];
	/**
	 * 入库数量
	 * @format int32
	 */
	stockInNum?: number;
	/**
	 * 入库状态：0-未入库；1-部分入库；2-全部入库
	 * @format int32
	 */
	stockInStatus?: number;
	/** 体积：（XS、S、M、L、XL） */
	volume?: string;
}

/** ProductDetail */
export interface ProductDetail {
	/** @format int32 */
	deliveryNums?: number;
	productPropertiesName?: string;
	productSku?: string;
	productTitle?: string;
}

/** ProductMeasureAddReqDTO */
export interface ProductMeasureAddReqDTO {
	/** @format int64 */
	customerShopId?: number;
	customerShopName?: string;
	orderCode?: string;
	platformOrderNo?: string;
	productSku?: string;
	shopProductSku?: string;
	/** @format int32 */
	systemSource?: number;
	warehouse?: string;
}

/** ProductMeasureDto */
export interface ProductMeasureDto {
	/** @format int32 */
	code?: number;
	name?: string;
	value?: string;
}

/** ProductMeasureEditImageReqDTO */
export interface ProductMeasureEditImageReqDTO {
	/** 系统订单编码 */
	orderCode?: string;
	/** 图片url */
	urls?: string[];
}

/** ProductMeasureEditSizeReqDTO */
export interface ProductMeasureEditSizeReqDTO {
	/** 测量结果 */
	list?: ProductMeasureDto[];
	/** 系统订单编码 */
	orderCode?: string;
}

/** ProductMeasurePageQuery */
export interface ProductMeasurePageQuery {
	/**
	 * 客户店铺id
	 * @format int64
	 */
	customerShopId?: number;
	/** @format int32 */
	pageNum?: number;
	/** @format int32 */
	pageSize?: number;
	/** 直行便订单编号 */
	platformOrderNo?: string;
	/** 平台商品SKU */
	productSku?: string;
	sorts?: ISortFieldEnum[];
	/**
	 * 系统来源: 1-D2C; 2-B2B
	 * @format int32
	 */
	systemSource?: number;
}

/** ProductMeasureRespDTO */
export interface ProductMeasureRespDTO {
	/**
	 * 创建日期
	 * @format date-time
	 */
	createTime?: string;
	/**
	 * 创建人ID
	 * @format int64
	 */
	creatorId?: number;
	/** 创建人名称 */
	creatorName?: string;
	/**
	 * 修改人ID
	 * @format int64
	 */
	editorId?: number;
	/** 修改人名称 */
	editorName?: string;
	/**
	 * id
	 * @format int64
	 */
	id?: number;
	/**
	 * 是否逻辑删除(0:未删除 1:已删除)
	 * @format int32
	 */
	isDelete?: number;
	/**
	 * 测量人ID
	 * @format int64
	 */
	measureId?: number;
	/** 测量人名称 */
	measureName?: string;
	/**
	 * 测量时间
	 * @format date-time
	 */
	measureTime?: string;
	/** 系统订单编号 */
	orderCode?: string;
	/** 直行便订单编号 */
	platformOrderNo?: string;
	/** 测量结果 */
	productMeasureDtoList?: ProductMeasureDto[];
	/** 平台商品SKU */
	productSku?: string;
	/** 客户商品SKU */
	shopProductSku?: string;
	/**
	 * 修改时间
	 * @format date-time
	 */
	updateTime?: string;
	/** 图片 */
	urls?: string[];
	/** 仓库 */
	warehouse?: string;
}

/** ProductPropOptionDTO */
export interface ProductPropOptionDTO {
	/** 商品属性 */
	productPropertiesName?: string;
	/** 平台商品SKU */
	productSku?: string;
	/** 商品图片 */
	productSkuImg?: string;
}

/** PurchaseDto */
export interface PurchaseDto {
	/** 支付账号 */
	alipayAccount?: string;
	/**
	 * 业务类型: 1-档口(市场购);2-线上平台（透明购） 3-工厂定制
	 * @format int32
	 */
	bizType?: number;
	/**
	 * 重拍次数
	 * @format int32
	 */
	createNum?: number;
	/**
	 * 创建日期
	 * @format date-time
	 */
	createTime?: string;
	/**
	 * 停滞天数
	 * @format int32
	 */
	delayDays?: number;
	/** 流程编码 */
	flowCode?: string;
	/** 入库单号 */
	inboundCode?: string;
	/**
	 * 入库单状态 1-待签收 2-入库中 3-待确认 4-已完成 5-已关闭
	 * @format int32
	 */
	inboundStatus?: number;
	/** 国内物流单号 */
	logisticsNumberList?: OrderExpressRespDTO[];
	/**
	 * 需采购的总件数
	 * @format int32
	 */
	num?: number;
	/**
	 * OEM商品分类表id
	 * @format int64
	 */
	oemProductClassificationId?: number;
	/**
	 * 实际支付时间
	 * @format date-time
	 */
	payTime?: string;
	/**
	 * 拿货状态 0 未同步 1 空闲/ 2 接单/ 3 完成
	 * @format int32
	 */
	pickStatus?: number;
	/** 平台类型: TB-淘宝; AM-1688; TM-天猫 */
	platformType?: string;
	/** 商品列表 */
	productDTOList?: ProductDTO[];
	/**
	 * 采购完成时间
	 * @format date-time
	 */
	purchaseCompleteTime?: string;
	/**
	 * 采购下单时间
	 * @format date-time
	 */
	purchaseCreateTime?: string;
	/** 国内总运费 */
	purchaseFreight?: number;
	/**
	 * 采购单ID
	 * @format int64
	 */
	purchaseId?: number;
	/**
	 * 采购入库类型：1-正常采购、2-补发、3-换货 4 oem多件采购入库
	 * @format int32
	 */
	purchaseInboundType?: number;
	/** 采购商品总价 */
	purchaseMoney?: number;
	/** 采购单编号 */
	purchaseNo?: string;
	/**
	 * 实际采购总数
	 * @format int32
	 */
	purchaseNum?: number;
	/** 采购商品 */
	purchaseOrderItemDTOList?: PurchaseOrderItemDTO[];
	/** 采购单备注 */
	purchaseRemark?: string;
	/**
	 * 采购状态：0 采购中;1 采购下单;2采购完成
	 * @format int32
	 */
	purchaseStatus?: number;
	/**
	 * 0 普通采购单 1 OEM大货单 2 OEM样品单
	 * @format int32
	 */
	purchaseType?: number;
	/** 采购员用户id */
	purchaseUserId?: string;
	/** 采购员用户姓名 */
	purchaseUserName?: string;
	/** 异常采购单号 */
	purchasingAbnormalOrderNo?: string;
	/** 供应商店铺编码 */
	shopCode?: string;
	/** 供应商店铺名 */
	shopName?: string;
	/**
	 * 签收数量
	 * @format int32
	 */
	signNum?: number;
	/** 是否有特殊质检要求标记 */
	specialFlag?: boolean;
	/**
	 * 采购单确认时间
	 * @format date-time
	 */
	splitConfirmTime?: string;
	/** 采购单确认人ID */
	splitConfirmUserId?: string;
	/** 采购单确认人名称 */
	splitConfirmUserName?: string;
	/**
	 * 分单日期
	 * @format date-time
	 */
	splitDate?: string;
	/** 供应商所在商场楼层 */
	stallFloorNumber?: string;
	/** 供应商所在商场 */
	stallName?: string;
	/** 档口号 */
	stallRoomNumber?: string;
	/**
	 * 全部入库时间
	 * @format date-time
	 */
	stockCompleteTime?: string;
	/**
	 * 入库数量
	 * @format int32
	 */
	stockInNum?: number;
	/**
	 * 入库状态：0-未入库；1-部分入库；2-全部入库
	 * @format int32
	 */
	stockInStatus?: number;
	/**
	 * 小程序同步状态 0 1
	 * @format int32
	 */
	synchronousStatus?: number;
	/**
	 * 系统来源: 1-D2C; 2-B2B
	 * @format int32
	 */
	systemSource?: number;
	/** 淘宝账号 */
	tbAccount?: string;
	/** 淘宝单号 */
	tbOrderNo?: string;
	/**
	 * 运输中、未入库数量
	 * @format int32
	 */
	totalInTransitNum?: number;
	/**
	 * 入库数量--包含所有异常次品数量
	 * @format int32
	 */
	totalStockInNum?: number;
	/** 交易流水号 */
	tradeNo?: string;
	/**
	 * 更新日期
	 * @format date-time
	 */
	updateTime?: string;
	/**
	 * 是否可见 1 0
	 * @format int32
	 */
	viewStatus?: number;
	/** 体积：（XS、S、M、L、XL） */
	volume?: string;
	/** 仓库编码 */
	warehouse?: string;
	/**
	 * 仓库表id
	 * @format int32
	 */
	warehouseId?: number;
}

/** PurchaseOrderItemDTO */
export interface PurchaseOrderItemDTO {
	abnormalFlowInfoDTOList?: AbnormalFlowInfoDTO[];
	/** @format int32 */
	changeGoodsTag?: number;
	/** @format int32 */
	createOrderStatus?: number;
	customerTags?: number[];
	errorMsg?: string;
	historicalLowPrice?: number;
	itemCategory?: string;
	/** @format int32 */
	maxOverFlowNum?: number;
	productCode?: string;
	productImg?: string;
	productLink?: string;
	productName?: string;
	productPrice?: number;
	productProp?: string;
	productSku?: string;
	productStallNumber?: string;
	/** @format int32 */
	productTag?: number;
	/** @format int64 */
	purchaseId?: number;
	/** @format int64 */
	purchaseItemId?: number;
	purchaseNo?: string;
	/** @format int32 */
	purchaseNum?: number;
	purchaseUnitPrice?: number;
	recommendProductSku?: string;
	relationRespDTOList?: PurchaseOrderItemRelationRespDTO[];
	remark?: string;
	/** @format int32 */
	sellQuantity?: number;
	sellUnitPrice?: number;
	/** @format int32 */
	signNum?: number;
	stallPrice?: number;
	/** @format int32 */
	stockInNum?: number;
	/** @format int32 */
	stockInStatus?: number;
	/** @format int32 */
	yinYangTag?: number;
}

/** PurchaseOrderItemRelationRespDTO */
export interface PurchaseOrderItemRelationRespDTO {
	actualUnitPrice?: number;
	/** @format int32 */
	combinationProductItemFlag?: number;
	/** @format date-time */
	createTime?: string;
	/** @format int64 */
	customerId?: number;
	customerName?: string;
	customerProductSku?: string;
	/** @format int64 */
	customerShopId?: number;
	customerShopName?: string;
	/** @format int32 */
	customerTag?: number;
	/** @format int32 */
	directSaleFlag?: number;
	isUseDiscount?: boolean;
	/** @format int64 */
	mainCustomerId?: number;
	/** @format int32 */
	num?: number;
	/** @format int64 */
	oemProductClassificationId?: number;
	/** @format int64 */
	orderId?: number;
	/** @format int64 */
	orderItemId?: number;
	orderNo?: string;
	/** @format date-time */
	orderTime?: string;
	/** @format int32 */
	orderType?: number;
	originalProductLink?: string;
	originalProductSku?: string;
	platformOrderNo?: string;
	productCode?: string;
	productImg?: string;
	productLink?: string;
	productName?: string;
	productProp?: string;
	productSku?: string;
	/** @format int32 */
	productTag?: number;
	/** @format int32 */
	productType?: number;
	/** @format int64 */
	purchaseOrderId?: number;
	/** @format int64 */
	purchaseOrderItemId?: number;
	/** @format int64 */
	purchaseOrderItemRelationId?: number;
	/** @format int32 */
	purchasePriceAdd?: number;
	recommendProductSku?: string;
	/** @format int32 */
	saleAmount?: number;
	/** @format int32 */
	sellQuantity?: number;
	sellUnitPrice?: number;
	settlePrice?: number;
	/** @format int32 */
	singleProductFlag?: number;
	singleProductFlagStatus?: boolean;
	specialCheckInfoList?: SpecialCheckDTO[];
	stationCode?: string;
	/** @format int32 */
	status?: number;
	totalActualPrice?: number;
	totalSellPrice?: number;
	/** @format int32 */
	transformStockStatus?: number;
	unificationCustomerFullName?: string;
	/** @format date-time */
	updateTime?: string;
}

/** PurchaseOrderRespVO */
export interface PurchaseOrderRespVO {
	/** @format int32 */
	abnormalStatus?: number;
	accountType?: string;
	alipayAccount?: string;
	/** @format int32 */
	bizType?: number;
	/** 是否需要质检标志 */
	checkingFlag?: boolean;
	/** @format date-time */
	createTime?: string;
	/** @format int32 */
	delayDays?: number;
	/** 物流单号 */
	expressNo?: string;
	/** 入库单号 */
	inboundCode?: string;
	/** @format int32 */
	num?: number;
	/** @format int32 */
	oemProductClassificationId?: number;
	/** @format date-time */
	payTime?: string;
	/** @format int32 */
	pickStatus?: number;
	platformType?: string;
	/** @format date-time */
	purchaseCompleteTime?: string;
	/** @format date-time */
	purchaseCreateTime?: string;
	purchaseFreight?: number;
	/** @format int64 */
	purchaseId?: number;
	purchaseMoney?: number;
	purchaseNo?: string;
	/** @format int32 */
	purchaseNum?: number;
	/** @format int32 */
	purchaseStatus?: number;
	/** @format int32 */
	purchaseType?: number;
	purchaseUserId?: string;
	purchaseUserName?: string;
	purchasingAbnormalOrderNo?: string;
	/** @format int32 */
	reconciliationStatus?: number;
	remark?: string;
	shopCode?: string;
	shopName?: string;
	/** @format int32 */
	signNum?: number;
	/** @format date-time */
	splitConfirmTime?: string;
	splitConfirmUserId?: string;
	splitConfirmUserName?: string;
	/** @format date-time */
	splitDate?: string;
	stallFloorNumber?: string;
	stallName?: string;
	stallRoomNumber?: string;
	/** @format date-time */
	stockCompleteTime?: string;
	/** @format int32 */
	stockInNum?: number;
	/** @format int32 */
	stockInStatus?: number;
	/** @format int32 */
	syncStatus?: number;
	/** @format int32 */
	synchronousStatus?: number;
	/** @format int32 */
	systemSource?: number;
	tbAccount?: string;
	tbOrderNo?: string;
	/** @format date-time */
	updateTime?: string;
	/** @format int32 */
	viewStatus?: number;
	wareCode?: string;
}

/** QueryCustomerStockPkgReqDto */
export interface QueryCustomerStockPkgReqDto {
	/**
	 * 子账号id
	 * @format int64
	 */
	customerId?: number;
	/** 客户名称 */
	customerName?: string;
	/**
	 * 主账号id
	 * @format int64
	 */
	mainCustomerId?: number;
	/** 批次包编码 */
	pkgCode?: string;
	/** 平台订单号 */
	platformOrderNo?: string;
	/** 平台sku */
	productSku?: string;
	/** 店铺商品sku */
	shopProductSku?: string;
}

/** QueryShopSkuByAgeReq */
export interface QueryShopSkuByAgeReq {
	/** @format int64 */
	customerShopId?: number;
	/** @format int32 */
	endAgeQuantity?: number;
	/** @format int32 */
	startAgeQuantity?: number;
}

/** Region */
export interface Region {
	/** @format int32 */
	beactive?: number;
	/** @format int32 */
	bindStatus?: number;
	contactPhone?: string;
	/** @format date-time */
	createTime?: string;
	/** @format int64 */
	creater?: number;
	createrName?: string;
	/** @format int64 */
	id?: number;
	/** @format date-time */
	lmt?: string;
	/** @format int64 */
	modifier?: number;
	modifierName?: string;
	regionAddress?: string;
	regionCode?: string;
	regionContact?: string;
	regionName?: string;
	warehouse?: string;
}

/** RegionPickDTO */
export interface RegionPickDTO {
	/** 可拣货商品明细 */
	itemDTOList?: RegionPickItemDTO[];
	/**
	 * 拣货数量
	 * @format int32
	 */
	nums?: number;
	/** 库区名称 */
	regionName?: string;
}

/** RegionPickItemDTO */
export interface RegionPickItemDTO {
	/** 商品图片 */
	productImg?: string;
	/** 主图 */
	productMainImg?: string;
	/** 商品中文名 */
	productName?: string;
	/** 商品属性 */
	productProp?: string;
	/** 平台商品SKU */
	productSku?: string;
	/** 店铺商品SKU */
	shopProductSku?: string;
	/**
	 * 单位组合消耗数量
	 * @format int32
	 */
	unitAmount?: number;
	/**
	 * 使用数
	 * @format int32
	 */
	useAmount?: number;
}

/** RegularizationOperationVO */
export interface RegularizationOperationVO {
	bizCode?: string;
	context?: string;
	/** @format date-time */
	createTime?: string;
	/** @format int64 */
	creatorId?: number;
	creatorName?: string;
	/** @format int64 */
	editorId?: number;
	editorName?: string;
	/** @format int64 */
	id?: number;
	/** @format int32 */
	isDelete?: number;
	/** @format int32 */
	operationType?: number;
	/** @format date-time */
	updateTime?: string;
	warehouse?: string;
}

/** RegularizationPageRespDTO */
export interface RegularizationPageRespDTO {
	/** 分页列表次品转正品 */
	regularizationPage?: PageRegularizationRespDTO;
	/** 次品转正品总数统计 */
	regularizationStatus?: RegularizationStatusRespDTO;
}

/** RegularizationReqDTO */
export interface RegularizationReqDTO {
	/** 创建结束日期 */
	createEndTime?: string;
	/** 创建开始日期 */
	createStartTime?: string;
	/** 新包编码 */
	newPkgCode?: string;
	/** @format int32 */
	pageNum?: number;
	/** @format int32 */
	pageSize?: number;
	/** 包编码 */
	pkgCode?: string;
	sorts?: ISortFieldEnum[];
	/**
	 * 状态(1:待处理 2:处理中 3:已完成)
	 * @format int32
	 */
	state?: number;
}

/** RegularizationRespDTO */
export interface RegularizationRespDTO {
	additionName?: string;
	additionNameJp?: string;
	additionTotal?: number;
	/** @format date-time */
	becomeRegularTime?: string;
	/** @format int32 */
	combinationProductItemFlag?: number;
	/** @format date-time */
	createTime?: string;
	/** @format int64 */
	creatorId?: number;
	creatorName?: string;
	/** @format int64 */
	customerShopId?: number;
	customerShopName?: string;
	/** @format int32 */
	defectiveAmount?: number;
	defectiveCode?: string;
	defectiveImg?: string;
	defectiveImgList?: string[];
	defectiveReason?: string;
	/** @format int64 */
	editorId?: number;
	editorName?: string;
	gridCode?: string;
	/** @format int64 */
	id?: number;
	inboundOrderAdditionList?: InboundOrderAdditionRespDTO[];
	/** @format int32 */
	isDelete?: number;
	moveProductName?: string;
	/** @format date-time */
	moveProductTime?: string;
	newPkgCode?: string;
	/** @format int32 */
	noAdditionalFlag?: number;
	/** @format int32 */
	oemProductClassificationId?: number;
	/** @format int32 */
	operateState?: number;
	orderAdditionCode?: string;
	orderCode?: string;
	/** @format int32 */
	orderProcessResult?: number;
	/** @format date-time */
	orderProcessTime?: string;
	/** @format int32 */
	orderType?: number;
	pkgCode?: string;
	platformOrderNo?: string;
	/** @format int32 */
	printBatchFlag?: number;
	productImg?: string;
	productName?: string;
	productProp?: string;
	productSku?: string;
	/** @format int32 */
	productType?: number;
	regionCode?: string;
	regionName?: string;
	regularOperationName?: string;
	/** @format date-time */
	regularOperationTime?: string;
	regularizationCode?: string;
	settleAdditionAmount?: number;
	shopProductSku?: string;
	/** @format int32 */
	state?: number;
	/** @format int32 */
	systemSource?: number;
	/** @format date-time */
	updateTime?: string;
	warehouse?: string;
}

/** RegularizationStatusRespDTO */
export interface RegularizationStatusRespDTO {
	/**
	 * 全部
	 * @format int32
	 */
	all?: number;
	/**
	 * 已完成
	 * @format int32
	 */
	completed?: number;
	/**
	 * 待处理
	 * @format int32
	 */
	pending?: number;
	/**
	 * 处理中
	 * @format int32
	 */
	processing?: number;
}

/** RemainCombinationAmountReqDTO */
export interface RemainCombinationAmountReqDTO {
	orderCode?: string;
	/** @format int32 */
	remainCombinationAmount?: number;
}

/** RemainProductDTO */
export interface RemainProductDTO {
	orderCode?: string;
	/** @format int32 */
	quantity?: number;
	shopProductSku?: string;
}

/** RemoveBoxTemplateReqDTO */
export interface RemoveBoxTemplateReqDTO {
	/**
	 * 模板ID
	 * @format int64
	 */
	boxTemplateId?: number;
}

/** RemoveDeliveryPackageReqDTO */
export interface RemoveDeliveryPackageReqDTO {
	/** 包裹编号 */
	deliveryPackageCode?: string;
}

/** RenewCustomsReqDTO */
export interface RenewCustomsReqDTO {
	/**
	 * 发货任务详情ID
	 * @format int64
	 */
	deliveryTaskDetailId?: number;
	/** 平台商品SKU */
	productSku?: string;
}

/** ReselectBadAdditionalItemsReqDTO */
export interface ReselectBadAdditionalItemsReqDTO {
	inboundOrderAdditionList?: InboundOrderAdditionReqDTO[];
	/** @format int32 */
	noAdditionalFlag?: number;
	orderNo?: string;
	pkgCode?: string;
	shopProductSku?: string;
}

/** ReviewDeclarationInfoReqDTO */
export interface ReviewDeclarationInfoReqDTO {
	/**
	 * 发货审核详情主键ID
	 * @format int64
	 */
	deliveryReviewDetailId?: number;
	/** 发货任务编号 */
	deliveryTaskCode?: string;
	/** 商品SKU */
	productSku?: string;
}

/** SameRecipientQuery */
export interface SameRecipientQuery {
	/** 发货单号 */
	deliveryTaskCode?: string;
}

/** SelectPackageReqDTO */
export interface SelectPackageReqDTO {
	/** 发货任务编号 */
	deliveryTaskCode?: string;
}

/** ShelveReqDTO */
export interface ShelveReqDTO {
	/** 货格码 */
	gridCode?: string;
	/** 批次包编码列表 */
	pkgCodeList?: string[];
}

/** ShippingAddressRespDTO */
export interface ShippingAddressRespDTO {
	/** 详细地址 */
	address?: string;
	/**
	 * 类型(0:收件人地址 1:英文收货地址 2:英文清关地址 3:发件人地址)
	 * @format int32
	 */
	addressType?: number;
	/** 区/县 */
	area?: string;
	/** 城市 */
	city?: string;
	/** 会社编码 */
	clubCode?: string;
	/** 国家code */
	code?: string;
	/** 公司名 */
	companyName?: string;
	/** 联系邮箱 */
	email?: string;
	/** 联系方式 */
	mobile?: string;
	/** 姓名 */
	name?: string;
	/** 国家 */
	nation?: string;
	/** 邮编 */
	postalCode?: string;
	/** 省/州 */
	province?: string;
}

/** SignAllTransferWarehouseReqDTO */
export interface SignAllTransferWarehouseReqDTO {
	/** 物流单号 */
	logisticsNo?: string;
	/** 签收批次包编号列表 */
	pkgCodeList?: string[];
	/** 成品库区编码 */
	regionCode?: string;
}

/** SignDTO */
export interface SignDTO {
	/** 是否已经签收: true 是 false 否 */
	hasSigned?: boolean;
	/** 采购单详情 */
	purchaseOrderRespDTO?: PurchaseOrderRespVO;
	/** 采购单详情集合，返回多个时使用 */
	purchaseOrderRespDTOList?: PurchaseOrderRespVO[];
	/** 签收类型，0-未知，1-采购单签收，2-仓库调拨签收 */
	signType?: string;
	/** 调拨发货单 */
	transferWarehouseDelivery?: TransferWarehouseDelivery;
}

/** SignFlowReqDTO */
export interface SignFlowReqDTO {
	/** 收货快递单号 */
	expressNo?: string;
	/** 快递面单签收人电话(扫不出) */
	expressPhone?: string;
	/** 备注 */
	remark?: string;
}

/** SignRecord */
export interface SignRecord {
	/** @format int32 */
	beactive?: number;
	/** @format int32 */
	boxAmount?: number;
	/** @format date-time */
	createTime?: string;
	/** @format int64 */
	creater?: number;
	createrName?: string;
	expressNo?: string;
	/** @format date-time */
	flowDate?: string;
	/** @format int64 */
	id?: number;
	/** @format date-time */
	lmt?: string;
	/** @format int64 */
	modifier?: number;
	modifierName?: string;
	purchaseNo?: string;
	remark?: string;
	/** @format int32 */
	reported?: number;
	serviceCode?: string;
	signType?: string;
	/** @format int32 */
	systemSource?: number;
	/** @format int32 */
	totalAmount?: number;
	warehouse?: string;
	/** @format int32 */
	weight?: number;
}

/** SignRecordQueryReqDTO */
export interface SignRecordQueryReqDTO {
	/**
	 * 创建时间开始
	 * @format date-time
	 */
	createTimeBegin?: string;
	/**
	 * 创建时间结束
	 * @format date-time
	 */
	createTimeEnd?: string;
	/** 运单号 */
	expressNo?: string;
	/** @format int32 */
	pageNum?: number;
	/** @format int32 */
	pageSize?: number;
	sorts?: ISortFieldEnum[];
	/**
	 * 系统来源: 1-D2C; 2-B2B
	 * @format int32
	 */
	systemSource?: number;
	/** 仓库 */
	warehouse?: string;
}

/** SinglePackPkgCheckReq */
export interface SinglePackPkgCheckReq {
	/** 批次包编码 */
	pkgCode?: string;
}

/** SinglePackPkgCheckResp */
export interface SinglePackPkgCheckResp {
	/** 是否存在多个容器 */
	hasMoreContainer?: boolean;
}

/** SingleReviewReqDTO */
export interface SingleReviewReqDTO {
	/** 审核日本配送公司名称（客户） */
	auditDistribution?: string;
	/** 审核中国货代公司名称 */
	auditForwarder?: string;
	/**
	 * 审核中国货代公司id
	 * @format int64
	 */
	auditIntForwarderConfigId?: number;
	/**
	 * 审核日本配送公司id（客户）
	 * @format int64
	 */
	auditIntShipConfigId?: number;
	/** 审核原因 */
	auditReason?: string;
	/** 审核原因编码 */
	auditReasonCode?: string;
	/** 审核原因(日文) */
	auditReasonJp?: string;
	/** 审核备注 */
	auditRemark?: string;
	/** 审核运输方式 */
	auditShippingType?: string;
	/** 审单SKU里列表，只有在auditType为按照sku审核有效 */
	auditSkuList?: DeliveryReivewSkuReqDTO[];
	/**
	 * 审单类型: 0 保持不变 1 整单审核 2 按照SKU审核
	 * @format int32
	 */
	auditType?: number;
	/** 发货审单编号 */
	deliveryReviewCode?: string;
}

/** SkuInventoryInitReq */
export interface SkuInventoryInitReq {
	/** @format int64 */
	customerId?: number;
	shopProductSku?: string;
	warehouse?: string;
}

/** SkuInventoryPageReqDTO */
export interface SkuInventoryPageReqDTO {
	/** @format int32 */
	pageNum?: number;
	/** @format int32 */
	pageSize?: number;
	shopProductSkus?: string[];
	sorts?: ISortFieldEnum[];
	warehouse?: string;
}

/** SkuInventoryRespDTO */
export interface SkuInventoryRespDTO {
	shopProductSku?: string;
	/** @format int32 */
	stockCheckedNums?: number;
	/** @format int32 */
	stockFreezedNums?: number;
	/** @format int32 */
	transportationAllocateNums?: number;
	/** @format int32 */
	transportationNums?: number;
}

/** SpecialCheckDTO */
export interface SpecialCheckDTO {
	/** @format int32 */
	orderQuantity?: number;
	platformOrderNo?: string;
	productSku?: string;
	shopProductSku?: string;
	specialCheckRequirement?: string;
	specialCheckRequirementImg?: string;
	systemOrderNo?: string;
}

/** SplitOrderLogisticsReqDTO */
export interface SplitOrderLogisticsReqDTO {
	/** 原发货任务编号 */
	deliveryTaskCode?: string;
}

/** SplitPackDeliveryTaskDetailRespDTO */
export interface SplitPackDeliveryTaskDetailRespDTO {
	/** 批次包号集合 */
	pkgCodeList?: string[];
	/** 商品图片 */
	productImg?: string;
	/** 商品sku */
	productSku?: string;
	/**
	 * 实际发货数
	 * @format int32
	 */
	realDeliveryNums?: number;
	/** 店铺商品sku */
	shopProductSku?: string;
}

/** SplitPackDeliveryTaskRespDTO */
export interface SplitPackDeliveryTaskRespDTO {
	/** 新发货任务编号 */
	deliveryTaskCode?: string;
	/** 物流单号 */
	logisticsCode?: string;
	/** 物流公司code */
	logisticsCompanyCode?: string;
	/** 物流标签地址 */
	logisticsTagUrl?: string;
	/**
	 * 包裹序号
	 * @format int32
	 */
	packageNo?: number;
	/**
	 * 实际发货总数
	 * @format int32
	 */
	realDeliveryNums?: number;
	/** 发货任务详情 */
	taskDetailList?: SplitPackDeliveryTaskDetailRespDTO[];
	/**
	 * 总重量（克）
	 * @format int64
	 */
	totalWeight?: number;
	/**
	 * 实际发货的中国货代公司id
	 * @format int64
	 */
	wmsIntForwarderConfigId?: number;
}

/** Stock */
export interface Stock {
	/** @format int32 */
	beactive?: number;
	/** @format int32 */
	checked?: number;
	/** @format date-time */
	createTime?: string;
	/** @format int64 */
	creater?: number;
	createrName?: string;
	customerShopId?: string;
	customerShopName?: string;
	/** @format int32 */
	freezed?: number;
	/** @format int64 */
	id?: number;
	itemCategory?: string;
	/** @format date-time */
	lmt?: string;
	/** @format int64 */
	modifier?: number;
	modifierName?: string;
	/** @format int32 */
	oemProductClassificationId?: number;
	productImg?: string;
	productLink?: string;
	productName?: string;
	productProp?: string;
	productSku?: string;
	/** @format int32 */
	productType?: number;
	/** @format int32 */
	shelved?: number;
	shopProductSku?: string;
	/** @format int32 */
	stockType?: number;
	/** @format int32 */
	systemSource?: number;
	warehouse?: string;
}

/** StockBatchAllocateReqDTO */
export interface StockBatchAllocateReqDTO {
	/** @format int32 */
	allocateAmount?: number;
	bizEnum?:
		| 'DECREASE'
		| 'DELIVERY_UNFREEZE'
		| 'FBA_UNFREEZE'
		| 'FREEZE'
		| 'INCREASE'
		| 'INGREDIENTS_UNFREEZE'
		| 'INIT'
		| 'PLAN_CANCEL'
		| 'PLAN_CREATE'
		| 'UNFREEZE';
	bizId?: string;
	/** @format int64 */
	customerId?: number;
	platformOrderNo?: string;
	skuAllocateDetails?: StockBatchAllocateReqDetail[];
	warehouse?: string;
}

/** StockBatchAllocateReqDetail */
export interface StockBatchAllocateReqDetail {
	shopProductSku?: string;
	systemItemId?: string;
	/** @format int32 */
	useTransportation?: number;
}

/** StockBatchAllocateRespDTO */
export interface StockBatchAllocateRespDTO {
	bizId?: string;
	/** @format int64 */
	customerId?: number;
	stockBatchAllocateRespDetails?: StockBatchAllocateRespDetail[];
	warehouse?: string;
}

/** StockBatchAllocateRespDetail */
export interface StockBatchAllocateRespDetail {
	/** @format int32 */
	checkedAllocateAmount?: number;
	shopProductSku?: string;
	systemItemId?: string;
	/** @format int32 */
	transportationAllocateAmount?: number;
}

/** StockFreeze4StockRespDTO */
export interface StockFreeze4StockRespDTO {
	items?: StockReqDTO[];
	success?: boolean;
}

/** StockHistoryPageReq */
export interface StockHistoryPageReq {
	/** 业务单据编码 */
	bizOrderNo?: string;
	/**
	 * 业务类型
	 * @format int32
	 */
	bizType?: number;
	/**
	 * 结束时间
	 * @format date-time
	 */
	endTime?: string;
	/** @format int32 */
	pageNum?: number;
	/** @format int32 */
	pageSize?: number;
	/** 批次包编码 */
	pkgCode?: string;
	sorts?: ISortFieldEnumReq[];
	/**
	 * 开始时间
	 * @format date-time
	 */
	startTime?: string;
	/** 系统单据编码 */
	systemOrderNo?: string;
}

/** StockHistoryPageResp */
export interface StockHistoryPageResp {
	/** 业务单据 */
	bizOrderNo?: string;
	/**
	 * 业务类型
	 * @format int32
	 */
	bizType?: number;
	/**
	 * 变更数量
	 * @format int32
	 */
	changeNums?: number;
	/**
	 * 操作时间
	 * @format date-time
	 */
	createTime?: string;
	/** 源容器编码 */
	fromContainerCode?: string;
	/** 源库库区编码 */
	fromRegionCode?: string;
	/**
	 * 操作人ID
	 * @format int64
	 */
	operatorId?: number;
	/** 操作人名称 */
	operatorName?: string;
	/** @format int32 */
	pageNum?: number;
	/** @format int32 */
	pageSize?: number;
	/** 批次包编码 */
	pkgCode?: string;
	sorts?: ISortFieldEnumRes[];
	/** @format int32 */
	startIndex?: number;
	/** 系统单据 */
	systemOrderNo?: string;
	/** 目标容器编码 */
	toContainerCode?: string;
	/** 目标库区编码 */
	toRegionCode?: string;
}

/** StockInDTO */
export interface StockInDTO {
	/** @format int32 */
	amount?: number;
	/** @format int32 */
	beactive?: number;
	/** @format date-time */
	createTime?: string;
	/** @format int64 */
	creater?: number;
	createrName?: string;
	/** @format int32 */
	exceptionCode?: number;
	exceptionRelation?: string;
	expressNo?: string;
	/** @format int64 */
	id?: number;
	/** @format date-time */
	lmt?: string;
	/** @format int64 */
	modifier?: number;
	modifierName?: string;
	/** @format int32 */
	operationType?: number;
	orderCode?: string;
	picRelation?: string;
	/** 客户订单信息 */
	pkgList?: PkgDTO[];
	productSku?: string;
	purchaseNo?: string;
	/** @format int32 */
	purchaseType?: number;
	remark?: string;
	stockCode?: string;
	warehouse?: string;
}

/** StockInLessDTO */
export interface StockInLessDTO {
	/** @format int32 */
	amount?: number;
	inboundCode?: string;
	orderCode?: string;
	productSku?: string;
	purchaseNo?: string;
}

/** StockInReqDTO */
export interface StockInReqDTO {
	/**
	 * 数量
	 * @format int32
	 */
	amount?: number;
	/** 异常编码列表 */
	exceptionCodeList?: number[];
	/** 异常关联编码 */
	exceptionRelation?: string;
	/** 运单号 */
	expressNo?: string;
	/** 入库单号 */
	inboundCode?: string;
	/**
	 * 操作类型，1-正品入库, 2-次品入库, 3-异常上报用户入库, 4-异常上报采购入库
	 * @format int32
	 */
	operationType?: number;
	/** 订单编码 */
	orderCode?: string;
	/** 图片关联编码 */
	picRelation?: string;
	/** 商品sku */
	productSku?: string;
	/** 采购单编号 */
	purchaseNo?: string;
	/**
	 * 0 普通采购单 1 OEM大货单 2 OEM样品单
	 * @format int32
	 */
	purchaseType?: number;
	/** 备注 */
	remark?: string;
	/** 体积：（XS、S、M、L、XL） */
	volume?: string;
}

/** StockInitialReqDTO */
export interface StockInitialReqDTO {
	/** @format int32 */
	checked?: number;
	customerShopId?: string;
	customerShopName?: string;
	itemCategory?: string;
	/** @format int32 */
	oemProductClassificationId?: number;
	productImg?: string;
	productLink?: string;
	productName?: string;
	productProp?: string;
	productSku?: string;
	shopProductSku?: string;
}

/** StockOperation */
export interface StockOperation {
	/** @format int32 */
	beactive?: number;
	bizeId?: string;
	/** @format int32 */
	checked?: number;
	/** @format date-time */
	createTime?: string;
	/** @format int64 */
	creater?: number;
	createrName?: string;
	/** @format int32 */
	freezeAmount?: number;
	/** @format int32 */
	freezed?: number;
	/** @format int64 */
	id?: number;
	/** @format date-time */
	lmt?: string;
	/** @format int64 */
	modifier?: number;
	modifierName?: string;
	/** @format int32 */
	operationType?: number;
	pkgCode?: string;
	productSkuNew?: string;
	shopProductSku?: string;
	/** @format int32 */
	stockAmount?: number;
	/** @format int32 */
	stockType?: number;
	warehouse?: string;
}

/** StockOperationPageReqDTO */
export interface StockOperationPageReqDTO {
	/** @format int32 */
	pageNum?: number;
	/** @format int32 */
	pageSize?: number;
	/** 店铺商品SKU(店铺商品库) */
	shopProductSku?: string;
	sorts?: ISortFieldEnum[];
}

/** StockPageReqDTO */
export interface StockPageReqDTO {
	/** 店铺编码 */
	customerShopId?: string;
	/** 店铺名称 */
	customerShopName?: string;
	/**
	 * OEM商品分类id：1-服装; 2-杂货; 3-辅料
	 * @format int32
	 */
	oemProductClassificationId?: number;
	/** @format int32 */
	pageNum?: number;
	/** @format int32 */
	pageSize?: number;
	/** 店铺商品SKU(店铺商品库) */
	shopProductSku?: string;
	sorts?: ISortFieldEnum[];
	/**
	 * 系统来源: 1-D2C; 2-B2B
	 * @format int32
	 */
	systemSource?: number;
}

/** StockReqDTO */
export interface StockReqDTO {
	/** @format int32 */
	actualFreeze?: number;
	bizEnum?:
		| 'DECREASE'
		| 'DELIVERY_UNFREEZE'
		| 'FBA_UNFREEZE'
		| 'FREEZE'
		| 'INCREASE'
		| 'INGREDIENTS_UNFREEZE'
		| 'INIT'
		| 'PLAN_CANCEL'
		| 'PLAN_CREATE'
		| 'UNFREEZE';
	bizeId?: string;
	/** @format int32 */
	deduc?: number;
	extraData?: string;
	freezeAble?: boolean;
	/** @format int32 */
	operationType?: number;
	orderCode?: string;
	platformOrderNo?: string;
	/** @format int32 */
	productType?: number;
	shopProductSku?: string;
	shopProductSkuNew?: string;
	/** @format int32 */
	stockType?: number;
	systemOrderItemId?: string;
	/** @format int32 */
	targetFreeze?: number;
	/** @format int32 */
	transportationActualFreeze?: number;
	/** @format int32 */
	useTransportation?: number;
	warehouse?: string;
}

/** StockRespDTO */
export interface StockRespDTO {
	/** @format int32 */
	checked?: number;
	/** @format int32 */
	freezed?: number;
	/** @format int32 */
	lastMonthUse?: number;
	shopProductSku?: string;
	/** @format int32 */
	useable?: number;
}

/** SystemDeliverTaskReqDTO */
export interface SystemDeliverTaskReqDTO {
	/** @format int32 */
	bindManagerFlag?: number;
	/** @format int64 */
	customerId?: number;
	customerName?: string;
	customerShopName?: string;
	/** @format int32 */
	deliveryStatus?: number;
	deliveryTaskCode?: string;
	/** @format int32 */
	managerConfirmStatus?: number;
	/** @format int64 */
	managerId?: number;
	/** @format int32 */
	pageNum?: number;
	/** @format int32 */
	pageSize?: number;
	sorts?: ISortFieldEnum[];
	stationCode?: string;
	/** @format int32 */
	systemSource?: number;
	unificationCustomerFullName?: string;
}

/** TrackForwarderChannelResp */
export interface TrackForwarderChannelResp {
	/**
	 * 数量
	 * @format int32
	 */
	currentAmount?: number;
	/** 货代公司 */
	forwarder?: string;
	/**
	 * id
	 * @format int64
	 */
	intForwarderConfigId?: number;
	/** 运输方式 */
	shippingType?: string;
}

/** TrackForwarderCompanyResp */
export interface TrackForwarderCompanyResp {
	/**
	 * 数量
	 * @format int32
	 */
	currentAmount?: number;
	/** 货代公司 */
	forwarder?: string;
}

/** Transfer */
export interface Transfer {
	/** @format int32 */
	beactive?: number;
	/** @format date-time */
	confirmTime?: string;
	/** @format date-time */
	createTime?: string;
	/** @format int64 */
	creater?: number;
	createrName?: string;
	/** @format int64 */
	id?: number;
	/** @format date-time */
	lmt?: string;
	/** @format int64 */
	modifier?: number;
	modifierName?: string;
	sourceName?: string;
	sourceRegion?: string;
	targetName?: string;
	targetRegion?: string;
	/** @format int32 */
	targetStatus?: number;
	transferCode?: string;
	/** @format int32 */
	transferStatus?: number;
	warehouse?: string;
}

/** TransferDTO */
export interface TransferDTO {
	/** @format int32 */
	beactive?: number;
	/** @format date-time */
	confirmTime?: string;
	/** @format date-time */
	createTime?: string;
	/** @format int64 */
	creater?: number;
	createrName?: string;
	/** @format int64 */
	id?: number;
	/** 调拨明细 */
	items?: TransferItemVO[];
	/** @format date-time */
	lmt?: string;
	/** @format int64 */
	modifier?: number;
	modifierName?: string;
	sourceName?: string;
	sourceRegion?: string;
	targetName?: string;
	targetRegion?: string;
	/** @format int32 */
	targetStatus?: number;
	transferCode?: string;
	/** @format int32 */
	transferStatus?: number;
	warehouse?: string;
}

/** TransferDeliveryPageRespDTO */
export interface TransferDeliveryPageRespDTO {
	/**
	 * 创建时间
	 * @format date-time
	 */
	createTime?: string;
	/** 发货时间 */
	deliveryTime?: string;
	/** 入库仓 */
	inboundWarehouse?: string;
	/** 入库仓编码 */
	inboundWarehouseCode?: string;
	/** 物流单号 */
	logisticsNo?: string;
	/** 出库仓 */
	outboundWarehouse?: string;
	/** 出库仓编码 */
	outboundWarehouseCode?: string;
	/** 发货单商品列表 */
	productRespDTOList?: TransferWarehouseProductRespDTO[];
	/** 发货单编号 */
	transferDeliveryTaskCode?: string;
	/** 调拨单编号 */
	transferWarehouseCode?: string;
}

/** TransferItemReqDTO */
export interface TransferItemReqDTO {
	/** @format int32 */
	amount?: number;
	/** @format int32 */
	beactive?: number;
	/** @format int32 */
	bizType?: number;
	/** @format int32 */
	combinationProductItemFlag?: number;
	/** @format date-time */
	createTime?: string;
	/** @format int64 */
	creater?: number;
	createrName?: string;
	gridCode?: string;
	/** @format int64 */
	id?: number;
	/** @format date-time */
	lmt?: string;
	/** @format int64 */
	modifier?: number;
	modifierName?: string;
	/** @format int32 */
	noticeSettlementFlag?: number;
	orderCode?: string;
	pkgCode?: string;
	/**
	 * 包类型, 1-正品, 2-次品, 3-异常, 4-异常上报采购
	 * @format int32
	 */
	pkgType?: number;
	productSku?: string;
	/** @format int32 */
	productType?: number;
	/** @format int32 */
	purchaseType?: number;
	/** @format int32 */
	regularizationFlag?: number;
	shopProductSku?: string;
	singleProductFlag?: boolean;
	sourceName?: string;
	sourceRegion?: string;
	/** @format int32 */
	systemSource?: number;
	targetName?: string;
	targetRegion?: string;
	/** @format int32 */
	targetStatus?: number;
	/**
	 * 移库数量
	 * @format int32
	 */
	transferAmount?: number;
	transferCode?: string;
	/** @format int32 */
	transferStatus?: number;
	/** @format date-time */
	upShelfTime?: string;
	warehouse?: string;
}

/** TransferItemRespDTO */
export interface TransferItemRespDTO {
	/** @format int32 */
	amount?: number;
	/** @format int32 */
	beactive?: number;
	/** @format int32 */
	bizType?: number;
	/** @format int32 */
	combinationProductItemFlag?: number;
	/** @format date-time */
	createTime?: string;
	/** @format int64 */
	creater?: number;
	createrName?: string;
	gridCode?: string;
	/** @format int64 */
	id?: number;
	img?: string;
	/** @format date-time */
	lmt?: string;
	/** @format int64 */
	modifier?: number;
	modifierName?: string;
	/** @format int32 */
	noticeSettlementFlag?: number;
	orderCode?: string;
	pkgCode?: string;
	/**
	 * 审单处理状态(0:待处理 1:待确认 2:已确认 3:已取消)
	 * @format int32
	 */
	processingStatus?: number;
	/** 商品链接 */
	productLink?: string;
	/** 商品中文名 */
	productName?: string;
	/** 商品属性 */
	productProp?: string;
	productSku?: string;
	/** @format int32 */
	productType?: number;
	/** @format int32 */
	purchaseType?: number;
	/** @format int32 */
	regularizationFlag?: number;
	shopProductSku?: string;
	singleProductFlag?: boolean;
	sourceName?: string;
	sourceRegion?: string;
	/** 特殊质检要求 */
	specialCheckRequirement?: string;
	/** 特殊质检要求图片 */
	specialCheckRequirementImg?: string;
	/**
	 * 拆分类型
	 * @format int32
	 */
	spiltType?: number;
	/** @format int32 */
	systemSource?: number;
	targetName?: string;
	targetRegion?: string;
	/** @format int32 */
	targetStatus?: number;
	transferCode?: string;
	/** @format int32 */
	transferStatus?: number;
	/** @format date-time */
	upShelfTime?: string;
	warehouse?: string;
}

/** TransferItemVO */
export interface TransferItemVO {
	/** @format int32 */
	amount?: number;
	/** @format int32 */
	beactive?: number;
	/** @format int32 */
	bizType?: number;
	/** @format int32 */
	combinationProductItemFlag?: number;
	/** @format date-time */
	createTime?: string;
	/** @format int64 */
	creater?: number;
	createrName?: string;
	gridCode?: string;
	/** @format int64 */
	id?: number;
	/** @format date-time */
	lmt?: string;
	/** @format int64 */
	modifier?: number;
	modifierName?: string;
	/** @format int32 */
	noticeSettlementFlag?: number;
	orderCode?: string;
	pkgCode?: string;
	productSku?: string;
	/** @format int32 */
	productType?: number;
	/** @format int32 */
	purchaseType?: number;
	/** @format int32 */
	regularizationFlag?: number;
	shopProductSku?: string;
	singleProductFlag?: boolean;
	sourceName?: string;
	sourceRegion?: string;
	/** @format int32 */
	systemSource?: number;
	targetName?: string;
	targetRegion?: string;
	/** @format int32 */
	targetStatus?: number;
	transferCode?: string;
	/** @format int32 */
	transferStatus?: number;
	/** @format date-time */
	upShelfTime?: string;
	warehouse?: string;
}

/** TransferPageDTO */
export interface TransferPageDTO {
	/**
	 * 结束时间
	 * @format date-time
	 */
	endTime?: string;
	/** @format int32 */
	pageNum?: number;
	/** @format int32 */
	pageSize?: number;
	/** 包编码 */
	pkgCode?: string;
	sorts?: ISortFieldEnum[];
	/** 源库区名称-起始仓 */
	sourceName?: string;
	/** 源库区 */
	sourceRegion?: string;
	/**
	 * 开始时间
	 * @format date-time
	 */
	startTime?: string;
	/** 目标库区名称-目标仓 */
	targetName?: string;
	/** 目标库区 */
	targetRegion?: string;
	/** 调拨单编码 */
	transferCodes?: string[];
	/**
	 * 调拨状态，0-待确认，1-调拨确认，2-调拨驳回
	 * @format int32
	 */
	transferStatus?: number;
}

/** TransferPkgSpiltReqDTO */
export interface TransferPkgSpiltReqDTO {
	/** 批次包编码 */
	pkgCode?: string;
	/**
	 * 移库数量
	 * @format int32
	 */
	transferAmount?: number;
}

/** TransferRespDTO */
export interface TransferRespDTO {
	/** @format int32 */
	beactive?: number;
	/** @format date-time */
	confirmTime?: string;
	/** @format date-time */
	createTime?: string;
	/** @format int64 */
	creater?: number;
	createrName?: string;
	/** @format int64 */
	id?: number;
	/** 调拨单明细 */
	itemList?: TransferItemRespDTO[];
	/** @format date-time */
	lmt?: string;
	/** @format int64 */
	modifier?: number;
	modifierName?: string;
	sourceName?: string;
	sourceRegion?: string;
	targetName?: string;
	targetRegion?: string;
	/** @format int32 */
	targetStatus?: number;
	transferCode?: string;
	/** @format int32 */
	transferStatus?: number;
	warehouse?: string;
}

/** TransferSpiltRespDTO */
export interface TransferSpiltRespDTO {
	/** 批次包编码 */
	pkgCode?: string;
	/**
	 * 拆包数量
	 * @format int32
	 */
	transferAmount?: number;
}

/** TransferWarehouseConfirmPkgRespDTO */
export interface TransferWarehouseConfirmPkgRespDTO {
	/**
	 * 需调拨数量
	 * @format int32
	 */
	amount?: number;
	/** 移库至 */
	moveTo?: string;
	/** 批次号编码 */
	pkgCode?: string;
	/** 商品图片(优先属性图，否则主图) */
	productImg?: string;
	/** 商品链接 */
	productLink?: string;
	/** 商品名称 */
	productName?: string;
	/** 商品SKU */
	productSku?: string;
	/** 签收类型，0-未知，1-采购单签收，2-仓库调拨签收 */
	signType?: string;
}

/** TransferWarehouseConfirmRespDTO */
export interface TransferWarehouseConfirmRespDTO {
	/**
	 * 调拨总数,为所有批次包调拨件数之和
	 * @format int32
	 */
	count?: number;
	/** 批次包列表 */
	pkgList?: TransferWarehouseConfirmPkgRespDTO[];
	/** 是否已签收; 已签收：true未签收：false */
	signFlag?: boolean;
}

/** TransferWarehouseDelivery */
export interface TransferWarehouseDelivery {
	/** @format int32 */
	actualDeliveryNum?: number;
	/** @format int32 */
	beactive?: number;
	/** @format date-time */
	createTime?: string;
	/** @format int64 */
	creater?: number;
	createrName?: string;
	/** @format date-time */
	deliveryTime?: string;
	/** @format int64 */
	id?: number;
	inboundWarehouse?: string;
	/** @format date-time */
	lmt?: string;
	logisticsNo?: string;
	/** @format int64 */
	modifier?: number;
	modifierName?: string;
	outboundWarehouse?: string;
	/** @format int32 */
	status?: number;
	transferDeliveryTaskCode?: string;
	transferWarehouseCode?: string;
	warehouse?: string;
}

/** TransferWarehouseDeliveryCreateDTO */
export interface TransferWarehouseDeliveryCreateDTO {
	/**
	 * 当前绑定数量
	 * @format int32
	 */
	actualDeliveryNums?: number;
	/** 物流单号 */
	logisticsNo?: string;
	/** 发货单中的批次包列表 */
	pkgList?: TransferWarehousePkgRespDTO[];
	/** 调拨单号 */
	transferWarehouseCode?: string;
}

/** TransferWarehouseDeliveryDTO */
export interface TransferWarehouseDeliveryDTO {
	/**
	 * 实际发货数量
	 * @format int32
	 */
	actualDeliveryNum?: number;
	/**
	 * 发货时间
	 * @format date-time
	 */
	deliveryTime?: string;
	/** 入库仓 */
	inboundWarehouse?: string;
	/** 物流单号 */
	logisticsNo?: string;
	/** 出库仓 */
	outboundWarehouse?: string;
	/**
	 * 签收时间
	 * @format date-time
	 */
	signTime?: string;
	/**
	 * 发货单状态，0-未签收，1-已签收
	 * @format int32
	 */
	status?: number;
	/** 调拨发货单编号 */
	transferDeliveryTaskCode?: string;
	/** 调拨单编号 */
	transferWarehouseCode?: string;
}

/** TransferWarehousePageRespDTO */
export interface TransferWarehousePageRespDTO {
	/**
	 * 创建时间
	 * @format date-time
	 */
	createTime?: string;
	/** 客户店铺名称 */
	customerShopName?: string;
	/** 入库仓 */
	inboundWarehouse?: string;
	/** 入库仓编码 */
	inboundWarehouseCode?: string;
	/** 物流单号 */
	logisticsNo?: string;
	/** 出库仓 */
	outboundWarehouse?: string;
	/** 出库仓编码 */
	outboundWarehouseCode?: string;
	/** 调拨单商品列表 */
	productRespDTOList?: TransferWarehouseProductRespDTO[];
	/** 调拨单编号 */
	transferWarehouseCode?: string;
	/** 调拨单发货列表 */
	transferWarehouseDeliveryDTOS?: TransferWarehouseDeliveryDTO[];
	/** 调拨单商品列表 */
	transferWarehousePkgRespDTOS?: TransferWarehousePkgRespDTO[];
}

/** TransferWarehousePkgPrintRespDTO */
export interface TransferWarehousePkgPrintRespDTO {
	/**
	 * 本次调拨出库数量
	 * @format int32
	 */
	amount?: number;
	/** 货格码 */
	gridCode?: string;
	/** 批次包编号 */
	pkgCode?: string;
}

/** TransferWarehousePkgRespDTO */
export interface TransferWarehousePkgRespDTO {
	/**
	 * 本次调拨出库数量
	 * @format int32
	 */
	amount?: number;
	/** 货格码 */
	gridCode?: string;
	/** 批次包编码 */
	pkgCode?: string;
	/** 调拨发货单编号 */
	transferDeliveryTaskCode?: string;
	/** 调拨单编号 */
	transferWarehouseCode?: string;
}

/** TransferWarehousePrintPickingRespDTO */
export interface TransferWarehousePrintPickingRespDTO {
	/** 批次包 */
	pkgList?: TransferWarehousePkgPrintRespDTO[];
	/** 调拨单编号 */
	transferWarehouseCode?: string;
}

/** TransferWarehouseProductRespDTO */
export interface TransferWarehouseProductRespDTO {
	/** 商品批次包列表 */
	pkgList?: TransferWarehousePkgRespDTO[];
	/** 商品图片(优先属性图，否则主图) */
	productImg?: string;
	/** 商品链接 */
	productLink?: string;
	/** 商品名称 */
	productName?: string;
	/** 商品属性 */
	productProp?: string;
	/** 商品SKU */
	productSku?: string;
	/**
	 * 本次调拨数量
	 * @format int64
	 */
	productTransferNum?: number;
}

/** UnpackSkuRespDTO */
export interface UnpackSkuRespDTO {
	orderCode?: string;
	pkgCode?: string;
	productImg?: string;
	shopProductSku?: string;
	/** @format int32 */
	unPackedNums?: number;
}

/** UpdateSpecialCheckRequirementReqDTO */
export interface UpdateSpecialCheckRequirementReqDTO {
	/** @format int64 */
	operatorId?: number;
	operatorName?: string;
	orderCode?: string;
	specialCheckRequirement?: string;
	specialCheckRequirementImg?: string;
}

/** UploadBoxMarkLinkReqDTO */
export interface UploadBoxMarkLinkReqDTO {
	/** 箱唛文件 */
	boxMarkLinkList: string[];
	/** 发货任务编号 */
	deliveryTaskCode: string;
}

/** UploadDataToAmazonReqDTO */
export interface UploadDataToAmazonReqDTO {
	/** 发货任务编号 */
	deliveryTaskCode: string;
}

/** WalletChangeResultEventDTO */
export interface WalletChangeResultEventDTO {
	/** @format int32 */
	additionId?: number;
	additionSettlementList?: AdditionSettlementDTO[];
	afterAvailableAmount?: number;
	bizContent?: string;
	bizContentCustomer?: string;
	bizId?: string;
	bizType?:
		| 'AFTER_SALE_REFUND'
		| 'BANK_CARD_RECHARGE'
		| 'BILL_OTHER_DEDUCT'
		| 'BILL_WEIGHT_DIFF_DEDUCT'
		| 'COMBINATION_PRODUCT_ADDITIONAL_SETTLEMENT'
		| 'COMBINATION_SERVICE_CHARGE'
		| 'COMBINATION_SERVICE_FREEZE_CHARGE'
		| 'CUSTOMER_BILL_PAY'
		| 'CUSTOMER_REFUND'
		| 'GJFH_ADDITION_FEE_DEDUCT'
		| 'GJFH_FREIGHT_FEE_DEDUCT'
		| 'GJFH_FREIGHT_FEE_DEDUCT_REFUND'
		| 'INV_AGE_FEE'
		| 'MEMBERSHIP_WALLET_CHARGE'
		| 'MULTI_FREEZE'
		| 'ORDER_SETTLEMENT'
		| 'PAYONEER_RECHARGE'
		| 'PKG_STAND_FEE'
		| 'PRODUCT_ADD_ONS'
		| 'PRODUCT_ADD_ONS_TRANSFER'
		| 'PRODUCT_ORDER_PAY'
		| 'PRODUCT_ORDER_SETTLEMENT'
		| 'PROMOTION_RECHARGE'
		| 'PURCHASE'
		| 'PURCHASE_FREIGHT'
		| 'RECHARGE'
		| 'RECHARGE_CANCEL'
		| 'RECHARGE_PAYPAL'
		| 'RECHARGE_REFUSED'
		| 'RECHARGE_WF'
		| 'RECHARGE_WF_AUDIT'
		| 'REFUND_CHARGE'
		| 'REMAKE_FREEZE'
		| 'REMAKE_FREEZE_ADDITIONAL_ITEMS'
		| 'REPAYMENT'
		| 'SEARCH_SOURCE_ORDER_PAY'
		| 'SEARCH_SOURCE_ORDER_REFUND'
		| 'SETTLEMENT_DEFAULT_NO'
		| 'SETTLEMENT_DELIVER_NO'
		| 'STOCKIN_CHARGE'
		| 'TRANSFER'
		| 'UNFREEZE'
		| 'WALLET_WITHDRAW'
		| 'WITHDRAW'
		| 'WITHDRAW_REFUSED';
	changeAmount?: number;
	changeAmountJpy?: number;
	/** @format int64 */
	customerShopId?: number;
	customerShopName?: string;
	data?: string;
	fundFlowNo?: string;
	fundFlowType?:
		| 'AFTER_SALE_REFUND'
		| 'AMOUNT_FREEZE'
		| 'AMOUNT_REMAKE_FREEZE'
		| 'AMOUNT_REMAKE_OVERFLOW_FREEZE'
		| 'AMOUNT_UNFREEZE'
		| 'COMMODITY_SERVICE_FEE'
		| 'COMMODITY_SERVICE_FEE_QUALITY_TRANSFORM'
		| 'CUSTOMER_BILL_PAY'
		| 'CUSTOMER_REFUND'
		| 'EXPRESS_AMOUNT'
		| 'IL_CHARGE'
		| 'IL_CHARGE_REFUND'
		| 'IL_CHARGE_SERVICE_FEE'
		| 'IL_CHARGE_WEIGHT_FEE'
		| 'MEMBERSHIP_FEE'
		| 'PRODUCT_SETTLEMENT'
		| 'PROMOTION_RECHARGE'
		| 'RECHARGE'
		| 'RECHARGE_CANCEL'
		| 'RECHARGE_REFUSED'
		| 'REFUND_DOMESTIC_FREIGHT'
		| 'REPAYMENT'
		| 'SEARCH_SOURCE_CHARGE'
		| 'SEARCH_SOURCE_CHARGE_REFUND'
		| 'SERVICE_CHARGE'
		| 'SHIPPING_SERVICE_FEE'
		| 'TRANSFER_IN'
		| 'TRANSFER_OUT'
		| 'WITHDRAW'
		| 'WITHDRAW_REFUSED';
	/** @format int64 */
	mainCustomerId?: number;
	newCallback?: boolean;
	originalNo?: string;
	platformOrderNo?: string;
	reason?: string;
	/** @format int32 */
	result?: number;
	/** @format int64 */
	walletFundFlowId?: number;
}

/** WalletFlowDetail */
export interface WalletFlowDetail {
	bizId?: string;
	/** @format int32 */
	bizType?: number;
	/** @format int32 */
	fundFlowType?: number;
}

/** WalletFlowDetailRespDTO */
export interface WalletFlowDetailRespDTO {
	additionDetails?: WalletFlowDetail[];
	buyerEnName?: string;
	/** @format int64 */
	customerId?: number;
	customerName?: string;
	/** @format int64 */
	customerShopId?: number;
	deliverCountry?: string;
	deliveryTaskCode?: string;
	isBackorder?: string;
	isDeclared?: string;
	isNewBuyer?: string;
	isShipped?: string;
	/** @format int64 */
	mainCustomerId?: number;
	/** @format int32 */
	orderStatus?: number;
	/** @format date-time */
	orderTime?: string;
	platformOrderNo?: string;
	referenceOrderId?: string;
	referenceOrderNo?: string;
	/** @format int32 */
	systemSource?: number;
	tradeAmount?: number;
	tradeAmountCurrency?: string;
	tradeBizType?: string;
	tradeCountry?: string;
	tradeType?: string;
	transAmount?: number;
	transAmountCurrency?: string;
	unificationCustomerFullName?: string;
	walletFlowDetails?: WalletFlowDetail[];
	wfAssociateOrderDeclarationInfoDTOS?: WfAssociateOrderDeclarationInfoDTO[];
	wfAssociateOrderGoodsDTOS?: WfAssociateOrderGoodsDTO[];
	wfAssociateOrderWayBillInfoDTOS?: WfAssociateOrderWayBillInfoDTO[];
}

/** WalletProductDTO */
export interface WalletProductDTO {
	cost?: number;
	money?: number;
	/** @format int32 */
	num?: number;
	price?: number;
	productImg?: string;
	productPropertiesName?: string;
	productSku?: string;
	productTitle?: string;
	systemOrderNo?: string;
}

/** Warehouse */
export interface Warehouse {
	address?: string;
	/** @format int32 */
	area?: number;
	/** @format int32 */
	beactive?: number;
	city?: string;
	contactPhone?: string;
	/** @format date-time */
	createTime?: string;
	/** @format int64 */
	creater?: number;
	createrName?: string;
	district?: string;
	/** @format int64 */
	id?: number;
	/** @format date-time */
	lmt?: string;
	/** @format int64 */
	modifier?: number;
	modifierName?: string;
	postalCode?: string;
	province?: string;
	remark?: string;
	/** @format int32 */
	throughputTob?: number;
	/** @format int32 */
	throughputToc?: number;
	wareCode?: string;
	wareContact?: string;
	wareName?: string;
	warehouse?: string;
}

/** WarehouseRespDto */
export interface WarehouseRespDto {
	address?: string;
	/** @format int32 */
	area?: number;
	/** @format int32 */
	b2bBoxNum?: number;
	city?: string;
	contactPhone?: string;
	/** @format int32 */
	d2cNum?: number;
	district?: string;
	/** @format int64 */
	id?: number;
	postalCode?: string;
	province?: string;
	remark?: string;
	/** @format int32 */
	throughputTob?: number;
	/** @format int32 */
	throughputToc?: number;
	wareCode?: string;
	wareContact?: string;
	wareName?: string;
}

/** WeightCostConfigPageQuery */
export interface WeightCostConfigPageQuery {
	/** @format int32 */
	pageNum?: number;
	/** @format int32 */
	pageSize?: number;
	sorts?: ISortFieldEnum[];
	/**
	 * 系统来源: 1-D2C; 2-B2B
	 * @format int32
	 */
	systemSource: number;
}

/** WeightCostConfigRespDTO */
export interface WeightCostConfigRespDTO {
	/**
	 * 类型：1-容器，2-箱子
	 * @format int32
	 */
	boxType?: number;
	/**
	 * 创建时间
	 * @format date-time
	 */
	createTime?: string;
	/**
	 * 创建人
	 * @format int64
	 */
	creater?: number;
	/**
	 * 发货类型:0-FBA发货，1-大件普通发货，2-小件普通发货
	 * @format int32
	 */
	deliverType?: number;
	/** 货代公司名 */
	forwarderName?: string;
	/**
	 * 中国货代公司id
	 * @format int64
	 */
	intForwarderConfigId?: number;
	/**
	 * 最新修改时间
	 * @format date-time
	 */
	lmt?: string;
	/** 物流公司code（OCS、YD、TH、SKB） */
	logisticsCompanyCode?: string;
	/**
	 * 修改人
	 * @format int64
	 */
	modifier?: number;
	/** 价格限制（美元） */
	priceLimit?: number;
	/** 仓库code */
	warehouse?: string;
	/**
	 * 主键id
	 * @format int64
	 */
	weightCostConfigId?: number;
	/** 重量限制kg */
	weightLimit?: number;
}

/** WfAssociateOrderDeclarationInfoDTO */
export interface WfAssociateOrderDeclarationInfoDTO {
	declarationOrderReferenceNo?: string;
	deliveryTaskCode?: string;
	platformOrderNo?: string;
	referenceOrderNo?: string;
	supervisionMethod?: string;
}

/** WfAssociateOrderGoodsDTO */
export interface WfAssociateOrderGoodsDTO {
	deliveryTaskCode?: string;
	goodsCnName?: string;
	goodsName?: string;
	goodsPrice?: number;
	goodsQuantity?: string;
	goodsUnit?: string;
	platformOrderNo?: string;
	referenceOrderNo?: string;
	storeUrl?: string;
}

/** WfAssociateOrderWayBillInfoDTO */
export interface WfAssociateOrderWayBillInfoDTO {
	buyerEnName?: string;
	deliverCountry?: string;
	deliveryTaskCode?: string;
	platformOrderNo?: string;
	providerKey?: string;
	providerValue?: string;
	referenceOrderNo?: string;
	shippingMethod?: string;
	shippingOrderReferenceNo?: string;
	tradeCountry?: string;
}

/** WmsIntForwarderConfigRespDTO */
export interface WmsIntForwarderConfigRespDTO {
	/** 国家中文名称 */
	countryCnName?: string;
	/** 国家code */
	countryCode?: string;
	/** 国家英文文名称 */
	countryEnName?: string;
	/** 国家日文名称 */
	countryJpyName?: string;
	/** @format date-time */
	createTime?: string;
	forwarder?: string;
	/** @format int64 */
	intForwarderConfigId?: number;
	logisticsCompanyCode?: string;
	membershipName?: string[];
	shippingType?: string;
	/** @format date-time */
	updateTime?: string;
}

/** BizResponse«AllotContainerBindResp» */
export interface BizResponseAllotContainerBindResp {
	code?: string;
	data?: AllotContainerBindResp;
	msg?: string;
	success?: boolean;
}

/** BizResponse«AppVersionResp» */
export interface BizResponseAppVersionResp {
	code?: string;
	data?: AppVersionResp;
	msg?: string;
	success?: boolean;
}

/** BizResponse«BadPendingRespDTO» */
export interface BizResponseBadPendingRespDTO {
	code?: string;
	data?: BadPendingRespDTO;
	msg?: string;
	success?: boolean;
}

/** BizResponse«CheckPickRespDTO» */
export interface BizResponseCheckPickRespDTO {
	code?: string;
	data?: CheckPickRespDTO;
	msg?: string;
	success?: boolean;
}

/** BizResponse«ClearStockRespDto» */
export interface BizResponseClearStockRespDto {
	code?: string;
	data?: ClearStockRespDto;
	msg?: string;
	success?: boolean;
}

/** BizResponse«ConfirmDomesticDeliveryRespDTO» */
export interface BizResponseConfirmDomesticDeliveryRespDTO {
	code?: string;
	data?: ConfirmDomesticDeliveryRespDTO;
	msg?: string;
	success?: boolean;
}

/** BizResponse«D2cAllotProgressRespDTO» */
export interface BizResponseD2CAllotProgressRespDTO {
	code?: string;
	data?: D2CAllotProgressRespDTO;
	msg?: string;
	success?: boolean;
}

/** BizResponse«D2cSingleProductAllotRespDTO» */
export interface BizResponseD2CSingleProductAllotRespDTO {
	code?: string;
	data?: D2CSingleProductAllotRespDTO;
	msg?: string;
	success?: boolean;
}

/** BizResponse«DefaultLabelConfigVO» */
export interface BizResponseDefaultLabelConfigVO {
	code?: string;
	data?: DefaultLabelConfigVO;
	msg?: string;
	success?: boolean;
}

/** BizResponse«DeliverTaskRespDTO» */
export interface BizResponseDeliverTaskRespDTO {
	code?: string;
	data?: DeliverTaskRespDTO;
	msg?: string;
	success?: boolean;
}

/** BizResponse«DeliveryBoxProductSkuEditDTO» */
export interface BizResponseDeliveryBoxProductSkuEditDTO {
	code?: string;
	data?: DeliveryBoxProductSkuEditDTO;
	msg?: string;
	success?: boolean;
}

/** BizResponse«DeliveryCensusDTO» */
export interface BizResponseDeliveryCensusDTO {
	code?: string;
	data?: DeliveryCensusDTO;
	msg?: string;
	success?: boolean;
}

/** BizResponse«DeliveryOrderInterceptEventResp» */
export interface BizResponseDeliveryOrderInterceptEventResp {
	code?: string;
	data?: DeliveryOrderInterceptEventResp;
	msg?: string;
	success?: boolean;
}

/** BizResponse«DeliveryPickAllotTaskCheckResp» */
export interface BizResponseDeliveryPickAllotTaskCheckResp {
	code?: string;
	data?: DeliveryPickAllotTaskCheckResp;
	msg?: string;
	success?: boolean;
}

/** BizResponse«DeliveryReviewApiConfirmCountRespDTO» */
export interface BizResponseDeliveryReviewApiConfirmCountRespDTO {
	code?: string;
	data?: DeliveryReviewApiConfirmCountRespDTO;
	msg?: string;
	success?: boolean;
}

/** BizResponse«DeliveryReviewInfoRespDTO» */
export interface BizResponseDeliveryReviewInfoRespDTO {
	code?: string;
	data?: DeliveryReviewInfoRespDTO;
	msg?: string;
	success?: boolean;
}

/** BizResponse«DeliveryReviewPageRespDTO» */
export interface BizResponseDeliveryReviewPageRespDTO {
	code?: string;
	data?: DeliveryReviewPageRespDTO;
	msg?: string;
	success?: boolean;
}

/** BizResponse«DeliveryReviewQueryRespDTO» */
export interface BizResponseDeliveryReviewQueryRespDTO {
	code?: string;
	data?: DeliveryReviewQueryRespDTO;
	msg?: string;
	success?: boolean;
}

/** BizResponse«DeliveryTaskAmountResp» */
export interface BizResponseDeliveryTaskAmountResp {
	code?: string;
	data?: DeliveryTaskAmountResp;
	msg?: string;
	success?: boolean;
}

/** BizResponse«DeliveryTaskDetailRespDTO» */
export interface BizResponseDeliveryTaskDetailRespDTO {
	code?: string;
	data?: DeliveryTaskDetailRespDTO;
	msg?: string;
	success?: boolean;
}

/** BizResponse«DeliveryTaskPdaConfirmResp» */
export interface BizResponseDeliveryTaskPdaConfirmResp {
	code?: string;
	data?: DeliveryTaskPdaConfirmResp;
	msg?: string;
	success?: boolean;
}

/** BizResponse«DeliveryTaskTrackRespDTO» */
export interface BizResponseDeliveryTaskTrackRespDTO {
	code?: string;
	data?: DeliveryTaskTrackRespDTO;
	msg?: string;
	success?: boolean;
}

/** BizResponse«DeliveryWaveDetailResp» */
export interface BizResponseDeliveryWaveDetailResp {
	code?: string;
	data?: DeliveryWaveDetailResp;
	msg?: string;
	success?: boolean;
}

/** BizResponse«DeliveryWaveMatchResp» */
export interface BizResponseDeliveryWaveMatchResp {
	code?: string;
	data?: DeliveryWaveMatchResp;
	msg?: string;
	success?: boolean;
}

/** BizResponse«DomesticDeliveryPrintPickingRespDTO» */
export interface BizResponseDomesticDeliveryPrintPickingRespDTO {
	code?: string;
	data?: DomesticDeliveryPrintPickingRespDTO;
	msg?: string;
	success?: boolean;
}

/** BizResponse«DomesticDeliveryRespDTO» */
export interface BizResponseDomesticDeliveryRespDTO {
	code?: string;
	data?: DomesticDeliveryRespDTO;
	msg?: string;
	success?: boolean;
}

/** BizResponse«FlowRespDTO» */
export interface BizResponseFlowRespDTO {
	code?: string;
	data?: FlowRespDTO;
	msg?: string;
	success?: boolean;
}

/** BizResponse«GetAllotPkgUnPickRespDTO» */
export interface BizResponseGetAllotPkgUnPickRespDTO {
	code?: string;
	data?: GetAllotPkgUnPickRespDTO;
	msg?: string;
	success?: boolean;
}

/** BizResponse«GetReviewForwarderRespDTO» */
export interface BizResponseGetReviewForwarderRespDTO {
	code?: string;
	data?: GetReviewForwarderRespDTO;
	msg?: string;
	success?: boolean;
}

/** BizResponse«IPage«StockOperation»» */
export interface BizResponseIPageStockOperation {
	code?: string;
	data?: IPageStockOperation;
	msg?: string;
	success?: boolean;
}

/** BizResponse«IPage«Stock»» */
export interface BizResponseIPageStock {
	code?: string;
	data?: IPageStock;
	msg?: string;
	success?: boolean;
}

/** BizResponse«InboundCountByTypeRespDTO» */
export interface BizResponseInboundCountByTypeRespDTO {
	code?: string;
	data?: InboundCountByTypeRespDTO;
	msg?: string;
	success?: boolean;
}

/** BizResponse«InboundSkuPropRespDto» */
export interface BizResponseInboundSkuPropRespDto {
	code?: string;
	data?: InboundSkuPropRespDto;
	msg?: string;
	success?: boolean;
}

/** BizResponse«List«AvailableStockRespDTO»» */
export interface BizResponseListAvailableStockRespDTO {
	code?: string;
	data?: AvailableStockRespDTO[];
	msg?: string;
	success?: boolean;
}

/** BizResponse«List«B2bDeliverySpotCheckNumsDTO»» */
export interface BizResponseListB2BDeliverySpotCheckNumsDTO {
	code?: string;
	data?: B2BDeliverySpotCheckNumsDTO[];
	msg?: string;
	success?: boolean;
}

/** BizResponse«List«BasketRespDTO»» */
export interface BizResponseListBasketRespDTO {
	code?: string;
	data?: BasketRespDTO[];
	msg?: string;
	success?: boolean;
}

/** BizResponse«List«BasketVO»» */
export interface BizResponseListBasketVO {
	code?: string;
	data?: BasketVO[];
	msg?: string;
	success?: boolean;
}

/** BizResponse«List«BatchGetLogisticsTrackRespDto»» */
export interface BizResponseListBatchGetLogisticsTrackRespDto {
	code?: string;
	data?: BatchGetLogisticsTrackRespDto[];
	msg?: string;
	success?: boolean;
}

/** BizResponse«List«BoxTemplateRespDTO»» */
export interface BizResponseListBoxTemplateRespDTO {
	code?: string;
	data?: BoxTemplateRespDTO[];
	msg?: string;
	success?: boolean;
}

/** BizResponse«List«ClientForwarderRespDTO»» */
export interface BizResponseListClientForwarderRespDTO {
	code?: string;
	data?: ClientForwarderRespDTO[];
	msg?: string;
	success?: boolean;
}

/** BizResponse«List«ContainerDetailResp»» */
export interface BizResponseListContainerDetailResp {
	code?: string;
	data?: ContainerDetailResp[];
	msg?: string;
	success?: boolean;
}

/** BizResponse«List«ContainerProductResp»» */
export interface BizResponseListContainerProductResp {
	code?: string;
	data?: ContainerProductResp[];
	msg?: string;
	success?: boolean;
}

/** BizResponse«List«ConversionRecordRespDTO»» */
export interface BizResponseListConversionRecordRespDTO {
	code?: string;
	data?: ConversionRecordRespDTO[];
	msg?: string;
	success?: boolean;
}

/** BizResponse«List«CountryInfoRespDTO»» */
export interface BizResponseListCountryInfoRespDTO {
	code?: string;
	data?: CountryInfoRespDTO[];
	msg?: string;
	success?: boolean;
}

/** BizResponse«List«CustomsProductCompilationMethodDTO»» */
export interface BizResponseListCustomsProductCompilationMethodDTO {
	code?: string;
	data?: CustomsProductCompilationMethodDTO[];
	msg?: string;
	success?: boolean;
}

/** BizResponse«List«CustomsProductDTO»» */
export interface BizResponseListCustomsProductDTO {
	code?: string;
	data?: CustomsProductDTO[];
	msg?: string;
	success?: boolean;
}

/** BizResponse«List«D2cDeliverySpotCheckNumsDTO»» */
export interface BizResponseListD2CDeliverySpotCheckNumsDTO {
	code?: string;
	data?: D2CDeliverySpotCheckNumsDTO[];
	msg?: string;
	success?: boolean;
}

/** BizResponse«List«DeliverTaskRespDTO»» */
export interface BizResponseListDeliverTaskRespDTO {
	code?: string;
	data?: DeliverTaskRespDTO[];
	msg?: string;
	success?: boolean;
}

/** BizResponse«List«DeliveryBoxRespDTO»» */
export interface BizResponseListDeliveryBoxRespDTO {
	code?: string;
	data?: DeliveryBoxRespDTO[];
	msg?: string;
	success?: boolean;
}

/** BizResponse«List«DeliveryCensusDTO»» */
export interface BizResponseListDeliveryCensusDTO {
	code?: string;
	data?: DeliveryCensusDTO[];
	msg?: string;
	success?: boolean;
}

/** BizResponse«List«DeliveryForwarderResp»» */
export interface BizResponseListDeliveryForwarderResp {
	code?: string;
	data?: DeliveryForwarderResp[];
	msg?: string;
	success?: boolean;
}

/** BizResponse«List«DeliveryPackageRespDTO»» */
export interface BizResponseListDeliveryPackageRespDTO {
	code?: string;
	data?: DeliveryPackageRespDTO[];
	msg?: string;
	success?: boolean;
}

/** BizResponse«List«DeliveryPickTaskDetailRespDTO»» */
export interface BizResponseListDeliveryPickTaskDetailRespDTO {
	code?: string;
	data?: DeliveryPickTaskDetailRespDTO[];
	msg?: string;
	success?: boolean;
}

/** BizResponse«List«DeliveryReviewCombinationProductRespDTO»» */
export interface BizResponseListDeliveryReviewCombinationProductRespDTO {
	code?: string;
	data?: DeliveryReviewCombinationProductRespDTO[];
	msg?: string;
	success?: boolean;
}

/** BizResponse«List«DeliverySkuDeductionRecordDto»» */
export interface BizResponseListDeliverySkuDeductionRecordDto {
	code?: string;
	data?: DeliverySkuDeductionRecordDto[];
	msg?: string;
	success?: boolean;
}

/** BizResponse«List«DeliveryTagTemplateRespDto»» */
export interface BizResponseListDeliveryTagTemplateRespDto {
	code?: string;
	data?: DeliveryTagTemplateRespDto[];
	msg?: string;
	success?: boolean;
}

/** BizResponse«List«DeliveryTaskAllotDetailRespDTO»» */
export interface BizResponseListDeliveryTaskAllotDetailRespDTO {
	code?: string;
	data?: DeliveryTaskAllotDetailRespDTO[];
	msg?: string;
	success?: boolean;
}

/** BizResponse«List«DeliveryTaskBoxLogisticsResp»» */
export interface BizResponseListDeliveryTaskBoxLogisticsResp {
	code?: string;
	data?: DeliveryTaskBoxLogisticsResp[];
	msg?: string;
	success?: boolean;
}

/** BizResponse«List«DeliveryTaskLogisticsDetailRespDTO»» */
export interface BizResponseListDeliveryTaskLogisticsDetailRespDTO {
	code?: string;
	data?: DeliveryTaskLogisticsDetailRespDTO[];
	msg?: string;
	success?: boolean;
}

/** BizResponse«List«DeliveryTaskOperationVO»» */
export interface BizResponseListDeliveryTaskOperationVO {
	code?: string;
	data?: DeliveryTaskOperationVO[];
	msg?: string;
	success?: boolean;
}

/** BizResponse«List«DomesticDeliveryPrintPickingRespDTO»» */
export interface BizResponseListDomesticDeliveryPrintPickingRespDTO {
	code?: string;
	data?: DomesticDeliveryPrintPickingRespDTO[];
	msg?: string;
	success?: boolean;
}

/** BizResponse«List«GetAllotPkgPrePickRespDTO»» */
export interface BizResponseListGetAllotPkgPrePickRespDTO {
	code?: string;
	data?: GetAllotPkgPrePickRespDTO[];
	msg?: string;
	success?: boolean;
}

/** BizResponse«List«Img»» */
export interface BizResponseListImg {
	code?: string;
	data?: Img[];
	msg?: string;
	success?: boolean;
}

/** BizResponse«List«ImportStockReqDTO»» */
export interface BizResponseListImportStockReqDTO {
	code?: string;
	data?: ImportStockReqDTO[];
	msg?: string;
	success?: boolean;
}

/** BizResponse«List«InboundNumInfoRespDTO»» */
export interface BizResponseListInboundNumInfoRespDTO {
	code?: string;
	data?: InboundNumInfoRespDTO[];
	msg?: string;
	success?: boolean;
}

/** BizResponse«List«InboundOneKeyInfoRespDTO»» */
export interface BizResponseListInboundOneKeyInfoRespDTO {
	code?: string;
	data?: InboundOneKeyInfoRespDTO[];
	msg?: string;
	success?: boolean;
}

/** BizResponse«List«InboundOrderAdditionRespDTO»» */
export interface BizResponseListInboundOrderAdditionRespDTO {
	code?: string;
	data?: InboundOrderAdditionRespDTO[];
	msg?: string;
	success?: boolean;
}

/** BizResponse«List«InboundOrderSkuInfoRespDTO»» */
export interface BizResponseListInboundOrderSkuInfoRespDTO {
	code?: string;
	data?: InboundOrderSkuInfoRespDTO[];
	msg?: string;
	success?: boolean;
}

/** BizResponse«List«InboundPurchaseNumInfoRespDTO»» */
export interface BizResponseListInboundPurchaseNumInfoRespDTO {
	code?: string;
	data?: InboundPurchaseNumInfoRespDTO[];
	msg?: string;
	success?: boolean;
}

/** BizResponse«List«IngredientsBatchRespDTO»» */
export interface BizResponseListIngredientsBatchRespDTO {
	code?: string;
	data?: IngredientsBatchRespDTO[];
	msg?: string;
	success?: boolean;
}

/** BizResponse«List«InvoiceForwarderRespDTO»» */
export interface BizResponseListInvoiceForwarderRespDTO {
	code?: string;
	data?: InvoiceForwarderRespDTO[];
	msg?: string;
	success?: boolean;
}

/** BizResponse«List«InvoiceTemplateDTO»» */
export interface BizResponseListInvoiceTemplateDTO {
	code?: string;
	data?: InvoiceTemplateDTO[];
	msg?: string;
	success?: boolean;
}

/** BizResponse«List«LabelTemplateRespDTO»» */
export interface BizResponseListLabelTemplateRespDTO {
	code?: string;
	data?: LabelTemplateRespDTO[];
	msg?: string;
	success?: boolean;
}

/** BizResponse«List«LableValue»» */
export interface BizResponseListLableValue {
	code?: string;
	data?: LableValue[];
	msg?: string;
	success?: boolean;
}

/** BizResponse«List«LogisticsChannelDTO»» */
export interface BizResponseListLogisticsChannelDTO {
	code?: string;
	data?: LogisticsChannelDTO[];
	msg?: string;
	success?: boolean;
}

/** BizResponse«List«LogisticsSignDto»» */
export interface BizResponseListLogisticsSignDto {
	code?: string;
	data?: LogisticsSignDto[];
	msg?: string;
	success?: boolean;
}

/** BizResponse«List«LogisticsTrackDetailResp»» */
export interface BizResponseListLogisticsTrackDetailResp {
	code?: string;
	data?: LogisticsTrackDetailResp[];
	msg?: string;
	success?: boolean;
}

/** BizResponse«List«MonthlyStatisticsAgeResp»» */
export interface BizResponseListMonthlyStatisticsAgeResp {
	code?: string;
	data?: MonthlyStatisticsAgeResp[];
	msg?: string;
	success?: boolean;
}

/** BizResponse«List«OpsPkgDto»» */
export interface BizResponseListOpsPkgDto {
	code?: string;
	data?: OpsPkgDto[];
	msg?: string;
	success?: boolean;
}

/** BizResponse«List«OrderDeliveryInfoDTO»» */
export interface BizResponseListOrderDeliveryInfoDTO {
	code?: string;
	data?: OrderDeliveryInfoDTO[];
	msg?: string;
	success?: boolean;
}

/** BizResponse«List«PackAllotPkgDTO»» */
export interface BizResponseListPackAllotPkgDTO {
	code?: string;
	data?: PackAllotPkgDTO[];
	msg?: string;
	success?: boolean;
}

/** BizResponse«List«PackPkgDTO»» */
export interface BizResponseListPackPkgDTO {
	code?: string;
	data?: PackPkgDTO[];
	msg?: string;
	success?: boolean;
}

/** BizResponse«List«PackPkgVO»» */
export interface BizResponseListPackPkgVO {
	code?: string;
	data?: PackPkgVO[];
	msg?: string;
	success?: boolean;
}

/** BizResponse«List«PackPkg»» */
export interface BizResponseListPackPkg {
	code?: string;
	data?: PackPkg[];
	msg?: string;
	success?: boolean;
}

/** BizResponse«List«PackTaskSettleRespDTO»» */
export interface BizResponseListPackTaskSettleRespDTO {
	code?: string;
	data?: PackTaskSettleRespDTO[];
	msg?: string;
	success?: boolean;
}

/** BizResponse«List«PendingBadQuantityRespDTO»» */
export interface BizResponseListPendingBadQuantityRespDTO {
	code?: string;
	data?: PendingBadQuantityRespDTO[];
	msg?: string;
	success?: boolean;
}

/** BizResponse«List«PendingDefectiveRespDTO»» */
export interface BizResponseListPendingDefectiveRespDTO {
	code?: string;
	data?: PendingDefectiveRespDTO[];
	msg?: string;
	success?: boolean;
}

/** BizResponse«List«PickStatusDTO»» */
export interface BizResponseListPickStatusDTO {
	code?: string;
	data?: PickStatusDTO[];
	msg?: string;
	success?: boolean;
}

/** BizResponse«List«PkgAdjustRecordDto»» */
export interface BizResponseListPkgAdjustRecordDto {
	code?: string;
	data?: PkgAdjustRecordDto[];
	msg?: string;
	success?: boolean;
}

/** BizResponse«List«PkgDTO»» */
export interface BizResponseListPkgDTO {
	code?: string;
	data?: PkgDTO[];
	msg?: string;
	success?: boolean;
}

/** BizResponse«List«Pkg»» */
export interface BizResponseListPkg {
	code?: string;
	data?: Pkg[];
	msg?: string;
	success?: boolean;
}

/** BizResponse«List«PrintTemplate»» */
export interface BizResponseListPrintTemplate {
	code?: string;
	data?: PrintTemplate[];
	msg?: string;
	success?: boolean;
}

/** BizResponse«List«Region»» */
export interface BizResponseListRegion {
	code?: string;
	data?: Region[];
	msg?: string;
	success?: boolean;
}

/** BizResponse«List«RegularizationOperationVO»» */
export interface BizResponseListRegularizationOperationVO {
	code?: string;
	data?: RegularizationOperationVO[];
	msg?: string;
	success?: boolean;
}

/** BizResponse«List«SplitPackDeliveryTaskRespDTO»» */
export interface BizResponseListSplitPackDeliveryTaskRespDTO {
	code?: string;
	data?: SplitPackDeliveryTaskRespDTO[];
	msg?: string;
	success?: boolean;
}

/** BizResponse«List«StockBatchAllocateRespDTO»» */
export interface BizResponseListStockBatchAllocateRespDTO {
	code?: string;
	data?: StockBatchAllocateRespDTO[];
	msg?: string;
	success?: boolean;
}

/** BizResponse«List«StockReqDTO»» */
export interface BizResponseListStockReqDTO {
	code?: string;
	data?: StockReqDTO[];
	msg?: string;
	success?: boolean;
}

/** BizResponse«List«StockRespDTO»» */
export interface BizResponseListStockRespDTO {
	code?: string;
	data?: StockRespDTO[];
	msg?: string;
	success?: boolean;
}

/** BizResponse«List«TrackForwarderChannelResp»» */
export interface BizResponseListTrackForwarderChannelResp {
	code?: string;
	data?: TrackForwarderChannelResp[];
	msg?: string;
	success?: boolean;
}

/** BizResponse«List«TrackForwarderCompanyResp»» */
export interface BizResponseListTrackForwarderCompanyResp {
	code?: string;
	data?: TrackForwarderCompanyResp[];
	msg?: string;
	success?: boolean;
}

/** BizResponse«List«TransferSpiltRespDTO»» */
export interface BizResponseListTransferSpiltRespDTO {
	code?: string;
	data?: TransferSpiltRespDTO[];
	msg?: string;
	success?: boolean;
}

/** BizResponse«List«UnpackSkuRespDTO»» */
export interface BizResponseListUnpackSkuRespDTO {
	code?: string;
	data?: UnpackSkuRespDTO[];
	msg?: string;
	success?: boolean;
}

/** BizResponse«List«WalletFlowDetailRespDTO»» */
export interface BizResponseListWalletFlowDetailRespDTO {
	code?: string;
	data?: WalletFlowDetailRespDTO[];
	msg?: string;
	success?: boolean;
}

/** BizResponse«List«WarehouseRespDto»» */
export interface BizResponseListWarehouseRespDto {
	code?: string;
	data?: WarehouseRespDto[];
	msg?: string;
	success?: boolean;
}

/** BizResponse«List«WmsIntForwarderConfigRespDTO»» */
export interface BizResponseListWmsIntForwarderConfigRespDTO {
	code?: string;
	data?: WmsIntForwarderConfigRespDTO[];
	msg?: string;
	success?: boolean;
}

/** BizResponse«List«string»» */
export interface BizResponseListString {
	code?: string;
	data?: string[];
	msg?: string;
	success?: boolean;
}

/** BizResponse«LogisticsApiPrintConfigRespDTO» */
export interface BizResponseLogisticsApiPrintConfigRespDTO {
	code?: string;
	data?: LogisticsApiPrintConfigRespDTO;
	msg?: string;
	success?: boolean;
}

/** BizResponse«LogisticsInfoDTO» */
export interface BizResponseLogisticsInfoDTO {
	code?: string;
	data?: LogisticsInfoDTO;
	msg?: string;
	success?: boolean;
}

/** BizResponse«LogisticsTrackUpdateResp» */
export interface BizResponseLogisticsTrackUpdateResp {
	code?: string;
	data?: LogisticsTrackUpdateResp;
	msg?: string;
	success?: boolean;
}

/** BizResponse«Map«string,List«RemainProductDTO»»» */
export interface BizResponseMapStringListRemainProductDTO {
	code?: string;
	data?: Record<string, RemainProductDTO[]>;
	msg?: string;
	success?: boolean;
}

/** BizResponse«Map«string,int»» */
export interface BizResponseMapStringInt {
	code?: string;
	data?: Record<string, number>;
	msg?: string;
	success?: boolean;
}

/** BizResponse«MyStockDTO» */
export interface BizResponseMyStockDTO {
	code?: string;
	data?: MyStockDTO;
	msg?: string;
	success?: boolean;
}

/** BizResponse«OpsPkgInfoDto» */
export interface BizResponseOpsPkgInfoDto {
	code?: string;
	data?: OpsPkgInfoDto;
	msg?: string;
	success?: boolean;
}

/** BizResponse«PackBasketInfoDTO» */
export interface BizResponsePackBasketInfoDTO {
	code?: string;
	data?: PackBasketInfoDTO;
	msg?: string;
	success?: boolean;
}

/** BizResponse«PackFinishRespDTO» */
export interface BizResponsePackFinishRespDTO {
	code?: string;
	data?: PackFinishRespDTO;
	msg?: string;
	success?: boolean;
}

/** BizResponse«PackPickBasketRespDTO» */
export interface BizResponsePackPickBasketRespDTO {
	code?: string;
	data?: PackPickBasketRespDTO;
	msg?: string;
	success?: boolean;
}

/** BizResponse«PageResult«ContainerPageResp»» */
export interface BizResponsePageResultContainerPageResp {
	code?: string;
	data?: PageResultContainerPageResp;
	msg?: string;
	success?: boolean;
}

/** BizResponse«PageResult«DeliverTaskRespDTO»» */
export interface BizResponsePageResultDeliverTaskRespDTO {
	code?: string;
	data?: PageResultDeliverTaskRespDTO;
	msg?: string;
	success?: boolean;
}

/** BizResponse«PageResult«DeliverTaskWmsPageDto»» */
export interface BizResponsePageResultDeliverTaskWmsPageDto {
	code?: string;
	data?: PageResultDeliverTaskWmsPageDto;
	msg?: string;
	success?: boolean;
}

/** BizResponse«PageResult«DeliveryPickBatchPageResp»» */
export interface BizResponsePageResultDeliveryPickBatchPageResp {
	code?: string;
	data?: PageResultDeliveryPickBatchPageResp;
	msg?: string;
	success?: boolean;
}

/** BizResponse«PageResult«DeliveryPickBatchPkgPageResp»» */
export interface BizResponsePageResultDeliveryPickBatchPkgPageResp {
	code?: string;
	data?: PageResultDeliveryPickBatchPkgPageResp;
	msg?: string;
	success?: boolean;
}

/** BizResponse«PageResult«DeliveryReviewPageApiRespDTO»» */
export interface BizResponsePageResultDeliveryReviewPageApiRespDTO {
	code?: string;
	data?: PageResultDeliveryReviewPageApiRespDTO;
	msg?: string;
	success?: boolean;
}

/** BizResponse«PageResult«DeliveryTaskInvoiceDTO»» */
export interface BizResponsePageResultDeliveryTaskInvoiceDTO {
	code?: string;
	data?: PageResultDeliveryTaskInvoiceDTO;
	msg?: string;
	success?: boolean;
}

/** BizResponse«PageResult«DeliveryWavePageResp»» */
export interface BizResponsePageResultDeliveryWavePageResp {
	code?: string;
	data?: PageResultDeliveryWavePageResp;
	msg?: string;
	success?: boolean;
}

/** BizResponse«PageResult«ExportRecordDTO»» */
export interface BizResponsePageResultExportRecordDTO {
	code?: string;
	data?: PageResultExportRecordDTO;
	msg?: string;
	success?: boolean;
}

/** BizResponse«PageResult«InboundOperationRecordRespDTO»» */
export interface BizResponsePageResultInboundOperationRecordRespDTO {
	code?: string;
	data?: PageResultInboundOperationRecordRespDTO;
	msg?: string;
	success?: boolean;
}

/** BizResponse«PageResult«InboundOrderRespDTO»» */
export interface BizResponsePageResultInboundOrderRespDTO {
	code?: string;
	data?: PageResultInboundOrderRespDTO;
	msg?: string;
	success?: boolean;
}

/** BizResponse«PageResult«LogisticsTrackPageResp»» */
export interface BizResponsePageResultLogisticsTrackPageResp {
	code?: string;
	data?: PageResultLogisticsTrackPageResp;
	msg?: string;
	success?: boolean;
}

/** BizResponse«PageResult«OperateRecordPageResp»» */
export interface BizResponsePageResultOperateRecordPageResp {
	code?: string;
	data?: PageResultOperateRecordPageResp;
	msg?: string;
	success?: boolean;
}

/** BizResponse«PageResult«PkgDeductionPageResp»» */
export interface BizResponsePageResultPkgDeductionPageResp {
	code?: string;
	data?: PageResultPkgDeductionPageResp;
	msg?: string;
	success?: boolean;
}

/** BizResponse«PageResult«PkgPageRespDTO»» */
export interface BizResponsePageResultPkgPageRespDTO {
	code?: string;
	data?: PageResultPkgPageRespDTO;
	msg?: string;
	success?: boolean;
}

/** BizResponse«PageResult«ProductMeasureRespDTO»» */
export interface BizResponsePageResultProductMeasureRespDTO {
	code?: string;
	data?: PageResultProductMeasureRespDTO;
	msg?: string;
	success?: boolean;
}

/** BizResponse«PageResult«SkuInventoryRespDTO»» */
export interface BizResponsePageResultSkuInventoryRespDTO {
	code?: string;
	data?: PageResultSkuInventoryRespDTO;
	msg?: string;
	success?: boolean;
}

/** BizResponse«PageResult«StockHistoryPageResp»» */
export interface BizResponsePageResultStockHistoryPageResp {
	code?: string;
	data?: PageResultStockHistoryPageResp;
	msg?: string;
	success?: boolean;
}

/** BizResponse«Page«BasketRespDTO»» */
export interface BizResponsePageBasketRespDTO {
	code?: string;
	data?: PageBasketRespDTO;
	msg?: string;
	success?: boolean;
}

/** BizResponse«Page«BoxTemplateRespDTO»» */
export interface BizResponsePageBoxTemplateRespDTO {
	code?: string;
	data?: PageBoxTemplateRespDTO;
	msg?: string;
	success?: boolean;
}

/** BizResponse«Page«ContainerConfigDTO»» */
export interface BizResponsePageContainerConfigDTO {
	code?: string;
	data?: PageContainerConfigDTO;
	msg?: string;
	success?: boolean;
}

/** BizResponse«Page«DeliveryPickTaskRespDTO»» */
export interface BizResponsePageDeliveryPickTaskRespDTO {
	code?: string;
	data?: PageDeliveryPickTaskRespDTO;
	msg?: string;
	success?: boolean;
}

/** BizResponse«Page«DomesticDeliverDTO»» */
export interface BizResponsePageDomesticDeliverDTO {
	code?: string;
	data?: PageDomesticDeliverDTO;
	msg?: string;
	success?: boolean;
}

/** BizResponse«Page«DomesticModifyAddressRespDTO»» */
export interface BizResponsePageDomesticModifyAddressRespDTO {
	code?: string;
	data?: PageDomesticModifyAddressRespDTO;
	msg?: string;
	success?: boolean;
}

/** BizResponse«Page«FlowPageRespDTO»» */
export interface BizResponsePageFlowPageRespDTO {
	code?: string;
	data?: PageFlowPageRespDTO;
	msg?: string;
	success?: boolean;
}

/** BizResponse«Page«Grid»» */
export interface BizResponsePageGrid {
	code?: string;
	data?: PageGrid;
	msg?: string;
	success?: boolean;
}

/** BizResponse«Page«InnerExceptionRespDTO»» */
export interface BizResponsePageInnerExceptionRespDTO {
	code?: string;
	data?: PageInnerExceptionRespDTO;
	msg?: string;
	success?: boolean;
}

/** BizResponse«Page«LogisticsApiConfigRespDTO»» */
export interface BizResponsePageLogisticsApiConfigRespDTO {
	code?: string;
	data?: PageLogisticsApiConfigRespDTO;
	msg?: string;
	success?: boolean;
}

/** BizResponse«Page«PackAbleTaskPageDTO»» */
export interface BizResponsePagePackAbleTaskPageDTO {
	code?: string;
	data?: PagePackAbleTaskPageDTO;
	msg?: string;
	success?: boolean;
}

/** BizResponse«Page«PackAllot»» */
export interface BizResponsePagePackAllot {
	code?: string;
	data?: PagePackAllot;
	msg?: string;
	success?: boolean;
}

/** BizResponse«Page«PackTaskPageDTO»» */
export interface BizResponsePagePackTaskPageDTO {
	code?: string;
	data?: PagePackTaskPageDTO;
	msg?: string;
	success?: boolean;
}

/** BizResponse«Page«PageDefaultLabelConfigRespDTO»» */
export interface BizResponsePagePageDefaultLabelConfigRespDTO {
	code?: string;
	data?: PagePageDefaultLabelConfigRespDTO;
	msg?: string;
	success?: boolean;
}

/** BizResponse«Page«PagePrintConfigRespDTO»» */
export interface BizResponsePagePagePrintConfigRespDTO {
	code?: string;
	data?: PagePagePrintConfigRespDTO;
	msg?: string;
	success?: boolean;
}

/** BizResponse«Page«PickTaskPageDTO»» */
export interface BizResponsePagePickTaskPageDTO {
	code?: string;
	data?: PagePickTaskPageDTO;
	msg?: string;
	success?: boolean;
}

/** BizResponse«Page«PickingTaskNotListedRespDTO»» */
export interface BizResponsePagePickingTaskNotListedRespDTO {
	code?: string;
	data?: PagePickingTaskNotListedRespDTO;
	msg?: string;
	success?: boolean;
}

/** BizResponse«Page«PkgDTO»» */
export interface BizResponsePagePkgDTO {
	code?: string;
	data?: PagePkgDTO;
	msg?: string;
	success?: boolean;
}

/** BizResponse«Page«SignRecord»» */
export interface BizResponsePageSignRecord {
	code?: string;
	data?: PageSignRecord;
	msg?: string;
	success?: boolean;
}

/** BizResponse«Page«TransferDTO»» */
export interface BizResponsePageTransferDTO {
	code?: string;
	data?: PageTransferDTO;
	msg?: string;
	success?: boolean;
}

/** BizResponse«Page«TransferDeliveryPageRespDTO»» */
export interface BizResponsePageTransferDeliveryPageRespDTO {
	code?: string;
	data?: PageTransferDeliveryPageRespDTO;
	msg?: string;
	success?: boolean;
}

/** BizResponse«Page«TransferWarehousePageRespDTO»» */
export interface BizResponsePageTransferWarehousePageRespDTO {
	code?: string;
	data?: PageTransferWarehousePageRespDTO;
	msg?: string;
	success?: boolean;
}

/** BizResponse«Page«WeightCostConfigRespDTO»» */
export interface BizResponsePageWeightCostConfigRespDTO {
	code?: string;
	data?: PageWeightCostConfigRespDTO;
	msg?: string;
	success?: boolean;
}

/** BizResponse«PickBatchTaskStartResp» */
export interface BizResponsePickBatchTaskStartResp {
	code?: string;
	data?: PickBatchTaskStartResp;
	msg?: string;
	success?: boolean;
}

/** BizResponse«PickSortingDTO» */
export interface BizResponsePickSortingDTO {
	code?: string;
	data?: PickSortingDTO;
	msg?: string;
	success?: boolean;
}

/** BizResponse«PkgDTO» */
export interface BizResponsePkgDTO {
	code?: string;
	data?: PkgDTO;
	msg?: string;
	success?: boolean;
}

/** BizResponse«PkgSkuInfoRespDTO» */
export interface BizResponsePkgSkuInfoRespDTO {
	code?: string;
	data?: PkgSkuInfoRespDTO;
	msg?: string;
	success?: boolean;
}

/** BizResponse«Pkg» */
export interface BizResponsePkg {
	code?: string;
	data?: Pkg;
	msg?: string;
	success?: boolean;
}

/** BizResponse«PrintConfigVO» */
export interface BizResponsePrintConfigVO {
	code?: string;
	data?: PrintConfigVO;
	msg?: string;
	success?: boolean;
}

/** BizResponse«PrintTemplate» */
export interface BizResponsePrintTemplate {
	code?: string;
	data?: PrintTemplate;
	msg?: string;
	success?: boolean;
}

/** BizResponse«PurchaseDto» */
export interface BizResponsePurchaseDto {
	code?: string;
	data?: PurchaseDto;
	msg?: string;
	success?: boolean;
}

/** BizResponse«RegularizationPageRespDTO» */
export interface BizResponseRegularizationPageRespDTO {
	code?: string;
	data?: RegularizationPageRespDTO;
	msg?: string;
	success?: boolean;
}

/** BizResponse«RegularizationRespDTO» */
export interface BizResponseRegularizationRespDTO {
	code?: string;
	data?: RegularizationRespDTO;
	msg?: string;
	success?: boolean;
}

/** BizResponse«SignDTO» */
export interface BizResponseSignDTO {
	code?: string;
	data?: SignDTO;
	msg?: string;
	success?: boolean;
}

/** BizResponse«SinglePackPkgCheckResp» */
export interface BizResponseSinglePackPkgCheckResp {
	code?: string;
	data?: SinglePackPkgCheckResp;
	msg?: string;
	success?: boolean;
}

/** BizResponse«StockFreeze4StockRespDTO» */
export interface BizResponseStockFreeze4StockRespDTO {
	code?: string;
	data?: StockFreeze4StockRespDTO;
	msg?: string;
	success?: boolean;
}

/** BizResponse«TransferRespDTO» */
export interface BizResponseTransferRespDTO {
	code?: string;
	data?: TransferRespDTO;
	msg?: string;
	success?: boolean;
}

/** BizResponse«TransferSpiltRespDTO» */
export interface BizResponseTransferSpiltRespDTO {
	code?: string;
	data?: TransferSpiltRespDTO;
	msg?: string;
	success?: boolean;
}

/** BizResponse«TransferWarehouseConfirmRespDTO» */
export interface BizResponseTransferWarehouseConfirmRespDTO {
	code?: string;
	data?: TransferWarehouseConfirmRespDTO;
	msg?: string;
	success?: boolean;
}

/** BizResponse«TransferWarehousePkgRespDTO» */
export interface BizResponseTransferWarehousePkgRespDTO {
	code?: string;
	data?: TransferWarehousePkgRespDTO;
	msg?: string;
	success?: boolean;
}

/** BizResponse«TransferWarehousePrintPickingRespDTO» */
export interface BizResponseTransferWarehousePrintPickingRespDTO {
	code?: string;
	data?: TransferWarehousePrintPickingRespDTO;
	msg?: string;
	success?: boolean;
}

/** BizResponse«Void» */
export interface BizResponseVoid {
	code?: string;
	msg?: string;
	success?: boolean;
}

/** BizResponse«boolean» */
export interface BizResponseBoolean {
	code?: string;
	data?: boolean;
	msg?: string;
	success?: boolean;
}

/** BizResponse«int» */
export interface BizResponseInt {
	code?: string;
	/** @format int32 */
	data?: number;
	msg?: string;
	success?: boolean;
}

/** BizResponse«long» */
export interface BizResponseLong {
	code?: string;
	/** @format int64 */
	data?: number;
	msg?: string;
	success?: boolean;
}

/** BizResponse«object» */
export interface BizResponseObject {
	code?: string;
	data?: object;
	msg?: string;
	success?: boolean;
}

/** BizResponse«string» */
export interface BizResponseString {
	code?: string;
	data?: string;
	msg?: string;
	success?: boolean;
}

/** IPage«StockOperation» */
export interface IPageStockOperation {
	/** @format int64 */
	current?: number;
	hitCount?: boolean;
	/** @format int64 */
	pages?: number;
	records?: StockOperation[];
	searchCount?: boolean;
	/** @format int64 */
	size?: number;
	/** @format int64 */
	total?: number;
}

/** IPage«Stock» */
export interface IPageStock {
	/** @format int64 */
	current?: number;
	hitCount?: boolean;
	/** @format int64 */
	pages?: number;
	records?: Stock[];
	searchCount?: boolean;
	/** @format int64 */
	size?: number;
	/** @format int64 */
	total?: number;
}

/** PageResult«ContainerPageResp» */
export interface PageResultContainerPageResp {
	/** @format int64 */
	current?: number;
	records?: ContainerPageResp[];
	/** @format int64 */
	size?: number;
	/** @format int64 */
	total?: number;
	/** @format int64 */
	totalPage?: number;
}

/** PageResult«DeliverTaskRespDTO» */
export interface PageResultDeliverTaskRespDTO {
	/** @format int64 */
	current?: number;
	records?: DeliverTaskRespDTO[];
	/** @format int64 */
	size?: number;
	/** @format int64 */
	total?: number;
	/** @format int64 */
	totalPage?: number;
}

/** PageResult«DeliverTaskWmsPageDto» */
export interface PageResultDeliverTaskWmsPageDto {
	/** @format int64 */
	current?: number;
	records?: DeliverTaskWmsPageDto[];
	/** @format int64 */
	size?: number;
	/** @format int64 */
	total?: number;
	/** @format int64 */
	totalPage?: number;
}

/** PageResult«DeliveryPickBatchPageResp» */
export interface PageResultDeliveryPickBatchPageResp {
	/** @format int64 */
	current?: number;
	records?: DeliveryPickBatchPageResp[];
	/** @format int64 */
	size?: number;
	/** @format int64 */
	total?: number;
	/** @format int64 */
	totalPage?: number;
}

/** PageResult«DeliveryPickBatchPkgPageResp» */
export interface PageResultDeliveryPickBatchPkgPageResp {
	/** @format int64 */
	current?: number;
	records?: DeliveryPickBatchPkgPageResp[];
	/** @format int64 */
	size?: number;
	/** @format int64 */
	total?: number;
	/** @format int64 */
	totalPage?: number;
}

/** PageResult«DeliveryReviewPageApiRespDTO» */
export interface PageResultDeliveryReviewPageApiRespDTO {
	/** @format int64 */
	current?: number;
	records?: DeliveryReviewPageApiRespDTO[];
	/** @format int64 */
	size?: number;
	/** @format int64 */
	total?: number;
	/** @format int64 */
	totalPage?: number;
}

/** PageResult«DeliveryTaskInvoiceDTO» */
export interface PageResultDeliveryTaskInvoiceDTO {
	/** @format int64 */
	current?: number;
	records?: DeliveryTaskInvoiceDTO[];
	/** @format int64 */
	size?: number;
	/** @format int64 */
	total?: number;
	/** @format int64 */
	totalPage?: number;
}

/** PageResult«DeliveryWavePageResp» */
export interface PageResultDeliveryWavePageResp {
	/** @format int64 */
	current?: number;
	records?: DeliveryWavePageResp[];
	/** @format int64 */
	size?: number;
	/** @format int64 */
	total?: number;
	/** @format int64 */
	totalPage?: number;
}

/** PageResult«ExportRecordDTO» */
export interface PageResultExportRecordDTO {
	/** @format int64 */
	current?: number;
	records?: ExportRecordDTO[];
	/** @format int64 */
	size?: number;
	/** @format int64 */
	total?: number;
	/** @format int64 */
	totalPage?: number;
}

/** PageResult«InboundOperationRecordRespDTO» */
export interface PageResultInboundOperationRecordRespDTO {
	/** @format int64 */
	current?: number;
	records?: InboundOperationRecordRespDTO[];
	/** @format int64 */
	size?: number;
	/** @format int64 */
	total?: number;
	/** @format int64 */
	totalPage?: number;
}

/** PageResult«InboundOrderRespDTO» */
export interface PageResultInboundOrderRespDTO {
	/** @format int64 */
	current?: number;
	records?: InboundOrderRespDTO[];
	/** @format int64 */
	size?: number;
	/** @format int64 */
	total?: number;
	/** @format int64 */
	totalPage?: number;
}

/** PageResult«LogisticsTrackPageResp» */
export interface PageResultLogisticsTrackPageResp {
	/** @format int64 */
	current?: number;
	records?: LogisticsTrackPageResp[];
	/** @format int64 */
	size?: number;
	/** @format int64 */
	total?: number;
	/** @format int64 */
	totalPage?: number;
}

/** PageResult«OperateRecordPageResp» */
export interface PageResultOperateRecordPageResp {
	/** @format int64 */
	current?: number;
	records?: OperateRecordPageResp[];
	/** @format int64 */
	size?: number;
	/** @format int64 */
	total?: number;
	/** @format int64 */
	totalPage?: number;
}

/** PageResult«PkgDeductionPageResp» */
export interface PageResultPkgDeductionPageResp {
	/** @format int64 */
	current?: number;
	records?: PkgDeductionPageResp[];
	/** @format int64 */
	size?: number;
	/** @format int64 */
	total?: number;
	/** @format int64 */
	totalPage?: number;
}

/** PageResult«PkgPageRespDTO» */
export interface PageResultPkgPageRespDTO {
	/** @format int64 */
	current?: number;
	records?: PkgPageRespDTO[];
	/** @format int64 */
	size?: number;
	/** @format int64 */
	total?: number;
	/** @format int64 */
	totalPage?: number;
}

/** PageResult«ProductMeasureRespDTO» */
export interface PageResultProductMeasureRespDTO {
	/** @format int64 */
	current?: number;
	records?: ProductMeasureRespDTO[];
	/** @format int64 */
	size?: number;
	/** @format int64 */
	total?: number;
	/** @format int64 */
	totalPage?: number;
}

/** PageResult«SkuInventoryRespDTO» */
export interface PageResultSkuInventoryRespDTO {
	/** @format int64 */
	current?: number;
	records?: SkuInventoryRespDTO[];
	/** @format int64 */
	size?: number;
	/** @format int64 */
	total?: number;
	/** @format int64 */
	totalPage?: number;
}

/** PageResult«StockHistoryPageResp» */
export interface PageResultStockHistoryPageResp {
	/** @format int64 */
	current?: number;
	records?: StockHistoryPageResp[];
	/** @format int64 */
	size?: number;
	/** @format int64 */
	total?: number;
	/** @format int64 */
	totalPage?: number;
}

/** Page«BasketRespDTO» */
export interface PageBasketRespDTO {
	countId?: string;
	/** @format int64 */
	current?: number;
	hitCount?: boolean;
	/** @format int64 */
	maxLimit?: number;
	optimizeCountSql?: boolean;
	orders?: OrderItem[];
	/** @format int64 */
	pages?: number;
	records?: BasketRespDTO[];
	searchCount?: boolean;
	/** @format int64 */
	size?: number;
	/** @format int64 */
	total?: number;
}

/** Page«BoxTemplateRespDTO» */
export interface PageBoxTemplateRespDTO {
	countId?: string;
	/** @format int64 */
	current?: number;
	hitCount?: boolean;
	/** @format int64 */
	maxLimit?: number;
	optimizeCountSql?: boolean;
	orders?: OrderItem[];
	/** @format int64 */
	pages?: number;
	records?: BoxTemplateRespDTO[];
	searchCount?: boolean;
	/** @format int64 */
	size?: number;
	/** @format int64 */
	total?: number;
}

/** Page«ContainerConfigDTO» */
export interface PageContainerConfigDTO {
	countId?: string;
	/** @format int64 */
	current?: number;
	hitCount?: boolean;
	/** @format int64 */
	maxLimit?: number;
	optimizeCountSql?: boolean;
	orders?: OrderItem[];
	/** @format int64 */
	pages?: number;
	records?: ContainerConfigDTO[];
	searchCount?: boolean;
	/** @format int64 */
	size?: number;
	/** @format int64 */
	total?: number;
}

/** Page«DeliveryPickTaskRespDTO» */
export interface PageDeliveryPickTaskRespDTO {
	countId?: string;
	/** @format int64 */
	current?: number;
	hitCount?: boolean;
	/** @format int64 */
	maxLimit?: number;
	optimizeCountSql?: boolean;
	orders?: OrderItem[];
	/** @format int64 */
	pages?: number;
	records?: DeliveryPickTaskRespDTO[];
	searchCount?: boolean;
	/** @format int64 */
	size?: number;
	/** @format int64 */
	total?: number;
}

/** Page«DeliveryReviewRespDTO» */
export interface PageDeliveryReviewRespDTO {
	countId?: string;
	/** @format int64 */
	current?: number;
	hitCount?: boolean;
	/** @format int64 */
	maxLimit?: number;
	optimizeCountSql?: boolean;
	orders?: OrderItem[];
	/** @format int64 */
	pages?: number;
	records?: DeliveryReviewRespDTO[];
	searchCount?: boolean;
	/** @format int64 */
	size?: number;
	/** @format int64 */
	total?: number;
}

/** Page«DomesticDeliverDTO» */
export interface PageDomesticDeliverDTO {
	countId?: string;
	/** @format int64 */
	current?: number;
	hitCount?: boolean;
	/** @format int64 */
	maxLimit?: number;
	optimizeCountSql?: boolean;
	orders?: OrderItem[];
	/** @format int64 */
	pages?: number;
	records?: DomesticDeliverDTO[];
	searchCount?: boolean;
	/** @format int64 */
	size?: number;
	/** @format int64 */
	total?: number;
}

/** Page«DomesticModifyAddressRespDTO» */
export interface PageDomesticModifyAddressRespDTO {
	countId?: string;
	/** @format int64 */
	current?: number;
	hitCount?: boolean;
	/** @format int64 */
	maxLimit?: number;
	optimizeCountSql?: boolean;
	orders?: OrderItem[];
	/** @format int64 */
	pages?: number;
	records?: DomesticModifyAddressRespDTO[];
	searchCount?: boolean;
	/** @format int64 */
	size?: number;
	/** @format int64 */
	total?: number;
}

/** Page«FlowPageRespDTO» */
export interface PageFlowPageRespDTO {
	countId?: string;
	/** @format int64 */
	current?: number;
	hitCount?: boolean;
	/** @format int64 */
	maxLimit?: number;
	optimizeCountSql?: boolean;
	orders?: OrderItem[];
	/** @format int64 */
	pages?: number;
	records?: FlowPageRespDTO[];
	searchCount?: boolean;
	/** @format int64 */
	size?: number;
	/** @format int64 */
	total?: number;
}

/** Page«Grid» */
export interface PageGrid {
	countId?: string;
	/** @format int64 */
	current?: number;
	hitCount?: boolean;
	/** @format int64 */
	maxLimit?: number;
	optimizeCountSql?: boolean;
	orders?: OrderItem[];
	/** @format int64 */
	pages?: number;
	records?: Grid[];
	searchCount?: boolean;
	/** @format int64 */
	size?: number;
	/** @format int64 */
	total?: number;
}

/** Page«InnerExceptionRespDTO» */
export interface PageInnerExceptionRespDTO {
	countId?: string;
	/** @format int64 */
	current?: number;
	hitCount?: boolean;
	/** @format int64 */
	maxLimit?: number;
	optimizeCountSql?: boolean;
	orders?: OrderItem[];
	/** @format int64 */
	pages?: number;
	records?: InnerExceptionRespDTO[];
	searchCount?: boolean;
	/** @format int64 */
	size?: number;
	/** @format int64 */
	total?: number;
}

/** Page«LogisticsApiConfigRespDTO» */
export interface PageLogisticsApiConfigRespDTO {
	countId?: string;
	/** @format int64 */
	current?: number;
	hitCount?: boolean;
	/** @format int64 */
	maxLimit?: number;
	optimizeCountSql?: boolean;
	orders?: OrderItem[];
	/** @format int64 */
	pages?: number;
	records?: LogisticsApiConfigRespDTO[];
	searchCount?: boolean;
	/** @format int64 */
	size?: number;
	/** @format int64 */
	total?: number;
}

/** Page«PackAbleTaskPageDTO» */
export interface PagePackAbleTaskPageDTO {
	countId?: string;
	/** @format int64 */
	current?: number;
	hitCount?: boolean;
	/** @format int64 */
	maxLimit?: number;
	optimizeCountSql?: boolean;
	orders?: OrderItem[];
	/** @format int64 */
	pages?: number;
	records?: PackAbleTaskPageDTO[];
	searchCount?: boolean;
	/** @format int64 */
	size?: number;
	/** @format int64 */
	total?: number;
}

/** Page«PackAllot» */
export interface PagePackAllot {
	countId?: string;
	/** @format int64 */
	current?: number;
	hitCount?: boolean;
	/** @format int64 */
	maxLimit?: number;
	optimizeCountSql?: boolean;
	orders?: OrderItem[];
	/** @format int64 */
	pages?: number;
	records?: PackAllot[];
	searchCount?: boolean;
	/** @format int64 */
	size?: number;
	/** @format int64 */
	total?: number;
}

/** Page«PackTaskPageDTO» */
export interface PagePackTaskPageDTO {
	countId?: string;
	/** @format int64 */
	current?: number;
	hitCount?: boolean;
	/** @format int64 */
	maxLimit?: number;
	optimizeCountSql?: boolean;
	orders?: OrderItem[];
	/** @format int64 */
	pages?: number;
	records?: PackTaskPageDTO[];
	searchCount?: boolean;
	/** @format int64 */
	size?: number;
	/** @format int64 */
	total?: number;
}

/** Page«PageDefaultLabelConfigRespDTO» */
export interface PagePageDefaultLabelConfigRespDTO {
	countId?: string;
	/** @format int64 */
	current?: number;
	hitCount?: boolean;
	/** @format int64 */
	maxLimit?: number;
	optimizeCountSql?: boolean;
	orders?: OrderItem[];
	/** @format int64 */
	pages?: number;
	records?: PageDefaultLabelConfigRespDTO[];
	searchCount?: boolean;
	/** @format int64 */
	size?: number;
	/** @format int64 */
	total?: number;
}

/** Page«PagePrintConfigRespDTO» */
export interface PagePagePrintConfigRespDTO {
	countId?: string;
	/** @format int64 */
	current?: number;
	hitCount?: boolean;
	/** @format int64 */
	maxLimit?: number;
	optimizeCountSql?: boolean;
	orders?: OrderItem[];
	/** @format int64 */
	pages?: number;
	records?: PagePrintConfigRespDTO[];
	searchCount?: boolean;
	/** @format int64 */
	size?: number;
	/** @format int64 */
	total?: number;
}

/** Page«PickTaskPageDTO» */
export interface PagePickTaskPageDTO {
	countId?: string;
	/** @format int64 */
	current?: number;
	hitCount?: boolean;
	/** @format int64 */
	maxLimit?: number;
	optimizeCountSql?: boolean;
	orders?: OrderItem[];
	/** @format int64 */
	pages?: number;
	records?: PickTaskPageDTO[];
	searchCount?: boolean;
	/** @format int64 */
	size?: number;
	/** @format int64 */
	total?: number;
}

/** Page«PickingTaskNotListedRespDTO» */
export interface PagePickingTaskNotListedRespDTO {
	countId?: string;
	/** @format int64 */
	current?: number;
	hitCount?: boolean;
	/** @format int64 */
	maxLimit?: number;
	optimizeCountSql?: boolean;
	orders?: OrderItem[];
	/** @format int64 */
	pages?: number;
	records?: PickingTaskNotListedRespDTO[];
	searchCount?: boolean;
	/** @format int64 */
	size?: number;
	/** @format int64 */
	total?: number;
}

/** Page«PkgDTO» */
export interface PagePkgDTO {
	countId?: string;
	/** @format int64 */
	current?: number;
	hitCount?: boolean;
	/** @format int64 */
	maxLimit?: number;
	optimizeCountSql?: boolean;
	orders?: OrderItem[];
	/** @format int64 */
	pages?: number;
	records?: PkgDTO[];
	searchCount?: boolean;
	/** @format int64 */
	size?: number;
	/** @format int64 */
	total?: number;
}

/** Page«RegularizationRespDTO» */
export interface PageRegularizationRespDTO {
	countId?: string;
	/** @format int64 */
	current?: number;
	hitCount?: boolean;
	/** @format int64 */
	maxLimit?: number;
	optimizeCountSql?: boolean;
	orders?: OrderItem[];
	/** @format int64 */
	pages?: number;
	records?: RegularizationRespDTO[];
	searchCount?: boolean;
	/** @format int64 */
	size?: number;
	/** @format int64 */
	total?: number;
}

/** Page«SignRecord» */
export interface PageSignRecord {
	countId?: string;
	/** @format int64 */
	current?: number;
	hitCount?: boolean;
	/** @format int64 */
	maxLimit?: number;
	optimizeCountSql?: boolean;
	orders?: OrderItem[];
	/** @format int64 */
	pages?: number;
	records?: SignRecord[];
	searchCount?: boolean;
	/** @format int64 */
	size?: number;
	/** @format int64 */
	total?: number;
}

/** Page«TransferDTO» */
export interface PageTransferDTO {
	countId?: string;
	/** @format int64 */
	current?: number;
	hitCount?: boolean;
	/** @format int64 */
	maxLimit?: number;
	optimizeCountSql?: boolean;
	orders?: OrderItem[];
	/** @format int64 */
	pages?: number;
	records?: TransferDTO[];
	searchCount?: boolean;
	/** @format int64 */
	size?: number;
	/** @format int64 */
	total?: number;
}

/** Page«TransferDeliveryPageRespDTO» */
export interface PageTransferDeliveryPageRespDTO {
	countId?: string;
	/** @format int64 */
	current?: number;
	hitCount?: boolean;
	/** @format int64 */
	maxLimit?: number;
	optimizeCountSql?: boolean;
	orders?: OrderItem[];
	/** @format int64 */
	pages?: number;
	records?: TransferDeliveryPageRespDTO[];
	searchCount?: boolean;
	/** @format int64 */
	size?: number;
	/** @format int64 */
	total?: number;
}

/** Page«TransferWarehousePageRespDTO» */
export interface PageTransferWarehousePageRespDTO {
	countId?: string;
	/** @format int64 */
	current?: number;
	hitCount?: boolean;
	/** @format int64 */
	maxLimit?: number;
	optimizeCountSql?: boolean;
	orders?: OrderItem[];
	/** @format int64 */
	pages?: number;
	records?: TransferWarehousePageRespDTO[];
	searchCount?: boolean;
	/** @format int64 */
	size?: number;
	/** @format int64 */
	total?: number;
}

/** Page«WeightCostConfigRespDTO» */
export interface PageWeightCostConfigRespDTO {
	countId?: string;
	/** @format int64 */
	current?: number;
	hitCount?: boolean;
	/** @format int64 */
	maxLimit?: number;
	optimizeCountSql?: boolean;
	orders?: OrderItem[];
	/** @format int64 */
	pages?: number;
	records?: WeightCostConfigRespDTO[];
	searchCount?: boolean;
	/** @format int64 */
	size?: number;
	/** @format int64 */
	total?: number;
}

/** 国际发货操作记录 */
export interface _ {
	bizContent?: string;
	/** 业务编号 */
	bizNo?: string;
	/** 国际发货单号 */
	deliveryTaskCode?: string;
	/** 中国货代公司名称 */
	forwarder?: string;
	/**
	 * 生成方式(1:手动 2:API)
	 * @format int32
	 */
	generateMode?: number;
	/** 物流单号 */
	logisticsCode?: string;
	/** 原客户经理名称 */
	managerName?: string;
	/** 更改后的客户经理名称 */
	managerNewName?: string;
	/** 新-中国货代公司名称 */
	newForwarder?: string;
	/**
	 * 节点类型
	 * @format int32
	 */
	nodeType?: number;
	params?: string;
	/** 拣货任务编号 */
	pickTaskCode?: string;
	/** 拆单国际发货单号 */
	splitDeliveryTaskCode?: string;
	/** 仓库 */
	warehouse?: string;
}

import axios, {
	AxiosInstance,
	AxiosRequestConfig,
	HeadersDefaults,
	ResponseType
} from 'axios';

export type QueryParamsType = Record<string | number, any>;

export interface FullRequestParams
	extends Omit<
		AxiosRequestConfig,
		'data' | 'params' | 'url' | 'responseType'
	> {
	/** set parameter to `true` for call `securityWorker` for this request */
	secure?: boolean;
	/** request path */
	path: string;
	/** content type of request body */
	type?: ContentType;
	/** query params */
	query?: QueryParamsType;
	/** format of response (i.e. response.json() -> format: "json") */
	format?: ResponseType;
	/** request body */
	body?: unknown;
}

export type RequestParams = Omit<
	FullRequestParams,
	'body' | 'method' | 'query' | 'path'
>;

export interface ApiConfig<SecurityDataType = unknown>
	extends Omit<AxiosRequestConfig, 'data' | 'cancelToken'> {
	securityWorker?: (
		securityData: SecurityDataType | null
	) => Promise<AxiosRequestConfig | void> | AxiosRequestConfig | void;
	secure?: boolean;
	format?: ResponseType;
}

export enum ContentType {
	Json = 'application/json',
	FormData = 'multipart/form-data',
	UrlEncoded = 'application/x-www-form-urlencoded',
	Text = 'text/plain'
}

export class HttpClient<SecurityDataType = unknown> {
	public instance: AxiosInstance;
	private securityData: SecurityDataType | null = null;
	private securityWorker?: ApiConfig<SecurityDataType>['securityWorker'];
	private secure?: boolean;
	private format?: ResponseType;

	constructor({
		securityWorker,
		secure,
		format,
		...axiosConfig
	}: ApiConfig<SecurityDataType> = {}) {
		this.instance = axios.create({
			...axiosConfig,
			baseURL: axiosConfig.baseURL || '//master-gateway.theckb.com:443'
		});
		this.secure = secure;
		this.format = format;
		this.securityWorker = securityWorker;
	}

	public setSecurityData = (data: SecurityDataType | null) => {
		this.securityData = data;
	};

	protected mergeRequestParams(
		params1: AxiosRequestConfig,
		params2?: AxiosRequestConfig
	): AxiosRequestConfig {
		const method = params1.method || (params2 && params2.method);

		return {
			...this.instance.defaults,
			...params1,
			...(params2 || {}),
			headers: {
				...((method &&
					this.instance.defaults.headers[
						method.toLowerCase() as keyof HeadersDefaults
					]) ||
					{}),
				...(params1.headers || {}),
				...((params2 && params2.headers) || {})
			}
		};
	}

	protected stringifyFormItem(formItem: unknown) {
		if (typeof formItem === 'object' && formItem !== null) {
			return JSON.stringify(formItem);
		} else {
			return `${formItem}`;
		}
	}

	protected createFormData(input: Record<string, unknown>): FormData {
		return Object.keys(input || {}).reduce((formData, key) => {
			const property = input[key];
			const propertyContent: any[] =
				property instanceof Array ? property : [property];

			for (const formItem of propertyContent) {
				const isFileType =
					formItem instanceof Blob || formItem instanceof File;
				formData.append(
					key,
					isFileType ? formItem : this.stringifyFormItem(formItem)
				);
			}

			return formData;
		}, new FormData());
	}

	public request = async <T = any, _E = any>({
		secure,
		path,
		type,
		query,
		format,
		body,
		...params
	}: FullRequestParams): Promise<T> => {
		const secureParams =
			((typeof secure === 'boolean' ? secure : this.secure) &&
				this.securityWorker &&
				(await this.securityWorker(this.securityData))) ||
			{};
		const requestParams = this.mergeRequestParams(params, secureParams);
		const responseFormat = format || this.format || undefined;

		if (
			type === ContentType.FormData &&
			body &&
			body !== null &&
			typeof body === 'object'
		) {
			body = this.createFormData(body as Record<string, unknown>);
		}

		if (
			type === ContentType.Text &&
			body &&
			body !== null &&
			typeof body !== 'string'
		) {
			body = JSON.stringify(body);
		}

		return this.instance
			.request({
				...requestParams,
				headers: {
					...(requestParams.headers || {}),
					...(type && type !== ContentType.FormData
						? { 'Content-Type': type }
						: {})
				},
				params: query,
				responseType: responseFormat,
				data: body,
				url: path
			})
			.then((response) => response.data);
	};
}

/**
 * @title WMS-API测试文档
 * @version v1.1.0
 * @baseUrl //master-gateway.theckb.com:443
 * @contact 史尼芙 (https://www.taobaockb.com/)
 *
 * 史尼芙测试文档
 */
export class Api<
	SecurityDataType extends unknown
> extends HttpClient<SecurityDataType> {
	wms = {
		/**
		 * No description
		 *
		 * @tags 健康检查探针
		 * @name ProbeTest
		 * @summary 执行一次健康检查探针
		 * @request GET:/wms/Probe/test
		 */
		probeTest: (params: RequestParams = {}) =>
			this.request<BizResponseObject, any>({
				path: `/wms/Probe/test`,
				method: 'GET',
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 健康检查探针
		 * @name ProbeTestGoods
		 * @summary testGoods
		 * @request GET:/wms/Probe/testGoods
		 */
		probeTestGoods: (params: RequestParams = {}) =>
			this.request<BizResponseBoolean, any>({
				path: `/wms/Probe/testGoods`,
				method: 'GET',
				...params
			}),

		/**
		 * No description
		 *
		 * @tags monitor-contorller
		 * @name Actuator1JvmInfo
		 * @summary jvmInfo
		 * @request GET:/wms/actuator1/jvmInfo
		 */
		actuator1JvmInfo: (params: RequestParams = {}) =>
			this.request<void, any>({
				path: `/wms/actuator1/jvmInfo`,
				method: 'GET',
				...params
			}),

		/**
		 * No description
		 *
		 * @tags monitor-contorller
		 * @name HeadWms
		 * @summary jvmInfo
		 * @request HEAD:/wms/actuator1/jvmInfo
		 */
		headWms: (params: RequestParams = {}) =>
			this.request<void, any>({
				path: `/wms/actuator1/jvmInfo`,
				method: 'HEAD',
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags monitor-contorller
		 * @name Actuator1JvmInfo2
		 * @summary jvmInfo
		 * @request POST:/wms/actuator1/jvmInfo
		 * @originalName actuator1JvmInfo
		 * @duplicate
		 */
		actuator1JvmInfo2: (params: RequestParams = {}) =>
			this.request<void, any>({
				path: `/wms/actuator1/jvmInfo`,
				method: 'POST',
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags monitor-contorller
		 * @name Actuator1JvmInfo3
		 * @summary jvmInfo
		 * @request PUT:/wms/actuator1/jvmInfo
		 * @originalName actuator1JvmInfo
		 * @duplicate
		 */
		actuator1JvmInfo3: (params: RequestParams = {}) =>
			this.request<void, any>({
				path: `/wms/actuator1/jvmInfo`,
				method: 'PUT',
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags monitor-contorller
		 * @name Actuator1JvmInfo4
		 * @summary jvmInfo
		 * @request DELETE:/wms/actuator1/jvmInfo
		 * @originalName actuator1JvmInfo
		 * @duplicate
		 */
		actuator1JvmInfo4: (params: RequestParams = {}) =>
			this.request<void, any>({
				path: `/wms/actuator1/jvmInfo`,
				method: 'DELETE',
				...params
			}),

		/**
		 * No description
		 *
		 * @tags monitor-contorller
		 * @name OptionsWms
		 * @summary jvmInfo
		 * @request OPTIONS:/wms/actuator1/jvmInfo
		 */
		optionsWms: (params: RequestParams = {}) =>
			this.request<void, any>({
				path: `/wms/actuator1/jvmInfo`,
				method: 'OPTIONS',
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags monitor-contorller
		 * @name Actuator1JvmInfo5
		 * @summary jvmInfo
		 * @request PATCH:/wms/actuator1/jvmInfo
		 * @originalName actuator1JvmInfo
		 * @duplicate
		 */
		actuator1JvmInfo5: (params: RequestParams = {}) =>
			this.request<void, any>({
				path: `/wms/actuator1/jvmInfo`,
				method: 'PATCH',
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 发货任务
		 * @name ApiSkuInventoryGetSkuInventoryByPage
		 * @summary getSkuInventoryPage
		 * @request POST:/wms/api/sku/inventory/getSkuInventoryByPage
		 */
		apiSkuInventoryGetSkuInventoryByPage: (
			reqDTO: SkuInventoryPageReqDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponsePageResultSkuInventoryRespDTO, any>({
				path: `/wms/api/sku/inventory/getSkuInventoryByPage`,
				method: 'POST',
				body: reqDTO,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 发货任务
		 * @name ApiSkuInventoryHasAvailableTransportationNums
		 * @summary hasAvailableTransportationNums
		 * @request POST:/wms/api/sku/inventory/hasAvailableTransportationNums
		 */
		apiSkuInventoryHasAvailableTransportationNums: (
			reqDTO: SkuInventoryPageReqDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponseBoolean, any>({
				path: `/wms/api/sku/inventory/hasAvailableTransportationNums`,
				method: 'POST',
				body: reqDTO,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 仓库PDA版本信息
		 * @name AppGetVersion
		 * @summary 获取仓库App版本信息
		 * @request POST:/wms/app/getVersion
		 */
		appGetVersion: (req: AppVersionReq, params: RequestParams = {}) =>
			this.request<BizResponseAppVersionResp, any>({
				path: `/wms/app/getVersion`,
				method: 'POST',
				body: req,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 后门
		 * @name BackBatchDeliveryTaskDeduction
		 * @summary 触发抵扣
		 * @request POST:/wms/back/batchDeliveryTaskDeduction
		 */
		backBatchDeliveryTaskDeduction: (
			dto: BatchDeliveryDeductionDto,
			params: RequestParams = {}
		) =>
			this.request<BizResponseObject, any>({
				path: `/wms/back/batchDeliveryTaskDeduction`,
				method: 'POST',
				body: dto,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 后门
		 * @name BackClearStock
		 * @summary 清除可用库存
		 * @request POST:/wms/back/clearStock
		 */
		backClearStock: (dto: ClearStockReqDto, params: RequestParams = {}) =>
			this.request<BizResponseClearStockRespDto, any>({
				path: `/wms/back/clearStock`,
				method: 'POST',
				body: dto,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 后门
		 * @name BackFixDeductionRelation
		 * @summary 修复库龄历史数据未记录抵扣关系数据
		 * @request GET:/wms/back/fixDeductionRelation
		 */
		backFixDeductionRelation: (params: RequestParams = {}) =>
			this.request<BizResponseObject, any>({
				path: `/wms/back/fixDeductionRelation`,
				method: 'GET',
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 后门
		 * @name BackInitDeliveryDedection
		 * @summary 初始化发货抵扣
		 * @request POST:/wms/back/initDeliveryDedection
		 */
		backInitDeliveryDedection: (
			dto: InitDeliveryDedectionDto,
			params: RequestParams = {}
		) =>
			this.request<BizResponseObject, any>({
				path: `/wms/back/initDeliveryDedection`,
				method: 'POST',
				body: dto,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags tool-controller
		 * @name BackToolAbnormalOrderSyncRetry
		 * @summary syncAbnormalOrder
		 * @request GET:/wms/back/tool/abnormalOrder/syncRetry/{deliveryReviewCode}
		 */
		backToolAbnormalOrderSyncRetry: (
			deliveryReviewCode: string,
			params: RequestParams = {}
		) =>
			this.request<void, any>({
				path: `/wms/back/tool/abnormalOrder/syncRetry/${deliveryReviewCode}`,
				method: 'GET',
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 分拣蓝
		 * @name BasketBatchAdd
		 * @summary 分拣蓝批量新增
		 * @request POST:/wms/basket/batchAdd
		 */
		basketBatchAdd: (
			dto: BatchAddBasketReqDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponseVoid, any>({
				path: `/wms/basket/batchAdd`,
				method: 'POST',
				body: dto,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 分拣蓝
		 * @name BasketEdit
		 * @summary 分拣蓝编辑
		 * @request POST:/wms/basket/edit
		 */
		basketEdit: (dto: BasketReqDTO, params: RequestParams = {}) =>
			this.request<BizResponseVoid, any>({
				path: `/wms/basket/edit`,
				method: 'POST',
				body: dto,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 分拣蓝
		 * @name BasketList
		 * @summary 分拣蓝列表--已启用状态
		 * @request GET:/wms/basket/list
		 */
		basketList: (params: RequestParams = {}) =>
			this.request<BizResponseListBasketRespDTO, any>({
				path: `/wms/basket/list`,
				method: 'GET',
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 分拣蓝
		 * @name BasketPage
		 * @summary 分拣蓝分页
		 * @request GET:/wms/basket/page
		 */
		basketPage: (
			query?: {
				/** 分拣蓝编码 */
				basketCode?: string;
				/** @format int32 */
				pageNum?: number;
				/** @format int32 */
				pageSize?: number;
				'sorts[0].asc'?: boolean;
				'sorts[0].field'?: string;
				/** @format int32 */
				startIndex?: number;
			},
			params: RequestParams = {}
		) =>
			this.request<BizResponsePageBasketRespDTO, any>({
				path: `/wms/basket/page`,
				method: 'GET',
				query: query,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 分拣蓝
		 * @name BasketUnusedNums
		 * @summary 空闲分拣蓝数
		 * @request GET:/wms/basket/unused/nums
		 */
		basketUnusedNums: (params: RequestParams = {}) =>
			this.request<BizResponseInt, any>({
				path: `/wms/basket/unused/nums`,
				method: 'GET',
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 箱子模板
		 * @name BoxAdd
		 * @summary 新增箱子模板
		 * @request POST:/wms/box/add
		 */
		boxAdd: (reqDTO: AddBoxTemplateReqDTO, params: RequestParams = {}) =>
			this.request<BizResponseObject, any>({
				path: `/wms/box/add`,
				method: 'POST',
				body: reqDTO,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 箱子模板
		 * @name BoxEdit
		 * @summary 编辑箱子模板
		 * @request POST:/wms/box/edit
		 */
		boxEdit: (reqDTO: EditBoxTemplateReqDTO, params: RequestParams = {}) =>
			this.request<BizResponseObject, any>({
				path: `/wms/box/edit`,
				method: 'POST',
				body: reqDTO,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 箱子模板
		 * @name BoxList
		 * @summary 箱子模板列表
		 * @request GET:/wms/box/list
		 */
		boxList: (params: RequestParams = {}) =>
			this.request<BizResponseListBoxTemplateRespDTO, any>({
				path: `/wms/box/list`,
				method: 'GET',
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 箱子模板
		 * @name BoxPage
		 * @summary 箱子模板分页
		 * @request GET:/wms/box/page
		 */
		boxPage: (
			query?: {
				/** @format int32 */
				pageNum?: number;
				/** @format int32 */
				pageSize?: number;
				'sorts[0].asc'?: boolean;
				'sorts[0].field'?: string;
				/** @format int32 */
				startIndex?: number;
			},
			params: RequestParams = {}
		) =>
			this.request<BizResponsePageBoxTemplateRespDTO, any>({
				path: `/wms/box/page`,
				method: 'GET',
				query: query,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 箱子模板
		 * @name BoxRemove
		 * @summary 删除箱子模板
		 * @request POST:/wms/box/remove
		 */
		boxRemove: (
			reqDTO: RemoveBoxTemplateReqDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponseObject, any>({
				path: `/wms/box/remove`,
				method: 'POST',
				body: reqDTO,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 容器相关
		 * @name ContainerClose
		 * @summary 容器关闭
		 * @request POST:/wms/container/close
		 */
		containerClose: (
			query: {
				/** containerCode */
				containerCode: string;
			},
			params: RequestParams = {}
		) =>
			this.request<BizResponseObject, any>({
				path: `/wms/container/close`,
				method: 'POST',
				query: query,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 容器相关
		 * @name ContainerCreate
		 * @summary 创建容器
		 * @request POST:/wms/container/create
		 */
		containerCreate: (
			request: ContainerCreateReq,
			params: RequestParams = {}
		) =>
			this.request<BizResponseObject, any>({
				path: `/wms/container/create`,
				method: 'POST',
				body: request,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 容器相关
		 * @name ContainerDetail
		 * @summary 容器详情
		 * @request POST:/wms/container/detail
		 */
		containerDetail: (
			query: {
				/** containerCode */
				containerCode: string;
			},
			params: RequestParams = {}
		) =>
			this.request<BizResponseObject, any>({
				path: `/wms/container/detail`,
				method: 'POST',
				query: query,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 容器相关
		 * @name ContainerGetChildContainers
		 * @summary 获取分拣子容器列表
		 * @request GET:/wms/container/getChildContainers
		 */
		containerGetChildContainers: (
			query: {
				/** containerCode */
				containerCode: string;
			},
			params: RequestParams = {}
		) =>
			this.request<BizResponseListContainerDetailResp, any>({
				path: `/wms/container/getChildContainers`,
				method: 'GET',
				query: query,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 容器相关
		 * @name ContainerOpen
		 * @summary 容器打开
		 * @request POST:/wms/container/open
		 */
		containerOpen: (
			query: {
				/** containerCode */
				containerCode: string;
			},
			params: RequestParams = {}
		) =>
			this.request<BizResponseObject, any>({
				path: `/wms/container/open`,
				method: 'POST',
				query: query,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 容器相关
		 * @name ContainerPage
		 * @summary 容器列表
		 * @request POST:/wms/container/page
		 */
		containerPage: (
			request: ContainerPageReq,
			params: RequestParams = {}
		) =>
			this.request<BizResponsePageResultContainerPageResp, any>({
				path: `/wms/container/page`,
				method: 'POST',
				body: request,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 容器相关
		 * @name ContainerProducts
		 * @summary 容器商品
		 * @request POST:/wms/container/products
		 */
		containerProducts: (
			request: ContainerProductPageReq,
			params: RequestParams = {}
		) =>
			this.request<BizResponseListContainerProductResp, any>({
				path: `/wms/container/products`,
				method: 'POST',
				body: request,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 容器配置
		 * @name ContainerConfigCreate
		 * @summary 创建容器配置
		 * @request GET:/wms/containerConfig/create
		 */
		containerConfigCreate: (
			query: {
				/** containerCode */
				containerCode: string;
			},
			params: RequestParams = {}
		) =>
			this.request<BizResponseObject, any>({
				path: `/wms/containerConfig/create`,
				method: 'GET',
				query: query,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 容器配置
		 * @name ContainerConfigPage
		 * @summary 分页容器配置
		 * @request GET:/wms/containerConfig/page
		 */
		containerConfigPage: (
			reqDTO: ContainerConfigReqDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponsePageContainerConfigDTO, any>({
				path: `/wms/containerConfig/page`,
				method: 'GET',
				body: reqDTO,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 容器配置
		 * @name ContainerConfigUpdateOperateType
		 * @summary 修改操作状态
		 * @request GET:/wms/containerConfig/updateOperateType
		 */
		containerConfigUpdateOperateType: (
			query: {
				/** containerCode */
				containerCode: string;
			},
			params: RequestParams = {}
		) =>
			this.request<BizResponseObject, any>({
				path: `/wms/containerConfig/updateOperateType`,
				method: 'GET',
				query: query,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 抵扣
		 * @name DeductionDeliverySkuDeductionRecord
		 * @summary 发货商品抵扣明细
		 * @request GET:/wms/deduction/deliverySkuDeductionRecord
		 */
		deductionDeliverySkuDeductionRecord: (
			query: {
				/**
				 * deliveryTaskDetailId
				 * @format int64
				 */
				deliveryTaskDetailId: number;
			},
			params: RequestParams = {}
		) =>
			this.request<BizResponseListDeliverySkuDeductionRecordDto, any>({
				path: `/wms/deduction/deliverySkuDeductionRecord`,
				method: 'GET',
				query: query,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 抵扣
		 * @name DeductionPkgDeductionPage
		 * @summary 批次包抵扣明细
		 * @request POST:/wms/deduction/pkgDeductionPage
		 */
		deductionPkgDeductionPage: (
			request: PkgDeductionPageReq,
			params: RequestParams = {}
		) =>
			this.request<BizResponsePageResultPkgDeductionPageResp, any>({
				path: `/wms/deduction/pkgDeductionPage`,
				method: 'POST',
				body: request,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 默认标签配置
		 * @name DefaultLabelConfigCreate
		 * @summary 创建默认标签配置
		 * @request POST:/wms/defaultLabelConfig/create
		 */
		defaultLabelConfigCreate: (
			reqDTO: DefaultLabelConfigReqDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponseBoolean, any>({
				path: `/wms/defaultLabelConfig/create`,
				method: 'POST',
				body: reqDTO,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 默认标签配置
		 * @name DefaultLabelConfigEditTemplate
		 * @summary 编辑模版
		 * @request POST:/wms/defaultLabelConfig/editTemplate
		 */
		defaultLabelConfigEditTemplate: (
			reqDTO: EditTemplateTemplateReqDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponseBoolean, any>({
				path: `/wms/defaultLabelConfig/editTemplate`,
				method: 'POST',
				body: reqDTO,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 默认标签配置
		 * @name DefaultLabelConfigGetDefaultLabelConfig
		 * @summary 获取默认标签配置
		 * @request GET:/wms/defaultLabelConfig/getDefaultLabelConfig
		 */
		defaultLabelConfigGetDefaultLabelConfig: (
			query: {
				/**
				 * 水洗标采购类型
				 * @format int32
				 */
				directSaleFlag?: number;
				/**
				 * 节点类型(1:简单检品打印 2:水洗标打印)
				 * @format int32
				 */
				nodeType: number;
				/** 站点 */
				stationCode?: string;
				/**
				 * 系统来源(1:D2C 2:B2B)
				 * @format int32
				 */
				systemSource: number;
			},
			params: RequestParams = {}
		) =>
			this.request<BizResponseDefaultLabelConfigVO, any>({
				path: `/wms/defaultLabelConfig/getDefaultLabelConfig`,
				method: 'GET',
				query: query,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 默认标签配置
		 * @name DefaultLabelConfigPage
		 * @summary 查询分页默认标签配置列表
		 * @request POST:/wms/defaultLabelConfig/page
		 */
		defaultLabelConfigPage: (
			reqDTO: PageDefaultLabelConfigReqDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponsePagePageDefaultLabelConfigRespDTO, any>({
				path: `/wms/defaultLabelConfig/page`,
				method: 'POST',
				body: reqDTO,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 发货-箱子
		 * @name DeliveryBoxBigBatchCopy
		 * @summary 大件批量复制
		 * @request POST:/wms/delivery/box/big/batchCopy
		 */
		deliveryBoxBigBatchCopy: (
			request: DeliveryBoxBatchCopyReqDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponseObject, any>({
				path: `/wms/delivery/box/big/batchCopy`,
				method: 'POST',
				body: request,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 发货-箱子
		 * @name DeliveryBoxCopyMaxAmount
		 * @summary 发货箱可复制最大数量
		 * @request POST:/wms/delivery/box/copy/maxAmount
		 */
		deliveryBoxCopyMaxAmount: (
			request: CopyBoxMaxAmountReqDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponseInt, any>({
				path: `/wms/delivery/box/copy/maxAmount`,
				method: 'POST',
				body: request,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 发货-箱子
		 * @name DeliveryBoxDelete
		 * @summary 删除发货-箱子
		 * @request POST:/wms/delivery/box/delete
		 */
		deliveryBoxDelete: (
			dto: BaseDeliveryBoxReqDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponseObject, any>({
				path: `/wms/delivery/box/delete`,
				method: 'POST',
				body: dto,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 发货-箱子
		 * @name DeliveryBoxEdit
		 * @summary 编辑发货-箱子
		 * @request POST:/wms/delivery/box/edit
		 */
		deliveryBoxEdit: (dto: DeliveryBoxReqDTO, params: RequestParams = {}) =>
			this.request<BizResponseObject, any>({
				path: `/wms/delivery/box/edit`,
				method: 'POST',
				body: dto,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 发货-箱子
		 * @name DeliveryBoxEditWeight
		 * @summary 修改重量
		 * @request POST:/wms/delivery/box/editWeight
		 */
		deliveryBoxEditWeight: (
			dto: DeliveryBoxEditWeightReqDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponseObject, any>({
				path: `/wms/delivery/box/editWeight`,
				method: 'POST',
				body: dto,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 发货-箱子
		 * @name DeliveryBoxFbaBatchCopy
		 * @summary FBA批量复制
		 * @request POST:/wms/delivery/box/fba/batchCopy
		 */
		deliveryBoxFbaBatchCopy: (
			request: DeliveryBoxBatchCopyReqDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponseObject, any>({
				path: `/wms/delivery/box/fba/batchCopy`,
				method: 'POST',
				body: request,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 发货-箱子
		 * @name DeliveryBoxProductEdit
		 * @summary 编辑发货-箱子-商品
		 * @request POST:/wms/delivery/box/product/edit
		 */
		deliveryBoxProductEdit: (
			dto: DeliveryBoxDetailReqDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponseDeliveryBoxProductSkuEditDTO, any>({
				path: `/wms/delivery/box/product/edit`,
				method: 'POST',
				body: dto,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 发货-箱子
		 * @name DeliveryBoxProductEditWithPkg
		 * @summary 编辑发货-箱子-商品
		 * @request POST:/wms/delivery/box/product/editWithPkg
		 */
		deliveryBoxProductEditWithPkg: (
			dto: DeliveryBoxPkgDetailReqDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponseDeliveryBoxProductSkuEditDTO, any>({
				path: `/wms/delivery/box/product/editWithPkg`,
				method: 'POST',
				body: dto,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 国内发货单
		 * @name DeliveryDomesticBatchPrintPick
		 * @summary getBatchPrintPickingInfo
		 * @request GET:/wms/delivery/domestic/batch/print/pick
		 */
		deliveryDomesticBatchPrintPick: (
			query: {
				/** deliveryTaskCodeList */
				deliveryTaskCodeList: string[];
			},
			params: RequestParams = {}
		) =>
			this.request<
				BizResponseListDomesticDeliveryPrintPickingRespDTO,
				any
			>({
				path: `/wms/delivery/domestic/batch/print/pick`,
				method: 'GET',
				query: query,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 国内发货单
		 * @name DeliveryDomesticBatchUpdate
		 * @summary batchUpdateLogisticsNo
		 * @request POST:/wms/delivery/domestic/batch/update
		 */
		deliveryDomesticBatchUpdate: (
			confirmDeliveryDTOList: ConfirmDeliveryDTO[],
			params: RequestParams = {}
		) =>
			this.request<BizResponseObject, any>({
				path: `/wms/delivery/domestic/batch/update`,
				method: 'POST',
				body: confirmDeliveryDTOList,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 国内发货单
		 * @name DeliveryDomesticCreate
		 * @summary insertDomesticDelivery
		 * @request POST:/wms/delivery/domestic/create
		 */
		deliveryDomesticCreate: (
			dto: AddDomesticDeliveryDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponseObject, any>({
				path: `/wms/delivery/domestic/create`,
				method: 'POST',
				body: dto,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 国内发货单
		 * @name DeliveryDomesticCreateTransfer
		 * @summary createTransfer
		 * @request POST:/wms/delivery/domestic/create/transfer
		 */
		deliveryDomesticCreateTransfer: (
			addTransferDTO: AddTransferDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponseObject, any>({
				path: `/wms/delivery/domestic/create/transfer`,
				method: 'POST',
				body: addTransferDTO,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 国内发货单
		 * @name DeliveryDomesticDiscard
		 * @summary 废弃退货单
		 * @request POST:/wms/delivery/domestic/discard
		 */
		deliveryDomesticDiscard: (
			reqDTO: DiscardDomesticReqDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponseObject, any>({
				path: `/wms/delivery/domestic/discard`,
				method: 'POST',
				body: reqDTO,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 国内发货单
		 * @name DeliveryDomesticExportReturnItem
		 * @summary 导出退货件
		 * @request POST:/wms/delivery/domestic/exportReturnItem
		 */
		deliveryDomesticExportReturnItem: (
			dto: GetDomesticDeliveryPageDTO,
			params: RequestParams = {}
		) =>
			this.request<void, any>({
				path: `/wms/delivery/domestic/exportReturnItem`,
				method: 'POST',
				body: dto,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 国内发货单
		 * @name DeliveryDomesticGetModifyAddressFlag
		 * @summary 查询处理中变更地址的记录
		 * @request POST:/wms/delivery/domestic/getModifyAddressFlag
		 */
		deliveryDomesticGetModifyAddressFlag: (
			dto: GetModifyAddressFlagReqDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponsePageDomesticModifyAddressRespDTO, any>({
				path: `/wms/delivery/domestic/getModifyAddressFlag`,
				method: 'POST',
				body: dto,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 国内发货单
		 * @name DeliveryDomesticImportTrackingNumber
		 * @summary 导入退货件物流单号
		 * @request POST:/wms/delivery/domestic/importTrackingNumber
		 */
		deliveryDomesticImportTrackingNumber: (
			file: any,
			params: RequestParams = {}
		) =>
			this.request<BizResponseObject, any>({
				path: `/wms/delivery/domestic/importTrackingNumber`,
				method: 'POST',
				body: file,
				type: ContentType.FormData,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 国内发货单
		 * @name DeliveryDomesticPage
		 * @summary getDomesticDeliveryPage
		 * @request POST:/wms/delivery/domestic/page
		 */
		deliveryDomesticPage: (
			dto: GetDomesticDeliveryPageDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponsePageDomesticDeliverDTO, any>({
				path: `/wms/delivery/domestic/page`,
				method: 'POST',
				body: dto,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 国内发货单
		 * @name DeliveryDomesticPrintPick
		 * @summary getPrintPickingInfo
		 * @request GET:/wms/delivery/domestic/print/pick
		 */
		deliveryDomesticPrintPick: (
			query?: {
				/** deliveryTaskCode */
				deliveryTaskCode?: string;
			},
			params: RequestParams = {}
		) =>
			this.request<BizResponseDomesticDeliveryPrintPickingRespDTO, any>({
				path: `/wms/delivery/domestic/print/pick`,
				method: 'GET',
				query: query,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 国内发货单
		 * @name DeliveryDomesticQueryRecipient
		 * @summary 查询相同收件人
		 * @request POST:/wms/delivery/domestic/queryRecipient
		 */
		deliveryDomesticQueryRecipient: (
			query: SameRecipientQuery,
			params: RequestParams = {}
		) =>
			this.request<BizResponseListString, any>({
				path: `/wms/delivery/domestic/queryRecipient`,
				method: 'POST',
				body: query,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 国内发货单
		 * @name DeliveryDomesticTransferConfirm
		 * @summary signAll
		 * @request POST:/wms/delivery/domestic/transfer/confirm
		 */
		deliveryDomesticTransferConfirm: (
			dto: SignAllTransferWarehouseReqDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponseObject, any>({
				path: `/wms/delivery/domestic/transfer/confirm`,
				method: 'POST',
				body: dto,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 国内发货单
		 * @name DeliveryDomesticTransferDelivery
		 * @summary createTransferDelivery
		 * @request POST:/wms/delivery/domestic/transfer/delivery
		 */
		deliveryDomesticTransferDelivery: (
			transferWarehouseDeliveryCreateDTO: TransferWarehouseDeliveryCreateDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponseObject, any>({
				path: `/wms/delivery/domestic/transfer/delivery`,
				method: 'POST',
				body: transferWarehouseDeliveryCreateDTO,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 国内发货单
		 * @name DeliveryDomesticTransferDeliveryPage
		 * @summary getTransferDeliveryPage
		 * @request POST:/wms/delivery/domestic/transfer/delivery/page
		 */
		deliveryDomesticTransferDeliveryPage: (
			dto: GetTransferDeliveryPageReqDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponsePageTransferDeliveryPageRespDTO, any>({
				path: `/wms/delivery/domestic/transfer/delivery/page`,
				method: 'POST',
				body: dto,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 国内发货单
		 * @name DeliveryDomesticTransferGetPkg
		 * @summary getTransferWarehousePkgInfo
		 * @request GET:/wms/delivery/domestic/transfer/get/pkg
		 */
		deliveryDomesticTransferGetPkg: (
			query: {
				/**
				 * customerShopId
				 * @format int64
				 */
				customerShopId: number;
				/** pkgCode */
				pkgCode: string;
			},
			params: RequestParams = {}
		) =>
			this.request<BizResponseTransferWarehousePkgRespDTO, any>({
				path: `/wms/delivery/domestic/transfer/get/pkg`,
				method: 'GET',
				query: query,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 国内发货单
		 * @name DeliveryDomesticTransferGetPkgByLogisticsNo
		 * @summary getTransferPkgByLogisticsNo
		 * @request GET:/wms/delivery/domestic/transfer/getPkgByLogisticsNo
		 */
		deliveryDomesticTransferGetPkgByLogisticsNo: (
			query?: {
				/** logisticsNo */
				logisticsNo?: string;
			},
			params: RequestParams = {}
		) =>
			this.request<BizResponseTransferWarehouseConfirmRespDTO, any>({
				path: `/wms/delivery/domestic/transfer/getPkgByLogisticsNo`,
				method: 'GET',
				query: query,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 国内发货单
		 * @name DeliveryDomesticTransferPage
		 * @summary getTransferWarehousePage
		 * @request POST:/wms/delivery/domestic/transfer/page
		 */
		deliveryDomesticTransferPage: (
			dto: GetTransferWarehousePageReqDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponsePageTransferWarehousePageRespDTO, any>({
				path: `/wms/delivery/domestic/transfer/page`,
				method: 'POST',
				body: dto,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 国内发货单
		 * @name DeliveryDomesticTransferPrint
		 * @summary getBatchPrintTransferWarehouse
		 * @request GET:/wms/delivery/domestic/transfer/print
		 */
		deliveryDomesticTransferPrint: (
			query: {
				/** transferWarehouseCode */
				transferWarehouseCode: string;
			},
			params: RequestParams = {}
		) =>
			this.request<BizResponseTransferWarehousePrintPickingRespDTO, any>({
				path: `/wms/delivery/domestic/transfer/print`,
				method: 'GET',
				query: query,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 国内发货单
		 * @name DeliveryDomesticUnDeliveryAblepage
		 * @summary unDeliveryAblepage
		 * @request POST:/wms/delivery/domestic/unDeliveryAblepage
		 */
		deliveryDomesticUnDeliveryAblepage: (
			dto: GetDomesticDeliveryPageDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponsePageDomesticDeliverDTO, any>({
				path: `/wms/delivery/domestic/unDeliveryAblepage`,
				method: 'POST',
				body: dto,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 国内发货单
		 * @name DeliveryDomesticUpdateExpressNo
		 * @summary updateExpressNo
		 * @request PUT:/wms/delivery/domestic/update/expressNo
		 */
		deliveryDomesticUpdateExpressNo: (
			confirmDeliveryDTO: ConfirmDeliveryDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponseConfirmDomesticDeliveryRespDTO, any>({
				path: `/wms/delivery/domestic/update/expressNo`,
				method: 'PUT',
				body: confirmDeliveryDTO,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 发货-拣货任务
		 * @name DeliveryPickAllotInfo
		 * @summary 通过批次包编码获取多品播种信息
		 * @request GET:/wms/delivery/pick/allot/info
		 */
		deliveryPickAllotInfo: (
			query: {
				/** 拣货任务编号 */
				pickTaskCode: string;
				/** 批次包编码 */
				pkgCode: string;
			},
			params: RequestParams = {}
		) =>
			this.request<BizResponseListDeliveryTaskAllotDetailRespDTO, any>({
				path: `/wms/delivery/pick/allot/info`,
				method: 'GET',
				query: query,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 拣货批次-分拣接口
		 * @name DeliveryPickBatchAllotAllotContainerBind
		 * @summary 拣货批次-分拣-扫描分拣容器绑定
		 * @request POST:/wms/delivery/pick/batch/allot/allotContainer/bind
		 */
		deliveryPickBatchAllotAllotContainerBind: (
			req: DeliveryPickAllotBindAllotContainerReq,
			params: RequestParams = {}
		) =>
			this.request<BizResponseAllotContainerBindResp, any>({
				path: `/wms/delivery/pick/batch/allot/allotContainer/bind`,
				method: 'POST',
				body: req,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 拣货批次-分拣接口
		 * @name DeliveryPickBatchAllotAllotContainerUnbind
		 * @summary 拣货批次-分拣-扫描分拣容器解绑
		 * @request POST:/wms/delivery/pick/batch/allot/allotContainer/unbind
		 */
		deliveryPickBatchAllotAllotContainerUnbind: (
			req: DeliveryPickAllotUnBindAllotContainerReq,
			params: RequestParams = {}
		) =>
			this.request<BizResponseObject, any>({
				path: `/wms/delivery/pick/batch/allot/allotContainer/unbind`,
				method: 'POST',
				body: req,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 拣货批次-分拣接口
		 * @name DeliveryPickBatchAllotAllotTaskCurrentCheck
		 * @summary 拣货批次-分拣-当前任务校验
		 * @request POST:/wms/delivery/pick/batch/allot/allotTask/currentCheck
		 */
		deliveryPickBatchAllotAllotTaskCurrentCheck: (
			req: DeliveryPickAllotTaskCheckReq,
			params: RequestParams = {}
		) =>
			this.request<BizResponseDeliveryPickAllotTaskCheckResp, any>({
				path: `/wms/delivery/pick/batch/allot/allotTask/currentCheck`,
				method: 'POST',
				body: req,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 拣货批次-分拣接口
		 * @name DeliveryPickBatchAllotAllotTaskStart
		 * @summary 拣货批次-分拣-开始分拣
		 * @request POST:/wms/delivery/pick/batch/allot/allotTask/start
		 */
		deliveryPickBatchAllotAllotTaskStart: (
			req: DeliveryPickAllotTaskStartReq,
			params: RequestParams = {}
		) =>
			this.request<BizResponseAllotContainerBindResp, any>({
				path: `/wms/delivery/pick/batch/allot/allotTask/start`,
				method: 'POST',
				body: req,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 拣货批次-分拣接口
		 * @name DeliveryPickBatchAllotPickContainerBind
		 * @summary 拣货批次-分拣-扫描拣货容器绑定
		 * @request POST:/wms/delivery/pick/batch/allot/pickContainer/bind
		 */
		deliveryPickBatchAllotPickContainerBind: (
			req: DeliveryPickAllotBindPickContainerReq,
			params: RequestParams = {}
		) =>
			this.request<BizResponseObject, any>({
				path: `/wms/delivery/pick/batch/allot/pickContainer/bind`,
				method: 'POST',
				body: req,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 拣货批次-分拣接口
		 * @name DeliveryPickBatchAllotPickContainerUnbind
		 * @summary 拣货批次-分拣-扫描拣货容器解绑
		 * @request POST:/wms/delivery/pick/batch/allot/pickContainer/unbind
		 */
		deliveryPickBatchAllotPickContainerUnbind: (
			req: DeliveryPickAllotUnBindPickContainerReq,
			params: RequestParams = {}
		) =>
			this.request<BizResponseObject, any>({
				path: `/wms/delivery/pick/batch/allot/pickContainer/unbind`,
				method: 'POST',
				body: req,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 拣货批次-拣货接口
		 * @name DeliveryPickBatchPage
		 * @summary 拣货批次-列表
		 * @request POST:/wms/delivery/pick/batch/page
		 */
		deliveryPickBatchPage: (
			request: DeliveryPickBatchPageReq,
			params: RequestParams = {}
		) =>
			this.request<BizResponsePageResultDeliveryPickBatchPageResp, any>({
				path: `/wms/delivery/pick/batch/page`,
				method: 'POST',
				body: request,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 拣货批次-拣货接口
		 * @name DeliveryPickBatchPickBatchTaskStart
		 * @summary 拣货批次-开始拣货
		 * @request POST:/wms/delivery/pick/batch/pickBatchTask/start
		 */
		deliveryPickBatchPickBatchTaskStart: (
			req: PickBatchTaskStartReq,
			params: RequestParams = {}
		) =>
			this.request<BizResponsePickBatchTaskStartResp, any>({
				path: `/wms/delivery/pick/batch/pickBatchTask/start`,
				method: 'POST',
				body: req,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 拣货批次-拣货接口
		 * @name DeliveryPickBatchPickContainerScanBind
		 * @summary 拣货批次-扫描绑定拣货容器
		 * @request POST:/wms/delivery/pick/batch/pickContainer/scanBind
		 */
		deliveryPickBatchPickContainerScanBind: (
			req: PickContainerBindReq,
			params: RequestParams = {}
		) =>
			this.request<BizResponseObject, any>({
				path: `/wms/delivery/pick/batch/pickContainer/scanBind`,
				method: 'POST',
				body: req,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 拣货批次-拣货接口
		 * @name DeliveryPickBatchPickContainerUnBind
		 * @summary 拣货批次-解绑拣货容器
		 * @request POST:/wms/delivery/pick/batch/pickContainer/unBind
		 */
		deliveryPickBatchPickContainerUnBind: (
			req: PickContainerUnBindReq,
			params: RequestParams = {}
		) =>
			this.request<BizResponseObject, any>({
				path: `/wms/delivery/pick/batch/pickContainer/unBind`,
				method: 'POST',
				body: req,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 拣货批次-拣货接口
		 * @name DeliveryPickBatchPkgGetByBatchCode
		 * @summary 拣货批次-批次包详情
		 * @request POST:/wms/delivery/pick/batch/pkg/getByBatchCode
		 */
		deliveryPickBatchPkgGetByBatchCode: (
			request: DeliveryPickBatchPkgPageReq,
			params: RequestParams = {}
		) =>
			this.request<
				BizResponsePageResultDeliveryPickBatchPkgPageResp,
				any
			>({
				path: `/wms/delivery/pick/batch/pkg/getByBatchCode`,
				method: 'POST',
				body: request,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 发货-拣货任务
		 * @name DeliveryPickConfirmAllot
		 * @summary 确认分拣
		 * @request POST:/wms/delivery/pick/confirmAllot
		 */
		deliveryPickConfirmAllot: (
			reqDTO: ConfirmAllotReqDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponseObject, any>({
				path: `/wms/delivery/pick/confirmAllot`,
				method: 'POST',
				body: reqDTO,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 发货-拣货任务
		 * @name DeliveryPickExceptionTransfer
		 * @summary 拣货异常转移
		 * @request POST:/wms/delivery/pick/exception/transfer
		 */
		deliveryPickExceptionTransfer: (
			request: DeliveryPickTaskExceptionCreateReq,
			params: RequestParams = {}
		) =>
			this.request<BizResponseObject, any>({
				path: `/wms/delivery/pick/exception/transfer`,
				method: 'POST',
				body: request,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 发货-拣货任务
		 * @name DeliveryPickGetAllotPkgUnPickNums
		 * @summary 获取批次包可分拣数
		 * @request POST:/wms/delivery/pick/getAllotPkgUnPickNums
		 */
		deliveryPickGetAllotPkgUnPickNums: (
			reqDTO: GetAllotPkgUnPickReqDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponseGetAllotPkgUnPickRespDTO, any>({
				path: `/wms/delivery/pick/getAllotPkgUnPickNums`,
				method: 'POST',
				body: reqDTO,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 发货-拣货任务
		 * @name DeliveryPickGetD2CAllotProgress
		 * @summary 查询配货进度
		 * @request POST:/wms/delivery/pick/getD2cAllotProgress
		 */
		deliveryPickGetD2CAllotProgress: (
			reqDTO: GetD2CAllotProgressReqDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponseD2CAllotProgressRespDTO, any>({
				path: `/wms/delivery/pick/getD2cAllotProgress`,
				method: 'POST',
				body: reqDTO,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 发货-拣货任务
		 * @name DeliveryPickGetPkgPrePickInfo
		 * @summary 获取批次包预分拣详情
		 * @request POST:/wms/delivery/pick/getPkgPrePickInfo
		 */
		deliveryPickGetPkgPrePickInfo: (
			reqDTO: GetAllotPkgPrePickReqDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponseListGetAllotPkgPrePickRespDTO, any>({
				path: `/wms/delivery/pick/getPkgPrePickInfo`,
				method: 'POST',
				body: reqDTO,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 发货-拣货任务
		 * @name DeliveryPickPagePickingTaskNotListed
		 * @summary 拣货任务未上架商品分页
		 * @request POST:/wms/delivery/pick/pagePickingTaskNotListed
		 */
		deliveryPickPagePickingTaskNotListed: (
			reqDTO: PickingTaskNotListedReqDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponsePagePickingTaskNotListedRespDTO, any>({
				path: `/wms/delivery/pick/pagePickingTaskNotListed`,
				method: 'POST',
				body: reqDTO,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 发货-拣货任务
		 * @name DeliveryPickSingleProductAllot
		 * @summary 单件商品配货
		 * @request POST:/wms/delivery/pick/singleProductAllot
		 */
		deliveryPickSingleProductAllot: (
			reqDTO: D2CSingleProductAllotReqDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponseD2CSingleProductAllotRespDTO, any>({
				path: `/wms/delivery/pick/singleProductAllot`,
				method: 'POST',
				body: reqDTO,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 发货-拣货任务
		 * @name DeliveryPickTaskAllotFinish
		 * @summary 配货完成
		 * @request POST:/wms/delivery/pick/task/allot/finish
		 */
		deliveryPickTaskAllotFinish: (
			dto: DeliveryPickTaskDetailReqDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponseObject, any>({
				path: `/wms/delivery/pick/task/allot/finish`,
				method: 'POST',
				body: dto,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 发货-拣货任务
		 * @name DeliveryPickTaskCreate
		 * @summary 创建拣货任务
		 * @request POST:/wms/delivery/pick/task/create
		 */
		deliveryPickTaskCreate: (
			dto: DeliveryPickTaskCreateDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponseObject, any>({
				path: `/wms/delivery/pick/task/create`,
				method: 'POST',
				body: dto,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 发货-拣货任务
		 * @name DeliveryPickTaskD2CList
		 * @summary 拣货任务详情信息-d2c拣货
		 * @request GET:/wms/delivery/pick/task/d2c/list
		 */
		deliveryPickTaskD2CList: (
			query: {
				/** 拣货任务编号 */
				batchCode: string;
			},
			params: RequestParams = {}
		) =>
			this.request<BizResponseListDeliveryPickTaskDetailRespDTO, any>({
				path: `/wms/delivery/pick/task/d2c/list`,
				method: 'GET',
				query: query,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 发货-拣货任务
		 * @name DeliveryPickTaskDetailList
		 * @summary 拣货任务详情信息
		 * @request GET:/wms/delivery/pick/task/detail/list
		 */
		deliveryPickTaskDetailList: (
			query?: {
				/** 拣货任务编号 */
				pickTaskCode?: string;
			},
			params: RequestParams = {}
		) =>
			this.request<BizResponseListDeliveryPickTaskDetailRespDTO, any>({
				path: `/wms/delivery/pick/task/detail/list`,
				method: 'GET',
				query: query,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 发货-拣货任务
		 * @name DeliveryPickTaskDetailPicked
		 * @summary 更新拣货任务详情-已拣
		 * @request POST:/wms/delivery/pick/task/detail/picked
		 */
		deliveryPickTaskDetailPicked: (
			dto: DeliveryPickTaskDetailReqDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponseListDeliveryPickTaskDetailRespDTO, any>({
				path: `/wms/delivery/pick/task/detail/picked`,
				method: 'POST',
				body: dto,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 发货-拣货任务
		 * @name DeliveryPickTaskNewPickFinish
		 * @summary 按照拣货批次-拣货完成
		 * @request POST:/wms/delivery/pick/task/new/pick/finish
		 */
		deliveryPickTaskNewPickFinish: (
			req: DeliveryPickTaskPickFinishReq,
			params: RequestParams = {}
		) =>
			this.request<BizResponseObject, any>({
				path: `/wms/delivery/pick/task/new/pick/finish`,
				method: 'POST',
				body: req,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 发货-拣货任务
		 * @name DeliveryPickTaskPage
		 * @summary 拣货任务分页
		 * @request GET:/wms/delivery/pick/task/page
		 */
		deliveryPickTaskPage: (
			query?: {
				/** @format int32 */
				pageNum?: number;
				/** @format int32 */
				pageSize?: number;
				/** 拣货任务编号 */
				pickTaskCode?: string;
				/** 拣货类型(0:大件拣货 1:小件单品 2:小件多品 3:FBA拣货 4:次转正 5:国内拣货) */
				pickTypeList?: number[];
				'sorts[0].asc'?: boolean;
				'sorts[0].field'?: string;
				/** @format int32 */
				startIndex?: number;
			},
			params: RequestParams = {}
		) =>
			this.request<BizResponsePageDeliveryPickTaskRespDTO, any>({
				path: `/wms/delivery/pick/task/page`,
				method: 'GET',
				query: query,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 发货-拣货任务
		 * @name DeliveryPickTaskPickFinish
		 * @summary 拣货完成
		 * @request POST:/wms/delivery/pick/task/pick/finish
		 */
		deliveryPickTaskPickFinish: (
			dto: DeliveryPickTaskDetailReqDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponseObject, any>({
				path: `/wms/delivery/pick/task/pick/finish`,
				method: 'POST',
				body: dto,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 拆单
		 * @name DeliverySplitOrderAddSku
		 * @summary 添加商品到包裹
		 * @request POST:/wms/delivery/splitOrder/addSku
		 */
		deliverySplitOrderAddSku: (
			dto: DeliveryPackageAddSkuReqDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponseObject, any>({
				path: `/wms/delivery/splitOrder/addSku`,
				method: 'POST',
				body: dto,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 拆单
		 * @name DeliverySplitOrderConfirmSplit
		 * @summary 确认拆包
		 * @request POST:/wms/delivery/splitOrder/confirmSplit
		 */
		deliverySplitOrderConfirmSplit: (
			dto: ConfirmSplitPackageReqDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponseObject, any>({
				path: `/wms/delivery/splitOrder/confirmSplit`,
				method: 'POST',
				body: dto,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 拆单
		 * @name DeliverySplitOrderCreatePackage
		 * @summary 创建包裹
		 * @request POST:/wms/delivery/splitOrder/createPackage
		 */
		deliverySplitOrderCreatePackage: (
			dto: CreateDeliveryPackageReqDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponseObject, any>({
				path: `/wms/delivery/splitOrder/createPackage`,
				method: 'POST',
				body: dto,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 拆单
		 * @name DeliverySplitOrderEditWeight
		 * @summary 更新重量
		 * @request POST:/wms/delivery/splitOrder/editWeight
		 */
		deliverySplitOrderEditWeight: (
			dto: DeliveryPackageEditWeightReqDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponseObject, any>({
				path: `/wms/delivery/splitOrder/editWeight`,
				method: 'POST',
				body: dto,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 拆单
		 * @name DeliverySplitOrderGetLogisticsDetail
		 * @summary 查询子发货单物流明细
		 * @request POST:/wms/delivery/splitOrder/getLogisticsDetail
		 */
		deliverySplitOrderGetLogisticsDetail: (
			dto: SplitOrderLogisticsReqDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponseListSplitPackDeliveryTaskRespDTO, any>({
				path: `/wms/delivery/splitOrder/getLogisticsDetail`,
				method: 'POST',
				body: dto,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 拆单
		 * @name DeliverySplitOrderGetUnpackSku
		 * @summary 查询剩余未打包明细
		 * @request POST:/wms/delivery/splitOrder/getUnpackSku
		 */
		deliverySplitOrderGetUnpackSku: (
			dto: GetUnpackSkuReqDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponseListUnpackSkuRespDTO, any>({
				path: `/wms/delivery/splitOrder/getUnpackSku`,
				method: 'POST',
				body: dto,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 拆单
		 * @name DeliverySplitOrderRemovePackage
		 * @summary 删除包裹
		 * @request POST:/wms/delivery/splitOrder/removePackage
		 */
		deliverySplitOrderRemovePackage: (
			dto: RemoveDeliveryPackageReqDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponseObject, any>({
				path: `/wms/delivery/splitOrder/removePackage`,
				method: 'POST',
				body: dto,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 拆单
		 * @name DeliverySplitOrderRemoveSku
		 * @summary 移除商品
		 * @request POST:/wms/delivery/splitOrder/removeSku
		 */
		deliverySplitOrderRemoveSku: (
			dto: DeliveryPackageRemoveSkuReqDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponseObject, any>({
				path: `/wms/delivery/splitOrder/removeSku`,
				method: 'POST',
				body: dto,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 拆单
		 * @name DeliverySplitOrderSelectPackage
		 * @summary 查询包裹
		 * @request POST:/wms/delivery/splitOrder/selectPackage
		 */
		deliverySplitOrderSelectPackage: (
			dto: SelectPackageReqDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponseListDeliveryPackageRespDTO, any>({
				path: `/wms/delivery/splitOrder/selectPackage`,
				method: 'POST',
				body: dto,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 发货任务
		 * @name DeliveryTaskAddSendAddress
		 * @summary addSendAddress
		 * @request POST:/wms/delivery/task/addSendAddress
		 */
		deliveryTaskAddSendAddress: (
			dtoList: OrderSendAddressCreateDTO[],
			params: RequestParams = {}
		) =>
			this.request<BizResponseObject, any>({
				path: `/wms/delivery/task/addSendAddress`,
				method: 'POST',
				body: dtoList,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 发货任务
		 * @name DeliveryTaskAfterSales
		 * @summary 申请售后
		 * @request POST:/wms/delivery/task/afterSales
		 */
		deliveryTaskAfterSales: (
			reqDTO: DeliveryAfterSalesReqDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponseObject, any>({
				path: `/wms/delivery/task/afterSales`,
				method: 'POST',
				body: reqDTO,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 发货任务
		 * @name DeliveryTaskApiArrearageDeliveryTask
		 * @summary getArrearageDeliveryTaskAmount
		 * @request GET:/wms/delivery/task/api/arrearage/deliveryTask
		 */
		deliveryTaskApiArrearageDeliveryTask: (
			query: {
				/**
				 * customerShopId
				 * @format int64
				 */
				customerShopId: number;
			},
			params: RequestParams = {}
		) =>
			this.request<BizResponseObject, any>({
				path: `/wms/delivery/task/api/arrearage/deliveryTask`,
				method: 'GET',
				query: query,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 发货任务
		 * @name DeliveryTaskApiCancelByDetail
		 * @summary cancelByDetails
		 * @request POST:/wms/delivery/task/api/cancel/byDetail
		 */
		deliveryTaskApiCancelByDetail: (
			request: DeliverTaskCancelReq,
			params: RequestParams = {}
		) =>
			this.request<BizResponseObject, any>({
				path: `/wms/delivery/task/api/cancel/byDetail`,
				method: 'POST',
				body: request,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 发货任务
		 * @name DeliveryTaskApiCancelDeliveryTask
		 * @summary cancelDeliveryTask
		 * @request POST:/wms/delivery/task/api/cancelDeliveryTask
		 */
		deliveryTaskApiCancelDeliveryTask: (
			dto: BaseDeliverTaskApiReqDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponseObject, any>({
				path: `/wms/delivery/task/api/cancelDeliveryTask`,
				method: 'POST',
				body: dto,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 发货任务
		 * @name DeliveryTaskApiConfirm
		 * @summary confirmDeliveryTask
		 * @request POST:/wms/delivery/task/api/confirm
		 */
		deliveryTaskApiConfirm: (
			deliverTaskConfirmReq: DeliverTaskConfirmReq,
			params: RequestParams = {}
		) =>
			this.request<BizResponseObject, any>({
				path: `/wms/delivery/task/api/confirm`,
				method: 'POST',
				body: deliverTaskConfirmReq,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 发货任务
		 * @name DeliveryTaskAsyncExport
		 * @summary 异步导出发货单
		 * @request POST:/wms/delivery/task/asyncExport
		 */
		deliveryTaskAsyncExport: (
			dto: DeliverTaskReqDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponseObject, any>({
				path: `/wms/delivery/task/asyncExport`,
				method: 'POST',
				body: dto,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 发货任务
		 * @name DeliveryTaskBackPushDeliverToPlatform
		 * @summary 推送已发货数据到平台后门
		 * @request GET:/wms/delivery/task/back/pushDeliverToPlatform
		 */
		deliveryTaskBackPushDeliverToPlatform: (
			query: {
				/** 发货单号 */
				deliveryTaskCode: string;
				/** startTime */
				startTime?: string;
			},
			params: RequestParams = {}
		) =>
			this.request<BizResponseObject, any>({
				path: `/wms/delivery/task/back/pushDeliverToPlatform`,
				method: 'GET',
				query: query,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 发货任务
		 * @name DeliveryTaskBatchDeliveryCensus
		 * @summary batchDeliveryCensus
		 * @request POST:/wms/delivery/task/batchDeliveryCensus
		 */
		deliveryTaskBatchDeliveryCensus: (
			customerShopIdList: number[],
			params: RequestParams = {}
		) =>
			this.request<BizResponseListDeliveryCensusDTO, any>({
				path: `/wms/delivery/task/batchDeliveryCensus`,
				method: 'POST',
				body: customerShopIdList,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 发货任务
		 * @name DeliveryTaskBatchEditLogisticsNo
		 * @summary 批量更新物流单号
		 * @request POST:/wms/delivery/task/batchEditLogisticsNo
		 */
		deliveryTaskBatchEditLogisticsNo: (
			dto: BatchEditLogisticsNoReqDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponseObject, any>({
				path: `/wms/delivery/task/batchEditLogisticsNo`,
				method: 'POST',
				body: dto,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 发货任务
		 * @name DeliveryTaskCancelDelivery
		 * @summary 取消发货
		 * @request POST:/wms/delivery/task/cancelDelivery
		 */
		deliveryTaskCancelDelivery: (
			dto: BaseDeliverTaskReqDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponseObject, any>({
				path: `/wms/delivery/task/cancelDelivery`,
				method: 'POST',
				body: dto,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 发货任务
		 * @name DeliveryTaskConfirmBatchDelivery
		 * @summary 批量确认发货
		 * @request POST:/wms/delivery/task/confirm/batch/delivery
		 */
		deliveryTaskConfirmBatchDelivery: (
			dto: BaseBatchDeliverTaskReqDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponseObject, any>({
				path: `/wms/delivery/task/confirm/batch/delivery`,
				method: 'POST',
				body: dto,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 发货任务
		 * @name DeliveryTaskConfirmDelivery
		 * @summary 确认发货
		 * @request POST:/wms/delivery/task/confirm/delivery
		 */
		deliveryTaskConfirmDelivery: (
			dto: BaseDeliverTaskReqDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponseObject, any>({
				path: `/wms/delivery/task/confirm/delivery`,
				method: 'POST',
				body: dto,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 发货任务
		 * @name DeliveryTaskConfirmPay
		 * @summary 确认发货-支付
		 * @request GET:/wms/delivery/task/confirm/pay
		 */
		deliveryTaskConfirmPay: (
			query: {
				/** 发货任务编号 */
				deliveryTaskCode: string;
			},
			params: RequestParams = {}
		) =>
			this.request<BizResponseObject, any>({
				path: `/wms/delivery/task/confirm/pay`,
				method: 'GET',
				query: query,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 发货任务
		 * @name DeliveryTaskConfirmPayNotify
		 * @summary 确认发货-支付结果通知
		 * @request POST:/wms/delivery/task/confirm/payNotify
		 */
		deliveryTaskConfirmPayNotify: (
			dto: WalletChangeResultEventDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponseObject, any>({
				path: `/wms/delivery/task/confirm/payNotify`,
				method: 'POST',
				body: dto,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 发货任务
		 * @name DeliveryTaskConfirmReview
		 * @summary 确认审单
		 * @request POST:/wms/delivery/task/confirmReview
		 */
		deliveryTaskConfirmReview: (
			dto: BaseDeliverTaskReqDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponseObject, any>({
				path: `/wms/delivery/task/confirmReview`,
				method: 'POST',
				body: dto,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 发货任务
		 * @name DeliveryTaskCountryList
		 * @summary 获取收货国家列表
		 * @request POST:/wms/delivery/task/country/list
		 */
		deliveryTaskCountryList: (params: RequestParams = {}) =>
			this.request<BizResponseListCountryInfoRespDTO, any>({
				path: `/wms/delivery/task/country/list`,
				method: 'POST',
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 发货任务
		 * @name DeliveryTaskCreate
		 * @summary createTask
		 * @request POST:/wms/delivery/task/create
		 */
		deliveryTaskCreate: (
			dto: DeliverTaskCreateDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponseString, any>({
				path: `/wms/delivery/task/create`,
				method: 'POST',
				body: dto,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 发货任务
		 * @name DeliveryTaskCustomerDownloadPackingList
		 * @summary 下载装箱清单--业务前台
		 * @request POST:/wms/delivery/task/customerDownloadPackingList
		 */
		deliveryTaskCustomerDownloadPackingList: (
			dto: DownloadPackingListReqDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponseObject, any>({
				path: `/wms/delivery/task/customerDownloadPackingList`,
				method: 'POST',
				body: dto,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 发货任务
		 * @name DeliveryTaskDeliveryCensus
		 * @summary deliveryCensus
		 * @request GET:/wms/delivery/task/deliveryCensus
		 */
		deliveryTaskDeliveryCensus: (
			query: {
				/**
				 * customerShopId
				 * @format int64
				 */
				customerShopId: number;
			},
			params: RequestParams = {}
		) =>
			this.request<BizResponseDeliveryCensusDTO, any>({
				path: `/wms/delivery/task/deliveryCensus`,
				method: 'GET',
				query: query,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 发货任务
		 * @name DeliveryTaskDownloadCartonInfoExcel
		 * @summary FBA-下载箱子尺寸规格excel
		 * @request POST:/wms/delivery/task/download/carton/info/excel
		 */
		deliveryTaskDownloadCartonInfoExcel: (
			dto: BaseDeliverTaskReqDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponseObject, any>({
				path: `/wms/delivery/task/download/carton/info/excel`,
				method: 'POST',
				body: dto,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 发货任务
		 * @name DeliveryTaskDownloadCustomerBoxMarkFile
		 * @summary 下载箱唛--客户上传的文件
		 * @request POST:/wms/delivery/task/downloadCustomerBoxMarkFile
		 */
		deliveryTaskDownloadCustomerBoxMarkFile: (
			dto: BaseDeliverTaskReqDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponseString, any>({
				path: `/wms/delivery/task/downloadCustomerBoxMarkFile`,
				method: 'POST',
				body: dto,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 发货任务
		 * @name DeliveryTaskDownloadCustomerPackingListFile
		 * @summary 下载装箱清单--客户上传的文件
		 * @request POST:/wms/delivery/task/downloadCustomerPackingListFile
		 */
		deliveryTaskDownloadCustomerPackingListFile: (
			dto: BaseDeliverTaskReqDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponseString, any>({
				path: `/wms/delivery/task/downloadCustomerPackingListFile`,
				method: 'POST',
				body: dto,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 发货任务
		 * @name DeliveryTaskDownloadPackingDetailsFile
		 * @summary 下载纳品书
		 * @request POST:/wms/delivery/task/downloadPackingDetailsFile
		 */
		deliveryTaskDownloadPackingDetailsFile: (
			request: BaseDeliverTaskRequest,
			params: RequestParams = {}
		) =>
			this.request<BizResponseObject, any>({
				path: `/wms/delivery/task/downloadPackingDetailsFile`,
				method: 'POST',
				body: request,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 发货任务
		 * @name DeliveryTaskEditAdditionalDetail
		 * @summary 修改发货附加项
		 * @request POST:/wms/delivery/task/edit/additionalDetail
		 */
		deliveryTaskEditAdditionalDetail: (
			dto: EditDeliverTaskAdditionalDetailReqDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponseObject, any>({
				path: `/wms/delivery/task/edit/additionalDetail`,
				method: 'POST',
				body: dto,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 发货任务
		 * @name DeliveryTaskEditInvoicRemark
		 * @summary 修改单证备注
		 * @request POST:/wms/delivery/task/edit/invoicRemark
		 */
		deliveryTaskEditInvoicRemark: (
			reqDTO: EditInvoicRemarkReqDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponseObject, any>({
				path: `/wms/delivery/task/edit/invoicRemark`,
				method: 'POST',
				body: reqDTO,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 发货任务
		 * @name DeliveryTaskEditManagerRemark
		 * @summary 编辑担当给仓库的备注
		 * @request POST:/wms/delivery/task/edit/managerRemark
		 */
		deliveryTaskEditManagerRemark: (
			reqDTO: EditManagerRemarkReqDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponseObject, any>({
				path: `/wms/delivery/task/edit/managerRemark`,
				method: 'POST',
				body: reqDTO,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 发货任务
		 * @name DeliveryTaskEditBarCode
		 * @summary 修改sku条形码文件
		 * @request POST:/wms/delivery/task/editBarCode
		 */
		deliveryTaskEditBarCode: (
			dto: EditBarCodeReqDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponseObject, any>({
				path: `/wms/delivery/task/editBarCode`,
				method: 'POST',
				body: dto,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 发货任务
		 * @name DeliveryTaskEnableUpdateAddr
		 * @summary 是否可修改收货地址
		 * @request GET:/wms/delivery/task/enableUpdateAddr
		 */
		deliveryTaskEnableUpdateAddr: (
			query: {
				/** 平台订单号 */
				platformOrderNo: string;
			},
			params: RequestParams = {}
		) =>
			this.request<BizResponseBoolean, any>({
				path: `/wms/delivery/task/enableUpdateAddr`,
				method: 'GET',
				query: query,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 发货任务
		 * @name DeliveryTaskGetBoxMarkLink
		 * @summary FBA-同步箱唛并下载
		 * @request POST:/wms/delivery/task/get/boxMarkLink
		 */
		deliveryTaskGetBoxMarkLink: (
			dto: BaseDeliverTaskReqDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponseString, any>({
				path: `/wms/delivery/task/get/boxMarkLink`,
				method: 'POST',
				body: dto,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 发货任务
		 * @name DeliveryTaskGetAdditionalDetails
		 * @summary 获取国际发货单任务辅料信息
		 * @request POST:/wms/delivery/task/getAdditionalDetails
		 */
		deliveryTaskGetAdditionalDetails: (
			request: DeliveryTaskAdditionalReqDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponseObject, any>({
				path: `/wms/delivery/task/getAdditionalDetails`,
				method: 'POST',
				body: request,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 发货任务
		 * @name DeliveryTaskGetAgainLogisticsNo
		 * @summary 一键重新获取物流单号
		 * @request POST:/wms/delivery/task/getAgainLogisticsNo
		 */
		deliveryTaskGetAgainLogisticsNo: (
			dto: BaseDeliverTaskReqDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponseObject, any>({
				path: `/wms/delivery/task/getAgainLogisticsNo`,
				method: 'POST',
				body: dto,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 发货任务
		 * @name DeliveryTaskGetB2BSpotCheckNums
		 * @summary B2B查询抽检数
		 * @request POST:/wms/delivery/task/getB2bSpotCheckNums
		 */
		deliveryTaskGetB2BSpotCheckNums: (
			deliveryTaskCodeList: string[],
			params: RequestParams = {}
		) =>
			this.request<BizResponseListB2BDeliverySpotCheckNumsDTO, any>({
				path: `/wms/delivery/task/getB2bSpotCheckNums`,
				method: 'POST',
				body: deliveryTaskCodeList,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 发货任务
		 * @name DeliveryTaskGetBoxInfo
		 * @summary 查询装箱明细
		 * @request GET:/wms/delivery/task/getBoxInfo
		 */
		deliveryTaskGetBoxInfo: (
			query: {
				/** 发货单号 */
				deliveryTaskCode: string;
			},
			params: RequestParams = {}
		) =>
			this.request<BizResponseListDeliveryBoxRespDTO, any>({
				path: `/wms/delivery/task/getBoxInfo`,
				method: 'GET',
				query: query,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 发货任务
		 * @name DeliveryTaskGetBoxMarkFile
		 * @summary 查询箱唛文件
		 * @request GET:/wms/delivery/task/getBoxMarkFile
		 */
		deliveryTaskGetBoxMarkFile: (
			query: {
				/** 发货单号 */
				deliveryTaskCode: string;
			},
			params: RequestParams = {}
		) =>
			this.request<BizResponseListString, any>({
				path: `/wms/delivery/task/getBoxMarkFile`,
				method: 'GET',
				query: query,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 发货任务
		 * @name DeliveryTaskGetChannel
		 * @summary 获取渠道运输方式
		 * @request GET:/wms/delivery/task/getChannel
		 */
		deliveryTaskGetChannel: (
			query: {
				/** 物流公司code */
				logisticsCompanyCode: string;
			},
			params: RequestParams = {}
		) =>
			this.request<BizResponseListLogisticsChannelDTO, any>({
				path: `/wms/delivery/task/getChannel`,
				method: 'GET',
				query: query,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 发货任务
		 * @name DeliveryTaskGetD2CSpotCheckNums
		 * @summary D2c查询抽检数
		 * @request POST:/wms/delivery/task/getD2cSpotCheckNums
		 */
		deliveryTaskGetD2CSpotCheckNums: (
			orderCodeList: string[],
			params: RequestParams = {}
		) =>
			this.request<BizResponseListD2CDeliverySpotCheckNumsDTO, any>({
				path: `/wms/delivery/task/getD2cSpotCheckNums`,
				method: 'POST',
				body: orderCodeList,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 发货任务
		 * @name DeliveryTaskGetDeliverTaskBySku
		 * @summary getDeliverTaskBySku
		 * @request GET:/wms/delivery/task/getDeliverTaskBySku
		 */
		deliveryTaskGetDeliverTaskBySku: (
			query: {
				/** shopProductSku */
				shopProductSku: string;
			},
			params: RequestParams = {}
		) =>
			this.request<BizResponseListString, any>({
				path: `/wms/delivery/task/getDeliverTaskBySku`,
				method: 'GET',
				query: query,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 发货任务
		 * @name DeliveryTaskGetDeliverTaskDetailBySystemOrderNo
		 * @summary getDeliverTaskDetailBySystemOrderNo
		 * @request GET:/wms/delivery/task/getDeliverTaskDetailBySystemOrderNo
		 */
		deliveryTaskGetDeliverTaskDetailBySystemOrderNo: (
			query: {
				/** orderNo */
				orderNo: string;
			},
			params: RequestParams = {}
		) =>
			this.request<BizResponseListString, any>({
				path: `/wms/delivery/task/getDeliverTaskDetailBySystemOrderNo`,
				method: 'GET',
				query: query,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 发货任务
		 * @name DeliveryTaskGetListByDeliveryTaskCode
		 * @summary getListByDeliveryTaskCode
		 * @request POST:/wms/delivery/task/getListByDeliveryTaskCode
		 */
		deliveryTaskGetListByDeliveryTaskCode: (
			deliveryTaskCodeList: string[],
			params: RequestParams = {}
		) =>
			this.request<BizResponseListDeliverTaskRespDTO, any>({
				path: `/wms/delivery/task/getListByDeliveryTaskCode`,
				method: 'POST',
				body: deliveryTaskCodeList,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 发货任务
		 * @name DeliveryTaskGetLogisticsNo
		 * @summary 一键获取物流单号
		 * @request POST:/wms/delivery/task/getLogisticsNo
		 */
		deliveryTaskGetLogisticsNo: (
			dto: BaseDeliverTaskReqDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponseObject, any>({
				path: `/wms/delivery/task/getLogisticsNo`,
				method: 'POST',
				body: dto,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 发货任务
		 * @name DeliveryTaskGetLogisticsSkuInfo
		 * @summary getLogisticsSkuInfo
		 * @request GET:/wms/delivery/task/getLogisticsSkuInfo
		 */
		deliveryTaskGetLogisticsSkuInfo: (
			query: {
				/** logisticsCode */
				logisticsCode: string;
			},
			params: RequestParams = {}
		) =>
			this.request<BizResponseLogisticsInfoDTO, any>({
				path: `/wms/delivery/task/getLogisticsSkuInfo`,
				method: 'GET',
				query: query,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 发货任务
		 * @name DeliveryTaskGetTagTemplate
		 * @summary 获取标签模板接口
		 * @request GET:/wms/delivery/task/getTagTemplate
		 */
		deliveryTaskGetTagTemplate: (
			query: {
				/**
				 * customerShopId
				 * @format int64
				 */
				customerShopId: number;
				/**
				 * thirdWarehouseType
				 * @format int32
				 */
				thirdWarehouseType: number;
			},
			params: RequestParams = {}
		) =>
			this.request<BizResponseListDeliveryTagTemplateRespDto, any>({
				path: `/wms/delivery/task/getTagTemplate`,
				method: 'GET',
				query: query,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 发货任务
		 * @name DeliveryTaskGetTrack
		 * @summary 获取物流轨迹
		 * @request GET:/wms/delivery/task/getTrack
		 */
		deliveryTaskGetTrack: (
			query: {
				/** 发货单号 */
				deliveryTaskCode: string;
				/** 物流单号 */
				logisticsCode: string;
			},
			params: RequestParams = {}
		) =>
			this.request<BizResponseDeliveryTaskTrackRespDTO, any>({
				path: `/wms/delivery/task/getTrack`,
				method: 'GET',
				query: query,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 发货任务
		 * @name DeliveryTaskInfo
		 * @summary 发货任务详情
		 * @request GET:/wms/delivery/task/info
		 */
		deliveryTaskInfo: (
			query: {
				/** deliveryTaskCode */
				deliveryTaskCode: string;
			},
			params: RequestParams = {}
		) =>
			this.request<BizResponseDeliverTaskRespDTO, any>({
				path: `/wms/delivery/task/info`,
				method: 'GET',
				query: query,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 发货任务
		 * @name DeliveryTaskInfoB2B
		 * @summary 通过拣货任务编号查询发货任务信息
		 * @request GET:/wms/delivery/task/info/b2b
		 */
		deliveryTaskInfoB2B: (
			query: {
				/**
				 * 发货操作台状态:2-打标中,3-打包中
				 * @format int32
				 */
				deliveryStatus: number;
				/** 发货任务编号 */
				deliveryTaskCode: string;
			},
			params: RequestParams = {}
		) =>
			this.request<BizResponseDeliverTaskRespDTO, any>({
				path: `/wms/delivery/task/info/b2b`,
				method: 'GET',
				query: query,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 发货任务
		 * @name DeliveryTaskInfoD2CMore
		 * @summary 获取小件多品发货任务信息
		 * @request GET:/wms/delivery/task/info/d2c/more
		 */
		deliveryTaskInfoD2CMore: (
			query?: {
				/** 分拣篮编号 */
				basketCode?: string;
			},
			params: RequestParams = {}
		) =>
			this.request<BizResponseDeliverTaskRespDTO, any>({
				path: `/wms/delivery/task/info/d2c/more`,
				method: 'GET',
				query: query,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 发货任务
		 * @name DeliveryTaskInfoD2CMoreGetByContainerCode
		 * @summary 根据子容器获取详情
		 * @request GET:/wms/delivery/task/info/d2c/more/getByContainerCode
		 */
		deliveryTaskInfoD2CMoreGetByContainerCode: (
			query: {
				/** 分拣子容器编码 */
				containerCode: string;
			},
			params: RequestParams = {}
		) =>
			this.request<BizResponseDeliverTaskRespDTO, any>({
				path: `/wms/delivery/task/info/d2c/more/getByContainerCode`,
				method: 'GET',
				query: query,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 发货任务
		 * @name DeliveryTaskInfoD2CSingle
		 * @summary 获取小件单品发货任务信息
		 * @request GET:/wms/delivery/task/info/d2c/single
		 */
		deliveryTaskInfoD2CSingle: (
			query: {
				/** containerCode */
				containerCode?: string;
				/** 批次包编码 */
				pkgCode: string;
			},
			params: RequestParams = {}
		) =>
			this.request<BizResponseDeliverTaskRespDTO, any>({
				path: `/wms/delivery/task/info/d2c/single`,
				method: 'GET',
				query: query,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 发货任务
		 * @name DeliveryTaskInfoD2CSingleCheck
		 * @summary 小件单品打包-校验批次包是否有多容器
		 * @request POST:/wms/delivery/task/info/d2c/single/check
		 */
		deliveryTaskInfoD2CSingleCheck: (
			request: SinglePackPkgCheckReq,
			params: RequestParams = {}
		) =>
			this.request<BizResponseSinglePackPkgCheckResp, any>({
				path: `/wms/delivery/task/info/d2c/single/check`,
				method: 'POST',
				body: request,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 发货任务
		 * @name DeliveryTaskInfoByPlatformOrderNo
		 * @summary infoByPlatformOrderNo
		 * @request GET:/wms/delivery/task/infoByPlatformOrderNo
		 */
		deliveryTaskInfoByPlatformOrderNo: (
			query: {
				/** platformOrderNo */
				platformOrderNo: string;
			},
			params: RequestParams = {}
		) =>
			this.request<BizResponseListDeliverTaskRespDTO, any>({
				path: `/wms/delivery/task/infoByPlatformOrderNo`,
				method: 'GET',
				query: query,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 发货任务
		 * @name DeliveryTaskIntercept
		 * @summary deliveryOrderIntercept
		 * @request POST:/wms/delivery/task/intercept
		 */
		deliveryTaskIntercept: (
			req: DeliveryOrderInterceptEventReq,
			params: RequestParams = {}
		) =>
			this.request<BizResponseDeliveryOrderInterceptEventResp, any>({
				path: `/wms/delivery/task/intercept`,
				method: 'POST',
				body: req,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 发货任务
		 * @name DeliveryTaskListByPlatformOrderNoList
		 * @summary listByPlatformOrderNoList
		 * @request POST:/wms/delivery/task/listByPlatformOrderNoList
		 */
		deliveryTaskListByPlatformOrderNoList: (
			platformOrderNoList: string[],
			params: RequestParams = {}
		) =>
			this.request<BizResponseListString, any>({
				path: `/wms/delivery/task/listByPlatformOrderNoList`,
				method: 'POST',
				body: platformOrderNoList,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 发货任务物流信息
		 * @name DeliveryTaskLogisticsBoxGetLogistics
		 * @summary 根据国际发货单号获取货箱物流明细
		 * @request POST:/wms/delivery/task/logistics/box/getLogistics
		 */
		deliveryTaskLogisticsBoxGetLogistics: (
			request: DeliveryTaskBoxLogisticsReq,
			params: RequestParams = {}
		) =>
			this.request<BizResponseListDeliveryTaskBoxLogisticsResp, any>({
				path: `/wms/delivery/task/logistics/box/getLogistics`,
				method: 'POST',
				body: request,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 发货任务物流信息
		 * @name DeliveryTaskLogisticsBoxLogisticsEdit
		 * @summary 编辑国际发货单发货箱物流单号
		 * @request POST:/wms/delivery/task/logistics/box/logistics/edit
		 */
		deliveryTaskLogisticsBoxLogisticsEdit: (
			request: DeliveryTaskBoxLogisticsEditReq[],
			params: RequestParams = {}
		) =>
			this.request<BizResponseObject, any>({
				path: `/wms/delivery/task/logistics/box/logistics/edit`,
				method: 'POST',
				body: request,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 发货任务物流信息
		 * @name DeliveryTaskLogisticsD2CWeightEdit
		 * @summary d2cEdit
		 * @request POST:/wms/delivery/task/logistics/d2c/weight/edit
		 */
		deliveryTaskLogisticsD2CWeightEdit: (
			dto: DeliveryBoxReqDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponseObject, any>({
				path: `/wms/delivery/task/logistics/d2c/weight/edit`,
				method: 'POST',
				body: dto,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 发货任务物流信息
		 * @name DeliveryTaskLogisticsDetailList
		 * @summary list
		 * @request GET:/wms/delivery/task/logistics/detail/list
		 */
		deliveryTaskLogisticsDetailList: (
			query?: {
				/** 发货任务编号 */
				deliveryTaskCode?: string;
			},
			params: RequestParams = {}
		) =>
			this.request<
				BizResponseListDeliveryTaskLogisticsDetailRespDTO,
				any
			>({
				path: `/wms/delivery/task/logistics/detail/list`,
				method: 'GET',
				query: query,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 发货任务物流信息
		 * @name DeliveryTaskLogisticsEdit
		 * @summary edit
		 * @request POST:/wms/delivery/task/logistics/edit
		 */
		deliveryTaskLogisticsEdit: (
			dto: DeliveryTaskLogisticsDetailReqDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponseString, any>({
				path: `/wms/delivery/task/logistics/edit`,
				method: 'POST',
				body: dto,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 发货任务物流信息
		 * @name DeliveryTaskLogisticsGetForwarder
		 * @summary getForwarder
		 * @request GET:/wms/delivery/task/logistics/getForwarder
		 */
		deliveryTaskLogisticsGetForwarder: (
			query: {
				/** deliveryTaskCode */
				deliveryTaskCode: string;
			},
			params: RequestParams = {}
		) =>
			this.request<BizResponseListWmsIntForwarderConfigRespDTO, any>({
				path: `/wms/delivery/task/logistics/getForwarder`,
				method: 'GET',
				query: query,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 发货任务物流信息
		 * @name DeliveryTaskLogisticsGetLogisticsTrackStatus
		 * @summary getLogisticsTrackStatus
		 * @request POST:/wms/delivery/task/logistics/getLogisticsTrackStatus
		 */
		deliveryTaskLogisticsGetLogisticsTrackStatus: (
			trackingNumberList: string[],
			params: RequestParams = {}
		) =>
			this.request<BizResponseListBatchGetLogisticsTrackRespDto, any>({
				path: `/wms/delivery/task/logistics/getLogisticsTrackStatus`,
				method: 'POST',
				body: trackingNumberList,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 发货任务物流信息
		 * @name DeliveryTaskLogisticsInfo
		 * @summary info
		 * @request GET:/wms/delivery/task/logistics/info
		 */
		deliveryTaskLogisticsInfo: (
			query: {
				/** 发货订单编号 */
				deliveryTaskCode: string;
				/** 物流单号 */
				logisticsCode: string;
			},
			params: RequestParams = {}
		) =>
			this.request<
				BizResponseListDeliveryTaskLogisticsDetailRespDTO,
				any
			>({
				path: `/wms/delivery/task/logistics/info`,
				method: 'GET',
				query: query,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 发货任务
		 * @name DeliveryTaskManagerConfirm
		 * @summary 担当确认
		 * @request POST:/wms/delivery/task/managerConfirm
		 */
		deliveryTaskManagerConfirm: (
			reqDTO: ManagerConfirmReqDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponseObject, any>({
				path: `/wms/delivery/task/managerConfirm`,
				method: 'POST',
				body: reqDTO,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 发货任务
		 * @name DeliveryTaskMarkFinish
		 * @summary 打标完成
		 * @request POST:/wms/delivery/task/mark/finish
		 */
		deliveryTaskMarkFinish: (
			dto: BaseDeliverTaskReqDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponseObject, any>({
				path: `/wms/delivery/task/mark/finish`,
				method: 'POST',
				body: dto,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 发货任务
		 * @name DeliveryTaskModifyCustoms
		 * @summary 修改申报信息
		 * @request POST:/wms/delivery/task/modifyCustoms
		 */
		deliveryTaskModifyCustoms: (
			dto: ModifyCustomsReqDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponseObject, any>({
				path: `/wms/delivery/task/modifyCustoms`,
				method: 'POST',
				body: dto,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 发货任务
		 * @name DeliveryTaskModifyForwarder
		 * @summary 修改货代公司
		 * @request POST:/wms/delivery/task/modifyForwarder
		 */
		deliveryTaskModifyForwarder: (
			dto: ModifyDeliveryForwarderReqDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponseObject, any>({
				path: `/wms/delivery/task/modifyForwarder`,
				method: 'POST',
				body: dto,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 发货任务
		 * @name DeliveryTaskNoDeclaratioSkuExport
		 * @summary 导出下载没有申报信息的sku数据
		 * @request POST:/wms/delivery/task/noDeclaratioSku/export
		 */
		deliveryTaskNoDeclaratioSkuExport: (
			deliveryTaskCodes: string[],
			params: RequestParams = {}
		) =>
			this.request<BizResponseObject, any>({
				path: `/wms/delivery/task/noDeclaratioSku/export`,
				method: 'POST',
				body: deliveryTaskCodes,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 发货任务
		 * @name DeliveryTaskPackFinish
		 * @summary 打包完成
		 * @request POST:/wms/delivery/task/pack/finish
		 */
		deliveryTaskPackFinish: (
			dto: PackFinishDto,
			params: RequestParams = {}
		) =>
			this.request<BizResponsePackFinishRespDTO, any>({
				path: `/wms/delivery/task/pack/finish`,
				method: 'POST',
				body: dto,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 发货任务
		 * @name DeliveryTaskPage
		 * @summary 发货任务分页-废弃
		 * @request POST:/wms/delivery/task/page
		 */
		deliveryTaskPage: (
			dto: DeliverTaskReqDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponsePageResultDeliverTaskRespDTO, any>({
				path: `/wms/delivery/task/page`,
				method: 'POST',
				body: dto,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 发货任务
		 * @name DeliveryTaskPdaConfirmDelivery
		 * @summary PDA确认发货
		 * @request POST:/wms/delivery/task/pda/confirm/delivery
		 */
		deliveryTaskPdaConfirmDelivery: (
			req: DeliveryTaskPdaConfirmReq,
			params: RequestParams = {}
		) =>
			this.request<BizResponseDeliveryTaskPdaConfirmResp, any>({
				path: `/wms/delivery/task/pda/confirm/delivery`,
				method: 'POST',
				body: req,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 发货任务
		 * @name DeliveryTaskProcessDeliveryTaskSettle
		 * @summary 溢出数库存处理
		 * @request POST:/wms/delivery/task/processDeliveryTaskSettle
		 */
		deliveryTaskProcessDeliveryTaskSettle: (
			deliveryTasks: string[],
			params: RequestParams = {}
		) =>
			this.request<BizResponseObject, any>({
				path: `/wms/delivery/task/processDeliveryTaskSettle`,
				method: 'POST',
				body: deliveryTasks,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 发货任务
		 * @name DeliveryTaskQueryDeliveryInfo
		 * @summary queryDeliveryInfo
		 * @request GET:/wms/delivery/task/queryDeliveryInfo
		 * @deprecated
		 */
		deliveryTaskQueryDeliveryInfo: (
			query: {
				/** systemOrderNoSet */
				systemOrderNoSet: string[];
			},
			params: RequestParams = {}
		) =>
			this.request<BizResponseListOrderDeliveryInfoDTO, any>({
				path: `/wms/delivery/task/queryDeliveryInfo`,
				method: 'GET',
				query: query,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 发货任务
		 * @name DeliveryTaskQueryDeliveryInfoList
		 * @summary queryDeliveryInfoList
		 * @request POST:/wms/delivery/task/queryDeliveryInfoList
		 */
		deliveryTaskQueryDeliveryInfoList: (
			systemOrderNoSet: string[],
			params: RequestParams = {}
		) =>
			this.request<BizResponseListOrderDeliveryInfoDTO, any>({
				path: `/wms/delivery/task/queryDeliveryInfoList`,
				method: 'POST',
				body: systemOrderNoSet,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 发货任务
		 * @name DeliveryTaskRemark
		 * @summary 发货单备注
		 * @request POST:/wms/delivery/task/remark
		 */
		deliveryTaskRemark: (
			dto: DeliverTaskRemarkReqDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponseObject, any>({
				path: `/wms/delivery/task/remark`,
				method: 'POST',
				body: dto,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 发货任务
		 * @name DeliveryTaskRenewCustoms
		 * @summary 更新申报信息
		 * @request POST:/wms/delivery/task/renewCustoms
		 */
		deliveryTaskRenewCustoms: (
			dto: RenewCustomsReqDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponseObject, any>({
				path: `/wms/delivery/task/renewCustoms`,
				method: 'POST',
				body: dto,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 发货任务
		 * @name DeliveryTaskSign
		 * @summary 签收
		 * @request POST:/wms/delivery/task/sign
		 */
		deliveryTaskSign: (
			dto: BaseDeliverTaskReqDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponseObject, any>({
				path: `/wms/delivery/task/sign`,
				method: 'POST',
				body: dto,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 发货任务
		 * @name DeliveryTaskSkuInfo
		 * @summary 商品详情
		 * @request GET:/wms/delivery/task/skuInfo
		 */
		deliveryTaskSkuInfo: (
			query: {
				/**
				 * 商品详情id
				 * @format int64
				 */
				deliveryTaskDetailId: number;
			},
			params: RequestParams = {}
		) =>
			this.request<BizResponseDeliveryTaskDetailRespDTO, any>({
				path: `/wms/delivery/task/skuInfo`,
				method: 'GET',
				query: query,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 发货任务
		 * @name DeliveryTaskSkuRemark
		 * @summary 商品备注
		 * @request POST:/wms/delivery/task/skuRemark
		 */
		deliveryTaskSkuRemark: (
			dto: DeliverTaskSkuRemarkReqDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponseObject, any>({
				path: `/wms/delivery/task/skuRemark`,
				method: 'POST',
				body: dto,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 发货任务
		 * @name DeliveryTaskSysDownloadPackingList
		 * @summary 下载装箱清单--业务后台
		 * @request POST:/wms/delivery/task/sysDownloadPackingList
		 */
		deliveryTaskSysDownloadPackingList: (
			dto: DownloadPackingListReqDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponseObject, any>({
				path: `/wms/delivery/task/sysDownloadPackingList`,
				method: 'POST',
				body: dto,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 发货任务
		 * @name DeliveryTaskSystemPage
		 * @summary 发货任务分页-后台访问
		 * @request POST:/wms/delivery/task/system/page
		 */
		deliveryTaskSystemPage: (
			dto: SystemDeliverTaskReqDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponsePageResultDeliverTaskRespDTO, any>({
				path: `/wms/delivery/task/system/page`,
				method: 'POST',
				body: dto,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 发货任务
		 * @name DeliveryTaskTaskPage
		 * @summary 发货任务分页-新
		 * @request POST:/wms/delivery/task/taskPage
		 */
		deliveryTaskTaskPage: (
			dto: DeliverTaskReqDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponsePageResultDeliverTaskWmsPageDto, any>({
				path: `/wms/delivery/task/taskPage`,
				method: 'POST',
				body: dto,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 发货任务
		 * @name DeliveryTaskUpdateOrderAddress
		 * @summary updateOrderAddress
		 * @request POST:/wms/delivery/task/updateOrderAddress
		 */
		deliveryTaskUpdateOrderAddress: (
			dto: DeliverTaskUpdateAddressDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponseObject, any>({
				path: `/wms/delivery/task/updateOrderAddress`,
				method: 'POST',
				body: dto,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 发货任务
		 * @name DeliveryTaskUpdateOverflowQuantity
		 * @summary 更新溢出数
		 * @request POST:/wms/delivery/task/updateOverflowQuantity
		 */
		deliveryTaskUpdateOverflowQuantity: (
			reqDTOList: MinimumOrderQuantityReqDTO[],
			params: RequestParams = {}
		) =>
			this.request<BizResponseObject, any>({
				path: `/wms/delivery/task/updateOverflowQuantity`,
				method: 'POST',
				body: reqDTOList,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 发货任务
		 * @name DeliveryTaskUploadBoxMarkLink
		 * @summary 上传、修改箱唛文件
		 * @request POST:/wms/delivery/task/uploadBoxMarkLink
		 */
		deliveryTaskUploadBoxMarkLink: (
			dto: UploadBoxMarkLinkReqDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponseObject, any>({
				path: `/wms/delivery/task/uploadBoxMarkLink`,
				method: 'POST',
				body: dto,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 发货任务
		 * @name DeliveryTaskUploadDataToAmazon
		 * @summary FBA-更新数据到亚马逊
		 * @request POST:/wms/delivery/task/uploadDataToAmazon
		 */
		deliveryTaskUploadDataToAmazon: (
			dto: UploadDataToAmazonReqDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponseObject, any>({
				path: `/wms/delivery/task/uploadDataToAmazon`,
				method: 'POST',
				body: dto,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 发货任务
		 * @name DeliveryTaskWfNoticeSettlement
		 * @summary 万里汇通知结算
		 * @request POST:/wms/delivery/task/wfNoticeSettlement
		 */
		deliveryTaskWfNoticeSettlement: (
			deliveryTaskCodes: string[],
			params: RequestParams = {}
		) =>
			this.request<BizResponseObject, any>({
				path: `/wms/delivery/task/wfNoticeSettlement`,
				method: 'POST',
				body: deliveryTaskCodes,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 发货任务
		 * @name DeliveryTaskWmsDownloadPackingList
		 * @summary 下载装箱清单--wms
		 * @request POST:/wms/delivery/task/wmsDownloadPackingList
		 */
		deliveryTaskWmsDownloadPackingList: (
			dto: DownloadPackingListReqDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponseObject, any>({
				path: `/wms/delivery/task/wmsDownloadPackingList`,
				method: 'POST',
				body: dto,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 波次接口相关
		 * @name DeliveryWaveCancel
		 * @summary 波次-取消
		 * @request POST:/wms/delivery/wave/cancel
		 */
		deliveryWaveCancel: (
			query: {
				/** waveCode */
				waveCode: string;
			},
			params: RequestParams = {}
		) =>
			this.request<BizResponseDeliveryWaveDetailResp, any>({
				path: `/wms/delivery/wave/cancel`,
				method: 'POST',
				query: query,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 波次接口相关
		 * @name DeliveryWaveCreate
		 * @summary 波次-创建
		 * @request POST:/wms/delivery/wave/create
		 */
		deliveryWaveCreate: (
			request: DeliveryWaveCreateReq,
			params: RequestParams = {}
		) =>
			this.request<BizResponseObject, any>({
				path: `/wms/delivery/wave/create`,
				method: 'POST',
				body: request,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 波次接口相关
		 * @name DeliveryWaveCustomerDeliveryTaskAmount
		 * @summary 波次-查询客户可待发货发货单数量
		 * @request POST:/wms/delivery/wave/customer/deliveryTaskAmount
		 */
		deliveryWaveCustomerDeliveryTaskAmount: (
			request: DeliveryTaskAmountReq,
			params: RequestParams = {}
		) =>
			this.request<BizResponseDeliveryTaskAmountResp, any>({
				path: `/wms/delivery/wave/customer/deliveryTaskAmount`,
				method: 'POST',
				body: request,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 波次接口相关
		 * @name DeliveryWaveDetail
		 * @summary 波次-详情
		 * @request GET:/wms/delivery/wave/detail
		 */
		deliveryWaveDetail: (
			query: {
				/** waveCode */
				waveCode: string;
			},
			params: RequestParams = {}
		) =>
			this.request<BizResponseDeliveryWaveDetailResp, any>({
				path: `/wms/delivery/wave/detail`,
				method: 'GET',
				query: query,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 波次接口相关
		 * @name DeliveryWaveForwarderList
		 * @summary 波次-物流货代信息列表
		 * @request POST:/wms/delivery/wave/forwarder/list
		 */
		deliveryWaveForwarderList: (
			deliveryForwarderReq: DeliveryForwarderReq,
			params: RequestParams = {}
		) =>
			this.request<BizResponseListDeliveryForwarderResp, any>({
				path: `/wms/delivery/wave/forwarder/list`,
				method: 'POST',
				body: deliveryForwarderReq,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 波次接口相关
		 * @name DeliveryWaveMatchAnalysis
		 * @summary 波次-批次数量查询
		 * @request POST:/wms/delivery/wave/match/analysis
		 */
		deliveryWaveMatchAnalysis: (
			deliveryWaveMatchReq: DeliveryWaveMatchReq,
			params: RequestParams = {}
		) =>
			this.request<BizResponseDeliveryWaveMatchResp, any>({
				path: `/wms/delivery/wave/match/analysis`,
				method: 'POST',
				body: deliveryWaveMatchReq,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 波次接口相关
		 * @name DeliveryWavePage
		 * @summary 波次-列表
		 * @request POST:/wms/delivery/wave/page
		 */
		deliveryWavePage: (
			req: DeliveryWavePageReq,
			params: RequestParams = {}
		) =>
			this.request<BizResponsePageResultDeliveryWavePageResp, any>({
				path: `/wms/delivery/wave/page`,
				method: 'POST',
				body: req,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 波次接口相关
		 * @name DeliveryWaveRun
		 * @summary 波次-运行
		 * @request POST:/wms/delivery/wave/run
		 */
		deliveryWaveRun: (
			query: {
				/** waveCode */
				waveCode: string;
			},
			params: RequestParams = {}
		) =>
			this.request<BizResponseObject, any>({
				path: `/wms/delivery/wave/run`,
				method: 'POST',
				query: query,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 波次接口相关
		 * @name DeliveryWaveScheduleWavePicKStatusSync
		 * @summary 波次-同步拣货状态
		 * @request POST:/wms/delivery/wave/schedule/wave/picKStatus/sync
		 */
		deliveryWaveScheduleWavePicKStatusSync: (params: RequestParams = {}) =>
			this.request<BizResponseObject, any>({
				path: `/wms/delivery/wave/schedule/wave/picKStatus/sync`,
				method: 'POST',
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 波次接口相关
		 * @name DeliveryWaveScheduleWaveRun
		 * @summary 波次-运行
		 * @request POST:/wms/delivery/wave/schedule/wave/run
		 */
		deliveryWaveScheduleWaveRun: (params: RequestParams = {}) =>
			this.request<BizResponseObject, any>({
				path: `/wms/delivery/wave/schedule/wave/run`,
				method: 'POST',
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 发货审单
		 * @name DeliveryReviewAbnormalCommit
		 * @summary 国际发货审核单-上报异常接口
		 * @request POST:/wms/deliveryReview/abnormal/commit
		 */
		deliveryReviewAbnormalCommit: (
			reqDTO: DeliveryReviewAbnormalReqDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponseObject, any>({
				path: `/wms/deliveryReview/abnormal/commit`,
				method: 'POST',
				body: reqDTO,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 发货审单
		 * @name DeliveryReviewAbnormalSkuEdit
		 * @summary 国际发货审核单-编辑sku备注接口
		 * @request POST:/wms/deliveryReview/abnormal/sku/edit
		 */
		deliveryReviewAbnormalSkuEdit: (
			reqDTO: DeliveryReviewSkuEditReqDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponseObject, any>({
				path: `/wms/deliveryReview/abnormal/sku/edit`,
				method: 'POST',
				body: reqDTO,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 发货审单
		 * @name DeliveryReviewAbnormalSpuEdit
		 * @summary 国际发货审核单-根据spu编辑
		 * @request POST:/wms/deliveryReview/abnormal/spu/edit
		 */
		deliveryReviewAbnormalSpuEdit: (
			reqDTO: DeliveryReviewAbnormalSpuEditReqDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponseObject, any>({
				path: `/wms/deliveryReview/abnormal/spu/edit`,
				method: 'POST',
				body: reqDTO,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 发货审单
		 * @name DeliveryReviewApiDeliveryReviewCancel
		 * @summary Feign接口-取消审单
		 * @request POST:/wms/deliveryReview/api/deliveryReview/cancel
		 */
		deliveryReviewApiDeliveryReviewCancel: (
			reqDTO: DeliveryReviewCancelReqDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponseObject, any>({
				path: `/wms/deliveryReview/api/deliveryReview/cancel`,
				method: 'POST',
				body: reqDTO,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 发货审单
		 * @name DeliveryReviewApiDeliveryReviewQuery
		 * @summary Feign接口-查询审单信息
		 * @request POST:/wms/deliveryReview/api/deliveryReviewQuery
		 */
		deliveryReviewApiDeliveryReviewQuery: (
			reqDTO: DeliveryReviewQueryReqDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponseDeliveryReviewQueryRespDTO, any>({
				path: `/wms/deliveryReview/api/deliveryReviewQuery`,
				method: 'POST',
				body: reqDTO,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 发货审单
		 * @name DeliveryReviewApiPage
		 * @summary Feign接口-客户确认接口
		 * @request POST:/wms/deliveryReview/api/page
		 */
		deliveryReviewApiPage: (
			reqDTO: DeliverReviewPageApiReqDTO,
			params: RequestParams = {}
		) =>
			this.request<
				BizResponsePageResultDeliveryReviewPageApiRespDTO,
				any
			>({
				path: `/wms/deliveryReview/api/page`,
				method: 'POST',
				body: reqDTO,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 发货审单
		 * @name DeliveryReviewApiWaitingConfirmCount
		 * @summary Feign接口-带确认数量统计
		 * @request POST:/wms/deliveryReview/api/waitingConfirm/count
		 */
		deliveryReviewApiWaitingConfirmCount: (
			reqDTO: DeliveryReviewApiConfirmCountReqDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponseDeliveryReviewApiConfirmCountRespDTO, any>({
				path: `/wms/deliveryReview/api/waitingConfirm/count`,
				method: 'POST',
				body: reqDTO,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 发货审单
		 * @name DeliveryReviewAuditReasonList
		 * @summary 国际发货审单分页
		 * @request POST:/wms/deliveryReview/auditReason/list
		 */
		deliveryReviewAuditReasonList: (params: RequestParams = {}) =>
			this.request<BizResponseListLableValue, any>({
				path: `/wms/deliveryReview/auditReason/list`,
				method: 'POST',
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 发货审单
		 * @name DeliveryReviewBatchEdit
		 * @summary 批量修改货代公司接口和异常信息
		 * @request POST:/wms/deliveryReview/batchEdit
		 */
		deliveryReviewBatchEdit: (
			reqDTO: BatchEditAuditInfoReqDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponseObject, any>({
				path: `/wms/deliveryReview/batchEdit`,
				method: 'POST',
				body: reqDTO,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 发货审单
		 * @name DeliveryReviewBatchEditAndSubmit
		 * @summary 批量修改货代公司接口和异常信息--并提交
		 * @request POST:/wms/deliveryReview/batchEditAndSubmit
		 */
		deliveryReviewBatchEditAndSubmit: (
			reqDTO: BatchEditAuditInfoReqDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponseObject, any>({
				path: `/wms/deliveryReview/batchEditAndSubmit`,
				method: 'POST',
				body: reqDTO,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 发货审单
		 * @name DeliveryReviewBatchReview
		 * @summary 批量审单
		 * @request POST:/wms/deliveryReview/batchReview
		 */
		deliveryReviewBatchReview: (
			reqDTOList: BatchReviewReqDTO[],
			params: RequestParams = {}
		) =>
			this.request<BizResponseBoolean, any>({
				path: `/wms/deliveryReview/batchReview`,
				method: 'POST',
				body: reqDTOList,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 发货审单
		 * @name DeliveryReviewCombinationProductList
		 * @summary 国际发货审核单-组合商品明细
		 * @request POST:/wms/deliveryReview/combination/product/list
		 */
		deliveryReviewCombinationProductList: (
			reqDTO: DeliveryReviewCombinationProductReqDTO,
			params: RequestParams = {}
		) =>
			this.request<
				BizResponseListDeliveryReviewCombinationProductRespDTO,
				any
			>({
				path: `/wms/deliveryReview/combination/product/list`,
				method: 'POST',
				body: reqDTO,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 发货审单
		 * @name DeliveryReviewCustomerConfirm
		 * @summary Feign接口-客户确认接口
		 * @request POST:/wms/deliveryReview/customer/confirm
		 */
		deliveryReviewCustomerConfirm: (
			reqDTO: DeliveryReviewCustomerConfirmReqDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponseListString, any>({
				path: `/wms/deliveryReview/customer/confirm`,
				method: 'POST',
				body: reqDTO,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 发货审单
		 * @name DeliveryReviewD2CCreateDeliveryReview
		 * @summary D2C创建国际发货审单
		 * @request POST:/wms/deliveryReview/d2cCreateDeliveryReview
		 */
		deliveryReviewD2CCreateDeliveryReview: (
			reqDTO: DeliveryReviewCreateReqDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponseObject, any>({
				path: `/wms/deliveryReview/d2cCreateDeliveryReview`,
				method: 'POST',
				body: reqDTO,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 发货审单
		 * @name DeliveryReviewExportDeliveryReview
		 * @summary 导出国际发货审单
		 * @request POST:/wms/deliveryReview/exportDeliveryReview
		 */
		deliveryReviewExportDeliveryReview: (
			reqDTO: ExportDeliveryReviewReqDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponseObject, any>({
				path: `/wms/deliveryReview/exportDeliveryReview`,
				method: 'POST',
				body: reqDTO,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 发货审单
		 * @name DeliveryReviewGetClientForwarder
		 * @summary 客户选择货代公司
		 * @request POST:/wms/deliveryReview/getClientForwarder
		 */
		deliveryReviewGetClientForwarder: (
			reqDTO: ClientForwarderReqDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponseListClientForwarderRespDTO, any>({
				path: `/wms/deliveryReview/getClientForwarder`,
				method: 'POST',
				body: reqDTO,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 发货审单
		 * @name DeliveryReviewGetReviewForwarder
		 * @summary 获取审单货代公司
		 * @request POST:/wms/deliveryReview/getReviewForwarder
		 */
		deliveryReviewGetReviewForwarder: (
			reqDTO: GetReviewForwarderReqDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponseGetReviewForwarderRespDTO, any>({
				path: `/wms/deliveryReview/getReviewForwarder`,
				method: 'POST',
				body: reqDTO,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 发货审单
		 * @name DeliveryReviewModifyReviewForwarder
		 * @summary 修改审单货代公司
		 * @request POST:/wms/deliveryReview/modifyReviewForwarder
		 */
		deliveryReviewModifyReviewForwarder: (
			reqDTO: ModifyReviewForwarderReqDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponseObject, any>({
				path: `/wms/deliveryReview/modifyReviewForwarder`,
				method: 'POST',
				body: reqDTO,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 发货审单
		 * @name DeliveryReviewPage
		 * @summary 国际发货审单分页
		 * @request POST:/wms/deliveryReview/page
		 */
		deliveryReviewPage: (
			reqDTO: DeliveryReviewReqDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponseDeliveryReviewPageRespDTO, any>({
				path: `/wms/deliveryReview/page`,
				method: 'POST',
				body: reqDTO,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 发货审单
		 * @name DeliveryReviewProductCustomsCompilationMethodPage
		 * @summary 申报商品材质接口查询
		 * @request POST:/wms/deliveryReview/product/customs/compilationMethod/page
		 */
		deliveryReviewProductCustomsCompilationMethodPage: (
			params: RequestParams = {}
		) =>
			this.request<
				BizResponseListCustomsProductCompilationMethodDTO,
				any
			>({
				path: `/wms/deliveryReview/product/customs/compilationMethod/page`,
				method: 'POST',
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 发货审单
		 * @name DeliveryReviewProductCustomsConfirm
		 * @summary 申报商品提交接口
		 * @request POST:/wms/deliveryReview/product/customs/confirm
		 */
		deliveryReviewProductCustomsConfirm: (
			reqDTO: DeliveryReviewProductCustomersReqDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponseObject, any>({
				path: `/wms/deliveryReview/product/customs/confirm`,
				method: 'POST',
				body: reqDTO,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 发货审单
		 * @name DeliveryReviewProductCustomsPage
		 * @summary 申报商品接口查询
		 * @request POST:/wms/deliveryReview/product/customs/page
		 */
		deliveryReviewProductCustomsPage: (params: RequestParams = {}) =>
			this.request<BizResponseListCustomsProductDTO, any>({
				path: `/wms/deliveryReview/product/customs/page`,
				method: 'POST',
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 发货审单
		 * @name DeliveryReviewReviewInfo
		 * @summary 审单信息
		 * @request POST:/wms/deliveryReview/reviewInfo
		 */
		deliveryReviewReviewInfo: (
			reqDTO: DeliveryReviewInfoReqDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponseDeliveryReviewInfoRespDTO, any>({
				path: `/wms/deliveryReview/reviewInfo`,
				method: 'POST',
				body: reqDTO,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 发货审单
		 * @name DeliveryReviewSingleReview
		 * @summary 单个审单
		 * @request POST:/wms/deliveryReview/singleReview
		 */
		deliveryReviewSingleReview: (
			reqDTO: SingleReviewReqDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponseBoolean, any>({
				path: `/wms/deliveryReview/singleReview`,
				method: 'POST',
				body: reqDTO,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 发货审单详情
		 * @name DeliveryReviewDetailBatchUpdateDeclarationInfo
		 * @summary 发货审单-批量更新申报信息
		 * @request POST:/wms/deliveryReviewDetail/batchUpdateDeclarationInfo
		 */
		deliveryReviewDetailBatchUpdateDeclarationInfo: (
			reqDTOList: ReviewDeclarationInfoReqDTO[],
			params: RequestParams = {}
		) =>
			this.request<BizResponseObject, any>({
				path: `/wms/deliveryReviewDetail/batchUpdateDeclarationInfo`,
				method: 'POST',
				body: reqDTOList,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 发货审单详情
		 * @name DeliveryReviewDetailUpdateDeclarationInfo
		 * @summary 发货审单-更新申报信息
		 * @request POST:/wms/deliveryReviewDetail/updateDeclarationInfo
		 */
		deliveryReviewDetailUpdateDeclarationInfo: (
			reqDTO: ReviewDeclarationInfoReqDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponseObject, any>({
				path: `/wms/deliveryReviewDetail/updateDeclarationInfo`,
				method: 'POST',
				body: reqDTO,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags delivery-task-invoice-controller
		 * @name DeliveryTaskInvoiceBackInit
		 * @summary backInit
		 * @request GET:/wms/deliveryTask/invoice/backInit
		 */
		deliveryTaskInvoiceBackInit: (
			query?: {
				/** deliveryTaskCode */
				deliveryTaskCode?: string;
			},
			params: RequestParams = {}
		) =>
			this.request<BizResponseObject, any>({
				path: `/wms/deliveryTask/invoice/backInit`,
				method: 'GET',
				query: query,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags delivery-task-invoice-controller
		 * @name DeliveryTaskInvoiceCommit
		 * @summary 制作发票
		 * @request POST:/wms/deliveryTask/invoice/commit
		 */
		deliveryTaskInvoiceCommit: (
			dto: CommitInvoiceReqDto,
			params: RequestParams = {}
		) =>
			this.request<BizResponseObject, any>({
				path: `/wms/deliveryTask/invoice/commit`,
				method: 'POST',
				body: dto,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags delivery-task-invoice-controller
		 * @name DeliveryTaskInvoiceDownloadInvoice
		 * @summary 下载发票
		 * @request POST:/wms/deliveryTask/invoice/downloadInvoice
		 */
		deliveryTaskInvoiceDownloadInvoice: (
			dto: CommitInvoiceReqDto,
			params: RequestParams = {}
		) =>
			this.request<BizResponseObject, any>({
				path: `/wms/deliveryTask/invoice/downloadInvoice`,
				method: 'POST',
				body: dto,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags delivery-task-invoice-controller
		 * @name DeliveryTaskInvoiceDownloadPackingList
		 * @summary 下载装箱清单
		 * @request POST:/wms/deliveryTask/invoice/downloadPackingList
		 */
		deliveryTaskInvoiceDownloadPackingList: (
			dto: GenerateSalesContractReqDto,
			params: RequestParams = {}
		) =>
			this.request<BizResponseObject, any>({
				path: `/wms/deliveryTask/invoice/downloadPackingList`,
				method: 'POST',
				body: dto,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags delivery-task-invoice-controller
		 * @name DeliveryTaskInvoiceExport
		 * @summary 导出
		 * @request POST:/wms/deliveryTask/invoice/export
		 */
		deliveryTaskInvoiceExport: (
			dto: InvoiceExportReqDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponseLong, any>({
				path: `/wms/deliveryTask/invoice/export`,
				method: 'POST',
				body: dto,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags delivery-task-invoice-controller
		 * @name DeliveryTaskInvoiceGenerateSalesContract
		 * @summary 制作销售合同
		 * @request POST:/wms/deliveryTask/invoice/generateSalesContract
		 */
		deliveryTaskInvoiceGenerateSalesContract: (
			dto: GenerateSalesContractReqDto,
			params: RequestParams = {}
		) =>
			this.request<BizResponseObject, any>({
				path: `/wms/deliveryTask/invoice/generateSalesContract`,
				method: 'POST',
				body: dto,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags delivery-task-invoice-controller
		 * @name DeliveryTaskInvoiceGetForwarderCompany
		 * @summary 获取货代公司
		 * @request POST:/wms/deliveryTask/invoice/getForwarderCompany
		 */
		deliveryTaskInvoiceGetForwarderCompany: (
			reqDTO: InvoiceForwarderReqDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponseListInvoiceForwarderRespDTO, any>({
				path: `/wms/deliveryTask/invoice/getForwarderCompany`,
				method: 'POST',
				body: reqDTO,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags delivery-task-invoice-controller
		 * @name DeliveryTaskInvoiceGetInvoiceForwarder
		 * @summary 获取货代渠道
		 * @request POST:/wms/deliveryTask/invoice/getInvoiceForwarder
		 */
		deliveryTaskInvoiceGetInvoiceForwarder: (
			reqDTO: InvoiceForwarderReqDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponseListInvoiceForwarderRespDTO, any>({
				path: `/wms/deliveryTask/invoice/getInvoiceForwarder`,
				method: 'POST',
				body: reqDTO,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags delivery-task-invoice-controller
		 * @name DeliveryTaskInvoiceGetTemplate
		 * @summary 查询发票模板
		 * @request GET:/wms/deliveryTask/invoice/getTemplate
		 */
		deliveryTaskInvoiceGetTemplate: (
			query: {
				/** logisticsCode */
				logisticsCode: string;
			},
			params: RequestParams = {}
		) =>
			this.request<BizResponseListInvoiceTemplateDTO, any>({
				path: `/wms/deliveryTask/invoice/getTemplate`,
				method: 'GET',
				query: query,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags delivery-task-invoice-controller
		 * @name DeliveryTaskInvoiceOfflineCommit
		 * @summary 已线下制作发票
		 * @request POST:/wms/deliveryTask/invoice/offlineCommit
		 */
		deliveryTaskInvoiceOfflineCommit: (
			dto: OfflineCommitInvoiceReqDto,
			params: RequestParams = {}
		) =>
			this.request<BizResponseObject, any>({
				path: `/wms/deliveryTask/invoice/offlineCommit`,
				method: 'POST',
				body: dto,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags delivery-task-invoice-controller
		 * @name DeliveryTaskInvoicePage
		 * @summary 分页查询
		 * @request POST:/wms/deliveryTask/invoice/page
		 */
		deliveryTaskInvoicePage: (
			query: DeliveryTaskInvoicePageQuery,
			params: RequestParams = {}
		) =>
			this.request<BizResponsePageResultDeliveryTaskInvoiceDTO, any>({
				path: `/wms/deliveryTask/invoice/page`,
				method: 'POST',
				body: query,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 国际发货操作记录
		 * @name DeliveryTaskOperationBatchCreate
		 * @summary 批量创建国际发货操作记录
		 * @request POST:/wms/deliveryTaskOperation/batchCreate
		 */
		deliveryTaskOperationBatchCreate: (
			reqDTOList: _[],
			params: RequestParams = {}
		) =>
			this.request<BizResponseObject, any>({
				path: `/wms/deliveryTaskOperation/batchCreate`,
				method: 'POST',
				body: reqDTOList,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 国际发货操作记录
		 * @name DeliveryTaskOperationCreate
		 * @summary 创建国际发货操作记录
		 * @request POST:/wms/deliveryTaskOperation/create
		 */
		deliveryTaskOperationCreate: (reqDTO: _, params: RequestParams = {}) =>
			this.request<BizResponseBoolean, any>({
				path: `/wms/deliveryTaskOperation/create`,
				method: 'POST',
				body: reqDTO,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 国际发货操作记录
		 * @name DeliveryTaskOperationCreateMarkOperation
		 * @summary 大件打标新增操作记录
		 * @request POST:/wms/deliveryTaskOperation/createMarkOperation
		 */
		deliveryTaskOperationCreateMarkOperation: (
			reqDTO: CreateMarkOperationReqDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponseObject, any>({
				path: `/wms/deliveryTaskOperation/createMarkOperation`,
				method: 'POST',
				body: reqDTO,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 国际发货操作记录
		 * @name DeliveryTaskOperationListDeliveryTaskOperation
		 * @summary 查询国际发货操作记录列表
		 * @request GET:/wms/deliveryTaskOperation/listDeliveryTaskOperation
		 */
		deliveryTaskOperationListDeliveryTaskOperation: (
			query: {
				/** 国际发货单号 */
				deliveryTaskCode: string;
			},
			params: RequestParams = {}
		) =>
			this.request<BizResponseListDeliveryTaskOperationVO, any>({
				path: `/wms/deliveryTaskOperation/listDeliveryTaskOperation`,
				method: 'GET',
				query: query,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 国际发货操作记录
		 * @name DeliveryTaskOperationUpdateDeliveryTaskCode
		 * @summary 更新发货单任务编号
		 * @request GET:/wms/deliveryTaskOperation/updateDeliveryTaskCode
		 */
		deliveryTaskOperationUpdateDeliveryTaskCode: (
			query: {
				/** 业务编号 */
				bizNo: string;
				/** 发货任务编号 */
				deliveryTaskCode: string;
			},
			params: RequestParams = {}
		) =>
			this.request<BizResponseObject, any>({
				path: `/wms/deliveryTaskOperation/updateDeliveryTaskCode`,
				method: 'GET',
				query: query,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 导出记录
		 * @name ExportPage
		 * @summary 导出记录分页
		 * @request POST:/wms/export/page
		 */
		exportPage: (dto: ExportRecordReqDTO, params: RequestParams = {}) =>
			this.request<BizResponsePageResultExportRecordDTO, any>({
				path: `/wms/export/page`,
				method: 'POST',
				body: dto,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 异常流程
		 * @name FlowCreateSignFlow
		 * @summary createSignFlow
		 * @request POST:/wms/flow/createSignFlow
		 */
		flowCreateSignFlow: (dto: SignFlowReqDTO, params: RequestParams = {}) =>
			this.request<BizResponseObject, any>({
				path: `/wms/flow/createSignFlow`,
				method: 'POST',
				body: dto,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 异常流程
		 * @name FlowDealFlow
		 * @summary dealFlow
		 * @request POST:/wms/flow/dealFlow
		 */
		flowDealFlow: (dto: FlowReqDTO, params: RequestParams = {}) =>
			this.request<BizResponseObject, any>({
				path: `/wms/flow/dealFlow`,
				method: 'POST',
				body: dto,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 异常流程
		 * @name FlowDealFlowMoreInbound
		 * @summary dealFlowMoreInbound
		 * @request POST:/wms/flow/dealFlowMoreInbound
		 */
		flowDealFlowMoreInbound: (
			request: FlowMoreInboundReqDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponseObject, any>({
				path: `/wms/flow/dealFlowMoreInbound`,
				method: 'POST',
				body: request,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 异常流程
		 * @name FlowGetFlowInfo
		 * @summary 查询异常详情
		 * @request GET:/wms/flow/getFlowInfo
		 */
		flowGetFlowInfo: (
			query: {
				/** flowCode */
				flowCode: string;
			},
			params: RequestParams = {}
		) =>
			this.request<BizResponseFlowRespDTO, any>({
				path: `/wms/flow/getFlowInfo`,
				method: 'GET',
				query: query,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 异常流程
		 * @name FlowPage
		 * @summary page
		 * @request POST:/wms/flow/page
		 */
		flowPage: (pageReqDTO: FlowPageReqDTO, params: RequestParams = {}) =>
			this.request<BizResponsePageFlowPageRespDTO, any>({
				path: `/wms/flow/page`,
				method: 'POST',
				body: pageReqDTO,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 异常流程
		 * @name FlowQueryAbnormalRefundStatus
		 * @summary queryAbnormalRefundStatus
		 * @request POST:/wms/flow/queryAbnormalRefundStatus
		 */
		flowQueryAbnormalRefundStatus: (
			query: {
				/**
				 * abnormalOrderDealId
				 * @format int64
				 */
				abnormalOrderDealId: number;
				/** flowCode */
				flowCode: string;
			},
			params: RequestParams = {}
		) =>
			this.request<BizResponseDomesticDeliveryRespDTO, any>({
				path: `/wms/flow/queryAbnormalRefundStatus`,
				method: 'POST',
				query: query,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 异常流程
		 * @name FlowQueryRefundStatus
		 * @summary queryRefundStatus
		 * @request POST:/wms/flow/queryRefundStatus
		 */
		flowQueryRefundStatus: (
			query: {
				/** flowCode */
				flowCode: string;
			},
			params: RequestParams = {}
		) =>
			this.request<BizResponseDomesticDeliveryRespDTO, any>({
				path: `/wms/flow/queryRefundStatus`,
				method: 'POST',
				query: query,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 异常流程
		 * @name FlowRefundReconcile
		 * @summary refundReconcile
		 * @request POST:/wms/flow/refundReconcile
		 */
		flowRefundReconcile: (
			query: {
				/** flowCode */
				flowCode: string;
			},
			params: RequestParams = {}
		) =>
			this.request<BizResponseObject, any>({
				path: `/wms/flow/refundReconcile`,
				method: 'POST',
				query: query,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 异常流程
		 * @name FlowRepair
		 * @summary repair
		 * @request POST:/wms/flow/repair
		 */
		flowRepair: (
			query: {
				/** flowCode */
				flowCode: string;
			},
			params: RequestParams = {}
		) =>
			this.request<BizResponseObject, any>({
				path: `/wms/flow/repair`,
				method: 'POST',
				query: query,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 异常流程
		 * @name FlowUpdRefund
		 * @summary updRefund
		 * @request POST:/wms/flow/updRefund
		 */
		flowUpdRefund: (dto: FlowReqDTO, params: RequestParams = {}) =>
			this.request<BizResponseObject, any>({
				path: `/wms/flow/updRefund`,
				method: 'POST',
				body: dto,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 异常流程
		 * @name FlowUpdRefundLrt
		 * @summary updRefundLrt
		 * @request POST:/wms/flow/updRefundLrt
		 */
		flowUpdRefundLrt: (dto: FlowReqDTO, params: RequestParams = {}) =>
			this.request<BizResponseObject, any>({
				path: `/wms/flow/updRefundLrt`,
				method: 'POST',
				body: dto,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 异常流程
		 * @name FlowUpdateImgs
		 * @summary updateImgs
		 * @request POST:/wms/flow/updateImgs
		 */
		flowUpdateImgs: (
			dto: FlowUpdateImgsReqDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponseObject, any>({
				path: `/wms/flow/updateImgs`,
				method: 'POST',
				body: dto,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 异常流程
		 * @name FlowUpdatePurchaseImgs
		 * @summary updatePurchaseImgs
		 * @request POST:/wms/flow/updatePurchaseImgs
		 */
		flowUpdatePurchaseImgs: (
			dto: FlowUpdateImgsReqDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponseObject, any>({
				path: `/wms/flow/updatePurchaseImgs`,
				method: 'POST',
				body: dto,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 异常流程
		 * @name FlowWareDealFlow
		 * @summary wareDealFlow
		 * @request POST:/wms/flow/wareDealFlow
		 */
		flowWareDealFlow: (dto: FlowReqDTO, params: RequestParams = {}) =>
			this.request<BizResponseObject, any>({
				path: `/wms/flow/wareDealFlow`,
				method: 'POST',
				body: dto,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 货格
		 * @name GridGridPage
		 * @summary gridPage
		 * @request POST:/wms/grid/gridPage
		 */
		gridGridPage: (dto: GridPageReqDTO, params: RequestParams = {}) =>
			this.request<BizResponsePageGrid, any>({
				path: `/wms/grid/gridPage`,
				method: 'POST',
				body: dto,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 货格
		 * @name GridSaveGrids
		 * @summary saveGrids
		 * @request POST:/wms/grid/saveGrids
		 */
		gridSaveGrids: (dto: GridReqDTO, params: RequestParams = {}) =>
			this.request<BizResponseObject, any>({
				path: `/wms/grid/saveGrids`,
				method: 'POST',
				body: dto,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 图片
		 * @name ImgApplyCode
		 * @summary applyCode
		 * @request GET:/wms/img/applyCode
		 */
		imgApplyCode: (
			query: {
				/**
				 * num
				 * @format int32
				 */
				num: number;
			},
			params: RequestParams = {}
		) =>
			this.request<BizResponseListString, any>({
				path: `/wms/img/applyCode`,
				method: 'GET',
				query: query,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 图片
		 * @name ImgDelete
		 * @summary queryByCode
		 * @request GET:/wms/img/delete
		 */
		imgDelete: (
			query: {
				/**
				 * id
				 * @format int64
				 */
				id: number;
			},
			params: RequestParams = {}
		) =>
			this.request<BizResponseObject, any>({
				path: `/wms/img/delete`,
				method: 'GET',
				query: query,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 图片
		 * @name ImgQueryByCode
		 * @summary queryByCode
		 * @request GET:/wms/img/queryByCode
		 */
		imgQueryByCode: (
			query: {
				/** picRelation */
				picRelation: string;
			},
			params: RequestParams = {}
		) =>
			this.request<BizResponseListImg, any>({
				path: `/wms/img/queryByCode`,
				method: 'GET',
				query: query,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 图片
		 * @name ImgSaveImg
		 * @summary saveImg
		 * @request POST:/wms/img/saveImg
		 */
		imgSaveImg: (dto: ImgSaveDTO, params: RequestParams = {}) =>
			this.request<BizResponseObject, any>({
				path: `/wms/img/saveImg`,
				method: 'POST',
				body: dto,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 图片
		 * @name ImgWsQueryByCode
		 * @summary wsQueryByCode
		 * @request GET:/wms/img/wsQueryByCode
		 */
		imgWsQueryByCode: (
			query: {
				/** picRelation */
				picRelation: string;
				/** toCodeId */
				toCodeId: string;
			},
			params: RequestParams = {}
		) =>
			this.request<void, any>({
				path: `/wms/img/wsQueryByCode`,
				method: 'GET',
				query: query,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 入库单
		 * @name InboundOrderCancelSku
		 * @summary 手动入库--商品取消入库
		 * @request POST:/wms/inboundOrder/cancelSku
		 */
		inboundOrderCancelSku: (
			dto: InboundCancelSkuReqDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponseObject, any>({
				path: `/wms/inboundOrder/cancelSku`,
				method: 'POST',
				body: dto,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 入库单
		 * @name InboundOrderCheckByPurchaseNo
		 * @summary 根据采购单查询入库单是否已存在
		 * @request GET:/wms/inboundOrder/checkByPurchaseNo
		 */
		inboundOrderCheckByPurchaseNo: (
			query: {
				/** purchaseNo */
				purchaseNo: string;
			},
			params: RequestParams = {}
		) =>
			this.request<BizResponseBoolean, any>({
				path: `/wms/inboundOrder/checkByPurchaseNo`,
				method: 'GET',
				query: query,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 入库单
		 * @name InboundOrderClose
		 * @summary 手动入库--关闭入库单
		 * @request POST:/wms/inboundOrder/close
		 */
		inboundOrderClose: (
			dto: InboundOrderCloseReqDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponseObject, any>({
				path: `/wms/inboundOrder/close`,
				method: 'POST',
				body: dto,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 入库单
		 * @name InboundOrderCountByType
		 * @summary 入库单-状态数量统计
		 * @request GET:/wms/inboundOrder/countByType
		 */
		inboundOrderCountByType: (
			query: {
				/**
				 * 入库单类型 0-采购入库 1-手动入库 2-调拨入库
				 * @format int32
				 */
				inboundType: number;
			},
			params: RequestParams = {}
		) =>
			this.request<BizResponseInboundCountByTypeRespDTO, any>({
				path: `/wms/inboundOrder/countByType`,
				method: 'GET',
				query: query,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 入库单
		 * @name InboundOrderCreate
		 * @summary 创建采购入库单
		 * @request POST:/wms/inboundOrder/create
		 */
		inboundOrderCreate: (
			dto: InboundOrderCreateReqDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponseObject, any>({
				path: `/wms/inboundOrder/create`,
				method: 'POST',
				body: dto,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 入库单
		 * @name InboundOrderCreateManualInboundOrder
		 * @summary 创建手动入库单
		 * @request POST:/wms/inboundOrder/createManualInboundOrder
		 */
		inboundOrderCreateManualInboundOrder: (
			query: {
				/**
				 * 客户店铺id
				 * @format int64
				 */
				customerShopId: number;
				/**
				 * 商品类型 0-线上商品 1-OEM 2-线下商品
				 * @format int32
				 */
				skuType: number;
			},
			file: any,
			params: RequestParams = {}
		) =>
			this.request<BizResponseObject, any>({
				path: `/wms/inboundOrder/createManualInboundOrder`,
				method: 'POST',
				query: query,
				body: file,
				type: ContentType.FormData,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 入库单
		 * @name InboundOrderGetInfoBySku
		 * @summary 手动入库--获取sku信息
		 * @request GET:/wms/inboundOrder/getInfoBySku
		 */
		inboundOrderGetInfoBySku: (
			query: {
				/** 平台sku */
				productSku: string;
				/**
				 * 商品类型 0-线上商品 1-OEM 2-线下商品
				 * @format int32
				 */
				skuType: number;
			},
			params: RequestParams = {}
		) =>
			this.request<BizResponseInboundSkuPropRespDto, any>({
				path: `/wms/inboundOrder/getInfoBySku`,
				method: 'GET',
				query: query,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 入库单
		 * @name InboundOrderInboundNumInfo
		 * @summary 入库单-入库数量详情
		 * @request GET:/wms/inboundOrder/inboundNumInfo
		 */
		inboundOrderInboundNumInfo: (
			query: {
				/**
				 * 商品id
				 * @format int64
				 */
				inboundItemId: number;
				/**
				 * 批次包类型, 1-正品, 2-次品, 3-异常上报客户, 4-异常上报采购
				 * @format int32
				 */
				pkgType: number;
			},
			params: RequestParams = {}
		) =>
			this.request<BizResponseListInboundNumInfoRespDTO, any>({
				path: `/wms/inboundOrder/inboundNumInfo`,
				method: 'GET',
				query: query,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 入库单
		 * @name InboundOrderOneKeyComplete
		 * @summary 手动入库--完成入库
		 * @request POST:/wms/inboundOrder/oneKey/complete
		 */
		inboundOrderOneKeyComplete: (
			dto: InboundOneKeyCompleteReqDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponseObject, any>({
				path: `/wms/inboundOrder/oneKey/complete`,
				method: 'POST',
				body: dto,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 入库单
		 * @name InboundOrderOneKeyConfirm
		 * @summary 手动入库--一键入库
		 * @request POST:/wms/inboundOrder/oneKey/confirm
		 */
		inboundOrderOneKeyConfirm: (
			dto: InboundOneKeyConfirmReqDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponseListInboundOneKeyInfoRespDTO, any>({
				path: `/wms/inboundOrder/oneKey/confirm`,
				method: 'POST',
				body: dto,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 入库单
		 * @name InboundOrderOneKeyInfo
		 * @summary 手动入库-获取一键入库详情
		 * @request GET:/wms/inboundOrder/oneKey/info
		 */
		inboundOrderOneKeyInfo: (
			query: {
				/** 入库单号 */
				inboundCode: string;
			},
			params: RequestParams = {}
		) =>
			this.request<BizResponseListInboundOneKeyInfoRespDTO, any>({
				path: `/wms/inboundOrder/oneKey/info`,
				method: 'GET',
				query: query,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 入库单
		 * @name InboundOrderPage
		 * @summary 入库单分页
		 * @request POST:/wms/inboundOrder/page
		 */
		inboundOrderPage: (
			query: InboundOrderPageQuery,
			params: RequestParams = {}
		) =>
			this.request<BizResponsePageResultInboundOrderRespDTO, any>({
				path: `/wms/inboundOrder/page`,
				method: 'POST',
				body: query,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 入库单
		 * @name InboundOrderPageOperationRecord
		 * @summary 入库单-操作详情分页
		 * @request POST:/wms/inboundOrder/pageOperationRecord
		 */
		inboundOrderPageOperationRecord: (
			query: InboundOperationRecordPageQuery,
			params: RequestParams = {}
		) =>
			this.request<
				BizResponsePageResultInboundOperationRecordRespDTO,
				any
			>({
				path: `/wms/inboundOrder/pageOperationRecord`,
				method: 'POST',
				body: query,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 入库单
		 * @name InboundOrderPurchaseNumInfo
		 * @summary 入库单-购买数量详情
		 * @request GET:/wms/inboundOrder/purchaseNumInfo
		 */
		inboundOrderPurchaseNumInfo: (
			query: {
				/**
				 * 商品id
				 * @format int64
				 */
				inboundItemId: number;
			},
			params: RequestParams = {}
		) =>
			this.request<BizResponseListInboundPurchaseNumInfoRespDTO, any>({
				path: `/wms/inboundOrder/purchaseNumInfo`,
				method: 'GET',
				query: query,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 入库单
		 * @name InboundOrderRemoveLogistics
		 * @summary 删除运单号
		 * @request POST:/wms/inboundOrder/removeLogistics
		 */
		inboundOrderRemoveLogistics: (
			dto: InboundOrderRemoveLogisticsReqDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponseObject, any>({
				path: `/wms/inboundOrder/removeLogistics`,
				method: 'POST',
				body: dto,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 入库单
		 * @name InboundOrderSkuInfo
		 * @summary 入库单-商品详情
		 * @request GET:/wms/inboundOrder/skuInfo
		 */
		inboundOrderSkuInfo: (
			query: {
				/** 入库单号 */
				inboundCode: string;
			},
			params: RequestParams = {}
		) =>
			this.request<BizResponseListInboundOrderSkuInfoRespDTO, any>({
				path: `/wms/inboundOrder/skuInfo`,
				method: 'GET',
				query: query,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 入库单
		 * @name InboundOrderUpdateProductSkuImage
		 * @summary 修改sku图片
		 * @request POST:/wms/inboundOrder/update/productSku/Image
		 */
		inboundOrderUpdateProductSkuImage: (
			dto: InboundOrderSkuImageUpdateReqDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponseObject, any>({
				path: `/wms/inboundOrder/update/productSku/Image`,
				method: 'POST',
				body: dto,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 入库单
		 * @name InboundOrderUpdateSpecialCheckRequirement
		 * @summary 修改特殊质检要求
		 * @request POST:/wms/inboundOrder/update/specialCheckRequirement
		 */
		inboundOrderUpdateSpecialCheckRequirement: (
			dto: UpdateSpecialCheckRequirementReqDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponseObject, any>({
				path: `/wms/inboundOrder/update/specialCheckRequirement`,
				method: 'POST',
				body: dto,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 入库单
		 * @name InboundOrderUpdateActualUnitPrice
		 * @summary 更新实际销售单价
		 * @request POST:/wms/inboundOrder/updateActualUnitPrice
		 */
		inboundOrderUpdateActualUnitPrice: (
			purchaseNos: string[],
			params: RequestParams = {}
		) =>
			this.request<BizResponseObject, any>({
				path: `/wms/inboundOrder/updateActualUnitPrice`,
				method: 'POST',
				body: purchaseNos,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 入库单
		 * @name InboundOrderUpdateByUrl
		 * @summary 手动入库--采购链接更新，商品入库
		 * @request POST:/wms/inboundOrder/updateByUrl
		 */
		inboundOrderUpdateByUrl: (
			dto: InboundUpdateByUrlReqDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponseObject, any>({
				path: `/wms/inboundOrder/updateByUrl`,
				method: 'POST',
				body: dto,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 入库单
		 * @name InboundOrderUpdateLogistics
		 * @summary 新增运单号
		 * @request POST:/wms/inboundOrder/updateLogistics
		 */
		inboundOrderUpdateLogistics: (
			dto: InboundOrderUpdateLogisticsReqDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponseObject, any>({
				path: `/wms/inboundOrder/updateLogistics`,
				method: 'POST',
				body: dto,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 入库单
		 * @name InboundOrderUpdateProp
		 * @summary 手动入库--修改商品规格byURL
		 * @request POST:/wms/inboundOrder/updateProp
		 */
		inboundOrderUpdateProp: (
			dto: InboundUpdatePropReqDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponseObject, any>({
				path: `/wms/inboundOrder/updateProp`,
				method: 'POST',
				body: dto,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 入库单
		 * @name InboundOrderUpdatePropBySku
		 * @summary 手动入库--修改商品规格bySKU
		 * @request POST:/wms/inboundOrder/updatePropBySku
		 */
		inboundOrderUpdatePropBySku: (
			dto: InboundUpdatePropBySkuReqDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponseObject, any>({
				path: `/wms/inboundOrder/updatePropBySku`,
				method: 'POST',
				body: dto,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 订单商品附加项
		 * @name InboundOrderAdditionBackUpdateInboundAddition
		 * @summary 修复入库单订单附加项
		 * @request POST:/wms/inboundOrderAddition/back/updateInboundAddition
		 */
		inboundOrderAdditionBackUpdateInboundAddition: (
			params: RequestParams = {}
		) =>
			this.request<BizResponseObject, any>({
				path: `/wms/inboundOrderAddition/back/updateInboundAddition`,
				method: 'POST',
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 订单商品附加项
		 * @name InboundOrderAdditionBatchUpdateByOrderAdditionCode
		 * @summary 批量更新订单商品附加项编码
		 * @request POST:/wms/inboundOrderAddition/batchUpdateByOrderAdditionCode
		 */
		inboundOrderAdditionBatchUpdateByOrderAdditionCode: (
			orderAdditionCodes: string[],
			params: RequestParams = {}
		) =>
			this.request<BizResponseListInboundOrderAdditionRespDTO, any>({
				path: `/wms/inboundOrderAddition/batchUpdateByOrderAdditionCode`,
				method: 'POST',
				body: orderAdditionCodes,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 订单商品附加项
		 * @name InboundOrderAdditionGetAdditionalItems
		 * @summary 获取正附加项
		 * @request GET:/wms/inboundOrderAddition/getAdditionalItems
		 */
		inboundOrderAdditionGetAdditionalItems: (
			query: {
				/** pkgCode */
				pkgCode: string;
			},
			params: RequestParams = {}
		) =>
			this.request<BizResponseListInboundOrderAdditionRespDTO, any>({
				path: `/wms/inboundOrderAddition/getAdditionalItems`,
				method: 'GET',
				query: query,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 订单商品附加项
		 * @name InboundOrderAdditionGetBadAdditionalItems
		 * @summary 获取次转正附加项
		 * @request GET:/wms/inboundOrderAddition/getBadAdditionalItems
		 */
		inboundOrderAdditionGetBadAdditionalItems: (
			query: {
				/** orderAdditionCode */
				orderAdditionCode: string;
				/** platformOrderNo */
				platformOrderNo: string;
				/** shopProductSku */
				shopProductSku: string;
			},
			params: RequestParams = {}
		) =>
			this.request<BizResponseListInboundOrderAdditionRespDTO, any>({
				path: `/wms/inboundOrderAddition/getBadAdditionalItems`,
				method: 'GET',
				query: query,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 订单商品附加项
		 * @name InboundOrderAdditionGetReselectBadAdditionalItems
		 * @summary 获取重选次转正附加项
		 * @request GET:/wms/inboundOrderAddition/getReselectBadAdditionalItems
		 */
		inboundOrderAdditionGetReselectBadAdditionalItems: (
			query: {
				/** orderAdditionCode */
				orderAdditionCode: string;
			},
			params: RequestParams = {}
		) =>
			this.request<BizResponseListInboundOrderAdditionRespDTO, any>({
				path: `/wms/inboundOrderAddition/getReselectBadAdditionalItems`,
				method: 'GET',
				query: query,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 订单商品附加项
		 * @name InboundOrderAdditionListIngredientsAdditional
		 * @summary 获取辅料附加项
		 * @request POST:/wms/inboundOrderAddition/listIngredientsAdditional
		 */
		inboundOrderAdditionListIngredientsAdditional: (
			pkgCodes: string[],
			params: RequestParams = {}
		) =>
			this.request<BizResponseListInboundOrderAdditionRespDTO, any>({
				path: `/wms/inboundOrderAddition/listIngredientsAdditional`,
				method: 'POST',
				body: pkgCodes,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 订单商品附加项
		 * @name InboundOrderAdditionSaveBadAdditionalItems
		 * @summary 保存重选次转正附加项
		 * @request POST:/wms/inboundOrderAddition/saveBadAdditionalItems
		 */
		inboundOrderAdditionSaveBadAdditionalItems: (
			reqDTO: ReselectBadAdditionalItemsReqDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponseObject, any>({
				path: `/wms/inboundOrderAddition/saveBadAdditionalItems`,
				method: 'POST',
				body: reqDTO,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 内部异常
		 * @name InnerExceptionBackfillExpressNo
		 * @summary backfillExpressNo
		 * @request GET:/wms/innerException/backfillExpressNo
		 */
		innerExceptionBackfillExpressNo: (
			query: {
				/** expressNo */
				expressNo: string;
				/** innerCode */
				innerCode: string;
			},
			params: RequestParams = {}
		) =>
			this.request<BizResponseObject, any>({
				path: `/wms/innerException/backfillExpressNo`,
				method: 'GET',
				query: query,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 内部异常
		 * @name InnerExceptionCreate
		 * @summary create
		 * @request POST:/wms/innerException/create
		 */
		innerExceptionCreate: (
			dto: InnerExceptionDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponseObject, any>({
				path: `/wms/innerException/create`,
				method: 'POST',
				body: dto,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 内部异常
		 * @name InnerExceptionDeal
		 * @summary 确认（tag = 1）/废弃（tag = 2）/已找到（backAmount）
		 * @request POST:/wms/innerException/deal
		 */
		innerExceptionDeal: (
			dto: InnerExceptionDealDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponseObject, any>({
				path: `/wms/innerException/deal`,
				method: 'POST',
				body: dto,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 内部异常
		 * @name InnerExceptionEdit
		 * @summary edit
		 * @request POST:/wms/innerException/edit
		 */
		innerExceptionEdit: (
			dto: InnerExceptionDealDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponseObject, any>({
				path: `/wms/innerException/edit`,
				method: 'POST',
				body: dto,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 内部异常
		 * @name InnerExceptionPage
		 * @summary innerExceptionPage
		 * @request POST:/wms/innerException/page
		 */
		innerExceptionPage: (
			pageReqDTO: InnerExceptionPageReqDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponsePageInnerExceptionRespDTO, any>({
				path: `/wms/innerException/page`,
				method: 'POST',
				body: pageReqDTO,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 库存初始化
		 * @name InventoryInit
		 * @summary init
		 * @request POST:/wms/inventory/init
		 */
		inventoryInit: (
			request: SkuInventoryInitReq,
			params: RequestParams = {}
		) =>
			this.request<BizResponseObject, any>({
				path: `/wms/inventory/init`,
				method: 'POST',
				body: request,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 库龄
		 * @name InventoryAgeInit
		 * @summary init
		 * @request POST:/wms/inventoryAge/init
		 */
		inventoryAgeInit: (params: RequestParams = {}) =>
			this.request<BizResponseObject, any>({
				path: `/wms/inventoryAge/init`,
				method: 'POST',
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 库龄
		 * @name InventoryAgeMonthlyStatistics
		 * @summary 客户端统计库龄
		 * @request POST:/wms/inventoryAge/monthlyStatistics
		 */
		inventoryAgeMonthlyStatistics: (
			req: MonthlyStatisticsAgeReq,
			params: RequestParams = {}
		) =>
			this.request<BizResponseListMonthlyStatisticsAgeResp, any>({
				path: `/wms/inventoryAge/monthlyStatistics`,
				method: 'POST',
				body: req,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 库龄
		 * @name InventoryAgeQueryShopSkuByAge
		 * @summary 客户端库龄筛选
		 * @request POST:/wms/inventoryAge/queryShopSkuByAge
		 */
		inventoryAgeQueryShopSkuByAge: (
			req: QueryShopSkuByAgeReq,
			params: RequestParams = {}
		) =>
			this.request<BizResponseListString, any>({
				path: `/wms/inventoryAge/queryShopSkuByAge`,
				method: 'POST',
				body: req,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 标签模板配置
		 * @name LabelTemplateAdd
		 * @summary add
		 * @request POST:/wms/labelTemplate/add
		 */
		labelTemplateAdd: (
			reqDTO: AddLabelTemplateReqDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponseObject, any>({
				path: `/wms/labelTemplate/add`,
				method: 'POST',
				body: reqDTO,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 标签模板配置
		 * @name LabelTemplateEdit
		 * @summary edit
		 * @request POST:/wms/labelTemplate/edit
		 */
		labelTemplateEdit: (
			reqDTO: EditLabelTemplateReqDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponseObject, any>({
				path: `/wms/labelTemplate/edit`,
				method: 'POST',
				body: reqDTO,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 标签模板配置
		 * @name LabelTemplateList
		 * @summary list
		 * @request GET:/wms/labelTemplate/list
		 */
		labelTemplateList: (
			query?: {
				/** 标签名称 */
				label?: string;
				/**
				 * 标签类型：1-标签 2-批次包
				 * @format int32
				 */
				type?: number;
			},
			params: RequestParams = {}
		) =>
			this.request<BizResponseListLabelTemplateRespDTO, any>({
				path: `/wms/labelTemplate/list`,
				method: 'GET',
				query: query,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 物流轨迹
		 * @name LogisticsTrackComplete
		 * @summary 完结
		 * @request POST:/wms/logistics/track/complete
		 */
		logisticsTrackComplete: (
			req: LogisticsTrackUpdateReq,
			params: RequestParams = {}
		) =>
			this.request<BizResponseObject, any>({
				path: `/wms/logistics/track/complete`,
				method: 'POST',
				body: req,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 物流轨迹
		 * @name LogisticsTrackEditErrMsg
		 * @summary 更新异常原因
		 * @request POST:/wms/logistics/track/editErrMsg
		 */
		logisticsTrackEditErrMsg: (
			req: LogisticsTrackEditErrMsgReq,
			params: RequestParams = {}
		) =>
			this.request<BizResponseObject, any>({
				path: `/wms/logistics/track/editErrMsg`,
				method: 'POST',
				body: req,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 物流轨迹
		 * @name LogisticsTrackExport
		 * @summary 导出
		 * @request POST:/wms/logistics/track/export
		 */
		logisticsTrackExport: (
			req: ExportTrackReq,
			params: RequestParams = {}
		) =>
			this.request<BizResponseLong, any>({
				path: `/wms/logistics/track/export`,
				method: 'POST',
				body: req,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 物流轨迹
		 * @name LogisticsTrackGetForwarderChannel
		 * @summary 获取货代渠道
		 * @request POST:/wms/logistics/track/getForwarderChannel
		 */
		logisticsTrackGetForwarderChannel: (
			req: GetTrackForwarderCompanyReq,
			params: RequestParams = {}
		) =>
			this.request<BizResponseListTrackForwarderChannelResp, any>({
				path: `/wms/logistics/track/getForwarderChannel`,
				method: 'POST',
				body: req,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 物流轨迹
		 * @name LogisticsTrackGetForwarderCompany
		 * @summary 获取货代公司
		 * @request POST:/wms/logistics/track/getForwarderCompany
		 */
		logisticsTrackGetForwarderCompany: (
			req: GetTrackForwarderCompanyReq,
			params: RequestParams = {}
		) =>
			this.request<BizResponseListTrackForwarderCompanyResp, any>({
				path: `/wms/logistics/track/getForwarderCompany`,
				method: 'POST',
				body: req,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 物流轨迹
		 * @name LogisticsTrackGetTrackDetail
		 * @summary 查询物流节点
		 * @request GET:/wms/logistics/track/getTrackDetail
		 */
		logisticsTrackGetTrackDetail: (
			query: {
				/** logisticsCode */
				logisticsCode: string;
			},
			params: RequestParams = {}
		) =>
			this.request<BizResponseListLogisticsTrackDetailResp, any>({
				path: `/wms/logistics/track/getTrackDetail`,
				method: 'GET',
				query: query,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 物流轨迹
		 * @name LogisticsTrackPage
		 * @summary 分页查询物流轨迹
		 * @request POST:/wms/logistics/track/page
		 */
		logisticsTrackPage: (
			req: LogisticsTrackPageReq,
			params: RequestParams = {}
		) =>
			this.request<BizResponsePageResultLogisticsTrackPageResp, any>({
				path: `/wms/logistics/track/page`,
				method: 'POST',
				body: req,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 物流轨迹
		 * @name LogisticsTrackUpdate
		 * @summary 更新物流状态
		 * @request POST:/wms/logistics/track/update
		 */
		logisticsTrackUpdate: (
			req: LogisticsTrackUpdateReq,
			params: RequestParams = {}
		) =>
			this.request<BizResponseLogisticsTrackUpdateResp, any>({
				path: `/wms/logistics/track/update`,
				method: 'POST',
				body: req,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 物流API配置
		 * @name LogisticsApiConfigGetPrintConfig
		 * @summary getPrintConfig
		 * @request GET:/wms/logisticsApiConfig/getPrintConfig
		 */
		logisticsApiConfigGetPrintConfig: (
			query: {
				/** logisticsCompanyCode */
				logisticsCompanyCode: string;
			},
			params: RequestParams = {}
		) =>
			this.request<BizResponseLogisticsApiPrintConfigRespDTO, any>({
				path: `/wms/logisticsApiConfig/getPrintConfig`,
				method: 'GET',
				query: query,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 物流API配置
		 * @name LogisticsApiConfigPage
		 * @summary page
		 * @request POST:/wms/logisticsApiConfig/page
		 */
		logisticsApiConfigPage: (
			query: LogisticsApiConfigQuery,
			params: RequestParams = {}
		) =>
			this.request<BizResponsePageLogisticsApiConfigRespDTO, any>({
				path: `/wms/logisticsApiConfig/page`,
				method: 'POST',
				body: query,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 物流API配置
		 * @name LogisticsApiConfigSave
		 * @summary edit
		 * @request POST:/wms/logisticsApiConfig/save
		 */
		logisticsApiConfigSave: (
			dto: LogisticsApiConfigEditDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponseObject, any>({
				path: `/wms/logisticsApiConfig/save`,
				method: 'POST',
				body: dto,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 商品测量
		 * @name MeasureAdd
		 * @summary 新增商品测量数据
		 * @request POST:/wms/measure/add
		 */
		measureAdd: (
			reqDTO: ProductMeasureAddReqDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponseObject, any>({
				path: `/wms/measure/add`,
				method: 'POST',
				body: reqDTO,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 商品测量
		 * @name MeasureBatchAdd
		 * @summary 批量新增商品测量数据
		 * @request POST:/wms/measure/batchAdd
		 */
		measureBatchAdd: (
			list: ProductMeasureAddReqDTO[],
			params: RequestParams = {}
		) =>
			this.request<BizResponseObject, any>({
				path: `/wms/measure/batchAdd`,
				method: 'POST',
				body: list,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 商品测量
		 * @name MeasureEditImage
		 * @summary 编辑图片
		 * @request POST:/wms/measure/editImage
		 */
		measureEditImage: (
			dto: ProductMeasureEditImageReqDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponseObject, any>({
				path: `/wms/measure/editImage`,
				method: 'POST',
				body: dto,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 商品测量
		 * @name MeasureEditSize
		 * @summary 提交新品测量结果
		 * @request POST:/wms/measure/editSize
		 */
		measureEditSize: (
			dto: ProductMeasureEditSizeReqDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponseObject, any>({
				path: `/wms/measure/editSize`,
				method: 'POST',
				body: dto,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 商品测量
		 * @name MeasurePage
		 * @summary 分页查询
		 * @request POST:/wms/measure/page
		 */
		measurePage: (
			query: ProductMeasurePageQuery,
			params: RequestParams = {}
		) =>
			this.request<BizResponsePageResultProductMeasureRespDTO, any>({
				path: `/wms/measure/page`,
				method: 'POST',
				body: query,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags operate-record-controller
		 * @name OperateRecordNodeList
		 * @summary 操作节点枚举
		 * @request POST:/wms/operate/record/node/list
		 */
		operateRecordNodeList: (params: RequestParams = {}) =>
			this.request<BizResponseListLableValue, any>({
				path: `/wms/operate/record/node/list`,
				method: 'POST',
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags operate-record-controller
		 * @name OperateRecordPage
		 * @summary 操作列表
		 * @request POST:/wms/operate/record/page
		 */
		operateRecordPage: (
			request: OperateRecordPageReq,
			params: RequestParams = {}
		) =>
			this.request<BizResponsePageResultOperateRecordPageResp, any>({
				path: `/wms/operate/record/page`,
				method: 'POST',
				body: request,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags OPS服务
		 * @name OpsAdjustPkgInventory
		 * @summary 库存OPS-调整库存
		 * @request POST:/wms/ops/adjustPkgInventory
		 */
		opsAdjustPkgInventory: (
			dto: AdjustPkgInventoryReqDto,
			params: RequestParams = {}
		) =>
			this.request<BizResponseObject, any>({
				path: `/wms/ops/adjustPkgInventory`,
				method: 'POST',
				body: dto,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags OPS服务
		 * @name OpsGetPkgAdjustRecord
		 * @summary 库存OPS-查询批次库存调整日志
		 * @request GET:/wms/ops/getPkgAdjustRecord
		 */
		opsGetPkgAdjustRecord: (
			query: {
				/** pkgCode */
				pkgCode: string;
			},
			params: RequestParams = {}
		) =>
			this.request<BizResponseListPkgAdjustRecordDto, any>({
				path: `/wms/ops/getPkgAdjustRecord`,
				method: 'GET',
				query: query,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags OPS服务
		 * @name OpsGetPkgInfo
		 * @summary 库存OPS-查询批次详情
		 * @request GET:/wms/ops/getPkgInfo
		 */
		opsGetPkgInfo: (
			query: {
				/** pkgCode */
				pkgCode: string;
			},
			params: RequestParams = {}
		) =>
			this.request<BizResponseOpsPkgInfoDto, any>({
				path: `/wms/ops/getPkgInfo`,
				method: 'GET',
				query: query,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags OPS服务
		 * @name OpsQueryCustomerStockPkg
		 * @summary 库存OPS-查询客户库存
		 * @request POST:/wms/ops/queryCustomerStockPkg
		 */
		opsQueryCustomerStockPkg: (
			dto: QueryCustomerStockPkgReqDto,
			params: RequestParams = {}
		) =>
			this.request<BizResponseListOpsPkgDto, any>({
				path: `/wms/ops/queryCustomerStockPkg`,
				method: 'POST',
				body: dto,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 组合
		 * @name PackTaskAllotPickTaskDetail
		 * @summary 待拣货
		 * @request GET:/wms/packTask/allotPickTaskDetail
		 */
		packTaskAllotPickTaskDetail: (
			query: {
				/** pickCode */
				pickCode: string;
			},
			params: RequestParams = {}
		) =>
			this.request<BizResponseListPackAllotPkgDTO, any>({
				path: `/wms/packTask/allotPickTaskDetail`,
				method: 'GET',
				query: query,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 组合
		 * @name PackTaskBackInitCheckingStock
		 * @summary backInitCheckingStock
		 * @request POST:/wms/packTask/back/initCheckingStock
		 */
		packTaskBackInitCheckingStock: (params: RequestParams = {}) =>
			this.request<BizResponseObject, any>({
				path: `/wms/packTask/back/initCheckingStock`,
				method: 'POST',
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 组合
		 * @name PackTaskCancelPackTask
		 * @summary 取消组合任务
		 * @request POST:/wms/packTask/cancelPackTask
		 */
		packTaskCancelPackTask: (
			orderCodeList: string[],
			params: RequestParams = {}
		) =>
			this.request<BizResponseObject, any>({
				path: `/wms/packTask/cancelPackTask`,
				method: 'POST',
				body: orderCodeList,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 组合
		 * @name PackTaskCheckPick
		 * @summary 校验是否需要分拣
		 * @request GET:/wms/packTask/checkPick
		 */
		packTaskCheckPick: (
			query: {
				/** pickCode */
				pickCode: string;
			},
			params: RequestParams = {}
		) =>
			this.request<BizResponseCheckPickRespDTO, any>({
				path: `/wms/packTask/checkPick`,
				method: 'GET',
				query: query,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 组合
		 * @name PackTaskCreateAllotPick
		 * @summary 创建调拨任务
		 * @request POST:/wms/packTask/createAllotPick
		 * @deprecated
		 */
		packTaskCreateAllotPick: (
			orderCodes: string[],
			params: RequestParams = {}
		) =>
			this.request<BizResponseObject, any>({
				path: `/wms/packTask/createAllotPick`,
				method: 'POST',
				body: orderCodes,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 组合
		 * @name PackTaskCreateAllotPickNew
		 * @summary 创建调拨任务2
		 * @request POST:/wms/packTask/createAllotPickNew
		 */
		packTaskCreateAllotPickNew: (
			allotIds: number[],
			params: RequestParams = {}
		) =>
			this.request<BizResponseObject, any>({
				path: `/wms/packTask/createAllotPickNew`,
				method: 'POST',
				body: allotIds,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 组合
		 * @name PackTaskCreateAllotTask
		 * @summary 子商品单品售卖
		 * @request POST:/wms/packTask/createAllotTask
		 */
		packTaskCreateAllotTask: (
			dto: PackAllotSyncDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponseObject, any>({
				path: `/wms/packTask/createAllotTask`,
				method: 'POST',
				body: dto,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 组合
		 * @name PackTaskCreatePackBatch
		 * @summary 创建组合拣货任务
		 * @request POST:/wms/packTask/createPackBatch
		 */
		packTaskCreatePackBatch: (
			packCodes: string[],
			params: RequestParams = {}
		) =>
			this.request<BizResponseObject, any>({
				path: `/wms/packTask/createPackBatch`,
				method: 'POST',
				body: packCodes,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 组合
		 * @name PackTaskCreatePackTask
		 * @summary 创建组合任务主
		 * @request POST:/wms/packTask/createPackTask
		 */
		packTaskCreatePackTask: (
			dto: PackTaskSyncDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponseObject, any>({
				path: `/wms/packTask/createPackTask`,
				method: 'POST',
				body: dto,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 组合
		 * @name PackTaskDoBasketPackFinish
		 * @summary 分拣框-组合完成
		 * @request POST:/wms/packTask/doBasketPackFinish
		 */
		packTaskDoBasketPackFinish: (
			dto: BasketPackFinishDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponseObject, any>({
				path: `/wms/packTask/doBasketPackFinish`,
				method: 'POST',
				body: dto,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 组合
		 * @name PackTaskDoPick
		 * @summary 拣货完成
		 * @request GET:/wms/packTask/doPick
		 */
		packTaskDoPick: (
			query: {
				/** pickCode */
				pickCode: string;
				/** pkgCode */
				pkgCode: string;
			},
			params: RequestParams = {}
		) =>
			this.request<BizResponseObject, any>({
				path: `/wms/packTask/doPick`,
				method: 'GET',
				query: query,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 组合
		 * @name PackTaskGetBasketInfo
		 * @summary 组合分拣框详情
		 * @request GET:/wms/packTask/getBasketInfo
		 */
		packTaskGetBasketInfo: (
			query: {
				/** basketCode */
				basketCode: string;
			},
			params: RequestParams = {}
		) =>
			this.request<BizResponsePackBasketInfoDTO, any>({
				path: `/wms/packTask/getBasketInfo`,
				method: 'GET',
				query: query,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 组合
		 * @name PackTaskGetBasketItem
		 * @summary 组合分拣框-拣货商品明细
		 * @request GET:/wms/packTask/getBasketItem
		 */
		packTaskGetBasketItem: (
			query: {
				/** basketCode */
				basketCode: string;
			},
			params: RequestParams = {}
		) =>
			this.request<BizResponseListPackPkgVO, any>({
				path: `/wms/packTask/getBasketItem`,
				method: 'GET',
				query: query,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 组合
		 * @name PackTaskGetCombinationPickConfig
		 * @summary 查询-组合单批次组数上限设置
		 * @request GET:/wms/packTask/getCombinationPickConfig
		 */
		packTaskGetCombinationPickConfig: (params: RequestParams = {}) =>
			this.request<BizResponseInt, any>({
				path: `/wms/packTask/getCombinationPickConfig`,
				method: 'GET',
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 组合
		 * @name PackTaskGetPackBasket
		 * @summary 查询组合任务分拣框
		 * @request GET:/wms/packTask/getPackBasket
		 */
		packTaskGetPackBasket: (
			query: {
				/** packCode */
				packCode: string;
			},
			params: RequestParams = {}
		) =>
			this.request<BizResponseListBasketVO, any>({
				path: `/wms/packTask/getPackBasket`,
				method: 'GET',
				query: query,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 组合
		 * @name PackTaskPackAbleTaskPage
		 * @summary pc-待拣货任务列表
		 * @request POST:/wms/packTask/packAbleTaskPage
		 */
		packTaskPackAbleTaskPage: (
			pageReqDTO: PackAbleTaskPageReqDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponsePagePackAbleTaskPageDTO, any>({
				path: `/wms/packTask/packAbleTaskPage`,
				method: 'POST',
				body: pageReqDTO,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 组合
		 * @name PackTaskPackAllotPage
		 * @summary 调拨任务-列表
		 * @request POST:/wms/packTask/packAllotPage
		 */
		packTaskPackAllotPage: (
			pageReqDTO: PackAllotPageReqDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponsePagePackAllot, any>({
				path: `/wms/packTask/packAllotPage`,
				method: 'POST',
				body: pageReqDTO,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 组合
		 * @name PackTaskPackTaskPage
		 * @summary pc-组合任务列表
		 * @request POST:/wms/packTask/packTaskPage
		 */
		packTaskPackTaskPage: (
			pageReqDTO: PackTaskPageReqDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponsePagePackTaskPageDTO, any>({
				path: `/wms/packTask/packTaskPage`,
				method: 'POST',
				body: pageReqDTO,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 组合
		 * @name PackTaskPackTaskSettle
		 * @summary 组合任务清算
		 * @request POST:/wms/packTask/packTaskSettle
		 */
		packTaskPackTaskSettle: (
			orderCodes: string[],
			params: RequestParams = {}
		) =>
			this.request<BizResponseListPackTaskSettleRespDTO, any>({
				path: `/wms/packTask/packTaskSettle`,
				method: 'POST',
				body: orderCodes,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 组合
		 * @name PackTaskPcPickTaskPage
		 * @summary 任务列表-组合任务
		 * @request POST:/wms/packTask/pcPickTaskPage
		 */
		packTaskPcPickTaskPage: (
			pageReqDTO: PackPickTaskPageReqDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponsePagePickTaskPageDTO, any>({
				path: `/wms/packTask/pcPickTaskPage`,
				method: 'POST',
				body: pageReqDTO,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 组合
		 * @name PackTaskPickTaskDetail
		 * @summary pickTaskDetail
		 * @request GET:/wms/packTask/pickTaskDetail
		 */
		packTaskPickTaskDetail: (
			query: {
				/** pickCode */
				pickCode: string;
			},
			params: RequestParams = {}
		) =>
			this.request<BizResponseListPackPkgDTO, any>({
				path: `/wms/packTask/pickTaskDetail`,
				method: 'GET',
				query: query,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 组合
		 * @name PackTaskPickTaskPage
		 * @summary 拣货任务-列表
		 * @request POST:/wms/packTask/pickTaskPage
		 */
		packTaskPickTaskPage: (
			pageReqDTO: PackPickTaskPageReqDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponsePagePickTaskPageDTO, any>({
				path: `/wms/packTask/pickTaskPage`,
				method: 'POST',
				body: pageReqDTO,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 组合
		 * @name PackTaskQueryPickStatus
		 * @summary queryPickStatus
		 * @request POST:/wms/packTask/queryPickStatus
		 */
		packTaskQueryPickStatus: (
			orderCodeList: string[],
			params: RequestParams = {}
		) =>
			this.request<BizResponseListPickStatusDTO, any>({
				path: `/wms/packTask/queryPickStatus`,
				method: 'POST',
				body: orderCodeList,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 组合
		 * @name PackTaskQueryRemainByMainOrderCode
		 * @summary 落单库存查询-主商品系统单号
		 * @request POST:/wms/packTask/queryRemainByMainOrderCode
		 */
		packTaskQueryRemainByMainOrderCode: (
			orderCodeList: string[],
			params: RequestParams = {}
		) =>
			this.request<BizResponseMapStringListRemainProductDTO, any>({
				path: `/wms/packTask/queryRemainByMainOrderCode`,
				method: 'POST',
				body: orderCodeList,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 组合
		 * @name PackTaskQueryRemainByOrderCode
		 * @summary 落单库存查询-子商品系统单号
		 * @request POST:/wms/packTask/queryRemainByOrderCode
		 */
		packTaskQueryRemainByOrderCode: (
			orderCodeList: string[],
			params: RequestParams = {}
		) =>
			this.request<BizResponseMapStringInt, any>({
				path: `/wms/packTask/queryRemainByOrderCode`,
				method: 'POST',
				body: orderCodeList,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 组合
		 * @name PackTaskQueryRemainProduct
		 * @summary 落单库存查询-主商品sku
		 * @request POST:/wms/packTask/queryRemainProduct
		 */
		packTaskQueryRemainProduct: (
			combinationProductSkuList: string[],
			params: RequestParams = {}
		) =>
			this.request<BizResponseMapStringListRemainProductDTO, any>({
				path: `/wms/packTask/queryRemainProduct`,
				method: 'POST',
				body: combinationProductSkuList,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 组合
		 * @name PackTaskSelectPackBasket
		 * @summary 组合分拣框查询
		 * @request GET:/wms/packTask/selectPackBasket
		 */
		packTaskSelectPackBasket: (
			query?: {
				/** basketCode */
				basketCode?: string;
			},
			params: RequestParams = {}
		) =>
			this.request<BizResponsePackPickBasketRespDTO, any>({
				path: `/wms/packTask/selectPackBasket`,
				method: 'GET',
				query: query,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 组合
		 * @name PackTaskSorting
		 * @summary 新拣货中-配货
		 * @request POST:/wms/packTask/sorting
		 */
		packTaskSorting: (dto: PackSortingDTO, params: RequestParams = {}) =>
			this.request<BizResponsePickSortingDTO, any>({
				path: `/wms/packTask/sorting`,
				method: 'POST',
				body: dto,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 组合
		 * @name PackTaskSowQuery
		 * @summary 拣货中-配货
		 * @request GET:/wms/packTask/sowQuery
		 */
		packTaskSowQuery: (
			query: {
				/** pickCode */
				pickCode: string;
				/** pkgCode */
				pkgCode: string;
			},
			params: RequestParams = {}
		) =>
			this.request<BizResponseListPackPkg, any>({
				path: `/wms/packTask/sowQuery`,
				method: 'GET',
				query: query,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 组合
		 * @name PackTaskUpdateCombinationPickConfig
		 * @summary 更新-组合单批次组数上限设置
		 * @request POST:/wms/packTask/updateCombinationPickConfig
		 */
		packTaskUpdateCombinationPickConfig: (
			dto: CombinationPickConfigDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponseObject, any>({
				path: `/wms/packTask/updateCombinationPickConfig`,
				method: 'POST',
				body: dto,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 批次包
		 * @name PkgBackCancelOrder
		 * @summary 后门-取消转囤货
		 * @request GET:/wms/pkg/back/cancelOrder
		 */
		pkgBackCancelOrder: (
			query: {
				/** orderCode */
				orderCode: string;
			},
			params: RequestParams = {}
		) =>
			this.request<BizResponseObject, any>({
				path: `/wms/pkg/back/cancelOrder`,
				method: 'GET',
				query: query,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 批次包
		 * @name PkgBatchUpdateVolume
		 * @summary 修改sku体积
		 * @request POST:/wms/pkg/batchUpdateVolume
		 */
		pkgBatchUpdateVolume: (
			dto: BatchUpdateVolumeReqDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponseObject, any>({
				path: `/wms/pkg/batchUpdateVolume`,
				method: 'POST',
				body: dto,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 批次包
		 * @name PkgCheckingRecord
		 * @summary 详细检品操作日志
		 * @request GET:/wms/pkg/checkingRecord
		 */
		pkgCheckingRecord: (
			query: {
				/** pkgCode */
				pkgCode: string;
			},
			params: RequestParams = {}
		) =>
			this.request<BizResponseObject, any>({
				path: `/wms/pkg/checkingRecord`,
				method: 'GET',
				query: query,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 批次包
		 * @name PkgExportPkgState
		 * @summary 导出库存状态
		 * @request POST:/wms/pkg/exportPkgState
		 */
		pkgExportPkgState: (dto: PkgPageDTO, params: RequestParams = {}) =>
			this.request<BizResponseObject, any>({
				path: `/wms/pkg/exportPkgState`,
				method: 'POST',
				body: dto,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 批次包
		 * @name PkgGetCustomerPkgSkuInfo
		 * @summary getCustomerPkgSkuInfo
		 * @request POST:/wms/pkg/getCustomerPkgSkuInfo
		 */
		pkgGetCustomerPkgSkuInfo: (
			reqDTO: PkgSkuInfoReqDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponsePkgSkuInfoRespDTO, any>({
				path: `/wms/pkg/getCustomerPkgSkuInfo`,
				method: 'POST',
				body: reqDTO,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 批次包
		 * @name PkgGetIngredientsBatch
		 * @summary 获取辅料批次
		 * @request POST:/wms/pkg/getIngredientsBatch
		 */
		pkgGetIngredientsBatch: (
			ingredientsSkus: string[],
			params: RequestParams = {}
		) =>
			this.request<BizResponseListIngredientsBatchRespDTO, any>({
				path: `/wms/pkg/getIngredientsBatch`,
				method: 'POST',
				body: ingredientsSkus,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 批次包
		 * @name PkgGetPendingDefective
		 * @summary 查询待处理次品数
		 * @request POST:/wms/pkg/getPendingDefective
		 */
		pkgGetPendingDefective: (
			reqDTO: PendingDefectiveReqDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponseListPendingDefectiveRespDTO, any>({
				path: `/wms/pkg/getPendingDefective`,
				method: 'POST',
				body: reqDTO,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 批次包
		 * @name PkgGetSkuMaterials
		 * @summary 获取商品水洗标材质
		 * @request GET:/wms/pkg/getSkuMaterials
		 */
		pkgGetSkuMaterials: (
			query: {
				/** pkgCode */
				pkgCode: string;
			},
			params: RequestParams = {}
		) =>
			this.request<BizResponseString, any>({
				path: `/wms/pkg/getSkuMaterials`,
				method: 'GET',
				query: query,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 批次包
		 * @name PkgListPkgDetail
		 * @summary listPkgDetail
		 * @request POST:/wms/pkg/listPkgDetail
		 */
		pkgListPkgDetail: (pkgCodeList: string[], params: RequestParams = {}) =>
			this.request<BizResponseListPkgDTO, any>({
				path: `/wms/pkg/listPkgDetail`,
				method: 'POST',
				body: pkgCodeList,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 批次包
		 * @name PkgPkgDetail
		 * @summary 列表-批次包
		 * @request GET:/wms/pkg/pkgDetail
		 */
		pkgPkgDetail: (
			query: {
				/** pkgCode */
				pkgCode: string;
			},
			params: RequestParams = {}
		) =>
			this.request<BizResponsePkgDTO, any>({
				path: `/wms/pkg/pkgDetail`,
				method: 'GET',
				query: query,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 批次包
		 * @name PkgPkgPage
		 * @summary 仓库状态
		 * @request POST:/wms/pkg/pkgPage
		 */
		pkgPkgPage: (dto: PkgPageDTO, params: RequestParams = {}) =>
			this.request<BizResponsePagePkgDTO, any>({
				path: `/wms/pkg/pkgPage`,
				method: 'POST',
				body: dto,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 批次包
		 * @name PkgPkgPage4Settle
		 * @summary pkgPage4Settle
		 * @request POST:/wms/pkg/pkgPage4Settle
		 */
		pkgPkgPage4Settle: (
			reqDTO: PkgPageReqDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponsePageResultPkgPageRespDTO, any>({
				path: `/wms/pkg/pkgPage4Settle`,
				method: 'POST',
				body: reqDTO,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 批次包
		 * @name PkgPkgSplit
		 * @summary 次品登记/异常上报客户
		 * @request POST:/wms/pkg/pkgSplit
		 */
		pkgPkgSplit: (dto: PkgSplitReqDTO, params: RequestParams = {}) =>
			this.request<BizResponsePkg, any>({
				path: `/wms/pkg/pkgSplit`,
				method: 'POST',
				body: dto,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 批次包
		 * @name PkgPrintLabelRecord
		 * @summary 打印水洗标操作日志
		 * @request GET:/wms/pkg/printLabelRecord
		 */
		pkgPrintLabelRecord: (
			query: {
				/** pkgCode */
				pkgCode: string;
			},
			params: RequestParams = {}
		) =>
			this.request<BizResponseObject, any>({
				path: `/wms/pkg/printLabelRecord`,
				method: 'GET',
				query: query,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 批次包
		 * @name PkgQueryDoingWmsOrderCount
		 * @summary queryDoingWmsOrderCount
		 * @request GET:/wms/pkg/queryDoingWmsOrderCount
		 */
		pkgQueryDoingWmsOrderCount: (
			query: {
				/** platformOrderNo */
				platformOrderNo: string;
			},
			params: RequestParams = {}
		) =>
			this.request<BizResponseInt, any>({
				path: `/wms/pkg/queryDoingWmsOrderCount`,
				method: 'GET',
				query: query,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 批次包
		 * @name PkgQueryPkgCodeBizId
		 * @summary 查询万里汇手续费
		 * @request GET:/wms/pkg/queryPkgCodeBizId
		 */
		pkgQueryPkgCodeBizId: (
			query: {
				/** platformOrderNo */
				platformOrderNo: string;
			},
			params: RequestParams = {}
		) =>
			this.request<BizResponseListWalletFlowDetailRespDTO, any>({
				path: `/wms/pkg/queryPkgCodeBizId`,
				method: 'GET',
				query: query,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 批次包
		 * @name PkgShelve
		 * @summary 批次包上架
		 * @request POST:/wms/pkg/shelve
		 */
		pkgShelve: (reqDTO: ShelveReqDTO, params: RequestParams = {}) =>
			this.request<BizResponseObject, any>({
				path: `/wms/pkg/shelve`,
				method: 'POST',
				body: reqDTO,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 批次包
		 * @name PkgSyncCancelOrder
		 * @summary 同步取消订单
		 * @request GET:/wms/pkg/syncCancelOrder
		 */
		pkgSyncCancelOrder: (
			query: {
				/** orderCode */
				orderCode: string;
			},
			params: RequestParams = {}
		) =>
			this.request<BizResponseObject, any>({
				path: `/wms/pkg/syncCancelOrder`,
				method: 'GET',
				query: query,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 批次包
		 * @name PkgSyncOrder
		 * @summary 异常同步订单
		 * @request GET:/wms/pkg/syncOrder
		 */
		pkgSyncOrder: (
			query: {
				/**
				 * businessType
				 * @format int32
				 */
				businessType: number;
				/** orderCode */
				orderCode: string;
				/**
				 * source
				 * @format int32
				 */
				source: number;
			},
			params: RequestParams = {}
		) =>
			this.request<BizResponsePkgDTO, any>({
				path: `/wms/pkg/syncOrder`,
				method: 'GET',
				query: query,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 批次包
		 * @name PkgSyncPurchase
		 * @summary 同步采购入库数量
		 * @request POST:/wms/pkg/syncPurchase
		 */
		pkgSyncPurchase: (record: StockInReqDTO, params: RequestParams = {}) =>
			this.request<BizResponseObject, any>({
				path: `/wms/pkg/syncPurchase`,
				method: 'POST',
				body: record,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 批次包
		 * @name PkgUpdateRemainCombinationAmount
		 * @summary 更新剩余组合数
		 * @request POST:/wms/pkg/updateRemainCombinationAmount
		 */
		pkgUpdateRemainCombinationAmount: (
			reqDTO: RemainCombinationAmountReqDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponseObject, any>({
				path: `/wms/pkg/updateRemainCombinationAmount`,
				method: 'POST',
				body: reqDTO,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 打印模版
		 * @name PrintGetPkgTemplate
		 * @summary getPkgTemplate
		 * @request GET:/wms/print/getPkgTemplate
		 */
		printGetPkgTemplate: (params: RequestParams = {}) =>
			this.request<BizResponsePrintTemplate, any>({
				path: `/wms/print/getPkgTemplate`,
				method: 'GET',
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 打印模版
		 * @name PrintList
		 * @summary list
		 * @request GET:/wms/print/list
		 */
		printList: (
			query?: {
				/**
				 * templateType
				 * @format int32
				 */
				templateType?: number;
			},
			params: RequestParams = {}
		) =>
			this.request<BizResponseListPrintTemplate, any>({
				path: `/wms/print/list`,
				method: 'GET',
				query: query,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 打印模版
		 * @name PrintListByType
		 * @summary list
		 * @request GET:/wms/print/listByType
		 */
		printListByType: (
			query?: {
				/** 异常批次包 */
				pkgCode?: string;
				/** 标签名称 */
				templateName?: string;
				/**
				 * 模板类型，1-批次包，2-发货打标，3-物流，4-箱唛，5-拣货单，6-水洗标
				 * @format int32
				 */
				type?: number;
			},
			params: RequestParams = {}
		) =>
			this.request<BizResponseListPrintTemplate, any>({
				path: `/wms/print/listByType`,
				method: 'GET',
				query: query,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 打印模版
		 * @name PrintListPrintTemplate
		 * @summary 打印图片
		 * @request GET:/wms/print/listPrintTemplate
		 */
		printListPrintTemplate: (
			query: {
				/**
				 * systemSource
				 * @format int32
				 */
				systemSource: number;
				/**
				 * templateType
				 * @format int32
				 */
				templateType: number;
			},
			params: RequestParams = {}
		) =>
			this.request<BizResponseListPrintTemplate, any>({
				path: `/wms/print/listPrintTemplate`,
				method: 'GET',
				query: query,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 打印模版
		 * @name PrintQueryByCode
		 * @summary queryByCode
		 * @request GET:/wms/print/queryByCode
		 */
		printQueryByCode: (
			query: {
				/** templateCode */
				templateCode: string;
			},
			params: RequestParams = {}
		) =>
			this.request<BizResponsePrintTemplate, any>({
				path: `/wms/print/queryByCode`,
				method: 'GET',
				query: query,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 打印模版
		 * @name PrintQueryById
		 * @summary queryById
		 * @request GET:/wms/print/queryById
		 */
		printQueryById: (
			query: {
				/**
				 * id
				 * @format int64
				 */
				id: number;
			},
			params: RequestParams = {}
		) =>
			this.request<BizResponsePrintTemplate, any>({
				path: `/wms/print/queryById`,
				method: 'GET',
				query: query,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 打印模版
		 * @name PrintSave
		 * @summary save
		 * @request POST:/wms/print/save
		 */
		printSave: (template: PrintTemplate, params: RequestParams = {}) =>
			this.request<BizResponseObject, any>({
				path: `/wms/print/save`,
				method: 'POST',
				body: template,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 打印机配置
		 * @name PrintConfigCreate
		 * @summary 创建打印机配置
		 * @request POST:/wms/printConfig/create
		 */
		printConfigCreate: (
			reqDTO: PrintConfigReqDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponseBoolean, any>({
				path: `/wms/printConfig/create`,
				method: 'POST',
				body: reqDTO,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 打印机配置
		 * @name PrintConfigEditOpenOrClose
		 * @summary 编辑开启/关闭
		 * @request GET:/wms/printConfig/editOpenOrClose
		 */
		printConfigEditOpenOrClose: (
			query: {
				/**
				 * id
				 * @format int64
				 */
				id: number;
				/**
				 * 是否开启(0:否 1:是)
				 * @format int32
				 */
				openFlag: number;
			},
			params: RequestParams = {}
		) =>
			this.request<BizResponseBoolean, any>({
				path: `/wms/printConfig/editOpenOrClose`,
				method: 'GET',
				query: query,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 打印机配置
		 * @name PrintConfigEditPrint
		 * @summary 编辑打印
		 * @request POST:/wms/printConfig/editPrint
		 */
		printConfigEditPrint: (
			reqDTO: EditPrintReqDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponseBoolean, any>({
				path: `/wms/printConfig/editPrint`,
				method: 'POST',
				body: reqDTO,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 打印机配置
		 * @name PrintConfigGetByLogistic
		 * @summary 根据物流获取打印机配置
		 * @request GET:/wms/printConfig/getByLogistic
		 */
		printConfigGetByLogistic: (
			query: {
				/** 物流公司code */
				logisticsCompanyCode: string;
				/** mac地址 */
				macAddress: string;
			},
			params: RequestParams = {}
		) =>
			this.request<BizResponsePrintConfigVO, any>({
				path: `/wms/printConfig/getByLogistic`,
				method: 'GET',
				query: query,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 打印机配置
		 * @name PrintConfigGetByLogisticId
		 * @summary 根据物流获取打印机配置
		 * @request GET:/wms/printConfig/getByLogisticId
		 */
		printConfigGetByLogisticId: (
			query: {
				/**
				 * 货代公司id
				 * @format int64
				 */
				intForwarderConfigId: number;
				/** mac地址 */
				macAddress: string;
			},
			params: RequestParams = {}
		) =>
			this.request<BizResponsePrintConfigVO, any>({
				path: `/wms/printConfig/getByLogisticId`,
				method: 'GET',
				query: query,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 打印机配置
		 * @name PrintConfigGetLogisticPrintConfig
		 * @summary 获取面单-打印机配置
		 * @request GET:/wms/printConfig/getLogisticPrintConfig
		 */
		printConfigGetLogisticPrintConfig: (
			query: {
				/** 尺寸(宽) */
				macAddress: string;
				/** 尺寸(高) */
				printPaperSizeHigh: string;
			},
			params: RequestParams = {}
		) =>
			this.request<BizResponsePrintConfigVO, any>({
				path: `/wms/printConfig/getLogisticPrintConfig`,
				method: 'GET',
				query: query,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 打印机配置
		 * @name PrintConfigGetPrintConfig
		 * @summary 获取批次包-打印机配置
		 * @request GET:/wms/printConfig/getPrintConfig
		 */
		printConfigGetPrintConfig: (
			query: {
				/** MAC地址 */
				macAddress: string;
				/**
				 * 节点类型(1:简单检品打印 2:水洗标打印)
				 * @format int32
				 */
				nodeType: number;
				/**
				 * 系统来源(1:D2C 2:B2B)
				 * @format int32
				 */
				systemSource: number;
			},
			params: RequestParams = {}
		) =>
			this.request<BizResponsePrintConfigVO, any>({
				path: `/wms/printConfig/getPrintConfig`,
				method: 'GET',
				query: query,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 打印机配置
		 * @name PrintConfigPage
		 * @summary 查询分页打印机配置列表
		 * @request POST:/wms/printConfig/page
		 */
		printConfigPage: (
			reqDTO: PagePrintConfigReqDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponsePagePagePrintConfigRespDTO, any>({
				path: `/wms/printConfig/page`,
				method: 'POST',
				body: reqDTO,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 库区
		 * @name RegionRegionList
		 * @summary regionList
		 * @request GET:/wms/region/RegionList
		 */
		regionRegionList: (
			query?: {
				/**
				 * bindStatus
				 * @format int32
				 */
				bindStatus?: number;
			},
			params: RequestParams = {}
		) =>
			this.request<BizResponseListRegion, any>({
				path: `/wms/region/RegionList`,
				method: 'GET',
				query: query,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 库区
		 * @name RegionCreateRegion
		 * @summary createRegion
		 * @request POST:/wms/region/createRegion
		 */
		regionCreateRegion: (region: Region, params: RequestParams = {}) =>
			this.request<BizResponseObject, any>({
				path: `/wms/region/createRegion`,
				method: 'POST',
				body: region,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 库区
		 * @name RegionUpdRegion
		 * @summary updRegion
		 * @request POST:/wms/region/updRegion
		 */
		regionUpdRegion: (region: Region, params: RequestParams = {}) =>
			this.request<BizResponseObject, any>({
				path: `/wms/region/updRegion`,
				method: 'POST',
				body: region,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 次转正
		 * @name RegularizationBatchCreateTask
		 * @summary 生成拣货任务
		 * @request POST:/wms/regularization/batchCreateTask
		 */
		regularizationBatchCreateTask: (
			pkgCodes: string[],
			params: RequestParams = {}
		) =>
			this.request<BizResponseObject, any>({
				path: `/wms/regularization/batchCreateTask`,
				method: 'POST',
				body: pkgCodes,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 次转正
		 * @name RegularizationBatchUpdateBecomeInvalid
		 * @summary 次品作废
		 * @request POST:/wms/regularization/batchUpdateBecomeInvalid
		 */
		regularizationBatchUpdateBecomeInvalid: (
			pkgCodes: string[],
			params: RequestParams = {}
		) =>
			this.request<BizResponseObject, any>({
				path: `/wms/regularization/batchUpdateBecomeInvalid`,
				method: 'POST',
				body: pkgCodes,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 次转正
		 * @name RegularizationBatchUpdateBecomeRegular
		 * @summary 次品转正
		 * @request POST:/wms/regularization/batchUpdateBecomeRegular
		 */
		regularizationBatchUpdateBecomeRegular: (
			reqDTO: BecomeRegularReqDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponseObject, any>({
				path: `/wms/regularization/batchUpdateBecomeRegular`,
				method: 'POST',
				body: reqDTO,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 次转正
		 * @name RegularizationConversionRecord
		 * @summary 转正记录
		 * @request POST:/wms/regularization/conversionRecord
		 */
		regularizationConversionRecord: (
			reqDTO: ConversionRecordReqDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponseListConversionRecordRespDTO, any>({
				path: `/wms/regularization/conversionRecord`,
				method: 'POST',
				body: reqDTO,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 次转正
		 * @name RegularizationGetRegularizationInfo
		 * @summary 获取次转正信息
		 * @request GET:/wms/regularization/getRegularizationInfo
		 */
		regularizationGetRegularizationInfo: (
			query: {
				/** pkgCodes */
				pkgCodes: string;
			},
			params: RequestParams = {}
		) =>
			this.request<BizResponseRegularizationRespDTO, any>({
				path: `/wms/regularization/getRegularizationInfo`,
				method: 'GET',
				query: query,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 次转正
		 * @name RegularizationHandleFlowRegularization
		 * @summary 处理异常转次品数据
		 * @request GET:/wms/regularization/handleFlowRegularization
		 */
		regularizationHandleFlowRegularization: (params: RequestParams = {}) =>
			this.request<BizResponseObject, any>({
				path: `/wms/regularization/handleFlowRegularization`,
				method: 'GET',
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 次转正
		 * @name RegularizationPage
		 * @summary 次品转正品分页
		 * @request POST:/wms/regularization/page
		 */
		regularizationPage: (
			reqDTO: RegularizationReqDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponseRegularizationPageRespDTO, any>({
				path: `/wms/regularization/page`,
				method: 'POST',
				body: reqDTO,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 次转正
		 * @name RegularizationPageBad
		 * @summary 订单次品处理分页
		 * @request POST:/wms/regularization/pageBad
		 */
		regularizationPageBad: (
			reqDTO: BadPendingReqDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponseBadPendingRespDTO, any>({
				path: `/wms/regularization/pageBad`,
				method: 'POST',
				body: reqDTO,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 次转正
		 * @name RegularizationPendingBadQuantity
		 * @summary 待处理次品数
		 * @request POST:/wms/regularization/pendingBadQuantity
		 */
		regularizationPendingBadQuantity: (
			shopProductSkus: string[],
			params: RequestParams = {}
		) =>
			this.request<BizResponseListPendingBadQuantityRespDTO, any>({
				path: `/wms/regularization/pendingBadQuantity`,
				method: 'POST',
				body: shopProductSkus,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 次转正
		 * @name RegularizationPickUpComplete
		 * @summary 拣货完成
		 * @request GET:/wms/regularization/pickUpComplete
		 */
		regularizationPickUpComplete: (
			query: {
				/** pickTaskCode */
				pickTaskCode: string;
			},
			params: RequestParams = {}
		) =>
			this.request<BizResponseObject, any>({
				path: `/wms/regularization/pickUpComplete`,
				method: 'GET',
				query: query,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 次转正
		 * @name RegularizationPrintBatch
		 * @summary 打印批次
		 * @request GET:/wms/regularization/printBatch
		 */
		regularizationPrintBatch: (
			query: {
				/** newPkgCode */
				newPkgCode: string;
			},
			params: RequestParams = {}
		) =>
			this.request<BizResponseObject, any>({
				path: `/wms/regularization/printBatch`,
				method: 'GET',
				query: query,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 次转正操作
		 * @name RegularizationOperationGetOperationDetail
		 * @summary 获取操作详细
		 * @request GET:/wms/regularizationOperation/getOperationDetail
		 */
		regularizationOperationGetOperationDetail: (
			query: {
				/** regularizationCode */
				regularizationCode: string;
			},
			params: RequestParams = {}
		) =>
			this.request<BizResponseListRegularizationOperationVO, any>({
				path: `/wms/regularizationOperation/getOperationDetail`,
				method: 'GET',
				query: query,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 结算操作记录
		 * @name SettlementOperationBackRepairAmount
		 * @summary 修复结算金额
		 * @request POST:/wms/settlementOperation/back/repairAmount
		 */
		settlementOperationBackRepairAmount: (params: RequestParams = {}) =>
			this.request<BizResponseObject, any>({
				path: `/wms/settlementOperation/back/repairAmount`,
				method: 'POST',
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 签收记录
		 * @name SignExportLastDay
		 * @summary 导出签收记录--昨日
		 * @request POST:/wms/sign/exportLastDay
		 */
		signExportLastDay: (params: RequestParams = {}) =>
			this.request<BizResponseObject, any>({
				path: `/wms/sign/exportLastDay`,
				method: 'POST',
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 签收记录
		 * @name SignExportLastWeek
		 * @summary 导出签收记录--上周
		 * @request POST:/wms/sign/exportLastWeek
		 */
		signExportLastWeek: (params: RequestParams = {}) =>
			this.request<BizResponseObject, any>({
				path: `/wms/sign/exportLastWeek`,
				method: 'POST',
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 签收记录
		 * @name SignExportSignRecord
		 * @summary 导出签收记录
		 * @request POST:/wms/sign/exportSignRecord
		 */
		signExportSignRecord: (
			dto: SignRecordQueryReqDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponseObject, any>({
				path: `/wms/sign/exportSignRecord`,
				method: 'POST',
				body: dto,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 签收记录
		 * @name SignPdaQueryByExpressNoOrInboundCode
		 * @summary queryByExpressNoOrInboundCodeForPda
		 * @request GET:/wms/sign/pda/queryByExpressNoOrInboundCode
		 */
		signPdaQueryByExpressNoOrInboundCode: (
			query: {
				/** expressNo */
				expressNo: string;
			},
			params: RequestParams = {}
		) =>
			this.request<BizResponseSignDTO, any>({
				path: `/wms/sign/pda/queryByExpressNoOrInboundCode`,
				method: 'GET',
				query: query,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 签收记录
		 * @name SignPdaQueryByExpressNo
		 * @summary pdaQueryByExpressNo
		 * @request GET:/wms/sign/pdaQueryByExpressNo
		 */
		signPdaQueryByExpressNo: (
			query: {
				/** expressNo */
				expressNo: string;
			},
			params: RequestParams = {}
		) =>
			this.request<BizResponseSignDTO, any>({
				path: `/wms/sign/pdaQueryByExpressNo`,
				method: 'GET',
				query: query,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 签收记录
		 * @name SignQueryByExpressNo
		 * @summary queryByExpressNo
		 * @request GET:/wms/sign/queryByExpressNo
		 */
		signQueryByExpressNo: (
			query: {
				/** expressNo */
				expressNo: string;
			},
			params: RequestParams = {}
		) =>
			this.request<BizResponseSignDTO, any>({
				path: `/wms/sign/queryByExpressNo`,
				method: 'GET',
				query: query,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 签收记录
		 * @name SignQueryByExpressNoOrInboundCode
		 * @summary queryByExpressNoOrInboundCode
		 * @request GET:/wms/sign/queryByExpressNoOrInboundCode
		 */
		signQueryByExpressNoOrInboundCode: (
			query: {
				/** expressNo */
				expressNo: string;
			},
			params: RequestParams = {}
		) =>
			this.request<BizResponseSignDTO, any>({
				path: `/wms/sign/queryByExpressNoOrInboundCode`,
				method: 'GET',
				query: query,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 签收记录
		 * @name SignSaveSignRecords
		 * @summary saveSignRecords
		 * @request POST:/wms/sign/saveSignRecords
		 */
		signSaveSignRecords: (
			signRecordList: SignRecord[],
			params: RequestParams = {}
		) =>
			this.request<BizResponseObject, any>({
				path: `/wms/sign/saveSignRecords`,
				method: 'POST',
				body: signRecordList,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 签收记录
		 * @name SignSignRecordPage
		 * @summary signRecordPage
		 * @request POST:/wms/sign/signRecordPage
		 */
		signSignRecordPage: (
			reqDTO: SignRecordQueryReqDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponsePageSignRecord, any>({
				path: `/wms/sign/signRecordPage`,
				method: 'POST',
				body: reqDTO,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags sls-waring-controller
		 * @name SlsWarningTest
		 * @summary slsWarningTest
		 * @request GET:/wms/sls/warning/test
		 */
		slsWarningTest: (params: RequestParams = {}) =>
			this.request<void, any>({
				path: `/wms/sls/warning/test`,
				method: 'GET',
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 商品总库存
		 * @name StockBatchQueryStock
		 * @summary batchQueryStock
		 * @request POST:/wms/stock/batchQueryStock
		 */
		stockBatchQueryStock: (
			shopProductSkus: string[],
			params: RequestParams = {}
		) =>
			this.request<BizResponseListStockRespDTO, any>({
				path: `/wms/stock/batchQueryStock`,
				method: 'POST',
				body: shopProductSkus,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 商品总库存
		 * @name StockBatchQueryStockUse
		 * @summary batchQueryStockUse
		 * @request POST:/wms/stock/batchQueryStockUse
		 */
		stockBatchQueryStockUse: (
			shopProductSkus: string[],
			params: RequestParams = {}
		) =>
			this.request<BizResponseListStockRespDTO, any>({
				path: `/wms/stock/batchQueryStockUse`,
				method: 'POST',
				body: shopProductSkus,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 商品总库存
		 * @name StockFreeze4Oem
		 * @summary freeze4OEM
		 * @request POST:/wms/stock/freeze4OEM
		 */
		stockFreeze4Oem: (dtos: StockReqDTO[], params: RequestParams = {}) =>
			this.request<BizResponseListStockRespDTO, any>({
				path: `/wms/stock/freeze4OEM`,
				method: 'POST',
				body: dtos,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 商品总库存
		 * @name StockFreeze4Stock
		 * @summary freeze4Stock
		 * @request POST:/wms/stock/freeze4Stock
		 */
		stockFreeze4Stock: (dtos: StockReqDTO[], params: RequestParams = {}) =>
			this.request<BizResponseStockFreeze4StockRespDTO, any>({
				path: `/wms/stock/freeze4Stock`,
				method: 'POST',
				body: dtos,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 商品总库存
		 * @name StockFreezeByType
		 * @summary freezeByType
		 * @request POST:/wms/stock/freezeByType
		 */
		stockFreezeByType: (
			dtos: FreezeStockReqDTO[],
			params: RequestParams = {}
		) =>
			this.request<BizResponseObject, any>({
				path: `/wms/stock/freezeByType`,
				method: 'POST',
				body: dtos,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 商品总库存
		 * @name StockFreezeSwitch
		 * @summary freezeSwitch
		 * @request POST:/wms/stock/freezeSwitch
		 */
		stockFreezeSwitch: (dtos: StockReqDTO[], params: RequestParams = {}) =>
			this.request<BizResponseListStockRespDTO, any>({
				path: `/wms/stock/freezeSwitch`,
				method: 'POST',
				body: dtos,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 库存历史记录
		 * @name StockHistoryPage
		 * @summary 库存历史列表
		 * @request POST:/wms/stock/history/page
		 */
		stockHistoryPage: (
			request: StockHistoryPageReq,
			params: RequestParams = {}
		) =>
			this.request<BizResponsePageResultStockHistoryPageResp, any>({
				path: `/wms/stock/history/page`,
				method: 'POST',
				body: request,
				type: ContentType.Json,
				...params
			}),

		/**
		 * @description 导入辅料商品库存信息
		 *
		 * @tags 商品总库存
		 * @name StockImportAuxiliaryStockInfo
		 * @summary 导入辅料商品库存信息
		 * @request POST:/wms/stock/importAuxiliaryStockInfo
		 */
		stockImportAuxiliaryStockInfo: (
			file: any,
			params: RequestParams = {}
		) =>
			this.request<BizResponseListImportStockReqDTO, any>({
				path: `/wms/stock/importAuxiliaryStockInfo`,
				method: 'POST',
				body: file,
				type: ContentType.FormData,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 商品总库存
		 * @name StockInitialOem
		 * @summary initialOEM
		 * @request POST:/wms/stock/initialOEM
		 */
		stockInitialOem: (
			dtos: StockInitialReqDTO[],
			params: RequestParams = {}
		) =>
			this.request<BizResponseObject, any>({
				path: `/wms/stock/initialOEM`,
				method: 'POST',
				body: dtos,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 商品总库存
		 * @name StockMyStock
		 * @summary myStock
		 * @request POST:/wms/stock/myStock
		 */
		stockMyStock: (
			query: {
				/**
				 * customerShopId
				 * @format int64
				 */
				customerShopId: number;
			},
			params: RequestParams = {}
		) =>
			this.request<BizResponseMyStockDTO, any>({
				path: `/wms/stock/myStock`,
				method: 'POST',
				query: query,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 商品总库存
		 * @name StockQueryStockByType
		 * @summary queryStockByType
		 * @request POST:/wms/stock/queryStockByType
		 */
		stockQueryStockByType: (
			shopProductSkus: string[],
			params: RequestParams = {}
		) =>
			this.request<BizResponseListAvailableStockRespDTO, any>({
				path: `/wms/stock/queryStockByType`,
				method: 'POST',
				body: shopProductSkus,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 商品总库存
		 * @name StockStockBatchAllocate
		 * @summary stockBatchAllocate
		 * @request POST:/wms/stock/stockBatchAllocate
		 */
		stockStockBatchAllocate: (
			requests: StockBatchAllocateReqDTO[],
			params: RequestParams = {}
		) =>
			this.request<BizResponseListStockBatchAllocateRespDTO, any>({
				path: `/wms/stock/stockBatchAllocate`,
				method: 'POST',
				body: requests,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 商品总库存
		 * @name StockStockDeduc
		 * @summary stockDeduc
		 * @request POST:/wms/stock/stockDeduc
		 */
		stockStockDeduc: (dtos: StockReqDTO[], params: RequestParams = {}) =>
			this.request<BizResponseObject, any>({
				path: `/wms/stock/stockDeduc`,
				method: 'POST',
				body: dtos,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 商品总库存
		 * @name StockStockFreeze
		 * @summary stockFreeze
		 * @request POST:/wms/stock/stockFreeze
		 */
		stockStockFreeze: (dtos: StockReqDTO[], params: RequestParams = {}) =>
			this.request<BizResponseListStockReqDTO, any>({
				path: `/wms/stock/stockFreeze`,
				method: 'POST',
				body: dtos,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 商品总库存
		 * @name StockStockOperation
		 * @summary stockOperation
		 * @request POST:/wms/stock/stockOperation
		 */
		stockStockOperation: (
			pageReqDTO: StockOperationPageReqDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponseIPageStockOperation, any>({
				path: `/wms/stock/stockOperation`,
				method: 'POST',
				body: pageReqDTO,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 商品总库存
		 * @name StockStockPage
		 * @summary 仓库库存
		 * @request POST:/wms/stock/stockPage
		 */
		stockStockPage: (
			pageReqDTO: StockPageReqDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponseIPageStock, any>({
				path: `/wms/stock/stockPage`,
				method: 'POST',
				body: pageReqDTO,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 入库操作
		 * @name StockInDoStockIn
		 * @summary 简单检品界面【入库,异常给采购,异常给用户】--历史版本--废弃
		 * @request POST:/wms/stockIn/doStockIn
		 */
		stockInDoStockIn: (reqDTO: StockInReqDTO, params: RequestParams = {}) =>
			this.request<BizResponseListPkg, any>({
				path: `/wms/stockIn/doStockIn`,
				method: 'POST',
				body: reqDTO,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 入库操作
		 * @name StockInDoStockInWithInboundCode
		 * @summary 简单检品界面【入库,异常给采购,异常给用户】--新入库单版本
		 * @request POST:/wms/stockIn/doStockInWithInboundCode
		 */
		stockInDoStockInWithInboundCode: (
			reqDTO: StockInReqDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponseListPkg, any>({
				path: `/wms/stockIn/doStockInWithInboundCode`,
				method: 'POST',
				body: reqDTO,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 入库操作
		 * @name StockInLessPart
		 * @summary lessPart
		 * @request POST:/wms/stockIn/less-part
		 */
		stockInLessPart: (dto: StockInLessDTO, params: RequestParams = {}) =>
			this.request<BizResponseObject, any>({
				path: `/wms/stockIn/less-part`,
				method: 'POST',
				body: dto,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 入库操作
		 * @name StockInQueryLogisticsInfo
		 * @summary 查询物流包裹信息
		 * @request GET:/wms/stockIn/queryLogisticsInfo
		 */
		stockInQueryLogisticsInfo: (
			query: {
				/** inboundOrderCode */
				inboundOrderCode: string;
			},
			params: RequestParams = {}
		) =>
			this.request<BizResponseListLogisticsSignDto, any>({
				path: `/wms/stockIn/queryLogisticsInfo`,
				method: 'GET',
				query: query,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 入库操作
		 * @name StockInQueryPurchaseInfo
		 * @summary 简单检品查询采购信息--历史版本--废弃
		 * @request GET:/wms/stockIn/queryPurchaseInfo
		 */
		stockInQueryPurchaseInfo: (
			query: {
				/** expressNo */
				expressNo: string;
			},
			params: RequestParams = {}
		) =>
			this.request<BizResponsePurchaseDto, any>({
				path: `/wms/stockIn/queryPurchaseInfo`,
				method: 'GET',
				query: query,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 入库操作
		 * @name StockInQueryWithInboundCode
		 * @summary 简单检品查询采购信息--新入库单版本
		 * @request GET:/wms/stockIn/queryWithInboundCode
		 */
		stockInQueryWithInboundCode: (
			query: {
				/** expressNo */
				expressNo: string;
			},
			params: RequestParams = {}
		) =>
			this.request<BizResponsePurchaseDto, any>({
				path: `/wms/stockIn/queryWithInboundCode`,
				method: 'GET',
				query: query,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags test-controller
		 * @name TestDelilveryTaskInvoice
		 * @summary testExcel
		 * @request GET:/wms/test/delilvery-task/invoice
		 */
		testDelilveryTaskInvoice: (params: RequestParams = {}) =>
			this.request<void, any>({
				path: `/wms/test/delilvery-task/invoice`,
				method: 'GET',
				...params
			}),

		/**
		 * No description
		 *
		 * @tags test-controller
		 * @name TestDeliveryreviewConfirm
		 * @summary deliveryreviewConfirm
		 * @request POST:/wms/test/deliveryreview/confirm
		 */
		testDeliveryreviewConfirm: (body: string, params: RequestParams = {}) =>
			this.request<void, any>({
				path: `/wms/test/deliveryreview/confirm`,
				method: 'POST',
				body: body,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags test-controller
		 * @name TestGetForwardByCountryCode
		 * @summary getForwardByCountryCode
		 * @request POST:/wms/test/getForwardByCountryCode
		 */
		testGetForwardByCountryCode: (
			body: string,
			params: RequestParams = {}
		) =>
			this.request<string, any>({
				path: `/wms/test/getForwardByCountryCode`,
				method: 'POST',
				body: body,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags test-controller
		 * @name TestInboundCreate
		 * @summary queryPurchaseInfo
		 * @request POST:/wms/test/inbound/create
		 */
		testInboundCreate: (body: number, params: RequestParams = {}) =>
			this.request<void, any>({
				path: `/wms/test/inbound/create`,
				method: 'POST',
				body: body,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags test-controller
		 * @name TestInboundFlowMoreInbound
		 * @summary moreInboundCreate
		 * @request POST:/wms/test/inbound/flow/more-inbound
		 */
		testInboundFlowMoreInbound: (
			body: string,
			params: RequestParams = {}
		) =>
			this.request<void, any>({
				path: `/wms/test/inbound/flow/more-inbound`,
				method: 'POST',
				body: body,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags test-controller
		 * @name TestInboundFlowReturn
		 * @summary flowReturn
		 * @request POST:/wms/test/inbound/flow/return
		 */
		testInboundFlowReturn: (body: string, params: RequestParams = {}) =>
			this.request<void, any>({
				path: `/wms/test/inbound/flow/return`,
				method: 'POST',
				body: body,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags test-controller
		 * @name TestInboundItemGetOrderCountry
		 * @summary getOrderCountry
		 * @request POST:/wms/test/inbound/item/getOrderCountry
		 */
		testInboundItemGetOrderCountry: (
			body: string,
			params: RequestParams = {}
		) =>
			this.request<string, any>({
				path: `/wms/test/inbound/item/getOrderCountry`,
				method: 'POST',
				body: body,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags test-controller
		 * @name TestPurchaseSyncOem
		 * @summary syncOemToPurchase
		 * @request POST:/wms/test/purchase/sync-oem
		 */
		testPurchaseSyncOem: (
			query: {
				/** purchaseNo */
				purchaseNo: string;
			},
			params: RequestParams = {}
		) =>
			this.request<boolean, any>({
				path: `/wms/test/purchase/sync-oem`,
				method: 'POST',
				query: query,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags test-controller
		 * @name TestSpiltOrder
		 * @summary spiltDetail
		 * @request POST:/wms/test/spilt/order
		 */
		testSpiltOrder: (
			deliveryReviewCode: string,
			params: RequestParams = {}
		) =>
			this.request<void, any>({
				path: `/wms/test/spilt/order`,
				method: 'POST',
				body: deliveryReviewCode,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags test-controller
		 * @name TestSyncAbnormalOrder
		 * @summary syncAbnormalOrder
		 * @request POST:/wms/test/sync/abnormal/order
		 */
		testSyncAbnormalOrder: (body: string, params: RequestParams = {}) =>
			this.request<void, any>({
				path: `/wms/test/sync/abnormal/order`,
				method: 'POST',
				body: body,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags test-controller
		 * @name TestSyncInitforward
		 * @summary initforward
		 * @request POST:/wms/test/sync/initforward
		 */
		testSyncInitforward: (id: number, params: RequestParams = {}) =>
			this.request<string, any>({
				path: `/wms/test/sync/initforward`,
				method: 'POST',
				body: id,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 库区调拨
		 * @name TransferDoConfirm
		 * @summary 移库确认
		 * @request POST:/wms/transfer/doConfirm
		 */
		transferDoConfirm: (transfer: Transfer, params: RequestParams = {}) =>
			this.request<BizResponseObject, any>({
				path: `/wms/transfer/doConfirm`,
				method: 'POST',
				body: transfer,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 库区调拨
		 * @name TransferDoTransfer
		 * @summary 移库
		 * @request POST:/wms/transfer/doTransfer
		 */
		transferDoTransfer: (
			items: TransferItemReqDTO[],
			params: RequestParams = {}
		) =>
			this.request<BizResponseObject, any>({
				path: `/wms/transfer/doTransfer`,
				method: 'POST',
				body: items,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 库区调拨
		 * @name TransferExportLastDay
		 * @summary 导出移库台账--昨日
		 * @request POST:/wms/transfer/exportLastDay
		 */
		transferExportLastDay: (params: RequestParams = {}) =>
			this.request<BizResponseObject, any>({
				path: `/wms/transfer/exportLastDay`,
				method: 'POST',
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 库区调拨
		 * @name TransferExportLastWeek
		 * @summary 导出移库台账--上周
		 * @request POST:/wms/transfer/exportLastWeek
		 */
		transferExportLastWeek: (params: RequestParams = {}) =>
			this.request<BizResponseObject, any>({
				path: `/wms/transfer/exportLastWeek`,
				method: 'POST',
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 库区调拨
		 * @name TransferExportTransfer
		 * @summary 导出移库台账
		 * @request POST:/wms/transfer/exportTransfer
		 */
		transferExportTransfer: (
			dto: TransferPageDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponseObject, any>({
				path: `/wms/transfer/exportTransfer`,
				method: 'POST',
				body: dto,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 库区调拨
		 * @name TransferGetTransfer
		 * @summary getTransfer
		 * @request GET:/wms/transfer/getTransfer
		 */
		transferGetTransfer: (
			query: {
				/** pkgCode */
				pkgCode: string;
			},
			params: RequestParams = {}
		) =>
			this.request<BizResponseTransferRespDTO, any>({
				path: `/wms/transfer/getTransfer`,
				method: 'GET',
				query: query,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 库区调拨
		 * @name TransferGetTransferSpiltPkgs
		 * @summary 查询移库拆包批次包
		 * @request GET:/wms/transfer/getTransferSpiltPkgs
		 */
		transferGetTransferSpiltPkgs: (
			query: {
				/** pkgCode */
				pkgCode: string;
			},
			params: RequestParams = {}
		) =>
			this.request<BizResponseListTransferSpiltRespDTO, any>({
				path: `/wms/transfer/getTransferSpiltPkgs`,
				method: 'GET',
				query: query,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 库区调拨
		 * @name TransferPkgSpilt
		 * @summary 移库批次包拆包
		 * @request POST:/wms/transfer/pkg/spilt
		 */
		transferPkgSpilt: (
			transferPkgSpiltReq: TransferPkgSpiltReqDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponseTransferSpiltRespDTO, any>({
				path: `/wms/transfer/pkg/spilt`,
				method: 'POST',
				body: transferPkgSpiltReq,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 库区调拨
		 * @name TransferQueryPkg4Transfer
		 * @summary queryPkg4Transfer
		 * @request GET:/wms/transfer/queryPkg4Transfer
		 */
		transferQueryPkg4Transfer: (
			query: {
				/** pkgCode */
				pkgCode: string;
			},
			params: RequestParams = {}
		) =>
			this.request<BizResponsePkgDTO, any>({
				path: `/wms/transfer/queryPkg4Transfer`,
				method: 'GET',
				query: query,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 库区调拨
		 * @name TransferQueryPkg4TransferList
		 * @summary queryPkg4TransferList
		 * @request GET:/wms/transfer/queryPkg4TransferList
		 */
		transferQueryPkg4TransferList: (
			query: {
				/** pkgCodeList */
				pkgCodeList: string[];
			},
			params: RequestParams = {}
		) =>
			this.request<BizResponseListPkgDTO, any>({
				path: `/wms/transfer/queryPkg4TransferList`,
				method: 'GET',
				query: query,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 库区调拨
		 * @name TransferTransferPage
		 * @summary transferPage
		 * @request POST:/wms/transfer/transferPage
		 */
		transferTransferPage: (
			dto: TransferPageDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponsePageTransferDTO, any>({
				path: `/wms/transfer/transferPage`,
				method: 'POST',
				body: dto,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 仓库
		 * @name WarehouseCreateWarehouse
		 * @summary createWarehouse
		 * @request POST:/wms/warehouse/createWarehouse
		 */
		warehouseCreateWarehouse: (
			warehouse: Warehouse,
			params: RequestParams = {}
		) =>
			this.request<BizResponseObject, any>({
				path: `/wms/warehouse/createWarehouse`,
				method: 'POST',
				body: warehouse,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 仓库
		 * @name WarehouseSwitchWarehouse
		 * @summary switchWarehouse
		 * @request POST:/wms/warehouse/switchWarehouse
		 */
		warehouseSwitchWarehouse: (
			query: {
				/** wareCode */
				wareCode: string;
			},
			params: RequestParams = {}
		) =>
			this.request<BizResponseObject, any>({
				path: `/wms/warehouse/switchWarehouse`,
				method: 'POST',
				query: query,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 仓库
		 * @name WarehouseUpdWarehouse
		 * @summary updWarehouse
		 * @request POST:/wms/warehouse/updWarehouse
		 */
		warehouseUpdWarehouse: (
			warehouse: Warehouse,
			params: RequestParams = {}
		) =>
			this.request<BizResponseObject, any>({
				path: `/wms/warehouse/updWarehouse`,
				method: 'POST',
				body: warehouse,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 仓库
		 * @name WarehouseWarehouseList
		 * @summary warehouseList
		 * @request GET:/wms/warehouse/warehouseList
		 */
		warehouseWarehouseList: (params: RequestParams = {}) =>
			this.request<BizResponseListWarehouseRespDto, any>({
				path: `/wms/warehouse/warehouseList`,
				method: 'GET',
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 仓库
		 * @name WarehouseWarehouseList4Switch
		 * @summary warehouseList4Switch
		 * @request GET:/wms/warehouse/warehouseList4Switch
		 */
		warehouseWarehouseList4Switch: (params: RequestParams = {}) =>
			this.request<BizResponseListWarehouseRespDto, any>({
				path: `/wms/warehouse/warehouseList4Switch`,
				method: 'GET',
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 发货重量费用配置
		 * @name WeightCostConfigAdd
		 * @summary 新增配置
		 * @request POST:/wms/weightCostConfig/add
		 */
		weightCostConfigAdd: (
			reqDTO: AddWeightCostConfigReqDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponseObject, any>({
				path: `/wms/weightCostConfig/add`,
				method: 'POST',
				body: reqDTO,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 发货重量费用配置
		 * @name WeightCostConfigEdit
		 * @summary 编辑配置
		 * @request POST:/wms/weightCostConfig/edit
		 */
		weightCostConfigEdit: (
			reqDTO: EditWeightCostConfigReqDTO,
			params: RequestParams = {}
		) =>
			this.request<BizResponseObject, any>({
				path: `/wms/weightCostConfig/edit`,
				method: 'POST',
				body: reqDTO,
				type: ContentType.Json,
				...params
			}),

		/**
		 * No description
		 *
		 * @tags 发货重量费用配置
		 * @name WeightCostConfigPage
		 * @summary 配置分页
		 * @request POST:/wms/weightCostConfig/page
		 */
		weightCostConfigPage: (
			queryPage: WeightCostConfigPageQuery,
			params: RequestParams = {}
		) =>
			this.request<BizResponsePageWeightCostConfigRespDTO, any>({
				path: `/wms/weightCostConfig/page`,
				method: 'POST',
				body: queryPage,
				type: ContentType.Json,
				...params
			})
	};
}
