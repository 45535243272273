/*
 * @Author: shiguang
 * @Date: 2023-04-28 11:51:00
 * @LastEditors: tianzhitong laotianwy@163.com
 * @LastEditTime: 2024-04-30 11:54:06
 * @Description: interceptors
 */

import { message } from 'antd';
import { api, apiInstanceList } from '@/service';
import { getToken, userInfo } from '@/atom/user';
import { US_INFO } from '@/container/account/components/const';

import Code from '@/i18n/locales/code.json';
import { loginEnv } from '@/const/enum';

export interface User {
	loginInfo: {
		userId: string;
		username: string;
	};
	userInfo: {};
	auth: never[];
	token: string;
}

export enum ENUM_RES_CODE {
	/** token 过期 */
	EXPIRE = '10000000'
}

declare module 'axios' {
	interface AxiosRequestConfig {
		/** post请求仍然带参数 */
		postParams?: boolean;
	}
}

// 错误提示
function wrap() {
	const cache: Record<string, boolean> = {};
	function debounce(fn: any) {
		return (msg: string) => {
			if (cache[msg]) return;
			cache[msg] = true;
			fn(msg);
			setTimeout(() => {
				delete cache[msg];
			}, 1000);
		};
	}
	return debounce;
}
export const errorMsgShow = wrap()(message.error.bind(message));
const env = process.env.REACT_APP_ENV as loginEnv;
/** 根据翻译显示code报错信息 */
const translateByRspCode = (msgCode: string) => {
	/** 后端接口返回code,前端翻译 */
	const stationCodeMapLocal = 'en_GB';
	return (
		Code[stationCodeMapLocal][msgCode] ?? `【${msgCode}】${Code.noMatch}`
	);
};

// 白名单code，不进行提示，进行特殊操作
const whiteCode = ['3006'] as string[];

/** 非0的code 成功返回 */
const whiteSuccessResponseCode = [
	'240300029',
	'240300031',
	'240300032',
	'240300033',
	// 已下架
	'24100008',
	// 黑名单商品
	'24130002'
];

apiInstanceList.forEach((item) => {
	// 请求拦截
	item.instance.instance.interceptors.request.use(function (config) {
		config.headers = config.headers ?? {};
		const isPost = ['POST', 'post'].includes(config.method!);
		const shopId = userInfo.shopId();
		const token = getToken();
		if (token) {
			config.headers['X-AuthToken'] = token;
		}
		config.headers['X-Gray-Tag'] = '20240412-america';
		config.headers['X-Stationcode'] = US_INFO.countryCode;
		config.headers['X-Authshopid'] = shopId;
		config.headers['Time-Zone'] =
			'UTC+' + (0 - new Date().getTimezoneOffset() / 60);
		if (isPost) {
			if (!config.data && config.params) {
				config.data = config.params;
			}
			config.params = {};
			config.headers['Content-Type'] = 'application/json';
		}
		// 使得post带上参数
		if (config.postParams) {
			config.params = config.data;
		}
		return config;
	});

	// 返回拦截
	item.instance.instance.interceptors.response.use(
		function (response) {
			const code = response.data?.code;
			if (response.data?.code === '0' && response.data.success === true) {
				return response;
			}
			if (response.config.responseType === 'blob') {
				return response;
			}

			if (whiteSuccessResponseCode.includes(response.data.code)) {
				return response;
			}

			// debugger;
			const errorMsg = translateByRspCode(response.data.code);
			// TODO：生产记得去除
			if (!whiteCode.includes(response.data.code)) {
				errorMsg && errorMsgShow(errorMsg);
			}
			// TODO：暂时加入如果是商品详情，不成功跳转到首页
			// if (response.request.responseURL.includes('goods/product/detail')) {
			// 	return Promise.resolve(response);
			// }
			// message.error('请求错误：' + response.data.msg);
			return Promise.reject(response.data.msg);
		},
		function (error) {
			// message.error('请求错误：' + String(error));
			return Promise.reject(error);
		}
	);
});
export const request = api;
