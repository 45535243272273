import { useEffect, useState } from 'react';

export const SiteHeaderMask = ({ maskVisibleOnUse }) => {
	const [visible, setVisible] = useState(false);
	const [opacity, setOpacity] = useState(0);
	useEffect(() => {
		if (maskVisibleOnUse) {
			setVisible(true);
			setTimeout(() => {
				setOpacity(0.5);
			}, 10);
			return;
		}
		setOpacity(0);
		setTimeout(() => {
			setVisible(false);
		}, 300);
	}, [maskVisibleOnUse]);
	return (
		visible && (
			<div
				className="fixed h-screen w-screen bg-black-900 z-50 transition duration-200"
				style={{
					opacity
				}}
			/>
		)
	);
};
