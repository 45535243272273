import {
	PayPalScriptProvider,
	PayPalButtons,
	usePayPalScriptReducer
} from '@paypal/react-paypal-js';
import { useSearchParams } from 'react-router-dom';
import { OnApproveData } from '@paypal/paypal-js/types/components/buttons';
import { useRequest } from 'ahooks';
import { CSSProperties, useRef, useState } from 'react';
import { request } from '@/config/request';
import { OrderType, PayContent } from '@/const/enum';
import { US_INFO } from '@/container/account/components/const';

import { PayPalCardPayProps } from '../PayPalCardPay';
export interface ButtonWrapperProps extends PayPalCardPayProps {
	/** 展示旋转亮片 */
	showSpinner?: boolean;
	pendingDom?: React.ReactNode;
}
const ButtonWrapper = ({
	showSpinner,
	onCaptrueEnd,
	memberShip,
	activeItem,
	type,
	amount
}: ButtonWrapperProps) => {
	const payPalOrderId = useRef();
	const [{ isPending }] = usePayPalScriptReducer();
	const pendingDom = <div>{window._$m.t('paypal 加载中')}</div>;
	async function onApprove(data: OnApproveData) {
		console.log(data, 'onApprove');

		try {
			if (type === PayContent.VIP) {
				await request.settlement.membership.orderCreatePaypalApprove({
					payPalOrderId: data.orderID,
					payPalSubscriptionId: payPalOrderId.current
				});
			}
			if (type === PayContent.Recharge) {
				await request.settlement.wallet.orderCapturePaypal({
					payPalOrderId: payPalOrderId.current!,
					creditPaymentOption: false,
					authorizationId: '',
					needValid: true
				} as any);
			}
			onCaptrueEnd?.();
		} catch (error) {
			onCaptrueEnd?.(error);
		}
	}
	const onCancel = () => {
		// palpay支付关闭弹窗，跳到重新支付页面，已在重新支付页面不跳转
		// if (urlParams.get('orderNo')) return;
		// window.location.href = urlAddChannel(`/settlement?orderNo=${orderNo}`);
	};
	console.log(amount, 'PayPalButtons_amount');
	return (
		<div
			style={{
				position: 'relative'
			}}
		>
			{showSpinner && isPending && pendingDom}

			{type === PayContent.VIP && (
				<PayPalButtons
					style={{
						// label: 'checkout',
						layout: 'vertical',
						shape: 'pill',
						height: 40
					}}
					key={activeItem?.membershipTemplatePriceId}
					createSubscription={async () => {
						const res: any =
							await request.settlement.membership.orderCreatePaypal(
								{
									membershipTemplateId:
										activeItem?.membershipTemplateId,
									membershipTemplatePrice:
										memberShip?.membershipTemplatePrice,
									membershipTemplatePriceId:
										activeItem?.membershipTemplatePriceId,
									orderType: OrderType.Immediately
								}
							);
						// setPayPalSubscriptionId(
						// 	res?.data?.payPalSubscriptionId
						// );
						payPalOrderId.current = res.data.payPalSubscriptionId;
						return res.data.payPalSubscriptionId;
					}}
					disabled={false}
					fundingSource="paypal"
					onApprove={onApprove}
					onCancel={onCancel}
				/>
			)}

			{type === PayContent.Recharge && (
				<PayPalButtons
					forceReRender={['key']}
					key={amount}
					style={{
						label: 'checkout',
						layout: 'vertical',
						shape: 'pill',
						height: 40
					}}
					createOrder={async () => {
						const res: any =
							await request.settlement.wallet.orderCreatePaypal({
								originCurrencyCode: US_INFO.currency,
								originRechargeAmount: amount
							});
						// setPayPalOrderId(res.data.payPalOrderId);
						payPalOrderId.current = res.data.payPalOrderId;
						return res.data.payPalOrderId;
					}}
					disabled={!amount}
					fundingSource="paypal"
					onApprove={onApprove}
					onCancel={onCancel}
				/>
			)}
		</div>
	);
};
export interface PayPalPayProps extends PayPalCardPayProps {
	style?: CSSProperties;
}
const PayPalPay = (props: PayPalPayProps) => {
	const { style, onCaptrueEnd, memberShip, activeItem, amount } = props;
	const { data } = useRequest(request.settlement.membership.orderGetClientId);
	const clientId = data?.data as any;
	if (!clientId) return <div>{window._$m.t('paypal 加载中')}</div>;
	console.log(amount, 'PayPalPay_amount');

	return (
		<div style={style}>
			<PayPalScriptProvider
				options={{
					clientId,
					components: 'buttons',
					currency: US_INFO.currency,
					vault: true,
					locale: 'en_US'
				}}
			>
				<ButtonWrapper
					showSpinner={true}
					onCaptrueEnd={onCaptrueEnd}
					type={props.type}
					memberShip={memberShip}
					activeItem={activeItem}
					amount={amount}
				/>
			</PayPalScriptProvider>
		</div>
	);
};
export default PayPalPay;
