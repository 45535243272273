import { request } from '@/config/request';
import { US_INFO } from '@/container/account/components/const';
import { formatMoney } from '@/utils/biz';
import { asyncData } from './_base';
import { getToken, userInfo } from './user';

export const rateInfo = asyncData(
	{
		/** 请求用户信息 */
		get: async (store) => {
			const user = userInfo.getState();
			if (user?.mainCustomerId) {
				const res =
					await request.settlement.exchangeRate.cnyToJpyExchangeRateAfterConfigFloat(
						{
							mainCustomerId: user?.mainCustomerId
						}
					);
				return {
					floatingRate: Number(res.data?.exchangeRate ?? 1),
					floatExchangeRate: Number(res.data?.floatExchangeRate ?? 1),
					rate: res.data?.originalExchangeRate ?? 1
				};
			}
			const res =
				await request.settlement.exchangeRate.getOriginExchangeRate({
					stationCode: US_INFO.countryCode
				});
			return {
				floatingRate: Number(res.data?.exchangeRate ?? 1),
				floatExchangeRate: Number(res.data?.floatExchangeRate ?? 1),
				rate: res.data?.originalExchangeRate ?? 1
			};
		},
		set: async () => {},
		/** 计算金额 */
		calc: (
			{ getState },
			{ rmb = 0, usd = 0 }: { rmb?: number; usd?: number }
		) => {
			const { floatingRate } = getState() || {};
			if (!floatingRate) return 0;
			if (rmb) {
				return formatMoney(Math.floor(rmb / floatingRate).toFixed(2));
			}
			return formatMoney(parseFloat((usd * floatingRate).toFixed(2)));
		},
		/** 获取用户戏 */
		async getRate({ getState, get }) {
			if (getState()?.rate) {
				return getState();
			}
			return get();
		}
	},
	{
		defaultValue: {
			floatingRate: 1,
			floatExchangeRate: 1,
			rate: 1
		}
	}
);
