import { Route } from '@sniff/routeguard';
import { lazy } from 'react';
import { Skeleton } from '@/container/mall/shopcart/components/Skelet';

const cart: Route = {
	path: 'cart',
	id: 'cart',
	component: lazy(() => import('@route/mall/shopcart/index')),
	skeleton: <Skeleton />,
	meta: {
		title: 'Cart',
		authentication: true
	}
};

export default cart;
