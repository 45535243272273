import { subscribe } from '@sniff/routeguard';
import { clone } from '@/utils/base';
import { request } from '@/config/request';
import { US_INFO } from '@/container/account/components/const';
import { asyncData } from '../_base';
import { SkuData, cart } from './cart';

/** 下单设置项 */
export const cartSet = subscribe({
	/** 选择地址id */
	receiveId: null as any,
	/** 选择物流id */
	shippingId: null as any,
	// 选择物流obj
	shipping: null as any,
	// 发货附加项
	deliveryAdds: [] as any[],
	// 当前编辑附加项的商品
	editAddsSkus: [] as SkuData[]
});

/** 默认发货附加项 */
export const defaultDlrAddIds = [247];
export const getDefaultDlrAdds = async () => {
	const res = await request.settlement.customer.additionGetAdditionList({
		productType: 1,
		stationCode: US_INFO.stationCode,
		source: 0,
		systemSource: 1
	});
	return res.data?.deliveryAdditionList?.filter((v) =>
		defaultDlrAddIds.includes(v.additionId ?? -1)
	);
};

/** 重新设置 */
export const settingCart = (
	state: Partial<ReturnType<typeof cartSet>>,
	dontCalc?: boolean
) => {
	cartSet.setState((prev) => clone(prev, state));
	// 重新算价，dontCalc是个临时方案
	if (!dontCalc) {
		if (['deliveryAdds', 'shippingId'].some((v) => v in state))
			cart.verifyBeforeCalc();
	}
};

/** 国际料金表物流选项 */
export const shippings = asyncData(
	async () => {
		const res = await request.settlement.intShipConfig.international();
		return res.data!;
	},
	{
		defaultValue: [],
		manual: true
	}
);
