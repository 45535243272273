import { useCallback, useLayoutEffect, useRef } from 'react';

export default function useEvent<T extends (...params: any[]) => any>(
	handler: T
) {
	const handlerRef = useRef<T | null>(null);

	// In a real implementation, this would run before layout effects
	useLayoutEffect(() => {
		handlerRef.current = handler;
	});

	return useCallback((...args) => {
		// In a real implementation, this would throw if called during render
		const fn = handlerRef.current as T;
		return fn(...args);
	}, []);
}
