import React, { useEffect, useRef } from 'react';
import styles from './style.css';
interface IconProps extends React.SVGProps<SVGSVGElement> {
	size?: string | number;
	width?: string | number;
	height?: string | number;
	spin?: boolean;
	rtl?: boolean;
	color?: string;
	fill?: string;
	stroke?: string;
}

export default function OrderdingdanCofaggkk(props: IconProps) {
	const root = useRef<SVGSVGElement>(null);
	const {
		size = '1em',
		width,
		height,
		spin,
		rtl,
		color,
		fill,
		stroke,
		className,
		...rest
	} = props;
	const _width = width || size;
	const _height = height || size;
	const _stroke = stroke || color;
	const _fill = fill || color;
	useEffect(() => {
		if (!_fill) {
			(root.current as SVGSVGElement)
				?.querySelectorAll('[data-follow-fill]')
				.forEach((item) => {
					item.setAttribute(
						'fill',
						item.getAttribute('data-follow-fill') || ''
					);
				});
		}
		if (!_stroke) {
			(root.current as SVGSVGElement)
				?.querySelectorAll('[data-follow-stroke]')
				.forEach((item) => {
					item.setAttribute(
						'stroke',
						item.getAttribute('data-follow-stroke') || ''
					);
				});
		}
	}, [stroke, color, fill]);
	return (
		<svg
			ref={root}
			width={_width}
			height={_height}
			viewBox="0 0 16 16"
			preserveAspectRatio="xMidYMid meet"
			fill="none"
			role="presentation"
			xmlns="http://www.w3.org/2000/svg"
			className={`${className || ''} ${spin ? styles.spin : ''} ${rtl ? styles.rtl : ''}`.trim()}
			{...rest}
		>
			<g>
				<path
					d="M4.955 2.667A3.321 3.321 0 0 1 8 .667a3.32 3.32 0 0 1 3.045 2H12a2 2 0 0 1 2 2v8.666a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V4.667a2 2 0 0 1 2-2h.955ZM8 2c-.925 0-1.702.635-1.927 1.5a.667.667 0 0 1-.645.5H4a.667.667 0 0 0-.667.667v8.666c0 .368.3.667.667.667h8a.667.667 0 0 0 .667-.667V4.667A.667.667 0 0 0 12 4h-1.428a.667.667 0 0 1-.645-.5A1.994 1.994 0 0 0 8 2Z"
					clipRule="evenodd"
					fillRule="evenodd"
					data-follow-fill="#434343"
					fill={_fill}
				/>
				<path
					d="M4.667 11.333c0-.368.298-.666.667-.666h5.333a.667.667 0 0 1 0 1.333H5.334a.667.667 0 0 1-.667-.667Zm0-4c0-.368.298-.666.667-.666h5.333a.667.667 0 1 1 0 1.333H5.334a.667.667 0 0 1-.667-.667ZM8 3.327c.368 0 .667.298.667.666V4a.667.667 0 0 1-1.333 0v-.007c0-.368.298-.666.666-.666Z"
					clipRule="evenodd"
					fillRule="evenodd"
					data-follow-fill="#434343"
					fill={_fill}
				/>
			</g>
		</svg>
	);
}
