/*
 * @Author: shiguang
 * @Date: 2023-07-04 22:27:10
 * @LastEditors: tianzhitong laotianwy@163.com
 * @LastEditTime: 2024-04-23 16:36:14
 * @Description: Description
 */

import qs from 'query-string';
import { createContext } from 'react';
import extractConfig from '@/i18n/locales/extractConfig.json';
// import jp from '@/i18n/locales/ja_JP.json';
// import kr from './locales/ko_KR.json';
import en from './locales/en_GB.json';
const pkg = require('../../package.json');

/** */
export enum Local {
	ZH = 'zh',
	/** 日文 */
	JA = 'ja',
	/** 韩文 */
	KO = 'ko',
	/** 英文 */
	EN = 'en'
}

/** */
export enum Lang {
	zh_CN = 'zh_CN',

	/** 日文 */
	ja_JP = 'ja_JP',
	/** 韩文 */
	ko_KR = 'ko_KR',
	/** 英文 */
	en_GB = 'en_GB'
}

const getQuerylang = (): Lang => {
	const query = qs.parse(window.location.search) as { lang: Local };

	return (
		{
			[Local.ZH]: Lang.zh_CN,

			// [Local.JA]: Lang.ja_JP,

			// [Local.KO]: Lang.ko_KR,

			[Local.EN]: Lang.en_GB
		}[query.lang] || Lang.en_GB
	);
};

const getOldLocalLang = () => {
	const lang = localStorage.getItem('__lang__') || Local.ZH;
	return (
		{
			[Local.ZH]: Lang.zh_CN,

			[Local.JA]: Lang.ja_JP,

			[Local.KO]: Lang.ko_KR,

			[Local.EN]: Lang.en_GB
		}[lang] || Lang.en_GB
	);
};
export const getLang = () => {
	// const langs = ['zh', 'ja', 'ko', 'en', 'ko', 'th']
	const queryLang = getQuerylang();
	if (queryLang) {
		localStorage.setItem('lang', queryLang);
		const _queryLang = {
			[Lang.zh_CN]: Local.ZH,
			// [Lang.ja_JP]: Local.JA,
			// [Lang.ko_KR]: Local.KO,
			[Lang.en_GB]: Local.EN
		}[queryLang];

		localStorage.setItem('__lang__', _queryLang);
		return queryLang;
	}

	const oldLocalLang = getOldLocalLang();
	if (oldLocalLang) {
		localStorage.setItem('lang', oldLocalLang);
		return oldLocalLang;
	}
	const lang = localStorage.getItem('lang');
	if (lang) {
		return lang;
	}
	localStorage.setItem('lang', navigator.language);
	return navigator.language;
};
const initLang = getLang();

export const LocalContext = createContext(initLang);

window._$m = {
	t: (text: string, options: { data?: Record<string, any> } = {}) => {
		const translateFn = window.g_locales
			? window.g_locales[initLang]
			: window._$m[initLang] ?? {};
		let lastText = ['', undefined].includes(translateFn?.[text] ?? '')
			? text
			: (translateFn[text] as string);
		if (options.data) {
			for (const key in options.data) {
				if (Object.prototype.hasOwnProperty.call(options.data, key)) {
					const value = options.data[key];
					const templateStr = `{{${key}}}`;
					lastText = lastText.replace(templateStr, value as string);
				}
			}
		}
		return lastText;
	},
	// [Lang.ja_JP]: jp,
	// [Lang.ko_KR]: kr,
	[Lang.en_GB]: en
};

(function () {
	if (process.env.NODE_ENV === 'development') return;
	const env = process.env.REACT_APP_ENV ?? 'test';
	fetch(
		`https://static-us.theckb.com/mercury/${env}/${pkg.name}.json?date=${new Date().getTime()}`
	)
		.then((res) => {
			return res.json();
		})
		.then((data) => {
			window.g_locales = data;
			Object.freeze(window.g_locales);
		});
})();

export const getUnTranslateData = (data) => {
	return Object.entries(data).reduce((pre, cur: any) => {
		const [key, config] = cur;
		pre[key] = Object.entries(config).reduce((_pre, _cur) => {
			const [_key, _value] = _cur;
			if ('' === _value) {
				_pre[_key] = '';
			}
			return _pre;
		}, {});
		return pre;
	}, {});
};

export const mergeDataByOtherConfig = (data, otherConfig) => {
	return Object.entries(data).reduce((pre, cur: any) => {
		const [key, config] = cur;
		pre[key] = Object.entries(config).reduce((_pre, _cur) => {
			const [_key, _value] = _cur;
			if (_value === '' && otherConfig[_key]) {
				_pre[_key] = otherConfig[_key];
			} else {
				_pre[_key] = _value;
			}
			return _pre;
		}, {});
		return pre;
	}, {});
};
