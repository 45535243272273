import {
	Button,
	Checkbox,
	ConfigProvider,
	Divider,
	Drawer,
	Radio,
	message
} from 'antd';
import { useEffect, useRef, useState } from 'react';
import { useAsyncEffect, useRequest } from 'ahooks';
import classNames from 'classnames';
import axios from 'axios';
import { request } from '@/config/request';
import {
	ManualWalletRechargeMembershipVO,
	MembershipTemplatePrice,
	MembershipTemplatePriceListVO
} from '@/service/settlement';
import {
	AccountType,
	AutomaticRenewalStatus,
	AutomaticRenewalStytle,
	OrderType,
	PayContent,
	PayList,
	PayType
} from '@/const/enum';
import { Term, US_INFO } from '@/container/account/components/const';
import PayPalPay from '@/common/payment/PayPalPay/Index';
import { rateInfo } from '@/atom/rate';
import { useRateFormatMoney } from '@/hooks/useRateFormatMoney';
import { formatMoney, formatTimeToUs, getPriceTwoList } from '@/utils/biz';
import { useModal } from '@/components/modal';
import { userInfo } from '@/atom/user';
import { usePreLoadImg } from '@/hooks/usePreLoadImg';
import Header from './modules/Header';
import CardSelect, { RefCardSelectProps } from './modules/CardSelect';
import SuccessModal from './modules/SuccessModal';

interface OpenVipDialogProps {
	onSuccess?: () => void;
	close?: () => void;
	activeItem?: MembershipTemplatePrice;
}

const OpenVipDialog = (props: OpenVipDialogProps) => {
	const rate = rateInfo();

	const formatMoneyCny = useRateFormatMoney(true);

	const user = userInfo();
	const shopId = userInfo.shopId();
	const expiresDate = formatTimeToUs(user?.membership?.expiresDate);

	// 会员套餐
	const [combo, setCombo] = useState<MembershipTemplatePriceListVO>();
	// 激活价格item
	const [activeItem, setActiveItem] = useState<
		MembershipTemplatePrice | undefined
	>(props.activeItem);
	// 支付方式选择
	const [payType, setPayType] = useState(PayType.PAYPAL_CARD);
	const [openTitle, setOpenTitle] = useState<string | undefined>();
	// 文件URL
	const [file, setFile] = useState<string>('');
	const selectCardRef = useRef<RefCardSelectProps>(null);
	// 禁用按钮
	const [disbtn, setDisbtn] = useState(false);
	// 是否同意协议
	const [isAgree, setIsAgree] = useState(true);
	// 成功弹窗
	const [
		SuccessModalComp,
		{ open: openSuccessModal, close: closeSuccessModal }
	] = useModal(<SuccessModal onClose={() => window.location.reload()} />, {
		closable: false,
		maskClosable: false,
		width: 480,
		title: null,
		footer: null,
		styles: {
			content: {
				padding: '0',
				borderRadius: '24px'
			}
		},
		cancelButtonProps: { style: { display: 'none' } }
	});
	// 文章列表
	const { runAsync: termGetEffectTermByType } = useRequest(
		request.customer.base.termGetEffectTermByType,
		{
			manual: true
		}
	);
	// 待支付订单参数
	const [payParams, setPayParams] =
		useState<ManualWalletRechargeMembershipVO>();

	const openFileDrawer = async (
		type: Term,
		title: string,
		e: React.MouseEvent<HTMLSpanElement, MouseEvent>
	) => {
		e.preventDefault();
		try {
			const res = await termGetEffectTermByType({
				stationCode: US_INFO.countryCode,
				type
			});
			if (res.success) {
				const fileUrl = res.data?.[0]?.fileUrl || '';
				const res2 = await axios(fileUrl);
				setFile(res2.data.replace(/target=""/g, 'target="_blank"'));
				setOpenTitle(title);
			} else {
				message.error(window._$m.t('查询条款失败'));
			}
		} catch (error) {
			console.log(error);
		}
	};

	useAsyncEffect(async () => {
		const res1 =
			await request.settlement.membership.configureTemplatePriceSelectList();
		if (res1.data?.length) {
			const data = res1.data[0];
			setActiveItem({ ...(props.activeItem || data.priceItemList?.[0]) });
			setCombo(data);
		}
	}, []);

	useEffect(() => {
		if (props.activeItem) {
			setActiveItem(props.activeItem);
		}
	}, [props.activeItem]);

	useEffect(() => {
		setPayParams((item) => {
			return {
				...item,
				membershipTemplate: {
					membershipTemplateId: combo?.membershipTemplateId
				},
				membershipTemplatePrice: activeItem,
				price: activeItem?.discountActualPrice,
				shopId,
				systemSource: US_INFO.systemSource,
				orderType: OrderType.Immediately,
				membershipTrialActivityId:
					combo?.trialFlag ?? combo?.membershipTrialActivityId,
				membershipTrialActivityComboId:
					combo?.trialFlag ?? combo?.membershipTrialActivityComboId
			};
		});
	}, [
		activeItem,
		combo?.discountActualPrice,
		combo?.membershipTemplateId,
		combo?.membershipTrialActivityComboId,
		combo?.membershipTrialActivityId,
		combo?.trialFlag,
		shopId
	]);

	usePreLoadImg([
		'https://static-us.theckb.com/static-asset/icon/icon_success.png'
	]);

	// 支付点击
	const clickPay = async () => {
		if (!isAgree) {
			return message.error(window._$m.t('请勾选同意协议'));
		}
		try {
			if (payType === PayType.BALANCE) {
				const res =
					await request.settlement.wallet.getCustomerWalletList({
						stationCode: US_INFO.countryCode
					} as any);
				const availableAmount =
					res.data?.find((i) => i.accountType === AccountType.SUB)
						?.availableAmount || 0;
				if (
					Number(rateInfo.calc({ usd: payParams?.price ?? 0 })) >
					availableAmount
				) {
					return message.error(window._$m.t('余额不足,请先充值'));
				}
				setDisbtn(true);
				const res1 =
					await request.settlement.wallet.rechargeMembershipManual({
						...payParams
					});
				await request.settlement.wallet.rechargeMembershipAutomaticToggle(
					{
						automaticRenewalStatus: AutomaticRenewalStatus.OPEN,
						automaticRenewalStytle: AutomaticRenewalStytle.BALANCE
					}
				);
				setDisbtn(false);
				props.close && props.close();
				userInfo.update();
				openSuccessModal();
			}
			if (payType === PayType.PAYPAL_CARD) {
				const creditCardId =
					selectCardRef.current?.getSelectedCard()?.creditCardId;
				if (!creditCardId) {
					return message.error(window._$m.t('请选择信用卡'));
				}
				setDisbtn(true);
				try {
					const res =
						await request.settlement.membership.orderCreatePaypalCredit(
							{
								...payParams,
								creditCardId: Number(creditCardId)
							}
						);
					if (res.success) {
						await request.settlement.membership.orderCreatePaypalCreditCapture(
							{
								...payParams,
								authorizationId: res.data?.authorizationId,
								creditCardOrderId: res.data?.orderId
							}
						);
					}
					props.close && props.close();
					openSuccessModal();
					userInfo.update();
					setDisbtn(false);
				} catch (error) {}
			}
		} catch (error) {
			setDisbtn(false);
		}
	};
	const dateUnit = {
		30: 'Monthly',
		90: 'Quarterly',
		360: 'Yearly'
	};

	// const finanlPrice = payParams?.price?.toString().split('.');
	const finanlPrice = getPriceTwoList(payParams?.price?.toString() ?? '0');
	return (
		<div className="w-[1000px] bg-black-0 rounded-[24px] OpenVipDialog">
			<Header userInfo={user} />
			<div className="p-[20px]">
				{/* 价格列表 */}
				<div className="priceList flex justify-between">
					{combo?.priceItemList?.map((i) => {
						const month = ((i.validPeriod ?? 0) / 30).toFixed(0);
						const averagePrice = (
							(i.discountActualPrice ?? 0) / (Number(month) ?? 1)
						).toFixed(2);
						console.log(averagePrice);

						const discountActualPrice = getPriceTwoList(
							String(i.discountActualPrice ?? '0') ?? '0'
						);
						return (
							<div
								className={classNames(
									'p-[20px] cursor-pointer flex-1 mr-[16px] last:mr-0  rounded-[8px] border  flex flex-col text-left text-font-regular',

									activeItem?.membershipTemplatePriceId ===
										i.membershipTemplatePriceId
										? 'border-cyan-9 bg-cyan-1 shadow-c1'
										: 'border-grey-4 '
								)}
								onClick={() => setActiveItem({ ...i })}
								key={i.membershipTemplatePriceId}
							>
								<div
									className={classNames(
										'h-[30px] font-bold  text-h4',

										activeItem?.membershipTemplatePriceId ===
											i.membershipTemplatePriceId
											? 'text-cyan-10'
											: 'text-font-primary'
									)}
								>
									{dateUnit[i.validPeriod ?? 30] ?? 'Monthly'}
								</div>
								<div className="mt-[8px] text-h3 font-bold tracking-[0.72px]">
									<div
										className={classNames(
											'font-bold flex items-start mt-[8px]',
											activeItem?.membershipTemplatePriceId ===
												i.membershipTemplatePriceId
												? 'text-cyan-10'
												: 'text-font-primary'
										)}
									>
										<span className="text-sm leading-[12px]">
											$
										</span>
										<span className="text-[40px] leading-[42px]  tracking-[1.2px]">
											{discountActualPrice[0]}
										</span>
										<span className="text-sm leading-[12px]">
											{discountActualPrice[1]}
										</span>
									</div>
								</div>
								{Number(month) > 0 && (
									<div className="text-cyan-9 text-base">
										{window._$m.t('折扣后价格')}

										{formatMoney(averagePrice ?? 0)}
										{window._$m.t('美金/月')}
									</div>
								)}
							</div>
						);
					})}
				</div>
				<Divider />
				<div>
					<div className="text-font-primary font-bold  text-lg">
						{window._$m.t('支付方式')}
					</div>
					<div className="text-font-Secondary mb-[16px] text-base">
						{window._$m.t(
							'购买会员到期后我们会为您自动续费，您可在工作台-会员管理中关闭自动续费服'
						)}
					</div>
				</div>
				<Radio.Group
					className="block"
					value={payType}
					onChange={(e) => setPayType(e.target.value)}
				>
					<div className="flex  justify-between">
						{PayList.map((i, idx) => {
							return (
								<Radio
									key={i.type}
									className={classNames(
										'flex-1 flex    border border-gray-4 p-[16px] rounded-[8px]',
										{
											'border-cyan-9 bg-cyan-1 shadow-c1':
												i.type === payType
										},
										(idx + 1) % 3 === 0
											? 'mr-[0px]'
											: 'mr-[16px]'
									)}
									value={i.type}
								>
									<div
										className={classNames(
											'text-h4 text-font-primary',
											i.type === payType
												? 'font-bold'
												: 'font-medium'
										)}
									>
										{i.name}
									</div>
								</Radio>
							);
						})}
					</div>
				</Radio.Group>
				{payType === PayType.PAYPAL_CARD && (
					<CardSelect
						onSuccess={() => {
							openSuccessModal();
							props.onSuccess && props.onSuccess();
						}}
						ref={selectCardRef}
						type={PayContent.VIP}
						memberShip={payParams}
						activeItem={activeItem}
					/>
				)}

				<Divider />
				<div className="flex-col flex  font-bold">
					<div className="text-right">
						<span className="font-bold text-base text-font-Title mr-[4px]">
							{window._$m.t('总计')}
						</span>
						<div className="inline-block text-red-6">
							<span className="text-sm">$</span>
							<span className="font-bold text-h3">
								{finanlPrice[0]}
							</span>
							<span className="text-sm">.{finanlPrice[1]}</span>
						</div>
						<span className="text-font-Secondary text-sm font-bold ml-[4px]">
							CNY{formatMoneyCny(payParams?.price || 0)}
						</span>
					</div>
					<div className="text-right">
						<span className="text-font-regular mr-[4px]">
							{window._$m.t('汇率')}:
						</span>
						<span className="text-font-primary">
							$1≈CNY{rate.floatingRate}
						</span>
					</div>

					<div className="flex items-center justify-between mt-[20px]">
						<div>
							<ConfigProvider
								theme={{
									token: {
										colorPrimary: '#199A97'
									}
								}}
							>
								<Checkbox
									checked={isAgree}
									onChange={(e) =>
										setIsAgree(e.target.checked)
									}
								>
									{window._$m.t('请阅读')}
									<span
										className="underline  px-[3px]"
										onClick={(e) =>
											openFileDrawer(
												Term.utilize,
												window._$m.t('用户协议'),
												e
											)
										}
									>
										{window._$m.t('用户协议')}
									</span>
									{window._$m.t('和')}

									<span
										className="underline px-[3px]"
										onClick={(e) =>
											openFileDrawer(
												Term.privacy,
												window._$m.t('隐私协议'),
												e
											)
										}
									>
										{window._$m.t('隐私协议')}
									</span>
								</Checkbox>
							</ConfigProvider>
						</div>
						{payType !== PayType.PAYPAL ? (
							<Button
								shape="round"
								size="large"
								type="primary"
								onClick={clickPay}
								disabled={disbtn}
							>
								{window._$m.t('确认协议并立即支付')}
							</Button>
						) : (
							<div
								className="cursor-pointer"
								onClick={(e) => {
									if (!isAgree) {
										return message.error(
											window._$m.t('请勾选同意协议')
										);
									}
								}}
							>
								<div
									className={classNames({
										'pointer-events-none': !isAgree
									})}
								>
									<PayPalPay
										type={PayContent.VIP}
										activeItem={activeItem}
										memberShip={payParams}
										onCaptrueEnd={(err) => {
											if (!err) {
												openSuccessModal();
												userInfo.update();
												props.onSuccess &&
													props.onSuccess();
											}
										}}
									/>
								</div>
							</div>
						)}
					</div>
				</div>
			</div>
			{SuccessModalComp}
			<Drawer
				placement="bottom"
				height={'100%'}
				title={openTitle}
				styles={{
					content: {
						width: 'auto',
						margin: '20px',
						padding: '20px',
						paddingTop: '0',
						borderTopLeftRadius: '10px',
						borderTopRightRadius: '10px'
					}
				}}
				footer={null}
				open={!!openTitle}
				onClose={() => setOpenTitle(undefined)}
			>
				<div dangerouslySetInnerHTML={{ __html: file }} />
			</Drawer>
		</div>
	);
};

export default OpenVipDialog;
