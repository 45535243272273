import React, { useEffect, useRef } from 'react';
import styles from './style.css';
interface IconProps extends React.SVGProps<SVGSVGElement> {
	size?: string | number;
	width?: string | number;
	height?: string | number;
	spin?: boolean;
	rtl?: boolean;
	color?: string;
	fill?: string;
	stroke?: string;
}

export default function Discover(props: IconProps) {
	const root = useRef<SVGSVGElement>(null);
	const {
		size = '1em',
		width,
		height,
		spin,
		rtl,
		color,
		fill,
		stroke,
		className,
		...rest
	} = props;
	const _width = width || size;
	const _height = height || size;
	const _stroke = stroke || color;
	const _fill = fill || color;
	useEffect(() => {
		if (!_fill) {
			(root.current as SVGSVGElement)
				?.querySelectorAll('[data-follow-fill]')
				.forEach((item) => {
					item.setAttribute(
						'fill',
						item.getAttribute('data-follow-fill') || ''
					);
				});
		}
		if (!_stroke) {
			(root.current as SVGSVGElement)
				?.querySelectorAll('[data-follow-stroke]')
				.forEach((item) => {
					item.setAttribute(
						'stroke',
						item.getAttribute('data-follow-stroke') || ''
					);
				});
		}
	}, [stroke, color, fill]);
	return (
		<svg
			ref={root}
			width={_width}
			height={_height}
			viewBox="0 0 40 40"
			preserveAspectRatio="xMidYMid meet"
			fill="none"
			role="presentation"
			xmlns="http://www.w3.org/2000/svg"
			className={`${className || ''} ${spin ? styles.spin : ''} ${rtl ? styles.rtl : ''}`.trim()}
			{...rest}
		>
			<g>
				<g clipPath="url(#11e7dd__a)">
					<path
						fill="#fff"
						d="M37.627 7.594H2.335A2.354 2.354 0 0 0-.019 9.947v20.149c0 1.3 1.054 2.353 2.354 2.353h35.292c1.3 0 2.353-1.053 2.353-2.353V9.947c0-1.3-1.053-2.353-2.353-2.353Z"
					/>
					<path
						fill="#EE7D00"
						d="M39.98 21.609c-.515.348-11.305 7.526-30.2 10.84h27.846c1.3 0 2.354-1.053 2.354-2.353v-8.487Z"
						clipRule="evenodd"
						fillRule="evenodd"
					/>
					<path
						fill="url(#11e7dd__b)"
						d="M21.255 13.842c-1.604 0-2.905 1.253-2.905 2.8 0 1.643 1.244 2.872 2.904 2.872 1.62 0 2.897-1.245 2.897-2.84 0-1.587-1.27-2.832-2.896-2.832Z"
					/>
					<path
						fill="#05171D"
						d="M4.763 13.944H3.2v5.447h1.554c.824 0 1.42-.195 1.943-.628a2.74 2.74 0 0 0 .988-2.093c0-1.608-1.2-2.726-2.923-2.726Zm1.242 4.091c-.336.303-.768.434-1.455.434h-.286v-3.603h.286c.687 0 1.102.123 1.455.441.367.327.586.833.586 1.355 0 .524-.219 1.048-.586 1.373Zm3.233-4.091H8.177v5.447h1.061v-5.447Zm2.599 2.09c-.638-.237-.826-.393-.826-.686 0-.343.334-.604.792-.604.319 0 .58.13.859.44l.554-.726a2.38 2.38 0 0 0-1.6-.605c-.964 0-1.7.67-1.7 1.56 0 .752.344 1.135 1.34 1.496.417.146.629.243.735.31a.651.651 0 0 1 .32.563c0 .441-.352.768-.827.768-.506 0-.914-.253-1.16-.727l-.684.662c.489.718 1.077 1.038 1.886 1.038 1.103 0 1.878-.736 1.878-1.79 0-.866-.358-1.258-1.567-1.7Zm1.903.636c0 1.603 1.257 2.844 2.875 2.844.458 0 .85-.09 1.332-.318v-1.25c-.425.424-.801.596-1.283.596-1.07 0-1.83-.777-1.83-1.88 0-1.044.784-1.87 1.781-1.87.506 0 .89.18 1.332.613v-1.25c-.466-.237-.85-.334-1.308-.334-1.61 0-2.9 1.266-2.9 2.85Zm12.814.933L25.1 13.944h-1.16l2.313 5.586h.571l2.353-5.586h-1.151l-1.472 3.659Zm3.106 1.788h3.014v-.922h-1.952v-1.471h1.877v-.923h-1.877v-1.208h1.952v-.923H29.66v5.447Zm7.219-3.839c0-1.02-.701-1.608-1.927-1.608h-1.577v5.447h1.063V17.2h.139l1.47 2.19h1.306l-1.716-2.294c.802-.164 1.242-.712 1.242-1.545Zm-2.132.9h-.309V14.8h.326c.663 0 1.022.278 1.022.808 0 .547-.359.842-1.039.842Z"
					/>
					<path
						fill="#1D1D1B"
						d="M37.628 14.35c0-.096-.063-.147-.18-.147h-.155v.48h.114v-.187l.134.186h.142l-.16-.198a.132.132 0 0 0 .105-.134Zm-.2.065h-.021v-.125h.022c.057 0 .085.02.085.062 0 .042-.03.063-.086.063Z"
					/>
					<path
						fill="#1D1D1B"
						d="M37.47 14.022a.419.419 0 1 0 0 .838.418.418 0 0 0 .414-.419.418.418 0 0 0-.413-.419Zm0 .763a.339.339 0 0 1-.336-.343c0-.191.149-.344.336-.344.18 0 .33.155.33.344 0 .189-.15.343-.33.343Zm-24.746 6.038 1.42 1.488v-1.41h.204v1.903l-1.421-1.485v1.404h-.203v-1.9Z"
					/>
					<path
						fill="#1D1D1B"
						d="M12.704 20.823v1.919h.241v-1.376l1.42 1.486v-1.97h-.24v1.382l-1.42-1.49v.049Zm.038.047 1.422 1.49v-1.441h.164v1.84l-1.42-1.488v1.433h-.166V20.87Zm2.59.03h.944v.189h-.742v.542h.72v.188h-.72v.715h.742v.189h-.944V20.9Z"
					/>
					<path
						fill="#1D1D1B"
						d="M16.276 20.881h-.963v1.861h.982v-.227h-.741v-.678h.72v-.225h-.72v-.503h.742v-.228h-.02Zm-.019.038v.15h-.742v.58h.72v.15h-.72v.754h.742v.15h-.907V20.92h.907Zm1.453 1.804h-.203v-1.634h-.44V20.9h1.088v.189h-.445v1.634Z"
					/>
					<path
						fill="#1D1D1B"
						d="M18.156 20.881h-1.108v.228h.44v1.633h.241v-1.633h.445v-.228h-.018Zm-.02.038v.15h-.444v1.635h-.165v-1.635h-.44v-.15h1.05Zm1.377 1.347.59-1.46.59 1.46.478-1.366h.221l-.7 1.917-.589-1.468-.59 1.468-.698-1.917h.22l.478 1.366Z"
					/>
					<path
						fill="#1D1D1B"
						d="m20.086 20.799-.571 1.413-.467-1.33h-.26l.726 1.99.59-1.472.59 1.471.725-1.99h-.26l-.467 1.331-.588-1.457-.018.044Zm-.555 1.474.573-1.416.59 1.463.491-1.401h.18l-.673 1.845-.588-1.465-.59 1.465-.671-1.845h.178c.01.025.491 1.4.491 1.4l.02-.046Zm4.486-.459c0 .524-.431.94-.95.94a.945.945 0 0 1-.95-.94.95.95 0 0 1 1.9 0Zm-1.697-.003c0 .41.333.754.747.754a.753.753 0 0 0 .746-.754.749.749 0 0 0-.746-.754.75.75 0 0 0-.747.754Z"
					/>
					<path
						fill="#1D1D1B"
						d="M22.098 21.814c0 .528.434.959.969.959.534 0 .969-.43.969-.96a.967.967 0 0 0-.97-.963.967.967 0 0 0-.968.964Zm.037 0a.93.93 0 0 1 .932-.926.93.93 0 0 1 .931.926.928.928 0 0 1-.931.921.928.928 0 0 1-.932-.921Z"
					/>
					<path
						fill="#1D1D1B"
						d="M22.3 21.811a.77.77 0 0 0 .766.773.769.769 0 0 0 .765-.773.77.77 0 0 0-.765-.773.77.77 0 0 0-.766.773Zm.038 0c0-.405.327-.735.728-.735.402 0 .727.33.727.735a.732.732 0 0 1-.727.736.733.733 0 0 1-.728-.736Zm2.762.912h-.203V20.9h.264c.345 0 .666.093.666.503 0 .28-.175.479-.456.503l.58.817h-.25l-.55-.8H25.1v.8Zm0-.98h.063c.23 0 .462-.042.462-.326 0-.299-.22-.328-.464-.328H25.1v.655Z"
					/>
					<path
						fill="#1D1D1B"
						d="M24.897 20.881h-.02v1.861h.242v-.8h.031c0 .013.536.791.536.791l.264.009h.038l-.586-.825a.492.492 0 0 0 .444-.514c0-.352-.224-.522-.686-.522h-.263Zm.263.038c.442 0 .648.154.648.484 0 .27-.172.462-.44.484l-.033.003.579.814H25.7c0-.014-.535-.792-.535-.792l-.067-.009h-.019v.8h-.164v-1.785h.244Z"
					/>
					<path
						fill="#1D1D1B"
						d="M25.1 21.07h-.02v.692h.082c.224 0 .48-.04.48-.345 0-.321-.248-.348-.482-.348h-.06Zm.06.039c.234 0 .445.022.445.308 0 .27-.224.307-.443.307h-.044v-.615h.042Zm2.63-.209h.273l-.855.85.884.973h-.28l-.748-.836-.05.05v.786h-.203V20.9h.203v.78l.776-.78Z"
					/>
					<path
						fill="#1D1D1B"
						d="M28.064 20.881h-.28s-.692.694-.75.755v-.755h-.241v1.861h.24v-.803a.153.153 0 0 0 .03-.025c.026.027.735.822.735.822l.295.006h.043l-.903-.992.876-.869h-.045Zm-.047.038-.835.83.868.955h-.238c0-.01-.733-.83-.733-.83l-.013-.016-.065.065-.006.78h-.164V20.92h.165v.808l.802-.808h.22Z"
					/>
				</g>
				<defs>
					<radialGradient
						gradientTransform="translate(21.25 16.678) scale(2.86857)"
						gradientUnits="userSpaceOnUse"
						r="1"
						cy="0"
						cx="0"
						id="11e7dd__b"
					>
						<stop stopColor="#FFF2E8" offset=".006" />
						<stop stopColor="#FEEDDF" offset=".096" />
						<stop stopColor="#FDE1C7" offset=".244" />
						<stop stopColor="#FACD9F" offset=".43" />
						<stop stopColor="#F6B168" offset=".648" />
						<stop stopColor="#F08E22" offset=".89" />
						<stop stopColor="#EE7D00" offset="1" />
					</radialGradient>
					<clipPath id="11e7dd__a">
						<path fill="#fff" d="M0 0h40v40H0z" />
					</clipPath>
				</defs>
			</g>
		</svg>
	);
}
