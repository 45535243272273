/*
 * @Author: shiguang
 * @Date: 2023-04-25 10:46:45
 * @LastEditors: liuliangliang liuliangliang@sniffgroup.com
 * @LastEditTime: 2024-05-20 10:25:14
 * @Description: App
 */
import { ConfigProvider, Spin, ThemeConfig } from 'antd';
import createRouter, { toPath } from '@sniff/routeguard';
import { none } from 'ramda';
import { useEffect } from 'react';
import routes from './config/router';
import { userInfo } from './atom/user';
import { useGetSystemRate } from './hooks/useGetRate';
import '@snifftest/flag-icons/css/flag-icons.min.css';

// 路由
const Router = createRouter(routes, {
	skeleton: <Spin spinning fullscreen tip="loading..." />,
	// 向上继承的属性
	inherit: ['skeleton', 'meta.authentication']
});

Router.before(async (to, from) => {
	console.log(to.meta);
	// 获取用户
	const asyncUser = userInfo.getUserInfo();
	const { authentication = null } = to.meta ?? {};
	if (authentication !== null) {
		// todo:跳转提示
		const user = await asyncUser;
		// 未登录 进入 需登录状态路由 ，跳转login
		if (!user && authentication)
			return `/account/signin?redirect=${toPath(to)}`;
		// 已登录 进入 无需登录路由，跳转首页或from
		if (user && !authentication) return from ? from : '/';
	}
});

Router.after((to) => {
	// const meta = to.meta ?? {};
	// if (meta.title) {
	// 	document.title = meta.title;
	// }
	if (document) {
		if (document?.documentElement || document?.body) {
			// 切换路由时手动置顶
			document.documentElement.scrollTop = document.body.scrollTop = 0;
		}
	}
});

// 主题
const themeConfig: ThemeConfig = {
	token: {
		// black
		colorPrimary: '#000000',
		// green
		colorSuccess: '#27f3ee',
		// red
		colorInfo: '#fe2c55',
		// blue
		colorLink: '#1677ff',
		fontFamily: 'Avenir'
	},
	components: {
		Input: {
			activeShadow: '	0 0 0 3px rgba(0, 0, 0, 0.12)',
			lineHeightLG: 1.572,
			paddingBlockLG: 8,
			fontSizeLG: 14
		},
		Button: {
			paddingBlockLG: 10,
			fontSizeLG: 16,
			borderColorDisabled: 'transparent',
			fontWeight: 700
		},
		InputNumber: {
			activeShadow: '	0 0 0 3px rgba(0, 0, 0, 0.12)',
			addonBg: '#fff'
		},
		Layout: {
			bodyBg: '#F0F0F0'
		},
		Upload: {
			colorInfoBorderHover: '#d9d9d9'
		},
		Select: {
			// 启用算法
			algorithm: true,
			boxShadow: '0 0 0 3px rgba(0, 0, 0, 0.12)'
		},
		Form: {
			labelHeight: 22,
			verticalLabelPadding: '0 0 4px',
			labelFontSize: 14
		},
		Descriptions: {
			itemPaddingBottom: 8
		}
	}
};

// entry
function App() {
	useGetSystemRate();
	return (
		<div className="App">
			<ConfigProvider theme={themeConfig}>
				<Router.RouterProvider />
			</ConfigProvider>
		</div>
	);
}

export default App;
