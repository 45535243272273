/*
 * @Author: liuliangliang liuliangliang@sniffgroup.com
 * @Date: 2024-04-17 19:57:15
 * @LastEditors: liuliangliang liuliangliang@sniffgroup.com
 * @LastEditTime: 2024-04-18 11:52:34
 * @FilePath: /seller-station-client/src/components/base/link.tsx
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import './css/link.css';

export const Link = (props) => {
	// eslint-disable-next-line jsx-a11y/anchor-has-content
	return <a {...props} className="sellerStationLink" />;
};
