import React, { useEffect, useRef } from 'react';
import styles from './style.css';
interface IconProps extends React.SVGProps<SVGSVGElement> {
	size?: string | number;
	width?: string | number;
	height?: string | number;
	spin?: boolean;
	rtl?: boolean;
	color?: string;
	fill?: string;
	stroke?: string;
}

export default function AvatortouxiangYidenglu(props: IconProps) {
	const root = useRef<SVGSVGElement>(null);
	const {
		size = '1em',
		width,
		height,
		spin,
		rtl,
		color,
		fill,
		stroke,
		className,
		...rest
	} = props;
	const _width = width || size;
	const _height = height || size;
	const _stroke = stroke || color;
	const _fill = fill || color;
	useEffect(() => {
		if (!_fill) {
			(root.current as SVGSVGElement)
				?.querySelectorAll('[data-follow-fill]')
				.forEach((item) => {
					item.setAttribute(
						'fill',
						item.getAttribute('data-follow-fill') || ''
					);
				});
		}
		if (!_stroke) {
			(root.current as SVGSVGElement)
				?.querySelectorAll('[data-follow-stroke]')
				.forEach((item) => {
					item.setAttribute(
						'stroke',
						item.getAttribute('data-follow-stroke') || ''
					);
				});
		}
	}, [stroke, color, fill]);
	return (
		<svg
			ref={root}
			width={_width}
			height={_height}
			viewBox="0 0 24 24"
			preserveAspectRatio="xMidYMid meet"
			fill="none"
			role="presentation"
			xmlns="http://www.w3.org/2000/svg"
			className={`${className || ''} ${spin ? styles.spin : ''} ${rtl ? styles.rtl : ''}`.trim()}
			{...rest}
		>
			<g>
				<g clipPath="url(#11d7f2__a)">
					<rect fill="#27F3EE" rx="12" height="24" width="24" />
					<path
						strokeWidth="1.44"
						fill="#FE2C55"
						d="M2.88 16.14 9.72-.48l16.62-3.24v31.44H7.68l2.52-11.58H2.88Z"
						data-follow-stroke="#fff"
						stroke={_stroke}
					/>
					<path
						fill="#000"
						d="M16.903 6.052c-1.252-.96-2.81-1.062-3.38-.996a.72.72 0 0 1-.164-1.43c.829-.096 2.792.035 4.42 1.283a.72.72 0 1 1-.876 1.143ZM9.766 21.517c2.632-.488 6.187-1.614 8.74-3.692.045.27.035.605-.068 1.024a.72.72 0 1 0 1.4.342c.354-1.453-.032-2.524-.763-3.209a3.307 3.307 0 0 0-2.157-.862.72.72 0 1 0 0 1.44c.172 0 .422.046.672.153-2.115 1.72-5.082 2.755-7.49 3.27l-.334 1.534Z"
						clipRule="evenodd"
						fillRule="evenodd"
					/>
				</g>
				<defs>
					<clipPath id="11d7f2__a">
						<rect fill="#fff" rx="12" height="24" width="24" />
					</clipPath>
				</defs>
			</g>
		</svg>
	);
}
