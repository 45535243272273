import { NestedRoute, Route } from '@sniff/routeguard';
import { lazy } from 'react';
import NotFound from '@route/404';
import Root from '@/container/Root';
import Error from '@/container/404/Error';
import { station } from './biz/station';
import mall from './biz/mall';
import account from './biz/account';
const routes: Route[] = [account, mall, station];

export default [
	{
		path: '',
		id: 'root',
		children: routes,
		errorElement: <Error />,
		component: Root
	},
	{
		path: '/*',
		id: '404',
		element: <NotFound />
	}
];
