import { useBoolean } from 'ahooks';

export const useLoading = <T extends any[], K>(fn: (...r: T) => K) => {
	const [loading, { toggle }] = useBoolean(false);
	const bindFn = async (...r: T) => {
		toggle();
		let result!: K;
		try {
			result = await fn(...r);
		} catch (err) {}
		toggle();
		return result;
	};
	return [loading, bindFn] as const;
};
