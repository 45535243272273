import { subscribe } from '@sniff/routeguard';
import { useEffect, useRef } from 'react';
import Attentionzhuyi from '@/common/icons/Attentionzhuyi';

/** 显示无法下单错误 */
export const cartError = subscribe<{ index: number }>();

/** 下单失败样式 */
export const useErrorStyle = (value) => {
	const show = cartError();
	if (show && !value) {
		return ' border border-blood-600 shadow-sm shadow-blood-600 ';
	}
	return '';
};

/** 找到第一个，需要滚动到对应位置 */
export const useError = (value) => {
	const show = cartError();

	const isCorrect = Array.isArray(value) ? value.length : !!value;
	if (!isCorrect && show) {
		show.index++;
	}
	return isCorrect ? -1 : show?.index;
};

/** */
export const resetError = () => {
	// 先置空，重新触发动画
	cartError.setState(null);
	requestAnimationFrame(() => {
		cartError.setState({ index: 0 });
	});
	// 是否需要等待一段时间关闭错误提示
	// setTimeout(cartError.setState, 10*1000, null);
};

/** 无法下单错误提示 */
export function ErrorTip({
	value,
	msg,
	className = ''
}: {
	value: any;
	msg: string;
	className?: string;
}) {
	const index = useError(value);
	const div = useRef<HTMLDivElement>(null);
	if (index === 1) {
		requestAnimationFrame(() => {
			div.current?.scrollIntoView({
				behavior: 'smooth',
				block: 'center',
				inline: 'end'
			});
		});
	}
	const joinClass =
		' leading-[18px] text-sm text-blood-600 animate-[shakeX_1s_linear_0.5s] ' +
		className;
	return index > 0 ? (
		<div ref={div} className={joinClass}>
			<Attentionzhuyi
				color="#FE2C55"
				className=" inline-block mr-1 translate-y-[-1px]"
			/>
			<span>{msg}</span>
		</div>
	) : (
		<></>
	);
}
