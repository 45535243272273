import React, { useEffect, useRef } from 'react';
import styles from './style.css';
interface IconProps extends React.SVGProps<SVGSVGElement> {
	size?: string | number;
	width?: string | number;
	height?: string | number;
	spin?: boolean;
	rtl?: boolean;
	color?: string;
	fill?: string;
	stroke?: string;
}

export default function Mastercard(props: IconProps) {
	const root = useRef<SVGSVGElement>(null);
	const {
		size = '1em',
		width,
		height,
		spin,
		rtl,
		color,
		fill,
		stroke,
		className,
		...rest
	} = props;
	const _width = width || size;
	const _height = height || size;
	const _stroke = stroke || color;
	const _fill = fill || color;
	useEffect(() => {
		if (!_fill) {
			(root.current as SVGSVGElement)
				?.querySelectorAll('[data-follow-fill]')
				.forEach((item) => {
					item.setAttribute(
						'fill',
						item.getAttribute('data-follow-fill') || ''
					);
				});
		}
		if (!_stroke) {
			(root.current as SVGSVGElement)
				?.querySelectorAll('[data-follow-stroke]')
				.forEach((item) => {
					item.setAttribute(
						'stroke',
						item.getAttribute('data-follow-stroke') || ''
					);
				});
		}
	}, [stroke, color, fill]);
	return (
		<svg
			ref={root}
			width={_width}
			height={_height}
			viewBox="0 0 40 40"
			preserveAspectRatio="xMidYMid meet"
			fill="none"
			role="presentation"
			xmlns="http://www.w3.org/2000/svg"
			className={`${className || ''} ${spin ? styles.spin : ''} ${rtl ? styles.rtl : ''}`.trim()}
			{...rest}
		>
			<g>
				<g clipPath="url(#11e7d7__a)">
					<path
						fill="#fff"
						d="M37.646 7.633H2.355A2.354 2.354 0 0 0 0 9.986v20.149c0 1.3 1.054 2.353 2.354 2.353h35.291c1.3 0 2.354-1.053 2.354-2.353V9.986c0-1.3-1.054-2.353-2.354-2.353Z"
					/>
					<path
						fill="#F79F1A"
						d="M35.775 20.014c0 5.267-4.26 9.533-9.513 9.533s-9.513-4.266-9.513-9.533c0-5.266 4.253-9.532 9.506-9.532 5.254 0 9.52 4.266 9.52 9.532Z"
					/>
					<path
						fill="#EA001B"
						d="M24.029 20.014c0 5.267-4.26 9.533-9.513 9.533s-9.513-4.266-9.513-9.533c0-5.266 4.26-9.532 9.513-9.532s9.513 4.266 9.513 9.532Z"
					/>
					<path
						fill="#FF5F01"
						d="M20.386 12.509a9.52 9.52 0 0 0-3.644 7.505 9.52 9.52 0 0 0 3.644 7.506 9.52 9.52 0 0 0 3.643-7.506 9.53 9.53 0 0 0-3.643-7.505Z"
					/>
					<path
						fill="#F79F1A"
						d="M35.75 25.077v-.337h-.083l-.101.236-.101-.244h-.093v.336h.059v-.252l.093.219h.067l.1-.22v.262h.06Zm-.563 0v-.287h.118v-.058h-.286v.058h.117v.287h.05Z"
					/>
				</g>
				<defs>
					<clipPath id="11e7d7__a">
						<path fill="#fff" d="M0 0h40v40H0z" />
					</clipPath>
				</defs>
			</g>
		</svg>
	);
}
