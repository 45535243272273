/** 账户类型 */
export enum ENUM_ACCOUNT_TYPE {
	/** 主账户 */
	MAIN = 1,
	/** 子账户 */
	SUB = 2
}

/** 支付方式 */
export enum PayType {
	/** paypal信用卡 */
	PAYPAL_CARD = 1,
	/** paypal支付 */
	PAYPAL,
	BALANCE
}

/** 支付方式列表 */
export const PayList = [
	{
		type: PayType.PAYPAL_CARD,
		name: 'Credit Card'
	},
	{
		type: PayType.PAYPAL,
		name: 'PayPal'
	},
	{
		type: PayType.BALANCE,
		name: 'Balance'
	}
];

/** 支付内容 */
export enum PayContent {
	// 充值
	'Recharge' = 0,
	// 支付会员
	'VIP'
}

// 立即生效和延后生效
export enum OrderType {
	'Immediately' = 0,
	'Delay' = 1
}

/** 账户主子 */
export enum AccountType {
	'MAIN' = 1,
	'SUB'
}

/** 自动续费 */
export enum AutomaticRenewalStatus {
	'OPEN' = 1,
	'CLOSE'
}

/** 支付方式 */
export enum AutomaticRenewalStytle {
	// 1-余额，2-PayPal,3-信用卡
	BALANCE = 1,
	/** paypal支付 */
	PAYPAL,
	PAYPAL_CARD
}

/**
 * 充值类型
 */
export enum RechargeType {
	/** 充值 */
	PAYPAL_CARD = 1,
	/** 信用卡 */
	PAYPAL,
	/** 银行 */
	BANK
}

/** 充值审核状态 */
export enum BalanceRechargeStatusDict {
	'Waiting for initial review' = 1,
	'Waiting for final review' = 2,
	'Review Completed' = 3,
	'Rejected' = 4,
	'Charge failed' = 5,
	'Payment is being confirmed' = 6,
	'Cancel Charge' = 7
}

/** 充值渠道 */
export enum PayChannelEnum {
	'Bank transfer' = 1,
	paypal,
	payoneer,
	'Credit Card',
	'Bank transfer (speed of reflection increased)'
}

/** 计费重量类型 */
export enum WEIGHT_THROWING_TYPE {
	// 1-全抛;2-半抛;3-不抛
	ALL_THROW = 1,
	HALF_THROW = 2,
	NOT_THROW = 3
}

/** 线索池标签 */
export enum SOURCE_TAG {
	/** 货源-同款 */
	US_SP_SAME = 'US-SP-SAME',
	/** 货源-类似款 */
	US_SP_SIMILAR = 'US-SP-SIMILAR',
	/** 线索-prime */
	US_SP_PRIME = 'US-SP-PRIME',
	/** 线索-当前热卖 */
	US_S_CURRENTLY = 'US-S-CURRENTLY',
	/** 线索-预测热卖 */
	US_S_PREDICTED = 'US-S-PREDICTED'
}

export enum ENUM_EDIT_TYPE {
	/** 新增 */
	ADD = 'add',
	/** 编辑 */
	EDIT = 'edit',
	/** 删除 */
	SHOW = 'show'
}

export enum ENUM_CLEARANCE_TYPE {
	/** 个人 */
	PERSONAL = 0,
	/** 企业 */
	COMPANY = 1
}
/** 默认类型 */
export enum ENUM_DEFAULT_TYPE {
	/** 默认 */
	DEFAULT = 1,
	/** 非默认 */
	NON_DEFAULT = 0
}

/** 自动续费状态 */
export enum ENUM_RENEWAL_STATUS {
	/** 开启状态 */
	OPEN = 1,
	/** 关闭状态 */
	CLOSE = 2
}
/** 自动续费开启方式 */
export enum ENUM_RENEWAL_STYLE {
	/** 余额 */
	BALANCE = 1,
	/** PayPal */
	PAYPAL = 2,
	/** 信用卡 */
	CREDIT_CARD = 3
}
// 登录环境
export type loginEnv = 'dev' | 'test' | 'pre' | 'prod' | 'prod1';

/**
 * 商品在美国站点的状态 0-待上架 1-已下架 2-已上架
 * @format int32
 */
export enum ENUM_STATION_STATUS {
	/** 待上架 */
	PENDING = 0,
	/** 已下架 */
	OFF_SALE = 1,
	/** 已上架 */
	ON_SALE = 2
}
