import React, { useEffect, useRef } from 'react';
import styles from './style.css';
interface IconProps extends React.SVGProps<SVGSVGElement> {
	size?: string | number;
	width?: string | number;
	height?: string | number;
	spin?: boolean;
	rtl?: boolean;
	color?: string;
	fill?: string;
	stroke?: string;
}

export default function TiktokCpie8p4m(props: IconProps) {
	const root = useRef<SVGSVGElement>(null);
	const {
		size = '1em',
		width,
		height,
		spin,
		rtl,
		color,
		fill,
		stroke,
		className,
		...rest
	} = props;
	const _width = width || size;
	const _height = height || size;
	const _stroke = stroke || color;
	const _fill = fill || color;
	useEffect(() => {
		if (!_fill) {
			(root.current as SVGSVGElement)
				?.querySelectorAll('[data-follow-fill]')
				.forEach((item) => {
					item.setAttribute(
						'fill',
						item.getAttribute('data-follow-fill') || ''
					);
				});
		}
		if (!_stroke) {
			(root.current as SVGSVGElement)
				?.querySelectorAll('[data-follow-stroke]')
				.forEach((item) => {
					item.setAttribute(
						'stroke',
						item.getAttribute('data-follow-stroke') || ''
					);
				});
		}
	}, [stroke, color, fill]);
	return (
		<svg
			ref={root}
			width={_width}
			height={_height}
			viewBox="0 0 32 32"
			preserveAspectRatio="xMidYMid meet"
			fill="none"
			role="presentation"
			xmlns="http://www.w3.org/2000/svg"
			className={`${className || ''} ${spin ? styles.spin : ''} ${rtl ? styles.rtl : ''}`.trim()}
			{...rest}
		>
			<g>
				<path
					d="M27 13.842a9.849 9.849 0 0 1-5.757-1.84v8.38a7.62 7.62 0 1 1-6.575-7.553v4.215a3.499 3.499 0 1 0 2.449 3.337V4h4.126c-.003.348.026.696.087 1.04a5.73 5.73 0 0 0 2.528 3.76 5.693 5.693 0 0 0 3.141.945v4.097Z"
					data-follow-fill="#000"
					fill={_fill}
				/>
			</g>
		</svg>
	);
}
