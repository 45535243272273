import React, { useContext, useEffect, useState } from 'react';
import {
	CloseCircleFilled,
	ExpandOutlined,
	UploadOutlined
} from '@ant-design/icons';
import { message, Popover, Upload, UploadProps } from 'antd';
import type { UploadFile, RcFile } from 'antd/es/upload/interface';
import { flushSync } from 'react-dom';
import { uniqueId } from 'lodash';
import { request } from '@/config/request';
interface OSSDataType {
	dir: string;
	expire: string;
	host: string;
	accessId: string;
	policy: string;
	signature: string;
	customDomain: string;
}
interface AliyunOSSUploadProps extends Omit<UploadProps, 'onChange'> {
	/** 最大 多少 M */
	maxSizeM?: number;
	/** 是否是全路径 */
	isFullPath?: boolean;
	onChange?: (fileList: UploadFile[]) => any;
	value?: UploadFile[];
	/** 上传的数据是否覆盖 默认不覆盖 */
	isOverride?: number;
	pictureUploadLabel?: React.ReactNode;
	pathParams: {
		/** 服务名，例如 order, purchase */
		serviceName: 'order' | 'purchase' | 'pay';
		/**
		 * 业务名称，(如果没有就自己命名)
		 * tableTrade : 表格下单
		 * deliveryInvoice: 国际发货单
		 * stockInRecord：入库单
		 * checkAbnormal：质检异常
		 * OEM：oem
		 * */
		bizName:
			| 'sellStationWeb'
			| 'easyWeb'
			| 'tableTrade'
			| 'deliveryInvoice'
			| 'stockInRecord'
			| 'checkAbnormal'
			| 'OEM';
		/**
		 * 业务类型：(属于bizName 下属 如果没有就自己命名)
		 * 如果是表格下单业务，分为
		 * 导入到购物车：importShopCart
		 * 导入下单: importTrade
		 * oem 商品图片: oemGoodsImg
		 * oem log 附件: oemLogAttachment
		 * oem 式样书: styleBook
		 * oem 色卡图片: styleBook
		 */
		bizType:
			| 'recharge'
			| 'oemGoodsImg'
			| 'oemSizeTemplate'
			| 'styleBook'
			| 'colorCardSelect'
			| 'oemMaterials'
			| 'oemSize'
			| 'oemStyle'
			| 'aftersale';
		/**
		 *  如果是Client端登陆，就是customerId
		 * 如果是System端登陆 userId
		 * 如果是WMS登陆 userId
		 */
		operatorId?: 'system' | 'client' | 'easy' | 'usstation' | string;
	};
	// url拼接读取权限
	readAuto?: boolean;
}
const isProduction = (() => {
	if (window.location.port) return false;
	const pre = window.location.hostname.split('.')[0];
	return pre.startsWith('pre-') || !pre.includes('-');
})();
export const normFile = (e: any) => {
	if (Array.isArray(e)) {
		return e;
	}
	return e?.fileList;
};
const getExtension = (value: string) => {
	if (typeof value === 'string') {
		const strList = value.split('.');
		const len = strList.length;
		return strList[len - 1];
	}
	return '';
};
const getRandomFileName = (params: AliyunOSSUploadProps, file) => {
	const ext = getExtension(file.name);
	return `${params.pathParams.bizName}/${
		params.pathParams.bizType
	}_${new Date().getTime()}.${ext}`;
};
const AliyunOSSUpload = (props: AliyunOSSUploadProps) => {
	const {
		pathParams,
		children,
		listType,
		accept: _accept,
		pictureUploadLabel,
		itemRender,
		isFullPath = true,
		maxSizeM = 10,
		disabled,
		readAuto = true,
		...otherProps
	} = props;
	let action = '';
	const uploadData = {
		name: '',
		key: '',
		policy: '',
		OSSAccessKeyId: '',
		success_action_status: 200,
		signature: ''
	};
	const accept = (() => {
		if (_accept) return _accept;
		if (['picture-card', 'picture'].includes(listType!)) {
			return '.jpg,.jpeg,.png,.JPG,.JPEG,.PNG';
		}
	})();
	// const xx = useContext(DisabledContext);
	const _children = () => {
		if (children) return children;
		if (listType === 'picture-card') {
			return (
				<div className="flex justify-center items-center flex-col ">
					<UploadOutlined
						className="text-[30px]"
						style={{
							color: '#898989'
						}}
					/>

					<div className="mt-[8px] px-[4px]">
						{pictureUploadLabel ?? window._$m.t('上传图片')}
					</div>
				</div>
			);
		}
	};
	const getIsShowChildren = () => {
		const listLen = props?.value?.length ?? 0;
		// 若是没有该字段，则上传个数无限次
		if (!props?.maxCount) {
			return _children();
		}
		// 上传文件个数
		if (listLen === 0) {
			return _children();
		}
		if (listLen < Number(props?.maxCount)) {
			return _children();
		}
		return null;
	};
	const getItemRendeFn = () => {
		if (itemRender) return itemRender;
		if (listType !== 'picture-card') return;
		const itemRendeFn = (originNode, file, fileList, actions) => {
			const viewUrl = file.thumbUrl ?? file.url;
			return (
				<div className="pr-[20px]">
					<div className="w-[100px] h-[100px] border-[1px] rounded-[4px] relative mr-[20px] border-grey-5">
						{!disabled && (
							<CloseCircleFilled
								className="absolute top-[-8px] right-[-8px] text-[20px] text-[#D0D0D0] cursor-pointer"
								onClick={actions.remove}
							/>
						)}

						<Popover
							content={
								<img
									src={viewUrl}
									alt=""
									className="w-[400px] h-[400px] block"
								/>
							}
						>
							<ExpandOutlined
								className="absolute bottom-[4px] right-[4px] text-[12px] cursor-pointer"
								onTouchMove={(e) => {
									e.preventDefault();
									e.stopPropagation();
								}}
								onTouchStart={(e) => {
									e.preventDefault();
									e.stopPropagation();
								}}
								onTouchEnd={(e) => {
									e.preventDefault();
									e.stopPropagation();
								}}
							/>
						</Popover>
						<img
							src={viewUrl}
							alt=""
							className="w-[100%] h-[100%]"
						/>
					</div>
				</div>
			);
		};
		return itemRendeFn;
	};
	const [OSSData, setOSSData] = useState<OSSDataType>();
	const { serviceName, bizName, operatorId, bizType } = pathParams;
	const path =
		[serviceName, bizName, operatorId ?? 'system', bizType].join('/') + '/';
	const bucketName = isProduction ? 'img-us-prod-theckb' : 'ckb-service-test';

	const mockGetOSSData = async () => {
		// 这个接口有毒 需要这样传
		return (
			await request.customer.oss.signAnother({}, {
				path: `/oss/sign/another?bucketName=${bucketName}&path=${pathParams.bizName}&type=0`
			} as any)
		).data;
	};
	const init = async () => {
		try {
			const result = await mockGetOSSData();
			if (result) {
				flushSync(() => {
					setOSSData(result as OSSDataType);
				});
				return result as OSSDataType;
			}
		} catch (error: any) {
			message.error(error);
		}
	};
	// useEffect(() => {
	//     init();
	//     // eslint-disable-next-line react-hooks/exhaustive-deps
	// }, []);
	const getExtraData: UploadProps['data'] = (file: any) => {
		return {
			key: file.shortUrl,
			OSSAccessKeyId: OSSData?.accessId,
			policy: OSSData?.policy,
			Signature: OSSData?.signature
		};
	};
	const validateMaxSize = (size: number, maxSizeM: number) => {
		const isLt2M = size / 1024 / 1024 <= maxSizeM;
		return isLt2M;
	};
	const beforeUpload: UploadProps['beforeUpload'] = async (file) => {
		if (!validateMaxSize(file.size, maxSizeM)) {
			// throw Error('x');
			flushSync(() => {
				message.warning(window._$m.t('文件最大10M'));
			});
			return Promise.resolve<false>(false);
		}
		// const _OSSData = await init();
		// const suffix = file.name.slice(file.name.lastIndexOf('.'));
		// const filename = Date.now() + suffix;
		// const shortUrl = `${_OSSData?.dir ?? ''}${filename}`;
		// // @ts-ignore
		// file.url = `${
		//     isFullPath ? 'https://theckbstest-oss.theckbs.com/' : ''
		// }${shortUrl}`;
		// // @ts-ignore
		// file.shortUrl = shortUrl;
		return getOssSign(file);
		// return file;
	};

	const uploadProps: UploadProps = {
		action: 'https://theckbstest.oss-cn-hangzhou.aliyuncs.com',
		itemRender: getItemRendeFn(),
		children: getIsShowChildren(),
		listType,
		data: getExtraData,
		beforeUpload
	};
	const getOssSign = async (file) => {
		console.log(file);
		const sign = await request.customer.oss
			.signAnother({}, {
				path: `/oss/sign/another?bucketName=${bucketName}&path=${pathParams.bizName}/&type=0`
			} as any)
			.then((res: any) => {
				return res.data;
			});
		if (!sign) return false;
		const location = window.location;
		uploadData.name = file.name;
		uploadData.key = getRandomFileName(props, file);
		uploadData.policy = sign.policy;
		uploadData.OSSAccessKeyId = sign.accessId;
		uploadData.signature = sign.signature;
		uploadData['Content-Disposition'] = 'inline';
		uploadData['x-oss-object-acl'] = props.readAuto
			? 'public-read'
			: 'private';
		uploadData['x-oss-forbid-overwrite'] = false;
		//
		action = sign?.host?.replace(/http[s]?:/, location.protocol);
		file.url = `${sign.customDomain}/${uploadData.key}`;
		console.log(`${sign.customDomain}/${uploadData.key}`);
		console.log(file);
		return file;
	};
	if (listType === 'picture-card' && disabled) {
		const viewDom = props.value?.map((item, idx) => {
			return (
				<div key={idx} className="mr-[10px]">
					<Popover
						content={
							<img
								src={item.url}
								alt=""
								className="w-[240px] h-[240px] block"
							/>
						}
					>
						<div
							className="w-[100px] h-[100px] bg-no-repeat"
							style={{
								backgroundImage: `url(${item.url})`,
								backgroundSize: 'contain'
							}}
						/>
					</Popover>
				</div>
			);
		});
		if (viewDom) return <div className="flex">{viewDom}</div>;
		return null;
	}
	console.log(otherProps.value, 'otherProps.value');

	return (
		<Upload
			style={{
				borderColor: '#d9d9d9 !important'
			}}
			onRemove={(e) => {
				const files =
					otherProps.value?.filter((i) => i.uid !== e.uid) || [];
				console.log(files);

				props?.onChange && props?.onChange([...files]);
			}}
			{...{
				...otherProps,
				...uploadProps,
				accept,
				disabled
			}}
			fileList={otherProps.value}
			onChange={(e) => {
				console.log(e.fileList, 'e.fileList');
				if (e.fileList && props?.onChange && e.fileList.length) {
					console.log(e.fileList, 'e.fileList');

					const files = e.fileList?.filter((item) => {
						// return true;
						// 过滤掉
						return item.size
							? validateMaxSize(item.size, maxSizeM)
							: true;
					});
					props?.onChange([...files]);
				}
			}}
			action={() => {
				return action;
			}}
			data={() => {
				return uploadData;
			}}
		/>
	);
};
export default AliyunOSSUpload;
