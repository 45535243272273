import React, { useEffect, useRef } from 'react';
import styles from './style.css';
interface IconProps extends React.SVGProps<SVGSVGElement> {
	size?: string | number;
	width?: string | number;
	height?: string | number;
	spin?: boolean;
	rtl?: boolean;
	color?: string;
	fill?: string;
	stroke?: string;
}

export default function ToTopqudingbu(props: IconProps) {
	const root = useRef<SVGSVGElement>(null);
	const {
		size = '1em',
		width,
		height,
		spin,
		rtl,
		color,
		fill,
		stroke,
		className,
		...rest
	} = props;
	const _width = width || size;
	const _height = height || size;
	const _stroke = stroke || color;
	const _fill = fill || color;
	useEffect(() => {
		if (!_fill) {
			(root.current as SVGSVGElement)
				?.querySelectorAll('[data-follow-fill]')
				.forEach((item) => {
					item.setAttribute(
						'fill',
						item.getAttribute('data-follow-fill') || ''
					);
				});
		}
		if (!_stroke) {
			(root.current as SVGSVGElement)
				?.querySelectorAll('[data-follow-stroke]')
				.forEach((item) => {
					item.setAttribute(
						'stroke',
						item.getAttribute('data-follow-stroke') || ''
					);
				});
		}
	}, [stroke, color, fill]);
	return (
		<svg
			ref={root}
			width={_width}
			height={_height}
			viewBox="0 0 24 24"
			preserveAspectRatio="xMidYMid meet"
			fill="none"
			role="presentation"
			xmlns="http://www.w3.org/2000/svg"
			className={`${className || ''} ${spin ? styles.spin : ''} ${rtl ? styles.rtl : ''}`.trim()}
			{...rest}
		>
			<g>
				<path
					data-follow-fill="currentColor"
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12.004 6.05a1 1 0 0 1 1 1V21a1 1 0 0 1-2 0V7.05a1 1 0 0 1 1-1Z"
					fillOpacity=".88"
					fill={_fill}
				/>
				<path
					data-follow-fill="currentColor"
					fillRule="evenodd"
					clipRule="evenodd"
					d="M11.293 6.293a1 1 0 0 1 1.414 0l6 6a1 1 0 0 1-1.414 1.414L12 8.414l-5.293 5.293a1 1 0 0 1-1.414-1.414l6-6ZM5 3a1 1 0 0 1 1-1h12a1 1 0 1 1 0 2H6a1 1 0 0 1-1-1Z"
					fillOpacity=".88"
					fill={_fill}
				/>
			</g>
		</svg>
	);
}
