import React, { useEffect, useRef } from 'react';
import styles from './style.css';
interface IconProps extends React.SVGProps<SVGSVGElement> {
	size?: string | number;
	width?: string | number;
	height?: string | number;
	spin?: boolean;
	rtl?: boolean;
	color?: string;
	fill?: string;
	stroke?: string;
}

export default function HomegongzuotaiD085k1h2(props: IconProps) {
	const root = useRef<SVGSVGElement>(null);
	const {
		size = '1em',
		width,
		height,
		spin,
		rtl,
		color,
		fill,
		stroke,
		className,
		...rest
	} = props;
	const _width = width || size;
	const _height = height || size;
	const _stroke = stroke || color;
	const _fill = fill || color;
	useEffect(() => {
		if (!_fill) {
			(root.current as SVGSVGElement)
				?.querySelectorAll('[data-follow-fill]')
				.forEach((item) => {
					item.setAttribute(
						'fill',
						item.getAttribute('data-follow-fill') || ''
					);
				});
		}
		if (!_stroke) {
			(root.current as SVGSVGElement)
				?.querySelectorAll('[data-follow-stroke]')
				.forEach((item) => {
					item.setAttribute(
						'stroke',
						item.getAttribute('data-follow-stroke') || ''
					);
				});
		}
	}, [stroke, color, fill]);
	return (
		<svg
			ref={root}
			width={_width}
			height={_height}
			viewBox="0 0 16 16"
			preserveAspectRatio="xMidYMid meet"
			fill="none"
			role="presentation"
			xmlns="http://www.w3.org/2000/svg"
			className={`${className || ''} ${spin ? styles.spin : ''} ${rtl ? styles.rtl : ''}`.trim()}
			{...rest}
		>
			<g>
				<path
					strokeLinejoin="round"
					strokeWidth="1.333"
					stroke="#434343"
					d="M4 11H1.335V2.333h13.333V11H4.001Z"
				/>
				<path
					strokeLinejoin="round"
					strokeLinecap="round"
					strokeWidth="1.333"
					stroke="#199A97"
					d="M5.334 7.333v1.334"
				/>
				<path
					strokeLinejoin="round"
					strokeLinecap="round"
					strokeWidth="1.333"
					stroke="#434343"
					d="M8 11v2"
				/>
				<path
					strokeLinejoin="round"
					strokeLinecap="round"
					strokeWidth="1.333"
					stroke="#199A97"
					d="M8 6v2.667m2.666-4v4"
				/>
				<path
					strokeLinejoin="round"
					strokeLinecap="round"
					strokeWidth="1.333"
					stroke="#434343"
					d="M4 13.667h8"
				/>
			</g>
		</svg>
	);
}
