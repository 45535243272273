import { Alert, AlertProps } from 'antd';

export function CartPanel({
	children,
	className
}: {
	children: [JSX.Element, JSX.Element];
	className?: string;
}) {
	return (
		<div
			className={
				' rounded-2xl border border-black-200 overflow-hidden ' +
				(className ?? '')
			}
		>
			<div className=" flex items-center bg-black-50 py-3 px-5 text-lg leading-8 font-bold">
				{children[0]}
			</div>
			<div className=" px-5 pt-4 pb-5 rounded-lg">{children[1]}</div>
		</div>
	);
}

export function Panel(props: AlertProps) {
	return (
		<Alert
			className=" border-none bg-sea-100 px-5 py-2"
			type="success"
			{...props}
		/>
	);
}
