import { clone, flatProp } from '@/utils/base';
import { CartGroupItemDTO, CartItemDTO } from '@/service/order';
import { ProductRespDTO, SkuRespDTO } from '@/service/goods';
import { request } from '@/config/request';
import { asyncData } from '../_base';
import { createCache } from '../_cache';
import { DisabledReasonDict, ProductStatus } from './enum';
import { cartSet, settingCart } from './setting';
import { pay } from './pay';

export type SkuData = CartItemDTO & {
	// 更新中状态
	loading: boolean;
	// 商品失效
	disabled: boolean;
	// 失效原因
	reason: string | null;
	// 商品勾选
	checked: boolean;
	// 最终计算单价
	buyPrice: number;
	// 单个sku的总价
	totalPrice: number;
	// 关联对应spu信息
	bindSpu: {
		qty: number;
		min: number;
		able: boolean;
	};
};

export type CartData = Omit<CartGroupItemDTO, 'cartList'> & {
	cartList: SkuData[];
};

// 购物车列表
const cart = asyncData(
	{
		/** 获取数据，匹配上一次勾选 */
		async get() {
			const { getCheckedIdSet, calcLadder, disabledReason } = cart;
			const res = await request.order.cart.list({ orderType: 1 });
			const data: CartData[] = res.data! as any;
			const skus = flatProp(data, 'cartList');
			// 同步勾选状态,失效不可勾选
			const checkSet = getCheckedIdSet();
			skus.forEach((v) => {
				// 兼容后端，黑名单商品，前端将作为失效商品
				if (v.blackTag) {
					v.productStatus = 1;
				}
				v.reason = disabledReason(v);
				v.disabled = !!v.reason;
				v.checked = v.disabled ? false : checkSet.has(v.cartId);
			});
			// 勾选确认后，计算阶梯价
			calcLadder(skus);
			// 为什么不在useEffect中调用，因为本地勾选修改数量等操作不做拦截会先渲染一次，之后同步get接口后可以减少调用次数，并且可以和set一并做防抖处理
			requestIdleCallback(() => {
				cart.verifyBeforeCalc();
			});
			return data;
		},
		/** 变更规格、数量、附加项，删除，刷新 */
		async set(payload?) {
			const { type, value, skus } = payload as {
				type: string;
				value: any;
				skus: SkuData[];
			};
			if (skus) {
				if (['changeSku', 'changeAdds'].includes(type)) {
					// 添加loading状态
					skus.forEach((sku) => {
						sku.loading = true;
					});
				}
				// 对于勾选商品做修改的话，需重置物流
				if (skus.some((v) => v.checked)) {
					// 编辑附加项不用
					if (!['changeAdds'].includes(type)) {
						settingCart({ shippingId: null, shipping: null }, true);
					}
				}
				cart.render();
			}
			/** 调购物车接口 */
			const post = {
				// 变更sku
				changeSku(params) {
					return request.order.cart.replace(params);
				},
				// 修改数量
				changeNum(params) {
					return request.order.cart.updateSellQuantity(params);
				},
				// 修改附加项，多条
				changeAdds(params) {
					return request.order.cart.batchUpdateAddition({
						updateCartAdditionReqDTOS: params
					});
				},
				// 删除，多条
				del(params) {
					return request.order.cart.delete(params);
				}
			};
			if (type in post) {
				await post[type](value);
			}
			// 更新购物车数量接口
			if (['del', 'changeSku'].includes(type)) {
				cartNum.update();
			}
		},
		/** 返回指定店铺下的sku，不传则全部sku */
		useSkuList(store, cartGroupNo?: string) {
			const cartList = store();
			const list = cartGroupNo
				? [cartList.find((v) => v.cartGroupNo === cartGroupNo)!]
				: cartList;
			return flatProp(list, 'cartList');
		},
		/** 返回勾选的sku */
		useCheckSkus(store) {
			return (
				(store.useSkuList() as SkuData[]).filter((v) => v.checked) ?? []
			);
		},
		/** 是否有勾选 */
		useIsChecked(store) {
			return (store.useCheckSkus() as any).length;
		},
		/** 返回sku的勾选属性 */
		useCheck(store, list: SkuData[]) {
			const disabled = list.every((v) => v.disabled);
			// 过滤掉disabled
			const ableList = list.filter((v) => !v.disabled);
			const checked = ableList.length
				? ableList.every((v) => v.checked)
				: false;
			const indeterminate = checked
				? false
				: ableList.some((v) => v.checked);
			const onChange = () => {
				let changed = false;
				ableList.forEach((v) => {
					const prev = v.checked;
					v.checked = v.disabled ? false : !checked;
					if (!changed && prev !== v.checked) changed = true;
				});
				// 无变化不渲染
				if (!changed) return;
				store.render();
				// 清空物流，重新算价
				settingCart({ shippingId: null, shipping: null });
				// 只有勾选需要手动触发计算总价，因为勾选不同步购物车；ps:清空物流会调用算价，不用手动调用
				// store.verifyBeforeCalc();
			};
			return { checked, disabled, indeterminate, onChange };
		},
		/**********************/
		/** hook 和 func 的分界线，hook内可以使用func，func内不能使用hook */
		/**********************/
		/** 刷新，下单成功/失败调用 */
		async refresh() {
			cart.get();
			cartNum.update();
		},
		/** 重新算价、渲染 */
		render(store) {
			store.setState((prev) => {
				const skus = store.getSkuList();
				// 渲染前算费
				store.calcLadder(skus);
				return clone(prev);
			});
		},
		/** 全部sku */
		getSkuList(store) {
			return flatProp(store.getState(), 'cartList');
		},
		/** 商品失效 */
		disabledReason(store, sku: SkuData) {
			const reason: string =
				DisabledReasonDict[sku.productStatus!] ??
				DisabledReasonDict[ProductStatus.INVALID];
			return sku.productStatus === ProductStatus.NORMAL ? null : reason;
		},
		/** 计算可用库存数、使用数，后续有库存才会使用 */
		calcBuyStaff() {},
		/** productSku集合，后续有组合商品才会用到 */
		getProductSkuMap(store, data) {
			const skuMap = new Map();
			return skuMap;
		},
		/** spu集合，计算spu下单数量 */
		getSpuMap(store, skus) {
			const spu = new Map<
				string,
				{ qty: number; min: number; able: boolean }
			>();
			skus.forEach((sku) => {
				const {
					productCode,
					orderQuantity,
					checked,
					minOrderQuantity,
					isOnePsale
				} = sku;
				const t = spu.get(productCode);
				if (!t) {
					spu.set(productCode, {
						qty: checked ? orderQuantity : 0,
						min: minOrderQuantity,
						able: isOnePsale
					});
				} else if (checked) {
					t.qty += orderQuantity;
				}
			});
			return spu;
		},
		/** 根据spu计算阶梯价，一件代发价 */
		calcLadder(store, skus: SkuData[]) {
			const spuMap = store.getSpuMap(skus) as Map<string, any>;
			//
			skus.forEach((sku) => {
				const {
					productCode,
					productSellPrice,
					productPriceLadderList: ladder,
					isOnePsale,
					consignPrice,
					orderQuantity,
					minOrderQuantity = 0
				} = sku;
				// 注意：这里要获取spu的勾选下单数量，阶梯价计算的是spu维度
				const spu = spuMap.get(productCode!)!;
				const { qty } = spu;
				// 小于最小起订量，有一件代发取代发价，否则是单价或最低阶梯价
				if (qty! < minOrderQuantity) {
					sku.buyPrice = (productSellPrice || ladder?.[0].price)!;
					// 存在勾选spu
					if (qty > 0) {
						sku.buyPrice = isOnePsale
							? consignPrice!
							: sku.buyPrice;
					}
				} else {
					// 存在阶梯价
					if (ladder?.length) {
						const { price } = ladder.find((cur, i) => {
							const next = ladder[i + 1] ?? { count: 9999999 };
							return cur.count! <= qty && qty < next.count!;
						}) ?? { price: 0 };
						sku.buyPrice = price!;
					} else {
						// 最终取单价
						sku.buyPrice = productSellPrice!;
					}
				}
				// 总价
				sku.totalPrice = sku.buyPrice * orderQuantity!;
				// 将spu信息关联
				sku.bindSpu = spu;
			});
		},
		/** 返回勾选的skuList */
		getCheckedSkus(store) {
			return (store.getSkuList() as SkuData[]).filter((v) => v.checked);
		},
		/** 获取所有勾选sku的cartId */
		getCheckedIdSet(store) {
			const skus = store.getCheckedSkus() as SkuData[];
			return new Set(skus.map((v) => v.cartId));
		},
		/** 可用于单行商品显示无法下单校验 */
		isEnough(store, sku) {
			const { bindSpu } = sku as SkuData;
			return bindSpu.able || bindSpu.qty >= bindSpu.min;
		},
		isBatch(store, sku) {
			return sku.batchNumber
				? sku.orderQuantity! % sku.batchNumber === 0
				: true;
		},
		isSetAdds(store, sku: SkuData) {
			return sku.cartAdditionList?.length || sku.noAdditionalFlag;
		},
		// 计算总价前校验最小起订量、倍数
		verifyBeforeCalc(store) {
			const checkSkus = store.getCheckedSkus() as SkuData[];
			const isCheck = !!checkSkus.length;
			// 确认选择附加项
			const isSetAdds = checkSkus.every(store.isSetAdds);
			// 满足起订量
			const isEnough = checkSkus.every(store.isEnough);
			// 满足倍数
			const isBatch = checkSkus.every(store.isBatch);
			// 获取支付账单
			return pay.get({
				isCheck,
				isEnough,
				isBatch,
				isSetAdds,
				cartIdList: checkSkus.map((v) => v.cartId),
				deliveryAdditionalIdList: cartSet
					.getState()
					.deliveryAdds.map((v) => v.additionId),
				intShipConfigId: cartSet.getState().shippingId
			});
		},
		/** 校验商品状态 */
		async preCheck(store) {
			const cartIds = [...(store.getCheckedIdSet() as Set<number>)];
			const { data } = await request.order.cart.createOrderPreCheck({
				orderType: 1,
				cartIds
			});
			if (data) {
				return Object.values(data).every((v) => v.length === 0);
			}
			return true;
		}
	},
	{
		defaultValue: []
	}
);

/** 缓存变更sku的商品 */
const spuForChange = {
	defaultValue: { skuList: [] },
	cache: createCache({ expire: 1000 * 3600 }),
	async get(productCode) {
		const res = await request.goods.product.detail({ productCode });
		this.cache.set(productCode, res.data!);
		return res.data!;
	},
	async getCache({ productCode }: { productCode?: string }) {
		if (this.cache.has(productCode!)) {
			return this.cache.get(productCode);
		}
		return this.get(productCode);
	},
	/** 分别以productProperties，productSku作为key */
	getSkuMaps(spu: ProductRespDTO) {
		const propMap = new Map<string, SkuRespDTO>();
		const idMap = new Map<string, SkuRespDTO>();
		const { skuList } = spu;
		skuList?.forEach((sku) => {
			propMap.set(sku.productProperties!, sku);
			idMap.set(sku.productSku!, sku);
		});
		const getSku = (key) => {
			return propMap.get(key) ?? idMap.get(key);
		};
		const transfer = (key: string) => {
			return (
				propMap.get(key)?.productSku ??
				idMap.get(key)?.productProperties
			);
		};
		//
		return { transfer, idMap, propMap, getSku };
	}
};

// 购物车数量，在购物车页面是否可以优化，不调接口
const cartNum = asyncData(
	async () => {
		const res = await request.order.cart.getCurrentCartList().catch(() => {
			return {} as any;
		});

		return (res?.data?.cartSummary?.sumCartProductQuantity ?? 0) as number;
	},
	{ manual: true }
);

export { cart, cartSet, spuForChange, cartNum };
