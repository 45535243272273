import { Descriptions } from 'antd';
import classNames from 'classnames';
import { useState } from 'react';

enum PartEnum {
	/** Aspiring Entrepreneurs */
	A,
	/** Established Sellers */
	E
}

export default () => {
	const [active, setActive] = useState(PartEnum['A']);

	const itemsA = [
		{
			label: 'Product Selection Uncertainty',
			children: (
				<div className="w-[436px]">
					{`Difficulty in determining what products to sell.Lack of
					knowledge on trending items and market demand.`}
				</div>
			)
		},
		{
			label: 'Curated Product Selection',
			children: (
				<div className="w-[436px]">{`Data-driven recommendations of TikTok's hot-selling products to guide selection.`}</div>
			)
		},
		{
			label: 'Supply Chain Challenges',
			children: (
				<div className="w-[436px]">{`Struggle to find reliable sources of products.
			Inaccessibility to a network of trusted suppliers and manufacturers.`}</div>
			)
		},
		{
			label: 'Direct Supply Chain Access',
			children: (
				<div className="w-[436px]">{`Image search functionality to locate the source of in-demand products in the carefully selected supply chain resources.
			A vast network of verified suppliers and manufacturers at your fingertips.`}</div>
			)
		},
		{
			label: 'High Startup Costs',
			children: (
				<div className="w-[436px]">{`Significant time investment required for research and setup.
				Financial burden of hiring staff and sourcing inventory.
				Capital constraints for initial business expenditures.`}</div>
			)
		},
		{
			label: 'Reduced Startup Costs',
			children: (
				<div className="w-[436px]">{`One-stop selection and procurement platform  to cut down on the need for extensive staffing.
				Streamlined procurement process saving time and reducing operational workload.
				Competitive pricing through direct manufacturer connections to minimize financial outlay.`}</div>
			)
		}
	];

	const itemsE = [
		{
			label: 'Desire to Expand Product Range',
			children: (
				<div className="w-[436px]">{`Limited SKU variety may be hindering growth opportunities. Need to diversify offerings to mitigate market risks and satisfy evolving consumer preferences.`}</div>
			)
		},
		{
			label: 'Expansion of Product Categories',
			children: (
				<div className="w-[436px]">{`Access to a variety of TikTok trending products to expand inventory and attract more customers. Insights into market trends to help select high-opportunity products for diversification.`}</div>
			)
		},
		{
			label: `Profit Margin Enhancement`,
			children: (
				<div className="w-[436px]">{`Facing competitive pressure to lower prices while maintaining or increasing profitability. Challenges in optimizing costs across the supply chain to improve margins.`}</div>
			)
		},
		{
			label: 'Profit Margin Improvement',
			children: (
				<div className="w-[436px]">{`Competitive sourcing options to reduce product costs and increase profit margins. Transparent profuct pricing assured with no added profit margins.`}</div>
			)
		},
		{
			label: 'Product Quality and Delivery Consistency',
			children: (
				<div className="w-[436px]">{`Encountering issues with inconsistent product quality from suppliers. Struggling with unreliable delivery times that affect inventory management and customer satisfaction.`}</div>
			)
		},
		{
			label: 'Quality and Timeliness Assurance',
			children: (
				<div className="w-[436px]">{`Rigorous supplier vetting to ensure product quality and consistency. Efficient logistics and supply chain management for timely product delivery and restocking.`}</div>
			)
		}
	];

	return (
		<div className="py-[72px]">
			<div className="text-center">
				<div className="text-white-0 font-bold text-[40px]">
					Who is Using Us ?
				</div>
				<div className="w-[1000px] mx-auto font-bold  mt-[18px] mb-[32px] flex justify-center items-center">
					<div className="text-grey-1 text-h1 mr-[24px]">
						SellerStation for:
					</div>
					<div
						style={{
							border: `${active !== PartEnum.A ? 'solid 0.5px rgb(255 255 255 / 27%)' : 'solid 0.5px rgb(255 255 255 / 0%)'}`
						}}
						className={classNames(
							{ 'bg-red-6': active === PartEnum.A },
							'text-h3 text-grey-1 rounded-[27px] py-[6px] px-[24px] mr-[20px] cursor-pointer'
						)}
						onClick={() => setActive(PartEnum['A'])}
					>
						Aspiring Entrepreneurs
					</div>
					<div
						style={{
							border: `${active !== PartEnum.E ? 'solid 0.5px rgb(255 255 255 / 27%)' : 'solid 0.5px rgb(255 255 255 / 0%)'}`
						}}
						className={classNames(
							{ 'bg-red-6': active === PartEnum.E },
							'text-h3 text-grey-1 rounded-[27px] py-[6px] px-[24px] cursor-pointer'
						)}
						onClick={() => setActive(PartEnum['E'])}
					>
						Established Sellers
					</div>
				</div>
			</div>
			<div>
				<div className="text-red-6 text-h1 mb-[16px] font-bold">
					{PartEnum.A === active
						? 'Aspiring Entrepreneurs'
						: 'Established Sellers'}
				</div>
				<div className="flex">
					<div>
						<div className="flex font-bold text-h3 mb-[14px]">
							<div className="w-[460px] text-red-5">
								Pain-point
							</div>
							<div className="w-[460px] text-cyan-5">
								What SellerSation Can Provide
							</div>
						</div>
						<Descriptions
							className="w-[920px]"
							labelStyle={{
								color: '#FFF',
								fontSize: '20px',
								fontWeight: 900
							}}
							contentStyle={{
								fontSize: '16px',
								color: 'rgba(255, 255, 255, 0.50)',
								width: '460px',
								paddingBottom: '8px'
							}}
							column={2}
							layout="vertical"
							items={active === PartEnum.A ? itemsA : itemsE}
						/>
					</div>
					<img
						className="w-[280px] h-[400px]"
						src={
							PartEnum.A === active
								? 'https://static-us.theckb.com/static-asset/images/home-Aspiring Entrepreneurs.png'
								: 'https://static-us.theckb.com/static-asset/images/home-Established Sellers.png'
						}
						alt=""
					/>
				</div>
			</div>
		</div>
	);
};
