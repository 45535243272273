import React, { useEffect, useRef } from 'react';
import styles from './style.css';
interface IconProps extends React.SVGProps<SVGSVGElement> {
	size?: string | number;
	width?: string | number;
	height?: string | number;
	spin?: boolean;
	rtl?: boolean;
	color?: string;
	fill?: string;
	stroke?: string;
}

export default function Logo(props: IconProps) {
	const root = useRef<SVGSVGElement>(null);
	const {
		size = '1em',
		width,
		height,
		spin,
		rtl,
		color,
		fill,
		stroke,
		className,
		...rest
	} = props;
	const _width = width || size;
	const _height = height || size;
	const _stroke = stroke || color;
	const _fill = fill || color;
	useEffect(() => {
		if (!_fill) {
			(root.current as SVGSVGElement)
				?.querySelectorAll('[data-follow-fill]')
				.forEach((item) => {
					item.setAttribute(
						'fill',
						item.getAttribute('data-follow-fill') || ''
					);
				});
		}
		if (!_stroke) {
			(root.current as SVGSVGElement)
				?.querySelectorAll('[data-follow-stroke]')
				.forEach((item) => {
					item.setAttribute(
						'stroke',
						item.getAttribute('data-follow-stroke') || ''
					);
				});
		}
	}, [stroke, color, fill]);
	return (
		<svg
			ref={root}
			width={_width}
			height={_height}
			viewBox="0 0 161 28"
			preserveAspectRatio="xMidYMid meet"
			fill="none"
			role="presentation"
			xmlns="http://www.w3.org/2000/svg"
			className={`${className || ''} ${spin ? styles.spin : ''} ${rtl ? styles.rtl : ''}`.trim()}
			{...rest}
		>
			<g>
				<g
					clipPath="url(#11d837__a)"
					data-follow-fill="#fff"
					fill={_fill}
				>
					<path d="M21.539 0 8.087 13.452a.817.817 0 0 0-.004 1.156.817.817 0 0 0 1.116.032l.04-.037 6.73-6.73c3.397-3.397 8.882-3.42 12.251-.05 3.336 3.336 3.348 8.745.052 12.149l-.101.102L20.245 28h-5.068L25.66 17.554l.148-.153c1.837-1.987 1.794-5.082-.132-7-1.98-1.972-5.188-1.965-7.176.016l-6.73 6.706-.14.133a4.43 4.43 0 0 1-6.116-.12l-.142-.148a4.398 4.398 0 0 1 .156-6.08L16.476 0h5.063ZM5.069 10.446c-1.98 1.973-1.993 5.17-.03 7.141l.154.147c1.988 1.837 5.094 1.785 7.036-.15l6.73-6.706.14-.133c1.74-1.593 4.436-1.555 6.116.12 1.716 1.71 1.715 4.493-.003 6.217L14.253 28h-5.06l13.453-13.451a.817.817 0 0 0 .004-1.156.817.817 0 0 0-1.116-.032l-.04.037-6.73 6.73c-3.397 3.397-8.882 3.42-12.251.05-3.336-3.336-3.348-8.745-.052-12.15l.101-.102L10.488 0h5.065L5.07 10.446ZM67.334 7h-2.8v14h2.8V7Zm4.2 0h-2.8v14h2.8V7ZM51.233 7v2.8h-6.3a1.4 1.4 0 0 0-.104 2.796l.104.004h2.8a4.2 4.2 0 0 1 .158 8.397l-.158.003h-6.3v-2.8h6.3a1.4 1.4 0 0 0 .105-2.796l-.105-.004h-2.8a4.2 4.2 0 0 1-.157-8.397L44.933 7h6.3Zm7.701 2.8a4.2 4.2 0 0 1 4.197 4.042l.003.158v2.8h-7a1.4 1.4 0 0 0 1.295 1.396l.105.004h4.9V21h-4.9a4.2 4.2 0 0 1-4.198-4.043l-.002-.157V14a4.2 4.2 0 0 1 4.042-4.197l.158-.003h1.4Zm0 2.8h-1.4a1.4 1.4 0 0 0-1.397 1.296l-.003.104h4.2a1.4 1.4 0 0 0-1.296-1.396l-.104-.004Zm19.6-2.8a4.2 4.2 0 0 1 4.197 4.042l.003.158v2.8h-7a1.4 1.4 0 0 0 1.295 1.396l.105.004h4.9V21h-4.9a4.2 4.2 0 0 1-4.197-4.043l-.003-.157V14a4.2 4.2 0 0 1 4.043-4.197l.157-.003h1.4Zm0 2.8h-1.4a1.4 1.4 0 0 0-1.396 1.296l-.004.104h4.2a1.4 1.4 0 0 0-1.296-1.396l-.104-.004Zm8.4-2.8v1.4a6.966 6.966 0 0 1 4.004-1.397l.196-.003h1.4v2.8h-1.4a4.2 4.2 0 0 0-4.197 4.043l-.003.157V21h-2.8V9.8h2.8Zm58.001 0h-1.4a4.2 4.2 0 0 0-4.2 4.2v2.8a4.2 4.2 0 0 0 4.2 4.2h1.4a4.2 4.2 0 0 0 4.2-4.2V14a4.2 4.2 0 0 0-4.2-4.2Zm-1.4 2.8h1.4a1.4 1.4 0 0 1 1.4 1.4v2.8a1.4 1.4 0 0 1-1.4 1.4h-1.4a1.4 1.4 0 0 1-1.4-1.4V14a1.4 1.4 0 0 1 1.4-1.4Z" />
					<path
						d="M112.035 7v2.8h2.1v2.8h-2.1V21h-2.8v-8.4h-2.1V9.8h2.1V7h2.8Zm19.6 0v2.8h2.1v2.8h-2.1V21h-2.8v-8.4h-2.1V9.8h2.1V7h2.8Zm3.5 4.2h2.8V21h-2.8v-9.8Zm0-4.2h2.8v2.8h-2.8V7Z"
						clipRule="evenodd"
						fillRule="evenodd"
					/>
					<path d="M105.034 7v2.8h-6.3a1.4 1.4 0 0 0-.104 2.796l.104.004h2.8a4.2 4.2 0 0 1 .158 8.397l-.158.003h-6.3v-2.8h6.3a1.4 1.4 0 0 0 .105-2.796l-.105-.004h-2.8a4.2 4.2 0 0 1-.157-8.397L98.734 7h6.3Zm16.801 2.8a3.5 3.5 0 0 1 3.497 3.348l.003.152V21h-6.3a3.5 3.5 0 0 1-.152-6.997l.152-.003h3.5v-.7a.7.7 0 0 0-.619-.695l-.081-.005h-2.8a.701.701 0 0 0-.696.618l-.004.082h-2.8a3.5 3.5 0 0 1 3.348-3.497l.152-.003h2.8Zm.7 8.4v-1.4h-3.5a.701.701 0 0 0-.696.618l-.004.082a.7.7 0 0 0 .618.695l.082.005h3.5Zm30.8 2.8h-2.8V9.8h5.601a4.2 4.2 0 0 1 4.197 4.042l.003.158v7h-2.8v-7a1.4 1.4 0 0 0-1.296-1.396l-.104-.004h-2.801V21Z" />
				</g>
				<defs>
					<clipPath id="11d837__a">
						<path
							d="M0 0h160.336v28H0z"
							data-follow-fill="#fff"
							fill={_fill}
						/>
					</clipPath>
				</defs>
			</g>
		</svg>
	);
}
