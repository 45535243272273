export enum LoginType {
	'Code' = 0,
	'PassWord' = 1
}

export enum Plat {
	/** google  */
	GG = 'google',
	/** apple */
	AP = 'apple',
	/** tiktok */
	TK = 'tiktok',
	/** ins */
	IN = 'ins',
	/** ins */
	MT = 'meta',
	/** 韩国naver */
	NV = 'naver'
}

/**
 * 三方平台列表
 */
export const PlatList = [
	{
		name: Plat.TK,
		thirdCustomerName: 'TikTok',
		icon: 'https://static-us.theckb.com/static-asset/icon/icon-tiktok.svg'
	},
	{
		name: Plat.AP,
		thirdCustomerName: 'Apple',
		icon: 'https://static-us.theckb.com/static-asset/icon/icon-apple.svg'
	},
	{
		name: Plat.GG,
		thirdCustomerName: 'Google',
		icon: 'https://static-us.theckb.com/static-asset/icon/icon-google.svg'
	},
	{
		name: Plat.IN,

		thirdCustomerName: 'ins',
		icon: 'https://static-us.theckb.com/static-asset/icon/icon-ins.svg'
	},
	{
		name: Plat.MT,
		thirdCustomerName: 'mate',
		icon: 'https://static-us.theckb.com/static-asset/icon/icon-meta.svg'
	}
];

export enum ENUM_BUTYPE {
	/** 直营 */
	DIRECT = 0,
	/** 代采 */
	PROXY
}

export enum PlatformType {
	PC = 1,
	H5 = 4,
	/** 直采app */
	APP = 5
}

/**
 * 国家枚举
 */
export enum CountriesEnum {
	/**
	 * 日本
	 */
	Japan = 'JP',
	/**
	 * 澳大利亚
	 */
	Australia = 'AU',
	/**
	 * 加拿大
	 */
	Canada = 'CA',
	/**
	 * 爱尔兰
	 */
	Ireland = 'IE',

	/**
	 * 韩国
	 */
	Korea = 'KR',
	/**
	 * 马来西亚
	 */
	Malaysia = 'MY',
	/**
	 * 新西兰
	 */
	NewZealand = 'NZ',
	/**
	 * 新加坡
	 */
	Singapore = 'SG',
	/**
	 * 英国
	 */
	UK = 'GB',
	/**
	 * 美国
	 */
	US = 'US',
	/**
	 * 其他国家
	 */
	other = 'OTHER'
}

export const usUtcTimeZone = 'UTC-6';

export enum Term {
	/** 利用规约 */
	utilize = 'utilize',
	/** 隐私权限 */
	privacy = 'privacy'
	/** 用户协议  （自动扣款的合并在user agreement用户协议） */
	// membershipAgreement = 'membershipAgreement'
	// /** 美国站会员协议 */
	// autoRenewalAgreement = 'autoRenewalAgreement'
}

export enum StationCode {
	/** 美国站 */
	US = 'US',
	JP = 'JapanStation'
}

export enum SystemSourceType {
	'D2C' = 1,
	'B2B'
}

export enum CurrencyEnum {
	'USD' = 'USD',
	'JPY' = 'JPY'
}

// TODO:修改为美国
export const US_INFO = {
	globalRoaming: '+1',
	countryCode: StationCode.US,
	stationCode: StationCode.US,
	platformType: PlatformType.PC,
	utcTimeZone: 'UTC-6',
	systemSource: SystemSourceType.D2C,
	currency: CurrencyEnum.USD,
	bankName: 'JP MORGAN CHASE BANK, N.A.'
};
