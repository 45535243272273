import { useNavigate } from 'react-router-dom';
import Shoppingcartgouwuche from '@/common/icons/Shoppingcartgouwuche';
import { cartNum } from '@/atom/cart/cart';
import './shopCart.css';
import { userInfo } from '@/atom/user';

export const ShopCar = () => {
	const user = userInfo();
	const onShowCartNum = cartNum()!;
	const navigate = useNavigate();
	return (
		<div
			className={`ml-[32px] flex items-center siteHeaderCart ${user ? 'cursor-pointer' : ''}`}
			onClick={() => {
				user && navigate('/cart');
			}}
		>
			<Shoppingcartgouwuche style={{ width: 24, height: 24 }} />
			<div className="ml-[8px] flex flex-col items-start">
				<div
					className={
						onShowCartNum ? 'activeCartNum' : 'noGoodsCartNum'
					}
				>
					{onShowCartNum > 99 ? '99+' : onShowCartNum ?? 0}
				</div>
				<div style={{ fontWeight: 900 }}>Cart</div>
			</div>
		</div>
	);
};
