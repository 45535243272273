import { cloneElement, ReactNode, useRef } from 'react';
import { Modal, ModalProps } from 'antd';
import { useBoolean } from 'ahooks';
import { useLoading } from '@/hooks/useLoading';

export const useModal = (
	e: JSX.Element,
	props?: Result<
		Omit<ModalProps, 'open'>,
		{ ok: () => void; close: () => void; data: { current: any } }
	>
) => {
	const [isOpen, { toggle, setTrue: open, setFalse: close }] =
		useBoolean(false);
	// 提供给内部组件的钩子
	const data = useRef(null);
	// 提供给内部组件定义ok
	const okRef = useRef<any>(null);
	const ok = async () => {
		const res = await okRef.current?.();
		if (res === false) return;
		close();
	};
	const defineOk = (fn) => {
		okRef.current = fn;
	};
	//
	const modalProps: ModalProps = Object.assign(
		// 默认关闭行为，关闭有两种 ok，cancel
		{
			open: isOpen,
			onCancel: close,
			onOk: ok
		},
		// 交给外部处理关闭行为
		typeof props == 'function' ? props({ ok, close, data }) : props
	);
	//  1. 确认按钮loading状态 2. 是否触发外部行为
	const tempOk = modalProps.onOk!;
	const [confirmLoading, onOk] = useLoading((e) => (e ? tempOk(e) : ok()));
	Object.assign(modalProps, { confirmLoading, onOk });
	//
	const element = cloneElement(e, {
		// 交给内部组件处理关闭行为
		cancel: modalProps.onCancel,
		ok: modalProps.onOk,
		defineOk,
		data
	});
	const modal = <Modal {...modalProps}>{element}</Modal>;
	return [modal, { open, close, toggle }] as const;
};

// 弹窗样式
const customStyle = {};

// 定制弹窗
export function CustomModal() {
	return <></>;
}

// 按钮和弹窗绑定
export function OpenModal({
	children,
	modal,
	modalProps
}: {
	children: JSX.Element;
	modal: Parameters<typeof useModal>[0];
	modalProps?: Parameters<typeof useModal>[1];
}) {
	const [modalView, { open }] = useModal(modal, modalProps);
	const btn = cloneElement(children, {
		onClick: open
	});
	return (
		<>
			{modalView}
			{btn}
		</>
	);
}
