import { Button } from 'antd';

interface Props {
	onClose: () => void;
}
export default ({ onClose }: Props) => {
	return (
		<div className="w-[480x] rounded-[24px] overflow-hidden">
			<div className="bg-red-6 h-[160px] flex justify-center items-center">
				<img
					className="h-[67px]"
					src="https://static-us.theckb.com/static-asset/icon/icon_success.png"
					alt=""
				/>
			</div>
			<div className="flex-col items-center p-[20px] justify-center text-center">
				<div className="text-font-Title font-bold text-h4">
					{window._$m.t('会员开通成功')}
				</div>
				<div className="text-font-regular font-black text-base mt-[8px]">
					{window._$m.t('您已成为 seller station 会员')}
					{/* {expire} */}
				</div>
				<div className="bg-grey-2 rounded-[4px] px-[10px] py-[8px] text-center mt-[20px] text-font-Secondary text-base">
					{window._$m.t(
						'为了不影响后续使用，请保持PayPal内开启自动续费'
					)}
				</div>
				<div className="mt-[40px] text-right">
					<Button type="primary" shape="round" onClick={onClose}>
						{window._$m.t('我知道')}
					</Button>
				</div>
			</div>
		</div>
	);
};
