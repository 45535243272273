/*
 * @Author: liuliangliang liuliangliang@sniffgroup.com
 * @Date: 2024-04-25 15:11:48
 * @LastEditors: liuliangliang liuliangliang@sniffgroup.com
 * @LastEditTime: 2024-04-26 15:41:31
 * @FilePath: /seller-station-client/src/config/router/biz/station.tsx
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */

import { ReactNode, lazy } from 'react';
import { NestedRoute, type Route } from '@sniff/routeguard';
import List from '@route/station/order/list';
import Layout from '@route/station/_layout';
import { Spin } from 'antd';
import Homegongzuotai1 from '@/common/icons/Homegongzuotai_1';
import HomegongzuotaiD085k1h2 from '@/common/icons/HomegongzuotaiD085k1h2';
import Orderdingdan from '@/common/icons/Orderdingdan';
import Ordersetupdingdanshezhi from '@/common/icons/Ordersetupdingdanshezhi';
import Accountzhanghu from '@/common/icons/Accountzhanghu';
import Moneyzijin from '@/common/icons/Moneyzijin';
import OrderdingdanCo0i52dh from '@/common/icons/OrderdingdanCo0i52dh';
import OrdersetupdingdanshezhiCo0i52e7 from '@/common/icons/OrdersetupdingdanshezhiCo0i52e7';
import AccountzhanghuCo0i52bb from '@/common/icons/AccountzhanghuCo0i52bb';
import MoneyzijinCo0i52a4 from '@/common/icons/MoneyzijinCo0i52a4';
import Error from '@/container/404/Error';
export const menus: Route<
	Partial<{
		authentication: boolean;
		title: string;
		icon: ReactNode;
		iconActive: ReactNode;
	}>
>[] = [
	{
		path: '',
		id: 'dashboard',
		component: lazy(() => import('@route/station/dashboard/_layout')),
		meta: {
			title: window._$m.t('工作台'),
			icon: <HomegongzuotaiD085k1h2 height={16} width={16} />,
			iconActive: <Homegongzuotai1 height={16} width={16} />
		},
		children: [
			{
				path: '',
				id: 'dashboard-index',
				component: lazy(
					() => import('@route/station/dashboard/Workbench')
				),
				meta: {
					title: window._$m.t('工作台')
				}
			},
			{
				path: 'favorite',
				id: 'goods-favorite',
				component: lazy(
					() => import('@/container/station/dashboard/goods/Favorite')
				),
				meta: {
					title: window._$m.t('喜欢的商品')
				}
			},
			{
				path: 'browsed',
				id: 'browsed',
				component: lazy(
					() => import('@/container/station/dashboard/goods/Browsed')
				),
				meta: {
					title: window._$m.t('浏览记录')
				}
			}
		]
	},
	{
		path: 'order',
		id: 'order',
		element: <NestedRoute />,
		meta: {
			title: window._$m.t('订单'),
			icon: <Orderdingdan height={16} width={16} />,
			iconActive: <OrderdingdanCo0i52dh height={16} width={16} />
		},
		redirect: '/center/order/all',
		children: [
			{
				path: ':type',
				id: 'order-list',
				component: lazy(() => import('@route/station/order/list')),
				// keepalive: true,
				meta: {
					title: window._$m.t('订单列表')
				},
				children: [
					{
						path: ':id',
						id: 'order-detail-all',
						component: lazy(
							() => import('@route/station/order/detail')
						),
						meta: {
							title: window._$m.t('订单详情')
						}
					},

					{
						path: 'searchGoods',
						id: 'order-search-goods',
						component: lazy(
							() => import('@route/station/order/searchGoods')
						),
						meta: {
							title: window._$m.t('商品搜索')
						}
					}
				]
			}
		]
	},
	{
		path: 'setting',
		id: 'setting',
		component: lazy(() => import('@route/station/setting/_layout')),
		meta: {
			title: window._$m.t('订单设置'),
			icon: <Ordersetupdingdanshezhi height={16} width={16} />,
			iconActive: (
				<OrdersetupdingdanshezhiCo0i52e7 height={16} width={16} />
			)
		}
	},

	{
		path: 'customerFund',
		id: 'customerFund',
		component: lazy(() => import('@route/station/customerFund')),
		// errorElement: <Error />,
		meta: {
			title: window._$m.t('余额'),
			icon: <Moneyzijin height={16} width={16} />,
			iconActive: <MoneyzijinCo0i52a4 height={16} width={16} />
		}
	},
	{
		path: 'account',
		id: 'account',
		component: lazy(() => import('@route/station/account/_layout')),
		meta: {
			title: window._$m.t('账户'),
			icon: <Accountzhanghu height={16} width={16} />,
			iconActive: <AccountzhanghuCo0i52bb height={16} width={16} />
		}
	}
];

export const station = {
	path: 'center',
	id: 'station',
	component: lazy(() => import('@route/station/_layout')),
	// component: Layout,
	children: menus,
	meta: {
		authentication: true
	},
	skeleton: (
		<Spin
			spinning={true}
			className="h-full min-h-full"
			wrapperClassName="min-h-[100vh]"
		>
			<div className="w-full h-full bg-black-0" />
		</Spin>
	)
};
