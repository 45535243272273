import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { DateFormt } from '@/const/baseEnum';
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault('Asia/Shanghai');
const localUtc = dayjs.tz.guess();
// dayjs.tz.setDefault('Asia/Tokyo');

// console.log(localUtc);

// 汇率转换

// 添加金额分隔符
export function formatMoney(
	num: number | string | void,
	returnVal = true
): string {
	if (num !== 0 && (!num || isNaN(Number(num)))) return '';
	return returnVal
		? (Math.round(Number(num) * 100) / 100)
				.toLocaleString('en-us')
				.replace(/(\.\d\d)\d$/, '$1')
		: num.toLocaleString('en-us');
}

interface FormatMoneyProps {
	money: number | string;
	isThousandth?: boolean;
}

/**
 * 不涉及汇率单纯展示
 * @param money 货币价格
 * @param isThousandth 是否需要千分位
 */
export const formatMoneyUs = ({
	money,
	isThousandth = false
}: FormatMoneyProps): string => {
	return '$ ' + (isThousandth ? formatMoney(money) : money);
};
interface FormatMoneyCnyProps extends FormatMoneyProps {
	rate: number;
}
/**
 * 涉及汇率单纯展示(us=>cny)
 * @param money 货币价格
 * @param isThousandth 是否需要千分位
 */
export const formatMoneyCny = ({
	money,
	rate,
	isThousandth = false
}: FormatMoneyCnyProps): string => {
	return isThousandth
		? formatMoney(Number(money) * rate)
		: String(Number(money) * rate);
};

// 时间转换
/**
 * 默认取当地时间
 * @param time 时间
 * @param format 格式
 */
export const formatTimeToUs = (
	time?: string | Date,
	format = DateFormt.Date
): string => {
	if (time && time !== '-') {
		const defferZone = getDefferTime();
		return dayjs(time).add(defferZone, 'hour').format(format);
	}
	return '';
};
export const formatTimeUsToCn = (
	time?: string | Date,
	format = DateFormt.Date
): string => {
	if (time && time !== '-') {
		const defferZone = getDefferTime();
		return dayjs(time).subtract(defferZone, 'hour').format(format);
	}
	return '';
};

/** 返回浏览器当前的UTC时间偏移量 */
export const getTimeZoneByBowser = () => {
	// 获取本地时间
	const localTime = new Date();

	// 计算UTC偏移量（以分钟为单位）
	const utcOffset = (localTime.getTimezoneOffset() / 60) * -1;

	if (utcOffset >= 0) {
		return `UTC+${utcOffset}`;
	}
	return `UTC${utcOffset}`;
};

export const getDefferTime = () => {
	// 目前服务器时间为+8东八区时间，根据用户所在地的时区加减时间
	const defaultZone = 8;
	const customerUtcTimeZone = 0 - new Date().getTimezoneOffset() / 60;
	const defferZone = customerUtcTimeZone - defaultZone;
	return defferZone;
};

/** 获取价格的整数部分和小数部分 */
export const getPriceTwoList = (price: string) => {
	if (!price.includes('.')) {
		return [price, '00'];
	}
	const splitList = price.split('.');
	const lastPrice = splitList[1] ?? '00';
	return [splitList[0], lastPrice.length === 2 ? lastPrice : lastPrice + '0'];
};

export const groupArray = (array: any[], subGroupLength: number) => {
	let index = 0;
	const newArray: any[] = [];
	while (index < array.length) {
		newArray.push(array.slice(index, (index += subGroupLength)));
	}
	return newArray;
};

/** 搜索时间转中国时间，组装下 */
export const formatTime = (applyTime, startType, endType) => {
	if (!applyTime || !applyTime.length) {
		return {};
	}
	return {
		[startType]: formatTimeUsToCn(
			applyTime[0]?.format('YYYY-MM-DD 00:00:00'),
			DateFormt.DateTime
		),
		[endType]: formatTimeUsToCn(
			applyTime[1]?.format('YYYY-MM-DD 23:59:59'),
			DateFormt.DateTime
		)
	};
};

const aliRex = /(cbu01|img|cbu02|cbu03|cbu04).alicdn.com/;

/**
 * 通过cdn地址替换图片地址
 * @param imgUrl 图片地址
 * @returns
 */
export const changeImageCdn = (
	imgUrl: string | undefined = '',
	size: number | undefined = 0
) => {
	const imgPart = imgUrl?.split('.') || [];
	const reg = /(png|jpg|jpeg|bmp|webp)/;
	if (!reg.test(imgPart[imgPart.length - 1])) {
		return imgUrl;
	}
	if (
		(imgUrl.includes('.theckbs.com') || imgUrl.includes('.theckb.com')) &&
		!imgUrl.includes('x-oss-process')
	) {
		let url =
			imgUrl +
			(size
				? `?x-oss-process=image/resize,w_${size},limit_0,/format,webp`
				: `?x-oss-process=image/format,webp`);
		if (url.includes('jpg')) {
			url += '/quality,Q_80';
		}
		return url;
	}

	if (aliRex.test(imgUrl)) {
		let newImgUrl = imgUrl.replace(aliRex, 'global-img-cdn.1688.com');
		const urlList = newImgUrl.split('.');
		// 如果商品详情有size参数，则不替换
		if (size && !/\dx\d/.test(urlList[urlList.length - 2])) {
			const maxSize = size > 375 ? 375 : size;
			urlList.splice(imgPart.length - 1, 0, `${maxSize}x${maxSize}`);
			newImgUrl = urlList.join('.');
		}
		return newImgUrl;
	}

	return imgUrl;
};
