import ReactDOM from 'react-dom/client';
import './i18n/init';
import App from './App';
import './index.css';
import './index.scss';
const root = ReactDOM.createRoot(
	document.getElementById('root') as HTMLElement
);

console.log({
	branch: process.env.REACT_APP_branch,
	timeStamp: process.env.REACT_APP_timeStamp
});

root.render(
	// eslint-disable-next-line react/react-in-jsx-scope
	<App />
);
