import { useEffect } from 'react';

/** 提前加载图片 */
export const usePreLoadImg = (imgList: string[]) => {
	useEffect(() => {
		if (!imgList.length) return;
		imgList.forEach((img) => {
			const imgEle = new Image();
			imgEle.src = img;
		});
	}, [imgList]);
};
