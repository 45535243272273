import React, { useEffect, useRef } from 'react';
import styles from './style.css';
interface IconProps extends React.SVGProps<SVGSVGElement> {
	size?: string | number;
	width?: string | number;
	height?: string | number;
	spin?: boolean;
	rtl?: boolean;
	color?: string;
	fill?: string;
	stroke?: string;
}

export default function Amex(props: IconProps) {
	const root = useRef<SVGSVGElement>(null);
	const {
		size = '1em',
		width,
		height,
		spin,
		rtl,
		color,
		fill,
		stroke,
		className,
		...rest
	} = props;
	const _width = width || size;
	const _height = height || size;
	const _stroke = stroke || color;
	const _fill = fill || color;
	useEffect(() => {
		if (!_fill) {
			(root.current as SVGSVGElement)
				?.querySelectorAll('[data-follow-fill]')
				.forEach((item) => {
					item.setAttribute(
						'fill',
						item.getAttribute('data-follow-fill') || ''
					);
				});
		}
		if (!_stroke) {
			(root.current as SVGSVGElement)
				?.querySelectorAll('[data-follow-stroke]')
				.forEach((item) => {
					item.setAttribute(
						'stroke',
						item.getAttribute('data-follow-stroke') || ''
					);
				});
		}
	}, [stroke, color, fill]);
	return (
		<svg
			ref={root}
			width={_width}
			height={_height}
			viewBox="0 0 40 40"
			preserveAspectRatio="xMidYMid meet"
			fill="none"
			role="presentation"
			xmlns="http://www.w3.org/2000/svg"
			className={`${className || ''} ${spin ? styles.spin : ''} ${rtl ? styles.rtl : ''}`.trim()}
			{...rest}
		>
			<g>
				<path
					fill="#3498D8"
					d="M37.5 8h-35C1.125 8 0 9.065 0 10.368v20.124c0 1.303 1.125 2.368 2.5 2.368h35c1.375 0 2.5-1.065 2.5-2.368V10.368C40 9.065 38.875 8 37.5 8Z"
					clipRule="evenodd"
					fillRule="evenodd"
				/>
				<path
					fill="#fff"
					d="M10.8 20.8h1.6l-.8-2-.8 2ZM33.1 17H29l-1 1.1-.9-1.1h-8.7l-.8 1.8-.8-1.8h-3.5v.8l-.4-.8h-3L7 24h3.5l.4-1.1h1l.4 1.1h3.9v-.8l.3.8h2l.3-.9v.9h8l1-1.1.9 1.1h4.1l-2.6-3.5 2.9-3.5ZM21 23h-1.1v-3.9L18.2 23h-1l-1.7-3.9V23h-2.3l-.4-1.1h-2.4L10 23H8.7l2.1-5h1.7l1.9 4.7V18h1.9l1.5 3.4 1.4-3.4h1.9v5H21Zm9.9 0h-1.5l-1.3-1.7-1.5 1.7h-4.5v-5h4.6l1.4 1.6 1.5-1.6H31l-2.2 2.5 2.1 2.5Zm-7.7-4v.9h2.5v1h-2.5v1H26l1.3-1.5-1.2-1.4h-2.9Z"
					clipRule="evenodd"
					fillRule="evenodd"
				/>
			</g>
		</svg>
	);
}
