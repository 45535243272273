import { Button, ConfigProvider } from 'antd';
import classNames from 'classnames';
import { useCountUp } from 'react-countup';
import { useNavigate } from 'react-router-dom';
import { Suspense, lazy, useEffect, useRef } from 'react';
import { useAsyncEffect, useRequest } from 'ahooks';
import { request } from '@/config/request';
// import HomeEchart from './components/HomeEchart';
import HomePart5 from './components/HomePart5/Index';
import FaqList from './components/FAQ/Index';

const HomeEchart = lazy(() => import('./components/HomeEchart'));

const part2Data = [
	{
		img: 'https://static-us.theckb.com/static-asset/images/home-img-2-1.png',
		num: '10,000',
		hasAdd: true,
		tips: 'Next Big Hits'
	},
	{
		img: 'https://static-us.theckb.com/static-asset/images/home-img-2-2.png',
		num: '20,000',
		hasAdd: true,
		tips: 'Top-Selling Products'
	},
	{
		img: 'https://static-us.theckb.com/static-asset/images/home-img-2-3.png',
		num: '19',
		hasAdd: false,
		tips: 'Top-Selling Categories'
	}
];

const part3Data = [
	{
		icon: 'https://static-us.theckb.com/static-asset/images/home-img-3-1.png',
		title: 'Data-driven',
		content:
			'Precise TikTok top-seller suggestions, powered by advanced big data analytics. Precise TikTok top-seller suggestions, powered by advanced big data analytics.'
	},
	{
		icon: 'https://static-us.theckb.com/static-asset/images/home-img-3-2.png',
		title: 'Transparent',
		content: ' Transparent pricing assured, with no added profit margins.'
	},
	{
		icon: 'https://static-us.theckb.com/static-asset/images/home-img-3-3.png',
		title: 'Vertical Integration',
		content:
			'Seamlessly integrated fulfillment resources through partnerships with meticulously chosen suppliers, logistics channels and local warehouses.'
	},
	{
		icon: 'https://static-us.theckb.com/static-asset/images/home-img-3-4.png',
		title: 'Trustworthy',
		content:
			'Extensive expertise in supply chain management, with 199673 orders successfully delivered over the past 13 years.'
	}
];

const qslist = [
	{
		label: 'How can I find trending products on SellerStation?',
		value: `SellerStation offers daily product recommendations highlighting trending items on TikTok to help you stay ahead of the curve. Our platform's intuitive interface and trend analysis tools make it easy to discover hot products that resonate with your target audience.`
	},
	{
		label: 'What does the membership include?',
		value: `Membership grants you access to our comprehensive sourcing platform, where you can unlock the privilege of placing orders and enjoy exclusive discounts available only to members. Additionally, you'll gain full access to our curated list of TikTok's hot-selling product recommendations and the top-quality sources we've selected for you.`
	},
	{
		label: 'Can SellerStation help me with product sourcing and logistics?',
		value: `SellerStation streamlines the sourcing process by connecting you directly with top-tier suppliers from China, Japan, Korea, and Europe. We also facilitate logistics and shipping through partnerships with meticulously chosen logistics channels, ensuring that your products are delivered timely and efficiently to your store or warehouse.`
	},
	{
		label: 'How can I reach out to SellerStation customer support team?',
		value: `Our customer support team is ready to assist you with any inquiries or issues. You can contact us via email (support@sellerstation.cn), feedback hub, or live chat on our website. We strive to provide prompt and helpful service to ensure your experience with SellerStation is seamless.`
	},
	{
		label: 'How often are new products featured on SellerStation?',
		value: `We update our product listings frequently, with new trending items showcased on a daily basis. Our team constantly monitors the market to ensure that you have access to the latest and most sought-after products to meet your customers' demands.`
	}
];

const Home = () => {
	const countUpRef = useRef<HTMLDivElement>(null);
	const { update } = useCountUp({
		ref: countUpRef,
		start: 0,
		end: 0
	});
	const navigate = useNavigate();

	useAsyncEffect(async () => {
		const res = await request.customer.getCountByKey.getCountByKey({
			key: 'americaFalseData'
		});
		if (res.success) {
			update(res?.data ?? 0);
		}
	}, []);

	return (
		<div className="bg-black-950">
			<div className="w-[1200px] mx-auto">
				{/* 板块1 */}
				<div className=" flex justify-between items-center py-[72px]">
					<div className=" relative  py-[72px]">
						<img
							className="absolute w-[50px] top-[35px] left-[-40px]"
							src="https://static-us.theckb.com/static-asset/icon/star-icon.png"
							alt=""
						/>

						<div className="absolute text-[10px] bottom-0 left-0 text-white-45">
							Data update time: 00:00:00(GMT+8)
						</div>
						<div>
							<div className="font-bold text-h4 text-cyan-6">
								Total Shipments Delivered*
							</div>
							<div
								ref={countUpRef}
								className="text-cyan-6 text-[72px] font-extrabold"
								id="counter"
							/>

							<div className="font-bold text-h3 text-white-0">
								SellerStation for TikTok Shop
							</div>
							<div className="text-white-75 text-lg">
								A big data-driven one-stop sourcing platform to
								enhance your online business.
							</div>
							<div>
								<ConfigProvider
									theme={{
										token: {
											colorPrimary: '#FE2C55'
										}
									}}
								>
									<Button
										className="font-bold mt-[40px]"
										shape="round"
										type="primary"
										size="large"
										onClick={() => navigate('/source')}
									>
										Start Sourcing
									</Button>
								</ConfigProvider>
							</div>
						</div>
					</div>
					<div>
						<img
							className="w-[494px]"
							src="https://static-us.theckb.com/static-asset/images/home-img-1.png?t=1"
							alt=""
						/>
					</div>
				</div>
				{/* 板块2 */}
				<div className="py-[72px]">
					<div className="text-center">
						<div className="text-white-0 font-bold text-[40px]">
							What is SellerStation？
						</div>
						<div className="w-[1000px] mx-auto text-white-65 text-h4 mt-[8px]">
							A big data-driven, one-stop sourcing platform that
							connects you with the hottest TikTok sellers and
							direct source suppliers from a global supply chain.
						</div>
					</div>
					<div className="flex justify-between mt-[60px]">
						{part2Data.map((item, idx) => {
							return (
								<div
									className="flex-1 flex flex-col items-center"
									key={idx}
								>
									<img
										className="w-[200px]"
										src={item.img}
										alt=""
									/>

									<div className="font-bold text-white-0 text-[64px] mt-[16px]">
										{item.num}
										{item.hasAdd && (
											<span className="text-[40px]">
												+
											</span>
										)}
									</div>
									<div className="mt-[5px] text-lg font-extrabold text-white-0">
										{item.tips}
									</div>
								</div>
							);
						})}
					</div>
				</div>
				{/* 板块3 */}
				<div className="py-[72px]">
					<div className="text-center">
						<div className="text-white-0 font-bold text-[40px]">
							Our Advantages
						</div>
						<div className="w-[1000px] mx-auto text-white-65 text-h4 mt-[8px]">
							{`Our service is dedicated to facilitating direct
							connections between sellers and the carefully
							selected source suppliers of TikTok's top-selling
							items, all without adding any additional profit margins.`}
						</div>
					</div>
					<div className="mt-[60px] flex flex-wrap justify-between">
						{part3Data.map((item, idx) => {
							return (
								<div
									key={idx}
									className={classNames(
										'p-[20px] bg-white-6 rounded-[16px] w-[590px]',
										{
											'mb-[20px]': idx <= 1
										}
									)}
								>
									<img
										className="w-[60px] h-[60px]"
										src={item.icon}
										alt=""
									/>

									<div className="mt-[16px] font-bold text-red-6 text-h4">
										{item.title}
									</div>
									<div className="mt-[8px] text-lg text-white-50">
										{item.content}
									</div>
								</div>
							);
						})}
					</div>
				</div>
				{/* 板块4 */}
				<div className="py-[72px]">
					<div className="text-center">
						<div className="text-white-0 font-black text-[40px]">
							Source of Purchase
						</div>
						<div className="w-[1000px] mx-auto text-white-65 text-h4 mt-[8px]">
							{`We are dedicated to link worldwide sellers with the best supply source from China, Japan, Korea, and Europe.`}
						</div>
					</div>
					<div className="mt-[60px]">
						<div className="flex justify-between ">
							<div className="text-grey-1 font-bold ">
								<div>
									<div>
										<span className="text-[64px]">
											100,000
										</span>
										<span className="text-[40px]"> +</span>
									</div>
									<div className="text-lg font-extrabold mt-[-8px] mb-[16px]">
										Total Registered Users
									</div>
								</div>
								<div>
									<div>
										<span className="text-[64px] mr-[8px]">
											12-16
										</span>
										<span className="text-[40px]">
											days
										</span>
									</div>
									<div className="text-lg font-extrabold mt-[-8px] mb-[16px]">
										Average Fulfillment Time
									</div>
								</div>
								<div>
									<div>
										<span className="text-[64px]">
											200,000
										</span>
										<span className="text-[40px]"> +</span>
									</div>
									<div className="text-lg font-extrabold mt-[-8px] mb-[16px]">
										Source Factories
									</div>
								</div>
							</div>
							<Suspense>
								<HomeEchart />
							</Suspense>
						</div>
					</div>
				</div>
				{/* 板块5 */}
				<HomePart5 />
				{/* 板块6 */}
				<div className="py-[72px]">
					<div className="text-center">
						<div className="text-white-0 font-black text-[40px]">
							How to Utilize SellerStation?
						</div>
						<div className="text-left text-white-0 font-bold text-[30px]">
							User guide
						</div>
					</div>
					<div className="mt-[32px]">
						<FaqList list={qslist} />
					</div>
				</div>
			</div>
		</div>
	);
};

export default Home;
