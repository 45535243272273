import { toStands } from '@/components/base/standard';
import { request } from '@/config/request';
import { asyncData } from '../_base';
import { userInfo } from '../user';
import { receives } from '../addr';
import { rateInfo } from '../rate';
import { FeeDict } from './enum';
import { cart, cartSet } from './cart';
import { resetError } from './error';

// 钱包余额
export const wallet = asyncData(
	{
		get: async () => {
			const res = await request.settlement.wallet.getCustomerWallet({
				customerShopId: userInfo.shopId()
			});
			return res.data?.availableAmount ?? 0;
		},
		useFormmat(store) {
			const balance = store.getState();
			return {
				valueCn: balance,
				value: balance / rateInfo.getState()?.floatingRate ?? NaN
			};
		}
	},
	{
		defaultValue: 0,
		noCache: true
	}
);

export const feeDetails = toStands(FeeDict, [
	'totalAmtCn',
	'productAmtCn',
	'productAdditionAmtCn',
	'handlingFeeAmtCn',
	'shipAmtCn',
	'deliverAdditionAmtCn'
]);

const defaultValue = {
	// 人民币
	totalAmtCn: 0,
	productAmtCn: 0,
	productAdditionAmtCn: 0,
	handlingFeeAmtCn: 0,
	shipAmtCn: 0,
	deliverAdditionAmtCn: 0,
	// 站点货币
	totalAmt: 0,
	productAmt: 0,
	productAdditionAmt: 0,
	handlingFeeAmt: 0,
	shipAmt: 0,
	deliverAdditionAmt: 0
};

/** 支付价格，以及商品下单的所有校验集中处理 */
export const pay = asyncData(
	{
		/** 获取预计总价 */
		get: async (verify: any) => {
			const { isCheck, isEnough, isBatch, isSetAdds, ...params } =
				verify as any;
			// 不满足条件，无需请求
			if ([isCheck, isEnough, isBatch].some((v) => !v)) {
				return {
					...defaultValue,
					isSetAdds,
					isCheck,
					isEnough,
					isBatch
				};
			}
			//
			const res = await request.order.cart.calculatePrice(params);
			return Object.assign(
				{ isBatch, isEnough, isCheck, isSetAdds },
				res.data ?? defaultValue
			);
		},
		canCalc(store) {
			const fee = store.getState();
			return fee.isCheck && fee.isEnough && fee.isBatch;
		},
		/** 物流、地址、发货附加项、选中商品、满足起订量、满足倍数 */
		canPay(store) {
			const { receiveId, shippingId } = cartSet.getState();
			const fee = store.getState();
			const can =
				receiveId &&
				shippingId &&
				fee.isEnough &&
				fee.isBatch &&
				fee.isCheck &&
				fee.isSetAdds;
			if (!can) {
				resetError();
			}
			return can;
		},
		/** 支付下单 */
		order: async (store) => {
			const cartIdList = [...cart.getCheckedIdSet()] as number[];
			const total = store.getState().totalAmtCn;
			const { receiveId, shippingId, deliveryAdds } = cartSet.getState();
			const deliverAddress: any = receives.getReceiveById(receiveId);
			deliverAddress.intShipConfigId = shippingId;

			return request.order.cart.createOrder({
				estimatedTotalOrderPrice: total,
				cartIdList,
				orderType: 1,
				priceFloatPercent: 0,
				customerOrderNo: '',
				deliverAddress,
				deliveryAdditionalList: deliveryAdds
			});
		},
		/** 支付账单字段 valueCn、value、label形式 */
		useFeeDetail(store) {
			const record = store();
			return feeDetails.map((v) =>
				Object.assign(v, {
					// 格式兼容
					valueCn: record[v.key],
					value: record[toCurrent(v.key)]
				})
			);
		}
	},
	{
		defaultValue,
		manual: true
	}
);

/** totalAmtCn字段转为totalAmt */
export const toCurrent = (key: string) => {
	return key.replace(/Cn$/, '');
};
