import './index.scss';
export interface PaypalLoadingProps {
	description?: React.ReactNode;
	/** 是否展示 */
	visible?: boolean;
}
const PaypalLoading = (props: PaypalLoadingProps) => {
	const { description, visible } = props;
	const getDescriptionDom = () => {
		if ([undefined, null].includes(description as any)) {
			return (
				<p
					style={{
						fontWeight: '500'
					}}
				>
					{window._$m.t('正在处理付款，请稍等')}
				</p>
			);
		}
		if (typeof description === 'string') {
			return (
				<p
					style={{
						fontWeight: '500'
					}}
				>
					{description}
				</p>
			);
		}
		return description;
	};
	if (!visible) return null;
	return (
		<div
			className="comp-paypal-loading"
			onClick={(e) => {
				e.preventDefault();
				e.stopPropagation();
			}}
			style={{
				position: 'fixed',
				left: 0,
				right: 0,
				top: 0,
				bottom: 0,
				display: 'flex',
				alignItems: 'center',
				flexDirection: 'column',
				justifyContent: 'center',
				background: 'rgba(255,255,255,0.9)',
				zIndex: 100,
				marginTop: '-100px'
			}}
		>
			<img
				style={{
					width: 114,
					height: 114
				}}
				src="https://theckbstest-oss.theckbs.com/client/6526a252aa6.png"
				className=" spinner tiny"
				alt="loading"
			/>

			<div
				style={{
					width: '270px',
					textAlign: 'center',
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center'
				}}
			>
				{getDescriptionDom()}
				<div
					style={{
						display: 'flex',
						alignItems: 'center'
					}}
				>
					<img
						src="https://static-us.theckb.com/static-asset/icon/paypal_logo.png"
						style={{
							width: '66px',
							height: '16px'
						}}
						alt=""
					/>

					<p
						style={{
							marginLeft: '4px',
							color: '#999999'
						}}
					>
						{window._$m.t('服务提供')}
					</p>
				</div>
			</div>
		</div>
	);
};
export default PaypalLoading;
