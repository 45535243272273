import React, { useEffect, useRef } from 'react';
import styles from './style.css';
interface IconProps extends React.SVGProps<SVGSVGElement> {
	size?: string | number;
	width?: string | number;
	height?: string | number;
	spin?: boolean;
	rtl?: boolean;
	color?: string;
	fill?: string;
	stroke?: string;
}

export default function DinersSgPay(props: IconProps) {
	const root = useRef<SVGSVGElement>(null);
	const {
		size = '1em',
		width,
		height,
		spin,
		rtl,
		color,
		fill,
		stroke,
		className,
		...rest
	} = props;
	const _width = width || size;
	const _height = height || size;
	const _stroke = stroke || color;
	const _fill = fill || color;
	useEffect(() => {
		if (!_fill) {
			(root.current as SVGSVGElement)
				?.querySelectorAll('[data-follow-fill]')
				.forEach((item) => {
					item.setAttribute(
						'fill',
						item.getAttribute('data-follow-fill') || ''
					);
				});
		}
		if (!_stroke) {
			(root.current as SVGSVGElement)
				?.querySelectorAll('[data-follow-stroke]')
				.forEach((item) => {
					item.setAttribute(
						'stroke',
						item.getAttribute('data-follow-stroke') || ''
					);
				});
		}
	}, [stroke, color, fill]);
	return (
		<svg
			ref={root}
			width={_width}
			height={_height}
			viewBox="0 0 40 40"
			preserveAspectRatio="xMidYMid meet"
			fill="none"
			role="presentation"
			xmlns="http://www.w3.org/2000/svg"
			className={`${className || ''} ${spin ? styles.spin : ''} ${rtl ? styles.rtl : ''}`.trim()}
			{...rest}
		>
			<g>
				<g clipPath="url(#1204d7__a)">
					<path
						fill="#1B557D"
						d="M37.646 8.023c1.082 0 1.963.88 1.963 1.963v20.149c0 1.082-.88 1.963-1.963 1.963H2.355a1.965 1.965 0 0 1-1.963-1.963V9.986c0-1.082.88-1.963 1.963-1.963h35.291Zm0-.39H2.355A2.354 2.354 0 0 0 0 9.986v20.149c0 1.3 1.054 2.353 2.354 2.353h35.291c1.3 0 2.354-1.053 2.354-2.353V9.986c0-1.3-1.054-2.353-2.354-2.353Z"
					/>
					<path
						fill="#0079BE"
						d="M37.646 7.633H2.355A2.354 2.354 0 0 0 0 9.986v20.149c0 1.3 1.054 2.353 2.354 2.353h35.291c1.3 0 2.354-1.053 2.354-2.353V9.986c0-1.3-1.054-2.353-2.354-2.353Z"
					/>
					<path
						fill="#fff"
						d="M32.195 20.087c0-5.966-4.98-10.089-10.435-10.087h-4.695C11.544 9.998 7 14.123 7 20.087c0 5.456 4.544 9.94 10.065 9.913h4.695c5.455.026 10.435-4.458 10.435-9.913Z"
					/>
					<path
						fill="#0079BE"
						d="M17.095 10.845c-5.044.002-9.132 4.099-9.133 9.156.001 5.055 4.089 9.152 9.133 9.154 5.046-.002 9.135-4.099 9.136-9.154-.001-5.057-4.09-9.154-9.136-9.156Z"
					/>
					<path
						fill="#fff"
						d="M11.321 19.976a5.812 5.812 0 0 1 3.717-5.415v10.83a5.81 5.81 0 0 1-3.717-5.415Zm7.861 5.417V14.56a5.809 5.809 0 0 1 3.72 5.416 5.81 5.81 0 0 1-3.72 5.417Z"
					/>
				</g>
				<defs>
					<clipPath id="1204d7__a">
						<path fill="#fff" d="M0 0h40v40H0z" />
					</clipPath>
				</defs>
			</g>
		</svg>
	);
}
