import { useAtom, useSetAtom } from 'jotai';
import { useEffect } from 'react';
import { isVip, openVip } from '@/atom/mall/user';
import { useModal } from '@/components/modal';
import { userInfo } from '@/atom/user';
import OpenVipDialog from './OpenVipDialog/Index';

const VipDialogComponent = () => {
	const [openVal, setOpenVal] = useAtom(openVip);
	const setCurrentSystemIsVip = useSetAtom(isVip);
	const [modal, { open, close }] = useModal(
		<OpenVipDialog
			onSuccess={() => {
				setCurrentSystemIsVip(true);
				userInfo.update();
				setOpenVal(false);
			}}
			close={() => setOpenVal(false)}
		/>,

		{
			width: '1000px',
			footer: <></>,
			styles: {
				content: {
					padding: 0,
					borderRadius: '24px',
					background: '#fff'
				}
			},
			afterClose() {
				setOpenVal(false);
			}
		}
	);

	useEffect(() => {
		if (openVal) {
			open();
		} else {
			close();
		}
	}, [close, open, openVal]);
	return <div>{modal}</div>;
};

export default VipDialogComponent;
