import VipOnevip from '@/common/icons/VipOnevip';
import {
	CustomerDetailRespDTO,
	CustomerMembershipResDTO
} from '@/service/customer';
import { formatTimeToUs } from '@/utils/biz';

interface Props {
	userInfo?: CustomerDetailRespDTO & {
		membership?: CustomerMembershipResDTO & { templateLevel?: number };
	};
}

export default ({ userInfo }: Props) => {
	const expiresDate = formatTimeToUs(userInfo?.membership?.expiresDate);
	return (
		<div className="p-[20px]  flex rounded-t-[24px]">
			<img
				className="h-[48px] rounded-full mr-[8px]"
				src="https://static-us.theckb.com/static-asset/icon/default-head.png"
				alt=""
			/>

			<div className="flex flex-col justify-between">
				<div className="text-font-primary font-bold text-lg flex items-center">
					{userInfo?.loginName}
					{Number(userInfo?.membership?.templateLevel) > 0 && (
						<VipOnevip className="ml-[4px]" />
					)}
				</div>
				{userInfo?.membership?.templateLevel !== 0 && (
					<div className="text-font-Secondary  text-sm">
						{window._$m.t('会员到期日：')}
						{expiresDate}
					</div>
				)}
			</div>
		</div>
	);
};
