import React, { useEffect, useRef } from 'react';
import styles from './style.css';
interface IconProps extends React.SVGProps<SVGSVGElement> {
	size?: string | number;
	width?: string | number;
	height?: string | number;
	spin?: boolean;
	rtl?: boolean;
	color?: string;
	fill?: string;
	stroke?: string;
}

export default function Shoppingcartgouwuche(props: IconProps) {
	const root = useRef<SVGSVGElement>(null);
	const {
		size = '1em',
		width,
		height,
		spin,
		rtl,
		color,
		fill,
		stroke,
		className,
		...rest
	} = props;
	const _width = width || size;
	const _height = height || size;
	const _stroke = stroke || color;
	const _fill = fill || color;
	useEffect(() => {
		if (!_fill) {
			(root.current as SVGSVGElement)
				?.querySelectorAll('[data-follow-fill]')
				.forEach((item) => {
					item.setAttribute(
						'fill',
						item.getAttribute('data-follow-fill') || ''
					);
				});
		}
		if (!_stroke) {
			(root.current as SVGSVGElement)
				?.querySelectorAll('[data-follow-stroke]')
				.forEach((item) => {
					item.setAttribute(
						'stroke',
						item.getAttribute('data-follow-stroke') || ''
					);
				});
		}
	}, [stroke, color, fill]);
	return (
		<svg
			ref={root}
			width={_width}
			height={_height}
			viewBox="0 0 24 24"
			preserveAspectRatio="xMidYMid meet"
			fill="none"
			role="presentation"
			xmlns="http://www.w3.org/2000/svg"
			className={`${className || ''} ${spin ? styles.spin : ''} ${rtl ? styles.rtl : ''}`.trim()}
			{...rest}
		>
			<g>
				<path
					strokeLinejoin="round"
					strokeLinecap="round"
					strokeWidth="2"
					d="M2.5 8.5h19l-2.1 13H4.6l-2.1-13Zm15 0c0-3.314-2.463-6-5.5-6-3.038 0-5.5 2.686-5.5 6"
					data-follow-stroke="#fff"
					stroke={_stroke}
				/>
				<path
					d="M8.5 14a1 1 0 1 0 0-2 1 1 0 0 0 0 2Z"
					data-follow-fill="#fff"
					fill={_fill}
				/>
				<path
					strokeLinejoin="round"
					strokeLinecap="round"
					strokeWidth="2"
					d="M9 16.5s1 1.5 3 1.5 3-1.5 3-1.5"
					data-follow-stroke="#fff"
					stroke={_stroke}
				/>
				<path
					d="M15.5 14a1 1 0 1 0 0-2 1 1 0 0 0 0 2Z"
					data-follow-fill="#fff"
					fill={_fill}
				/>
			</g>
		</svg>
	);
}
