import React, { useEffect, useRef } from 'react';
import styles from './style.css';
interface IconProps extends React.SVGProps<SVGSVGElement> {
	size?: string | number;
	width?: string | number;
	height?: string | number;
	spin?: boolean;
	rtl?: boolean;
	color?: string;
	fill?: string;
	stroke?: string;
}

export default function Visa(props: IconProps) {
	const root = useRef<SVGSVGElement>(null);
	const {
		size = '1em',
		width,
		height,
		spin,
		rtl,
		color,
		fill,
		stroke,
		className,
		...rest
	} = props;
	const _width = width || size;
	const _height = height || size;
	const _stroke = stroke || color;
	const _fill = fill || color;
	useEffect(() => {
		if (!_fill) {
			(root.current as SVGSVGElement)
				?.querySelectorAll('[data-follow-fill]')
				.forEach((item) => {
					item.setAttribute(
						'fill',
						item.getAttribute('data-follow-fill') || ''
					);
				});
		}
		if (!_stroke) {
			(root.current as SVGSVGElement)
				?.querySelectorAll('[data-follow-stroke]')
				.forEach((item) => {
					item.setAttribute(
						'stroke',
						item.getAttribute('data-follow-stroke') || ''
					);
				});
		}
	}, [stroke, color, fill]);
	return (
		<svg
			ref={root}
			width={_width}
			height={_height}
			viewBox="0 0 40 40"
			preserveAspectRatio="xMidYMid meet"
			fill="none"
			role="presentation"
			xmlns="http://www.w3.org/2000/svg"
			className={`${className || ''} ${spin ? styles.spin : ''} ${rtl ? styles.rtl : ''}`.trim()}
			{...rest}
		>
			<g>
				<path
					fill="#fff"
					d="M37.645 8H2.354A2.354 2.354 0 0 0 0 10.354v20.148c0 1.3 1.054 2.354 2.354 2.354h35.291c1.3 0 2.354-1.054 2.354-2.354V10.354c0-1.3-1.054-2.354-2.354-2.354Z"
				/>
				<path
					fill="#1434CB"
					d="m16.901 15.19-1.644 9.992h2.593l1.644-9.992h-2.593Zm14.989 0c-.633 0-1.075.19-1.328.822l-3.858 9.233h2.72s.442-1.265.569-1.518h3.288c.063.38.316 1.518.316 1.518h2.404L33.913 15.19H31.89Zm-1.202 6.45c.19-.569 1.012-2.782 1.012-2.782l.316-.949.19.885s.506 2.404.633 2.91h-2.15v-.064Zm-6.008-2.403c-.886-.442-1.455-.759-1.455-1.265 0-.442.443-.885 1.455-.885.822 0 1.454.19 1.96.38l.253.126.38-2.15a6.654 6.654 0 0 0-2.34-.443c-2.53 0-4.364 1.391-4.364 3.288 0 1.455 1.265 2.277 2.277 2.72 1.012.506 1.328.822 1.328 1.265 0 .695-.822.948-1.581.948-1.012 0-1.581-.126-2.467-.506l-.316-.19-.38 2.214c.633.253 1.708.506 2.91.506 2.72 0 4.49-1.328 4.49-3.415.063-1.012-.632-1.897-2.15-2.593ZM13.107 15.19l-2.53 6.83-.253-1.392-.885-4.616c-.127-.633-.633-.822-1.202-.822h-4.11c-.064 0-.064.063-.127.063 0 .063 0 .126.063.126.633.19 1.202.38 1.708.633.569.253.948.759 1.138 1.328l2.087 7.842h2.72l4.047-9.992h-2.656Z"
				/>
			</g>
		</svg>
	);
}
