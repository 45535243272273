import React, { useEffect, useRef } from 'react';
import styles from './style.css';
interface IconProps extends React.SVGProps<SVGSVGElement> {
    size?: string | number;
    width?: string | number;
    height?: string | number;
    spin?: boolean;
    rtl?: boolean;
    color?: string;
    fill?: string;
    stroke?: string;
}

export default function AccountzhanghuCo0i52bb(props: IconProps) {
    const root = useRef<SVGSVGElement>(null)
    const { size = '1em', width, height, spin, rtl, color, fill, stroke, className, ...rest } = props;
    const _width = width || size;
    const _height = height || size;
    const _stroke = stroke || color;
    const _fill = fill || color;
    useEffect(() => {
      if (!_fill) {
        (root.current as SVGSVGElement)?.querySelectorAll('[data-follow-fill]').forEach(item => {
          item.setAttribute('fill', item.getAttribute('data-follow-fill') || '')
        })
      }
      if (!_stroke) {
        (root.current as SVGSVGElement)?.querySelectorAll('[data-follow-stroke]').forEach(item => {
          item.setAttribute('stroke', item.getAttribute('data-follow-stroke') || '')
        })
      }
    }, [stroke, color, fill])
    return (
        <svg
          ref={root}
          width={_width} 
          height={_height}
          viewBox="0 0 16 16"
          preserveAspectRatio="xMidYMid meet"
          fill="none"
          role="presentation"
          xmlns="http://www.w3.org/2000/svg"
          className={`${className || ''} ${spin ? styles.spin : ''} ${rtl ? styles.rtl : ''}`.trim()}
          {...rest}
        >
          <g><path strokeLinejoin="round" strokeLinecap="round" strokeWidth="1.333" d="M8 6.667A2.333 2.333 0 1 0 8 2a2.333 2.333 0 0 0 0 4.667ZM2 13.6v.4h12v-.4c0-1.494 0-2.24-.29-2.811a2.668 2.668 0 0 0-1.166-1.165c-.57-.291-1.317-.291-2.81-.291H6.266c-1.494 0-2.24 0-2.811.29-.502.256-.91.664-1.165 1.166-.29.57-.29 1.317-.29 2.81Z" data-follow-fill="#199A97" data-follow-stroke="#199A97" fill={_fill} stroke={_stroke}/></g>
        </svg>
    )
}
